import { makeStyles } from '@material-ui/core/styles';

export const useBreadcrumbsStyles = makeStyles(({ color: { base } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1em',
    flexWrap: 'nowrap',
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 99,
    background: base,
    borderBottom: `1px solid ${base}`,
  },
  content: {
    padding: '0 1.5em 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    padding: '0 1.5em 0 1em',
  },
}));
