import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FormHelperText, LinearProgress } from '@material-ui/core';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';
import { getStorageItem } from '@components/storage/storage';
import clsx from 'clsx';
import { SearchIcon, TrashIcon } from '@shared/assets/images/icons';
import { useHelperTextStyles, useUploadFieldStyles } from './UploadField.styles';
import { bytesToSize } from '../utils/helpers';
import CommonConstants from '../utils/constants';
import { IUploadField } from './UploadField.interfaces';

export const UploadField: FunctionComponent<IUploadField> = ({
  fileNameFieldName = 'fileName',
  fileHashFieldName = 'fileHash',
  fileUrlFieldName = 'fileUrl',
  fileProgressName = 'fileProgress',
  fileOriginField,
  validate,
  required,
  disabled,
  readonly,
  onCancel,
  onChange,
  accept,
  className,
  label,
  requiredText,
  showViewButton,
}) => {
  const classes = useUploadFieldStyles();
  const helperTextClasses = useHelperTextStyles();
  const [translate] = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    clearErrors,
    setError,
  } = useFormContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fileName = watch(fileNameFieldName);
  const fileHash = watch(fileHashFieldName);
  const fileProgress = watch(fileProgressName);

  const progress = Number(fileProgress) || undefined;
  const loading = progress !== undefined;

  const isViewButtonShow = showViewButton && fileHash && fileHash.length;
  const computedButtonClasses = clsx(classes.buttonsContainer, {
    [classes.smallSize]: !isViewButtonShow || readonly,
    [classes.wideSize]: isViewButtonShow && !readonly,
  });

  const validateFileHash = useCallback(
    (value: string) => {
      if (loading) {
        return false;
      }
      if (required && !value) {
        return requiredText || translate('FILE_NOT_CHOSEN', '');
      }
      return true;
    },
    [loading, required, requiredText, translate]
  );

  const clearUploadFields = useCallback(() => {
    setValue(fileHashFieldName, '');
    setValue(fileNameFieldName, '');
    setValue(fileUrlFieldName, undefined);
    setValue(fileProgressName, undefined);
    if (fileOriginField) {
      setValue(fileOriginField, '');
    }
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [
    setValue,
    fileHashFieldName,
    fileNameFieldName,
    fileUrlFieldName,
    fileProgressName,
    fileOriginField,
  ]);

  useEffect(() => {
    const { message = '' } = errors?.[fileHashFieldName] || {};
    if (message && fileName) {
      clearUploadFields();
    }
  });

  useEffect(() => {
    if (Number(fileProgress) === 100) {
      setTimeout(() => {
        setValue(fileProgressName, undefined);
      }, 300);
    }
  }, [fileProgress, fileProgressName, setValue]);

  useEffect(() => {
    register(fileHashFieldName, { validate: validate || validateFileHash });
    register(fileNameFieldName);
    register(fileUrlFieldName);
    register(fileProgressName);
    if (fileOriginField) {
      register(fileOriginField);
    }
  }, [
    disabled,
    register,
    validateFileHash,
    required,
    validate,
    fileHashFieldName,
    fileUrlFieldName,
    fileNameFieldName,
    fileOriginField,
    fileProgressName,
  ]);

  const handleFileUpload = useCallback(
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      const [file = undefined] = files || [];
      if (file) {
        clearErrors([fileHashFieldName, fileNameFieldName, fileUrlFieldName]);

        if (file.size <= CommonConstants.FileUploadMaxSize) {
          if (fileOriginField) {
            setValue(fileOriginField, file);
            setValue(fileHashFieldName, file.name);
          } else {
            setValue(fileHashFieldName, '');
          }
          setValue(fileNameFieldName, file.name);
          setValue(fileUrlFieldName, '');
          if (typeof onChange === 'function') {
            onChange({
              fieldName: fileNameFieldName,
              urlName: fileUrlFieldName,
              hashName: fileHashFieldName,
              progressName: fileProgressName,
              file,
            });
          }
        } else {
          const { value: fileSize, units = '' } = bytesToSize(CommonConstants.FileUploadMaxSize);
          setError(fileHashFieldName, {
            type: 'error',
            message: translate('UPLOAD_MAX_FILE_SIZE_EXCEEDED', {
              fileSize,
              units: translate(units),
            }),
          });
        }
      }
    },
    [
      clearErrors,
      fileHashFieldName,
      fileNameFieldName,
      fileUrlFieldName,
      fileOriginField,
      fileProgressName,
      setValue,
      setError,
      translate,
      onChange,
    ]
  );

  function handleDocumentViewClick() {
    const token = getStorageItem('token');
    if (token && process.env.API_URL) {
      window.open(`${process.env.API_URL}/files/view?filename=${fileHash}&token=${token}`);
    }
  }

  const handleResetFile = useCallback(() => {
    clearUploadFields();
    clearErrors([fileHashFieldName, fileNameFieldName, fileUrlFieldName]);
    if (typeof onCancel === 'function') {
      onCancel();
    }
    if (typeof onChange === 'function') {
      onChange({ fieldName: '', urlName: '', hashName: '', progressName: '', file: null });
    }
  }, [
    clearErrors,
    clearUploadFields,
    onCancel,
    onChange,
    fileHashFieldName,
    fileNameFieldName,
    fileUrlFieldName,
  ]);

  const renderError = () => {
    const { message = '' } = errors?.[fileHashFieldName] || {};
    if (message) {
      return (
        <FormHelperText classes={helperTextClasses} error id={'component-helper-text'}>
          {String(message)}
        </FormHelperText>
      );
    }
    return null;
  };

  const buttonText = loading ? translate('LOADING') : fileName || label || translate('CHOOSE_FILE');

  return (
    <div className={clsx(classes.root, className)}>
      {!readonly ? (
        <div className={classes.control}>
          <Button
            variant={'secondary'}
            component={'label'}
            disableFocusRipple={loading}
            disableRipple={loading}
            aria-describedby="component-helper-text"
            fullWidth
            className={classes.button}
            disabled={disabled}
          >
            <input
              ref={fileInputRef}
              hidden
              onChange={handleFileUpload}
              type={'file'}
              multiple={false}
              accept={accept}
              disabled={disabled || loading}
            />
            {loading && (
              <LinearProgress
                variant={'determinate'}
                color={'primary'}
                value={progress || 0}
                className={classes.linearProgress}
              />
            )}
            <div className={classes.label}>
              {loading && (
                <CircularProgress
                  size={'1.3em'}
                  color={'primary'}
                  className={classes.circularProgress}
                />
              )}
              <Typography
                type={'text3'}
                color={disabled ? 'tertiary200' : 'tertiary900'}
                className={classes.fileLabel}
              >
                {buttonText}
              </Typography>
            </div>
          </Button>
          {renderError()}
        </div>
      ) : (
        <div className={classes.textOutlined}>
          <Typography type={'text3'} color={'tertiary900'} className={classes.fileLabel}>
            {buttonText}
          </Typography>
        </div>
      )}
      {!loading && !!fileHash && (
        <div className={computedButtonClasses}>
          {isViewButtonShow && (
            <Button
              variant={'secondary'}
              className={classes.functionalButton}
              onClick={() => handleDocumentViewClick()}
              disabled={disabled}
            >
              <SearchIcon />
            </Button>
          )}
          {!readonly && (
            <Button
              variant={'secondary'}
              color={'error'}
              className={classes.functionalButton}
              onClick={handleResetFile}
              disabled={disabled}
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadField;
