import React, { PropsWithChildren } from 'react';
import { Skeleton } from '@material-ui/lab';
import Flex from '@shared/components/Flex';
import Cell from '../Cell';
import { ILazyTableCellSkeletonType } from './CellSkeleton.interfaces';
import { useLazyTableCellSkeletonStyles } from './CellSkeleton.styles';

export const CellSkeleton = <T,>({
  column,
  columnIndex,
  maxLength,
}: PropsWithChildren<ILazyTableCellSkeletonType<T>>) => {
  const classes = useLazyTableCellSkeletonStyles();

  const renderSkeletonContent = () => {
    if (!columnIndex) {
      return (
        <Skeleton
          variant={'rect'}
          width={32}
          height={32}
          animation={false}
          className={classes.skeletonImage}
        />
      );
    }

    if (columnIndex === maxLength - 1) {
      return (
        <Flex className={classes.skeletonActionsCell}>
          <Skeleton
            variant={'rect'}
            width={32}
            height={32}
            animation={false}
            className={classes.skeletonImage}
          />
          <Skeleton
            variant={'rect'}
            width={32}
            height={32}
            animation={false}
            className={classes.skeletonImage}
          />
        </Flex>
      );
    }

    return (
      <Flex fullWidth direction={'column'}>
        <Skeleton
          variant={'text'}
          width={'100%'}
          animation={false}
          className={classes.skeletonTextLong}
        />
        <Skeleton
          variant={'text'}
          width={'87%'}
          animation={false}
          className={classes.skeletonText}
        />
      </Flex>
    );
  };

  return <Cell column={column}>{renderSkeletonContent()}</Cell>;
};

export default CellSkeleton;
