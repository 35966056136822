import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  comparedEmployeeListType,
  comparedEmployeeObjectValue,
  rawDataListType,
  scenarioDirectionEnum,
  scenarioSettingsType,
} from '../../IntegrationForm.interfaces';
import {
  incomingMissedResponsibleOptions,
  incomingResponsibleOptions,
  incomingUnknownResponsibleOptions,
  outgoingResponsibleOptions,
} from '../../IntegrationForm.constants';

export function useRetailScenarioFormPropsHooks(
  direction: scenarioDirectionEnum,
  edit: scenarioSettingsType | undefined,
  comparedEmployee: comparedEmployeeListType | null,
  rawEmployee: rawDataListType | null
) {
  const [translate] = useTranslation();
  const isCommonSettings = useMemo(() => direction === scenarioDirectionEnum.Common, [direction]);
  const isSiteCompare = useMemo(() => edit === scenarioSettingsType.Sites, [edit]);

  const comparedEmployeeList = useMemo(() => {
    if (comparedEmployee) {
      return Object.values(comparedEmployee).reduce(
        (
          res: Array<{ key: string; value: string }>,
          emp: comparedEmployeeObjectValue | Array<string>
        ) => {
          if (Array.isArray(emp)) return res;
          return [...res, { key: emp.crmId, value: emp.name }];
        },
        []
      );
    }
    return [];
  }, [comparedEmployee]);

  const rawEmployeeList = useMemo(
    () => (rawEmployee ? rawEmployee.map((i) => ({ key: String(i.id), value: i.name })) : []),
    [rawEmployee]
  );

  const responsibleDefaultList = useMemo(() => {
    if (direction === scenarioDirectionEnum.Incoming) {
      if (edit === scenarioSettingsType.IncomingUnknown) {
        return incomingUnknownResponsibleOptions;
      }
      if (edit === scenarioSettingsType.IncomingMissedKnown) {
        return incomingMissedResponsibleOptions;
      }
      return incomingResponsibleOptions;
    }
    return outgoingResponsibleOptions;
  }, [direction, edit]);

  const responsibleWithEmployeeList = useMemo(() => {
    if (comparedEmployeeList.length === 0) return [];

    if (
      edit !== scenarioSettingsType.IncomingUnknown &&
      edit !== scenarioSettingsType.IncomingMissedUnknown
    ) {
      return [
        {
          key: 'responsible',
          value: translate('CLIENT_RESPONSIBLE'),
        },
        ...comparedEmployeeList,
      ];
    }

    return [...comparedEmployeeList];
  }, [comparedEmployeeList, edit, translate]);

  const contactTitle = useMemo(() => {
    switch (edit) {
      case scenarioSettingsType.Sites: {
        return translate('SHOW_SITE_NAME');
      }
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingMissedUnknown:
      case scenarioSettingsType.OutgoingUnknown:
      case scenarioSettingsType.OutgoingMissedUnknown: {
        return translate('CREATE_CONTACT_CLIENT');
      }
      case scenarioSettingsType.CommonCallHistory: {
        return translate('SAVE_HISTORY_FROM_MANAGER');
      }
      default: {
        return '';
      }
    }
  }, [edit, translate]);

  const contactTitleDescription = useMemo(() => {
    const crmName = 'RetailCRM';
    switch (edit) {
      case scenarioSettingsType.Sites: {
        return translate('SHOW_SITE_NAME_DESCRIPTION');
      }
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingMissedUnknown: {
        return translate('CREATE_CONTACT_DESCRIPTION');
      }
      case scenarioSettingsType.OutgoingUnknown:
      case scenarioSettingsType.OutgoingMissedUnknown: {
        return translate('RESPONSIBLE_MANAGER_CALLED');
      }
      case scenarioSettingsType.CommonCallHistory: {
        return translate('COMMON_CALLS_HISTORY', { crmName });
      }
      default: {
        return null;
      }
    }
  }, [edit, translate]);

  return {
    contactTitle,
    contactTitleDescription,
    isCommonSettings,
    isSiteCompare,
    comparedEmployeeList,
    rawEmployeeList,
    responsibleDefaultList,
    responsibleWithEmployeeList,
  };
}
