import React, { FunctionComponent, MouseEvent as ReactMouseEvent, useState } from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import Button from '@shared/components/Button';
import { XIcon } from '@shared/assets/images/icons';
import { useTooltipStyles } from './Tooltip.styles';
import { ITooltipProps } from './Tooltip.interfaces';

export const Tooltip: FunctionComponent<ITooltipProps> = ({
  children,
  title = '',
  placement = 'top-end',
  open,
  onClose,
  ...props
}) => {
  const classes = useTooltipStyles();
  const [openState, setOpenState] = useState(true);

  function handlePopoverOpen() {
    setOpenState(true);
  }

  function handlePopoverClick(event: ReactMouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();
  }

  function handlePopoverClose(event: ReactMouseEvent<HTMLElement, MouseEvent>) {
    handlePopoverClick(event);
    if (typeof onClose === 'function') {
      onClose();
    } else {
      setOpenState(false);
    }
  }

  const renderTitle = () => (
    <div className={classes.container} onClick={handlePopoverClick}>
      <div className={classes.content}>{title}</div>
      <div className={classes.actions}>
        <Button clear onClick={handlePopoverClose}>
          <XIcon />
        </Button>
      </div>
    </div>
  );

  return (
    <MuiTooltip
      classes={{
        tooltip: classes.root,
        arrow: classes.arrow,
      }}
      placement={placement}
      arrow
      title={renderTitle()}
      open={open !== undefined ? open : openState}
      {...props}
    >
      <div
        className={classes.anchorContainer}
        onMouseOver={open !== undefined ? handlePopoverOpen : undefined}
        onFocus={open !== undefined ? handlePopoverOpen : undefined}
      >
        {children}
      </div>
    </MuiTooltip>
  );
};

export default Tooltip;
