import {
  BGBellIcon,
  BGBlacklistIcon,
  BGLayersIcon,
  BGMapIcon,
  BGMelodyIcon,
  BGMicIcon,
  BGRepeatIcon,
  BGSipIcon,
  BGSmsIcon,
} from '@images/settings';
import {
  ArrowRepeatIcon,
  BellIcon,
  DomainsIcon,
  MapInIcon,
  MelodyIcon,
  MicIcon,
  SipIcon,
  SmsIcon,
  UserXIcon,
} from '@shared/assets/images/icons';

import { OptionType } from '@/client/generated/graphql';
import { FeaturesType } from '../../../typings';

export const settingCardList = [
  {
    title: 'RECORD_TALKS',
    link: '/settings/record-talks',
    icon: MicIcon,
    backgroundIcon: BGMicIcon,
    description: 'DESCRIPTION_RECORD_TALKS',
    feature: FeaturesType.BasicSettings,
    option: OptionType.Records,
  },
  {
    title: 'TIMEZONE',
    link: '/settings/timezone',
    icon: MapInIcon,
    backgroundIcon: BGMapIcon,
    description: 'DESCRIPTION_TIMEZONE',
    feature: FeaturesType.BasicSettings,
    option: OptionType.BasicPbx,
  },
  {
    title: 'TUNE',
    link: '/settings/tune',
    icon: MelodyIcon,
    backgroundIcon: BGMelodyIcon,
    description: 'DESCRIPTION_TUNE',
    feature: 'basic',
    option: OptionType.BasicPbx,
  },
  {
    title: 'BLF',
    link: '/settings/blf',
    icon: ArrowRepeatIcon,
    backgroundIcon: BGRepeatIcon,
    description: 'DESCRIPTION_BLF',
    feature: FeaturesType.BasicSettings,
    option: OptionType.BasicPbx,
  },
  {
    title: 'BLACK_LIST',
    link: '/settings/black-list',
    icon: UserXIcon,
    backgroundIcon: BGBlacklistIcon,
    description: 'DESCRIPTION_BLACKLIST',
    feature: FeaturesType.BlackList,
    option: OptionType.BasicPbx,
  },
  {
    title: 'TONE',
    link: '/settings/tone',
    icon: DomainsIcon,
    backgroundIcon: BGLayersIcon,
    description: 'DESCRIPTION_TONE',
    feature: FeaturesType.BasicSettings,
    option: OptionType.BasicPbx,
  },
  {
    title: 'SIP',
    link: '/settings/sip',
    icon: SipIcon,
    backgroundIcon: BGSipIcon,
    description: 'DESCRIPTION_SIP',
    feature: FeaturesType.OwnNumbers,
    option: OptionType.OwnNumbersIn,
  },
  {
    title: 'NOTIFICATIONS',
    link: '/settings/notifications',
    icon: BellIcon,
    backgroundIcon: BGBellIcon,
    description: 'DESCRIPTION_NOTIFICATIONS',
    feature: FeaturesType.Notifications,
    option: OptionType.Missed,
  },
  {
    title: 'BUSINESS_CARDS',
    link: '/business-cards/greetings',
    icon: SmsIcon,
    backgroundIcon: BGSmsIcon,
    description: 'DESCRIPTION_SMS_CARDS',
    feature: FeaturesType.BusinessCards,
    option: OptionType.BusinessCards,
  },
];
