import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useOutgoingCallStyles = makeStyles(({ color: { primary } }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    height: '100%',
  },
  formContent: {
    width: '23em',
    marginBottom: '2em',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.375em',
  },
  secondaryText: {
    marginTop: '0.4em',
  },
  customOption: {
    display: 'flex',
    flexFlow: 'column nowrap',
    pointerEvents: 'none',
    height: '4.375em',
    justifyContent: 'center',
    width: '100%',
    padding: '0.75em 2.615em 0.75em 1.3em',
  },
  customLine: {
    padding: '0.825em 0.5em 0.825em 1em',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  callerTitle: {
    width: '95%',
    paddingRight: '1em',
  },
  callerExt: {
    width: '2em',
    display: 'flex',
    justifyContent: 'start',
  },
  glyph: {
    marginRight: '0.5em',
    color: primary[700],
  },
  ...styleConstants,
}));
