import { makeStyles } from '@material-ui/core/styles';

export const useAccordionStyles = makeStyles(
  ({
    color: { tertiary, secondary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    accordion: {
      boxShadow: 'none',
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '0.75em',
      '&:before': {
        display: 'none',
      },
      marginBottom: '1em',
    },
    accordionSumRoot: {
      borderRadius: '0.75em',
      padding: '0 2em',
      transition: createTransition(['backgroundColor'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      backgroundColor: tertiary[50],
      '&:hover': {
        backgroundColor: secondary[50],
        '& .Mui-expanded': {
          backgroundColor: secondary[50],
        },
      },
      '&.Mui-expanded': {
        borderRadius: '0.75em 0.75em 0 0',
        borderBottom: `1px solid ${tertiary[200]}`,
      },
    },
    accordionTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0.75em 0',
    },
    accordionTitleExpanded: {
      backgroundColor: tertiary[50],
      '&.Mui-expanded': {
        minHeight: 'auto',
      },
      '& .Mui-expanded': {
        margin: '0.75em 0',
      },
    },
    accordionBody: {
      padding: '1.5em 2em 2em',
      display: 'block',
    },
  }),
  {
    index: 1,
  }
);
