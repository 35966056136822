import { PaymentServiceModelWithId } from '@/utils/paymentServiceModel';
import { ICellProps } from '@components/LazyTable';
import Typography from '@shared/components/Typography';
import { format } from 'date-fns';
import React from 'react';

export const BalanceDateCell = ({ item }: ICellProps<PaymentServiceModelWithId>) => {
  if (item) {
    const cellValue = format(new Date(item.date), 'dd.MM.yyyy HH:mm');

    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {cellValue}
      </Typography>
    );
  }

  return <div />;
};
