import {
  Directions,
  IDay,
  IHour,
  IPeriod,
  ISchedulePeriod,
  ISelectionSegmentsRange,
} from './Scheduler.interfaces';
import { customPeriods, initialATCPeriods, initialPeriods } from './Scheduler.constants';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';

export const initScheduler = () => ({
  workingHours: [
    {
      start: 540,
      end: 1079,
      days: [0, 1, 2, 3, 4],
    },
  ],
  nonWorkingHours: [
    {
      start: 0,
      end: 539,
      days: [0, 1, 2, 3, 4],
    },
    {
      start: 1080,
      end: 1439,
      days: [0, 1, 2, 3, 4],
    },
    {
      start: 0,
      end: 1439,
      days: [5, 6],
    },
  ],
});

export const initPeriods = (periods: Partial<ISchedulePeriod>[]): ISchedulePeriod[] =>
  [...initialPeriods, ...initialATCPeriods, ...customPeriods].reduce<ISchedulePeriod[]>(
    (result, { key, ...rest }) => {
      const customParams = periods.find(({ key: existingKey }) => existingKey === key);
      if (customParams) {
        result.push({
          key,
          ...rest,
          ...customParams,
        });
      } else if (
        key === SchedulePeriodsTypes.NonWorkingHours ||
        key === SchedulePeriodsTypes.WorkingHours ||
        key === SchedulePeriodsTypes.ATC
      ) {
        result.push({ key, ...rest });
      }
      return result;
    },
    []
  );

export const getPeriodsBySegmentsRange = ({
  startDay,
  startHour,
  startSegment,
  endDay,
  endHour,
  endSegment,
}: ISelectionSegmentsRange): IPeriod[] => {
  const verticalDirection = startDay <= endDay ? Directions.Bottom : Directions.Top;
  const horizontalDirection =
    startHour < endHour || (startHour === endHour && startSegment <= endSegment)
      ? Directions.Right
      : Directions.Left;
  const dayDifference = Math.abs(startDay - endDay);
  return [...Array(dayDifference).keys(), 'someday:)'].map(
    (someDay: string | number, index: number) => {
      const limitStartDay =
        verticalDirection === Directions.Bottom ? startDay + index : startDay - index;
      const segmentStartMinutes =
        horizontalDirection === Directions.Right ? startSegment * 15 : startSegment * 15 + 14;
      const segmentEndMinutes =
        horizontalDirection === Directions.Right ? endSegment * 15 + 14 : endSegment * 15;
      const limitFrom = (limitStartDay * 24 + startHour) * 60 + segmentStartMinutes;
      const limitTo = (limitStartDay * 24 + endHour) * 60 + segmentEndMinutes;
      const start = horizontalDirection === Directions.Right ? limitFrom : limitTo;
      const end = horizontalDirection === Directions.Right ? limitTo : limitFrom;

      return { start, end };
    }
  );
};

export const getDayFiller =
  (limits: IPeriod[], type: SchedulePeriodsTypes) => (day: IDay, dayIndex: number) => ({
    ...day,
    hours: day.hours.map((hour: IHour, hourIndex: number) => {
      const hourWeekIndex = (dayIndex * 24 + hourIndex) * 60;
      const minutes = [...hour.minutes];
      limits.forEach(({ start: limitStart, end: limitEnd }) => {
        minutes.forEach((minute, minuteIndex) => {
          const minuteWeekIndex = hourWeekIndex + minuteIndex;
          if (minuteWeekIndex >= limitStart && minuteWeekIndex <= limitEnd) {
            minutes[minuteIndex] = type;
          }
        });
      });
      return {
        ...hour,
        minutes,
      };
    }),
  });

export const formatMinutesToHHmm = (minutes: number) => {
  if (Number.isFinite(minutes)) {
    const isLessThanHour = minutes < 60;
    const lastHourMinutes = minutes % 60;
    const h = `${isLessThanHour ? 0 : (minutes - lastHourMinutes) / 60}`.padStart(2, '0');
    const m = `${isLessThanHour ? minutes : lastHourMinutes}`.padStart(2, '0');
    return `${h}:${m}`;
  }
};

export const formatHHmmToMinutes = (hhmm: string) => {
  const match = /^(\d{1,2}):(\d{1,2})/.exec(hhmm);
  if (match) {
    const [, hours, minutes] = match;
    return Number(hours) * 60 + Number(minutes);
  }
};
