import {
  IDepartment,
  IDepOrEmployees,
  IDomainNumber,
  IEmployee,
  NotificationTypes,
} from '@components/typings/interfaces';
import { ErrorOption } from 'react-hook-form';

export interface IMissedCalls {
  allNumbers: boolean;
  department: IDepartment;
  domainId: number;
  domainNumber: IDomainNumber;
  employee: IEmployee;
  id: number;
  receivers: IReceivers[];
}

export interface IReceivers {
  department: IDepartment;
  employee: IEmployee;
  external?: string;
  id: number;
  missedCallSourceId: number;
  notificationTypes?: NotificationTypes[];
  allNumbers?: boolean;
}

export interface INotificationReceivers {
  departmentId?: number | null;
  employeeId?: number | null;
  department?: IDepartment;
  employee?: IEmployee;
  external?: string;
  notificationTypes?: NotificationTypes[];
}

export interface IRecipientCardType {
  onChange: (recipientItem: IDepOrEmployees, i: number) => void;
  recipient: IDepOrEmployees | null;
  cardIndex: number;
  data: IDepOrEmployees[];
  onDelete: (i: number) => void;
  name: string;
  error: ErrorOption;
  userDomain?: string;
}

export interface INotificationData {
  allNumbers: boolean;
  domainNumberId: number | null;
  departmentId: number | null;
  employeeId: number | null;
  receivers: INotificationReceivers[];
}

export interface INotificationsFilterParams {
  selectAll?: boolean;
  domainNumber?: boolean;
  department?: boolean;
  employee?: boolean;
}

export enum NotificationSource {
  phoneNumber = 'phoneNumber',
  employeeOrDep = 'employeeOrDep',
}
