import React, { useCallback, useState } from 'react';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DELETE_CALLS_RESTRICTIONS_MUTATION } from '@/client/mutations';
import { CALLS_RESTRICTIONS_QUERY } from '@/client/queries';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import ControlButtons from '@shared/components/ControlButtons';
import { FormProvider, useForm } from 'react-hook-form';
import MessageDialog from '@shared/components/MessageDialog';
import { ICellProps } from '@components/LazyTable';
import { CallsRestrictionsQuery } from '@/client/generated/graphql';
import { useCallsRestrictionsStyles } from '../CallsRestrictions.styles';

export const CallRestrictionsActionsCell = ({
  item,
}: ICellProps<CallsRestrictionsQuery['getCallRestrictions'][number]>) => {
  const classes = useCallsRestrictionsStyles();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const formMethods = useForm();
  const { handleSubmit } = formMethods;
  const [deleteCallsRestrictions, { loading: deleteLoading }] = useMutation(
    DELETE_CALLS_RESTRICTIONS_MUTATION,
    {
      refetchQueries: [
        {
          query: CALLS_RESTRICTIONS_QUERY,
        },
      ],
    }
  );

  function handleEditRestrictionClick(id: number) {
    searchParams.set('id', String(id));
    setSearchParams(searchParams);
  }

  const handleSubmitForm = useCallback(() => {
    deleteCallsRestrictions({ variables: { data: { id: item?.id } } }).then(() => {
      setOpen(false);
    });
  }, [deleteCallsRestrictions, item]);

  if (item) {
    const { id, employee, department } = item;
    const getEntityName = () => {
      if (department?.name) {
        return 'DELETE_CALLS_RESTRICTIONS_DEPARTMENT';
      }
      if (employee?.user?.name) {
        return 'DELETE_CALLS_RESTRICTIONS_EMPLOYEE';
      }
      return '';
    };

    return (
      <div className={classes.tableButtonCell}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.actionRowButton}
            variant={'secondary'}
            color={'error'}
            onClick={() => setOpen(true)}
          >
            <TrashIcon />
          </Button>
          <Button
            className={classes.actionRowButton}
            variant={'secondary'}
            onClick={() => handleEditRestrictionClick(id)}
          >
            <EditIcon />
          </Button>
        </div>
        <MessageDialog
          title={translate('ARE_YOU_SURE')}
          isOpen={open}
          onCancel={() => setOpen(false)}
          contentClass={classes.defaultElementWidth21}
          renderContent={
            <FormProvider {...formMethods}>
              <form
                id={'remove-restriction-number'}
                action={''}
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div className={classes.modalTextContainer}>
                  <Translate
                    i18nKey={'DELETE_CALLS_RESTRICTIONS_WARNING'}
                    values={{
                      entity: translate(getEntityName()),
                      name: employee?.user?.name || department?.name,
                    }}
                    components={{
                      b: <br />,
                      t: <Typography color="tertiary900" type={'text3'} />,
                      p: <Typography color="primary700" type={'text3'} />,
                    }}
                  />
                </div>
                <ControlButtons
                  confirmTitle={'REMOVE'}
                  cancelTitle={'CANCEL'}
                  cancelVariant="secondary"
                  onConfirmClick={handleSubmitForm}
                  onCancelClick={() => setOpen(false)}
                  rootClass={classes.dialogActions}
                  loading={deleteLoading}
                  justifyContent={'start'}
                  flexDirection={'row-reverse'}
                  confirmColor={'error'}
                  small
                />
              </form>
            </FormProvider>
          }
        />
      </div>
    );
  }

  return <div />;
};
