import { makeStyles } from '@material-ui/core';

export const usePreloaderStyle = makeStyles(({ color: { primary, tertiary, danger, base } }) => ({
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  root: {
    borderRadius: '50%',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'inline-block',
    '&:after, &:before': {
      position: 'absolute',
      display: 'block',
      content: '""',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: 'solid 3px',
    },
    '&:after': {
      borderColor: 'transparent',
      animation: '$rotation linear 1s infinite',
    },
  },
  success: {
    '&:before': {
      borderColor: tertiary['200'],
    },
    '&:after': {
      borderRightColor: primary['700'],
    },
  },
  danger: {
    '&:before': {
      borderColor: base,
    },
    '&:after': {
      borderRightColor: danger['600'],
    },
  },
  sizeDefault: {
    width: '2em',
    height: '2em',
  },
  sizeSmall: {
    width: '1em',
    height: '1em',
  },
  sizeMedium: {
    width: '1.5em',
    height: '1.5em',
  },
  sizeLarge: {
    width: '2.5em',
    height: '2.5em',
  },
}));
