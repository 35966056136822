export enum ConfirmAction {
  Cancel = 'cancel',
  Finish = 'finish',
  Edit = 'edit',
}

export interface IConfirmState {
  isBlocking: boolean;
  action: ConfirmAction;
}

export type ConfirmDialogProps = {
  isBlocked: boolean;
  onSaveChanges: () => void;
  onNavigationBlocked?: (path: string) => void;
};

export type ConfirmDialogState = {
  open: boolean;
  path: string | null;
  canLeave: boolean;
};
