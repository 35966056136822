import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';

export interface IHour {
  id: number;
  minutes: SchedulePeriodsTypes[];
}

export interface IDay {
  id: number;
  hours: IHour[];
  titleCode: string;
  abbreviationCode: string;
  weekend?: boolean;
}

export interface ISchedulePeriod {
  key: SchedulePeriodsTypes;
  id?: string;
  name?: string;
  titleCode: string;
  color: 'primary' | 'links' | 'error' | 'success' | 'warning' | undefined;
}

export interface IPeriod {
  start: number;
  end: number;
}

export enum Directions {
  Right = 'right',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
}

export interface ISelectionSegmentsRange {
  startDay: number;
  startHour: number;
  startSegment: number;
  endDay: number;
  endHour: number;
  endSegment: number;
}
