import { makeStyles } from '@material-ui/core/styles';

const colorStyleConstants = makeStyles(
  ({ color: { tertiary, links, success, danger, primary, secondary } }) => ({
    success600: {
      color: success[600],
    },
    links600: {
      color: links[600],
    },
    black600: {
      color: 'black',
    },
    danger600: {
      color: danger[600],
    },
    gray600: {
      color: tertiary[600],
    },
    primary600: {
      color: primary[600],
    },
    secondary600: {
      color: secondary[600],
    },
  })
);

export default colorStyleConstants;
