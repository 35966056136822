/* eslint-disable no-param-reassign */
type TariffInfo = {
  tariff?: {
    setupFee?: number | null;
    monthlyFee?: number | null;
    baseSetupFee?: number | null;
    baseMonthlyFee?: number | null;
  } | null;
};

export const getNumbersPrices = (arr: TariffInfo[]) => {
  const { fixedPrice, monthlyPrice, baseSetupPrice, baseMonthPrice } = arr.reduce(
    (
      result: {
        fixedPrice: number;
        monthlyPrice: number;
        baseSetupPrice: number;
        baseMonthPrice: number;
      },
      item
    ) => {
      if (item.tariff) {
        result.fixedPrice += item.tariff?.setupFee || 0;
        result.monthlyPrice += item.tariff?.monthlyFee || 0;
        result.baseSetupPrice += item.tariff?.baseSetupFee || 0;
        result.baseMonthPrice += item.tariff?.baseMonthlyFee || 0;
      }
      return result;
    },
    { fixedPrice: 0, monthlyPrice: 0, baseSetupPrice: 0, baseMonthPrice: 0 }
  );

  return { fixedPrice, monthlyPrice, baseSetupPrice, baseMonthPrice };
};
