import React, { FunctionComponent } from 'react';
import { ITagProps } from '@components/Tag/Tag.interfaces';
import { useTagStyle } from '@components/Tag/Tag.styles';
import { XIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';

export const Tag: FunctionComponent<ITagProps> = ({ text, onClose, color }) => {
  const classes = useTagStyle();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div className={classes.tag}>
      <div className={classes.tagText}>
        <Typography typeof={'text3'} color={color || 'tertiary900'}>
          {text}
        </Typography>
      </div>
      <Button className={classes.tagClose} variant={'tertiary'} onClick={() => handleClose()}>
        <XIcon />
      </Button>
    </div>
  );
};

export default Tag;
