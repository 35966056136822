import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormErrors } from '@components/common/formErrors.hooks';
import { IIncomingNumberScenario } from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import Typography from '@shared/components/Typography';
import SwitchField from '@shared/components/SwitchField';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import RadioGroup from '@shared/components/RadioGroup';
import { FormControlLabel, debounce } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import StyledMessageBlock from '@/components/StyledMessageBlock/StyledMessageBlock';
import { useQuery } from '@apollo/client';
import { GET_INTEGRATIONS_QUERY, USER_QUERY } from '@/client/queries';
import { IntegrationStatus } from '@/client/generated/graphql';
import { useSmartGreetingStyles } from './SmartGreeting.styles';
import { useTimeSlots } from './hooks/useTimeSlots.hooks';
import { ISmartGreetingFormData } from './SmartGreeting.interfaces';
import { ScenariosTypes } from '../../IncomingNumber.interfaces';

export interface ISmartGreetingProps {
  settings: IIncomingNumberScenario;
  onSave: (node: IIncomingNumberScenario) => void;
  onRemove: () => void;
  onCancel: () => void;
  onChanges: () => void;
}

export const SmartGreeting: FunctionComponent<ISmartGreetingProps> = ({
  settings,
  onSave,
  onCancel,
  onChanges,
}: ISmartGreetingProps) => {
  const classes = useSmartGreetingStyles();
  const [translate] = useTranslation();
  const { smartGreetingTimeSlots, disabledIconColor } = useTimeSlots();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { data: integrationListData, loading: loadingIntegrations } =
    useQuery(GET_INTEGRATIONS_QUERY);
  const integrationsList = integrationListData?.getIntegrations;
  const hasIntegrations = !!integrationsList?.length;
  const renderingIntegrationsArray = useMemo(
    () =>
      integrationsList?.filter(
        (integration) =>
          integration?.id === settings.integrationId ||
          integration?.status !== IntegrationStatus.Inactive
      ) || [],
    [integrationsList, settings]
  );
  const hasRenderingIntegrations = !!renderingIntegrationsArray?.length;

  const formMethods = useForm<ISmartGreetingFormData>({
    defaultValues: {
      messageType: settings.messageType || 'TEXT',
      isMessageActive: true,
      fileUrl: settings.fileUrl,
      isCallingByTime: settings.id ? settings.isGreetingByTimeOfDay : true,
      isCallingByClientName: settings.id ? settings.isGreetingByName : true,
      integrationId: String(settings.integrationId),
      message: settings.id ? settings.message : '',
    },
  });
  const { handleSubmit, watch, setValue } = formMethods;
  const isCallingByClientName = watch('isCallingByClientName');
  const isCallingByTime = watch('isCallingByTime');
  const selectedIntegration = watch('integrationId');
  const callingByTimeColor = isCallingByTime ? 'tertiary900' : 'tertiary300';
  const callingByClientNameColor =
    isCallingByClientName && hasRenderingIntegrations ? 'tertiary900' : 'tertiary300';
  const userName = userData?.user?.name?.split(' ')[0];
  const [message, setMessage] = useState<string>(
    settings?.id ? settings?.message : translate('SMART_GREETING_TEMPLATE')
  );
  const now = new Date().getHours();

  const greetingMessage = useMemo(() => {
    const greetingPhrase = () => {
      if (now >= 6 && now < 12) return translate('GOOD_MORNING');
      if (now >= 12 && now < 18) return translate('GOOD_AFTERNOON');
      if (now >= 18 && now <= 23) return translate('GOOD_EVENING');
      if (now >= 0 && now < 6) return translate('GOOD_NIGHT');
      return '';
    };

    if (isCallingByClientName && isCallingByTime) return `${greetingPhrase()}, ${userName}!`;
    if (isCallingByClientName) return `${userName}!`;
    if (isCallingByTime) return `${greetingPhrase()}!`;
    return '';
  }, [isCallingByClientName, isCallingByTime, now, translate, userName]);

  const textAreaTitle = useMemo(() => {
    if (isCallingByTime && isCallingByClientName)
      return `[${translate('CONTACT_ON_TIME')}], [${translate('NAME').toLowerCase()}] !`;
    if (isCallingByTime) return `[${translate('CONTACT_ON_TIME')}]!`;
    if (isCallingByClientName) return `[${translate('NAME').toLowerCase()}] !`;
    return '';
  }, [isCallingByClientName, isCallingByTime, translate]);

  useEffect(() => {
    if ((!hasIntegrations || !hasRenderingIntegrations) && !loadingIntegrations)
      setValue('isCallingByClientName', false);
    if (!isCallingByClientName && selectedIntegration) setValue('integrationId', undefined);
    if (
      !loadingIntegrations &&
      isCallingByClientName &&
      !selectedIntegration &&
      renderingIntegrationsArray.length === 1
    ) {
      setValue('integrationId', String(renderingIntegrationsArray[0]?.id));
    }
  }, [
    hasIntegrations,
    hasRenderingIntegrations,
    isCallingByClientName,
    loadingIntegrations,
    renderingIntegrationsArray,
    selectedIntegration,
    settings,
    setValue,
  ]);

  const handleChanges = useCallback(() => {
    if (typeof onChanges === 'function') {
      onChanges();
    }
  }, [onChanges]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMessageChanges = useCallback(
    debounce((messageText: string) => {
      handleChanges();
      setMessage(messageText);
    }, 400),
    [handleChanges]
  );

  const handleFormSubmit = (data: ISmartGreetingFormData) => {
    if (!message.length) return;
    onSave({
      ...data,
      type: ScenariosTypes.SmartGreeting,
      isGreetingByTimeOfDay: data.isCallingByTime,
      isGreetingByName: data.isCallingByClientName,
      integrationId: Number(data.integrationId),
      message,
      isMessageActive: true,
      fileUrl: undefined,
    });
    onCancel();
  };

  useFormErrors(undefined, formMethods);

  const renderIntegrationRadioLabel = (type: string, name?: string | null) =>
    `${translate(`CRM_${type}`)}${name ? ` — ${name}` : ''}`;

  const renderTimeSlots = () => (
    <Flex className={classes.marginTop1} alignItems={'flexStart'} direction={'column'}>
      {smartGreetingTimeSlots.map(({ Icon, to, from, greetingCode, iconColor }) => (
        <Flex
          className={clsx(classes.marginTop05, classes.defaultElementWidth21)}
          key={`item-${greetingCode}`}
          alignItems={'center'}
          justifyContent={'spaceBetween'}
        >
          <Flex className={classes.defaultElementWidth12} alignItems={'center'}>
            <Icon style={{ color: isCallingByTime ? iconColor : disabledIconColor }} />
            <Typography
              className={classes.marginLeft1}
              type={'text3'}
              color={callingByTimeColor}
            >{`${translate('FROM').toLowerCase()} ${from} ${translate(
              'UP_TO'
            ).toLowerCase()} ${to}`}</Typography>
          </Flex>
          <Flex className={classes.defaultElementWidth12} alignItems={'flexStart'}>
            <div>{'—'}</div>
            <Typography
              className={clsx(classes.greetingText, classes.marginLeft1)}
              type={'text3'}
              color={callingByTimeColor}
              medium
            >{`«${translate(greetingCode)}!»`}</Typography>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );

  const renderIntegrationsControls = () =>
    renderingIntegrationsArray.length ? (
      <RadioGroup name={'integrationId'} defaultValue={String(renderingIntegrationsArray[0].id)}>
        {renderingIntegrationsArray.map(({ type, name, id, status }, index: number) => (
          <div className={classes.marginTop05} key={`smart-radio-${index}`}>
            <Flex direction={'column'}>
              <FormControlLabel
                value={String(id)}
                control={
                  <Radio
                    color={'primary'}
                    disabled={!isCallingByClientName || status === IntegrationStatus.Inactive}
                  />
                }
                label={renderIntegrationRadioLabel(type, name)}
              />
              {status === IntegrationStatus.Inactive && (
                <Typography type={'text3'} color={'danger600'}>
                  {translate('INACTIVE_INTEGRATION')}
                </Typography>
              )}
            </Flex>
          </div>
        ))}
      </RadioGroup>
    ) : (
      <Flex className={classes.marginTop05} direction={'column'} alignItems={'center'}>
        <Typography type={'text3'} color={'danger600'}>
          {translate('INACTIVE_ALL_INTEGRATIONS')}
        </Typography>
      </Flex>
    );

  return (
    <div className={classes.root}>
      <FormProvider {...formMethods}>
        <form id={'smart-greeting'} action={''} onSubmit={handleSubmit(handleFormSubmit)}>
          <Flex direction={'column'}>
            <Flex>
              <SwitchField
                name={'isCallingByTime'}
                customLabel={
                  <Typography type={'text2'} color={callingByTimeColor}>
                    {translate('CALLING_BY_DAY_TIME')}
                  </Typography>
                }
                onChanges={handleChanges}
              />
            </Flex>
            <div className={classes.marginTop1}>
              <Typography type={'text3'} color={callingByTimeColor}>
                {translate('CALLING_BY_DAY_TIME_DESCRIPTION')}
              </Typography>
            </div>
            {renderTimeSlots()}
            <Flex className={classes.marginTop2}>
              <SwitchField
                name={'isCallingByClientName'}
                customLabel={
                  <Typography type={'text2'} color={callingByClientNameColor}>
                    {translate('CALLING_BY_CLIENT_NAME')}
                  </Typography>
                }
                disabled={!hasIntegrations || !hasRenderingIntegrations}
                onChanges={handleChanges}
              />
            </Flex>
            <Typography
              className={classes.marginTop1}
              type={'text3'}
              color={callingByClientNameColor}
            >
              {translate('CALLING_BY_CLIENT_NAME_DESCRIPTION')}
            </Typography>
            {hasIntegrations ? (
              <div>
                {integrationsList.length === 1 ? (
                  <div className={classes.marginTop1}>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {translate('SMART_GREETING_ONE_INTEGRATION')}
                    </Typography>
                    <Typography type={'text3'} color={'tertiary900'} medium>{`${translate(
                      `CRM_${integrationsList[0].type}`
                    )} ${integrationsList[0].name}`}</Typography>
                  </div>
                ) : (
                  <div className={classes.marginTop1}>
                    <Typography type={'text3'} color={callingByClientNameColor} medium>
                      {translate('CHOOSE_CRM')}
                    </Typography>
                    {renderIntegrationsControls()}
                  </div>
                )}
              </div>
            ) : (
              <div className={classes.smartGreetingWithoutIntegrationsBlock}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('SMART_GREETING_WITHOUT_INTEGRATIONS')}
                </Typography>
              </div>
            )}
            <StyledMessageBlock
              renderedTitle={
                <Typography type={'text3'} color={'link600'}>
                  {textAreaTitle}
                </Typography>
              }
              messageValue={message}
              greetingMessage={`${greetingMessage}`}
              onChanges={handleMessageChanges}
            />
          </Flex>
        </form>
      </FormProvider>
    </div>
  );
};

export default SmartGreeting;
