import { makeStyles } from '@material-ui/core/styles';

export const useMessageFieldsStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.25em',
  },
  radioButton: {
    paddingRight: '0.5em',
  },
  message: {
    marginTop: '0.5em',
  },
  uploadField: {
    marginTop: '1em',
  },
  audioControlsSection: {
    width: '100%',
    display: 'flex',
    marginLeft: '0.7em',
    alignItems: 'center',
  },
  audioSectionControlButton: {
    padding: '0.25em 0.5em',
    width: '7.875em',
    height: '2em',
  },
  audioSectionControlButtonIcon: {
    marginRight: '0.5em',
  },
  audioControlButton: {
    padding: '0.5em 0.5em',
    marginLeft: '0.5em',
  },
}));
