import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import { clearAuth, getStorageItem, setStorageItem } from '@components/storage/storage';
import { NextLink } from '@apollo/client/link/core';
import { AUTHENTICATION_STATE_QUERY, WIDE_SIDEBAR_STATE } from '@components/client/queries';
import {
  PARTNERS_REFRESH_TOKEN_MUTATION,
  REFRESH_TOKEN,
  SIGNOUT,
} from '@components/client/mutations';
import * as Sentry from '@sentry/react';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { cache } from './cache';

let isRefreshing = false;
// eslint-disable-next-line @typescript-eslint/ban-types
let pendingRequests: Function[] = [];

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
    query: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
    mutate: {},
  },
  resolvers: {},
});

const clearAuthData = async () => {
  clearAuth();
  await client.resetStore();
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export const pleaseSignIn = (msg?: string) => {
  clearAuth();
  client.writeQuery({
    query: AUTHENTICATION_STATE_QUERY,
    data: {
      isAuthenticated: false,
      role: undefined,
    },
  });
  pendingRequests = [];
};

export const getRoleFromToken = (token?: string) => {
  const dataPart = token?.split('.')?.[1];

  if (dataPart?.length) {
    try {
      const data = JSON.parse(window.atob(dataPart));
      if (data && typeof data === 'object' && data !== null && typeof data.role === 'string') {
        return data.role;
      }
    } catch (e) {
      const err = Error(`Could not get role from token, error: ${e}`);
      console.error(err);
      Sentry.captureException(err);
    }
  }

  return undefined;
};

export const refreshAuthenticationState = async () => {
  let token: string | undefined = getStorageItem('token');
  let role: string | undefined;

  if (window.location.pathname.match(/\/restore-password\/.*/)) {
    token = undefined;
  } else {
    role = getRoleFromToken(token);
    if (!role) {
      pleaseSignIn();
      return;
    }
  }

  cache.writeQuery({
    query: AUTHENTICATION_STATE_QUERY,
    data: {
      isAuthenticated: !!token,
      role,
    },
  });
};

const checkInitialTokenPresenceOnApplicationStart = () => {
  const token: string | undefined = getStorageItem('token');
  let role: string | undefined;
  // Here we are defining if it seems that user is authenticated.
  // But if something is wrong with token, the first request should
  // trigger error link and return user to un-authenticated state.
  let isAuthenticated = false;

  if (window.location.pathname.match(/\/restore-password\/.*/)) {
    isAuthenticated = false;
  } else {
    role = getRoleFromToken(token);
    if (role) {
      isAuthenticated = true;
    }
  }

  cache.writeQuery({
    query: AUTHENTICATION_STATE_QUERY,
    data: {
      isAuthenticated,
      role,
    },
  });
};

export const setWideSidebarState = (isWide: boolean) => {
  cache.writeQuery({
    query: WIDE_SIDEBAR_STATE,
    data: {
      isWide,
    },
  });
};

export const refreshTokensAndRepeatRequest = (forward: NextLink, operation: Operation) => {
  if (!isRefreshing) {
    isRefreshing = true;

    return fromPromise(
      client
        .mutate({
          mutation: process.env.IS_PARTNER_APPLICATION
            ? PARTNERS_REFRESH_TOKEN_MUTATION
            : REFRESH_TOKEN,
          variables: {},
        })
        .then(({ data }) => {
          if (data) {
            const token = process.env.IS_PARTNER_APPLICATION
              ? data.partnersRefreshToken.accessToken
              : data.refreshToken.token;
            const refreshToken = process.env.IS_PARTNER_APPLICATION
              ? data.partnersRefreshToken.refreshToken
              : data.refreshToken.refreshToken;

            setStorageItem('token', token);
            setStorageItem('refreshToken', refreshToken);
            resolvePendingRequests();
            isRefreshing = false;
          } else {
            pleaseSignIn();
          }
        })
        .catch(() => {
          pleaseSignIn();
        })
    ).flatMap(() => {
      resolvePendingRequests();
      isRefreshing = false;

      return forward(operation);
    });
  }

  return fromPromise(
    new Promise((resolve) => {
      pendingRequests.push(() => resolve(undefined));
    })
  ).flatMap(() => forward(operation));
};

const parseHeaders = (rawHeaders: string): Headers => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');

  preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
    const parts = line.split(':');
    const key = parts.shift()?.trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (
  input: RequestInfo,
  init: RequestInit & {
    onProgress?: ({ loaded, total }: ProgressEvent) => void;
    onAbortPossible?: (abortHandler: () => void) => void;
  }
) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const headers = parseHeaders(xhr.getAllResponseHeaders() || '');
      const opts: Partial<Response> = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers,
        url: 'responseURL' in xhr ? xhr.responseURL : headers.get('X-Request-URL') || undefined,
      };
      const body = 'response' in xhr ? xhr.response : (xhr as XMLHttpRequest).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(init?.method || 'get', input as string, true);

    // eslint-disable-next-line @typescript-eslint/ban-types
    Object.keys(init.headers as object).forEach((key) => {
      if ((init?.headers as { [key: string]: string })?.[key]) {
        const value = (init?.headers as { [key: string]: string })[key];
        if (value) {
          xhr.setRequestHeader(key, value);
        }
      }
    });

    if (xhr.upload) {
      xhr.upload.onprogress = init?.onProgress || null;
    }
    if (init?.onAbortPossible) {
      init?.onAbortPossible(() => {
        xhr.abort();
      });
    }

    xhr.send(init.body as any);
  });

const customFetch = (
  input: RequestInfo,
  init: RequestInit & {
    onProgress?: ({ loaded, total }: ProgressEvent) => void;
    onAbortPossible?: (abortHandler: () => void) => void;
  }
) => {
  if (init?.onProgress) {
    return uploadFetch(input, init);
  }
  return fetch(input, init);
};

const httpLink = createUploadLink({
  uri: `${process.env.API_URL}/graphql`,
  fetch: customFetch as WindowOrWorkerGlobalScope['fetch'],
  headers: { 'Apollo-Require-Preflight': 'true' },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const shouldUseRefreshToken =
    operation?.operationName === 'RefreshToken' ||
    (process.env.IS_PARTNER_APPLICATION === 'true' &&
      operation?.operationName === 'PartnersRefreshToken');

  const token = shouldUseRefreshToken ? getStorageItem('refreshToken') : getStorageItem('token');

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
    },
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }: ErrorResponse) => {
  if (networkError) {
    globalNotification$.show('warn', 'NETWORK_ERROR');
  }

  if (graphQLErrors) {
    const authenticationError = graphQLErrors.find(
      ({ extensions: { code = '' } = { code: undefined } }: GraphQLError) =>
        code === 'UNAUTHENTICATED'
    );
    if (authenticationError) {
      if (authenticationError.message === 'REFRESH_TOKEN_NOT_EXIST') {
        pleaseSignIn();
      }
      if (
        authenticationError.message === 'ACCESS_TOKEN_EXPIRED' &&
        getStorageItem('refreshToken') &&
        operation.operationName !== 'RefreshToken'
      ) {
        // eslint-disable-next-line consistent-return
        return refreshTokensAndRepeatRequest(forward, operation);
      }

      const isThereAuthTokenError = graphQLErrors.some((error) => {
        const extensions = error.extensions as { exception?: { message?: string } };
        return extensions.exception?.message === 'No auth token';
      });
      if (!isThereAuthTokenError) {
        globalNotification$.show('warn', 'PLEASE_SIGN_IN');
      }

      clearAuth();
      refreshAuthenticationState();
    }
  }
  return undefined;
});

export const signout = async (): Promise<void> => {
  try {
    await client.mutate({
      mutation: SIGNOUT,
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
  await clearAuthData();
};

export const omitTypename = (data: object): object =>
  JSON.parse(JSON.stringify(data), (key: string, value: unknown) =>
    key === '__typename' ? undefined : value
  );

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
client.setLink(from([errorLink, authMiddleware, httpLink]));

checkInitialTokenPresenceOnApplicationStart();

client.onResetStore(refreshAuthenticationState);

export default client;
