import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ControlButtons from '@shared/components/ControlButtons';
import CheckboxField from '@shared/components/CheckboxField';
import Typography from '@shared/components/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { XIcon, SearchIcon, ChevronUpIcon, ChevronDownIcon } from '@shared/assets/images/icons';
import { formatPhone } from '@components/utils';
import { Button } from '@shared/components/Button/Button';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import Preloader from '@shared/components/Preloader';
import { ClickAwayListener, InputAdornment, TextField } from '@material-ui/core';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { useSearchParams } from 'react-router-dom';
import { useStateWithMemo } from '@/utils';
import { IFilterByNumbersProps } from './FilterByNumbers.interfaces';
import { useFilterByNumbersStyle } from './FilterByNumbers.styles';

export const FilterByNumbers: FunctionComponent<IFilterByNumbersProps> = ({
  data,
  onFilterChange,
  loading,
  defaultValue,
  maxSelectedLength = 1,
}) => {
  const classes = useFilterByNumbersStyle();
  const [translate] = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterText, changeFilterText] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const formMethods = useForm();

  const { stateValues, setStateValues, savePrevState, restoreState } = useStateWithMemo<{
    [key: number]: string;
  }>();
  const selectedValuesLength = stateValues ? Object.keys(stateValues).length : 0;
  const selectedNumbersId = String(Object.keys(stateValues || {}));

  const filteredData = useMemo(() => {
    if (!data) return [];
    return data.filter((blockItem) => {
      if (blockItem.phone !== null) {
        if (filterText.length) {
          return blockItem.phone.includes(filterText);
        }
        return blockItem;
      }
      return null;
    });
  }, [data, filterText]);

  const handleSubmit = useCallback(() => {
    setOpen(false);
    setIsFilterActive(true);
    if (defaultValue) onFilterChange(defaultValue);
    if (onFilterChange && stateValues) {
      onFilterChange(stateValues);
      searchParams.set('numbersId', selectedNumbersId);
      setSearchParams(searchParams);
    }
  }, [defaultValue, onFilterChange, searchParams, selectedNumbersId, stateValues, setSearchParams]);

  const handleChooseValue = (id: number, value: string) => {
    setStateValues((prevState) => {
      const newPrevState = { ...prevState };
      const prevStateObject = prevState?.[id];
      if (!prevStateObject) {
        return { ...newPrevState, [id]: value };
      }
      delete newPrevState[id];
      return newPrevState;
    });
  };

  const handleFilterChange = (value: string) => {
    changeFilterText(value);
  };

  function handleOpen() {
    savePrevState();
    setOpen(!isOpen);
  }

  function handleCancelClick() {
    restoreState();
    setOpen(false);
  }

  function setCheckedStatus(id: number) {
    return !!(stateValues && stateValues[id]);
  }

  function handleFilterReset() {
    setStateValues({});
    setIsFilterActive(false);
    onFilterChange(defaultValue || {});
  }

  function handleClickAway() {
    if (filterText) {
      changeFilterText('');
    }
    restoreState();
    setOpen(false);
  }

  function setDisabledCheckbox(id: number) {
    if (stateValues) {
      return selectedValuesLength >= maxSelectedLength && !stateValues[id];
    }
    return false;
  }

  useEffect(() => {
    if (!stateValues && defaultValue) {
      setStateValues(defaultValue);
      handleSubmit();
    }
  }, [defaultValue, handleSubmit, stateValues, setStateValues]);

  return (
    <div className={classes.filterContainer}>
      <FormProvider {...formMethods}>
        <FormFieldRhfUncontrolled
          name={'filterByNumbers'}
          className={classes.filterTrigger}
          classes={{ root: classes.input }}
          onClick={handleOpen}
          InputProps={{
            endAdornment: isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />,
            classes: { input: classes.input },
            readOnly: true,
          }}
          value={
            isFilterActive
              ? `${translate('CHOOSEN_NUMBERS')}: ${selectedValuesLength}`
              : `${translate('FILTER_BY_NUMBERS')}`
          }
        />
        {isOpen && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.filterWrapper}>
              <TextField
                name={'search'}
                type={'text'}
                placeholder={translate('SEARCH')}
                InputProps={{
                  classes: { root: classes.searchInputRoot },
                  disableUnderline: true,
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      {filterText.length ? (
                        <div className={classes.clearIcon}>
                          <XIcon onClick={() => changeFilterText('')} />
                        </div>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
                value={filterText}
                onChange={({ target: { value = '' } = {} }) => handleFilterChange(value)}
              />
              <div className={classes.optionsWrapper}>
                {loading ? (
                  <Flex justifyContent={'center'} alignItems={'center'}>
                    <Preloader size={'large'} />
                  </Flex>
                ) : (
                  filteredData.map(({ phone, id }, index) => (
                    <CheckboxField
                      key={`el-${index}`}
                      name={`el-${index}`}
                      className={classes.checkboxControl}
                      checked={setCheckedStatus(id)}
                      label={formatPhone(phone)}
                      onChange={() => handleChooseValue(id, phone)}
                      disabled={setDisabledCheckbox(id)}
                    />
                  ))
                )}
              </div>
              <Button
                classes={{
                  root: clsx(classes.resetButton, {
                    [classes.resetButtonDisabled]: !selectedValuesLength,
                  }),
                }}
                title={translate('RESET')}
                variant={'tertiary'}
                onClick={handleFilterReset}
                disabled={!selectedValuesLength}
              />
              <div className={classes.counterRow}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {`${translate('CHOOSEN_NUMBERS')}:`}
                </Typography>
                <Typography
                  type={'text3'}
                  color={!selectedValuesLength ? 'danger600' : 'tertiary900'}
                >
                  {`${selectedValuesLength} ${translate(
                    'OUT_OF'
                  ).toLowerCase()} ${maxSelectedLength}`}
                </Typography>
              </div>
              <ControlButtons
                rootClass={classes.btnWrapper}
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelUnderline
                onConfirmClick={handleSubmit}
                onCancelClick={handleCancelClick}
                confirmDisable={!selectedValuesLength}
                justifyContent={'center'}
                rootConfirmStyles={classes.defaultElementWidth9}
              />
            </div>
          </ClickAwayListener>
        )}
      </FormProvider>
    </div>
  );
};

export default FilterByNumbers;
