import React, { FunctionComponent } from 'react';
import { FilterButtonIconTypes } from '@/components/ButtonFilter/ButtonFilter.interfaces';
import {
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  PhoneMissedIcon,
} from '@shared/assets/images/icons';

import { ICellProps } from '@components/LazyTable';
import { callTypeInterface, IHistoryList } from '../HistoryPage.interfaces';
import { useHistoryStyle } from '../HistoryPage.styles';

export const CallTypesCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const classes = useHistoryStyle();

  const callTypeIcons: callTypeInterface = {
    [FilterButtonIconTypes.All]: <PhoneIcon />,
    [FilterButtonIconTypes.Incoming]: <PhoneIncomingIcon className={classes.links} />,
    [FilterButtonIconTypes.IncomingMissed]: <PhoneIncomingIcon className={classes.danger} />,
    [FilterButtonIconTypes.Outgoing]: <PhoneOutgoingIcon className={classes.success} />,
    [FilterButtonIconTypes.OutgoingMissed]: <PhoneMissedIcon className={classes.warning} />,
  };

  if (item) {
    const { call } = item;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{callTypeIcons[call]}</>;
  }

  return <div />;
};
