import { Button } from '@shared/components/Button/Button';
import { TrashIcon } from '@shared/assets/images/icons';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import MessageDialog from '@shared/components/MessageDialog';
import Translate from '@shared/components/Translate';
import { useMutation } from '@apollo/client';
import { GET_BLACK_LIST_QUERY } from '@/client/queries';
import { REMOVE_FROM_BLACKLIST_MUTATION } from '@/client/mutations';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import { IBlackList } from '../SettingsBlackList.interfaces';
import { useSettingsBlackListStyles } from '../SettingsBlackList.styles';

export const BlackListActionsCell: FunctionComponent<ICellProps<IBlackList>> = ({ item }) => {
  const classes = useSettingsBlackListStyles();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [translate] = useTranslation();

  const [removeFromBlacklist, { loading }] = useMutation(REMOVE_FROM_BLACKLIST_MUTATION, {
    refetchQueries: [
      {
        query: GET_BLACK_LIST_QUERY,
        variables: {
          data: {
            search: null,
          },
        },
      },
    ],
  });

  const handleRemoveFromBlacklist = () => {
    removeFromBlacklist({
      variables: {
        data: {
          ids: Number(item?.id),
        },
      },
    }).then((res) => {
      setIsRemoveDialogOpen(false);
    });
  };

  const handleClosePopupDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  return (
    <>
      <Button
        variant={'secondary'}
        color={'error'}
        classes={{ root: classes.actionButton }}
        onClick={() => setIsRemoveDialogOpen(true)}
      >
        <TrashIcon />
      </Button>
      <MessageDialog
        contentClass={classes.removeNumberDialog}
        isOpen={isRemoveDialogOpen}
        title={translate('DELETE_EMPLOYEE_PROMPT')}
        onCancel={handleClosePopupDialog}
        renderContent={
          <>
            <div className={classes.removePopupText}>
              <Translate
                i18nKey={'NUMBER_WILL_BE_REMOVED'}
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  l: <br />,
                }}
              />
            </div>
            <ControlButtons
              justifyContent={'start'}
              flexDirection={'row-reverse'}
              confirmColor={'error'}
              cancelVariant="secondary"
              confirmTitle={'REMOVE'}
              cancelTitle={'CANCEL'}
              onConfirmClick={() => handleRemoveFromBlacklist()}
              onCancelClick={handleClosePopupDialog}
              loading={loading}
              small
            />
          </>
        }
      />
    </>
  );
};
