import SelectField from './SelectField';
import * as SelectFieldTypes from './SelectField.interfaces';

export type OptionsGroup<T> = SelectFieldTypes.OptionsGroup<T>;
export type ISelectFieldProps<T = Record<string, unknown>> = SelectFieldTypes.ISelectFieldProps<
  keyof T,
  keyof T,
  T
>;
export type ISelectFieldOptionProps<T = Record<string, unknown>> =
  SelectFieldTypes.ISelectFieldOptionProps<T>;
export type ISelectFieldOptionRendererProps<T = Record<string, unknown>> =
  SelectFieldTypes.ISelectFieldOptionRendererProps<T>;
export default SelectField;
