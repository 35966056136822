import React, { useCallback, useEffect, useState } from 'react';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CAN_REMOVE_DEPARTMENT_QUERY, DEPARTMENTS_QUERY } from '@/client/queries';
import { REMOVE_DEPARTMENT_MUTATION } from '@/client/mutations';
import { GraphQLError } from 'graphql';
import Typography from '@shared/components/Typography';
import { useDepartmentsStyles } from '@/features/Departments/Departments.styles';
import MessageDialog from '@shared/components/MessageDialog';
import { formatPhone } from '@components/utils';
import ControlButtons from '@shared/components/ControlButtons';
import clsx from 'clsx';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { Department } from '../Departments';

export const DepartmentActionsCell = ({ item }: ICellProps<Department>) => {
  const classes = useDepartmentsStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [removeDepartment, { error: errorRemoveDepartment, loading: loadingRemoveDepartment }] =
    useMutation(REMOVE_DEPARTMENT_MUTATION, {
      refetchQueries: [
        {
          query: DEPARTMENTS_QUERY,
        },
      ],
    });

  const [
    canRemoveDepartment,
    { loading: loadingCanRemoveDepartment, error: errorCanRemoveDepartment },
  ] = useLazyQuery(CAN_REMOVE_DEPARTMENT_QUERY, {
    onCompleted: () => {
      setOpen(true);
    },
    onError: () => {
      setOpen(true);
    },
  });

  useEffect(() => {
    if (errorRemoveDepartment) {
      setOpen(true);
    }
  }, [errorRemoveDepartment]);

  const handleDeleteButton = useCallback(() => {
    canRemoveDepartment({ variables: { id: Number(item?.id) } });
  }, [canRemoveDepartment, item]);

  const handleRemoveDepartment = useCallback(() => {
    if (loadingRemoveDepartment) return;
    removeDepartment({
      variables: {
        data: { id: Number(item?.id) },
      },
    });
  }, [item, removeDepartment, loadingRemoveDepartment]);

  const handleConfirmDialogClose = () => {
    setOpen(false);
  };

  const renderConfirmDialogContent = () => (
    <>
      <div className={classes.removeDepartmentCenterBlock}>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('ALL_DEPARTMENT_DATA')}
        </Typography>
        <Typography
          className={clsx(classes.defaultElementWidthFull, classes.textOverflow)}
          type={'text3'}
          color={'primary700'}
        >
          {item?.name || ''}
        </Typography>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('DELETE_DEPARTMENT_WARNING')}
        </Typography>
      </div>
      <ControlButtons
        confirmTitle={'REMOVE'}
        cancelTitle={'CANCEL'}
        cancelVariant="secondary"
        onConfirmClick={handleRemoveDepartment}
        onCancelClick={handleConfirmDialogClose}
        loading={loadingRemoveDepartment}
        rootClass={classes.removeDepartmentBtnBlock}
        justifyContent={'start'}
        flexDirection={'row-reverse'}
        confirmColor={'error'}
        small
      />
    </>
  );

  const renderUnableDeleteDialogContent = ({
    titleCode,
    hintCode,
    params,
  }: {
    titleCode: string;
    hintCode: string;
    params: { suffix?: string; numbers?: string[]; integrations?: string[] };
  }) => (
    <>
      <div className={classes.removeDepartmentCenterBlock}>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate(titleCode, params)}
        </Typography>
        <br />
        {params.numbers?.map((phone: string) => (
          <>
            <Typography key={phone} type={'text3'} color={'primary700'}>
              {formatPhone(phone)}
            </Typography>
            <br />
          </>
        ))}
        {params.integrations?.map((integration: string, index: number) => (
          <>
            <Typography key={`integration_${index}`} type={'text3'} color={'primary700'}>
              {integration}
            </Typography>
            <br />
          </>
        ))}
        {hintCode && (
          <Typography type={'text3'} color={'tertiary900'}>
            {translate(hintCode)}
          </Typography>
        )}
      </div>
      <Flex justifyContent={'flexEnd'}>
        <Button title={translate('CLOSE')} onClick={handleConfirmDialogClose} smallHeight />
      </Flex>
    </>
  );

  const renderDialogContent = () => {
    const error = errorCanRemoveDepartment || errorRemoveDepartment;
    if (error?.graphQLErrors?.length) {
      const errorDescription = error?.graphQLErrors.reduce(
        (
          result: {
            titleCode: string;
            hintCode: string;
            params: { suffix?: string; numbers?: string[]; integrations?: string[] };
          },
          { extensions }: GraphQLError
        ) => {
          const {
            message = '',
            affectedNumbers = [],
            affectedIntegrations = [],
          } = (
            extensions as {
              exception: {
                message: string;
                affectedNumbers: { phone?: string }[];
                affectedIntegrations: { name?: string }[];
              };
            }
          ).exception;

          switch (message) {
            case 'CANNOT_DELETE_DEPARTMENT_DUE_NUMBERS': {
              return {
                titleCode: 'CANNOT_DELETE_DEPARTMENT_DUE_NUMBERS',
                hintCode: 'CONFIGURE_INCOMING_CALLS_TO_ANOTHER_DEPARTMENT_AND_TRY_AGAIN',
                params: {
                  suffix: translate(affectedNumbers?.length > 1 ? 'MANY_NUMBERS' : 'ONE_NUMBER'),
                  numbers: affectedNumbers?.map(({ phone = '' }) => phone),
                },
              };
            }
            case 'CANNOT_DELETE_DEPARTMENT_DUE_INTEGRATIONS': {
              return {
                titleCode: 'CANNOT_DELETE_DEPARTMENT_DUE_INTEGRATIONS',
                hintCode: 'CONFIGURE_INCOMING_CALLS_TO_ANOTHER_DEPARTMENT_AND_TRY_AGAIN',
                params: {
                  suffix: translate(
                    affectedIntegrations?.length > 1 ? 'MANY_INTEGRATIONS' : 'ONE_INTEGRATION'
                  ),
                  integrations: affectedIntegrations?.map(({ name = '' }) => name),
                },
              };
            }
            default:
              return result;
          }
        },
        {
          titleCode: 'SOMETHING_WENT_WRONG',
          hintCode: 'PLEASE_TRY_AGAIN_LATER',
          params: {},
        }
      );

      return (
        <MessageDialog
          isOpen={open}
          title={translate('UNABLE_TO_DELETE')}
          contentClass={classes.removeDepartmentDialog}
          onCancel={handleConfirmDialogClose}
          renderContent={renderUnableDeleteDialogContent(errorDescription)}
        />
      );
    }

    return (
      <MessageDialog
        isOpen={open}
        title={translate('DELETE_DEPARTMENT_PROMPT')}
        contentClass={classes.possibleRemoveDepartmentDialog}
        onCancel={handleConfirmDialogClose}
        renderContent={renderConfirmDialogContent()}
      />
    );
  };

  if (!item) {
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    return <td />;
  }

  const { id } = item;

  return (
    <>
      <Button
        variant={'secondary'}
        className={classes.actionButton}
        onClick={() => navigate(`/employee/departments/${id}`)}
      >
        <EditIcon />
      </Button>
      <Button
        variant={'secondary'}
        color={'error'}
        className={classes.actionButton}
        loading={loadingCanRemoveDepartment}
        onClick={handleDeleteButton}
      >
        <TrashIcon />
      </Button>
      {open && renderDialogContent()}
    </>
  );
};

export default DepartmentActionsCell;
