import React, { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react';
import Typography from '@shared/components/Typography';
import { useMessageStyles } from '@components/Messages/Message.style';
import clsx from 'clsx';

export const Message: FunctionComponent<
  { data?: { message: string }; type: string } & DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
> = ({ type = 'error', data, className }) => {
  const classes = useMessageStyles();

  if (!data) {
    return null;
  }

  if (type === 'error') {
    return (
      <div className={clsx(classes.root, className)}>
        <Typography type={'text4'} color={'danger600'}>
          {data.message}
        </Typography>
      </div>
    );
  }

  if (type === 'success') {
    return (
      <div className={clsx(classes.root, className)}>
        <Typography type={'text4'} color={'success600'}>
          {data.message}
        </Typography>
      </div>
    );
  }
  return null;
};

export default Message;
