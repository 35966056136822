import {
  monthOption,
  suggestBankType,
  suggestCompanyType,
  suggestFMSType,
} from '@components/typings/interfaces';
import { endOfDay, endOfMonth, endOfWeek, subDays } from 'date-fns';
import { SizeUnits, sizeUnitsArray } from './constants';

export const formatDay = (day: string | number) => {
  if (!day) return 0;
  const numberValue = Number(day);
  if (numberValue < 10) {
    return Number(String(numberValue).replace('0', ''));
  }
  return Number(day);
};

export const timeToNumber = (val: string) => Number(val.replace(':', ''));

// 00:02:02 => 122
export const timeToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':');
  if (!seconds) {
    // 02:02 => 122
    return +hours! * 60 + +minutes!;
  }
  return +hours! * 60 * 60 + +minutes! * 60 + +seconds;
};

export const formatNumber = (numb: string): number =>
  Number(numb.replace(/,/g, '.').replace(/\s/g, ''));

export const formatTimeHour = (hour: number): string => {
  if (Number.isNaN(hour)) {
    return '00:00';
  }
  if (hour < 10) {
    return `0${hour}:00`;
  }
  if (hour === 24) {
    return '23:59';
  }
  return `${hour}:00`;
};

export const bytesToSize = (
  bytes: number,
  decimals = 2
): {
  value: number;
  units: SizeUnits;
} => {
  if (!bytes || Number.isNaN(Number(bytes))) {
    return {
      value: 0,
      units: SizeUnits.Bytes,
    };
  }
  const kSize = 1024;
  const dm = decimals <= 0 ? 0 : decimals;
  const unitIndex = Math.floor(Math.log(bytes) / Math.log(kSize));
  const value = parseFloat((bytes / kSize ** unitIndex).toFixed(dm));
  return {
    value,
    units: sizeUnitsArray[unitIndex]!,
  };
};

export const pluralForm = (n: number, msgBase: string): string =>
  // eslint-disable-next-line no-nested-ternary
  `${msgBase}_${
    // eslint-disable-next-line no-nested-ternary
    n % 10 === 1 && n % 100 !== 11
      ? 1
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 2
      : 5
  }`;

export const setStringMaxLength = (value: string, length?: number): string => {
  const maxLength = length || 20;
  if (!value) return '';
  if (value.length > maxLength) {
    return `${value.slice(0, maxLength)}...`;
  }
  return value;
};

const cleanDelimiters = (str = '', delimiters = '') =>
  str.replace(new RegExp(`[${delimiters}]+`, 'g'), '') || '';

// Функция выделяет в тексте с разделителями блоки, которые надо подсветить. Возвращает массив начал и концов блоков для подсветки
// matchWithDelimiters('+7(123)456-78-90', '5678') => [[8, 13]]
// Совместимо с autosuggest-highlight/parse
export const matchWithDelimiters = (
  str: string,
  query: string,
  delimiters = ''
): Array<[number, number]> => {
  const m: Array<[number, number]> = [];
  if (!query) return m;
  let start = 0;
  const cleanQuery = cleanDelimiters(query, delimiters);
  if (!cleanQuery) {
    return m;
  }
  let pos = cleanQuery.length;
  while (pos <= str.length) {
    const segment = cleanDelimiters(str.substring(start, pos), delimiters);
    if (segment === cleanQuery) {
      while (str[start] !== cleanQuery[0]) {
        start += 1;
      }
      m.push([start, pos]);
      start = pos;
    }
    pos += 1;
    if (pos > str.length && start < str.length - cleanQuery.length) {
      start += 1;
      pos = start + cleanQuery.length;
    }
  }
  return m;
};

export const setSuggestCompanyList = (arr: suggestCompanyType[]) =>
  arr.map((item, index) => ({
    key: `company_${index}`,
    value: item.name,
    companyName: item.name,
    data: item,
  }));

export const setSuggestBankList = (arr: suggestBankType[]) =>
  arr.map((item, index) => ({
    key: `bank_${index}`,
    value: item.name,
    bankName: item.name,
    data: item,
  }));

export const setSuggestFMSList = (arr: suggestFMSType[]) =>
  arr.map((item, index) => ({
    key: `fms_${index}`,
    value: item.code,
    departmentCode: item.code,
    data: item,
  }));

export const formatDate = (date: Date) => {
  const monthListShort = [
    'MONTH_JAN',
    'MONTH_FEB',
    'MONTH_MRT',
    'MONTH_APR',
    'MONTH_MA',
    'MONTH_JN',
    'MONTH_JL',
    'MONTH_AUG',
    'MONTH_SEP',
    'MONTH_OCT',
    'MONTH_NOV',
    'MONTH_DEC',
  ];
  const monthList: monthOption = [
    'MONTH_JANUARY',
    'MONTH_FEBRUARY',
    'MONTH_MART',
    'MONTH_APRIL',
    'MONTH_MAY',
    'MONTH_JUNE',
    'MONTH_JULY',
    'MONTH_AUGUST',
    'MONTH_SEPTEMBER',
    'MONTH_OCTOBER',
    'MONTH_NOVEMBER',
    'MONTH_DECEMBER',
  ];
  const day = date.getDate().toString();
  const dayEnd = endOfDay(date);
  const monthEnd = endOfMonth(date);
  const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
  const weekEndDay = weekEnd.getDate().toString();
  const pastWeekDay = subDays(date, 7).getDate().toString();
  const mnth = date.getMonth();
  const nextMonth = weekEnd.getMonth();
  const year = date.getFullYear();
  const month = monthList[mnth];
  const monthShort = monthListShort[mnth];
  const nextMonthShort = monthListShort[nextMonth];
  return {
    day,
    dayEnd,
    pastWeekDay,
    month,
    monthShort,
    monthEnd,
    year,
    weekEnd,
    weekEndDay,
    nextMonthShort,
  };
};
