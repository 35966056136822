import React, { JSXElementConstructor, ReactElement, useState } from 'react';
import clsx from 'clsx';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/components/Button/Button';
import { ChevronLeftIcon, ChevronRightIcon } from '@shared/assets/images/icons';
import MessageDialog from '@shared/components/MessageDialog';
import { usePreviewDialogStyles } from './PreviewDialog.styles';
import { IPreviewDialogProps } from './PreviewDialog.interfaces';

export const PreviewDialog = ({
  isOpen,
  title: titleFromProps,
  data,
  disableEnforceFocus = false,
  onCancel,
  className,
}: IPreviewDialogProps) => {
  const classes = usePreviewDialogStyles();
  const [translate] = useTranslation();
  const [selectedSlide, setSelectedSlide] = useState(0);

  const currentItem = data[selectedSlide];
  if (!currentItem) {
    return null;
  }

  const onClose = () => {
    onCancel?.();
    setTimeout(() => {
      setSelectedSlide(0);
    }, 200);
  };

  const handleChangeSlide = (operationType: 'increment' | 'decrement') => {
    setSelectedSlide((prevState) => {
      if (operationType === 'increment') {
        return Math.min(prevState + 1, data.length - 1);
      }
      return Math.max(prevState - 1, 0);
    });
  };

  const renderPreviewTextString = () => {
    const title = currentItem.title;

    if (typeof title === 'string') {
      return (
        <Typography color={'tertiary900'} type={'text3'}>
          {translate(title)}
        </Typography>
      );
    }

    return (
      <Translate
        i18nKey={title.key}
        components={
          title.components as unknown as readonly ReactElement<
            any,
            string | JSXElementConstructor<any>
          >[]
        }
      />
    );
  };

  const renderPreviewText = () => {
    return (
      <div className={classes.previewTextWrapper}>
        {data.length > 1 && (
          <Typography className={classes.previewTextNumber}>{selectedSlide + 1}. </Typography>
        )}
        {renderPreviewTextString()}
      </div>
    );
  };

  const renderImageWrapper = () => {
    return (
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={currentItem.img} alt={''} />
      </div>
    );
  };

  const renderSlideDots = () => {
    return data.map((_, index) => {
      return (
        <div
          key={index}
          className={clsx(classes.dot, index === selectedSlide && classes.selectedDot)}
        />
      );
    });
  };

  const renderSlideControls = () => {
    if (data.length <= 1) {
      return null;
    }

    return (
      <div className={classes.selectSlideControlsOuterWrapper}>
        <div className={classes.selectSlideControls}>
          <Button
            className={classes.chevronButton}
            variant="icon"
            clear
            disabled={selectedSlide === 0}
            onClick={() => handleChangeSlide('decrement')}
          >
            <ChevronLeftIcon />
          </Button>
          {renderSlideDots()}
          <Button
            className={classes.chevronButton}
            variant="icon"
            clear
            disabled={selectedSlide === data.length - 1}
            onClick={() => handleChangeSlide('increment')}
          >
            <ChevronRightIcon />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <MessageDialog
      isOpen={isOpen}
      title={titleFromProps || translate('WHERE_SEARCH')}
      paperClass={className}
      contentClass={classes.dialogContent}
      bodyWrapperClass={classes.dialogBodyWrapper}
      disableEnforceFocus={disableEnforceFocus || false}
      onCancel={onClose}
      renderContent={
        <>
          {renderPreviewText()}
          {renderImageWrapper()}
          {renderSlideControls()}
        </>
      }
      buttonSuccessProps={{
        titleCode: 'CLOSE',
        onClick: onClose,
      }}
    />
  );
};
