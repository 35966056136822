import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { secondsToDuration } from '@/common/utils';
import { IEmployee } from '@components/typings/interfaces';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';

export const EmployeeCallForwardingCell: FunctionComponent<ICellProps<IEmployee>> = ({ item }) => {
  const [translate] = useTranslation();

  if (item) {
    const { callForwarding, callForwardingTimeout } = item;
    let text = '';
    if (callForwarding) {
      if (callForwardingTimeout) {
        if (callForwardingTimeout < 0) {
          text = translate('IMMEDIATELY_ON_ALL_DEVICES_SHORT');
        } else {
          const duration = secondsToDuration(callForwardingTimeout);
          if (duration.hours) {
            text = `${translate('ANY_HOURS', duration)}\xa0`;
          }
          if (duration.minutes) {
            text += `${translate('ANY_MIN', duration)}\xa0`;
          }
          if (duration.seconds) {
            text += `${translate('ANY_SEC', duration)}\xa0`;
          }
        }
      } else if (callForwardingTimeout !== undefined) {
        text = translate('IMMEDIATELY');
      }
    }

    return (
      <Typography color={'inherit'} type={'text3'}>
        {text}
      </Typography>
    );
  }

  return <div />;
};
