import { makeStyles } from '@material-ui/core';
import styleConstants from '@components/styles';

export const UseEmployeesStatisticStyles = makeStyles(
  ({ color: { tertiary, warning, links, success, danger, primary } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    customOption: {
      height: '2em',
      padding: '0 1em',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      pointerEvents: 'none',
      justifyContent: 'space-between',
      width: '100%',
    },
    employeesStatisticControlsWrapper: {
      margin: '0 2em 1em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    empStatisticMarginHalfRight: {
      marginRight: '0.5em',
    },
    empStatisticMarginHalfLeft: {
      marginLeft: '0.5em',
    },
    empStatisticMarginRight: {
      marginLeft: '1em',
    },
    rightCell: {
      paddingRight: '7em',
    },
    employeesStatisticEmptyContent: {
      position: 'absolute',
      right: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      paddingTop: '15em',
      justifyContent: 'center',
      pointerEvents: 'none',
      backgroundColor: 'white',
    },
    employeesStatisticFilterIcon: {
      width: '4em',
      height: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    selectRoot: {
      '& .MuiSelect-root > li > * > svg': {
        display: 'none',
      },
    },
    employeesStatisticFilterBtn: {
      width: '16.5em',
      height: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: tertiary[100],
      borderRadius: '0.375em',
      userSelect: 'none',
    },
    headerCallsContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      '&:last-child': {
        justifyContent: 'flex-end',
      },
    },
    links: {
      color: links[600],
    },
    black: {
      color: 'black',
    },
    danger: {
      color: danger[600],
    },
    warning: {
      color: warning[600],
    },
    success: {
      color: success[600],
    },
    glyph: {
      marginTop: '-2px',
      marginRight: '0.5em',
      color: primary[700],
    },
    selectedText: {
      color: primary[700],
    },
    statisticLines: {
      marginTop: '0.5em',
      marginBottom: '0.25em',
      backgroundColor: tertiary[200],
    },
    sideLine: {
      height: '0.5em',
    },
    sideLineBlue: {
      backgroundColor: links[600],
    },
    sideLineGreen: {
      backgroundColor: success[600],
    },
    sideLineRed: {
      backgroundColor: danger[600],
    },
    sideLineWarning: {
      backgroundColor: warning[600],
    },
    separatingBlock: {
      content: '""',
      backgroundColor: 'white',
      width: '2px',
      height: '0.5em',
    },
    chevronIcon: {
      width: '0.875em',
      height: '0.875em',
      marginLeft: '0.25em',
      color: tertiary[600],
      '&:hover': {
        color: primary[600],
        cursor: 'pointer',
      },
    },
    emptyBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '14em',
    },
    statisticsIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
