import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/components/Button/Button';
import {
  DOMAIN_NUMBERS_QUERY,
  GET_BUSINESS_CARDS_QUERY,
  GET_ONE_BUSINESS_CARD_QUERY,
  MAX_CONTACT_LENGTH_QUERY,
} from '@/client/queries';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import ComboBoxField from '@shared/components/ComboBoxField';
import {
  CREATE_BUSINESS_CARD_MUTATION,
  DELETE_BUSINESS_CARD_MUTATION,
  UPDATE_BUSINESS_CARD_CONFIG_MUTATION,
} from '@/client/mutations';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { useRoutes } from '@components/Routes';
import { useFormErrors } from '@components/common/formErrors.hooks';
import FormErrorMessage from '@components/FormErrorMessage';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import Translate from '@shared/components/Translate';
import { CheckboxField } from '@shared/components/CheckboxField/CheckboxField';
import { HelpCircleIcon, TrashIcon } from '@shared/assets/images/icons';
import MessageDialog from '@shared/components/MessageDialog';
import { getSegments } from '@/common/utils';
import { pluralForm } from '@components/utils';
import clsx from 'clsx';
import ControlButtons from '@shared/components/ControlButtons';
import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import Flex from '@shared/components/Flex';
import { FormFields, ISelectedNumber } from './BusinessCard.interfaces';
import { BusinessCardStyles } from './BusinessCard.styles';
import {
  DEFAULT_NEW_CLIENT,
  MAX_MESSAGE_LENGTH,
  newClientDelaysOptions,
} from './BusinessCard.constants';

export const BusinessCard = () => {
  const classes = BusinessCardStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const formMethods = useForm<FormFields>({
    // TODO add correct types to form
    defaultValues: { 'numberId-0': -2 },
  });
  const { watch, handleSubmit, setValue, setError, clearErrors } = formMethods;
  const {
    path: { subcategory, identifier },
  } = useRoutes();

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isNoFreeNumbersDialogOpen, setIsNoFreeNumbersDialogOpen] = useState(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [numbersArray, setNumbersArray] = useState<number[]>([-2]);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [disabledNumbers, setDisabledNumbers] = useState<number[]>([]);

  const { data: numbersData, loading: numbersLoading } = useQuery(DOMAIN_NUMBERS_QUERY);
  const { data: contactData } = useQuery(MAX_CONTACT_LENGTH_QUERY);
  // TODO handle lazy query error
  const [getOneBusinessCard, { data: oneBusinessCard }] = useLazyQuery(GET_ONE_BUSINESS_CARD_QUERY);
  const [
    createBusinessCard,
    { error: errorCreateBC, called: calledCreateBC, loading: loadingCreateBC },
  ] = useMutation(CREATE_BUSINESS_CARD_MUTATION);
  const [removeBusinessCard, { loading: loadingRemoveBC }] = useMutation(
    DELETE_BUSINESS_CARD_MUTATION,
    {
      refetchQueries: [{ query: GET_BUSINESS_CARDS_QUERY }],
    }
  );
  const [
    updateBusinessCard,
    { error: errorUpdateBC, called: calledUpdateBC, loading: loadingUpdateBC },
  ] = useMutation(UPDATE_BUSINESS_CARD_CONFIG_MUTATION);
  useFormErrors(errorUpdateBC?.graphQLErrors, formMethods);
  useFormErrors(errorCreateBC?.graphQLErrors, formMethods);
  const formErrors = [
    ...(errorUpdateBC?.graphQLErrors || []),
    ...(errorCreateBC?.graphQLErrors || []),
  ];
  const isBusinessCardEdit = identifier !== 'add';
  const addEmployeeContacts = watch('addContacts');
  const emptyNumbersArray = numbersArray[0] === -2;
  const contactLength = contactData?.maxContactLength?.length || 0;
  const getOneBusinessCardConfig = oneBusinessCard?.getOneBusinessCardConfig;
  const maxLetterCounter = useMemo(
    () => (addEmployeeContacts ? MAX_MESSAGE_LENGTH - contactLength : MAX_MESSAGE_LENGTH),
    [addEmployeeContacts, contactLength]
  );
  const employeeContactLength = translate(pluralForm(contactLength, 'EMPLOYEE_CONTACT_LENGTH'), {
    count: contactLength,
  });
  const domainNumbers = useMemo(
    () => numbersData?.boughtDomainNumbers || [],
    [numbersData?.boughtDomainNumbers]
  );
  const smsTemplateText = watch('comment') || '';
  const optionNumbers = useMemo(
    () => [
      // This empty option is used to make ComboBoxField controlled (by internal logic of MUI Autocomplete)
      { id: -2, phone: '', hasBusinessCard: false },
      {
        id: -1,
        phone: translate('ALL_NUMBERS'),
        hasBusinessCard: false,
      },
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...domainNumbers?.map((item) => ({
        id: item.id,
        hasBusinessCard: item.hasBusinessCard,
        phone: formatPhone(item.phone),
      })),
    ],
    [domainNumbers, translate]
  );
  const computedClass = clsx(classes.textFieldCounter, {
    [classes.textFieldCounterError]:
      smsTemplateText.length > MAX_MESSAGE_LENGTH || maxLetterCounter < smsTemplateText.length,
  });

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function handleCancelClick() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    navigate(-1);
  }

  const handleRemoveBusinessCard = () => {
    handleFormChange();
    removeBusinessCard({
      variables: {
        data: {
          businessCardSettingId: Number(identifier),
        },
      },
    });
    navigate('/business-cards/greetings');
  };

  const handleAddNewNumber = useCallback(() => {
    handleFormChange();
    const newNumbersArray = [...numbersArray, -2];
    const lastElIndex = newNumbersArray.length - 1;

    formMethods.setValue(`numberId-${lastElIndex}`, -2);
    setNumbersArray([...numbersArray, -2]);
  }, [numbersArray, formMethods]);

  const handleSelectedNumber = useCallback(
    (item: ISelectedNumber, index: number) => {
      if (item.id === -2) {
        return;
      }

      if (item.id === -1) {
        setNumbersArray([-1]);
        formMethods.setValue('numberId-0', -1);
      } else {
        const newNumbersArray = [...numbersArray];
        newNumbersArray[index] = item?.id;
        setNumbersArray(newNumbersArray);
      }
      handleFormChange();
    },
    [numbersArray, formMethods]
  );

  function handleRemoveSelectedNumber(index: number) {
    if (numbersArray) {
      const updatedNumbersArray = [...numbersArray];
      updatedNumbersArray.splice(index, 1);
      setNumbersArray(updatedNumbersArray.length ? updatedNumbersArray : [-2]);
    }
  }

  function handleRemoveDialogOpen() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    setIsRemoveDialogOpen(true);
  }

  const handleClosePopupDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  const handleCloseInfoDialog = () => {
    setIsNoFreeNumbersDialogOpen(false);
    navigate('/business-cards/greetings');
  };

  const smsAmount = () => {
    if (!addEmployeeContacts && !smsTemplateText) {
      return `0 ${translate('SMS')}`;
    }
    if (addEmployeeContacts) {
      return `${getSegments(smsTemplateText, contactLength)} ${translate('SMS')}`;
    }
    return `${getSegments(smsTemplateText, 0)} ${translate('SMS')}`;
  };

  const handleFormSubmit = useCallback(
    (data: FormFields) => {
      const businessCardData = {
        smsTemplate: data.comment,
        newClientDelay: data.newClientDelay,
        addContacts: data.addContacts,
        active: Boolean(!isBusinessCardEdit || getOneBusinessCardConfig?.active),
        sources: numbersArray.map((phoneId: number | undefined) => ({
          domainNumberId: phoneId === -1 ? undefined : phoneId,
          allNumbers: phoneId === -1,
        })),
      };
      if (!isBusinessCardEdit) {
        createBusinessCard({
          variables: {
            data: businessCardData,
          },
        });
      } else {
        updateBusinessCard({
          variables: {
            data: {
              id: Number(identifier),
              ...businessCardData,
            },
          },
        });
      }
    },
    [
      isBusinessCardEdit,
      getOneBusinessCardConfig?.active,
      numbersArray,
      createBusinessCard,
      updateBusinessCard,
      identifier,
    ]
  );

  useEffect(() => {
    const numbers: number[] = [];
    domainNumbers.forEach((number) => {
      if (
        number.hasBusinessCard &&
        !getOneBusinessCardConfig?.sources.some(
          (source) => source?.number?.id === number.id || source?.allNumbers
        )
      ) {
        numbers.push(number.id);
      }
    });
    setDisabledNumbers(numbers);
  }, [domainNumbers, getOneBusinessCardConfig, setDisabledNumbers]);

  useEffect(() => {
    if (
      !isBusinessCardEdit &&
      !numbersLoading &&
      !domainNumbers.some((number) => !number.hasBusinessCard)
    ) {
      setIsNoFreeNumbersDialogOpen(true);
    }
  }, [
    isBusinessCardEdit,
    domainNumbers,
    setIsNoFreeNumbersDialogOpen,
    isNoFreeNumbersDialogOpen,
    numbersLoading,
  ]);

  useEffect(() => {
    if (!getOneBusinessCardConfig) return;
    if (isBusinessCardEdit) {
      setValue('comment', getOneBusinessCardConfig.smsTemplate);
      setValue('addContacts', getOneBusinessCardConfig.addContacts);
      setValue('newClientDelay', getOneBusinessCardConfig.newClientDelay);
    }
  }, [getOneBusinessCardConfig, isBusinessCardEdit, setValue]);

  useEffect(() => {
    if (numbersArray.length) {
      numbersArray.forEach((phoneId: number | undefined, index: number) => {
        setValue(`numberId-${index}`, phoneId);
      });
    }
  }, [numbersArray, setValue]);

  useEffect(() => {
    if (getOneBusinessCardConfig && isBusinessCardEdit && emptyNumbersArray) {
      const newArrayData: number[] = [];
      const sources = getOneBusinessCardConfig.sources;

      for (let i = 0; i < sources.length; i += 1) {
        const source = sources[i];

        if (source.allNumbers) {
          newArrayData.push(-1);
          continue;
        }

        if (!source.number) {
          continue;
        }

        newArrayData.push(source.number.id);
      }

      setNumbersArray(newArrayData);
    }
  }, [emptyNumbersArray, getOneBusinessCardConfig, isBusinessCardEdit, numbersArray]);

  useEffect(() => {
    if (!getOneBusinessCardConfig && isBusinessCardEdit) {
      const identifierAsNumber = Number(identifier);
      if (!Number.isFinite(identifierAsNumber)) {
        return;
      }

      getOneBusinessCard({
        variables: { id: identifierAsNumber },
      });
    }
  }, [getOneBusinessCard, getOneBusinessCardConfig, identifier, isBusinessCardEdit, subcategory]);

  useEffect(() => {
    if (
      (calledCreateBC && !loadingCreateBC && !errorCreateBC) ||
      (calledUpdateBC && !loadingUpdateBC && !errorUpdateBC)
    ) {
      navigate(blockedPath || '/business-cards/greetings');
    }
  }, [
    blockedPath,
    calledCreateBC,
    calledUpdateBC,
    errorCreateBC,
    errorUpdateBC,
    loadingCreateBC,
    loadingUpdateBC,
    navigate,
  ]);

  useEffect(() => {
    if ((calledCreateBC || calledUpdateBC) && !errorUpdateBC && !errorCreateBC) {
      setIsChanged({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });
    }
  }, [calledCreateBC, calledUpdateBC, errorCreateBC, errorUpdateBC]);

  useEffect(() => {
    if (maxLetterCounter < smsTemplateText.length || smsTemplateText.length > MAX_MESSAGE_LENGTH) {
      setError('comment', {
        type: 'manual',
        message: translate('MORE_THAN_POSSIBLE'),
      });
    } else clearErrors();
  }, [addEmployeeContacts, smsTemplateText, maxLetterCounter, clearErrors, setError, translate]);

  const renderAddContactsCard = (checked: boolean) => {
    if (checked) {
      return (
        <div className={classes.addEmployeeContactsCard}>
          <Translate
            i18nKey="ADD_EMPLOYEE_CONTACTS_DESCRIPTION"
            values={{
              contactLength,
              employeeContactLength,
            }}
            components={{
              n: <Typography color={'tertiary900'} type={'text4'} />,
              b: <Typography color={'tertiary900'} type={'text4'} medium />,
              l: <br />,
            }}
            shouldUnescape
          />
        </div>
      );
    }
    return null;
  };

  const renderPurposeItems = (phoneId: number | undefined, index: number) => {
    // Prevent `undefined` uncontrolled behavior. Use hidden empty option with id -2 instead.
    if (formMethods.getValues()[`numberId-${index}`] === undefined) {
      setValue(`numberId-${index}`, -2);
    }

    return (
      <>
        <ComboBoxField
          className={classes.controlSize}
          name={`numberId-${index}`}
          data={optionNumbers}
          getOptionDisabled={(option) =>
            numbersArray.includes(option.id) ||
            disabledNumbers.includes(option.id) ||
            (option.id === -1 && disabledNumbers.length > 0)
          }
          valueKey={'id'}
          titleKey={'phone'}
          placeholder={translate('CHOOSE')}
          validate={(value: number) => {
            const isEmpty = value === -2;
            if (isEmpty) {
              return translate('CHOOSE_NUMBER') as string;
            }
            return true;
          }}
          onChange={(e, item: ISelectedNumber) => handleSelectedNumber(item, index)}
          // We do not show hidden empty option
          filterOptions={(options) => {
            return options.filter((o) => o.phone !== '');
          }}
        />

        {numbersArray.length > 1 && (
          <Button
            variant={'secondary'}
            color={'error'}
            className={classes.listControl}
            onClick={() => handleRemoveSelectedNumber(index)}
          >
            <TrashIcon />
          </Button>
        )}
      </>
    );
  };

  return (
    <BodyContainer>
      <div className={classes.root}>
        <FormProvider {...formMethods}>
          <form
            id={'add-business-card'}
            action={''}
            className={classes.form}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('UPON_CALL_TO_NUMBER')}
            </Typography>
            <div className={classes.fieldSize}>
              {numbersArray.map((phoneId: number | undefined, index: number) => (
                <div className={classes.listItem} key={`selectedNumber-${index}`}>
                  {renderPurposeItems(phoneId || undefined, index)}
                </div>
              ))}
              {!emptyNumbersArray &&
                !numbersArray.includes(-1) &&
                !numbersArray.some((n) => n === -2) && (
                  <Button
                    className={classes.addNumberButton}
                    title={translate('ADD_NUMBER')}
                    variant={'secondary'}
                    onClick={handleAddNewNumber}
                  />
                )}
            </div>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('WILL_BE_SENT_MESSAGE')}
            </Typography>
            <div className={classes.textFormContainer}>
              <FormFieldRhfUncontrolled
                className={classes.textForm}
                name={'comment'}
                fullWidth
                rowsMax={12}
                rows={2}
                multiline
                onChange={handleFormChange}
                InputProps={{ style: { paddingBottom: '1.5em' } }}
                validate={(value) => {
                  if (!value) {
                    return translate('ENTER_MESSAGE') as string;
                  }
                  if (value && value.length > maxLetterCounter) {
                    return translate('MORE_THAN_POSSIBLE') as string;
                  }
                  return true;
                }}
              />
              <Typography className={computedClass} type={'text5'} color={'tertiary600'}>
                {`${smsTemplateText.length} ${translate(
                  'OUT_OF'
                ).toLowerCase()} ${maxLetterCounter}`}
              </Typography>
            </div>
            <CheckboxField
              name={'addContacts'}
              label={translate('ADD_EMPLOYEE_CONTACTS')}
              onChange={handleFormChange}
            />
            {renderAddContactsCard(addEmployeeContacts)}
            <div className={classes.bottomContainer}>
              <div className={classes.counterRow}>
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {translate('FUNDS_WILL_DE_DEBITED')}
                </Typography>
                <Flex alignItems="flexStart">
                  <Typography type={'text3'} color={'tertiary900'} bold>
                    {smsAmount()}
                  </Typography>
                  <TooltipHover
                    className={classes.notificationIcon}
                    placement={'right'}
                    title={
                      <Typography color={'tertiary900'} type={'text4'}>
                        {translate('SMS_LENGTH_NOTIFICATION')}
                      </Typography>
                    }
                  >
                    <HelpCircleIcon />
                  </TooltipHover>
                </Flex>
              </div>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('WHO_NEW_CLIENT')}
              </Typography>
              <div className={classes.fieldSize}>
                <SelectField
                  data={newClientDelaysOptions}
                  name={'newClientDelay'}
                  valueKey={'value'}
                  titleKey={'titleCode'}
                  translating
                  label={translate('IF_NO_CALLS')}
                  defaultValue={DEFAULT_NEW_CLIENT}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <BottomButtons>
              <div className={classes.actions}>
                <div>
                  <ControlButtons
                    confirmTitle={'SAVE_CHANGES'}
                    cancelTitle={'CANCEL'}
                    form={'add-business-card'}
                    onCancelClick={handleCancelClick}
                    rootConfirmStyles={classes.button}
                    rootCancelBtn={classes.cancelButton}
                    loading={loadingCreateBC || loadingUpdateBC}
                  />
                </div>
                {isBusinessCardEdit && (
                  <Button variant={'tertiary'} noHover onClick={handleRemoveDialogOpen}>
                    <Typography underline type={'text3'} color={'tertiary900'}>
                      {translate('REMOVE')}
                    </Typography>
                  </Button>
                )}
              </div>
            </BottomButtons>
            <FormErrorMessage errors={formErrors} />
          </form>
        </FormProvider>
        <ConfirmDialog
          isBlocked={isChanged.isBlocking}
          onSaveChanges={handleSubmit(handleFormSubmit)}
          onNavigationBlocked={getBlockedPath}
        />
        <MessageDialog
          isOpen={isRemoveDialogOpen}
          title={translate('ARE_YOU_SURE')}
          onCancel={handleClosePopupDialog}
          renderContent={
            <>
              <div className={classes.confirmPopupText}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('BUSINESS_CARD_WILL_BE_DELETED')}
                </Typography>
              </div>
              <ControlButtons
                confirmTitle={'REMOVE'}
                confirmColor={'error'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                onConfirmClick={() => handleRemoveBusinessCard()}
                onCancelClick={handleClosePopupDialog}
                loading={loadingRemoveBC}
                small
              />
            </>
          }
        />
        <MessageDialog
          isOpen={isNoFreeNumbersDialogOpen}
          title={translate('ERROR')}
          onCancel={handleCloseInfoDialog}
          renderContent={
            <>
              <div className={classes.confirmPopupText}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('BUSINESS_CARD_NO_FREE_NUMBERS')}
                </Typography>
              </div>
              <div className={classes.confirmPopupControls}>
                <Button title={translate('CLOSE')} onClick={handleCloseInfoDialog} smallHeight />
              </div>
            </>
          }
        />
      </div>
    </BodyContainer>
  );
};

export default BusinessCard;
