import React, { FunctionComponent, PropsWithChildren } from 'react';
import Preloader from '@shared/components/Preloader';
import Flex from '@shared/components/Flex';
import { useCellStyles } from './CellPreloader.styles';
import { ICellPreloaderType } from './CellPreloader.interfaces';

export const CellPreloader: FunctionComponent<PropsWithChildren<ICellPreloaderType>> = ({
  index,
  styles,
  children,
}) => {
  const classes = useCellStyles();

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      fullWidth
      key={index}
      className={classes.message}
      style={styles}
    >
      {children || <Preloader />}
    </Flex>
  );
};

export default CellPreloader;
