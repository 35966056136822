import { deserializeEmployee, IEmployee, IEmployeeRaw, serializeEmployee } from './employee';

export enum CallDistributionTypes {
  Multicall = 'MULTICALL',
  Cycled = 'CYCLED',
  Progressive = 'PROGRESSIVE',
}

export interface IDepartment {
  id?: number;
  name?: string;
  ext?: string;
  isActive?: boolean;
  employeesCount?: number;
  noActiveEmployee?: boolean;
  employees?: Partial<IEmployee>[];
  activeEmployeesCount?: number;
  emailCount?: number;
  numberCount?: number;
}

export interface IDepartmentRaw {
  id?: number;
  name?: string;
  ext?: string;
  employees?: IEmployeeRaw[];
  activeEmployeesCount?: number;
}

export const deserializeDepartment = (data: Partial<IDepartmentRaw>): IDepartment => {
  const { id, name, ext, employees, activeEmployeesCount } = data;
  return {
    id,
    name: name ?? '',
    ext: ext ?? '',
    employeesCount: (employees ?? []).length,
    activeEmployeesCount,
    employees: (employees ?? []).map(deserializeEmployee),
  };
};

export const serializeDepartment = ({
  id: departmentId,
  name,
  ext,
  employees,
}: Partial<IDepartment> = {}) => {
  const numberId = Number(departmentId);
  return {
    id: Number.isFinite(numberId) ? numberId : undefined,
    ext,
    name,
    employees: employees?.length ? employees.map(serializeEmployee) : undefined,
  };
};
