import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const usePayerDataStyle = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexFlow: 'column',
    },
    payerDataRoot: {
      padding: '0 2em 6em',
    },
    body: {
      position: 'relative',
      display: 'flex',
      height: '100%',
    },
    documentsContentContainer: {
      borderRadius: '12px',
    },
    documentsContent: {
      paddingTop: '0',
    },
    personalDataTitle: {
      paddingBottom: '0.875em',
    },
    personalDataWrapper: {
      marginRight: '2em',
      width: '31em',
    },
    personalDataColumn: {
      width: '31em',
      marginRight: '2em',
    },
    personalDataContainer: {
      border: `solid 1px ${tertiary[300]}`,
      borderRadius: '0.75em',
      padding: '1.5em',
      marginBottom: '2em',
    },
    personalDataItem: {
      paddingBottom: '1.5em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    personalDataContactTitle: {
      paddingBottom: '1em',
    },
    personalDataUploadLabel: {
      paddingBottom: '0.75em',
      display: 'block',
    },
    editContactsBtn: {
      height: '2em',
    },
    editContactsItem: {
      paddingBottom: '1em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    editContactsContainer: {
      display: 'flex',
      alignItems: 'end',
    },
    personalDataRemoveBtn: {
      width: '2.875em',
      height: '2.5em',
      marginLeft: '1em',
      padding: 0,
    },
    editContactsRemoveBtn: {
      width: '2.875em',
      height: '2.5em',
      marginLeft: '1em',
      padding: 0,
    },
    addContactBtn: {
      width: '11em',
      height: '2em',
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);
