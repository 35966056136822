import { fade, makeStyles } from '@material-ui/core/styles';

export const useSidebarItemsStyles = makeStyles(
  ({ color: { base, tertiary, primary } }) => ({
    gutters: {
      padding: '1em 2em',
    },
    listItem: {
      height: '3em',
    },
    listItemIcon: {
      width: '1em',
      minWidth: '1em',
      maxWidth: '1em',
      height: '1em',
      minHeight: '1em',
      maxHeight: '1em',
      marginRight: '1em',
      color: 'inherit',
      '& > svg': {
        width: '1em',
        height: '1em',
      },
    },
    listItemIconActive: {
      marginRight: '2em',
    },
    listItemText: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s, opacity 0.3s linear',
    },
    listItemTextActive: {
      visibility: 'visible',
      opacity: 1,
    },
    link: {
      display: 'flex',
      textDecoration: 'none',
      color: 'inherit',
      height: '3em',
      '&:hover': {
        background: tertiary[700],
        color: base,
      },
    },
    activeLink: {
      height: '3em',
      position: 'relative',
      display: 'flex',
      flexFlow: 'row-reverse',
      background: fade(tertiary[700] as string, 0.6),
      color: primary[300],
      '&:hover': {
        background: fade(tertiary[700] as string, 0.6),
        color: primary[300],
      },
      '&::after': {
        content: '" "',
        position: 'absolute',
        left: 0,
        top: '0.75em',
        bottom: '0.75em',
        width: 0,
        boxShadow: `2px 0 0.75em 0.75em ${fade(primary[300] as string, 0.26)}`,
        borderLeft: `2px solid ${fade(primary[300] as string, 0.26)}`,
        borderRadius: '50%',
      },
      '&::before': {
        content: '" "',
        position: 'absolute',
        left: '0',
        top: 0,
        bottom: 0,
        width: 0,
        borderLeft: `2px solid ${primary[300]}`,
        borderRadius: '3px 0 0 3px',
      },
    },
    tooltip: {
      border: `1px solid ${tertiary[700]}`,
      background: `${tertiary[700]}`,
    },
    arrow: {
      color: `${tertiary[700]}`,
      '&::before': {
        border: `1px solid ${tertiary[700]}`,
      },
    },
  }),
  {
    index: 2,
  }
);
