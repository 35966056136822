import { IDay, IHour, IPeriod, ISchedulePeriod } from './Scheduler.interfaces';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';

export const initialPeriods: ISchedulePeriod[] = [
  { key: SchedulePeriodsTypes.NonWorkingHours, titleCode: 'NON_WORKING_HOURS', color: undefined },
  { key: SchedulePeriodsTypes.WorkingHours, titleCode: 'WORKING_HOURS', color: 'success' },
];
export const initialATCPeriods: ISchedulePeriod[] = [
  { key: SchedulePeriodsTypes.ATC, titleCode: 'AROUND_THE_CLOCK', color: 'primary' },
];

export const customPeriods: ISchedulePeriod[] = [
  { key: SchedulePeriodsTypes.Custom1, titleCode: 'PERIOD_1', color: 'danger' },
  { key: SchedulePeriodsTypes.Custom2, titleCode: 'PERIOD_2', color: 'links' },
  { key: SchedulePeriodsTypes.Custom3, titleCode: 'PERIOD_3', color: 'secondary' },
];

export const minutes: SchedulePeriodsTypes[] = [...Array(60).values()].fill(
  SchedulePeriodsTypes.NonWorkingHours
);

export const hourSegments: IPeriod[] = [
  { start: 0, end: 14 },
  { start: 15, end: 29 },
  { start: 30, end: 44 },
  { start: 45, end: 59 },
];

export const hours: IHour[] = [...Array(24).keys()].map((id: number) => ({
  id,
  minutes: [...minutes],
}));

export const getDays = (): IDay[] => [
  {
    id: 0,
    titleCode: 'MONDAY',
    abbreviationCode: 'MON',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
  },
  {
    id: 1,
    titleCode: 'TUESDAY',
    abbreviationCode: 'TUE',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
  },
  {
    id: 2,
    titleCode: 'WEDNESDAY',
    abbreviationCode: 'WED',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
  },
  {
    id: 3,
    titleCode: 'THURSDAY',
    abbreviationCode: 'THU',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
  },
  {
    id: 4,
    titleCode: 'FRIDAY',
    abbreviationCode: 'FRI',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
  },
  {
    id: 5,
    titleCode: 'SATURDAY',
    abbreviationCode: 'SAT',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
    weekend: true,
  },
  {
    id: 6,
    titleCode: 'SUNDAY',
    abbreviationCode: 'SUN',
    hours: hours.map((item) => ({ ...item, minutes: [...item.minutes] })),
    weekend: true,
  },
];
