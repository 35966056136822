import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useVoiceMailFaxScenarioStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
    },
    formContent: {
      marginTop: '1.25em',
    },
    list: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginTop: '1em',
    },
    listItem: {
      display: 'flex',
      flexFlow: 'row',
      paddingBottom: '1em',
      width: '100%',
      alignItems: 'center',
    },
    listItemControl: {
      width: '60%',
    },
    addListItemButton: {
      marginTop: '0.5em',
      width: '60%',
    },
    listControl: {
      padding: '11px !important',
      marginLeft: '16px',
    },
    caption: {
      display: 'flex',
      flexFlow: 'row nowrap',
      gap: '0.5em',
      marginTop: '2em',
      '&:first-child': {
        marginTop: 0,
      },
    },
    subCaption: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      marginTop: '1.25em',
    },
    plainEmail: {
      display: 'flex',
      flexFlow: 'column',
      marginTop: '1.25em',
      marginBottom: '4em',
      '& > span': {
        marginBottom: '1em',
      },
    },
    plainEmailField: {
      width: '60%',
    },
    ...styleConstants,
  },
  {
    index: 1,
  }
);
