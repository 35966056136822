import merge from 'lodash.merge';
import React, { useMemo } from 'react';
import { Controller, useFormContext, UseControllerProps, FieldPath } from 'react-hook-form';
import FormFieldBase from '../FormFieldBase';
import { FormFieldRhfControlledProps } from './FormFieldRhfControlled.interfaces';

/** This component relies on react-hook-form context provider. Use it only when you have one above, otherwise it won't work. */
function FormFieldRhfControlled<
  FormFields extends Record<string, any> = Record<string, any>,
  FieldName extends FieldPath<FormFields> = FieldPath<FormFields>,
>({
  name,
  error,
  onChange,
  onBlur,
  required,
  disabled,
  validate,
  rules,
  type = 'text',
  ...props
}: FormFieldRhfControlledProps<FormFields, FieldName>) {
  const formMethods = useFormContext<FormFields>();

  const rulesComputed: UseControllerProps<FormFields, FieldName>['rules'] = useMemo(() => {
    const rulesFromProps = { required, validate };

    return merge(rulesFromProps, rules);
  }, [rules, required, validate]);

  if (formMethods === null) {
    console.error('Try to render FormFieldRhf without FormProvider above! Form methods is null!');
    return null;
  }

  const omittedType = type === 'number' ? 'text' : type;

  return (
    <Controller
      name={name}
      control={formMethods.control}
      rules={rulesComputed}
      render={({ field: { onChange: onChangeField, onBlur: onBlurField, ref, ...fieldRest } }) => (
        <FormFieldBase
          disabled={disabled}
          type={omittedType}
          error={Boolean(error)}
          {...props}
          onChange={(e) => {
            if (typeof onChange === 'function') {
              onChange(e);
            }
            onChangeField(e);
          }}
          onBlur={(e) => {
            if (typeof onBlur === 'function') {
              onBlur(e);
            }
            onBlurField();
          }}
          inputRef={ref}
          {...fieldRest}
        />
      )}
    />
  );
}

export default FormFieldRhfControlled;
