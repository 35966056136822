import { ReactElement } from 'react';
import { OptionsGroup } from '@shared/components/SelectField';

export interface IOption<T = string> {
  value: T;
  titleCode?: string;
  title?: string | ReactElement;
  onChange?: () => void;
}

export const callForwardingTimeoutOptions: { value: number; titleCode: string; type?: string }[] = [
  { value: -1, titleCode: 'IMMEDIATELY_ON_ALL_DEVICES', type: 'SIP_AND_MOBILE' },
  { value: 0, titleCode: 'IMMEDIATELY', type: 'MOBILE_ONLY' },
  { value: 5, titleCode: 'FIVE_SECONDS', type: 'MOBILE_ONLY' },
  { value: 10, titleCode: 'TEN_SECONDS', type: 'MOBILE_ONLY' },
  { value: 15, titleCode: 'FIFTEEN_SECONDS', type: 'MOBILE_ONLY' },
  { value: 30, titleCode: 'THIRTY_SECONDS', type: 'MOBILE_ONLY' },
];

export const callForwardingTimeoutGroups: OptionsGroup<{
  value: number;
  titleCode: string;
  type?: string;
}>[] = [
  {
    groupName: 'SIP_AND_MOBILE',
    dataKeyToGroupBy: 'type',
    hideTitle: true,
  },
  {
    groupName: 'MOBILE_ONLY',
    titleCode: 'MOBILE_ONLY',
    dataKeyToGroupBy: 'type',
  },
];
