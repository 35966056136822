import React from 'react';
import Typography from '@shared/components/Typography';
import { ILazyTableRowCellType } from './RowCell.interfaces';
import Cell from '../Cell';

export const RowCell = <T,>({ data, column, row, col }: ILazyTableRowCellType<T>) => {
  const { id, Renderer } = column;
  const value = (data?.[id as keyof T] || '') as React.ReactNode; // TODO fix type

  return (
    <Cell column={column}>
      {Renderer ? (
        <Renderer column={column} item={data} row={row} col={col} />
      ) : (
        <Typography type={'text3'} color={'tertiary900'}>
          {value}
        </Typography>
      )}
    </Cell>
  );
};

export default RowCell;
