import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_HISTORY_STATISTICS_QUERY,
  HAS_HISTORY_CALLS_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { BarDiagram, LineDiagram } from '@components/Diagram';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Button } from '@shared/components/Button/Button';
import {
  BarChartIcon,
  ExcelExportIcon,
  LineChartIcon,
  StatisticsIcon,
  XIcon,
} from '@shared/assets/images/icons';
import SelectField from '@shared/components/SelectField/SelectField';
import { FormProvider, useForm } from 'react-hook-form';
import { getDateList } from '@/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MessageDialog from '@shared/components/MessageDialog';
import PageError from '@shared/components/PageError';
import {
  Chart,
  FontSpec,
  InteractionItem,
  InteractionMode,
  ScriptableTooltipContext,
  TooltipItem,
  TooltipPositioner,
} from 'chart.js';
import clsx from 'clsx';
import { getStorageItem } from '@components/storage/storage';
import { formatDate, formatTimeHour } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import { endOfDay, startOfDay, format, differenceInCalendarWeeks } from 'date-fns';
import StaticDatePicker from '@components/StaticDatePicker';
import ControlButtons from '@shared/components/ControlButtons';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import BodyContainer from '@/layouts/BodyContainer';
import {
  arrowIcon,
  filterOptionsPeriod,
  filterOptionsShort,
  getRedirectDates,
  getSelectedOptionsType,
  periodShort,
  periodType,
  redirectValues,
  LINE_DIAGRAM_TENSION,
} from './StatisticsByCalls.constants';
import { IDiagramData, IHistoryStatistics } from './StatisticsByCalls.interfaces';
import { UseStatisticsByCallsStyles } from './StatisticsByCalls.styles';

export const StatisticsByCalls: FunctionComponent = () => {
  const classes = UseStatisticsByCallsStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [translate] = useTranslation();
  const theme: IThemeColorOptions = useTheme();
  const formMethods = useForm();
  const navigate = useNavigate();
  const { watch, setValue } = formMethods;
  const [chartSwitch, setChartSwitch] = useState(true);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [selectedDayValue, setSelectedDayValue] = useState<Date | null>(null);
  const [prevSelectedFilter, setPrevSelectedFilter] = useState<{
    from?: string | null;
    to?: string | null;
    period?: string | null;
    short?: string | null;
  } | null>(null);
  const filterShortValue = watch('filterShort');
  const filterPeriodValue = watch('filterPeriod');
  const sFrom = searchParams.get('from');
  const sTo = searchParams.get('to');
  const sPeriod = searchParams.get('period');
  const sFilterShort = searchParams.get('filterShort') || periodShort.day;
  const sChartType = searchParams.get('chartType');
  const {
    currentDay,
    currentWeek,
    endWeek,
    pastWeek,
    endPastWeek,
    startMonth,
    endMonth,
    startPastMonth,
    endPastMonth,
    startWholeYear,
    endWholeYear,
  } = getDateList();
  const [hasSearchParams, setHasSearchParams] = useState(false);

  const historyPeriodValues: { [key: string]: { from: Date; to: Date } } = useMemo(
    () => ({
      [periodType.week]: { from: currentWeek, to: endWeek },
      [periodType.pastWeek]: { from: pastWeek, to: endPastWeek },
      [periodType.month]: { from: startMonth, to: endMonth },
      [periodType.pastMonth]: { from: startPastMonth, to: endPastMonth },
      [periodType.year]: { from: startWholeYear, to: endWholeYear },
    }),
    [
      currentWeek,
      endWeek,
      pastWeek,
      endPastWeek,
      startMonth,
      endMonth,
      startPastMonth,
      endPastMonth,
      startWholeYear,
      endWholeYear,
    ]
  );

  const setPeriodValues = (isFrom: boolean) => {
    if (filterPeriodValue) {
      if (filterPeriodValue === periodType.dayHour) {
        return isFrom ? sFrom || currentWeek : sTo || endWeek;
      }
      return historyPeriodValues[filterPeriodValue][isFrom ? 'from' : 'to'];
    }
    return isFrom ? currentWeek : endWeek;
  };

  const {
    data: historyStatistics,
    refetch: getHistoryStatistics,
    error: statisticsError,
  } = useQuery(GET_HISTORY_STATISTICS_QUERY, {
    variables: {
      data: {
        from: setPeriodValues(true),
        to: setPeriodValues(false),
        timeUnit: filterShortValue || periodShort.day,
      },
    },
  });
  const { data: isHistoryData } = useQuery(HAS_HISTORY_CALLS_QUERY);
  const hasHistoryCalls = isHistoryData?.hasHistoryCalls;
  const historyData = historyStatistics?.getHistoryStatistics;
  const { data: { user = {} } = {} } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const minDate = getCurrentDomain(user)?.createdAt;
  const [errorState, setErrorState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: 'SOMETHING_WRONG_MESSAGE',
  });
  const createdAt = useMemo(() => {
    const currentDomain = getCurrentDomain(user);
    const now = new Date();
    return currentDomain?.createdAt ? new Date(currentDomain?.createdAt) : now;
  }, [user]);
  const sType = getSelectedOptionsType(filterPeriodValue);

  const disabledOptions = useMemo(() => {
    const result: Array<number> = [];

    if (!minDate) return result;

    const nowDateObject = new Date();
    const domainCreationDate = new Date(minDate);

    const [createMonth, nowMonth] = [domainCreationDate.getMonth(), nowDateObject.getMonth()];
    const [createYear, nowYear] = [domainCreationDate.getFullYear(), nowDateObject.getFullYear()];

    if (differenceInCalendarWeeks(domainCreationDate, nowDateObject, { weekStartsOn: 1 }) === 0)
      result.push(3); // past week
    if (createYear === nowYear && createMonth === nowMonth) result.push(5); // past month

    return result;
  }, [minDate]);

  useEffect(() => {
    setHasSearchParams(!searchParams.keys().next().done);
  }, [searchParams, setHasSearchParams]);

  const handlePointClick = useCallback(
    (element: InteractionItem[]) => {
      if (element[0]) {
        let historyPath = '';
        const { dateFrom, dateTo } = getRedirectDates(
          historyData[element[0].index - 1].timePeriod,
          filterShortValue
        );
        if (element.length === 1) {
          const { direction, missed } = redirectValues[element[0].datasetIndex];
          historyPath = `/history/?direction=${direction}&missed=${missed}&from=${dateFrom}&to=${dateTo}&period=custom`;
        } else {
          historyPath = `/history/?from=${dateFrom}&to=${dateTo}&period=custom`;
        }
        if (sPeriod === String(periodType.dayHour)) {
          const fromTime = formatTimeHour(element[0].index - 1);
          const toTime = formatTimeHour(element[0].index);
          historyPath += `&fromTime=${fromTime}&toTime=${toTime}`;
        }
        setTimeout(() => {
          navigate(historyPath);
        });
      }
    },
    [filterShortValue, historyData, navigate, sPeriod]
  );

  const setDiagramData = (data: IHistoryStatistics[]) => {
    if (data) {
      const detailType = filterShortValue || periodShort.day;
      const now = new Date();
      const { links, success, danger, warning } = theme?.color || {};
      const { labels, inSuccess, inNotSuccess, outSuccess, outNotSuccess } = data.reduce(
        (result: IDiagramData, item: IHistoryStatistics, index: number) => {
          const start = new Date(item.timePeriod);
          const { day, dayEnd, weekEnd, weekEndDay, monthEnd, month, monthShort, nextMonthShort } =
            formatDate(start);
          let end = dayEnd;
          if (detailType === periodShort.hour) {
            result.labels.push(formatTimeHour(index + 1));
          }
          if (detailType === periodShort.day) {
            result.labels.push(`${day} ${translate(monthShort)}`);
          }
          if (detailType === periodShort.week) {
            result.labels.push(
              `${day} ${
                monthShort !== nextMonthShort ? translate(monthShort) : ''
              } - ${weekEndDay} ${translate(nextMonthShort)}`
            );
            end = weekEnd;
          }
          if (detailType === periodShort.month) {
            result.labels.push(translate(`${month}_NOM`));
            end = monthEnd;
          }
          if (end < createdAt) {
            result.inSuccess.push(null);
            result.inNotSuccess.push(null);
            result.outSuccess.push(null);
            result.outNotSuccess.push(null);
          } else if (start < now) {
            result.inSuccess.push(item.inSuccess);
            result.inNotSuccess.push(item.inNotSuccess);
            result.outSuccess.push(item.outSuccess);
            result.outNotSuccess.push(item.outNotSuccess);
          }
          return result;
        },
        {
          labels: [''],
          inSuccess: [null],
          inNotSuccess: [null],
          outSuccess: [null],
          outNotSuccess: [null],
        }
      );
      // пустые значения в начале и конце массива данных добавлены для отступов
      labels.push('');
      inNotSuccess.push(null);
      inSuccess.push(null);
      outNotSuccess.push(null);
      outSuccess.push(null);
      return {
        labels,
        datasets: [
          {
            label: translate('INCOMING_OUT'),
            data: inNotSuccess,
            backgroundColor: chartSwitch ? danger[600] : danger[100],
            hoverBackgroundColor: chartSwitch ? danger[400] : danger[100],
            borderColor: danger[600],
            stack: 'IN',
            tension: LINE_DIAGRAM_TENSION,
          },
          {
            label: translate('INCOMING'),
            data: inSuccess,
            backgroundColor: chartSwitch ? links[600] : links[100],
            hoverBackgroundColor: chartSwitch ? links[400] : links[100],
            borderColor: links[600],
            stack: 'IN',
            tension: LINE_DIAGRAM_TENSION,
          },
          {
            label: translate('OUTGOING_OUT'),
            data: outNotSuccess,
            backgroundColor: chartSwitch ? warning[600] : warning[100],
            hoverBackgroundColor: chartSwitch ? warning[400] : warning[100],
            borderColor: warning[600],
            stack: 'OUT',
            tension: LINE_DIAGRAM_TENSION,
          },
          {
            label: translate('OUTGOING'),
            data: outSuccess,
            backgroundColor: chartSwitch ? success[600] : success[100],
            hoverBackgroundColor: chartSwitch ? success[400] : success[100],
            borderColor: success[600],
            stack: 'OUT',
            tension: LINE_DIAGRAM_TENSION,
          },
        ],
      };
    }
    return {
      labels: [],
      datasets: [],
    };
  };

  const data = setDiagramData(historyData);

  const options = useMemo(() => {
    const getOrCreateTooltip = (chart: Chart) => {
      let tooltipEl = chart.canvas.parentNode?.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.className = classes.tooltipWrapperClass;

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode?.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context: ScriptableTooltipContext<'line' | 'bar'>) => {
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }
      if (
        chartSwitch &&
        tooltip.dataPoints.findIndex((point: TooltipItem<'bar'>) => (point?.raw as number) > 0) < 0
      ) {
        tooltipEl.style.display = 'none';
        return;
      }
      tooltipEl.style.display = 'block';

      if (tooltip.dataPoints) {
        const { dataPoints } = tooltip;
        const tableHead = document.createElement('thead');

        const tr = document.createElement('tr');
        tr.style.borderWidth = '0px';

        const th = document.createElement('th');
        th.style.borderWidth = '0px';
        th.style.textAlign = 'left';
        th.style.fontSize = '0.875em';
        let labelText = '';
        if (sPeriod && sPeriod === String(periodType.dayHour)) {
          const rawIndex = dataPoints[0].dataIndex - 1;
          labelText = `${formatTimeHour(rawIndex)} - ${formatTimeHour(rawIndex + 1)}`;
        } else {
          labelText = dataPoints[0].label;
        }
        const text = document.createTextNode(labelText);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);

        const tableBody = document.createElement('tbody');
        const dataOrder = [1, 0, 3, 2];
        const index = dataPoints[0].dataIndex;
        dataOrder.forEach((i: number) => {
          const point = data?.datasets[i].data[index];

          const iconPath = arrowIcon[i];
          const imgIcon = document.createElement('img');
          imgIcon.className = classes.tooltipIconClass;
          imgIcon.src = iconPath;

          const leftTextSpan = document.createElement('span');
          leftTextSpan.className = classes.tooltipLeftTextClass;
          leftTextSpan.append(data?.datasets[i].label);

          const rightTextSpan = document.createElement('span');
          rightTextSpan.className = classes.tooltipRightTextClass;
          let val = point || 0;
          if (i === 1 || i === 3) {
            val += data?.datasets[i - 1].data[index] || 0;
          }
          rightTextSpan.append(`${val}`);

          const trd = document.createElement('tr');
          trd.style.backgroundColor = 'inherit';
          trd.style.borderWidth = '0px';

          const td = document.createElement('td');
          td.style.borderWidth = '0px';

          td.appendChild(imgIcon);
          td.appendChild(leftTextSpan);
          td.appendChild(rightTextSpan);
          trd.appendChild(td);
          tableBody.appendChild(trd);
        });

        const tableRoot = tooltipEl.querySelector('table');

        while (tableRoot?.firstChild) {
          tableRoot?.firstChild.remove();
        }

        tableRoot?.appendChild(tableHead);
        tableRoot?.appendChild(tableBody);
      }

      const {
        offsetLeft: positionX,
        width: canvasRawWidth,
        height: canvasRawHeight,
      } = chart.canvas;
      const canvasWidth = canvasRawWidth / window.devicePixelRatio;
      const canvasHeight = canvasRawHeight / window.devicePixelRatio;
      const { y: offsetTop } = chart.canvas.getBoundingClientRect();
      const { innerHeight: windowHeight } = window;
      const bottomPadding = windowHeight - canvasHeight - offsetTop;
      const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = tooltipEl;
      const paddingStepSize = 16;
      const xPosition = positionX + tooltip.caretX;
      const isTooltipReachBottom =
        tooltip.caretY + tooltipHeight / 2 + paddingStepSize > canvasHeight + bottomPadding;

      const setLeftPosition = () => {
        if (xPosition + tooltipWidth > canvasWidth) {
          return `${xPosition - tooltipWidth - paddingStepSize}px`;
        }
        return `${xPosition + paddingStepSize}px`;
      };

      const setTopPosition = () => {
        if (isTooltipReachBottom) {
          return `${canvasHeight - (tooltipHeight + paddingStepSize)}px`;
        }
        if (tooltip.caretY - tooltipHeight / 2 < 0) {
          return `${paddingStepSize}px`;
        }
        return `${tooltip.caretY - tooltipHeight / 2}px`;
      };

      const setArrowClass = () => {
        let isArrowBottom = false;
        let isArrowLeft = false;
        if (isTooltipReachBottom) {
          isArrowBottom = true;
        }
        if (xPosition + tooltipWidth > canvasWidth) {
          isArrowLeft = true;
        }
        return clsx(classes.tooltipWrapperClass, {
          [classes.tooltipArrowLeft]: isArrowLeft,
          [classes.tooltipArrowRight]: !isArrowLeft,
          [classes.tooltipArrowBottomPosition]: isArrowBottom,
        });
      };

      tooltipEl.style.opacity = '1';
      tooltipEl.style.left = setLeftPosition();
      tooltipEl.style.top = setTopPosition();
      tooltipEl.style.font = String((tooltip.options.bodyFont as FontSpec)?.size);
      tooltipEl.className = setArrowClass();
      tooltipEl.style.padding = `${Number(tooltip.options.padding) * 2}px`;
    };
    const now = new Date();
    const xMaxVisible = historyData?.findIndex(
      (item: IHistoryStatistics) => new Date(item.timePeriod) > now
    );
    const annotationVisible =
      xMaxVisible &&
      xMaxVisible >= 0 &&
      ((historyData?.length || 0) - (xMaxVisible || 0)) / (historyData?.length || 1) > 0.1;

    return {
      responsive: true,
      aspectRatio: 2.5,
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          grace: '1%',
          ticks: {
            callback: (value: number): string => {
              if (value !== Math.round(value)) {
                return '';
              }
              return String(value);
            },
          },
        },
        x: {
          grace: '10%',
        },
      },
      interaction: {
        intersect: false,
        mode: 'index' as InteractionMode,
        axis: 'x',
      },
      plugins: {
        legend: {
          display: false,
          events: [],
        },
        tooltip: {
          enabled: false,
          position: 'nearest' as TooltipPositioner,
          external: externalTooltipHandler,
        },
        annotation: {
          annotations: {
            nodata: {
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              display: xMaxVisible >= 0,
              // 0.5 сдвигает аннотацию на полшага, что область не была посередине последнего имеющегося значения.
              xMin: chartSwitch ? xMaxVisible + 0.5 : xMaxVisible + 0.2,
              borderColor: theme?.color?.tertiary[100],
              backgroundColor: `${theme?.color?.tertiary[100]}99`, // opacity: 0.6
              label: {
                content:
                  annotationVisible && translate('THERE_IS_NO_DATA_TO_PLOT_THE_GRAPH').split('\n'),
                enabled: true,
                color: theme?.color?.tertiary[600],
                textAlign: 'center',
              },
            },
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          pointStyle: 'circle',
          hoverRadius: 6,
        },
      },
    };
  }, [classes, sPeriod, theme?.color, data, historyData, translate, chartSwitch]);

  const disabledFilterShortList = useMemo(() => {
    if (sType === periodType.dayHour) {
      return [periodShort.day, periodShort.week, periodShort.month];
    }
    if (sType === periodType.year) {
      return [periodShort.day, periodShort.hour];
    }
    if (sType === periodType.month) {
      return [periodShort.month, periodShort.hour];
    }
    if (sType === periodType.week) {
      return [periodShort.week, periodShort.month, periodShort.hour];
    }
    return [periodShort.hour];
  }, [sType]);

  useEffect(() => {
    if (!selectedDayValue && sFrom && sTo) {
      const d = new Date(sFrom);
      setSelectedDayValue(d);
    }
  }, [selectedDayValue, sFrom, sTo]);

  useEffect(() => {
    if (!showDayPicker) {
      const periodNumberVal = Number(sPeriod);
      if (periodNumberVal && periodNumberVal !== filterPeriodValue) {
        setValue('filterPeriod', periodNumberVal);
        if (periodNumberVal !== periodType.dayHour) {
          searchParams.set('from', historyPeriodValues[periodNumberVal].from.toISOString());
          searchParams.set('to', historyPeriodValues[periodNumberVal].to.toISOString());
          setSearchParams(searchParams);
        }
      }
      if (sFilterShort && sFilterShort !== filterShortValue && filterPeriodValue) {
        const d = disabledFilterShortList;
        if (d.includes(sFilterShort as periodShort)) {
          if (
            (d.includes(periodShort.day) || d.includes(periodShort.month)) &&
            d.includes(periodShort.hour)
          ) {
            searchParams.set('filterShort', periodShort.week);
            setValue('filterShort', periodShort.week);
          }
          if (
            d.includes(periodShort.week) &&
            d.includes(periodShort.month) &&
            d.includes(periodShort.hour)
          ) {
            searchParams.set('filterShort', periodShort.day);
            setValue('filterShort', periodShort.day);
          }
        } else {
          setValue('filterShort', sFilterShort as periodShort);
        }
        setSearchParams(searchParams);
      }
      if (!prevSelectedFilter && periodNumberVal === periodType.dayHour) {
        setPrevSelectedFilter({});
      }
      if (sChartType) {
        setChartSwitch(sChartType !== 'line');
      }
    }
  }, [
    sPeriod,
    searchParams,
    setSearchParams,
    historyPeriodValues,
    filterPeriodValue,
    setValue,
    sFilterShort,
    sChartType,
    filterShortValue,
    disabledFilterShortList,
    showDayPicker,
    prevSelectedFilter,
  ]);

  useEffect(() => {
    if (!showDayPicker) {
      if (sFrom && sTo && filterShortValue === sFilterShort) {
        const f = filterShortValue;
        if (
          (sType === periodType.year && (f === periodShort.day || f === periodShort.hour)) ||
          (sType === periodType.month && (f === periodShort.month || f === periodShort.hour))
        ) {
          setValue('filterShort', periodShort.week);
        } else if (
          sType === periodType.week &&
          (f === periodShort.week || f === periodShort.month || f === periodShort.hour)
        ) {
          setValue('filterShort', periodShort.day);
        } else if (
          sType === periodType.dayHour &&
          (f === periodShort.day || f === periodShort.week || f === periodShort.month)
        ) {
          setValue('filterShort', periodShort.hour);
        } else if (!showDayPicker) {
          getHistoryStatistics({
            data: {
              from: new Date(sFrom),
              to: new Date(sTo),
              timeUnit: filterShortValue,
            },
          }).catch(() => {
            setErrorState((prevState) => ({
              ...prevState,
              isOpen: true,
            }));
          });
        }
      }
    }
  }, [
    sFrom,
    sTo,
    getHistoryStatistics,
    setValue,
    sType,
    filterShortValue,
    sFilterShort,
    showDayPicker,
  ]);

  function handleFilterPeriodChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const sValue = Number(value);
    if (
      sValue === periodType.month ||
      sValue === periodType.pastMonth ||
      sValue === periodType.year
    ) {
      setChartSwitch(false);
    }
    if (sValue === periodType.week || sValue === periodType.pastWeek) {
      setChartSwitch(true);
    }
    if (sValue === periodType.dayHour) {
      setSelectedDayValue(currentDay);
      setShowDayPicker(true);
    } else {
      searchParams.set('from', historyPeriodValues[value].from.toISOString());
      searchParams.set('to', historyPeriodValues[value].to.toISOString());
      searchParams.set('period', value);
      setSearchParams(searchParams);
    }
  }

  function handleDaySelect(selectedDay: Date | null) {
    if (selectedDay) {
      setSelectedDayValue(selectedDay);
    }
  }

  function handleDaySelectConfirm() {
    if (sPeriod !== String(periodType.dayHour)) {
      setPrevSelectedFilter({
        from: sFrom,
        to: sTo,
        period: sPeriod,
        short: sFilterShort,
      });
    }
    if (selectedDayValue) {
      searchParams.set('from', startOfDay(selectedDayValue).toISOString());
      searchParams.set('to', endOfDay(selectedDayValue).toISOString());
      searchParams.set('filterShort', String(periodShort.hour));
      searchParams.set('period', String(periodType.dayHour));
      setSearchParams(searchParams);
    }
    setShowDayPicker(false);
  }

  function handleDaySelectCancel() {
    setShowDayPicker(false);
    const prevDate = new Date(sFrom || currentDay);
    setSelectedDayValue(prevDate);
    if (!prevSelectedFilter) {
      setValue('filterPeriod', sPeriod || periodType.week);
    }
  }

  function handleFilterShortChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    searchParams.set('filterShort', value);
    setSearchParams(searchParams);
  }

  function handleResetDayFilter() {
    const { from, to, period, short } = prevSelectedFilter || {};
    setValue('filterPeriod', period ? Number(period) : periodType.week);
    searchParams.set('from', from || currentWeek.toISOString());
    searchParams.set('to', to || endWeek.toISOString());
    searchParams.set('filterShort', short || String(periodShort.day));
    searchParams.set('period', period || String(periodType.week));
    setSearchParams(searchParams);
    setPrevSelectedFilter(null);
  }

  function handleReportClick() {
    const token = getStorageItem('token');
    let url = process.env.API_URL;
    if (token && url) {
      const reportFrom = sFrom || currentWeek.toISOString();
      const reportTo = sTo || endWeek.toISOString();
      const reportTimeUnit = sFilterShort || periodShort.day;
      url += `/history/statistics/report?from=${reportFrom}&to=${reportTo}&timeUnit=${reportTimeUnit}`;
      url += `&token=${token}`;
      window.open(url);
    }
  }

  function handleSwitchChartType() {
    const chartType = !chartSwitch;
    setChartSwitch(chartType);
    searchParams.set('chartType', chartType ? 'bar' : 'line');
    setSearchParams(searchParams);
  }

  function handleCloseErrorDialog() {
    setErrorState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  const renderStatisticsChart = () => {
    if (chartSwitch) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore incompatible 'options' type
      return <BarDiagram options={options} data={data} onClick={handlePointClick} />;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore incompatible 'options' type
    return <LineDiagram options={options} data={data} onClick={handlePointClick} />;
  };

  if (statisticsError) {
    return <PageError />;
  }

  const renderEmptyContent = () => (
    <div className={classes.emptyBlock}>
      <StatisticsIcon className={classes.statisticsIcon} />
      <Typography color={'tertiary600'} type={'text3'}>
        {translate('HERE_WILL_BE_CALLS_STATISTIC')}
      </Typography>
    </div>
  );

  return (
    <FormProvider {...formMethods}>
      <form action={''}>
        <BodyContainer
          breadCrumbs={
            <Breadcrumbs>
              <div className={classes.statisticsLineItem}>
                <SelectField
                  translating
                  defaultValue={periodShort.day}
                  name={'filterShort'}
                  size={'small'}
                  className={classes.defaultElementWidth9}
                  data={filterOptionsShort}
                  onChange={handleFilterShortChange}
                  disabledOptions={disabledFilterShortList}
                  titleKey={'title'}
                  valueKey={'value'}
                />
              </div>
              <div className={classes.statisticsLineItem}>
                <SelectField
                  translating
                  defaultValue={periodType.week}
                  name={'filterPeriod'}
                  size={'small'}
                  className={clsx(classes.defaultElementWidth14, {
                    [classes.statisticsHiddenElement]: prevSelectedFilter,
                  })}
                  onChange={handleFilterPeriodChange}
                  data={filterOptionsPeriod}
                  titleKey={'title'}
                  valueKey={'value'}
                  disabledOptions={disabledOptions}
                />
                {prevSelectedFilter && (
                  <Flex
                    className={clsx(classes.statisticsDayWrapper, classes.defaultElementWidth14)}
                    justifyContent={'spaceBetween'}
                  >
                    <Button
                      clear
                      className={classes.statisticsDayFilterButton}
                      onClick={() => setShowDayPicker(true)}
                    >
                      <Typography
                        className={classes.statisticsDayText}
                        type={'text3'}
                        color={'tertiary900'}
                      >
                        {format(selectedDayValue || new Date(currentDay), 'dd.MM.yyyy')}
                      </Typography>
                    </Button>
                    <Button clear onClick={handleResetDayFilter}>
                      <XIcon className={classes.statisticsDayFilterReset} />
                    </Button>
                  </Flex>
                )}
              </div>
              <div className={classes.statisticsLineItem}>
                <Button clear rounded onClick={handleSwitchChartType}>
                  {chartSwitch ? (
                    <LineChartIcon className={classes.xls} />
                  ) : (
                    <BarChartIcon className={classes.xls} />
                  )}
                </Button>
              </div>
              <div className={classes.statisticsLineItem}>
                <Button onClick={handleReportClick} clear rounded>
                  <ExcelExportIcon className={classes.xls} />
                </Button>
              </div>
            </Breadcrumbs>
          }
        >
          <div className={classes.statisticsRoot}>
            {!hasSearchParams && !hasHistoryCalls ? renderEmptyContent() : renderStatisticsChart()}
          </div>
          <MessageDialog
            isOpen={errorState.isOpen}
            title={translate(errorState.title)}
            message={translate(errorState.message)}
            onCancel={handleCloseErrorDialog}
            buttonCancelProps={{
              titleCode: 'CLOSE',
              onClick: handleCloseErrorDialog,
            }}
          />
          <MessageDialog
            isOpen={showDayPicker}
            bodyWrapperClass={classes.messageDialogBodyWrapper}
            contentClass={classes.statisticsDatePicker}
            buttonsBlockClass={classes.messageDialogButtonsBlock}
            title={translate('CHOOSE_DAY')}
            onCancel={handleDaySelectCancel}
            renderContent={
              <StaticDatePicker
                minDate={minDate}
                value={selectedDayValue}
                onChange={handleDaySelect}
              />
            }
            buttonCancelProps={{
              onClick: handleDaySelectCancel,
            }}
            buttonSuccessProps={{
              titleCode: 'APPLY',
              onClick: handleDaySelectConfirm,
              className: classes.messageDialogButtonSuccess,
            }}
          />
        </BodyContainer>
      </form>
    </FormProvider>
  );
};

export default StatisticsByCalls;
