type OS = 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux';

export function getOS() {
  const { userAgent } = window.navigator;

  // userAgentData is not stable across browsers, so we need to check for it
  const typedWindow = window as typeof window & {
    navigator: { userAgentData?: { platform?: string } };
  };
  const platform = typedWindow.navigator?.userAgentData?.platform || window.navigator.platform;

  const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os: OS | null = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
}
