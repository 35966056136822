import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { IPayment } from '../Payments.interfaces';

export const PaymentSumCell: FunctionComponent<ICellProps<IPayment>> = ({ item }) => {
  const { sum = 0 } = item || {};
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;
  const value = `${toPrecision(String(sum))}  ₽`;

  if (!item) {
    return <div />;
  }

  return <HighlightedText type={'sum'} text={value} query={searchText} />;
};
