import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const BusinessCardStyles = makeStyles(
  ({ color: { tertiary, danger, secondary } }) => ({
    root: {
      display: 'flex',
      width: '35em',
      height: '100%',
      flexDirection: 'column',
      paddingBottom: '5em',
    },
    actions: {
      display: 'flex',
      width: '36em',
      justifyContent: 'space-between',
      paddingTop: '1em',
      '& > :not(:first-child)': {
        marginLeft: '1em',
        paddingTop: 0,
      },
    },
    addEmployeeContactsCard: {
      width: '35em',
      marginTop: '1em',
      position: 'relative',
      border: `solid 1px ${secondary[100]}`,
      backgroundColor: secondary[50],
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '1em 1.5em 0.75em 1em',
      '&:before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '17px',
        top: '-18px',
        borderWidth: '9px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${secondary[50]} transparent`,
      },
    },
    addNumberButton: {
      width: '17em',
      marginTop: '0.25em',
    },
    bottomContainer: {
      marginTop: '2em',
      borderTop: `1px solid ${tertiary[200]}`,
      width: '100%',
    },
    button: {
      width: '14em',
      maxHeight: '2.5em',
      margin: '0.25em 0 1em',
    },
    cancelButton: {
      width: '8em',
      margin: '0.25em 0 1em',
    },
    confirmPopupControls: {
      textAlign: 'right',
    },
    confirmPopupText: {
      paddingBottom: '1.5em',
      textAlign: 'left',
    },
    counterRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1em',
      marginBottom: '2em',
    },
    controlSize: {
      width: '17em',
      height: '2.5em',
    },
    fieldSize: {
      width: '17em',
      paddingTop: '1em',
      paddingBottom: '2em',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      paddingLeft: '2em',
      width: '37em',
    },
    listControl: {
      marginLeft: '1em',
      padding: '0.625em',
    },
    listItem: {
      display: 'flex',
      paddingBottom: '0.5em',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    notificationIcon: {
      marginLeft: '0.5em',
      color: tertiary[400],
      '&:hover': {
        color: tertiary[400],
      },
    },
    textForm: {
      marginBottom: '2em',
    },
    textFormContainer: {
      position: 'relative',
      marginTop: '1em',
    },
    textFieldCounter: {
      position: 'absolute',
      bottom: '4em',
      right: '1em',
    },
    textFieldCounterError: {
      color: danger[600],
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
