import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { PlayIcon, XIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import { CircularProgress } from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { SYNTHESIS_AUDIO_MUTATION } from '@/client/mutations';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import clsx from 'clsx';
import resultify from '@shared/utils/resultify';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { useStyledMessageBlockStyles } from './StyledMessageBlock.styles';

type Props = {
  renderedTitle?: JSX.Element;
  messageValue: string;
  greetingMessage?: string;
  onChanges: (value: string) => void;
};

export const StyledMessageBlock = ({
  renderedTitle,
  messageValue = '',
  greetingMessage = '',
  onChanges,
}: Props) => {
  const classes = useStyledMessageBlockStyles();
  const [translate] = useTranslation();
  const formMethods = useForm({
    defaultValues: {
      message: '',
      fileUrl: '',
    },
  });
  const {
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = formMethods;
  const message = watch('message');
  const fileUrl = watch('fileUrl');
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const completedMessage = `${greetingMessage} ${message}`;

  const onCompleted = () => {
    setExpanded(true);
  };

  const [synthesisAudio, { data, loading: loadingSyntheticAudio }] = useMutation(
    SYNTHESIS_AUDIO_MUTATION,
    { onCompleted }
  );

  const syntheticAudioUrl = data?.synthesis.url;

  useEffect(() => {
    if (!message.length) {
      setError('message', { type: 'error', message: translate('ENTER_MESSAGE') });
    } else clearErrors();
  }, [clearErrors, message, setError, translate]);

  useEffect(() => {
    if (messageValue && !message.length) {
      setValue('message', messageValue);
    }
    setValue('fileUrl', syntheticAudioUrl || '');
  }, [message, messageValue, setValue, syntheticAudioUrl]);

  useEffect(() => {
    setExpanded(false);
  }, [message]);

  const handlePlay = useCallback(async () => {
    if (!message) {
      return;
    }

    const result = await resultify(synthesisAudio({ variables: { text: completedMessage } }));
    if (result.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
    }
  }, [completedMessage, message, synthesisAudio]);

  const renderAudioSectionControlContent = () => {
    if (loadingSyntheticAudio) {
      return <CircularProgress size={'1em'} color={'primary'} />;
    }

    return (
      <>
        <PlayIcon className={classes.audioSectionControlButtonIcon} />
        {translate('LISTEN')}
      </>
    );
  };

  const renderAudioSectionControl = () => {
    if (isExpanded) {
      return null;
    }
    return (
      <Button
        variant={'secondary'}
        className={classes.audioSectionControlButton}
        disabled={!message || loadingSyntheticAudio}
        onClick={handlePlay}
      >
        {renderAudioSectionControlContent()}
      </Button>
    );
  };

  const renderAudioSection = () => {
    if (!isExpanded) {
      return null;
    }
    return (
      <div className={classes.audioControlsSection}>
        <AudioPlayer autoPlay isPaused={false} isExpanded collapseOnEnd={false} source={fileUrl} />
        <Button
          variant={'tertiary'}
          className={classes.audioControlButton}
          onClick={() => setExpanded(false)}
        >
          <XIcon />
        </Button>
      </div>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <Flex className={classes.marginTop1} direction={'column'}>
        <Flex
          className={classes.headWrapper}
          alignItems={'center'}
          justifyContent={'spaceBetween'}
          fullWidth
        >
          {!isExpanded && (
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('MESSAGE')}
            </Typography>
          )}
          {renderAudioSection()}
          {renderAudioSectionControl()}
        </Flex>
        <Flex
          className={clsx(classes.textAreaWrapper, {
            [classes.textAreaWrapperError]: errors.message,
          })}
          direction={'column'}
        >
          {renderedTitle}
          <Controller
            name={'message'}
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <textarea
                className={classes.areaRoot}
                rows={4}
                maxLength={4000}
                value={value}
                ref={ref}
                onChange={(e) => {
                  onChange(e.target.value);
                  onChanges(e.target.value);
                }}
              />
            )}
          />
        </Flex>
        {errors.message && (
          <Typography className={classes.marginTop05} type={'text4'} color={'danger600'}>
            {errors.message.message}
          </Typography>
        )}
      </Flex>
    </FormProvider>
  );
};

export default StyledMessageBlock;
