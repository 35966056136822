import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { callTypes, IHistoryList } from '../HistoryPage.interfaces';

export const DurationCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const [translate] = useTranslation();

  if (item) {
    const { duration, call, fax } = item;
    const renderCell = () => {
      if (call === callTypes.IncomingMissed) {
        return (
          <Typography type={'text3'} color={'danger600'}>
            {fax ? '' : translate('HISTORY_MISSED')}
          </Typography>
        );
      }
      if (call === callTypes.OutgoingMissed) {
        return (
          <Typography type={'text3'} color={'danger600'}>
            {translate('HISTORY_NOTCOMPLITE')}
          </Typography>
        );
      }
      return (
        <Typography type={'text3'} color={'tertiary900'}>
          {duration}
        </Typography>
      );
    };

    return <>{renderCell()}</>;
  }

  return <div />;
};
