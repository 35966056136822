import MessageDialog from '@shared/components/MessageDialog';
import Typography from '@shared/components/Typography/Typography';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IErrorDialog } from './ErrorDialog.interfaces';

export const ErrorDialog: FunctionComponent<IErrorDialog> = ({
  showErrorDialog,
  handleCloseErrorDialog,
}) => {
  const [translate] = useTranslation();

  const renderModalContentErrors = () => {
    const { showDialog, message } = showErrorDialog;
    if (showDialog) {
      return (
        <Typography type={'text3'} color={'tertiary900'}>
          {translate(message)}
        </Typography>
      );
    }
    return <div />;
  };

  return (
    <MessageDialog
      isOpen={showErrorDialog.showDialog}
      title={translate(showErrorDialog.title)}
      onCancel={handleCloseErrorDialog}
      renderContent={renderModalContentErrors()}
    />
  );
};
