import { makeStyles } from '@material-ui/core/styles';

export const useCallDetailsStyle = makeStyles(
  ({
    color: { tertiary, primary, base, links, success },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    lineItem: {
      paddingLeft: '1em',
    },
    headLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '1em',
      height: '2em',
      minHeight: '2em',
      backgroundColor: tertiary['50'],
      borderBottom: `solid 1px ${base}`,
      '& div': {
        boxSizing: 'content-box',
      },
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    cellLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
      '& div': {
        boxSizing: 'content-box',
      },
      '&:hover': {
        backgroundColor: tertiary['50'],
      },
    },
    dateLine: {
      paddingRight: '1em',
      height: '2em',
      backgroundColor: tertiary['50'],
      borderTop: `solid 1px${base}`,
      borderBottom: `solid 1px${base}`,
      '& div': {
        boxSizing: 'content-box',
      },
    },
    cellDirection: {
      width: '1em',
      paddingLeft: '2em',
    },
    cellNumber: {
      paddingLeft: '1.5em',
    },
    cellData: {
      width: '11em',
      marginLeft: 'auto',
      paddingLeft: '1em',
    },
    cellDuration: {
      width: '8em',
      marginLeft: 'auto',
      paddingLeft: '1em',
    },
    cellCost: {
      width: '5em',
      textAlign: 'right',
      marginLeft: 'auto',
      paddingRight: '10em',
      paddingLeft: '1em',
    },
    tableBody: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      '& > div': {
        width: '100% !important',
      },
    },
    textHover: {
      cursor: 'pointer',
      '&:hover *': {
        textDecoration: 'underline',
        color: primary['800'],
      },
    },
    links: {
      color: links[600],
    },
    success: {
      color: success[600],
    },
    xls: {
      transition: createTransition(['color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      color: tertiary['900'],
      '&:hover': {
        color: primary['600'],
      },
    },
    list: {
      willChange: 'opacity !important',
    },
  }),
  {
    index: 5,
  }
);
