import { makeStyles } from '@material-ui/core/styles';

export const ButtonFilterStyles = makeStyles(
  ({ color: { tertiary, success, links, danger, warning } }) => ({
    wrap: {
      transition: 'all 0.3s ease-out 0s',
      display: 'inline-flex',
      alignItems: 'flex-start',
      borderRadius: '5px',
    },
    border: {
      borderRight: `1px solid ${tertiary['300']}`,
      paddingRight: '0.5em',
      margin: '0.5em 0.5em 0 0',
      height: '2.5em',
    },
    hover: {
      backgroundColor: tertiary['50'],
    },
    selected: {
      backgroundColor: tertiary['100'],
    },
    icon: {
      width: '1em',
      paddingRight: '0.5em',
      boxSizing: 'content-box',
    },
    noIcon: {
      marginLeft: '0.5em',
    },
    text: {
      display: 'block',
      height: '1em',
      lineHeight: '1em',
      textAlign: 'left',
    },
    block: {
      display: 'block',
    },
    black: {
      color: tertiary[900],
    },
    links: {
      color: links[600],
    },
    danger: {
      color: danger[600],
    },
    tertiary: {
      color: tertiary[600],
    },
    warning: {
      color: warning[600],
    },
    success: {
      color: success[600],
    },
    button: {
      minWidth: '5.5em',
      borderRadius: '6px',
      padding: '0.25em 0.5em',
    },
    textLine: {
      padding: '4px 0px',
      display: 'flex',
      justifyContent: 'left',
    },
    title: {
      padding: '0',
      '&:last-child': {
        paddingRight: '0',
      },
      '&:first-child': {
        paddingLeft: '0',
      },
    },
    disabled: {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  })
);
