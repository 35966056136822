import generatePassword from 'password-generator';
import zxcvbn from 'zxcvbn';

const maxLength = 12;
const minLength = 3;
const numberCount = 1;

function maybe(): boolean {
  return Math.random() < 0.7;
}

function randomCapitalize(s: string): string {
  return maybe() ? s.charAt(0).toUpperCase() + s.slice(1) : s;
}

function randomNumber(): number {
  return Math.floor(Math.random() * 8) + 1; // exclude zero because it looks like O letter
}

function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min)) + min;
}
export function customPasswordGenerator() {
  let password = '';
  while (zxcvbn(password).score < 4) {
    const randomLength = random(minLength, maxLength - minLength - numberCount);
    password =
      randomCapitalize(generatePassword(randomLength, true)) +
      (maybe() ? randomNumber() : '') +
      randomCapitalize(generatePassword(random(2, minLength), true));
    password += maybe() ? randomNumber() : '';
    password += randomCapitalize(generatePassword(maxLength - password.length, true));
  }
  return password;
}
