import { CHANGE_EMAIL_MUTATION } from '@/client/mutations';
import { useMutation } from '@apollo/client';
import { refreshAuthenticationState } from '@components/client/client';
import { setStorageItem } from '@components/storage/storage';
import * as Sentry from '@sentry/react';
import { CheckCircleIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import EmailField from '@shared/components/EmailField';
import MessageDialog from '@shared/components/MessageDialog';
import Typography from '@shared/components/Typography';
import { isEmailAlreadyRegisteredError } from '@shared/utils/apollo';
import clsx from 'clsx';
import React, { FunctionComponent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProfileChangeEmailStyles } from './ProfileChangeDialog.styles';

export interface IProfileChangeEmailFormData {
  newEmail: string;
  newEmailConfirmation: string;
}

export const ProfileChangeEmail: FunctionComponent<{
  onClose: () => void;
  onRefetchProfile: () => void;
  open: boolean;
  userEmail?: string;
}> = ({ userEmail, onClose, onRefetchProfile, open }) => {
  const classes = useProfileChangeEmailStyles();
  const [isSuccess, setIsSuccess] = useState(false);
  const [disableBackdropClick, setDisableBackdropClick] = useState(false);
  const [translate] = useTranslation();
  const onCompleted = (
    response: { changeEmail?: { accessToken?: string; refreshToken?: string } } | null
  ) => {
    const { changeEmail: { accessToken = undefined, refreshToken = undefined } = {} } =
      response || {};
    if (accessToken) {
      setStorageItem('token', accessToken);
      setStorageItem('refreshToken', refreshToken);
      refreshAuthenticationState();
      onRefetchProfile();
    }
  };
  const [changeEmail, { loading }] = useMutation(CHANGE_EMAIL_MUTATION, { onCompleted });
  const formMethods = useForm<{
    newEmail: string;
    newEmailConfirmation: string;
  }>({
    defaultValues: { newEmail: '', newEmailConfirmation: '' },
  });

  const { handleSubmit, setError } = formMethods;

  const handleFormSubmit = handleSubmit(
    ({ newEmail, newEmailConfirmation }: IProfileChangeEmailFormData) => {
      if (userEmail === newEmail) {
        setError('newEmail', {
          type: 'error',
          message: translate('NEW_AND_OLD_EMAIL_EQUALS'),
        });
        return;
      }

      if (newEmailConfirmation !== newEmail) {
        setError('newEmailConfirmation', {
          type: 'error',
          message: translate('EMAIL_CONFIRMATION_DOES_NOT_MATCH'),
        });
        return;
      }

      changeEmail({
        variables: {
          data: { newEmail },
        },
      })
        .then(() => {
          setIsSuccess(true);
          setDisableBackdropClick(false);
        })
        .catch((err) => {
          if (isEmailAlreadyRegisteredError(err)) {
            setError('newEmail', { message: translate('EMAIL_ALREADY_REGISTERED') });
            return;
          }

          setError('newEmail', { message: translate('UNKNOWN_ERROR') });
          Sentry.captureException(err);
        });
    }
  );

  const handleClose = () => {
    onClose();
    setDisableBackdropClick(false);
    if (!loading) {
      setTimeout(() => {
        formMethods.reset();
        setIsSuccess(false);
      }, 300); // prevent screen blinking
    }
  };

  return (
    <MessageDialog
      title={isSuccess ? translate('EMAIL_SUCCESSFULLY_CHANGED') : translate('CHANGE_EMAIL')}
      onCancel={handleClose}
      isOpen={open}
      contentClass={classes.content}
      disableBackdropClick={disableBackdropClick}
      renderContent={
        <>
          <FormProvider {...formMethods}>
            <form
              className={clsx(isSuccess && classes.hidden)}
              noValidate
              action={''}
              autoComplete={'off'}
            >
              <div className={classes.itemWrapper}>
                <EmailField
                  name={'newEmail'}
                  label={translate('NEW_EMAIL')}
                  required
                  onChange={() => setDisableBackdropClick(true)}
                />
              </div>
              <div className={classes.itemWrapper}>
                <EmailField
                  name={'newEmailConfirmation'}
                  label={translate('CONFIRM_NEW_EMAIL')}
                  required
                  onChange={() => setDisableBackdropClick(true)}
                />
              </div>
              <div className={classes.controlButtonsWrapper}>
                <Button className={classes.buttonCancel} variant="secondary" onClick={handleClose}>
                  <Typography>{translate('CANCEL')}</Typography>
                </Button>
                <Button
                  className={classes.buttonSave}
                  title={translate('SAVE')}
                  loading={loading}
                  onClick={handleFormSubmit}
                />
              </div>
            </form>
          </FormProvider>

          {isSuccess && (
            <>
              <div className={classes.iconSuccessContainer}>
                <CheckCircleIcon className={classes.iconSuccess} viewBox="0 0 16 16" />
              </div>
              <div className={classes.messageSuccess}>
                Новый логин будет применен для доступа ко всем вашим АТС
              </div>
              <div className={classes.controlButtonsWrapper}>
                <Button className={classes.buttonClose} onClick={handleClose}>
                  <Typography color="base" pxToEmSize={14}>
                    Закрыть
                  </Typography>
                </Button>
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default ProfileChangeEmail;
