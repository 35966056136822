import { getOS } from './platforms';

export function getGraviphoneDownloadLink() {
  const currentOS = getOS();
  if (currentOS === null) {
    console.warn('Unable to get current OS for specifying Graviphone download link!');
    return null;
  }
  switch (currentOS) {
    case 'Windows': {
      if (!process.env.LINK_GRAVIPHONE_DOWNLOAD_WINDOWS) {
        console.warn(
          'Detected Windows platform, but link for Graviphone Windows installer is not present in process.env!'
        );
        return null;
      }
      return process.env.LINK_GRAVIPHONE_DOWNLOAD_WINDOWS;
    }
    case 'Mac OS': {
      if (!process.env.LINK_GRAVIPHONE_DOWNLOAD_MAC) {
        console.warn(
          'Detected Mac OS platform, but link for Graviphone MacOS installer is not present in process.env!'
        );
        return null;
      }
      return process.env.LINK_GRAVIPHONE_DOWNLOAD_MAC;
    }
    default: {
      return null;
    }
  }
}
