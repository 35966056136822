import React, { FunctionComponent } from 'react';
import { format } from 'date-fns';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { ICallDetailsList } from '../CallDetails.interfaces';

export const CallDetailsDateCell: FunctionComponent<ICellProps<ICallDetailsList>> = ({ item }) => {
  if (item) {
    const { date } = item;

    const cellValue = format(new Date(date), 'dd.MM.yyyy HH:mm');

    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {cellValue}
      </Typography>
    );
  }

  return <div />;
};
