import { IntegrationContext } from '@/features/Integrations/IntegrationForm/IntegrationForm';
import { CRMSites } from '@/features/Integrations/IntegrationForm/RetailCRM/modules/CRMSites';
import { isObjectEqual } from '@/utils';
import Drawer from '@components/Drawer';
import Flex from '@shared/components/Flex';
import SelectField from '@shared/components/SelectField';
import SwitchField from '@shared/components/SwitchField';
import Typography from '@shared/components/Typography';
import { normalizePhone } from '@shared/utils';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getSettingsObjectKeys } from '../IntegrationForm.constants';
import {
  IScenarioSettingsType,
  comparedSitesListType,
  rawDataListType,
  scenarioDirectionEnum,
  scenarioSettingsType,
} from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';
import { retailLeadResponsibleObjectKey } from './RetailCRM.constants';
import { useRetailScenarioFormPropsHooks } from './hooks/useRetailScenarioFormProps.hooks';

export const RetailCRMSettingsForm: FunctionComponent<IScenarioSettingsType> = ({
  isOpen,
  onClose,
  onComplete,
}) => {
  const classes = useIntegrationFormStyles();
  const { scenarioType, comparedEmployee, rawEmployee, scenarioData, responsibleNumbersList } =
    useContext(IntegrationContext)!; // TODO make check for null
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { setValue, watch, handleSubmit } = formMethods;
  const isContactActive = watch('isContactActive');
  const { title: settingTitle, edit } = scenarioType || {};
  const contactResponsibleKey = retailLeadResponsibleObjectKey(edit);
  const { direction, status, type } = getSettingsObjectKeys(edit);
  const [rawComparedSites, setRawComparedSites] = useState<{
    compared: comparedSitesListType | null;
    raw: rawDataListType | null;
  }>({
    compared: null,
    raw: null,
  });

  const {
    contactTitle,
    contactTitleDescription,
    isCommonSettings,
    isSiteCompare,
    comparedEmployeeList,
    rawEmployeeList,
  } = useRetailScenarioFormPropsHooks(direction, edit, comparedEmployee, rawEmployee);

  const getRawSites = () => {
    if (direction === scenarioDirectionEnum.RetailCrm && scenarioData) {
      return scenarioData[direction]?.sites;
    }
    return null;
  };
  const rawSites = getRawSites();

  useEffect(() => {
    if (isOpen && scenarioData && Object.keys(scenarioData).length) {
      if (direction === scenarioDirectionEnum.Common) {
        if (scenarioData[direction]?.defaultEmployee) {
          setValue('isContactActive', true);
          setValue('manager', scenarioData[direction]?.defaultEmployee);
        } else {
          setValue('isContactActive', false);
          setValue('manager', '');
        }
      }
      if (direction === scenarioDirectionEnum.RetailCrm) {
        setValue('isContactActive', Boolean(scenarioData[direction]?.sites));
      } else {
        const isCurrentSettingFilled = scenarioData[direction]?.[type]?.[status];
        if (isCurrentSettingFilled?.contact) {
          if (isCurrentSettingFilled?.contact?.defaultResponsible) {
            setValue('contactResponsible', isCurrentSettingFilled?.contact?.defaultResponsible);
          }
          setValue('isContactActive', true);
        } else {
          setValue('isContactActive', false);
          setValue('contactResponsible', '');
        }
      }
    } else {
      setValue('isContactActive', false);
      setValue('contactResponsible', '');
    }
  }, [scenarioData, isOpen, direction, type, status, setValue]);

  const handleSettingsSubmit = useCallback(
    (formData) => {
      const { contactResponsible, manager } = formData || {};
      const { compared: comparedSitesList } = rawComparedSites;

      const leadResponsibleObject = contactResponsibleKey
        ? { [contactResponsibleKey]: parseInt(contactResponsible) }
        : undefined;

      const setComparedSites = (comparedSites: comparedSitesListType) => {
        const cSitesKeys = Object.keys(comparedSites);
        return cSitesKeys.reduce((result, key) => {
          if (key === 'notmatch') {
            return {
              ...result,
              notmatch: comparedSites?.notmatch,
            };
          }
          const comparedSiteItem = comparedSites?.[key] as Record<string, unknown>;
          return {
            ...result,
            [normalizePhone(comparedSiteItem.name as string)]: comparedSiteItem.siteName,
          };
        }, {});
      };

      const comparedSites =
        comparedSitesList && isSiteCompare && isContactActive
          ? { sites: setComparedSites(comparedSitesList) }
          : undefined;

      const contact =
        isContactActive && !isCommonSettings && !isSiteCompare
          ? { contact: leadResponsibleObject ? { ...leadResponsibleObject } : isContactActive }
          : undefined;

      const defaultEmployee =
        isContactActive && isCommonSettings ? { defaultEmployee: parseInt(manager) } : undefined;

      onComplete({
        direction,
        type,
        status,
        data: {
          ...contact,
          ...comparedSites,
          ...defaultEmployee,
        },
      });
    },
    [
      isContactActive,
      contactResponsibleKey,
      isCommonSettings,
      rawComparedSites,
      isSiteCompare,
      onComplete,
      direction,
      status,
      type,
    ]
  );

  function handleChangeComparedSites(
    comparedList: comparedSitesListType,
    rawList: rawDataListType
  ) {
    if (
      !isObjectEqual(rawComparedSites.compared, comparedList) ||
      !isObjectEqual(rawComparedSites.raw, rawList)
    ) {
      setRawComparedSites({ compared: comparedList, raw: rawList });
    }
  }

  const renderSettingForm = () => {
    if (edit === scenarioSettingsType.Sites) {
      return (
        <CRMSites
          selectedNumbers={responsibleNumbersList}
          onChange={handleChangeComparedSites}
          rawComparedSites={rawSites}
        />
      );
    }
    if (
      edit === scenarioSettingsType.IncomingUnknown ||
      edit === scenarioSettingsType.IncomingMissedUnknown
    ) {
      return (
        <div className={classes.integrationScenarioNumbersList}>
          <div className={classes.scenarioSettingMenuItemSmall}>
            <SelectField
              data={comparedEmployeeList}
              label={translate('SELECT_DUTY_MANAGER')}
              name={'contactResponsible'}
              valueKey={'key'}
              titleKey={'value'}
              translating
              className={classes.defaultElementWidth21}
              validate={(contactResponsible: string) => {
                if (!isContactActive) {
                  return true;
                }
                if (!contactResponsible || contactResponsible === '') {
                  return translate('RESPONSIBLE_EMPTY_TEXT') as string;
                }
                return true;
              }}
            />
          </div>
        </div>
      );
    }
    if (edit === scenarioSettingsType.CommonCallHistory) {
      return (
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectField
            data={rawEmployeeList}
            name={'manager'}
            valueKey={'key'}
            titleKey={'value'}
            translating
            className={classes.defaultElementWidth21}
            validate={(manager: string) => {
              if (!isContactActive) {
                return true;
              }
              if (!manager || manager === '') {
                return translate('CHOOSE_RESPONSIBLE_MANAGER') as string;
              }
              return true;
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Drawer
      title={settingTitle || ''}
      variant={'persistent'}
      open={isOpen}
      classes={{ paper: classes.defaultElementWidth33 }}
      size={'lg'}
      primaryButton={
        !isContactActive
          ? {
              title: 'APPLY',
              onClick: handleSettingsSubmit,
            }
          : {
              title: 'APPLY',
              props: {
                type: 'submit',
                form: 'scenarios-settings-form',
              },
            }
      }
      secondaryButton={{
        title: 'CANCEL',
        onClick: onClose,
      }}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSettingsSubmit)} id={'scenarios-settings-form'}>
          <div className={classes.scenarioSettingMenuContent}>
            <Flex className={classes.integrationOnOffSwitch}>
              <SwitchField
                name={'isContactActive'}
                customLabel={
                  <Typography type={'text2'} color={'tertiary900'}>
                    {translate(contactTitle)}
                  </Typography>
                }
              />
            </Flex>
            <Flex
              className={!isContactActive ? classes.responsibleWrapper : ''}
              direction={'column'}
            >
              {contactTitleDescription ? (
                <div className={classes.scenarioSettingMenuTitle}>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {contactTitleDescription}
                  </Typography>
                </div>
              ) : null}
              {renderSettingForm()}
            </Flex>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default memo(RetailCRMSettingsForm);
