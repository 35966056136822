import React from 'react';
import { UserIcon, UsersIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { ICellProps } from '@components/LazyTable';
import { CallsRestrictionsQuery } from '@/client/generated/graphql';
import { useCallsRestrictionsStyles } from '../CallsRestrictions.styles';

export const CallRestrictionsTypeCell = ({
  item,
}: ICellProps<CallsRestrictionsQuery['getCallRestrictions'][number]>) => {
  const { department, employee } = item || {};
  const classes = useCallsRestrictionsStyles();

  const computedClass = clsx(classes.body, {
    [classes.primary]: employee,
    [classes.secondary]: department,
  });

  const renderIcon = () => {
    if (employee) {
      return <UserIcon width={16} height={16} />;
    }
    if (department) {
      return <UsersIcon width={16} height={16} />;
    }
    return null;
  };

  return <div className={computedClass}>{renderIcon()}</div>;
};
