import { makeStyles } from '@material-ui/core';
import styleConstants from '@components/styles';

export const UseStatisticsByCallsStyles = makeStyles(
  ({
    color: { tertiary, base, primary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    statisticsRoot: {
      position: 'relative',
      padding: '0 1em 2em 1em',
    },
    statisticsLineItem: {
      marginLeft: '1em',
      position: 'relative',
    },
    statisticsDatePicker: {
      width: '18em',
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
    },
    statisticsDayFilterReset: {
      marginRight: '0.5em',
      marginLeft: '0.5em',
      color: 'black',
    },
    statisticsDayFilterButton: {
      width: '100%',
      textAlign: 'left',
    },
    statisticsDatePickerControls: {
      padding: '0 1.5em',
    },
    statisticsHiddenElement: {
      display: 'none',
    },
    statisticsDayWrapper: {
      border: `solid 1px ${primary[700]}`,
      borderRadius: '4px',
    },
    statisticsDayText: {
      padding: '0.32em 0.75em 0.2em',
      display: 'block',
      width: '100%',
    },
    xls: {
      transition: createTransition(['color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      color: tertiary['900'],
      '&:hover': {
        backgroundColor: tertiary[100],
        borderRadius: '0.325em',
      },
    },
    tooltipLeftTextClass: {
      fontFamily: 'Onest-Regular, sans-serif',
      display: 'inline-block',
      fontSize: '0.875em',
      width: '9em',
      paddingLeft: '0.5em',
      verticalAlign: 'middle',
    },
    tooltipRightTextClass: {
      fontFamily: 'Onest-Medium, sans-serif',
      display: 'inline-block',
      textAlign: 'right',
      fontSize: '0.875em',
      width: '3em',
      verticalAlign: 'middle',
    },
    tooltipIconClass: {
      width: '1em',
      height: '1em',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    tooltipWrapperClass: {
      background: base,
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '12px',
      color: tertiary[900],
      opacity: 1,
      pointerEvents: 'none',
      position: 'absolute',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
      transition: 'all .1s ease',
      '&:after, &:before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderTop: 'solid 0.75em transparent',
        borderBottom: 'solid 0.75em transparent',
      },
    },
    tooltipArrowRight: {
      '&:after': {
        borderRight: `solid 0.75em ${base}`,
        left: '-0.75em',
      },
      '&:before': {
        borderRight: `solid 0.75em ${tertiary[200]}`,
        marginLeft: '-1px',
        left: '-0.75em',
      },
    },
    tooltipArrowLeft: {
      '&:after': {
        borderLeft: `solid 0.75em ${base}`,
        right: '-0.75em',
      },
      '&:before': {
        borderLeft: `solid 0.75em ${tertiary[200]}`,
        marginRight: '-1px',
        right: '-0.75em',
      },
    },
    tooltipArrowBottomPosition: {
      '&:after, &:before': {
        top: '80%',
      },
    },
    emptyBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '14em',
    },
    statisticsIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    messageDialogBodyWrapper: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    messageDialogButtonSuccess: {
      marginRight: '1.5em',
    },
    messageDialogButtonsBlock: {
      marginTop: 0,
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
