import { makeStyles } from '@material-ui/core';

export const useFormControlLabelForRadioStyles = makeStyles(({ color: { primary, tertiary } }) => ({
  label: {
    height: '2em',
    borderRadius: '6px',
    border: `1px solid ${tertiary[200]}`,
    marginRight: 0,
    marginLeft: 0,
    marginTop: 0,
    marginBottom: '1.5em',
    '&:last-child': {
      marginBottom: 0,
    },
    paddingLeft: '0.5em',
    '&:active': {
      backgroundColor: primary[50],
      borderColor: primary[700],
    },
  },
  active: {
    backgroundColor: primary[50],
    borderColor: primary[700],
  },
  labelText: {
    fontSize: '0.875em',
  },
}));
