import { IClient } from './client';
import { IDomainNumber } from './number';
import { IEmployee } from './employee';
import { AssistantType } from './scenario';

export enum DomainStatus {
  Demo = 'Demo',
  Moderating = 'Moderating',
  Confirmed = 'Confirmed',
  Commercial = 'Commercial',
  Blocked = 'Blocked',
  PreDeleted = 'PreDeleted',
  Deleted = 'Deleted',
  TechBlocked = 'TechBlocked',
  Unknown = 'UNKNOWN',
}

export const DemoDomainStatuses = [
  DomainStatus.Demo,
  DomainStatus.Moderating,
  DomainStatus.Confirmed,
];
export const CommercialDomainStatuses = [
  DomainStatus.Commercial,
  DomainStatus.Blocked,
  DomainStatus.TechBlocked,
  DomainStatus.PreDeleted,
];

export interface IReservedAssistants {
  type: AssistantType;
  count: number;
}

export interface IDomainSettings {
  blf?: boolean;
  holdTune: {
    url: string;
    type: 'default' | 'custom';
  };
  assistants?: IReservedAssistants[];
  allowEmergencyServices?: boolean;
  extLength?: number;
}

export interface IDomain {
  id: number;
  domain: string;
  current?: boolean;
  timezoneId?: number;
  createdAt: Date;
  status: DomainStatus;
  settings: IDomainSettings;
  client: IClient;
  employees: IEmployee[];
}

export interface IDomainDetails {
  id: number;
  domain: string;
  employeesCount: number;
  employeesLimit: number;
  timezoneId?: number;
  createdAt: Date;
  status: DomainStatus;
  settings: IDomainSettings;
  numbers: IDomainNumber[];
}
