import React, { FunctionComponent } from 'react';
import { Card, FormGroup } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import SwitchField from '@shared/components/SwitchField';
import clsx from 'clsx';
import {
  callForwardingTimeoutGroups,
  callForwardingTimeoutOptions,
} from './CallForwardingFields.interfaces';
import { useCallForwardingFieldStyles } from './CallForwardingFields.styles';

export const CallForwardingFields: FunctionComponent<{
  onChange?: (checked: boolean) => void;
  className?: string;
}> = ({ onChange = () => null, className }) => {
  const classes = useCallForwardingFieldStyles();
  const { watch } = useFormContext();
  const forwarding = watch('callForwarding');
  const [translate] = useTranslation();

  return (
    <FormGroup>
      <Flex alignItems={'center'}>
        <SwitchField name={'callForwarding'} onChanges={(checked) => onChange(checked)} />
        <Typography className={classes.label} type={'text3'} color={'tertiary900'}>
          {translate('ENABLE_CALL_FORWARDING')}
        </Typography>
      </Flex>
      {forwarding && (
        <Card className={classes.balloonCard} elevation={0}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('CALL_FORWARDING_TIMEOUT_QUESTION')}
          </Typography>
          <SelectField
            className={clsx(className, classes.selectField)}
            name={'callForwardingTimeout'}
            valueKey={'value'}
            titleKey={'titleCode'}
            translating
            data={callForwardingTimeoutOptions}
            groups={callForwardingTimeoutGroups}
            defaultValue={callForwardingTimeoutOptions[0]?.value}
            onChange={() => onChange(forwarding)}
          />
        </Card>
      )}
    </FormGroup>
  );
};

export default CallForwardingFields;
