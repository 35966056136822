import { makeStyles, Theme } from '@material-ui/core/styles';
import { IIndicatorProps } from '@components/Indicator/Indicator.interfaces';

export const useStyles = makeStyles<Theme, IIndicatorProps>(
  ({ color: { base, tertiary, success, danger } }) => ({
    indicator: ({ size }) => ({
      width: size,
      height: size,
      display: 'block',
      minHeight: size,
      minWidth: size,
      borderRadius: '50%',
      overflow: 'hidden',
      position: 'relative',
    }),
    middleCircle: ({ size = 8 }) => ({
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: size / 2,
      height: size / 2,
      backgroundColor: base,
      margin: '25%',
      borderRadius: '50%',
    }),
    active: {
      backgroundColor: success[600],
    },
    inactive: {
      backgroundColor: tertiary[400],
    },
    error: {
      backgroundColor: danger[600],
    },
  }),
  {
    index: 5,
  }
);
