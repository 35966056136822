import styleConstants from '@components/styles';
import { makeStyles } from '@material-ui/core';

export const useReservedNumberStyle = makeStyles(
  ({ color: { tertiary } }) => ({
    phoneLine: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: tertiary[50],
      height: '3em',
      marginTop: '0.25em',
      borderRadius: '5px',
    },
    phoneNumberCancelButton: {
      padding: '0.65em',
    },
    phoneNumberPaddingCell: {
      padding: '0 1em',
      boxSizing: 'content-box',
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);
