import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { GET_SELECTED_TARIFF_QUERY, USER_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
// eslint-disable-next-line import/no-duplicates
import { format, subDays } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { OptionType, TariffOptionModel } from '@/client/generated/graphql';
import { MicIcon, VoicemailIcon } from '@shared/assets/images/icons';
import PagePreloader from '@components/PagePreloader';
import Alert from '@shared/components/Alert';
import { SettingsPageTemplate } from '../SettingsPageTemplate';
import { useSettingsPagesStyles } from '../SettingsPage.styles';
import { RecordDescription } from './SettingsCallRecords.interface';

export const SettingsCallRecords = () => {
  const classes = useSettingsPagesStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { data: dataUser } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { data: dataSelectedTariff, loading: loadingGetSelectedTariff } =
    useQuery(GET_SELECTED_TARIFF_QUERY);

  const user = dataUser?.user;
  const tariff = dataSelectedTariff?.getDomain.tariff;

  const getRecordDescription = useCallback((): RecordDescription => {
    const description = { period: '', start: '' };
    if (!tariff || !user) {
      return description;
    }
    const record: Partial<TariffOptionModel> = tariff?.options
      ?.filter((o) => o.type === OptionType.Records)
      ?.reduce(
        (rec: Partial<TariffOptionModel>, option) =>
          (option?.params?.days || 0) > (rec?.params?.days || 0) ? option : rec,
        {
          params: { days: 0 },
        }
      );
    if (!record) return description;
    const currentDomain = getCurrentDomain(user);
    const now = new Date();
    const createdAt = currentDomain?.createdAt ? new Date(currentDomain?.createdAt) : now;
    const storageParams = { ...record.params, ...record.domainTariffOptionParams };
    const storageDays = storageParams.days || (storageParams.years || 0) * 365 || 91;
    const recordStart = subDays(now, storageDays);
    const startDate = recordStart < createdAt ? createdAt : recordStart;
    description.start = format(startDate, 'd MMMM yyyy', { locale: ru });
    switch (storageDays) {
      case 7:
        description.period = `7 ${translate('DAYS')}`;
        break;
      case 31:
        description.period = `1 ${translate('MONTH')}`;
        break;
      case 91:
        description.period = `3 ${translate('MONTH2')}`;
        break;
      case 181:
        description.period = `6 ${translate('MONTHS')}`;
        break;
      case 365:
        description.period = `12 ${translate('MONTHS')}`;
        break;
      case 1096:
        description.period = `36 ${translate('MONTHS')}`;
        break;
      case 3653:
        description.period = `10 ${translate('YEARS')}`;
        break;
      default:
        description.period = `${storageDays} ${translate('DAYS')}`;
    }

    return description;
  }, [tariff, user, translate]);

  const recordStorageOption = useMemo(() => {
    const option = tariff?.options?.find(
      (item) => item.type === 'Records' && item.category === 'Additional'
    );
    if (!option) return null;
    return option;
  }, [tariff]);

  const stereoRecordOption = useMemo(() => {
    const option = tariff?.options?.find(
      (item) => item.type === 'StereoRecords' && item.category === 'Additional'
    );
    if (!option) return null;
    return option;
  }, [tariff]);

  return (
    <SettingsPageTemplate containerClass={classes.customContainer} title={'RECORD_TALKS'}>
      <div className={classes.container}>
        <div className={classes.description}>
          <Typography color={'tertiary900'} type={'text3'}>
            {translate('SETTING_CALL_RECORDS_DESCRIPTION')}
          </Typography>
        </div>
        <div className={classes.description}>
          <Typography color={'tertiary900'} type={'text3'} bold>
            {`${translate('SETTING_CALL_RECORDS_DESCRIPTION_01', getRecordDescription())} `}
          </Typography>
          <Typography color={'tertiary900'} type={'text3'}>
            {translate('SETTING_CALL_RECORDS_DESCRIPTION_02')}
          </Typography>
        </div>
      </div>
      <FormProvider {...formMethods}>
        <form className={classes.form}>
          {loadingGetSelectedTariff ? (
            <PagePreloader />
          ) : (
            <div className={classes.settingList}>
              {!recordStorageOption && (
                <Alert
                  status="info"
                  title={translate('TARIFF_RECORD_LABEL')}
                  description={translate('TARIFF_ADDITIONAL_OPTION_WITH_PARAMS', {
                    text: translate('TARIFF_RECORD_LABEL'),
                  })}
                  icon={<VoicemailIcon />}
                />
              )}
              {!stereoRecordOption && (
                <Alert
                  classes={{
                    root: classes.marginTop16,
                  }}
                  status="info"
                  title={translate('TARIFF_STEREO_LABEL')}
                  description={translate('TARIFF_ADDITIONAL_OPTION_WITH_PARAMS', {
                    text: translate('TARIFF_STEREO_LABEL'),
                  })}
                  icon={<MicIcon />}
                />
              )}
            </div>
          )}
        </form>
      </FormProvider>
    </SettingsPageTemplate>
  );
};
