import { makeStyles } from '@material-ui/core';

export const useAudioPlayerStyles = makeStyles(
  ({ color: { primary, tertiary } }) => ({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    left: {
      width: '100%',
      boxSizing: 'border-box',
      height: '2.25em',
      position: 'relative',
    },
    orderLeft: {
      marginRight: '1em',
      order: 1,
    },
    orderRight: {
      order: 2,
    },
    bar: {
      height: '0.25em',
      borderRadius: '0.625em',
      width: '100%',
      top: '50%',
      position: 'absolute',
      cursor: 'pointer',
      marginTop: `${-0.25 / 2}em`,
      backgroundColor: tertiary['200'],
    },
    barDisabled: {
      cursor: 'auto',
    },
    fill: {
      transition: 'all 0.3s ease-out',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      borderRadius: '0.625em',
      backgroundColor: primary['600'],
      '&:after': {
        position: 'absolute',
        content: '""',
        right: 0,
        top: '50%',
        width: '0.625em',
        height: '0.625em',
        borderRadius: '50%',
        transform: 'translate(50%, -50%)',
        backgroundColor: primary['700'],
      },
    },
    fillDisabled: {
      backgroundColor: tertiary['200'],
      '&:after': {
        backgroundColor: tertiary['300'],
      },
    },
    min: {
      left: 0,
      bottom: 0,
      marginBottom: '-0.2em',
      position: 'absolute',
      '& *': {
        verticalAlign: 'bottom',
      },
      userSelect: 'none',
    },
    max: {
      right: 0,
      bottom: 0,
      marginBottom: '-0.2em',
      position: 'absolute',
      '& *': {
        verticalAlign: 'bottom',
      },
      userSelect: 'none',
    },
    button: {
      width: '2em',
      height: '2em',
      padding: 0,
    },
  }),
  {
    index: 1,
  }
);
