import React, { FunctionComponent } from 'react';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { useMediaQuery } from '@material-ui/core';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import BodyContainer from '@/layouts/BodyContainer';
import { Route, Routes } from 'react-router-dom';
import WelcomeDialog from '@/components/WelcomePage/WelcomeDialog';
import { useDesktopPageStyle } from './DesktopPage.styles';
import {
  CallStatisticsSectionWidget,
  BalanceWidget,
  TelephonyWidget,
  CallHourlyStatisticsSectionWidget,
} from './modules';
import { desktopPageSize } from './DesktopPage.interfaces';

export const DesktopPage: FunctionComponent = () => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWide = sidebarState?.isWide || false;
  const { domain: currentDomain = '' } = getCurrentDomain(userData?.user) || {};
  const isMinPageMaxWidth = isWide ? '1600px' : '1440px';
  const isNormalPageMaxWidth = isWide ? '1800px' : '1660px';

  const isMinPageSize = useMediaQuery(`(max-width:${isMinPageMaxWidth})`, { noSsr: true });
  const isNormalPageSize = useMediaQuery(`(max-width:${isNormalPageMaxWidth})`, { noSsr: true });

  const setPageSize = () => {
    if (isMinPageSize) {
      return {
        pageSize: desktopPageSize.Min,
        fontSize: '16px',
      };
    }
    if (isNormalPageSize) {
      return {
        pageSize: desktopPageSize.Normal,
        fontSize: '18px',
      };
    }
    return {
      pageSize: desktopPageSize.Wide,
      fontSize: '20px',
    };
  };

  const { fontSize, pageSize } = setPageSize();

  const renderDesktopContent = () => (
    <Flex direction={'column'} className={classes.root}>
      <Flex className={classes.body}>
        <div className={classes.desktopDataRoot}>
          <Flex className={classes.desktopPanelsCurrentPBS}>
            <Typography type={'text3'} color={'tertiary900'} bold>
              {`${translate('CURRENT_PBX')}:`}
            </Typography>
            <Typography
              className={classes.desktopPanelsCurrentPBSName}
              type={'text3'}
              color={'tertiary700'}
            >
              {currentDomain}
            </Typography>
          </Flex>
          <div style={{ fontSize }}>
            <Flex justifyContent={'spaceBetween'} className={classes.desktopPanelsWrapper}>
              <BalanceWidget pageSize={pageSize} />
              <TelephonyWidget />
              <CallStatisticsSectionWidget pageSize={pageSize} />
              <CallHourlyStatisticsSectionWidget />
            </Flex>
          </div>
        </div>
      </Flex>
    </Flex>
  );

  return (
    <BodyContainer>
      {renderDesktopContent()}
      <Routes>
        <Route path={'welcome'} element={<WelcomeDialog />} />
      </Routes>
    </BodyContainer>
  );
};

export default DesktopPage;
