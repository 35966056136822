import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useCallRestrictionsStyles = makeStyles(
  () => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
    },
    formContent: {
      width: '23em',
      marginBottom: '2em',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.5em',
    },
    actions: {
      display: 'flex',
      padding: '2em 0 4em',
      marginTop: 'auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      '& button': {
        marginTop: '0.5em',
        width: '60%',
      },
      '& > :not(:first-child)': {
        marginLeft: '1em',
        width: '35%',
      },
    },
    customLine: {
      padding: '0.825em 0.5em 0.825em 1em',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    callerTitle: {
      width: '95%',
      paddingRight: '1em',
    },
    callerExt: {
      width: '2em',
      display: 'flex',
      justifyContent: 'start',
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
