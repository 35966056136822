import React from 'react';
import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import Switch from '@shared/components/Switch';
import { useSwitchStyles } from './SwitchField.styles';

export const SwitchField = ({
  name = '',
  label = '',
  customLabel,
  defaultValue = false,
  onChanges = undefined,
  readOnly,
  disabled,
  ...props
}: Omit<CheckboxProps, 'defaultValue'> & {
  label?: string;
  defaultValue?: boolean;
  customLabel?: React.ReactNode;
  onChanges?: (checked: boolean) => void;
  readOnly?: boolean;
}) => {
  const classes = useSwitchStyles();
  const { control, watch } = useFormContext() || {};
  const defaultFormValue = watch ? watch(name) : undefined;
  const defaultControlValue = defaultFormValue || defaultValue;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultControlValue}
      render={({ field: { value, onChange, ref } }) => (
        <FormControlLabel
          className={classes.root}
          control={
            <Switch
              readOnly={readOnly}
              disabled={readOnly || disabled}
              color={'primary'}
              defaultValue={value || defaultControlValue}
              onChange={(e) => {
                onChange(e.target.checked);
                if (typeof onChanges === 'function') {
                  onChanges(e.target.checked);
                }
              }}
              inputRef={ref}
              checked={defaultControlValue ?? value}
              {...props}
            />
          }
          label={customLabel || label}
          classes={{ label: classes.label }}
          defaultValue={defaultControlValue}
        />
      )}
    />
  );
};

export default SwitchField;
