import { makeStyles } from '@material-ui/core/styles';

export const useQuickSearchStyles = makeStyles(
  () => ({
    endAdornment: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
      right: '1em',
    },
    inputMarginDense: {
      padding: '0.47em 3em 0.47em 1em',
    },
    root: {
      width: 'auto',
    },
  }),
  {
    index: 2,
  }
);
