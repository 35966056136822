import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import Typography from '@shared/components/Typography';
import { IAssistant } from '@components/typings/interfaces';
import { ICellProps } from '@components/LazyTable';

export const ScenarioPeriodicCostCell: FunctionComponent<ICellProps<IAssistant>> = ({ item }) => {
  if (!item) {
    return <div />;
  }

  const { telephonyService } = item;

  const cellValue = telephonyService?.cost
    ? `${toPrecision(telephonyService?.cost, false)} ₽/м`
    : '-';
  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {cellValue}
    </Typography>
  );
};
