import { makeStyles } from '@material-ui/core';

export const useFormErrorStyles = makeStyles(
  {
    root: {
      textAlign: 'center',
    },
  },
  {
    index: 1,
  }
);
