import { makeStyles } from '@material-ui/core/styles';

export const usePeriodStyle = makeStyles(
  ({
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
    color: { base, tertiary, primary },
  }) => ({
    periodContainer: {
      position: 'relative',
    },
    periodTrigger: {
      width: '12.5em',
      margin: 0,
      '& *': {
        cursor: 'pointer',
      },
    },
    periodWindow: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
      top: '130%',
      width: '66em',
      backgroundColor: base,
      borderRadius: '0.75em',
      boxShadow: '0px 0.5em 1.25em rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      '&:before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        right: '7.5em',
        top: '-8px',
        borderBottom: `8px solid${tertiary[50]}`,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
      },
      '& .MuiPickersStaticWrapper-root': {
        backgroundColor: 'transparent',
      },
    },
    periodWindowPositionDefault: {
      right: '-2em',
    },
    periodWindowPositionRight: {
      right: 0,
    },
    periodCalendar: {},
    periodControls: {
      padding: '1em 2em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '18em',
      boxSizing: 'border-box',
      backgroundColor: tertiary[50],
    },
    periodTitle: {
      textAlign: 'left',
      paddingBottom: '0.5em',
    },
    periodSelect: {
      paddingBottom: '1em',
    },
    fieldsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '1em',
    },
    fieldsButtons: {
      display: 'flex',
      paddingBottom: '1em',
      justifyContent: 'space-between',
    },
    input: {
      padding: '0.55em 1em 0.55em 1em',
      transition: createTransition(['background'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      '&:hover': {
        backgroundColor: tertiary[200],
      },
    },
    fieldSize: {
      width: '12em',
      margin: 0,
    },
    timeRoot: {
      minWidth: 'auto',
    },
    timeSize: {
      width: '4.5em',
      margin: 0,
    },
    rangePicker: {
      height: '100%',
      '& .MuiPickersDay-root': {
        width: '2em',
        height: '2em',
        borderRadius: '3px',
        fontSize: '1em',
        color: tertiary[900],
        '&.MuiPickersDay-dayOutsideMonth': {
          color: tertiary[300],
        },
        '&.Mui-selected': {
          color: base,
          backgroundColor: primary[700],
          '&:hover': {
            border: 'none',
            backgroundColor: primary[600],
          },
          '&:focus': {
            backgroundColor: primary[700],
          },
          '&.MuiPickersDay-dayOutsideMonth': {
            border: 'none',
            backgroundColor: 'transparent',
            color: tertiary[300],
          },
        },
        '&.Mui-disabled': {
          color: tertiary[300],
        },
      },
      '& .MuiPickersCalendar-weekDayLabel': {
        width: '2.285em',
        height: '2.285em',
        fontSize: '0.875em',
        margin: 0,
      },
      '& .MuiPickersDateRangeDay-day': {
        transform: 'scale(1)',
        '& > *': {
          transform: 'scale(1)',
        },
      },
      '& .MuiPickersDay-dayLabel': {
        fontSize: '0.875em',
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
        borderColor: 'transparent',
        border: 'none',
      },
      '& .MuiPickersDateRangeDay-rangeIntervalPreview': {
        border: 'none',
      },
      '& .MuiPickersDateRangeDay-root': {
        width: '2em',
        height: '2em',
        '&:first-child': {
          '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
            borderColor: 'transparent',
            border: 'none',
          },
        },
        '&:last-child': {
          '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
            borderColor: 'transparent',
            border: 'none',
          },
        },
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayHighlight': {
        backgroundColor: primary[50],
        '&:first-child': {
          borderRadius: '3px 0 0 3px',
        },
        '&:last-child': {
          borderRadius: '0 3px 3px 0',
        },
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd': {
        borderRadius: '0 3px 3px 0',
        marginRight: 0,
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayHighlightStart': {
        borderRadius: '3px 0 0 3px',
        marginLeft: 0,
      },
      '& .MuiPickersDesktopDateRangeCalendar-calendar': {
        minWidth: '15.875em',
        minHeight: '14.875em',
      },
      '& .MuiPickersDesktopDateRangeCalendar-root': {
        height: '100%',
      },
    },
    hidden: {
      display: 'none',
      visibility: 'hidden',
      height: '0',
    },
    timeWrapper: {
      minHeight: '2.25em',
      marginBottom: '1em',
    },
  }),
  {
    index: 5,
  }
);
