import { ZXCVBNScore } from 'zxcvbn';
import { TextColors, TextColorsTypes } from '@shared/components/Typography';

export const passwordScores: {
  [key in ZXCVBNScore]: { titleCode: string; color: TextColorsTypes };
} = {
  0: {
    titleCode: 'WEAK',
    color: TextColors.COLOR_DANGER600,
  },
  1: {
    titleCode: 'WEAK',
    color: TextColors.COLOR_DANGER600,
  },
  2: {
    titleCode: 'WEAK',
    color: TextColors.COLOR_DANGER600,
  },
  3: {
    titleCode: 'GOOD',
    color: TextColors.COLOR_TERTIARY700,
  },
  4: {
    titleCode: 'EXCELLENT',
    color: TextColors.COLOR_SUCCESS600,
  },
};
