import { makeStyles } from '@material-ui/core/styles';

export const useTagStyle = makeStyles(
  ({
    color: { tertiary, primary, base },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    tag: {
      height: '2em',
      width: '14.75em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: base,
      border: `1px solid ${primary[700]}`,
      borderRadius: '4px',
    },
    tagClose: {
      width: '1.75em',
      height: '1.75em',
      padding: 0,
      color: tertiary[800],
      transition: createTransition('color', {
        easing: sharp,
        duration: enteringScreen,
      }),
      '&:hover': {
        color: primary[800],
      },
    },
    tagText: {
      maxWidth: '11em',
      boxSizing: 'border-box',
      paddingLeft: '0.75em',
      marginRight: '1em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  {
    index: 2,
  }
);
