import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Translate from '@shared/components/Translate';
import Typography from '@shared/components/Typography';
import { useSettingsPagesStyles } from '../SettingsPage.styles';

export type SettingsToneBlockNames = {
  name: string;
};

export const SettingsToneContent: FunctionComponent<SettingsToneBlockNames> = ({ name }) => {
  const classes = useSettingsPagesStyles();
  const [translate] = useTranslation();

  const renderToneList = (contentName: string) => {
    switch (contentName) {
      case 'SETTING_TONE_INTERСEPTION': {
        return (
          <>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_INTERСEPTION_ALL"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_INTERСEPTION_ABONENT"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
          </>
        );
      }
      case 'SETTING_TONE_CALL_TRANSFER': {
        return (
          <>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CALL_TRANSFER_01"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Typography color={'tertiary900'} type={'text3'}>
                {translate('SETTING_TONE_CALL_TRANSFER_02')}
              </Typography>
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CALL_TRANSFER_03"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
          </>
        );
      }
      case 'SETTING_TONE_HOLD': {
        return (
          <>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_HOLD_01"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_HOLD_02"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
          </>
        );
      }
      case 'SETTING_TONE_MESSAGE': {
        return (
          <>
            <div className={classes.accordionItem}>
              <Typography color={'tertiary900'} type={'text3'}>
                {translate('SETTING_TONE_MESSAGE_01')}
              </Typography>
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_MESSAGE_02"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_MESSAGE_03"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_MESSAGE_04"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_MESSAGE_05"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
          </>
        );
      }
      case 'SETTING_TONE_CONFERENSE': {
        return (
          <>
            <div className={classes.accordionItem}>
              <Typography color={'tertiary900'} type={'text3'}>
                {translate('SETTING_TONE_CONFERENSE_01')}
              </Typography>
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CONFERENSE_02"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CONFERENSE_03"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CONFERENSE_04"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CONFERENSE_05"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
            <div className={classes.accordionItem}>
              <Typography color={'tertiary900'} type={'text3'}>
                {translate('SETTING_TONE_CONFERENSE_06')}
              </Typography>
            </div>
            <div className={classes.accordionItem}>
              <Translate
                i18nKey="SETTING_TONE_CONFERENSE_07"
                components={{
                  n: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'text3'} bold />,
                }}
              />
            </div>
          </>
        );
      }
      default: {
        return {};
      }
    }
  };

  return <>{renderToneList(name)}</>;
};

export default memo(SettingsToneContent);
