import { scenariosListType } from '../IntegrationForm.interfaces';
import RoistatHelpImg from './images/roistat_help.png';

export const RoistatScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
];
