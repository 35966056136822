import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import {
  PhoneIncomingIcon,
  MessageSquareIcon,
  PlusIcon,
  MinusIcon,
  ArrowMaximizeIcon,
  VoicemailIcon,
  FaxIcon,
  NumbersIcon,
  PhoneForwardedIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MicIcon,
} from '@shared/assets/images/icons';
import { useQuery } from '@apollo/client';
import { DEPARTMENTS_QUERY, EMPLOYEES_QUERY } from '@/client/queries';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { IIncomingNumberScenario } from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import { deserializeDepartment, deserializeEmployee } from '@components/typings/interfaces';
import Menu from '@components/Menu';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import { useIncomingNumberSettingsTreeStyles } from './IncomingNumberScenariosTree.styles';
import { initialATCPeriods, initialPeriods } from '../Scheduler/Scheduler.constants';
import { ScenariosTypes, SchedulePeriodsTypes, SchedulingMode } from '../IncomingNumber.interfaces';
import { incomingNumberSettingsMenuItems } from '../IncomingNumber.constants';
import { IIncomingNumberSettingsTreeProps } from './IncomingNumberScenariosTree.interfaces';
import { ISchedulePeriod } from '../Scheduler/Scheduler.interfaces';
import { MIN_SCALE, MAX_SCALE, RESET_TIMEOUT } from './IncomingNumberScenariosTree.constants';

export const IncomingNumberScenariosTree: FunctionComponent<IIncomingNumberSettingsTreeProps> = ({
  items,
  onOpenEditor,
  periods,
  mode,
}) => {
  const [treeScale, setTreeScale] = useState<number>(MAX_SCALE);
  const classes = useIncomingNumberSettingsTreeStyles({ treeScale });
  const theme = useTheme();
  const { tertiary } = theme?.color || {};
  const [translate] = useTranslation();
  const buttonDisabled = treeScale === MAX_SCALE;

  const { data: { employees = [] } = { employees: [] } } = useQuery<{
    employees: { id: string | number; user?: { name?: string } }[];
  }>(EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const { data: { departments = [] } = { departments: [] } } = useQuery<{
    departments: { id: string | number; name?: string }[];
  }>(DEPARTMENTS_QUERY, { fetchPolicy: 'no-cache' });

  const treeWrapperRef = useRef<HTMLDivElement>(null);
  // holds the timer for setTimeout and clearInterval
  let movementTimer: ReturnType<typeof setTimeout> | null = null;

  const [scrolledLeft, setScrolledLeft] = useState(false);
  const [scrolledRight, setScrolledRight] = useState(false);

  const setScrollMarks = () => {
    if (treeWrapperRef?.current) {
      const wrapper = treeWrapperRef.current;
      const wrapperStyle = window.getComputedStyle(wrapper);
      const paddingLeft = parseInt(wrapperStyle.getPropertyValue('padding-left'), 10);
      const paddingRight = parseInt(wrapperStyle.getPropertyValue('padding-right'), 10);
      const isScrolledRight = wrapper?.scrollLeft > paddingLeft;
      const isScrolledLeft =
        (wrapper?.scrollWidth || 0) - (wrapper?.scrollLeft || 0) - (wrapper?.offsetWidth || 0) >
        paddingRight;
      setScrolledRight(isScrolledRight);
      setScrolledLeft(isScrolledLeft);
    }
  };

  useEffect(() => {
    if (mode) {
      setScrollMarks();
    }
  }, [mode]);

  useLayoutEffect(() => {
    setScrollMarks();
  }, []);

  const leftWrapperClasses = clsx({
    [classes.scrollLeft]: scrolledRight,
  });
  const rightWrapperClasses = clsx({
    [classes.scrollRight]: scrolledLeft,
  });
  const leftIconClasses = clsx(classes.scrollIcon, classes.iconLeft, {
    [classes.invisible]: !scrolledRight,
  });
  const rightIconClasses = clsx(classes.scrollIcon, classes.iconRight, {
    [classes.invisible]: !scrolledLeft,
  });

  const onScrollTree = (): void => {
    setScrollMarks();
  };

  window.addEventListener('resize', () => {
    if (movementTimer) clearInterval(movementTimer);
    movementTimer = setTimeout(setScrollMarks, RESET_TIMEOUT);
  });

  const renderNewNode = (
    parentType: ScenariosTypes,
    parentId?: string,
    periodKey?: SchedulePeriodsTypes
  ) => (
    <TreeNode
      label={
        <Menu
          className={classes.newNode}
          items={incomingNumberSettingsMenuItems}
          onSelect={(settingType) => {
            onOpenEditor({ settingType: settingType as ScenariosTypes, parentId, periodKey });
          }}
        >
          <PlusIcon />
        </Menu>
      }
    />
  );

  const renderMessage = (
    item: IIncomingNumberScenario,
    parentId?: string,
    periodKey?: SchedulePeriodsTypes
  ) => {
    if (item?.messageType === 'TEXT') {
      return (
        <div
          className={classes.nodeTitle}
          onClick={() =>
            onOpenEditor({
              nodeId: item.id,
              parentId,
              periodKey,
              settingType: ScenariosTypes.Message,
            })
          }
        >
          <div className={classes.nodeTitleContent}>
            <MessageSquareIcon className={classes.nodeIcon} />
            <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
              {item?.message}
            </Typography>
          </div>
        </div>
      );
    }
    if (item?.messageType === 'AUDIO') {
      return (
        <div
          className={classes.nodeTitle}
          onClick={() =>
            onOpenEditor({
              nodeId: item.id,
              parentId,
              periodKey,
              settingType: ScenariosTypes.Message,
            })
          }
        >
          <div className={classes.nodeTitleContent}>
            <MessageSquareIcon className={classes.nodeIcon} />
            <div className={classes.messageWrapper}>
              <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                {`${translate('AUDIO_FILE')}:`}
              </Typography>
              <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                {`${item?.fileName}`}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  const renderSmartGreeting = (
    item: IIncomingNumberScenario,
    parentId?: string,
    periodKey?: SchedulePeriodsTypes
  ) => {
    if (item?.messageType === 'TEXT') {
      const headerTitle = () => {
        if (item.isCallingByClientName && item.isCallingByTime)
          return `[${translate('CONTACT_ON_TIME')}], [${translate('NAME').toLowerCase()}]!`;
        if (item.isCallingByClientName) return `[${translate('NAME')}]!`;
        if (item.isCallingByTime) return `[${translate('CONTACT_ON_TIME')}]!`;
        return '';
      };
      return (
        <div
          className={classes.nodeTitle}
          onClick={() =>
            onOpenEditor({
              nodeId: item.id,
              parentId,
              periodKey,
              settingType: ScenariosTypes.SmartGreeting,
            })
          }
        >
          <div className={classes.nodeTitleContent}>
            <MicIcon className={classes.nodeIcon} />
            <Flex direction={'column'}>
              <Flex fullWidth alignItems={'flexStart'}>
                <Typography
                  className={clsx(classes.textOverflow, classes.greetingHeader)}
                  type={'text4'}
                  color={'link600'}
                >
                  {headerTitle()}
                </Typography>
              </Flex>
              <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                {item?.message}
              </Typography>
            </Flex>
          </div>
        </div>
      );
    }
    return <div />;
  };

  const renderAdditionalMessage = (
    item: IIncomingNumberScenario,
    parentId?: string,
    periodKey?: SchedulePeriodsTypes
  ) => {
    if (item?.isMessageActive && item?.messageType === 'TEXT') {
      return (
        <div
          className={classes.nodeTitle}
          onClick={() =>
            onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
          }
        >
          <div className={classes.nodeTitleContent}>
            <MessageSquareIcon className={classes.nodeIcon} />
            <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
              {item?.message}
            </Typography>
          </div>
        </div>
      );
    }
    if (item?.isMessageActive && item?.messageType === 'AUDIO') {
      return (
        <div
          className={classes.nodeTitle}
          onClick={() =>
            onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
          }
        >
          <div className={classes.nodeTitleContent}>
            <MessageSquareIcon className={classes.nodeIcon} />
            <div className={classes.messageWrapper}>
              <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                {`${translate('AUDIO_FILE')}:`}
              </Typography>
              <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                {`${item?.fileName}`}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderNodeContent = (
    item: IIncomingNumberScenario,
    parentId?: string,
    periodKey?: SchedulePeriodsTypes
  ) => {
    switch (item?.type) {
      case ScenariosTypes.ClientChoice: {
        return (
          <div className={classes.nodeContent}>
            <div className={clsx(classes.nodeTitle, classes.nodeTitleClientChoice)}>
              <div className={classes.nodeTitleContent}>
                <div className={classes.clientChoiceBlock}>{item?.button || ''}</div>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate(item?.button === '?' ? 'NOTHING_CHOSEN' : 'CLIENT_CHOICE')}
                </Typography>
              </div>
            </div>
          </div>
        );
      }
      case ScenariosTypes.Message: {
        return (
          <div className={classes.nodeContent}>{renderMessage(item, parentId, periodKey)}</div>
        );
      }
      case ScenariosTypes.SmartGreeting: {
        return (
          <div className={classes.nodeContent}>
            {renderSmartGreeting(item, parentId, periodKey)}
          </div>
        );
      }
      case ScenariosTypes.VoiceMenu: {
        return (
          <div className={classes.nodeContent}>
            {renderAdditionalMessage(item, parentId, periodKey)}
            <div
              className={classes.nodeTitle}
              onClick={() =>
                onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
              }
            >
              <div className={classes.nodeTitleContent}>
                <NumbersIcon className={clsx(classes.nodeIcon, classes.nodeIconPrimary)} />
                <Typography className={classes.caption} type={'text4'} color={'tertiary900'}>
                  {translate('VOICE_MENU')}
                </Typography>
              </div>
            </div>
          </div>
        );
      }
      case ScenariosTypes.Voicemail: {
        return (
          <div className={classes.nodeContent}>
            {renderAdditionalMessage(item, parentId, periodKey)}
            <div
              className={classes.nodeTitle}
              onClick={() =>
                onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
              }
            >
              <div className={classes.nodeTitleContent}>
                <VoicemailIcon className={classes.nodeIcon} />
                <Typography className={classes.caption} type={'text4'} color={'tertiary900'}>
                  {translate('VOICEMAIL')}
                </Typography>
              </div>
            </div>
          </div>
        );
      }
      case ScenariosTypes.Fax: {
        return (
          <div className={classes.nodeContent}>
            {renderAdditionalMessage(item, parentId, periodKey)}
            <div
              className={classes.nodeTitle}
              onClick={() =>
                onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
              }
            >
              <div className={classes.nodeTitleContent}>
                <FaxIcon className={classes.nodeIcon} />
                <Typography className={classes.caption} type={'text4'} color={'tertiary900'}>
                  {translate('FAX')}
                </Typography>
              </div>
            </div>
          </div>
        );
      }
      case ScenariosTypes.RedirectToNumber: {
        return (
          <div className={classes.nodeContent}>
            {renderAdditionalMessage(item, parentId, periodKey)}
            <div
              className={classes.nodeTitle}
              onClick={() =>
                onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
              }
            >
              <div className={classes.nodeTitleContent}>
                <PhoneForwardedIcon className={classes.nodeIcon} />
                <div className={classes.messageWrapper}>
                  <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                    {`${translate('REDIRECT_TO_EXTERNAL_NUMBER_NUMBER')}:`}
                  </Typography>
                  <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                    {`${
                      item.externalNumber ? formatPhone(item.externalNumber) : item.externalNumber
                    }`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case ScenariosTypes.RedirectToEmployee: {
        const foundEmployee = employees.find(({ id }) => id === item.redirectEmployee);
        const serializedEmployee = foundEmployee ? deserializeEmployee(foundEmployee) : undefined;
        const employeeName = serializedEmployee ? serializedEmployee?.name || '' : 'unknown';
        return (
          <div className={classes.nodeContent}>
            {renderAdditionalMessage(item, parentId, periodKey)}
            <div
              className={classes.nodeTitle}
              onClick={() =>
                onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
              }
            >
              <div className={classes.nodeTitleContent}>
                <PhoneForwardedIcon className={classes.nodeIcon} />
                <div className={classes.messageWrapper}>
                  <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                    {`${translate('REDIRECT_TO_EMPLOYEE_NAME')}:`}
                  </Typography>
                  <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                    {`${employeeName}`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        );
      }
      case ScenariosTypes.RedirectToDepartment: {
        const foundDepartment = departments.find(
          ({ id }) => id === Number(item.redirectDepartment)
        );
        const serializedDepartment = foundDepartment
          ? deserializeDepartment(foundDepartment)
          : undefined;
        const departmentName = serializedDepartment ? serializedDepartment?.name : 'unknown';
        return (
          <div className={classes.nodeContent}>
            {renderAdditionalMessage(item, parentId, periodKey)}
            <div
              className={classes.nodeTitle}
              onClick={() =>
                onOpenEditor({ nodeId: item.id, parentId, periodKey, settingType: item?.type })
              }
            >
              <div className={classes.nodeTitleContent}>
                <PhoneForwardedIcon className={classes.nodeIcon} />
                <div className={classes.messageWrapper}>
                  <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                    {`${translate('REDIRECT_TO_DEPARTMENT_NAME')}:`}
                  </Typography>
                  <Typography type={'text4'} color={'tertiary900'} className={classes.message}>
                    {`${departmentName}`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };

  const renderNode = (
    id: string | undefined,
    index: number,
    parentId?: string,
    periodKey?: SchedulePeriodsTypes
  ) => {
    if (!id) return null;
    const targetItem = items[id];
    if (targetItem) {
      const { type, children = [] } = targetItem;

      if (type === ScenariosTypes.Voicemail || type === ScenariosTypes.Fax) {
        return (
          <TreeNode
            key={id}
            label={
              <div className={classes.node}>
                {renderNodeContent(targetItem as IIncomingNumberScenario, parentId, periodKey)}
              </div>
            }
          />
        );
      }
      return (
        <TreeNode
          key={id}
          label={
            <div className={classes.node}>
              {renderNodeContent(targetItem as IIncomingNumberScenario, parentId, periodKey)}
            </div>
          }
        >
          {children.map((childrenId: string | undefined, ind: number) =>
            renderNode(childrenId, ind, id)
          )}
          {!children.length &&
            type !== ScenariosTypes.VoiceMenu &&
            renderNewNode(type, id, undefined)}
        </TreeNode>
      );
    }
    return null;
  };

  const renderPeriodNode = ({ key, titleCode, name, id }: ISchedulePeriod, index: number) => {
    const indicatorClass = clsx(classes.indicator, {
      [classes.default]: key === SchedulePeriodsTypes.NonWorkingHours,
      [classes.success]: key === SchedulePeriodsTypes.WorkingHours,
      [classes.warning]: key === SchedulePeriodsTypes.Custom1,
      [classes.links]: key === SchedulePeriodsTypes.Custom2,
      [classes.secondary]: key === SchedulePeriodsTypes.Custom3,
    });

    return (
      <TreeNode
        key={key}
        label={
          <div className={classes.node}>
            <div className={classes.nodeTitle}>
              <div className={classes.nodeTitleContent}>
                <div className={indicatorClass} />
                <div className={classes.rootNodeTitleText}>
                  <Typography type={'text3'} color={'tertiary900'} className={classes.text}>
                    {name || translate(titleCode)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        }
      >
        {id && items[id]
          ? renderNode(id, index, undefined, key)
          : renderNewNode(ScenariosTypes.Period, undefined, key)}
      </TreeNode>
    );
  };

  const renderPeriods = () => {
    let resultPeriods: ISchedulePeriod[] = [];

    if (mode !== SchedulingMode.ATC) {
      resultPeriods = periods.filter(
        ({ key }) =>
          key !== SchedulePeriodsTypes.ATC && key !== SchedulePeriodsTypes.NonWorkingHours
      );
      resultPeriods.push(
        periods.find(({ key }) => key === SchedulePeriodsTypes.NonWorkingHours) || {
          ...initialPeriods[0],
        }
      );
    } else {
      resultPeriods.push(
        periods.find(({ key }) => key === SchedulePeriodsTypes.ATC) || { ...initialATCPeriods[0] }
      );
    }
    return resultPeriods.map(renderPeriodNode);
  };

  const handleScaleClick = useCallback(
    (plus: boolean, minus: boolean) => {
      if (plus && treeScale < MAX_SCALE && treeScale >= MIN_SCALE) {
        const currentScale = treeScale + 1;
        setTreeScale(currentScale);
      }
      if (
        minus &&
        treeScale <= MAX_SCALE &&
        treeScale > MIN_SCALE &&
        (scrolledRight || scrolledLeft)
      ) {
        const currentScale = treeScale - 1;
        setTreeScale(currentScale);
      }
      return treeScale;
    },
    [scrolledLeft, scrolledRight, treeScale]
  );

  useEffect(() => {
    if (items || handleScaleClick) {
      setScrollMarks();
    }
  }, [handleScaleClick, items]);

  const renderScaleButtons = () => (
    <div className={classes.treeScaleControlButtons}>
      <div>
        <Button
          className={clsx(classes.treeScalePlus, {
            [classes.treeScalePlusDisabled]: buttonDisabled,
          })}
          onClick={() => !buttonDisabled && handleScaleClick(true, false)}
        >
          <PlusIcon />
        </Button>
        <Button className={classes.treeScaleMinus} onClick={() => handleScaleClick(false, true)}>
          <MinusIcon />
        </Button>
      </div>
      {treeScale < MAX_SCALE && (
        <Button className={classes.treeScaleDefault} onClick={() => setTreeScale(MAX_SCALE)}>
          <ArrowMaximizeIcon />
        </Button>
      )}
    </div>
  );

  return (
    <div className={classes.treeWrapper}>
      <div className={leftWrapperClasses}>
        <ChevronLeftIcon className={leftIconClasses} />
      </div>
      <div className={classes.treeSection} onScroll={onScrollTree} ref={treeWrapperRef}>
        <Tree
          lineWidth={'1px'}
          lineColor={tertiary[300]}
          lineBorderRadius={'10px'}
          label={
            <div className={classes.node}>
              <div className={classes.nodeTitle}>
                <div className={classes.nodeTitleContent}>
                  <PhoneIncomingIcon className={clsx(classes.nodeIcon, classes.nodeIconPrimary)} />
                  <Typography type={'text3'} color={'tertiary900'}>
                    {translate('INCOMING_CALL')}
                  </Typography>
                </div>
                <div />
              </div>
            </div>
          }
        >
          {renderPeriods()}
        </Tree>
      </div>
      {(scrolledRight || scrolledLeft || treeScale !== MAX_SCALE) && renderScaleButtons()}
      <div className={rightWrapperClasses}>
        <ChevronRightIcon className={rightIconClasses} />
      </div>
    </div>
  );
};

export default IncomingNumberScenariosTree;
