import React, { FunctionComponent, useMemo } from 'react';
import Typography from '@shared/components/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import Flex from '@shared/components/Flex';
import { formatPhone, matchWithDelimiters } from '@components/utils';
import { useHighlightedStyles } from './HighlightedText.styles';
import { IHighlightedTextProps } from './HighlightedText.interfaces';

export const HighlightedText: FunctionComponent<IHighlightedTextProps> = ({
  className,
  isBold,
  isUnderline,
  typographyColor = 'tertiary900',
  typographyType = 'text3',
  type = 'text',
  text = '',
  query = '',
  color = 'primary',
  textOverflow,
  whiteSpaceProp = 'nowrap',
}) => {
  const classes = useHighlightedStyles();

  const textMatches = match(text || '', query || '', {
    insideWords: true,
    findAllOccurrences: true,
    requireMatchAll: true,
  });
  const formattedPhone = (type === 'phone' && formatPhone(text)) || '';
  const phoneMatches = matchWithDelimiters(formattedPhone, query || '', '+( )-');
  const sumMatches = matchWithDelimiters(text || '', query || '', '-+( ),.');

  const parts = useMemo(() => {
    switch (type) {
      case 'phone': {
        return parse(formattedPhone, phoneMatches);
      }
      case 'sum': {
        return parse(text || '', sumMatches);
      }
      default:
        return parse(text || '', textMatches);
    }
  }, [formattedPhone, phoneMatches, sumMatches, text, textMatches, type]);

  const formattedParts = useMemo(
    () =>
      parts.map((part) => ({
        ...part,
        text: part.text.replace(' ', '\xa0'),
      })),
    [parts]
  );

  return (
    <Flex className={textOverflow ? clsx(classes.root, classes.textOverflow) : undefined}>
      {formattedParts.map((part, index) => (
        <Typography
          key={index}
          type={typographyType}
          color={typographyColor}
          className={clsx(className, !className && !query && classes.searchSpan, {
            [classes.searchColorPrimary]: query && part.highlight,
            [classes.searchColorSecondary]: query && part.highlight && color === 'secondary',
          })}
          bold={isBold}
          underline={isUnderline}
          style={{ whiteSpace: whiteSpaceProp }}
        >
          {part.text}
        </Typography>
      ))}
    </Flex>
  );
};

export default HighlightedText;
