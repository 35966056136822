import React, { FunctionComponent, useMemo } from 'react';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { ICellProps } from '@components/LazyTable';
import { callType } from '../../StatisticsByCalls/StatisticsByCalls.constants';
import { IStatisticsByEmployees } from '../../StatisticsByCalls/StatisticsByCalls.interfaces';
import { UseEmployeesStatisticStyles } from '../StatisticsByEmployees.styles';

export const EmployeesStatisticCallsCell: FunctionComponent<ICellProps<IStatisticsByEmployees>> = ({
  item,
}) => {
  const { outSuccess = 0, outNotSuccess = 0, inSuccess = 0, inNotSuccess = 0 } = item || {};
  const classes = UseEmployeesStatisticStyles();
  const [searchParams] = useSearchParams();
  const allCallsType = callType.allCalls;
  const sCallType = searchParams.get('callType') || allCallsType;
  const computedLeftSideClass = clsx(classes.sideLine, {
    [classes.sideLineBlue]: sCallType === allCallsType || callType.incoming,
    [classes.sideLineGreen]: sCallType === callType.outgoing,
  });
  const computedRightSideClass = clsx(classes.sideLine, {
    [classes.sideLineGreen]: sCallType === allCallsType,
    [classes.sideLineRed]: sCallType === callType.incoming,
    [classes.sideLineWarning]: sCallType === callType.outgoing,
  });

  const setSidesPercentsValue = (leftValue: number, rightValue: number) => {
    const leftSidePercents = +((leftValue * 100) / (leftValue + rightValue)).toFixed(0);
    const rightSidePercents = +((rightValue * 100) / (leftValue + rightValue)).toFixed(0);
    return { leftSidePercents, rightSidePercents };
  };

  const {
    leftSidePercents = 0,
    rightSidePercents = 0,
    leftCallsAmount,
    rightCallsAmount,
  } = useMemo(() => {
    if (sCallType === allCallsType) {
      return {
        leftCallsAmount: inSuccess,
        rightCallsAmount: outSuccess,
        ...setSidesPercentsValue(inSuccess, outSuccess),
      };
    }
    if (sCallType === callType.incoming) {
      return {
        leftCallsAmount: inSuccess,
        rightCallsAmount: inNotSuccess,
        ...setSidesPercentsValue(inSuccess, inNotSuccess),
      };
    }
    if (sCallType === callType.outgoing) {
      return {
        leftCallsAmount: outSuccess,
        rightCallsAmount: outNotSuccess,
        ...setSidesPercentsValue(outSuccess, outNotSuccess),
      };
    }
    return { leftSidePercents: 0, rightSidePercents: 0, leftCallsAmount: 0, rightCallsAmount: 0 };
  }, [allCallsType, inNotSuccess, inSuccess, outNotSuccess, outSuccess, sCallType]);

  const isHavingValue = leftSidePercents > 0 || rightSidePercents > 0;

  return (
    <Flex fullWidth direction={'column'} alignItems={'center'}>
      <Flex fullWidth justifyContent={'spaceBetween'}>
        <div>
          <Typography type={'text4'} color={'tertiary900'} bold>
            {leftCallsAmount}
          </Typography>
          {isHavingValue && (
            <Typography type={'text4'} color={'tertiary700'}>
              {` (${leftSidePercents}%)`}
            </Typography>
          )}
        </div>
        <div>
          <Typography type={'text4'} color={'tertiary900'} bold>
            {rightCallsAmount}
          </Typography>
          {isHavingValue && (
            <Typography type={'text4'} color={'tertiary700'}>
              {` (${100 - leftSidePercents}%)`}
            </Typography>
          )}
        </div>
      </Flex>
      <Flex fullWidth className={classes.statisticLines}>
        <div
          className={computedLeftSideClass}
          style={{ width: leftSidePercents ? `${leftSidePercents}%` : 0 }}
        />
        {!!rightSidePercents && rightSidePercents !== 100 && (
          <div className={classes.separatingBlock} />
        )}
        <div
          className={computedRightSideClass}
          style={{ width: rightSidePercents ? `${rightSidePercents}%` : 0 }}
        />
      </Flex>
      <Typography type={'text4'} color={'tertiary600'}>
        {leftCallsAmount + rightCallsAmount}
      </Typography>
    </Flex>
  );
};
