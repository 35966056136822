import React, { FunctionComponent, memo, MouseEventHandler } from 'react';
import { IconButton } from '@material-ui/core';
import { EyeIcon, EyeOffIcon } from '@shared/assets/images/icons';

export const PasswordVisibilityControls: FunctionComponent<{
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isShowing: boolean;
  disabled: boolean;
}> = ({ onClick, isShowing, disabled }) => {
  const adornmentIcon = isShowing ? <EyeIcon /> : <EyeOffIcon />;

  return (
    <IconButton
      aria-label={'toggle-password-visibility'}
      onClick={onClick}
      edge={'end'}
      color={'inherit'}
      size={'small'}
      disabled={disabled}
    >
      {adornmentIcon}
    </IconButton>
  );
};

export default memo(PasswordVisibilityControls);
