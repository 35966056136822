import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useDocumentsStyle = makeStyles(
  ({ color: { tertiary, primary, base } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    documentsButton: {
      padding: '0.5em',
      marginLeft: '1em',
    },
    documentTypeBlock: {
      display: 'flex',
      flexDirection: 'column',
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    container: {
      display: 'flex',
      width: '27em',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: '9em auto 0',
    },
    menuArrowDown: {
      transform: 'rotate(180deg)',
      color: tertiary[900],
      margin: '1.375em 0 1em',
    },
    buttonDownloadContract: {
      marginTop: '1.5em',
    },
    stepItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1em',
      textDecoration: 'none',
      '&:first-child': {
        marginLeft: 0,
      },
    },
    stepIcon: {
      width: '2em',
      minWidth: '2em',
      height: '2em',
      borderRadius: '50%',
      marginRight: '1em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: tertiary[200],
      '& > *': {
        lineHeight: '1em',
      },
    },
    stepIconCurrentBg: {
      backgroundColor: primary[50],
      border: `1px solid ${primary[700]}`,
    },
    stepIconSuccessBg: {
      backgroundColor: primary[700],
    },
    checkIcon: {
      color: base,
    },
    checkIconBlack: {
      color: 'black',
    },
    stepIconLastBg: {
      backgroundColor: tertiary[100],
      border: 'none',
    },
    stepsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: 'max-content',
      height: '4.5em',
      textDecoration: 'none',
      marginBottom: '3em',
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);
