import React, { FunctionComponent, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_MISSED_CALLS_QUERY } from '@/client/queries';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { SmsIcon } from '@shared/assets/images/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { NotificationsBreadcrumbs } from './NotificationsBreadcrumbs';
import { IMissedCalls, IReceivers } from './SettingsNotifications.interface';
import { useNotificationsStyles } from './SettingsNotifications.styles';
import {
  NotificationsStatusHeaderCell,
  NotificationsActionsCell,
  NotificationsArrowCell,
  NotificationsMethodCell,
  NotificationsRecipientCell,
  NotificationsStatusCell,
  NotificationsMissedCallCell,
} from './modules';

export const missedCallsColumns: IColumn<IMissedCalls>[] = [
  {
    id: 'notificationsFilter',
    width: '1em',
    minWidth: '1em',
    align: 'center',
    label: '',
    HeaderRenderer: NotificationsStatusHeaderCell,
    Renderer: NotificationsStatusCell,
  },
  {
    id: 'missedCall',
    label: 'MISSED_CALL_ON',
    Renderer: NotificationsMissedCallCell,
  },
  {
    id: 'missedCall',
    label: '',
    Renderer: NotificationsArrowCell,
  },
  {
    id: 'notificationRecipient',
    label: 'NOTIFICATION_RECIPIENT',
    Renderer: NotificationsRecipientCell,
  },
  {
    id: 'method',
    label: 'NOTIFICATION_METHOD',
    Renderer: NotificationsMethodCell,
  },
  {
    id: 'actions',
    label: '',
    align: 'right',
    Renderer: NotificationsActionsCell,
  },
];

export const SettingsNotificationsMissedCalls: FunctionComponent = () => {
  const { data: missedCallsData, loading, error } = useQuery(GET_ALL_MISSED_CALLS_QUERY);
  const allMissedCallsData = missedCallsData?.getAllMissedCallConfigs;
  const [translate] = useTranslation();
  const classes = useNotificationsStyles();
  const [searchParams] = useSearchParams();
  const filterQuery = searchParams.getAll('filter');
  const sQuery = searchParams.get('query')?.toUpperCase() || '';

  const formMethods = useForm({
    defaultValues: { query: sQuery },
  });

  const tableData = useMemo(() => {
    if (allMissedCallsData) {
      if (sQuery) {
        filterQuery.length = 0;
        const cleanedQuery = sQuery.replace(/[( )\s+-]/g, '');
        return allMissedCallsData?.filter(
          (el: IMissedCalls) =>
            el.domainNumber?.phone.includes(cleanedQuery) ||
            el.department?.name?.toUpperCase().includes(sQuery) ||
            el.employee?.name?.toUpperCase().includes(sQuery) ||
            el.receivers?.reduce((acc: boolean, rec: IReceivers) => {
              if (
                rec.employee?.name?.toUpperCase().includes(sQuery) ||
                rec.department?.name?.toUpperCase().includes(sQuery) ||
                rec.external?.includes(cleanedQuery)
              ) {
                return true;
              }
              return acc;
            }, false)
        );
      }
      if (filterQuery.length) {
        if (filterQuery[0] === 'all') {
          return allMissedCallsData;
        }
        return filterQuery.reduce(
          (r, el) =>
            r.concat(
              allMissedCallsData.filter(
                (config: IMissedCalls) => !!config[el as keyof IMissedCalls]
              )
            ),
          []
        );
      }
      if (!filterQuery.length || !sQuery.length) {
        return allMissedCallsData;
      }
    }
    return null;
  }, [allMissedCallsData, filterQuery, sQuery]);

  const renderEmptyContent = () => {
    if (!loading && !error) {
      if (!allMissedCallsData.length) {
        return (
          <ContentHint isContentCenter>
            <SmsIcon className={classes.SmsIcon} />
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('HERE_YOU_CAN_ADD')}
            </Typography>
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('NEW_NOTIFICATIONS')?.toLowerCase()}
            </Typography>
          </ContentHint>
        );
      }
      if (!tableData.length) {
        return (
          <CellPreloader>
            <Typography color={'tertiary900'} type={'text3'}>
              {translate('NOTHING_FOUND')}
            </Typography>
          </CellPreloader>
        );
      }
    }
    return null;
  };

  const renderSettingsNotificationsMissedCallsContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;
    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <NotificationsBreadcrumbs />
          <LazyTable<IMissedCalls>
            columns={missedCallsColumns}
            data={tableData}
            renderEmptyDataMessage={renderEmptyContent()}
          />
        </Flex>
      </FormProvider>
    );
  };

  return (
    <BodyContainer disableOverflow>{renderSettingsNotificationsMissedCallsContent()}</BodyContainer>
  );
};
