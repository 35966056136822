import { makeStyles } from '@material-ui/core/styles';

export const LinkStyle = makeStyles({
  root: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    '& * ': {
      verticalAlign: 'top',
    },
  },
});
