import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Breadcrumbs from '@/components/Breadcrumbs';
import { UserXIcon, CheckIcon, StatisticsIcon } from '@shared/assets/images/icons';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import {
  callType,
  filterPeriodsEmployeesStatistic,
  filterTypeCallIcons,
  filterTypeCallOptions,
  periodType,
  EmployeesStatisticsSortBy,
} from '@/features/Statistics/StatisticsByCalls/StatisticsByCalls.constants';
import { useSearchParams } from 'react-router-dom';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { useQuery } from '@apollo/client';
import {
  GET_DEPARTMENTS_AND_EMPLOYEES_QUERY,
  GET_EMPLOYEES_STATISTICS_QUERY,
  HAS_HISTORY_CALLS_QUERY,
  USER_QUERY,
} from '@/client/queries';
import clsx from 'clsx';
import { getDateList } from '@/utils';
import PageError from '@shared/components/PageError';
import FilterByPBX from '@/components/FilterByPBX';
import { IDepOrEmployees, sortDirection } from '@components/typings/interfaces';
import { IFilterChangeValues } from '@/components/FilterByPBX/FilterByPBX.interfaces';
import LazyTable, { IColumn } from '@components/LazyTable';
import BodyContainer from '@/layouts/BodyContainer';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { differenceInCalendarWeeks } from 'date-fns';
import { UseEmployeesStatisticStyles } from './StatisticsByEmployees.styles';
import { IStatisticsByEmployees } from '../StatisticsByCalls/StatisticsByCalls.interfaces';
import {
  EmployeesStatisticAverageDuration,
  EmployeesStatisticCallsCell,
  EmployeesStatisticCallsHeaderCell,
  EmployeesStatisticEmployeeCell,
  EmployeesStatisticTotalCalls,
  EmployeesStatisticAvgDurationHeaderCell,
  EmployeesStatisticTotalCallsHeaderCell,
} from './modules';

export const employeesStatisticColumns: IColumn<IStatisticsByEmployees>[] = [
  {
    id: 'employee',
    label: 'EMPLOYEE',
    width: '17em',
    minWidth: '17em',
    Renderer: EmployeesStatisticEmployeeCell,
  },
  {
    id: 'calls',
    align: 'center',
    label: '',
    HeaderRenderer: EmployeesStatisticCallsHeaderCell,
    Renderer: EmployeesStatisticCallsCell,
  },
  {
    id: 'totalCalls',
    align: 'right',
    label: '',
    width: '18em',
    minWidth: '18em',
    Renderer: EmployeesStatisticTotalCalls,
    HeaderRenderer: EmployeesStatisticTotalCallsHeaderCell,
  },
  {
    id: 'callsAvg',
    align: 'right',
    width: '12em',
    minWidth: '12em',
    label: '',
    Renderer: EmployeesStatisticAverageDuration,
    HeaderRenderer: EmployeesStatisticAvgDurationHeaderCell,
  },
];

export const StatisticsByEmployees = () => {
  const classes = UseEmployeesStatisticStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { watch, setValue } = formMethods;
  const callTypeValue = watch('callType');
  const sCallType = searchParams.get('callType');
  const sFilterPeriodValue = searchParams.get('filterPeriod');
  const sAvgDurationFilter = searchParams.get('avgDuration');
  const sTotalCallsFilter = searchParams.get('totalCalls');
  const filterPeriodValue = watch('filterPeriod');
  const [selectedValuesList, setSelectedValuesList] = useState<IFilterChangeValues>({});
  const isDepartmentsListId = selectedValuesList[0]?.length;
  const isEmployeesListId = selectedValuesList[1]?.length;
  const [hasSearchParams, setHasSearchParams] = useState(false);

  const { data } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const user = data?.user;

  const minDate = getCurrentDomain(user)?.createdAt;

  const {
    startDay,
    currentDay,
    startYesterday,
    endYesterday,
    currentWeek,
    endWeek,
    pastWeek,
    endPastWeek,
    startMonth,
    endMonth,
    startPastMonth,
    endPastMonth,
  } = getDateList();

  const historyPeriodValues: { [key: string]: { from: Date; to: Date } } = useMemo(
    () => ({
      [periodType.today]: { from: startDay, to: currentDay },
      [periodType.yesterday]: { from: startYesterday, to: endYesterday },
      [periodType.week]: { from: currentWeek, to: endWeek },
      [periodType.pastWeek]: { from: pastWeek, to: endPastWeek },
      [periodType.month]: { from: startMonth, to: endMonth },
      [periodType.pastMonth]: { from: startPastMonth, to: endPastMonth },
    }),
    [
      startDay,
      currentDay,
      startYesterday,
      endYesterday,
      currentWeek,
      endWeek,
      pastWeek,
      endPastWeek,
      startMonth,
      endMonth,
      startPastMonth,
      endPastMonth,
    ]
  );

  const sortByValue = useCallback(() => {
    if (callTypeValue === callType.allCalls && sAvgDurationFilter)
      return EmployeesStatisticsSortBy.avgDuration;
    if (callTypeValue === callType.allCalls && sTotalCallsFilter)
      return EmployeesStatisticsSortBy.allCount;
    if (callTypeValue === callType.incoming && sAvgDurationFilter)
      return EmployeesStatisticsSortBy.inDuration;
    if (callTypeValue === callType.incoming && sTotalCallsFilter)
      return EmployeesStatisticsSortBy.inCount;
    if (callTypeValue === callType.outgoing && sAvgDurationFilter)
      return EmployeesStatisticsSortBy.outDuration;
    if (callTypeValue === callType.outgoing && sTotalCallsFilter)
      return EmployeesStatisticsSortBy.outCount;
    return EmployeesStatisticsSortBy.allCount;
  }, [callTypeValue, sAvgDurationFilter, sTotalCallsFilter]);

  const {
    data: employeesStatistics,
    error: employeesStatisticsError,
    loading,
    refetch,
  } = useQuery(GET_EMPLOYEES_STATISTICS_QUERY, {
    variables: {
      data: {
        from: filterPeriodValue ? historyPeriodValues[filterPeriodValue].from : startDay,
        to: filterPeriodValue ? historyPeriodValues[filterPeriodValue].to : currentDay,
        sortDirection: sAvgDurationFilter || sTotalCallsFilter || sortDirection.Desc,
        sortBy: sortByValue(),
        employees: selectedValuesList[1] || null,
        departments: selectedValuesList[0] || null,
      },
    },
  });

  const rows = employeesStatistics?.getEmployeesStatistics || [];
  const rowsWithIds = rows.map((row) => ({ ...row, id: row.employeeId }));
  const dataLength = rowsWithIds?.length;

  const { data: isHistoryData } = useQuery(HAS_HISTORY_CALLS_QUERY);
  const hasHistoryCalls = isHistoryData?.hasHistoryCalls;

  const { data: { departmentsAndEmployees = [] } = {}, loading: loadingDepAndEmp } = useQuery<{
    departmentsAndEmployees: IDepOrEmployees[];
  }>(GET_DEPARTMENTS_AND_EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const clientDepartments = departmentsAndEmployees
    ?.filter((config) => config.type === 'DEPARTMENTS')
    .map((el) => ({ value: el.id || null, title: el.name || '', additional: el.employeesCount }));

  const clientEmployees = departmentsAndEmployees
    ?.filter((config) => config.type === 'EMPLOYEES')
    .map((el) => ({ value: el.id || null, title: el.name || '' }));

  const filterPBXData = useMemo(
    () => [
      {
        title: translate('DEPARTMENTS'),
        data: clientDepartments || [],
      },
      {
        title: translate('EMPLOYEES'),
        data: clientEmployees || [],
      },
    ],
    [clientDepartments, clientEmployees, translate]
  );

  const disabledOptions = useMemo(() => {
    const result: Array<number> = [];

    if (!minDate) return result;

    const nowDateObject = new Date();
    const domainCreationDate = new Date(minDate);

    const [createDate, nowDate] = [domainCreationDate.getDate(), nowDateObject.getDate()];
    const [createMonth, nowMonth] = [domainCreationDate.getMonth(), nowDateObject.getMonth()];
    const [createYear, nowYear] = [domainCreationDate.getFullYear(), nowDateObject.getFullYear()];

    if (differenceInCalendarWeeks(domainCreationDate, nowDateObject, { weekStartsOn: 1 }) === 0)
      result.push(3); // past week

    const yearAndMonthSame = createYear === nowYear && createMonth === nowMonth;

    if (yearAndMonthSame) result.push(5);
    if (yearAndMonthSame && createDate === nowDate) result.push(1);

    return result;
  }, [minDate]);

  useEffect(() => {
    setHasSearchParams(!searchParams.keys().next().done);
  }, [searchParams, setHasSearchParams]);

  useEffect(() => {
    refetch().then();
  }, [refetch, selectedValuesList]);

  useEffect(() => {
    if (sCallType && callTypeValue !== sCallType) setValue('callType', sCallType);
  }, [callTypeValue, sCallType, setValue]);

  useEffect(() => {
    if (filterPeriodValue !== sFilterPeriodValue) {
      setValue('filterPeriod', Number(sFilterPeriodValue));
    }
  }, [filterPeriodValue, sFilterPeriodValue, setValue]);

  function handleSelectCallType({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    searchParams.set('callType', value);
    setSearchParams(searchParams);
  }

  function handleSelectPeriod({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    searchParams.set('filterPeriod', value);
    setSearchParams(searchParams);
  }

  function handleFilterChange(selectedValues: IFilterChangeValues | null) {
    if (selectedValues) {
      setSelectedValuesList(selectedValues);
    }
  }

  const renderCustomOption = ({
    data: renderOptionData,
    selected,
  }: ISelectFieldOptionRendererProps<{ value: string; title: string }>) => {
    const { value = '', title = '' } = renderOptionData;
    const Icon = filterTypeCallIcons[value || callType.allCalls];
    const computedClass = clsx({
      [classes.black]: value === callType.allCalls || undefined,
      [classes.links]: value === callType.incoming,
      [classes.success]: value === callType.outgoing,
    });
    return (
      <div className={classes.customOption}>
        <Flex alignItems={'center'}>
          {selected && <CheckIcon className={classes.glyph} />}
          <Typography
            className={clsx({ [classes.selectedText]: selected })}
            type={'text3'}
            color={'tertiary900'}
          >
            {translate(title)}
          </Typography>
        </Flex>
        <Icon className={computedClass} width={16} height={16} />
      </div>
    );
  };

  if (employeesStatisticsError) {
    return <PageError />;
  }

  const renderEmptyContent = () => {
    if (!loading && !employeesStatisticsError) {
      if (!dataLength && isDepartmentsListId) {
        return (
          <div className={classes.employeesStatisticEmptyContent}>
            <Flex direction={'column'} alignItems={'center'}>
              <UserXIcon className={classes.employeesStatisticFilterIcon} />
              <Typography type={'text3'} color={'tertiary600'}>
                {selectedValuesList[0]?.length >= 2
                  ? translate('NO_USERS_DATA_02')
                  : translate('NO_USERS_DATA')}
              </Typography>
            </Flex>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <BodyContainer disableOverflow>
      <Flex direction={'column'} className={classes.root}>
        <Breadcrumbs />
        <FormProvider {...formMethods}>
          <form action={''}>
            <Flex className={classes.employeesStatisticControlsWrapper}>
              <FilterByPBX
                data={filterPBXData}
                onFilterChange={(selectedValues) => handleFilterChange(selectedValues)}
                loading={loadingDepAndEmp}
                filterTitle={
                  (isDepartmentsListId || isEmployeesListId) && dataLength
                    ? `${translate('CHOSEN_EMPLOYEES')}: ${dataLength}`
                    : `${translate('ALL_EMPLOYEES')}`
                }
              />
              <Flex justifyContent={'spaceBetween'}>
                <div className={classes.empStatisticMarginRight}>
                  <SelectField
                    name={'callType'}
                    defaultValue={callType.allCalls}
                    size={'small'}
                    className={clsx(classes.defaultElementWidth14, classes.selectRoot)}
                    data={filterTypeCallOptions}
                    translating
                    onChange={handleSelectCallType}
                    titleKey={'title'}
                    valueKey={'value'}
                    Renderer={renderCustomOption}
                  />
                </div>
                <div className={classes.empStatisticMarginRight}>
                  <SelectField
                    translating
                    defaultValue={periodType.today}
                    name={'filterPeriod'}
                    size={'small'}
                    className={classes.defaultElementWidth14}
                    onChange={handleSelectPeriod}
                    data={filterPeriodsEmployeesStatistic}
                    disabledOptions={disabledOptions}
                    titleKey={'title'}
                    valueKey={'value'}
                  />
                </div>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
        {hasSearchParams || hasHistoryCalls ? (
          <LazyTable<IStatisticsByEmployees>
            columns={employeesStatisticColumns}
            data={rowsWithIds}
            pending={loading || !rowsWithIds.length}
            renderEmptyDataMessage={renderEmptyContent()}
          />
        ) : (
          <div className={classes.emptyBlock}>
            <StatisticsIcon className={classes.statisticsIcon} />
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('HERE_WILL_BE_EMPLOYEES_STATISTIC')}
            </Typography>
          </div>
        )}
      </Flex>
    </BodyContainer>
  );
};

export default StatisticsByEmployees;
