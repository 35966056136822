import React, { ChangeEvent, FunctionComponent } from 'react';
import clsx from 'clsx';
import { Slider } from '@material-ui/core';
import { useRangePickerStyles } from './RangePicker.styles';
import { IRangePicker } from './RangePicker.interfaces';

export const RangePicker: FunctionComponent<IRangePicker> = ({
  className,
  onRangeChange,
  ...props
}) => {
  const classes = useRangePickerStyles();

  function handleChange(event: ChangeEvent<unknown>, value: number | number[]) {
    if (value && onRangeChange) {
      onRangeChange(value);
    }
  }

  return <Slider className={clsx(classes.root, className)} onChange={handleChange} {...props} />;
};

export default RangePicker;
