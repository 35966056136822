import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { IBlackList } from '../SettingsBlackList.interfaces';

export const BlackListCallsPerYearCell: FunctionComponent<ICellProps<IBlackList>> = ({ item }) => {
  const { yearCalls = '' } = item || {};

  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {yearCalls}
    </Typography>
  );
};
