import PageError from '@shared/components/PageError';
import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

type Props = {
  children: ReactNode;
  leadingErrorMessage?: string;
  pageErrorBodyClassName?: string;
};

type State = {
  hasError: boolean;
};

class ErrorBoundaryCommon extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    const { leadingErrorMessage } = this.props;
    const leadingErrorMessageLocal = leadingErrorMessage ?? 'ErrorBoundary caught an error:';

    if (process.env.NODE_ENV === 'development') {
      console.error(leadingErrorMessageLocal, error, errorInfo);
    }
    Sentry.captureException(Error(`${leadingErrorMessage} ${error} ${errorInfo}`));
  }

  render() {
    const { children, pageErrorBodyClassName } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return <PageError bodyClassName={pageErrorBodyClassName} />;
    }

    return children;
  }
}

export default ErrorBoundaryCommon;
