export enum OperationType {
  Payment = 'PAYMENT',
  Periodic = 'PERIODIC',
  Telephony = 'TELEPHONY',
  OneTime = 'ONETIME',
  Credit = 'CREDIT',
  FreezedPayment = 'FREEZEDPAYMENTS',
}

export interface IBalance {
  id: string | number;
  name: string;
  type: OperationType;
  date: string;
  amount: number;
  burnt?: string;
}
