import { makeStyles } from '@material-ui/core/styles';

export const useSchedulerStyles = makeStyles(
  ({ color: { tertiary, base, primary, success, links, secondary, warning } }) => ({
    root: {
      display: 'flex',
      flexFlow: 'row',
      width: '66em',
      background: tertiary[50],
      padding: '0 2em 2em 0',
      borderRadius: '0.3125em',
      border: `0.0625em solid ${tertiary[100]}`,
      height: '100%',
      minWidth: '66em',
      position: 'relative',
      userSelect: 'none',
    },
    chart: {
      display: 'flex',
      flexFlow: 'column',
    },
    table: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      userSelect: 'none',
      position: 'relative',
    },
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      width: '100%',
      userSelect: 'none',
    },
    cell: {
      width: '2.5625em',
      minWidth: '2.5625em',
      height: '2em',
      minHeight: '2em',
      display: 'flex',
      flexFlow: 'row nowrap',
      marginLeft: '-0.0625em',
      marginTop: '-0.0625em',
      userSelect: 'none',
    },
    segment: {
      width: '25%',
      height: '100%',
      userSelect: 'none',
      boxSizing: 'border-box',
      '&$segmentActive:hover': {
        background: base,
      },
    },
    segmentActive: {},
    segmentContrast: {},
    segmentPrimary: {
      zIndex: 1,
      background: primary[100],
      borderTop: `0.0625em solid ${primary[300]}`,
      borderBottom: `0.0625em solid ${primary[300]}`,
      borderLeft: `0.0625em solid ${primary[200]}`,
      '&:first-child': {
        borderLeft: `0.0625em solid ${primary[300]}`,
      },
      '&:last-child': {
        borderRight: `0.0625em solid ${primary[300]}`,
      },
      '&$segmentFirst': {
        borderLeft: `0.0625em solid ${primary[300]}`,
      },
      '&$segmentLast': {
        borderRight: `0.0625em solid ${primary[300]}`,
      },
      '&$segmentActive': {
        zIndex: 2,
        background: primary[200],
        borderTop: `0.0625em solid ${primary[500]}`,
        borderBottom: `0.0625em solid ${primary[500]}`,
        borderLeft: `0.0625em solid ${primary[300]}`,
        '&:first-child': {
          borderLeft: `0.0625em solid ${primary[500]}`,
        },
        '&:last-child': {
          borderRight: `0.0625em solid ${primary[500]}`,
        },
        '&$segmentFirst': {
          borderLeft: `0.0625em solid ${primary[500]}`,
        },
        '&$segmentLast': {
          borderRight: `0.0625em solid ${primary[500]}`,
        },
      },
    },
    segmentFirst: {},
    segmentLast: {},
    segmentTertiary: {
      background: base,
      borderTop: `0.0625em solid ${tertiary[300]}`,
      borderBottom: `0.0625em solid ${tertiary[300]}`,
      borderLeft: `0.0625em solid ${tertiary[100]}`,
      '&:first-child': {
        borderLeft: `0.0625em solid ${tertiary[300]}`,
      },
      '&:last-child': {
        borderRight: `0.0625em solid ${tertiary[300]}`,
      },
      '&$segmentActive': {
        zIndex: 2,
        background: base,
        borderTop: `0.0625em solid ${tertiary[500]}`,
        borderBottom: `0.0625em solid ${tertiary[500]}`,
        borderLeft: `0.0625em solid ${tertiary[300]}`,
        '&:first-child': {
          borderLeft: `0.0625em solid ${tertiary[500]}`,
        },
        '&:last-child': {
          borderRight: `0.0625em solid ${tertiary[500]}`,
        },
        '&$segmentFirst': {
          borderLeft: `0.0625em solid ${tertiary[500]}`,
        },
        '&$segmentLast': {
          borderRight: `0.0625em solid ${tertiary[500]}`,
        },
      },
    },
    segmentSuccess: {
      zIndex: 1,
      background: success[100],
      borderTop: `0.0625em solid ${success[300]}`,
      borderBottom: `0.0625em solid ${success[300]}`,
      borderLeft: `0.0625em solid ${success[200]}`,
      '&:first-child': {
        borderLeft: `0.0625em solid ${success[300]}`,
      },
      '&:last-child': {
        borderRight: `0.0625em solid ${success[300]}`,
      },
      '&$segmentFirst': {
        borderLeft: `0.0625em solid ${success[300]}`,
      },
      '&$segmentLast': {
        borderRight: `0.0625em solid ${success[300]}`,
      },
      '&$segmentActive': {
        zIndex: 2,
        background: success[200],
        borderTop: `0.0625em solid ${success[500]}`,
        borderBottom: `0.0625em solid ${success[500]}`,
        borderLeft: `0.0625em solid ${success[300]}`,
        '&:first-child': {
          borderLeft: `0.0625em solid ${success[500]}`,
        },
        '&:last-child': {
          borderRight: `0.0625em solid ${success[500]}`,
        },
        '&$segmentFirst': {
          borderLeft: `0.0625em solid ${success[500]}`,
        },
        '&$segmentLast': {
          borderRight: `0.0625em solid ${success[500]}`,
        },
      },
    },
    segmentWarning: {
      zIndex: 1,
      background: warning[100],
      borderTop: `0.0625em solid ${warning[300]}`,
      borderBottom: `0.0625em solid ${warning[300]}`,
      borderLeft: `0.0625em solid ${warning[200]}`,
      '&:first-child': {
        borderLeft: `0.0625em solid ${warning[300]}`,
      },
      '&:last-child': {
        borderRight: `0.0625em solid ${warning[300]}`,
      },
      '&$segmentFirst': {
        borderLeft: `0.0625em solid ${warning[300]}`,
      },
      '&$segmentLast': {
        borderRight: `0.0625em solid ${warning[300]}`,
      },
      '&$segmentActive': {
        zIndex: 2,
        background: warning[200],
        borderTop: `0.0625em solid ${warning[500]}`,
        borderBottom: `0.0625em solid ${warning[500]}`,
        borderLeft: `0.0625em solid ${warning[300]}`,
        '&:first-child': {
          borderLeft: `0.0625em solid ${warning[500]}`,
        },
        '&:last-child': {
          borderRight: `0.0625em solid ${warning[500]}`,
        },
        '&$segmentFirst': {
          borderLeft: `0.0625em solid ${warning[500]}`,
        },
        '&$segmentLast': {
          borderRight: `0.0625em solid ${warning[500]}`,
        },
      },
    },
    segmentLinks: {
      zIndex: 1,
      background: links[100],
      borderTop: `0.0625em solid ${links[300]}`,
      borderBottom: `0.0625em solid ${links[300]}`,
      borderLeft: `0.0625em solid ${links[200]}`,
      '&:first-child': {
        borderLeft: `0.0625em solid ${links[300]}`,
      },
      '&:last-child': {
        borderRight: `0.0625em solid ${links[300]}`,
      },
      '&$segmentFirst': {
        borderLeft: `0.0625em solid ${links[300]}`,
      },
      '&$segmentLast': {
        borderRight: `0.0625em solid ${links[300]}`,
      },
      '&$segmentActive': {
        zIndex: 2,
        background: links[200],
        borderTop: `0.0625em solid ${links[500]}`,
        borderBottom: `0.0625em solid ${links[500]}`,
        borderLeft: `0.0625em solid ${links[300]}`,
        '&:first-child': {
          borderLeft: `0.0625em solid ${links[500]}`,
        },
        '&:last-child': {
          borderRight: `0.0625em solid ${links[500]}`,
        },
        '&$segmentFirst': {
          borderLeft: `0.0625em solid ${links[500]}`,
        },
        '&$segmentLast': {
          borderRight: `0.0625em solid ${links[500]}`,
        },
      },
    },
    segmentSecondary: {
      zIndex: 1,
      background: secondary[100],
      borderTop: `0.0625em solid ${secondary[300]}`,
      borderBottom: `0.0625em solid ${secondary[300]}`,
      borderLeft: `0.0625em solid ${secondary[200]}`,
      '&:first-child': {
        borderLeft: `0.0625em solid ${secondary[300]}`,
      },
      '&:last-child': {
        borderRight: `0.0625em solid ${secondary[300]}`,
      },
      '&$segmentFirst': {
        borderLeft: `0.0625em solid ${secondary[300]}`,
      },
      '&$segmentLast': {
        borderRight: `0.0625em solid ${secondary[300]}`,
      },
      '&$segmentActive': {
        zIndex: 2,
        background: secondary[200],
        borderTop: `0.0625em solid ${secondary[500]}`,
        borderBottom: `0.0625em solid ${secondary[500]}`,
        borderLeft: `0.0625em solid ${secondary[300]}`,
        '&:first-child': {
          borderLeft: `0.0625em solid ${secondary[500]}`,
        },
        '&:last-child': {
          borderRight: `0.0625em solid ${secondary[500]}`,
        },
        '&$segmentFirst': {
          borderLeft: `0.0625em solid ${secondary[500]}`,
        },
        '&$segmentLast': {
          borderRight: `0.0625em solid ${secondary[500]}`,
        },
      },
    },
    cellHoverPrimary: {
      '&:hover': {
        '& > $segment': {
          '&:hover': {
            background: primary[200],
          },
          '&$segmentPrimary': {
            '&:hover': {
              background: base,
            },
          },
          zIndex: 2,
          borderTop: `0.0625em solid ${primary[500]}`,
          borderBottom: `0.0625em solid ${primary[500]}`,
          '&:first-child': {
            borderLeft: `0.0625em solid ${primary[500]}`,
          },
          '&:last-child': {
            borderRight: `0.0625em solid ${primary[500]}`,
          },
        },
      },
    },
    cellHoverSuccess: {
      '&:hover': {
        '& > $segment': {
          '&:hover': {
            background: success[200],
          },
          '&$segmentSuccess': {
            '&:hover': {
              background: base,
            },
          },
          zIndex: 2,
          borderTop: `0.0625em solid ${success[500]}`,
          borderBottom: `0.0625em solid ${success[500]}`,
          '&:first-child': {
            borderLeft: `0.0625em solid ${success[500]}`,
          },
          '&:last-child': {
            borderRight: `0.0625em solid ${success[500]}`,
          },
        },
      },
    },
    cellHoverSecondary: {
      '&:hover': {
        '& > $segment': {
          '&:hover': {
            background: secondary[200],
          },
          '&$segmentSecondary': {
            '&:hover': {
              background: base,
            },
          },
          zIndex: 2,
          borderTop: `0.0625em solid ${secondary[500]}`,
          borderBottom: `0.0625em solid ${secondary[500]}`,
          '&:first-child': {
            borderLeft: `0.0625em solid ${secondary[500]}`,
          },
          '&:last-child': {
            borderRight: `0.0625em solid ${secondary[500]}`,
          },
        },
      },
    },
    cellHoverLinks: {
      '&:hover': {
        '& > $segment': {
          '&:hover': {
            background: links[200],
          },
          '&$segmentLinks': {
            '&:hover': {
              background: base,
            },
          },
          zIndex: 2,
          borderTop: `0.0625em solid ${links[500]}`,
          borderBottom: `0.0625em solid ${links[500]}`,
          '&:first-child': {
            borderLeft: `0.0625em solid ${links[500]}`,
          },
          '&:last-child': {
            borderRight: `0.0625em solid ${links[500]}`,
          },
        },
      },
    },
    scale: {
      position: 'relative',
      alignItems: 'center',
      height: '2.875em',
      display: 'flex',
      flexFlow: 'row nowrap',
      userSelect: 'none',
      '& $scaleCell:first-child': {
        width: '2.5em',
        minWidth: '2.5em',
        justifyContent: 'flex-start',
      },
      '& $scaleCell:last-child': {
        width: '2.5em',
        minWidth: '2.5em',
        justifyContent: 'flex-end',
      },
    },
    scaleCell: {
      width: '5em',
      minWidth: '5em',
      justifyContent: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      userSelect: 'none',
    },
    dayControl: {
      userSelect: 'none',
      cursor: 'pointer',
      display: 'flex',
      padding: '0 1.5em',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      textDecoration: 'underline',
    },
    emptyCell: {
      display: 'flex',
      height: '2.875em',
      minHeight: '2.875em',
      userSelect: 'none',
      width: 'auto',
    },
    daysControls: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
  })
);
