import React, { FunctionComponent } from 'react';
import Indicator from '@components/Indicator/Indicator';
import { IEmployee } from '@components/typings/interfaces';
import clsx from 'clsx';
import { ICellProps } from '@components/LazyTable';
import { useEmployeesStyles } from '../Employees.styles';

export const EmployeeStatusCell: FunctionComponent<ICellProps<IEmployee>> = ({ item }) => {
  const classes = useEmployeesStyles();
  const className = clsx(classes.indicatorIcon, {
    [classes.active]: item?.hasSipRegistration,
    [classes.inactive]: !item?.hasSipRegistration,
  });
  return <Indicator className={className} />;
};
