import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CLIENT_QUERY, USER_QUERY } from '@/client/queries';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@/components/Breadcrumbs';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { useNavigate } from 'react-router-dom';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import DateField from '@shared/components/DateField';
import PhoneField from '@shared/components/PhoneField';
import EmailField from '@shared/components/EmailField';
import UploadField from '@components/UploadField';
import { FormProvider, useForm } from 'react-hook-form';
import BodyContainer from '@/layouts/BodyContainer';
import { DomainStatus } from '@components/typings/interfaces';
import Button from '@shared/components/Button';
import MessageDialog from '@shared/components/MessageDialog';
import { TrashIcon } from '@shared/assets/images/icons';
import ControlButtons from '@shared/components/ControlButtons';
import { UPDATE_CLIENT_CONTACTS_MUTATION } from '@/client/mutations';
import Snackbar from '@shared/components/Snackbar';
import { ClientType } from '@/client/generated/graphql';
import { usePayerDataStyle } from './PayerData.styles';

export const PayerData = () => {
  const classes = usePayerDataStyle();
  const [translate] = useTranslation();

  const { data } = useQuery(CLIENT_QUERY);
  const individualPassport = data?.getClient.individualPassport || null;
  const legalPassport = data?.getClient.legalPassport || null;
  const type = data?.getClient.type || null;

  const params = useMemo(() => {
    return data?.getClient?.params || null;
  }, [data?.getClient.params]);

  const { data: userData } = useQuery(USER_QUERY);
  const [updateClientContacts, { loading: loadingUpdateClientContacts }] = useMutation(
    UPDATE_CLIENT_CONTACTS_MUTATION,
    {
      refetchQueries: [
        {
          query: CLIENT_QUERY,
        },
      ],
    }
  );
  const [clientPhonesList, setClientPhonesList] = useState<string[]>([]);
  const [clientEmailsList, setClientEmailsList] = useState<string[]>([]);
  const [isEditPayerContactsDialogOpen, setIsEditPayerContactsDialogOpen] = useState(false);
  const [isSuccessNotifyOpen, setIsSuccessNotifyOpen] = useState(false);
  const [updateClientContactsDialogError, setUpdateClientContactsDialogError] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const isOneClientPhone = clientPhonesList?.length <= 1;
  const isOneClientEmail = clientEmailsList?.length <= 1;
  const status = getCurrentDomain(userData?.user)?.status;
  const navigate = useNavigate();
  const formMethods = useForm();
  const { setValue, watch, reset, clearErrors, handleSubmit } = formMethods;
  const isClientFl = useMemo(() => type === ClientType.Fl, [type]);
  const isClientUL = useMemo(() => type === ClientType.Ul, [type]);
  const isClientIP = useMemo(() => type === ClientType.Ip, [type]);
  const isNotOneEmail = (params?.contacts?.emails?.length || 0) > 1;
  const isNotOnePhone = (params?.contacts?.phones?.length || 0) > 1;
  const clientPhones = watch('phones');
  const clientEmails = watch('emails');

  const personalPassportData = useMemo(() => {
    if (!individualPassport && !legalPassport) return null;
    const clientPassport = individualPassport || legalPassport;
    return {
      ...clientPassport,
      phones: params?.contacts?.phones,
      emails: params?.contacts?.emails,
    };
  }, [individualPassport, legalPassport, params]);

  const setDefaultFormValues = useCallback(() => {
    setClientEmailsList(personalPassportData?.emails || []);
    setClientPhonesList(personalPassportData?.phones || []);
  }, [personalPassportData]);

  const getErrorMessage = (message: string) => {
    const msg = translate(message);
    if (msg === message) {
      return translate('ERROR_TEXT');
    }
    return msg;
  };

  useEffect(() => {
    if (personalPassportData) {
      setClientPhonesList(personalPassportData?.phones || []);
      setClientEmailsList(personalPassportData?.emails || []);
      setDefaultFormValues();
    }
  }, [
    personalPassportData,
    isClientFl,
    isClientUL,
    isClientIP,
    setValue,
    translate,
    setDefaultFormValues,
  ]);

  useEffect(() => {
    setValue('email', params?.contacts?.emails);
    setValue('phone', params?.contacts?.phones);
    if (
      clientEmailsList.length === 0 &&
      params?.contacts?.emails &&
      params?.contacts?.emails.length !== 0
    ) {
      setClientEmailsList(params?.contacts?.emails || []);
    }
    if (
      clientPhonesList.length === 0 &&
      params?.contacts?.phones &&
      params?.contacts?.phones.length !== 0
    ) {
      setClientPhonesList(params?.contacts?.phones || []);
    }
    if (individualPassport) {
      setValue('personName', individualPassport.name);
      setValue('inn', individualPassport.inn);
      setValue('series', individualPassport.series);
      setValue('number', individualPassport.number);
      setValue('placeOfIssue', individualPassport.placeOfIssue);
      setValue('dateOfIssue', new Date(individualPassport.dateOfIssue));
      setValue('departmentCode', individualPassport.departmentCode);
      setValue('legalAddress', individualPassport.legalAddress);
      setValue('postalAddress', individualPassport.postalAddress);
      setValue('passportFrontFileHash', individualPassport.scans?.[0]);
      setValue('passportFrontFileName', translate('DOCUMENT_LOADED'));
      setValue('passportAddressFileHash', individualPassport.scans?.[1]);
      setValue('passportAddressFileName', translate('DOCUMENT_LOADED'));
    }
    if (legalPassport) {
      setValue('bik', legalPassport.bik);
      setValue('bankName', legalPassport.bankName);
      setValue('correspondentAccount', legalPassport.correspondentAccount);
      setValue('personName', legalPassport.person);
      setValue('genitiveCasePerson', legalPassport.genitiveCasePerson);
      setValue('account', legalPassport.account);
      if (isClientUL) {
        setValue('position', legalPassport.position);
        setValue('genitiveCasePosition', legalPassport.genitiveCasePosition);
        setValue('actBasis', legalPassport.actBasis);
      }
      if (isClientUL || isClientIP) {
        setValue('companyName', legalPassport.name);
        setValue('inn', legalPassport.inn);
        setValue('kpp', legalPassport.kpp);
        setValue('ogrn', legalPassport.ogrn);
        setValue('postalAddress', legalPassport.postalAddress);
        setValue('legalAddress', legalPassport.legalAddress);
      }
    }
  }, [
    setValue,
    legalPassport,
    individualPassport,
    userData,
    isClientUL,
    isClientIP,
    translate,
    params,
    clientEmailsList.length,
    clientPhonesList.length,
  ]);

  useEffect(() => {
    if (status && status === DomainStatus.Demo) {
      navigate('/register/contract');
    }
  }, [status, navigate]);

  useEffect(() => {
    setValue('emails', clientEmailsList);
  }, [clientEmailsList, setValue]);

  useEffect(() => {
    setValue('phones', clientPhonesList);
  }, [clientPhonesList, setValue]);

  function handleEditCancelClick() {
    setIsEditPayerContactsDialogOpen(false);
    setDefaultFormValues();
    clearErrors();
  }

  function handleRemoveClientEmail(i: number) {
    const updatedEmailsList = [...clientEmails];
    updatedEmailsList.splice(i, 1);
    setClientEmailsList(updatedEmailsList);
  }

  function handleRemoveClientPhone(i: number) {
    const updatedPhonesList = [...clientPhones];
    updatedPhonesList.splice(i, 1);
    setClientPhonesList(updatedPhonesList);
  }

  function handleAddClientPhone() {
    const newClientPhonesList = [...clientPhones];
    newClientPhonesList.push(null);
    reset({}, { keepValues: true });
    setClientPhonesList(newClientPhonesList);
  }

  function handleAddClientEmail() {
    const newClientEmailsList = [...clientEmails];
    newClientEmailsList.push(null);
    setClientEmailsList(newClientEmailsList);
  }

  function handleUpdateClientContacts() {
    updateClientContacts({
      variables: {
        data: {
          emails: clientEmails,
          phones: clientPhones,
        },
      },
    })
      .then((res) => {
        setIsEditPayerContactsDialogOpen(false);
        setIsSuccessNotifyOpen(true);
      })
      .catch(() => {
        setIsEditPayerContactsDialogOpen(false);
        setUpdateClientContactsDialogError({
          isOpen: true,
          title: 'SOMETHING_WENT_WRONG',
          message: 'ERROR_TEXT',
        });
      });
  }

  const renderDocumentClientContacts = () => (
    <>
      <Flex justifyContent={'spaceBetween'}>
        <div className={classes.personalDataTitle}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('CONTACTS')}
          </Typography>
        </div>
        <Button
          className={classes.editContactsBtn}
          variant={'secondary'}
          onClick={() => setIsEditPayerContactsDialogOpen(true)}
          title={translate('EDITING')}
        />
      </Flex>
      <div className={classes.personalDataContainer}>
        {params?.contacts ? (
          <>
            {isNotOnePhone && (
              <div className={classes.personalDataContactTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('NUMBER')}
                </Typography>
              </div>
            )}
            {clientPhonesList.map((phone: string, index: number) => (
              <div className={classes.personalDataItem} key={`client-phone-${phone}`}>
                <PhoneField
                  name={`phone.${index}`}
                  InputProps={{ readOnly: true }}
                  label={isNotOnePhone ? undefined : translate('PHONE')}
                />
              </div>
            ))}
            {isNotOneEmail && (
              <div className={classes.personalDataContactTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('EMAIL')}
                </Typography>
              </div>
            )}
            {clientEmailsList.map((email: string, index: number) => (
              <div className={classes.personalDataItem} key={`client-email-${email}`}>
                <EmailField
                  name={`email.${index}`}
                  InputProps={{ readOnly: true }}
                  label={isNotOneEmail ? undefined : translate('EMAIL')}
                />
              </div>
            ))}
          </>
        ) : (
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('CONTACTS_NOT_SPECIFIED')}
          </Typography>
        )}
      </div>
    </>
  );

  const renderEditContactsModalContent = () => (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleUpdateClientContacts)} id={'edit-contacts'}>
        <div className={classes.personalDataContactTitle}>
          <Typography type={'text3'} color={'tertiary900'} medium>
            {translate('NUMBER')}
          </Typography>
        </div>
        <div className={classes.personalDataItem}>
          {clientPhonesList?.map((number: string | null, index: number) => (
            <div className={classes.editContactsItem} key={`client-phone-${number}`}>
              <div className={classes.editContactsContainer}>
                <PhoneField name={`phones.${index}`} required />
                <Button
                  color={'error'}
                  variant="secondary"
                  className={classes.personalDataRemoveBtn}
                  disabled={isOneClientPhone}
                  onClick={() => handleRemoveClientPhone(index)}
                >
                  <TrashIcon />
                </Button>
              </div>
            </div>
          ))}
          <div className={classes.editContactsItem}>
            <Button
              className={classes.addContactBtn}
              variant={'secondary'}
              onClick={handleAddClientPhone}
              title={translate('ADD_NUMBER')}
              disabled={clientPhones?.some(
                (phone: string | null) => phone === null || phone.replace(/[^+\d]+/g, '').length < 8
              )}
            />
          </div>
        </div>
        <div className={classes.personalDataContactTitle}>
          <Typography type={'text3'} color={'tertiary900'} medium>
            {translate('EMAIL')}
          </Typography>
        </div>
        {clientEmailsList?.map((email: string | null, index: number) => (
          <div className={classes.editContactsItem} key={`client-email-${email}`}>
            <div className={classes.editContactsContainer}>
              <EmailField name={`emails.${index}`} required />
              <Button
                className={classes.editContactsRemoveBtn}
                variant={'secondary'}
                color={'error'}
                disabled={isOneClientEmail}
                onClick={() => handleRemoveClientEmail(index)}
              >
                <TrashIcon />
              </Button>
            </div>
          </div>
        ))}
        <Button
          className={classes.addContactBtn}
          variant={'secondary'}
          onClick={handleAddClientEmail}
          title={translate('ADD_EMAIL')}
          disabled={clientEmails?.some((email: string) => email === '')}
        />
        <ControlButtons
          rootClass={classes.marginTop2}
          confirmTitle={'SAVE'}
          cancelTitle={'CANCEL'}
          cancelVariant="secondary"
          form={'edit-contacts'}
          onCancelClick={handleEditCancelClick}
          loading={loadingUpdateClientContacts}
          justifyContent={'start'}
          flexDirection={'row-reverse'}
          small
        />
      </form>
    </FormProvider>
  );

  const renderDocumentPersonalData = () => {
    if (type) {
      if (isClientFl) {
        return (
          <Flex>
            <Flex className={classes.personalDataWrapper} direction={'column'}>
              <div className={classes.personalDataTitle}>
                <Typography type={'text2'} color={'tertiary900'}>
                  {translate('CLIENT')}
                </Typography>
              </div>
              <div className={classes.personalDataContainer}>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'type'}
                    label={translate('LEGAL_STATUS')}
                    value={translate('INDIVIDUAL_TYPE')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'personName'}
                    label={translate('FULL_NAME')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'inn'}
                    label={translate('INN')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <Flex justifyContent={'spaceBetween'} className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'series'}
                    label={translate('SERIES')}
                    InputProps={{ readOnly: true }}
                    className={classes.defaultElementWidth6}
                  />
                  <FormFieldRhfUncontrolled
                    name={'number'}
                    label={translate('PAS_NUMBER')}
                    InputProps={{ readOnly: true }}
                    className={classes.defaultElementWidth19}
                  />
                </Flex>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'placeOfIssue'}
                    multiline
                    rows={3}
                    label={translate('WHO_GIVEN')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <DateField
                    name={'dateOfIssue'}
                    label={translate('GIVEN_DATE')}
                    disableFuture
                    readonly
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'departmentCode'}
                    label={translate('CODE')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'legalAddress'}
                    multiline
                    rows={3}
                    label={translate('REGISTRATION_ADDRESS')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'postalAddress'}
                    multiline
                    rows={3}
                    label={translate('LIVING_ADDRESS')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
              </div>
            </Flex>
            <Flex direction={'column'} className={classes.personalDataColumn}>
              {renderDocumentClientContacts()}
              <div className={classes.personalDataTitle}>
                <Typography type={'text2'} color={'tertiary900'}>
                  {translate('DOCUMENTS')}
                </Typography>
              </div>
              <div className={classes.personalDataContainer}>
                <div className={classes.personalDataItem}>
                  <Typography
                    type={'text4'}
                    color={'tertiary600'}
                    className={classes.personalDataUploadLabel}
                  >
                    {`${translate('PASSPORT')}. ${translate('PASSPORT_DATA_PAGE')}`}
                  </Typography>
                  <UploadField
                    label={translate('CHOOSE_FILE')}
                    requiredText={translate('FILE_NOT_CHOSEN')}
                    fileHashFieldName={'passportFrontFileHash'}
                    fileNameFieldName={'passportFrontFileName'}
                    fileUrlFieldName={'passportFrontFileUrl'}
                    fileProgressName={'passportDataPage'}
                    readonly
                    showViewButton
                    required
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <Typography
                    type={'text4'}
                    color={'tertiary600'}
                    className={classes.personalDataUploadLabel}
                  >
                    {`${translate('PASSPORT')}. ${translate('PASSPORT_ADDRESS_PAGE')}`}
                  </Typography>
                  <UploadField
                    label={translate('CHOOSE_FILE')}
                    requiredText={translate('FILE_NOT_CHOSEN')}
                    fileHashFieldName={'passportAddressFileHash'}
                    fileNameFieldName={'passportAddressFileName'}
                    fileUrlFieldName={'passportAddressFileUrl'}
                    fileProgressName={'passportAddressPage'}
                    readonly
                    showViewButton
                    required
                  />
                </div>
              </div>
            </Flex>
          </Flex>
        );
      }
      if (isClientUL || isClientIP) {
        return (
          <Flex>
            <Flex direction={'column'}>
              <Flex className={classes.personalDataWrapper} direction={'column'}>
                <div className={classes.personalDataTitle}>
                  <Typography type={'text2'} color={'tertiary900'}>
                    {translate('PAYER')}
                  </Typography>
                </div>
                <div className={classes.personalDataContainer}>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'type'}
                      label={translate('LEGAL_STATUS')}
                      value={translate(isClientUL ? 'LEGAL_TYPE' : 'BUSINESSMAN_TYPE')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'companyName'}
                      label={translate('COMPANY_NAME')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'inn'}
                      label={translate('INN')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  {isClientUL && (
                    <div className={classes.personalDataItem}>
                      <FormFieldRhfUncontrolled
                        name={'kpp'}
                        label={translate('KPP')}
                        InputProps={{ readOnly: true }}
                      />
                    </div>
                  )}
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'ogrn'}
                      label={translate(isClientUL ? 'OGRN' : 'OGRNIP')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'legalAddress'}
                      multiline
                      rows={3}
                      label={translate('LEGAL_ADDRESS')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'postalAddress'}
                      multiline
                      rows={3}
                      label={translate('POSTAL_ADDRESS')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
              </Flex>
              <Flex className={classes.personalDataWrapper} direction={'column'}>
                <div className={classes.personalDataTitle}>
                  <Typography type={'text2'} color={'tertiary900'}>
                    {translate('SIGNATORY')}
                  </Typography>
                </div>
                <div className={classes.personalDataContainer}>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'personName'}
                      label={translate('FULL_NAME')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className={classes.personalDataItem}>
                    <FormFieldRhfUncontrolled
                      name={'genitiveCasePerson'}
                      label={translate('GENITIVE_NAME')}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  {isClientUL && (
                    <>
                      <div className={classes.personalDataItem}>
                        <FormFieldRhfUncontrolled
                          name={'position'}
                          label={translate('POSITION')}
                          InputProps={{ readOnly: true }}
                        />
                      </div>
                      {legalPassport?.genitiveCasePosition && (
                        <div className={classes.personalDataItem}>
                          <FormFieldRhfUncontrolled
                            name={'genitiveCasePosition'}
                            label={translate('POSITION_GENITIVE')}
                            InputProps={{ readOnly: true }}
                          />
                        </div>
                      )}
                      <div className={classes.personalDataItem}>
                        <FormFieldRhfUncontrolled
                          name={'actBasis'}
                          label={translate('BASIC_CONTRACT_GENITIVE')}
                          InputProps={{ readOnly: true }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Flex>
            </Flex>
            <Flex direction={'column'} className={classes.personalDataColumn}>
              {renderDocumentClientContacts()}
              <div className={classes.personalDataTitle}>
                <Typography type={'text2'} color={'tertiary900'}>
                  {translate('BANK_DETAILS')}
                </Typography>
              </div>
              <div className={classes.personalDataContainer}>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'bik'}
                    label={translate('BIK')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'bankName'}
                    label={translate('BANK_NAME')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'account'}
                    label={translate('CHECKING_ACCOUNT')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
              </div>
            </Flex>
          </Flex>
        );
      }
    }
    return null;
  };

  const renderSuccessNotify = () => {
    const closeSuccessNotifySnackbar = () => setIsSuccessNotifyOpen(false);

    if (!loadingUpdateClientContacts) {
      return (
        <Snackbar
          open={isSuccessNotifyOpen}
          onClose={closeSuccessNotifySnackbar}
          autoHideDuration={4000}
          withLeftMargin
          AlertProps={{
            description: translate('YOUR_CONTACTS_HAVE_BEEN_CHANGED'),
            status: 'success',
            variant: 'filled',
            onClose: closeSuccessNotifySnackbar,
          }}
        />
      );
    }
    return null;
  };

  return (
    <BodyContainer breadCrumbs={<Breadcrumbs />}>
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.payerDataRoot}>
            <FormProvider {...formMethods}>
              <form>
                <div className={classes.documentsContentContainer}>
                  <div className={classes.documentsContent}>{renderDocumentPersonalData()}</div>
                </div>
                <MessageDialog
                  isOpen={isEditPayerContactsDialogOpen}
                  contentClass={classes.defaultElementWidth26}
                  title={translate('CONTACTS')}
                  onCancel={handleEditCancelClick}
                  renderContent={renderEditContactsModalContent()}
                />
                <MessageDialog
                  isOpen={updateClientContactsDialogError.isOpen}
                  title={translate(updateClientContactsDialogError.title || 'SOMETHING_WENT_WRONG')}
                  message={getErrorMessage(updateClientContactsDialogError.message)}
                  onCancel={() =>
                    setUpdateClientContactsDialogError({
                      ...updateClientContactsDialogError,
                      isOpen: false,
                    })
                  }
                  renderControls={
                    <Button
                      title={translate('CLOSE')}
                      variant={'primary'}
                      color={'error'}
                      smallHeight
                      onClick={() =>
                        setUpdateClientContactsDialogError({
                          ...updateClientContactsDialogError,
                          isOpen: false,
                        })
                      }
                    />
                  }
                />
              </form>
            </FormProvider>
            {renderSuccessNotify()}
          </div>
        </div>
      </div>
    </BodyContainer>
  );
};
