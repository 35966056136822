import { makeStyles } from '@material-ui/core/styles';

export const useSchedulerDayPeriodStyles = makeStyles(
  ({ color: { primary, tertiary, base, success, danger, links, secondary, warning } }) => ({
    form: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      padding: '1.5em 0',
      borderBottom: `0.0625em solid ${tertiary[100]}`,
    },
    formActive: {
      background: tertiary[50],
    },
    formControls: {
      height: '2.75em',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      width: '100%',
      whiteSpace: 'normal',
      '& > :not(:first-child)': {
        marginLeft: '1em',
      },
    },
    formSubmissionControls: {
      margin: '2em 2em 0',
    },
    indicator: {
      height: '1em',
      maxHeight: '1em',
      minHeight: '1em',
      width: '1em',
      maxWidth: '1em',
      minWidth: '1em',
      margin: '0 1em 0 0.5em',
      borderRadius: '3px',
    },
    default: {
      background: base,
      border: `1px solid ${tertiary[300]}`,
    },
    success: {
      background: success[500],
    },
    error: {
      background: danger[500],
    },
    primary: {
      background: primary[500],
    },
    links: {
      background: links[500],
    },
    warning: {
      background: warning[600],
    },
    secondary: {
      background: secondary[600],
    },
    timeFieldset: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    separator: {
      width: '1em',
      margin: '0.5em',
      background: tertiary[400],
      height: '1.5px',
    },
    customOption: {
      height: '2.5em',
      padding: '0 1em',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      pointerEvents: 'none',
    },
    validationError: {
      lineHeight: 1,
      marginTop: '0.5em',
    },
    cancelButton: {
      padding: 'initial',
      height: '2.5em',
      minWidth: '2.5em',
      marginBottom: 'auto',
    },
    periodField: {
      display: 'flex',
      flexFlow: 'column',
    },
  })
);
