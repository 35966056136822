import { makeStyles } from '@material-ui/core';

export const useRangePickerStyles = makeStyles(({ color: { primary, tertiary } }) => ({
  root: {
    backgroundColor: 'initial',
    color: primary[700],
    '& .MuiSlider-thumb::after': {
      border: `2px solid ${primary[700]}`,
      top: '-2px',
      left: '-2px',
      right: '-2px',
      bottom: '-2px',
      position: 'absolute',
      borderRadius: '50%',
    },
    '& .MuiSlider-rail': {
      backgroundColor: tertiary[200],
      height: '0.25em',
    },
    '& .MuiSlider-track': {
      backgroundColor: primary[600],
      height: '0.25em',
    },
  },
}));
