import { makeStyles } from '@material-ui/core';

export const useCheckboxFieldStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      fontSize: '0.875em',
      marginLeft: 0,
      marginRight: 0,
    },
    label: {
      fontSize: '1em',
      paddingTop: '0.125em',
      lineHeight: '1.7em',
      color: tertiary[900],
      '&$disabled': {
        color: tertiary[300],
      },
    },
    disabled: {},
    readOnly: {
      color: tertiary[100],
    },
  }),
  {
    index: 1,
  }
);
