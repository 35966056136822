import { makeStyles } from '@material-ui/core/styles';

export const useBreadcrumbStyles = makeStyles(
  ({ color: { tertiary, primary } }) => ({
    root: {
      fontSize: '0.875em',
      position: 'relative',
      padding: '0.5em 0.5em 1em',
      textDecoration: 'none',
      color: tertiary[600],
      marginRight: '1em',
      '&:last-child': {
        marginRight: 'initial',
      },
      '&::after': {
        borderRadius: '2px 2px 0px 0px',
        content: '" "',
        borderTop: '2px solid transparent',
        position: 'absolute',
        height: '0px',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'transparent',
      },
      '&:hover': {
        color: tertiary[900],
        '&:after': {
          borderTop: `2px solid ${tertiary[300]}`,
        },
      },
    },
    active: {
      color: tertiary[900],
      cursor: 'default',
      '&::after': {
        borderTop: `2px solid ${primary[700]}`,
      },
      '&:hover': {
        color: tertiary[900],
      },
    },
  }),
  {
    index: 1,
  }
);
