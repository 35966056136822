import React, { useMemo } from 'react';
import { Trans, TransProps } from 'react-i18next';

const Translate = (props: TransProps<string>) => {
  const { values } = props || {};
  const pattern = useMemo(
    () =>
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|<img[^>]+>(<\/img>)?|<iframe.+?<\/iframe>/gi,
    []
  );
  let filteredValue = null;

  if (values) {
    const variablesList = Object.values(values).map(
      (el: any) => el?.toString().replace(pattern, '')
    );
    filteredValue = Object.keys(values).reduce(
      (acc, key, index) => ({ ...acc, [key]: variablesList[index] }),
      {}
    );
  }

  return (
    <Trans
      {...props}
      values={filteredValue || {}}
      tOptions={{ interpolation: { escapeValue: false } }}
    />
  );
};
export default Translate;
