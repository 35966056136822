import React from 'react';
import { observable } from '@legendapp/state';
import { useSelector } from '@legendapp/state/react';
import Snackbar from '@shared/components/Snackbar';
import { useTranslation } from 'react-i18next';
import { AlertStatus, AlertVariant } from '@shared/components/Alert/Alert.types';

type GlobalNotification = {
  notificationData: {
    status: AlertStatus;
    variant: AlertVariant;
    message: string;
    isVisible: boolean;
    titleHasRegularWeight: boolean;
  };
  show(
    status: AlertStatus,
    message: string,
    hideDelayMS?: number,
    variant?: AlertVariant,
    titleHasRegularWeight?: boolean
  ): void;
};

let globalNotificationHideDelayTimeout: ReturnType<typeof setTimeout> | null = null;

export const globalNotification$ = observable<GlobalNotification>({
  show(status, message, hideDelay = 4000, variant = 'filled', titleHasRegularWeight = true) {
    if (globalNotificationHideDelayTimeout !== null) {
      clearTimeout(globalNotificationHideDelayTimeout);
    }

    globalNotificationHideDelayTimeout = setTimeout(() => {
      globalNotification$.notificationData.isVisible.set(false);
      globalNotificationHideDelayTimeout = null;
    }, hideDelay);

    globalNotification$.notificationData.set({
      status,
      message,
      isVisible: true,
      variant,
      titleHasRegularWeight,
    });
  },
  notificationData: {
    status: 'success',
    message: '',
    isVisible: false,
    variant: 'filled',
    titleHasRegularWeight: true,
  },
});

export const GlobalSnackbarNotification = () => {
  const notificationData = useSelector(globalNotification$.notificationData);
  const [translate] = useTranslation();

  const handleClose = () => {
    globalNotification$.notificationData.isVisible.set(false);
  };

  const title = notificationData.titleHasRegularWeight
    ? undefined
    : translate(notificationData.message);
  const description = notificationData.titleHasRegularWeight
    ? translate(notificationData.message)
    : undefined;

  return (
    <Snackbar
      autoHideDuration={null}
      open={notificationData.isVisible}
      AlertProps={{
        title,
        description,
        status: notificationData.status,
        variant: notificationData.variant,
      }}
      onClose={handleClose}
    />
  );
};
