import { AlertCircleIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { usePageErrorStyle } from './PageError.styles';
import { PageErrorProps } from './PageError.types';

const PageErrorDefaultContent = () => {
  const [translate] = useTranslation();

  return (
    <>
      <Typography color={'tertiary600'} type={'text3'}>
        {translate('PAGE_ERROR_HEADER')}
      </Typography>
      <Typography color={'tertiary600'} type={'text3'}>
        {translate('PAGE_ERROR_TEXT1')}
      </Typography>
      <Typography color={'tertiary600'} type={'text3'}>
        {translate('PAGE_ERROR_TEXT2')}
      </Typography>
    </>
  );
};

export const PageError = ({ customErrorMessage, bodyClassName }: PageErrorProps) => {
  const classes = usePageErrorStyle();

  return (
    <div className={clsx(classes.body, bodyClassName)}>
      <AlertCircleIcon className={classes.alertIcon} />
      {customErrorMessage || <PageErrorDefaultContent />}
    </div>
  );
};

export default PageError;
