import { deserializeEmployee, deserializeUser } from '@components/typings/interfaces';
import { isBase64ImageValid } from '@shared/utils/images';
import { getRole } from '@/utils';
import { IProfileData, IProfileFormData } from './ProfileFormInterfaces';

export const deserializeProfile = (data: any = {}): IProfileData => {
  const { name, email, notifications, avatar } = deserializeUser(data);
  const { position, role, ext, phone, callForwarding, callForwardingTimeout, departments } =
    deserializeEmployee(
      data?.domains?.find(({ current }: { current?: boolean }) => current)?.employees?.[0] || {}
    );
  return {
    position,
    role: getRole(role) || undefined,
    name,
    phone,
    email,
    ext,
    callForwarding,
    callForwardingTimeout,
    departments,
    notifications,
    avatar,
  };
};

/**
 * This function deserializes profile data only for Profile.tsx form
 */
export const deserializeProfileForForm = (data: any = {}): IProfileFormData => {
  const {
    email,
    name,
    callForwarding = false,
    callForwardingTimeout = 0,
    phone = '',
  } = deserializeProfile(data);
  return { email, name, callForwarding, callForwardingTimeout, phone, avatarError: '' };
};

export const fileToBase64 = (file: File) =>
  new Promise<string>((res, rej) => {
    if (!file.type.startsWith('image/') || !/(jpe?g|png|webp)$/.test(file.name.toLowerCase())) {
      rej(Error('ERROR_AVATAR_FILE_FORMAT'));
      return;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      if (event.target && typeof event.target.result === 'string') {
        const isValid = await isBase64ImageValid(event.target.result).catch(() => false);

        if (!isValid) {
          rej(Error('ERROR_FILE_READING'));
          return;
        }

        res(event.target.result);
      } else {
        rej(Error('ERROR_FILE_READING'));
      }
    };

    reader.onerror = () => {
      rej(Error('ERROR_FILE_READING'));
    };

    reader.readAsDataURL(file);
  });
