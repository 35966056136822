import { normalizePhone } from '@shared/utils';
import { deserializeDepartment, IDepartment, IDepartmentRaw } from './department';
import { IDomain } from './domain';
import { deserializeUser, IUserRaw } from './user';
import { IOutgoingCall } from './outgoingCall';
import { ICallsRestriction } from './callRestriction';

export interface IEmployee {
  id: number;
  email?: string;
  name?: string;
  /** This should actually be Role enum, but components package
   *  does not have generated schema.
   *  To get actual role use `getRole` utility function */
  role?: string;
  isActive?: boolean;
  ext?: string;
  login?: string | null;
  position?: string;
  phone?: string;
  departments?: Partial<IDepartment>[];
  password?: string;
  sipPassword?: string;
  callForwarding?: boolean;
  callForwardingTimeout?: number;
  hasSipRegistration?: boolean;
  deleteAvatar?: boolean;
}

export interface IEmployeeRaw {
  id?: number;
  /** This should actually be Role enum, but components package
   *  does not have generated schema.
   *  To get actual role use `getRole` utility function */
  role: string;
  position: string;
  ext: string;
  login?: string;
  callForwarding: boolean;
  callForwardingTimeout: number;
  phone?: string;
  hasSipRegistration?: boolean;
  password?: string;
  sipPassword?: string;
  isActive?: boolean;
  domainId: number;
  domain?: IDomain;
  userId: number;
  user?: Partial<IUserRaw>;
  departments?: Partial<IDepartmentRaw>[];
  restrictions?: ICallsRestriction;
  callerId?: IOutgoingCall;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleteAvatar?: boolean;
}

export interface IRegistrationContact {
  node: string;
  transport: string;
  userAgent: string;
  contact: string;
  expires: number;
}

export const deserializeEmployee = (data: Partial<IEmployeeRaw>): IEmployee => {
  const {
    id = 0,
    position,
    role,
    ext,
    login,
    password,
    sipPassword,
    hasSipRegistration,
    callForwarding,
    callForwardingTimeout,
    phone,
    isActive,
    user,
    departments: departmentsData,
  } = data;
  const userData = deserializeUser(user);
  const departments: IDepartment[] = (departmentsData || []).map(deserializeDepartment);
  return {
    position: position || '',
    role,
    ext: ext || '',
    login,
    hasSipRegistration: hasSipRegistration || false,
    callForwarding,
    callForwardingTimeout,
    password,
    sipPassword,
    phone,
    departments,
    ...userData,
    isActive,
    id,
  };
};

export const serializeEmployee = ({
  id,
  position,
  role,
  ext,
  login,
  name,
  email,
  phone,
  password,
  sipPassword,
  callForwarding,
  callForwardingTimeout,
  isActive,
}: Partial<IEmployee> = {}) => {
  const timeout = Number.isFinite(Number(callForwardingTimeout))
    ? Number(callForwardingTimeout)
    : 0;
  return {
    id,
    position,
    role,
    ext,
    login,
    sipPassword,
    callForwarding,
    callForwardingTimeout: timeout,
    phone: phone !== undefined ? normalizePhone(phone) : undefined,
    user: {
      name,
      email,
      password,
      isActive,
    },
  };
};
