import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { ICellProps } from '@components/LazyTable';
import { IApologySMS, IApologySMSTriggerTime } from '../SettingsApologySMS.interface';

export const ApologySMSDispatchTime: FunctionComponent<ICellProps<IApologySMS>> = ({ item }) => {
  const { triggerTime } = item || {};
  const [translate] = useTranslation();
  const value: { [key: string]: string } = {
    Always: IApologySMSTriggerTime.Always,
    WorkTime: IApologySMSTriggerTime.WorkTime,
    NonWorkTime: IApologySMSTriggerTime.NonWorkTime,
  };

  if (!triggerTime) return null;

  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {translate(value[triggerTime])}
    </Typography>
  );
};
