import { IDay, IPeriod } from './Scheduler/Scheduler.interfaces';
import { getDays } from './Scheduler/Scheduler.constants';
import { SchedulePeriodsTypes, ServerSchedule } from './IncomingNumber.interfaces';
import { getDayFiller } from './Scheduler/Scheduler.helpers';

export const deserializeSchedule = (schedule: ServerSchedule): IDay[] =>
  Object.keys(schedule).reduce<IDay[]>((result, periodType) => {
    (schedule[periodType as SchedulePeriodsTypes] || []).forEach(({ start, end, days }) => {
      days.forEach((dayIndex) => {
        result[dayIndex] = getDayFiller(
          [
            {
              start: start + dayIndex * 24 * 60,
              end: end + dayIndex * 24 * 60,
            },
          ],
          periodType as SchedulePeriodsTypes
        )(result[dayIndex], dayIndex);
      });
    });
    return result;
  }, getDays());

export const serializeSchedule = (schedule: IDay[]): ServerSchedule =>
  // TODO: try to simplify IDays mapping directly to ServerSchedule or get rid of IPeriod entity
  schedule
    .reduce<(IPeriod & { type: SchedulePeriodsTypes })[][]>((result, day) => {
      result.push(
        day.hours.reduce<(IPeriod & { type: SchedulePeriodsTypes })[]>((res, hour, hourIndex) => {
          const definePeriods = (index: number) => {
            let nextStarted = index;
            const currentPeriodType = hour.minutes[index];
            const currentlyStarted = index;
            const nextPeriodIndex = hour.minutes.findIndex(
              (minute, minuteIndex) => minuteIndex > nextStarted && minute !== currentPeriodType
            );
            nextStarted = nextPeriodIndex !== -1 ? nextPeriodIndex : 60;
            const end = hourIndex * 60 + nextStarted - 1;
            if (res.length && res[res.length - 1].type === currentPeriodType) {
              res[res.length - 1] = { ...res[res.length - 1], end };
            } else {
              res.push({ start: hourIndex * 60 + currentlyStarted, end, type: currentPeriodType });
            }
            if (nextStarted < 60) definePeriods(nextStarted);
          };
          definePeriods(0);
          return res;
        }, [])
      );
      return result;
    }, [])
    .reduce<ServerSchedule>((result, dayPeriods, dayIndex) => {
      dayPeriods.forEach(({ start, end, type }) => {
        let groupFound;
        Object.keys(result).forEach((resultType) => {
          (result[resultType as SchedulePeriodsTypes] || []).forEach(
            ({ start: grouperPeriodStart, end: groupedPeriodEnd, days: groupedPeriodDays }) => {
              if (
                resultType === type &&
                grouperPeriodStart === start &&
                groupedPeriodEnd === end &&
                groupedPeriodDays[groupedPeriodDays.length - 1] === dayIndex - 1
              ) {
                groupedPeriodDays.push(dayIndex); // mutation;
                groupFound = true;
              }
            }
          );
        });
        if (!groupFound) {
          if (result[type]?.length) {
            result[type]?.push({ start, end, days: [dayIndex] }); // mutation;
          } else {
            result[type] = [{ start, end, days: [dayIndex] }]; // mutation;
          }
        }
      });
      return result;
    }, {});
