import Typography from './Typography';
import * as TypographyTypes from './Typography.interfaces';
import * as TypographyConstants from './Typography.constants';

export type TextColorsTypes = TypographyTypes.TextColorsTypes;
export type TextProps = TypographyTypes.TextProps;
export type TextTypes = TypographyTypes.TextTypes;
export const { TextColors } = TypographyConstants;
export const { TextSize } = TypographyConstants;
export default Typography;
