export default {
  fontFamily: 'Onest-Regular, sans-serif',
  fontWeight: 'normal',
  body1: {
    lineHeight: '1.2em',
    background: 'transparent',
  },
  body2: {
    fontSize: 16,
    lineHeight: 'inherit',
    overflow: 'hidden',
  },
};
