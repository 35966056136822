import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import Breadcrumbs from '@/components/Breadcrumbs';
import { GET_PAYMENTS_QUERY, USER_QUERY } from '@/client/queries';
import QuickSearch from '@components/QuickSearch';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import LazyTable, { IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { Button } from '@shared/components/Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import BalanceDialog from '@/components/BalanceDialog/BalanceDialog';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { CommercialDomainStatuses, DomainStatus } from '@components/typings/interfaces';
import { IPayment } from './Payments.interfaces';
import { usePaymentsStyle } from './Payments.styles';
import { PaymentDateCell, PaymentNameCell, PaymentSumCell, PaymentActionsCell } from './modules';
import { goToDocumentsIfCurrentDomainStatusIsDemo } from '../utils/goToDocumentsIfCurrentDomainStatusIsDemo';

export const paymentsColumns: IColumn<IPayment>[] = [
  { id: 'name', verticalAlign: 'center', label: 'ACCOUNT', Renderer: PaymentNameCell },
  { id: 'type', verticalAlign: 'center', label: 'ISSUE_DATE', Renderer: PaymentDateCell },
  {
    id: 'date',
    verticalAlign: 'center',
    align: 'right',
    label: 'SUMMARY',
    Renderer: PaymentSumCell,
  },
  {
    id: 'actions',
    minWidth: '3em',
    verticalAlign: 'center',
    label: '',
    align: 'right',
    Renderer: PaymentActionsCell,
  },
];

export const Payments = () => {
  const classes = usePaymentsStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sQuery = searchParams.get('query') || '';
  const { data: paymentsData, loading, refetch } = useQuery(GET_PAYMENTS_QUERY);
  const [isCreateAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { status = DomainStatus.Unknown } = getCurrentDomain(userData?.user) || {};
  const isDomainCommercial = CommercialDomainStatuses.indexOf(status) >= 0;

  const formMethods = useForm({
    defaultValues: {
      query: sQuery,
    },
  });

  const tableData = useMemo(() => {
    const querySum = sQuery.replace(' ', '').replace(',', '.');
    return (
      paymentsData?.getPayments.filter((payment: IPayment) => {
        const paymentDate = format(new Date(payment.createdAt), 'dd.MM.yyyy');
        return (
          !sQuery ||
          String(payment.id).includes(sQuery) ||
          payment.sum.toFixed(2).includes(querySum) ||
          String(paymentDate).includes(sQuery)
        );
      }) || []
    );
  }, [paymentsData?.getPayments, sQuery]);

  useEffect(() => {
    goToDocumentsIfCurrentDomainStatusIsDemo(navigate, userData);
  }, [navigate, userData]);

  function handleCloseBalanceDialog() {
    refetch().then();
    setCreateAccountDialogOpen(false);
  }

  const renderGenerateInvoiceMessage = () => (
    <BalanceDialog
      isFillBalanceDialogOpen={isCreateAccountDialogOpen}
      onCloseBalanceDialog={handleCloseBalanceDialog}
    />
  );

  return (
    <FormProvider {...formMethods}>
      <BodyContainer disableOverflow>
        <Flex direction={'column'} className={classes.root}>
          <Breadcrumbs>
            <QuickSearch parameterName={'query'} />
            <Button
              title={translate('GENERATE_INVOICE')}
              className={classes.action}
              onClick={() => setCreateAccountDialogOpen(true)}
              disabled={!isDomainCommercial}
            />
          </Breadcrumbs>
          <LazyTable<IPayment>
            columns={paymentsColumns}
            data={tableData}
            tableLoading={loading}
            emptyDataMessage={translate(!loading ? 'NOTHING_FOUND' : '')}
          />
        </Flex>
        {renderGenerateInvoiceMessage()}
      </BodyContainer>
    </FormProvider>
  );
};
