import React, { useMemo, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_CALL_STATISTICS_QUERY } from '@/client/queries';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { DoughnutDiagram } from '@components/Diagram';
import { useTheme } from '@material-ui/core/styles';
import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import ButtonGroupSwitch from '@components/ButtonGroupSwitch';
import clsx from 'clsx';
import Preloader from '@shared/components/Preloader';
import { InteractionItem } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { getDateList } from '@/utils';
import PageError from '@shared/components/PageError';
import { CallStatisticsPeriod, DashboardCallStatisticsModel } from '@/client/generated/graphql';
import { useDesktopPageStyle } from '../DesktopPage.styles';
import { callStatisticsList, callStatisticsPeriodList } from '../DesktopPage.constants';
import { desktopPageSize, IDesktopWidgetProps } from '../DesktopPage.interfaces';

export const CallStatisticsSectionWidget = ({ pageSize }: IDesktopWidgetProps) => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();
  const theme: IThemeColorOptions = useTheme();
  const navigate = useNavigate();
  const {
    data: dashboardCallStatistics,
    refetch: refetchCallStatistics,
    loading,
    error: { graphQLErrors: getCallStatisticsErrors = null } = {},
  } = useQuery(GET_DASHBOARD_CALL_STATISTICS_QUERY, {
    variables: { period: CallStatisticsPeriod.Today },
  });
  const callStatistics = dashboardCallStatistics?.dashboardCallStatistics;
  const {
    outSuccess = 0,
    outNotSuccess = 0,
    inSuccess = 0,
    inNotSuccess = 0,
  } = callStatistics || {
    outSuccess: 0,
    outNotSuccess: 0,
    inSuccess: 0,
    inNotSuccess: 0,
  };
  const outTotal = outSuccess + outNotSuccess;
  const inTotal = inSuccess + inNotSuccess;
  const sumValues = callStatistics ? outTotal + inTotal : 0;
  const [statisticsPeriod, setStatisticsPeriod] = useState<CallStatisticsPeriod>(
    CallStatisticsPeriod.Today
  );

  const buttonGroupData = useMemo(
    () => callStatisticsPeriodList.map((item) => ({ ...item, title: translate(item.title) })),
    [translate]
  );

  const doughnutDiagramOptions = useMemo(() => {
    const setCotOut = () => {
      if (pageSize === desktopPageSize.Min) return 50;
      if (pageSize === desktopPageSize.Normal) return 55;
      return 60;
    };

    return {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      aspectRatio: 1,
      maintainAspectRatio: false,
      cutout: setCotOut(),
    };
  }, [pageSize]);

  const callStatisticsDiagramData = useMemo(() => {
    const { success, links, danger, tertiary, warning } = theme?.color || {};
    const emptyStatistics = sumValues === 0 ? 100 : 0;
    return {
      labels: [
        translate('OUTGOING'),
        translate('OUTGOING_OUT'),
        translate('INCOMING'),
        translate('INCOMING_OUT'),
      ],
      datasets: [
        {
          data: [outSuccess, outNotSuccess, inSuccess, inNotSuccess, emptyStatistics],
          backgroundColor: [success[600], warning[600], links[600], danger[600], tertiary[200]],
          borderWidth: 0,
        },
      ],
    };
  }, [inNotSuccess, inSuccess, outNotSuccess, outSuccess, sumValues, theme?.color, translate]);

  const handlePointClick = useCallback(
    (element: InteractionItem[]) => {
      if (element[0]) {
        let historyPath: string;
        const { currentWeek, endWeek, startMonth, endMonth } = getDateList();
        switch (statisticsPeriod) {
          case CallStatisticsPeriod.ThisWeek:
            historyPath = `/history/?from=${currentWeek.toISOString()}&to=${endWeek.toISOString()}&period=custom`;
            break;
          case CallStatisticsPeriod.ThisMonth:
            historyPath = `/history/?from=${startMonth.toISOString()}&to=${endMonth.toISOString()}&period=currentMonth`;
            break;
          case CallStatisticsPeriod.Today:
          default:
            historyPath = '/history/?period=today';
            break;
        }
        switch (element[0].index) {
          case 0:
            historyPath += '&direction=Out';
            break;
          case 1:
            historyPath += '&direction=Out&missed=true';
            break;
          case 2:
            historyPath += '&direction=In';
            break;
          case 3:
            historyPath += '&direction=In&missed=true';
            break;
          default:
        }
        setTimeout(() => {
          navigate(historyPath);
        });
      }
    },
    [navigate, statisticsPeriod]
  );

  function handleButtonGroupSwitchClick(buttonPeriodValue: string | number) {
    setStatisticsPeriod(buttonPeriodValue as CallStatisticsPeriod);
    refetchCallStatistics({
      period: buttonPeriodValue as CallStatisticsPeriod,
    }).then();
  }

  const renderStatisticsAmount = () => (
    <>
      <Typography type={'text4'}>{translate('FILTER_ALL')}</Typography>
      <Typography type={'text3'} color={'tertiary900'}>
        {sumValues}
      </Typography>
    </>
  );

  const renderCallStatisticsInformationList = () =>
    callStatisticsList.map((statisticItem, index) => {
      if (!callStatistics) {
        return null;
      }
      const callAmount = () => {
        if (statisticItem.key === 'outSuccess') return outTotal;
        if (statisticItem.key === 'inSuccess') return inTotal;
        return callStatistics[statisticItem.key as keyof DashboardCallStatisticsModel];
      };
      return (
        <Flex
          key={`statistics-item-${index}`}
          direction={'column'}
          className={clsx(classes.desktopCallStatisticsInfoText, {
            [classes.desktopAfterOutgoingColor]: statisticItem.key === 'outSuccess',
            [classes.desktopAfterOutgoingMissedColor]: statisticItem.key === 'outNotSuccess',
            [classes.desktopAfterIncomingColor]: statisticItem.key === 'inSuccess',
            [classes.desktopAfterIncomingMissedColor]: statisticItem.key === 'inNotSuccess',
          })}
        >
          <Typography type={'text4'} color={'tertiary700'}>
            {translate(statisticItem.title)}
          </Typography>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {callAmount()}
          </Typography>
        </Flex>
      );
    });

  if (loading && !dashboardCallStatistics) {
    return (
      <Flex justifyContent={'center'} alignItems={'center'} className={classes.desktopSection}>
        <Preloader size={'large'} />
      </Flex>
    );
  }

  if (getCallStatisticsErrors) {
    return (
      <div className={classes.desktopSection}>
        <PageError />
      </div>
    );
  }

  return (
    <div className={classes.desktopSection}>
      <Flex
        className={classes.desktopSectionHeader}
        justifyContent={'spaceBetween'}
        alignItems={'flexStart'}
      >
        <Typography type={'text2'} color={'tertiary900'} bold>
          {translate('CALLS_STATISTICS')}
        </Typography>
        <Flex>
          <ButtonGroupSwitch
            data={buttonGroupData}
            onClick={handleButtonGroupSwitchClick}
            defaultValue={statisticsPeriod}
          />
        </Flex>
      </Flex>
      <Flex justifyContent={'spaceBetween'}>
        <div className={classes.desktopCallStatisticsDoughnut}>
          <DoughnutDiagram
            data={callStatisticsDiagramData}
            options={doughnutDiagramOptions}
            onClick={handlePointClick}
          />
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
            className={classes.desktopCallStatisticsCenterInfo}
          >
            {renderStatisticsAmount()}
          </Flex>
        </div>
        <Flex className={classes.desktopCallStatisticsContainer} justifyContent={'spaceBetween'}>
          {renderCallStatisticsInformationList()}
        </Flex>
      </Flex>
    </div>
  );
};

export default memo(CallStatisticsSectionWidget);
