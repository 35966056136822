import React, { FunctionComponent, useCallback, useState } from 'react';
import Drawer from '@components/Drawer';
import { IIncomingNumberScenario } from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import { numberSettingsTitleCodes, settingTypeCurrentList } from '../IncomingNumber.constants';
import MessageScenario from './MessageScenarios/MessageScenario';
import RedirectScenarios from './RedirectScenarios/RedirectScenarios';
import VoiceMailFaxScenario from './VoiceMailFaxScenario/VoiceMailFaxScenario';
import VoiceMenuScenario from './VoiceMenuScenario/VoiceMenuScenario';
import SmartGreeting from './SmartGreeting/SmartGreeting';
import { ScenariosTypes, SchedulePeriodsTypes } from '../IncomingNumber.interfaces';

export interface IIncomingNumberScenariosProps {
  settingType: ScenariosTypes;
  nodeId?: string;
  parentId?: string;
  periodKey?: SchedulePeriodsTypes;
  settings: { [key: string]: IIncomingNumberScenario | undefined };
  onChange: (params: { parentId?: string; nodeId: string; node: IIncomingNumberScenario }) => void;
  onAdd: (params: {
    parentId?: string;
    periodKey?: SchedulePeriodsTypes;
    node: IIncomingNumberScenario;
  }) => void;
  onRemove: (params: {
    parentId?: string;
    periodKey?: SchedulePeriodsTypes;
    nodeId: string;
  }) => void;
  onClose: () => void;
}

export const IncomingNumberScenarios: FunctionComponent<IIncomingNumberScenariosProps> = ({
  settingType,
  nodeId,
  settings,
  onAdd,
  periodKey,
  parentId,
  onChange,
  onClose,
  onRemove,
}: IIncomingNumberScenariosProps) => {
  const [isChanged, setIsChanged] = useState(false);
  const [settingTypeCurrent, setSettingTypeCurrent] = useState<ScenariosTypes>(settingType);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

  const handleChangeSettings = useCallback(
    (node: IIncomingNumberScenario) => {
      if (nodeId) {
        onChange({ parentId, nodeId, node: { ...node } });
      } else {
        onAdd({ parentId, periodKey, node: { ...node } });
      }
    },
    [nodeId, onAdd, onChange, parentId, periodKey]
  );

  const handleChangeChildrenSettings = useCallback(
    (node: IIncomingNumberScenario) => {
      onChange({ parentId: nodeId, nodeId: node.id as string, node });
    },
    [nodeId, onChange]
  );

  const handleChanges = useCallback((newType?: ScenariosTypes) => {
    setIsChanged(true);
    if (newType) {
      setSettingTypeCurrent(newType);
    }
  }, []);

  const handleAddChildrenSettings = useCallback(
    (node: IIncomingNumberScenario) => {
      onAdd({ parentId: nodeId, node });
    },
    [nodeId, onAdd]
  );

  const removeChildRecursively = useCallback(
    (childId: string, id: string) => {
      const children = settings?.[childId]?.children;
      if (children && children.length) {
        children.forEach(removeChildRecursively);
      }
      onRemove({ parentId: id, nodeId: childId });
    },
    [onRemove, settings]
  );

  const handleRemoveSettings = useCallback(() => {
    if (nodeId) {
      const children = settings?.[nodeId]?.children;
      if (children && children.length) {
        children.forEach(removeChildRecursively);
      }
      onRemove({ parentId, periodKey, nodeId });
    }
  }, [nodeId, onRemove, parentId, periodKey, removeChildRecursively, settings]);

  const getRenderSettingsObject = useCallback(() => {
    const setting =
      nodeId && settings?.[nodeId]
        ? (settings?.[nodeId] as IIncomingNumberScenario)
        : {
            id: nodeId,
            type: settingTypeCurrent,
            children: [],
          };

    switch (settingTypeCurrent) {
      case ScenariosTypes.Message: {
        return {
          Element: MessageScenario,
          formId: 'edit-text-or-audio',
          settings: setting,
          onSave: handleChangeSettings,
          onRemove: handleRemoveSettings,
          onCancel: onClose,
          onChanges: handleChanges,
          setIsSaveButtonLoading,
        };
      }
      case ScenariosTypes.SmartGreeting: {
        return {
          Element: SmartGreeting,
          formId: 'smart-greeting',
          settings: setting,
          onSave: handleChangeSettings,
          onRemove: handleRemoveSettings,
          onCancel: onClose,
          onChanges: handleChanges,
        };
      }
      case ScenariosTypes.VoiceMenu: {
        return {
          Element: VoiceMenuScenario,
          formId: 'voice-menu-scenario',
          allSettings: settings,
          settings: setting,
          onSave: handleChangeSettings,
          onRemove: handleRemoveSettings,
          onCancel: onClose,
          onRemoveChild: removeChildRecursively,
          onAddChild: handleAddChildrenSettings,
          onChangeChild: handleChangeChildrenSettings,
          onChanges: handleChanges,
          setIsSaveButtonLoading,
        };
      }
      case ScenariosTypes.RedirectToNumber:
      case ScenariosTypes.RedirectToEmployee:
      case ScenariosTypes.RedirectToDepartment: {
        return {
          Element: RedirectScenarios,
          formId: 'edit-redirect-settings',
          initial: settingTypeCurrent,
          settings: setting,
          onSave: handleChangeSettings,
          onRemove: handleRemoveSettings,
          onCancel: onClose,
          onChanges: handleChanges,
          title: settingTypeCurrentList[settingTypeCurrent],
          setIsSaveButtonLoading,
        };
      }
      case ScenariosTypes.Voicemail: {
        return {
          Element: VoiceMailFaxScenario,
          formId: 'edit-text-or-audio',
          scenario: setting,
          onSave: handleChangeSettings,
          onRemove: handleRemoveSettings,
          onCancel: onClose,
          onChanges: handleChanges,
          setIsSaveButtonLoading,
          scenarioType: ScenariosTypes.Voicemail,
          maxEmails: 20,
        };
      }
      case ScenariosTypes.Fax: {
        return {
          Element: VoiceMailFaxScenario,
          formId: 'edit-text-or-audio',
          scenario: setting,
          onSave: handleChangeSettings,
          onRemove: handleRemoveSettings,
          onCancel: onClose,
          onChanges: handleChanges,
          setIsSaveButtonLoading,
          scenarioType: ScenariosTypes.Fax,
          maxEmails: 5,
        };
      }
      default:
        return undefined;
    }
  }, [
    handleAddChildrenSettings,
    handleChangeChildrenSettings,
    handleChangeSettings,
    handleRemoveSettings,
    nodeId,
    onClose,
    removeChildRecursively,
    settingTypeCurrent,
    settings,
  ]);

  const renderSettingsObject = getRenderSettingsObject();

  if (!renderSettingsObject) return null;

  const {
    onCancel: onCancelFromSettings,
    onRemove: onRemoveFromSettings,
    formId,
    Element,
    title,
    ...ElementProps
  } = renderSettingsObject;

  return (
    <Drawer
      title={title ?? numberSettingsTitleCodes.get(settingType) ?? 'VOICE_MENU'}
      ModalProps={{ disableBackdropClick: isChanged }}
      elevation={4}
      size={'lg'}
      open
      primaryButton={{
        title: 'SAVE',
        props: {
          type: 'submit',
          form: formId,
          loading: isSaveButtonLoading,
        },
      }}
      secondaryButton={{
        title: 'CANCEL',
        onClick: onCancelFromSettings,
      }}
      tertiaryButton={{
        title: 'REMOVE',
        onClick: () => {
          onCancelFromSettings();
          onRemoveFromSettings();
        },
      }}
      onClose={onClose}
    >
      {
        <Element
          onCancel={onCancelFromSettings}
          onRemove={onRemoveFromSettings}
          {...ElementProps}
        />
      }
    </Drawer>
  );
};

export default IncomingNumberScenarios;
