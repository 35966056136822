import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  comparedEmployeeListType,
  comparedEmployeeObjectValue,
  rawDataListType,
  scenarioDirectionEnum,
  scenarioSettingsType,
} from '../../IntegrationForm.interfaces';
import {
  incomingResponsibleOptions,
  outgoingResponsibleOptions,
} from '../../IntegrationForm.constants';

export function useBitrixScenarioFormPropsHooks(
  direction: scenarioDirectionEnum,
  edit: scenarioSettingsType | undefined,
  comparedEmployee: comparedEmployeeListType | null,
  rawEmployee: rawDataListType | null
) {
  const [translate] = useTranslation();

  const isCommonSettings = useMemo(() => direction === scenarioDirectionEnum.Common, [direction]);

  const comparedEmployeeList = useMemo(() => {
    if (comparedEmployee) {
      return Object.values(comparedEmployee).reduce(
        (
          res: Array<{ key: string; value: string }>,
          emp: comparedEmployeeObjectValue | Array<string>
        ) => {
          if (Array.isArray(emp)) return res;
          return [...res, { key: emp.crmId, value: emp.name }];
        },
        []
      );
    }
    return [];
  }, [comparedEmployee]);

  const rawEmployeeList = useMemo(
    () => (rawEmployee ? rawEmployee.map((i) => ({ key: String(i.id), value: i.name })) : []),
    [rawEmployee]
  );
  const responsibleDefaultList = useMemo(() => {
    if (direction === scenarioDirectionEnum.Incoming) {
      return incomingResponsibleOptions;
    }
    return outgoingResponsibleOptions;
  }, [direction]);

  const leadTitle = useMemo(() => {
    switch (edit) {
      case scenarioSettingsType.OutgoingKnown:
      case scenarioSettingsType.OutgoingMissedKnown: {
        return translate('CREATE_REPEAT_LEAD');
      }
      case scenarioSettingsType.IncomingKnown:
      case scenarioSettingsType.IncomingMissedKnown: {
        return translate('CREATE_LEAD');
      }
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingMissedUnknown:
      case scenarioSettingsType.OutgoingMissedUnknown:
      case scenarioSettingsType.OutgoingUnknown: {
        return translate('CREATE_NEW_LEAD');
      }
      case scenarioSettingsType.CommonCallHistory: {
        return translate('SAVE_FROM_RESPONSIBLE_MANAGER');
      }
      default: {
        return '';
      }
    }
  }, [edit, translate]);

  const leadTitleDescription = useMemo(() => {
    const crmName = translate('Bitrix24');
    switch (edit) {
      case scenarioSettingsType.IncomingKnown:
      case scenarioSettingsType.IncomingMissedKnown:
      case scenarioSettingsType.OutgoingKnown:
      case scenarioSettingsType.OutgoingMissedKnown: {
        return translate('INCOMING_KNOWN_LEAD');
      }
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingMissedUnknown: {
        return translate('INCOMING_UNKNOWN_LEAD');
      }
      case scenarioSettingsType.OutgoingUnknown:
      case scenarioSettingsType.OutgoingMissedUnknown: {
        return translate('INCOMING_UNKNOWN_NEW_CONTACT_LEAD');
      }
      case scenarioSettingsType.CommonCallHistory: {
        return translate('COMMON_CALLS_HISTORY', { crmName });
      }
      default: {
        return '';
      }
    }
  }, [edit, translate]);

  return {
    leadTitle,
    leadTitleDescription,
    isCommonSettings,
    comparedEmployeeList,
    rawEmployeeList,
    responsibleDefaultList,
  };
}
