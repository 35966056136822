import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import { NumberStatus } from '@components/typings/interfaces';
import { useMutation, useQuery } from '@apollo/client';
import {
  ABORT_REFUSE_NUMBER_MUTATION,
  BUY_NUMBER_MUTATION,
  REFUSE_NUMBER_MUTATION,
  REMOVE_RESERVED_NUMBER_MUTATION,
} from '@/client/mutations';
import { CAN_BOUGHT_NUMBER_QUERY, DOMAIN_ALL_NUMBERS_QUERY } from '@/client/queries';
import MessageDialog from '@shared/components/MessageDialog';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import DialogPayForNumber from '../DialogPayForNumber/DialogPayForNumber';
import { useNumbersStyle } from '../Numbers.styles';
import { DomainNumber, OpenModalType } from '../Numbers.interfaces';

export const NumberActionsCell = ({ item }: ICellProps<DomainNumber>) => {
  const classes = useNumbersStyle();
  const [translate] = useTranslation();
  const [modal, setModal] = useState<OpenModalType>(OpenModalType.Off);
  const [open, setOpen] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState({
    showDialog: false,
    title: '',
    message: '',
  });

  const options = useMemo(
    () => ({
      refetchQueries: [{ query: DOMAIN_ALL_NUMBERS_QUERY }],
    }),
    []
  );

  const [refuseBoughtNumber, { loading: loadingRefuseBoughtNumber }] = useMutation(
    REFUSE_NUMBER_MUTATION,
    options
  );
  const [abortRefuseNumber, { loading: loadingAbortRefuseNumber }] = useMutation(
    ABORT_REFUSE_NUMBER_MUTATION,
    options
  );
  const [removeReservedNumber, { loading: loadingRemoveReservedNumber }] = useMutation(
    REMOVE_RESERVED_NUMBER_MUTATION,
    options
  );
  const [buyNumber, { loading: loadingBuyNumber }] = useMutation(BUY_NUMBER_MUTATION, options);

  const {
    data: canBoughtNumber,
    loading: loadingCanBoughtNumber,
    error: errorCanBoughtNumber,
  } = useQuery(CAN_BOUGHT_NUMBER_QUERY, {
    variables: {
      domainNumberId: Number(item?.id),
    },
    onCompleted: () => {
      setModal(OpenModalType.Bought);
    },
    onError: () => {
      setShowErrorDialog({
        showDialog: true,
        title: 'SOMETHING_WENT_WRONG',
        message: 'SOMETHING_WENT_WRONG',
      });
    },
    skip: !open,
    fetchPolicy: 'cache-and-network',
  });
  const boughtNumberData = canBoughtNumber?.canBoughtNumber;

  useEffect(() => {
    if (errorCanBoughtNumber) {
      setOpen(true);
    }
  }, [errorCanBoughtNumber]);

  if (!item || item.__typename === 'FmcNumberModel') {
    return <div />;
  }

  function handleItemClick() {
    setOpen(true);
  }

  const getOptionsRequestVariables = () => ({
    variables: {
      domainNumberId: item?.id,
    },
  });
  const getOptionsRequestBoughtVariables = () => ({
    variables: {
      catalogNumberId: item.numbersCatalogId,
    },
  });

  const handleRefuseNumber = async () => {
    switch (item.numberStatus) {
      case NumberStatus.Bought:
        await refuseBoughtNumber(getOptionsRequestVariables());
        break;
      case NumberStatus.Reserved:
        await removeReservedNumber(getOptionsRequestVariables());
        break;
      default:
    }
  };

  // оформление номера
  const handleConfirmBoughtNumber = () => {
    buyNumber(getOptionsRequestBoughtVariables())
      .then(() => {
        setShowErrorDialog({
          showDialog: true,
          title: 'SUCCESSFUL_PURCHASE',
          message: 'NUMBER_SUCCESSFUL_PURCHASE',
        });
      })
      .catch((error) => {
        let errorTitle = 'SOMETHING_WENT_WRONG';
        let errorMessage = 'SOMETHING_WENT_WRONG';
        const messageFromError = getErrorMessageFromGraphqlError(error);

        if (messageFromError === 'Balance too low.') {
          errorTitle = 'ERROR';
          errorMessage = 'NUMBER_LOW_BALANCE_ERROR';
        }

        setShowErrorDialog({
          showDialog: true,
          title: errorTitle,
          message: errorMessage,
        });
      });
  };

  const handleUnDisabled = async () => {
    await abortRefuseNumber({
      variables: {
        domainNumberId: item?.id,
      },
    });
  };

  const handleCloseModal = () => {
    setModal(OpenModalType.Off);
    if (open) setOpen(false);
  };

  const handleOpenModal = (typeModal: OpenModalType) => () => {
    setModal(typeModal);
  };

  const handleCloseErrorDialog = () => {
    setShowErrorDialog({
      showDialog: false,
      title: '',
      message: '',
    });
  };
  const { numberStatus } = item;

  const renderButtonsGroup = () => {
    const renderRemoveButton = () => {
      const getModalTexts = () => {
        switch (numberStatus) {
          case NumberStatus.Reserved:
            return {
              body: translate('NUMBER_REMOVE_RESERVED'),
            };
          case NumberStatus.Bought:
            return {
              body: translate('NUMBER_OFF_CHANGE'),
            };
          default:
            return {
              body: '',
            };
        }
      };

      const texts = getModalTexts();

      const renderDialogContent = () => (
        <>
          <div className={classes.numberActionsDialogContent}>
            <Typography type={'text3'} color={'tertiary900'}>{`${texts.body}`}</Typography>
          </div>
          <div className={classes.numberActionsDialogActions}>
            <ControlButtons
              confirmColor={'error'}
              confirmTitle={'CONFIRM'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              flexDirection={'row-reverse'}
              justifyContent={'start'}
              onConfirmClick={handleRefuseNumber}
              onCancelClick={handleCloseModal}
              loading={loadingRefuseBoughtNumber || loadingRemoveReservedNumber}
              small
            />
          </div>
        </>
      );

      return (
        <>
          <Button
            className={classes.numberActionsActionButton}
            variant={'secondary'}
            color={'error'}
            onClick={handleOpenModal(OpenModalType.RefuseBought)}
          >
            <TrashIcon />
          </Button>
          <MessageDialog
            isOpen={modal === OpenModalType.RefuseBought}
            title={translate('ARE_YOU_SURE')}
            contentClass={classes.numberActionsDialogPaper}
            onCancel={handleCloseModal}
            renderContent={renderDialogContent()}
          />
        </>
      );
    };

    const renderBoughtButton = () => (
      <>
        <Button
          title={translate('NUMBER_BOUGHT_BUTTON')}
          className={classes.numberActionsActionButtonText}
          variant={'secondary'}
          onClick={handleItemClick}
          loading={loadingCanBoughtNumber}
        />
        {!loadingCanBoughtNumber && (
          <DialogPayForNumber
            balance={boughtNumberData?.balance || 0}
            totalNumberCost={boughtNumberData?.totalNumberCost || 0}
            onConfirm={handleConfirmBoughtNumber}
            onCancel={handleCloseModal}
            isOpen={modal === OpenModalType.Bought}
            loading={loadingBuyNumber}
          />
        )}
      </>
    );

    const renderContinueButton = () => {
      const renderDialogContent = () => (
        <>
          <div className={classes.numberActionsDialogContent}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('DELETED_NUMBER')}
            </Typography>
          </div>
          <div className={classes.numberActionsDialogActions}>
            <ControlButtons
              confirmColor={'error'}
              confirmTitle={'CONFIRM'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              flexDirection={'row-reverse'}
              justifyContent={'start'}
              onConfirmClick={handleUnDisabled}
              onCancelClick={handleCloseModal}
              loading={loadingAbortRefuseNumber}
              small
            />
          </div>
        </>
      );

      return (
        <>
          <Button
            title={translate('NUMBER_UNRESERVED_BUTTON')}
            className={classes.numberActionsActionButtonText}
            variant={'secondary'}
            onClick={handleOpenModal(OpenModalType.AbortRefuse)}
          />
          <MessageDialog
            isOpen={modal === OpenModalType.AbortRefuse}
            title={translate('ARE_YOU_SURE')}
            contentClass={classes.numberActionsDialogPaper}
            onCancel={handleCloseModal}
            renderContent={renderDialogContent()}
          />
        </>
      );
    };

    switch (numberStatus) {
      case NumberStatus.Reserved:
        return (
          <>
            {renderRemoveButton()}
            {renderBoughtButton()}
          </>
        );
      case NumberStatus.Bought:
        if (item.statusTill) {
          return renderContinueButton();
        }
        return renderRemoveButton();
      default:
        return null;
    }
  };

  const renderErrorDialog = () => (
    <MessageDialog
      isOpen={showErrorDialog.showDialog}
      title={translate(showErrorDialog.title)}
      message={translate(showErrorDialog.message)}
      onCancel={handleCloseErrorDialog}
      renderControls={
        <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
      }
    />
  );

  return (
    <>
      <div className={classes.numberActionsContent}>{renderButtonsGroup()}</div>
      {showErrorDialog.showDialog && renderErrorDialog()}
    </>
  );
};
