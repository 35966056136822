import { makeStyles } from '@material-ui/core';
import styleConstants from '@components/styles';

export const ProfileStyles = makeStyles(
  ({ color: { tertiary, base, danger } }) => ({
    root: {
      flexGrow: 1,
      overflow: 'auto',
    },
    content: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      background: base,
      maxWidth: '80em',
    },
    profileUserData: {
      width: '100%',
      borderRadius: '12px',
      border: `1px solid ${tertiary[300]}`,
      padding: '1.75em 1.5em 1.5em',
    },
    button: {
      width: '100%',
      height: '2.5em',
      margin: '0 0 1em',
    },
    form: {
      display: 'flex',
      width: '75em',
      flexDirection: 'column',
      lineHeight: 'normal',
      padding: '0 2em 2em',
      position: 'relative',
    },
    profileSmallCardTitle: {
      marginLeft: '0.5em',
    },
    profileCardPhone: {
      marginBottom: '0.75em',
    },
    profileEditIcon: {
      marginLeft: '0.5em',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    profileCommonInfoLeft: {
      width: '50%',
    },
    profileInfo: {
      lineHeight: 2.5,
      width: '20em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    profileInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '2em',
    },
    profileNameRow: {
      height: '2.5em',
    },
    profileNameEditField: {
      width: '16em',
    },
    profileName: {
      maxWidth: '17em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    profileUserDepartmentBg: {
      marginTop: '0.5em',
      borderRadius: '0.25em',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: tertiary[50],
      padding: '0.5em 1em',
      scrollbarWidth: 'thin',
      height: '6em',
      width: '15em',
    },
    profileUserDepartment: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderBottom: `1px solid ${tertiary[200]}`,
      padding: '0.625em 0',
      '&:last-of-type': {
        borderBottom: 'none',
      },
      '&:first-child': {
        borderBottom: `1px solid ${tertiary[200]}`,
      },
    },
    profileCard: {
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '12px',
      height: '20em',
      width: '23em',
      marginTop: '1em',
      padding: '1.625em 1.5em 1.5em',
    },
    profileNotificationsBg: {
      backgroundColor: tertiary[100],
      padding: '0.625em 1em',
      borderRadius: '4px',
      marginTop: '0.5em',
    },
    profileCardHeader: {
      marginBottom: '1.25em',
    },
    profileCommonInfo: {
      marginBottom: '2em',
    },
    actions: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > :not(:first-child)': {
        marginLeft: '1em',
      },
    },
    confirmButton: {
      width: '14em',
      height: '3em',
    },
    confirmAvatarEditButton: {
      width: '12em',
    },
    avatarEditButtons: {
      width: '100%',
      paddingTop: '1.5em',
    },
    profileAvatar: {
      width: '5em',
      height: '5em',
    },
    profileEmptyAvatar: {
      width: '4em',
      height: '4em',
      backgroundColor: `${danger[500]}`,
    },
    editorAvatar: {
      width: '12em',
      height: '12em',
    },
    avatarControlsWrapper: {
      width: '100%',
      marginBottom: '1em',
    },
    editAvatarBg: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '12em',
      height: '12em',
      backgroundColor: tertiary[300],
      borderRadius: '50%',
      marginBottom: '2em',
      transition: '0.3s ease',
      '&:hover': {
        backgroundColor: tertiary[200],
        cursor: 'pointer',
      },
    },
    avatarControl: {
      padding: '0.5em',
      margin: '0 1.5em',
      justifyContent: 'center',
    },
    editAvatarCameraIcon: {
      position: 'absolute',
      width: '3em',
      height: '3em',
      color: tertiary[500],
    },
    editAvatarText: {
      position: 'absolute',
      top: '8.5em',
    },
    editAvatarInput: {
      width: '100%',
      height: '100%',
      opacity: 0,
      position: 'absolute',
      borderRadius: '50%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    avatarEditor: {
      width: '20em',
      height: '12em',
    },
    delimiter: {
      borderLeft: `1px solid ${tertiary[300]}`,
      paddingLeft: '2.75em',
    },
    avatarWrapper: {
      position: 'relative',
      marginRight: '2em',
      '&:hover': {
        opacity: '0.8',
        cursor: 'pointer',
      },
    },
    avatarScale: {
      marginBottom: '1.5em',
      width: '90%',
    },
    avatarCameraIcon: {
      color: 'black',
      margin: 'auto',
    },
    avatarCameraIconBg: {
      width: '1.625em',
      height: '1.625em',
      borderRadius: '50%',
      backgroundColor: 'white',
      position: 'absolute',
      bottom: '0.25em',
      right: '0.25em',
    },
    confirmPopupText: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
