import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useEmployeeStyles = makeStyles(
  ({ color: { tertiary, danger, secondary } }) => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      background: '#fff',
      flexDirection: 'column',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      paddingLeft: '2em',
      paddingRight: '1em',
      overflow: 'auto',
    },
    formContent: {
      width: '23em',
      paddingBottom: '2em',
      '& > *': {
        marginTop: '2em',
        '&:first-child': {
          marginTop: '1em',
        },
      },
    },
    contentBottomSpace: {
      bottom: '5em',
    },
    itemWrapper: {
      height: '4.25em',
    },
    formContentWrapper: {
      margin: '1.5em 0 0',
    },
    title: {
      display: 'flex',
      paddingBottom: '1em',
    },
    actions: {
      display: 'flex',
      width: '21.5em',
      padding: '2em 0 0',
      marginBottom: '2em',
      '& > :not(:first-child)': {
        marginLeft: '1em',
      },
    },
    controlButton: {
      width: '11.5em',
      height: '2.5em',
    },
    tooltipContent: {
      width: '18.5em',
      display: 'flex',
      marginLeft: '-0.375em',
      paddingLeft: '0.375em',
      flexDirection: 'column',
      '& > span': {
        whiteSpace: 'normal',
      },
    },
    tooltipLink: {
      cursor: 'pointer',
    },
    balloonCard: {
      backgroundColor: tertiary[50],
      width: 'auto',
      height: 'auto',
      maxWidth: '23em',
      minWidth: '10.75em',
      borderRadius: '6px',
      border: `1px solid ${tertiary[100]}`,
      padding: '0.75em 0.5em',
      position: 'relative',
      marginTop: '1em',
      marginBottom: '0.3125em',
      overflow: 'visible',
      '&::before': {
        top: '-0.5em',
        left: '2.375em',
        transform: 'rotate(45deg)',
        width: '0.875em',
        height: '0.875em',
        content: '""',
        border: `1px solid ${tertiary[100]}`,
        background: tertiary[50],
        position: 'absolute',
        borderRight: 'none',
        borderBottom: 'none',
      },
    },
    registrationCard: {
      backgroundColor: 'white',
      padding: '0.75em 0.5em',
      width: 'auto',
      border: `1px solid ${tertiary[100]}`,
      borderRadius: '6px',
      marginBottom: '0.25em',
    },
    registrationHeader: {
      textAlign: 'right',
      width: '9em',
    },
    notificationBlock: {
      padding: '0.75em',
      backgroundColor: secondary[50],
      border: `1px solid ${secondary[200]}`,
      marginTop: '1.325em',
      borderRadius: '0.5em',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '0.875em',
        top: '-18px',
        borderWidth: '9px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${secondary[200]} transparent`,
      },
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '0.925em',
        top: '-16px',
        borderWidth: '8px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${secondary[50]} transparent`,
      },
    },
    dialog: {
      width: '21em',
    },
    btnControlls: {
      width: '100%',
      paddingTop: '1.5em',
      textAlign: 'right',
    },
    registrationValue: {
      paddingLeft: '1em',
    },
    via: {
      paddingLeft: '0.5em',
    },
    removeRegistrations: {
      color: danger[600],
    },
    hidden: {
      display: 'none',
      height: 0,
      opacity: 0,
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
