import { ReactNode } from 'react';

export type SettingsPageTemplateProps = {
  title?: string;
  onCancel?: () => void;
  form?: string;
  isLoading?: boolean;
  children?: ReactNode;
  containerClass?: string;
};

export enum TuneType {
  Default = 'Default',
  Custom = 'Custom',
}
