import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';

export const useCallsRestrictionsStyles = makeStyles(
  ({ color: { primary, secondary, tertiary } }: Theme & IThemeOptions) => ({
    root: {
      tableLayout: 'fixed',
    },
    rootContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
    headFilterIcon: {
      background: tertiary[50],
      color: tertiary[400],
      lineHeight: 0,
      textAlign: 'center',
    },
    body: {
      marginTop: '0.25em',
      padding: '0 2em',
    },
    tableButtonCell: {
      color: 'inherit',
      padding: '0 1em 0 0',
    },
    primary: {
      color: primary[600],
    },
    secondary: {
      color: secondary[600],
    },
    secondaryText: {
      marginTop: '0.5em',
    },
    tableContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexFlow: 'column',
    },
    tablePanel: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '23em',
      minWidth: '23em',
      height: '100%',
    },
    tablePanelHeader: {
      top: '3.45em',
      display: 'flex',
      position: 'sticky',
      height: '2em',
      minHeight: '2em',
      background: tertiary[50],
      borderBottom: '1px solid #fff',
    },
    tablePanelContent: {
      display: 'flex',
      flexFlow: 'column nowrap',
      borderRight: `1px solid ${tertiary[100]}`,
      padding: '2.5em 1em 2.5em 2em',
      height: '100%',
      alignItems: 'center',
      width: '23em',
    },
    tableHeaderTitle: {
      textAlign: 'center',
      width: '70%',
    },
    tableTitleControls: {
      borderRadius: '0.375em',
      height: 'auto',
      minHeight: '3em',
      marginTop: '0.5em',
      marginBottom: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    rowContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    nameRowMargin: {
      marginTop: '3px',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row-reverse nowrap',
      '& > :not(:last-child)': {
        marginLeft: '1em',
      },
    },
    actionButton: {
      width: 'initial',
      height: '2em',
      marginLeft: '1em',
    },
    actionRowButton: {
      height: '2em',
      width: '2em',
      padding: 'initial',
    },
    dialog: {
      padding: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogWrapper: {
      borderRadius: '0.75em',
    },
    dialogContent: {
      padding: '1em',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    dialogTitle: {
      fontWeight: 'bold',
      lineHeight: '150%',
      textAlign: 'left',
      padding: '2em 2em 0',
    },
    modalTextContainer: {
      textAlign: 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    dialogActions: {
      paddingTop: '1.5em',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      overflow: 'auto',
    },
    container: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      '& > *': {
        marginTop: '1em',
      },
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '-0.125em',
      '& > *': {
        lineHeight: '2.4em',
      },
    },
    checkboxControl: {
      margin: '0.375em 0',
    },
    phoneNumberIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  {
    name: 'callsRestrictions',
    index: 1,
  }
);
