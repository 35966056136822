import { GET_RETAILCRM_IS_KNOWN_MUTATION } from '@/client/mutations';
import { useMutation } from '@apollo/client';
import MessageDialog from '@shared/components/MessageDialog';
import IntegrationIconRetail from '@shared/assets/images/icons/retail_puzzle.png';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import Typography from '@shared/components/Typography';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { IIntegrationAuthorizationProps } from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';
import { integrationRetailInstallOptions } from './RetailCRM.constants';

export const RetailCRMAuthorization: FunctionComponent<IIntegrationAuthorizationProps> = ({
  isOpen,
  onClose,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const navigate = useNavigate();
  const { clearErrors, watch, setError } = formMethods;
  const retailURL = watch('retailURL');
  const [searchParams] = useSearchParams();
  const sError = searchParams.get('error');
  const [notInstallDialogShow, setNotInstallDialogShow] = useState(false);
  const [getIsRetailAppInstalled, { loading }] = useMutation(GET_RETAILCRM_IS_KNOWN_MUTATION);
  const [errorDialogState, setErrorDialogState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: '',
  });

  useEffect(() => {
    if (sError) {
      setErrorDialogState((prevState) => ({
        ...prevState,
        isOpen: true,
        message: 'SOMETHING_WRONG_MESSAGE',
      }));
      onClose?.();
    }
  }, [sError]);

  function handleCloseErrorDialog() {
    setErrorDialogState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    if (sError) {
      navigate('/integrations');
    }
  }

  function handleCloseInstallNotification() {
    setNotInstallDialogShow(false);
  }

  function handleRetailAppInstall() {
    if (!retailURL) {
      setError(
        'retailURL',
        { type: 'error', message: translate('RETAIL_EMPTY_URL') },
        { shouldFocus: true }
      );
      return;
    }

    if (
      !validator.isURL(retailURL, {
        protocols: ['http', 'https'],
        allow_trailing_dot: false,
      })
    ) {
      setError(
        'retailURL',
        { type: 'error', message: translate('RETAIL_INTEGRATION_ERROR_BAD_URL') },
        { shouldFocus: true }
      );
      return;
    }

    let retailCrmUrl = retailURL;
    if (!retailURL.match(/^https?:\/\//)) {
      retailCrmUrl = `https://${retailCrmUrl}`;
    }

    getIsRetailAppInstalled({
      variables: {
        data: {
          retailCrmUrl,
        },
      },
    })
      .then((res) => {
        if (res.data && res.data.isRetailCrmAppInstalled) {
          if (res.data.isRetailCrmAppInstalled.confirmed) {
            navigate(
              `/integrations/edit-integration/${res.data.isRetailCrmAppInstalled.integrationId}`
            );
          } else {
            setNotInstallDialogShow(true);
          }
        }
      })
      .catch((error) => {
        const errorMessage = getErrorMessageFromGraphqlError(error);
        if (errorMessage === 'retailCrmUrl must be an URL address') {
          setError(
            'retailURL',
            { type: 'error', message: translate('RETAIL_INTEGRATION_ERROR_BAD_URL') },
            { shouldFocus: true }
          );
          return;
        }
        let message = '';
        if (errorMessage === 'Integration already exists') {
          message = 'RETAIL_INTEGRATION_ERROR_EXIST';
        }
        setErrorDialogState((prevState) => ({
          ...prevState,
          isOpen: true,
          message,
        }));
      });
  }

  return (
    <FormProvider {...formMethods}>
      <MessageDialog
        isOpen={isOpen}
        contentClass={classes.integrationDialog}
        title={translate('INTEGRATION_INSTALL_TITLE')}
        onCancel={() => onClose?.()}
        renderContent={
          <>
            <div className={classes.integrationAuthFieldBlockSimple}>
              <FormFieldRhfUncontrolled
                label={translate('RETAILCRM_ADDRESS_NAME')}
                name={'retailURL'}
                onChange={() => clearErrors('retailURL')}
              />
            </div>
            <Flex justifyContent={'flexEnd'}>
              <Button
                className={classes.installCRMButton}
                title={translate('INSTALL_CRM')}
                loading={loading}
                onClick={() => handleRetailAppInstall()}
                smallHeight
              />
            </Flex>
          </>
        }
      />
      <MessageDialog
        isOpen={errorDialogState.isOpen}
        contentClass={classes.integrationDialog}
        title={translate(errorDialogState.title)}
        message={translate(errorDialogState.message)}
        onCancel={handleCloseErrorDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
        }
      />
      <MessageDialog
        isOpen={notInstallDialogShow}
        contentClass={classes.integrationResponsibleErrorDialog}
        title={translate('RETAIL_API_INTEGRATION')}
        onCancel={() => handleCloseInstallNotification()}
        renderContent={
          <>
            <div className={classes.integrationIconBig}>
              <img src={IntegrationIconRetail} alt={''} />
            </div>
            <Flex className={classes.integrationInstallItemContainer} direction={'column'}>
              {integrationRetailInstallOptions.map((item, index) => (
                <Flex key={`install-note-${index}`} className={classes.integrationInstallItem}>
                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={classes.integrationInstallCircleNumber}
                  >
                    <Typography type={'text3'} color={'tertiary900'}>
                      {index + 1}
                    </Typography>
                  </Flex>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {translate(item)}
                  </Typography>
                </Flex>
              ))}
            </Flex>
            <Flex justifyContent={'flexEnd'}>
              <Button
                title={translate('CLOSE')}
                onClick={handleCloseInstallNotification}
                smallHeight
              />
            </Flex>
          </>
        }
      />
    </FormProvider>
  );
};

export default memo(RetailCRMAuthorization);
