import { GET_AMOCRM_INSTALL_URL_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import MessageDialog from '@shared/components/MessageDialog';
import Button from '@shared/components/Button';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IIntegrationAuthorizationProps } from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';

export const AmoCRMAuthorization: FunctionComponent<IIntegrationAuthorizationProps> = ({
  isOpen,
  onClose,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sCode = searchParams.get('code');
  const sError = searchParams.get('error');
  const { data: urlData } = useQuery(GET_AMOCRM_INSTALL_URL_QUERY);
  const { getAmoInstallURL: amoUrl = '' } = urlData || {};
  const [errorDialogState, setErrorDialogState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: '',
  });

  function handleAmocrmAppInstall() {
    window.location.href = amoUrl;
  }

  useEffect(() => {
    if (isOpen && amoUrl) {
      handleAmocrmAppInstall();
    }
  }, [isOpen, amoUrl]);

  useEffect(() => {
    if (sError) {
      let message = 'SOMETHING_WRONG_MESSAGE';
      if (sCode === 'already_installed') {
        message = 'AMOCRM_INTEGRATION_ERROR_EXIST';
      }
      setErrorDialogState((prevState) => ({
        ...prevState,
        isOpen: true,
        message,
      }));
      onClose?.();
    }
  }, [sError, sCode]);

  function handleCloseErrorDialog() {
    setErrorDialogState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    if (sError) {
      navigate('/integrations/preview-integration/AmoCRM');
    }
  }

  return (
    <MessageDialog
      isOpen={errorDialogState.isOpen}
      contentClass={classes.integrationDialog}
      title={translate(errorDialogState.title)}
      message={translate(errorDialogState.message)}
      onCancel={handleCloseErrorDialog}
      renderControls={
        <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
      }
    />
  );
};

export default memo(AmoCRMAuthorization);
