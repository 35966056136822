import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import Flex from '@shared/components/Flex';
import { IBlackList } from '../SettingsBlackList.interfaces';
import { useSettingsBlackListStyles } from '../SettingsBlackList.styles';

export const BlackListCommentCell: FunctionComponent<ICellProps<IBlackList>> = ({ item }) => {
  const { comment = '' } = item || {};
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('searchText') || null;
  const classes = useSettingsBlackListStyles();

  return (
    <Flex className={classes.defaultElementWidthFull}>
      <HighlightedText type={'text'} text={comment} query={searchText} textOverflow />
    </Flex>
  );
};
