import React from 'react';
import { useQuery } from '@apollo/client';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import clsx from 'clsx';
import { useBottomButtonsStyles } from './BottomButtons.styles';
import { BottomButtonsProps } from './BottonButtons.interfaces';

export const BottomButtons = ({ children }: BottomButtonsProps) => {
  const classes = useBottomButtonsStyles();
  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWideSidebar = sidebarState?.isWide;

  return (
    <div className={clsx(classes.wrapper, { [classes.wideSidebarMargin]: isWideSidebar })}>
      {children}
    </div>
  );
};

export default BottomButtons;
