import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { ICellProps } from '@components/LazyTable';
import { BusinessCardData } from '../SettingsBusinessCards.interface';

export const BusinessCardsMessageFormat: FunctionComponent<ICellProps<BusinessCardData>> = ({
  item,
}) => {
  const { sources } = item || {};
  const [translate] = useTranslation();

  if (!sources) return null;

  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {translate('SMS_GREETING')}
    </Typography>
  );
};
