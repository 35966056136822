import {
  responsibleObjectKey,
  scenarioSettingsType,
  scenariosListType,
} from '../IntegrationForm.interfaces';

export const amoScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: false,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'INCOMING_SCENARIOS_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_02'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.Responsible,
      options: ['INCOMING_SCENARIOS_ITEM_04'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_05',
      edit: scenarioSettingsType.IncomingKnown,
      options: ['INCOMING_SCENARIOS_ITEM_13', 'INCOMING_SCENARIOS_ITEM_06'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_04', 'SCENARIOS_SETTING_INSTALL_ON_02'],
      unsortedInstalled: ['SCENARIOS_SETTING_INSTALL_ON_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_06',
      edit: scenarioSettingsType.IncomingMissedKnown,
      options: ['INCOMING_SCENARIOS_ITEM_13', 'INCOMING_SCENARIOS_ITEM_17'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_04', 'SCENARIOS_SETTING_INSTALL_ON_02'],
      unsortedInstalled: ['SCENARIOS_SETTING_INSTALL_ON_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_07',
      edit: scenarioSettingsType.IncomingUnknown,
      options: ['INCOMING_SCENARIOS_ITEM_16', 'INCOMING_SCENARIOS_ITEM_14'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_05', 'SCENARIOS_SETTING_INSTALL_OUTGOING_02'],
      unsortedInstalled: ['SCENARIOS_SETTING_INSTALL_ON_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_08',
      edit: scenarioSettingsType.IncomingMissedUnknown,
      options: ['INCOMING_SCENARIOS_ITEM_16', 'INCOMING_SCENARIOS_ITEM_12'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_05', 'SCENARIOS_SETTING_INSTALL_OUTGOING_02'],
      unsortedInstalled: ['SCENARIOS_SETTING_INSTALL_ON_03'],
    },
  ],
  [
    {
      title: 'OUTGOING_SCENARIOS_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['OUTGOING_SCENARIOS_ITEM_01'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_02',
      edit: scenarioSettingsType.OutgoingKnown,
      options: ['OUTGOING_SCENARIOS_ITEM_10', 'OUTGOING_SCENARIOS_ITEM_03'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_04', 'SCENARIOS_SETTING_INSTALL_ON_02'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_03',
      edit: scenarioSettingsType.OutgoingMissedKnown,
      options: ['OUTGOING_SCENARIOS_ITEM_10', 'OUTGOING_SCENARIOS_ITEM_05'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_04', 'SCENARIOS_SETTING_INSTALL_ON_02'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.OutgoingUnknown,
      options: ['OUTGOING_SCENARIOS_ITEM_15', 'OUTGOING_SCENARIOS_ITEM_09'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_05', 'SCENARIOS_SETTING_INSTALL_OUTGOING_01'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_05',
      edit: scenarioSettingsType.OutgoingMissedUnknown,
      options: ['OUTGOING_SCENARIOS_ITEM_13', 'OUTGOING_SCENARIOS_ITEM_09'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_03', 'SCENARIOS_SETTING_INSTALL_OFF_02'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_05', 'SCENARIOS_SETTING_INSTALL_OUTGOING_01'],
    },
  ],
  [
    {
      title: 'GENERAL_SCENARIOS_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['GENERAL_SCENARIOS_ITEM_01'],
    },
    {
      title: 'GENERAL_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.CommonCallHistory,
      options: ['GENERAL_SCENARIOS_ITEM_04'],
      notInstalled: ['GENERAL_SCENARIOS_ITEM_04'],
      installed: ['SCENARIOS_SETTING_INSTALL_COMMON'],
    },
  ],
];

export const amoLeadResponsibleObjectKey = (
  settingType?: scenarioSettingsType
): responsibleObjectKey | null => {
  switch (settingType) {
    case scenarioSettingsType.IncomingKnown:
    case scenarioSettingsType.IncomingMissedKnown:
    case scenarioSettingsType.OutgoingKnown:
    case scenarioSettingsType.OutgoingMissedKnown:
      return responsibleObjectKey.Responsible;
    case scenarioSettingsType.IncomingUnknown:
    case scenarioSettingsType.IncomingMissedUnknown:
      return responsibleObjectKey.DefaultResponsible;
    case scenarioSettingsType.OutgoingUnknown:
    case scenarioSettingsType.OutgoingMissedUnknown:
      return responsibleObjectKey.Contract;
    default:
      return null;
  }
};

export const amoTaskResponsibleObjectKey = (
  settingType?: scenarioSettingsType
): responsibleObjectKey | null => {
  switch (settingType) {
    case scenarioSettingsType.IncomingKnown:
    case scenarioSettingsType.IncomingMissedKnown:
    case scenarioSettingsType.OutgoingKnown:
    case scenarioSettingsType.OutgoingMissedKnown:
      return responsibleObjectKey.Responsible;
    case scenarioSettingsType.IncomingUnknown:
    case scenarioSettingsType.IncomingMissedUnknown:
      return responsibleObjectKey.DefaultResponsible;
    default:
      return null;
  }
};

export const createConditionOptions = [
  {
    key: 'always',
    value: 'ALWAYS',
  },
  {
    key: 'if_not_exists',
    value: 'IF_NOT_EXISTS',
  },
];

export const tagsOptionList = [
  {
    title: 'CALL_DIRECTION',
    id: 'call_direction',
  },
  {
    title: 'CALL_RESULT',
    id: 'call_result',
  },
  {
    title: 'CLIENT_STATUS',
    id: 'client_status',
  },
  {
    title: 'UTM_PHONE',
    id: 'utm_phone',
  },
  {
    title: 'EMPLOYEE_NAME',
    id: 'employee_name',
  },
];
