import { DEPARTMENTS_QUERY, EMPLOYEES_QUERY } from '@/client/queries';
import MessageFields from '@/components/MessageFields/MessageFields';
import {
  IIncomingNumberScenario,
  IVoiceMailEmailType,
  IVoiceMenuEmail,
} from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import { getEmployeeDepartmentList } from '@/utils/getEmployeeDepartmentList';
import { useLazyQuery, useQuery } from '@apollo/client';
import { TrashIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import ComboBoxField from '@shared/components/ComboBoxField';
import EmailField from '@shared/components/EmailField';
import SwitchField from '@shared/components/SwitchField';
import Typography from '@shared/components/Typography';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { defaultMessages } from '../../IncomingNumber.constants';
import { ScenariosTypes } from '../../IncomingNumber.interfaces';
import {
  ISuggestEmployeeType,
  IVoiceMailScenarioProps,
  IVoiceMenuSettingsFormData,
} from './VoiceMailFaxScenario.interfaces';
import { useVoiceMailFaxScenarioStyles } from './VoiceMailFaxScenario.styles';

export const VoiceMailFaxScenario: FunctionComponent<IVoiceMailScenarioProps> = ({
  onSave,
  scenario,
  onCancel,
  onChanges,
  setIsSaveButtonLoading,
  scenarioType = ScenariosTypes.Voicemail,
  maxEmails,
  hasDepartments = false,
}) => {
  const classes = useVoiceMailFaxScenarioStyles();
  const [translate] = useTranslation();

  const { data: { employees = [] } = { employees: [] } } = useQuery<{
    employees: { id: number; user?: { name?: string } }[];
  }>(EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });
  // TODO handle lazy query error
  const [getDepartments, { data: { departments = [] } = { departments: [] } }] = useLazyQuery<{
    departments: { id: number; name?: string }[];
  }>(DEPARTMENTS_QUERY, { fetchPolicy: 'no-cache' });

  const formatEmailsList = (data: IVoiceMenuEmail[] | undefined) => {
    let filteredEmails = (data || []).filter(({ type }) => type !== IVoiceMailEmailType.Plain);
    if (filteredEmails.length === 0) {
      filteredEmails = [{ email: '', type: IVoiceMailEmailType.Employee }];
    }
    return filteredEmails.map(({ id, type }: IVoiceMenuEmail) => (id ? `${id}_${type}` : ''));
  };

  const plainEmail = (data: IVoiceMenuEmail[] | undefined) =>
    (data || []).find(({ type }) => type === IVoiceMailEmailType.Plain)?.email || '';

  const formMethods = useForm<Partial<IVoiceMenuSettingsFormData>>({
    defaultValues: {
      message: scenario?.id
        ? scenario?.message
        : translate(defaultMessages.get(scenarioType) || '', ''),
      messageType: scenario?.messageType || 'TEXT',
      isMessageActive: Boolean(
        scenario?.isMessageActive || scenario?.isMessageActive === undefined
      ),
      fileName: scenario?.fileName,
      fileUrl: scenario?.fileUrl,
      fileHash: scenario?.fileHash,
      email: plainEmail(scenario?.emails),
    },
  });
  const { handleSubmit, unregister, setValue, watch, clearErrors, setError } = formMethods;
  const isMessageActive = watch('isMessageActive');
  const [emailsList, setEmailsList] = useState<string[]>(formatEmailsList(scenario?.emails));
  const [isEmailFieldFocused, setIsEmailFieldFocused] = useState(false);
  const email = watch('email');

  const listEmailsLength = useMemo(
    () => emailsList.filter((emailItem) => Boolean(emailItem)).length,
    [emailsList]
  );
  const listMaxLength = useMemo(() => maxEmails - (email ? 1 : 0), [email, maxEmails]);

  const handleChanges = useCallback(() => {
    if (typeof onChanges === 'function') {
      onChanges();
    }
  }, [onChanges]);

  useEffect(() => {
    if (emailsList.length) {
      emailsList.forEach((itemValue, index) => {
        setValue(`emails-${index}`, itemValue);
      });
    }
  }, [emailsList, setValue]);

  const handleSubmitForm = useCallback(
    (data: IVoiceMenuSettingsFormData) => {
      if ((emailsList.length === 0 || data.email) && !validator.isEmail(data.email || '')) {
        setError('email', {
          message: translate(data.email ? 'INVALID_EMAIL' : 'VOICEMAIL_EMAIL_EMPTY'),
          type: 'error',
        });
        return;
      }
      const filteredEmails = emailsList.filter((itemEmail) => itemEmail !== '');
      const node: IIncomingNumberScenario = {
        ...scenario,
        ...data,
        type: scenario?.type || scenarioType,
        fileUrl: undefined,
        email: null,
        emails: filteredEmails.map((emailAlias: string) => {
          const [, id, type] = /^(\d+)_(EMPLOYEES|DEPARTMENTS)/gi.exec(emailAlias) || [];
          return { id: parseInt(id, 10), type } as IVoiceMenuEmail;
        }),
      };
      if (data.email) {
        node.emails = (node.emails || []).concat([
          { email: data.email, type: IVoiceMailEmailType.Plain } as IVoiceMenuEmail,
        ]);
      }
      onSave(node);
      onCancel();
    },
    [emailsList, scenario, scenarioType, translate, onSave, onCancel, setError]
  );

  const handleEmployeeSelect = useCallback(
    (item: ISuggestEmployeeType | null, index: number) => {
      const newEmails = [...emailsList];
      newEmails[index] = item ? item.value : '';
      setEmailsList(newEmails);
      handleChanges();
    },
    [emailsList, handleChanges]
  );

  const onRemoveOption = useCallback(
    (index) => {
      const newEmails = [...emailsList];
      unregister(`emails-${index}`);
      newEmails.splice(index, 1);
      setEmailsList(newEmails);
      handleChanges();
    },
    [emailsList, handleChanges, unregister]
  );

  const handleAddOption = useCallback(() => {
    const newEmails = [...emailsList, ''];
    clearErrors('email');
    setEmailsList(newEmails);
    handleChanges();
  }, [emailsList, clearErrors, handleChanges]);

  useEffect(() => {
    if (hasDepartments) {
      getDepartments();
    }
  }, [getDepartments, hasDepartments]);

  const renderItem = (emailAlias: string, index: number) => {
    const options = getEmployeeDepartmentList(
      employees,
      departments,
      'EMPLOYEES',
      'DEPARTMENTS'
    ).filter(
      ({ value }) => value === emailAlias || !emailsList?.some((alias: string) => alias === value)
    );

    const optionsSorted = options.sort(({ type: typeA }, { type: typeB }) => {
      if (typeA < typeB) return -1;
      if (typeA > typeB) return 1;
      return 0;
    });

    return (
      <div key={`voicemail-email-${index}-${emailAlias}`} className={classes.listItem}>
        <div className={classes.marginRight1}>
          <Typography>{index + 1}</Typography>
        </div>
        <ComboBoxField
          data={optionsSorted}
          groupBy={(option: { type: string }) => translate(option.type || '', '')}
          name={`emails-${index}`}
          valueKey={'value'}
          titleKey={'title'}
          onChange={(e, i) => {
            handleEmployeeSelect(i, index);
          }}
          placeholder={translate('CHOOSE')}
          classes={{ root: classes.defaultElementWidthFull }}
          validate={(id: string) => {
            if (!id && emailsList.filter((e) => e !== '').length === 0 && !email) {
              return translate('SELECT_EMPLOYEE') as string;
            }
            return true;
          }}
        />
        <Button
          variant={'secondary'}
          color={'error'}
          className={classes.listControl}
          onClick={() => {
            onRemoveOption(index);
          }}
        >
          <TrashIcon />
        </Button>
      </div>
    );
  };

  const renderAddButton = () => {
    const hasUnselected = emailsList?.some((itemEmail) => itemEmail === '');
    const isAvailable =
      employees.some(
        ({ id: employeeId }) =>
          !emailsList?.some((emailAlias) => emailAlias === `${employeeId}_EMPLOYEES`)
      ) ||
      departments.some(
        ({ id: departmentId }) =>
          !emailsList?.some((emailAlias) => emailAlias === `${departmentId}_DEPARTMENTS`)
      );
    const isDisabled =
      !isAvailable ||
      emailsList.length >= employees.length + departments.length ||
      emailsList.length >= listMaxLength ||
      (isEmailFieldFocused && emailsList.length >= maxEmails - 1) ||
      hasUnselected;
    return (
      <Button
        title={translate('ADD')}
        variant={'secondary'}
        onClick={handleAddOption}
        className={classes.addListItemButton}
        disabled={isDisabled}
      />
    );
  };

  useEffect(() => {
    if (!isMessageActive) {
      clearErrors('message');
      clearErrors('fileHash');
    }
  }, [isMessageActive, clearErrors]);

  return (
    <div className={classes.root}>
      <FormProvider {...formMethods}>
        <form id={'edit-text-or-audio'} action={''} onSubmit={handleSubmit(handleSubmitForm)}>
          <div>
            <div className={classes.caption}>
              <SwitchField name={'isMessageActive'} onChanges={handleChanges} />
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('MESSAGE')}
              </Typography>
            </div>
            <div className={classes.formContent}>
              <MessageFields
                disabled={!isMessageActive}
                required={isMessageActive}
                onChanges={handleChanges}
                setIsSaveButtonLoading={setIsSaveButtonLoading}
                maxLength={1000}
              />
            </div>
            <div className={classes.caption}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('SEND_VOICEMAIL')}
              </Typography>
            </div>
            <div className={classes.subCaption}>
              <Typography type={'text3'} color={'tertiary900'}>
                {translate('SEND_VOICEMAIL_HINT')}
              </Typography>
            </div>
            <div className={classes.marginTop1}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('INSTALLED_ADDRESSES', { amount: listEmailsLength, max: listMaxLength })}
              </Typography>
            </div>
            <div className={classes.list}>
              {emailsList.map(renderItem)}
              {renderAddButton()}
            </div>
            <div className={classes.plainEmail}>
              <Typography type={'text3'} color={'tertiary900'}>
                {translate('SEND_VOICEMAIL_PLAIN_HINT')}
              </Typography>
              <EmailField
                name={'email'}
                required={false}
                onChange={handleChanges}
                className={classes.plainEmailField}
                disabled={emailsList.length === listMaxLength && !email}
                onFocus={() => setIsEmailFieldFocused(true)}
                onBlur={() => setIsEmailFieldFocused(false)}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default VoiceMailFaxScenario;
