import { makeStyles } from '@material-ui/core';

export const useMessageStyles = makeStyles(
  {
    root: { textAlign: 'center' },
  },
  {
    index: 1,
  }
);
