import widthStyleConstants from './width.constants';
import paddingStyleConstants from './padding.constants';
import textStyleConstants from './text.constants';
import colorStyleConstants from './color.constants';

const styleConstants = {
  ...widthStyleConstants,
  ...textStyleConstants,
  ...paddingStyleConstants,
  ...colorStyleConstants,
  // Прикрепленные шапки
  stickyHeader: {
    top: '3.45em',
  },
  // Базовые стили
  defaultTextAlignCenter: {
    textAlign: 'center' as const,
  },
  searchColorPrimary: {
    background: 'rgb(191,255,255)',
  },
  searchColorSecondary: {
    background: 'rgb(199,210,254)',
  },
};

export default styleConstants;
