import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import { IStatisticsByEmployees } from '../../StatisticsByCalls/StatisticsByCalls.interfaces';
import { callType } from '../../StatisticsByCalls/StatisticsByCalls.constants';
import { UseEmployeesStatisticStyles } from '../StatisticsByEmployees.styles';

export const EmployeesStatisticTotalCalls: FunctionComponent<
  ICellProps<IStatisticsByEmployees>
> = ({ item }) => {
  const { outSuccess = 0, outNotSuccess = 0, inSuccess = 0, inNotSuccess = 0 } = item || {};
  const [searchParams] = useSearchParams();
  const sCallType = searchParams.get('callType');
  const classes = UseEmployeesStatisticStyles();

  const renderTotalCallsValue = () => {
    if (sCallType === callType.incoming) return inSuccess + inNotSuccess;
    if (sCallType === callType.outgoing) return outSuccess + outNotSuccess;
    return inSuccess + outSuccess;
  };

  return (
    <div className={classes.rightCell}>
      <Typography type={'text3'} color={'tertiary900'}>
        {renderTotalCallsValue()}
      </Typography>
    </div>
  );
};
