import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CALLS_RESTRICTIONS_QUERY, DEPARTMENTS_QUERY, EMPLOYEES_QUERY } from '@/client/queries';
import Drawer from '@components/Drawer';
import {
  ADD_CALLS_RESTRICTIONS_MUTATION,
  UPDATE_CALLS_RESTRICTIONS_MUTATION,
} from '@/client/mutations';
import ComboBoxField from '@shared/components/ComboBoxField';
import Typography from '@shared/components/Typography';
import PagePreloader from '@components/PagePreloader';
import { CallRestrictionsDirectionsField } from '@/features/CallsRestrictions/modules';
import BodyContainer from '@/layouts/BodyContainer';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import HighlightedText from '@components/HighlightedText';
import { CallsRestrictionsQuery, CallRestrictionType } from '@/client/generated/graphql';
import { useCallRestrictionsStyles } from './CallRestrictions.styles';

export type CallRestrictionsProps = {
  item?: Omit<CallsRestrictionsQuery['getCallRestrictions'][number], 'id'> & {
    id: number | undefined;
  };
};

export const deserializeCallsRestrictions = (
  data: CallsRestrictionsQuery['getCallRestrictions'][number] | undefined
) => {
  if (!data) {
    return null;
  }

  const { id, employee, department, employeeId, departmentId, restriction } = data || {};

  if (employee) {
    return {
      type: 'employee' as const,
      id,
      employeeId,
      name: data.employee?.user.name || '',
      restriction,
    };
  }

  if (department) {
    return {
      type: 'department' as const,
      id,
      departmentId,
      name: data.department?.name || '',
      employeesCount: department?.employees?.length || 0,
      restriction,
    };
  }

  return null;
};

export const CallRestrictions = ({
  item: { id, restriction = [], employeeId, departmentId } = {
    id: undefined,
    restriction: [],
    employeeId: undefined,
    departmentId: undefined,
  },
}: CallRestrictionsProps) => {
  const classes = useCallRestrictionsStyles({});
  const [translate] = useTranslation();
  const [isChanged, setIsChanged] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [addCallsRestrictions, { loading: addLoading }] = useMutation(
    ADD_CALLS_RESTRICTIONS_MUTATION
  );
  const [updateCallsRestrictions, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_CALLS_RESTRICTIONS_MUTATION
  );
  const {
    loading = false,
    error = false,
    data: { getCallRestrictions = [] } = {},
    called,
  } = useQuery(CALLS_RESTRICTIONS_QUERY);
  const isAdd = !!id;

  const restrictions = useMemo(
    () => getCallRestrictions?.map(deserializeCallsRestrictions) || [],
    [getCallRestrictions]
  );

  const { data: { employees = [] } = { employees: [] }, loading: employeesLoading } = useQuery<{
    employees: { id: string | number; ext: string; user?: { name?: string } }[];
  }>(EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const { data: { departments = [] } = { departments: [] }, loading: departmentsLoading } =
    useQuery<{
      departments: { id: string | number; ext: string; name?: string }[];
    }>(DEPARTMENTS_QUERY, { fetchPolicy: 'no-cache' });

  const formMethods = useForm<
    Partial<{ callerId?: string; local?: boolean; mn?: boolean; mg?: boolean }>
  >({
    defaultValues: {
      callerId:
        // eslint-disable-next-line no-nested-ternary
        employeeId !== null && Number.isFinite(Number(employeeId))
          ? `${employeeId}_employeeId`
          : departmentId !== null && Number.isFinite(Number(departmentId))
          ? `${departmentId}_departmentId`
          : '',
      local: true,
      mn: restriction.includes(CallRestrictionType.Mn),
      mg: restriction.includes(CallRestrictionType.Mg),
    },
  });
  const { handleSubmit } = formMethods;

  const getOptionDisabled = useCallback(
    ({ value }: { value: string }) => {
      const [, optionId, type] = /^(\d+)_(employeeId|departmentId)/gi.exec(value) || [];
      if (optionId && !loading && !error && called) {
        return restrictions.some((r) => {
          if (r === null) {
            return false;
          }
          return (
            (type === 'employeeId' && r.type === 'employee' && r.employeeId === Number(optionId)) ||
            (type === 'departmentId' &&
              r.type === 'department' &&
              r.departmentId === Number(optionId))
          );
        });
      }
      return false;
    },
    [called, error, loading, restrictions]
  );

  const handleClose = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const handleSubmitForm = (formData: {
    callerId?: string;
    local?: boolean;
    mn?: boolean;
    mg?: boolean;
  }) => {
    if (addLoading || updateLoading) return;
    const { callerId } = formData;
    const selectedRestriction: CallRestrictionType[] = [];
    if (formData.local) {
      selectedRestriction.push(CallRestrictionType.Local);
    }
    if (formData.mg) {
      selectedRestriction.push(CallRestrictionType.Mg);
    }
    if (formData.mn) {
      selectedRestriction.push(CallRestrictionType.Mn);
    }

    const employeeIdLocal = callerId?.endsWith('_employeeId')
      ? Number(callerId?.replace('_employeeId', ''))
      : undefined;
    const departmentIdLocal = callerId?.endsWith('_departmentId')
      ? Number(callerId?.replace('_departmentId', ''))
      : undefined;
    const restrictionLocal = selectedRestriction;

    const refetchQueries = [{ query: CALLS_RESTRICTIONS_QUERY }];

    if (typeof id === 'number') {
      updateCallsRestrictions({
        variables: {
          data: {
            id,
            restriction: restrictionLocal,
            departmentId: departmentIdLocal,
            employeeId: employeeIdLocal,
          },
        },
        refetchQueries,
      }).then(() => {
        handleClose();
      });
    } else {
      addCallsRestrictions({
        variables: {
          data: {
            restriction: restrictionLocal,
            departmentId: departmentIdLocal,
            employeeId: employeeIdLocal,
          },
        },
        refetchQueries,
      }).then(() => {
        handleClose();
      });
    }
  };

  const handleChanges = () => {
    setIsChanged(true);
  };

  const options = employees
    .map(({ id: emplId, ext, user: { name = '' } = {} }) => ({
      value: `${emplId}_employeeId`,
      title: name || '',
      ext,
      type: translate('EMPLOYEES'),
    }))
    .concat(
      departments.map(({ id: deptId, ext, name: deptName = '' }) => ({
        value: `${deptId}_departmentId`,
        title: deptName || '',
        ext,
        type: translate('DEPARTMENTS'),
      }))
    );

  const isLoading = isAdd ? loading : updateLoading;

  const renderCallerOptions = (
    data: {
      ext: string;
      title: string;
    },
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <Flex className={classes.customLine} justifyContent={'spaceBetween'}>
      <Typography
        className={clsx(classes.callerTitle, classes.textOverflow)}
        color={'tertiary900'}
        type={'text3'}
      >
        {data.title}
      </Typography>
      <div className={classes.callerExt}>
        <HighlightedText
          type={'text'}
          text={data.ext}
          query={inputValue}
          typographyType={'text3'}
          typographyColor={'tertiary900'}
        />
      </div>
    </Flex>
  );

  return (
    <BodyContainer>
      <Drawer
        title={'PERSONAL_RULES'}
        ModalProps={{ disableBackdropClick: isChanged }}
        elevation={4}
        open
        size={'xs'}
        primaryButton={{
          title: 'SAVE',
          props: {
            form: 'edit-personal-rules',
            type: 'submit',
            loading: isLoading,
          },
        }}
        secondaryButton={{
          title: 'CANCEL',
          onClick: handleClose,
          props: {
            disabled: isLoading,
          },
        }}
        onClose={handleClose}
      >
        {employeesLoading || departmentsLoading ? (
          <PagePreloader />
        ) : (
          <FormProvider {...formMethods}>
            <form
              id={'edit-personal-rules'}
              action={''}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('EMPLOYEE_OR_DEPARTMENT')}
              </Typography>
              <div className={classes.formContent}>
                <ComboBoxField
                  data={options.sort(({ type: typeA }, { type: typeB }) => {
                    if (typeA < typeB) return -1;
                    if (typeA > typeB) return 1;
                    return 0;
                  })}
                  name={'callerId'}
                  valueKey={'value'}
                  titleKey={'title'}
                  groupBy={(option: { type: string }) => option.type}
                  getOptionDisabled={getOptionDisabled}
                  renderOption={renderCallerOptions}
                  validate={(value: string) => {
                    if (!value) {
                      return translate('SELECT_EMPLOYEE_OR_DEPARTMENT') as string;
                    }
                    return true;
                  }}
                  placeholder={translate('CHOOSE')}
                  onChange={handleChanges}
                  filterOptions={(opts, state) => {
                    if (!state.inputValue) return opts;
                    const inputValueLowercased = state.inputValue.toLocaleLowerCase();
                    return opts.filter(
                      (opt) =>
                        opt.ext.toLowerCase().includes(inputValueLowercased) ||
                        opt.title.toLowerCase().includes(inputValueLowercased)
                    );
                  }}
                />
              </div>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('ALLOWED_CALLS_DIRECTIONS')}
              </Typography>
              <div className={classes.formContent}>
                <CallRestrictionsDirectionsField onChange={handleChanges} />
              </div>
              {updateError && (
                <Typography type={'text4'} color={'danger600'}>
                  {translate('SOMETHING_WENT_WRONG')}
                </Typography>
              )}
            </form>
          </FormProvider>
        )}
      </Drawer>
    </BodyContainer>
  );
};

export default CallRestrictions;
