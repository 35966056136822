import { makeStyles } from '@material-ui/core/styles';

export const useWelcomeDialogStyles = makeStyles(
  ({ color: { secondary, tertiary } }) => ({
    button: {
      width: '6.5em',
      marginLeft: 'auto',
    },
    dialog: {
      padding: '2em 2em 1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogPaper: {
      maxWidth: '24em',
      width: '24em',
    },
    dialogContent: {
      padding: '0 1.5em 1.5em',
      lineHeight: '150%',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',
    },
    descriptionText: {
      textAlign: 'center',
      margin: '1.5em auto 1em',
      display: 'block',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      position: 'relative',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.75em 1.5em',
      borderBottom: `1px solid ${tertiary[200]}`,
    },
    pbxTextBlock: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.25em 0',
    },
    reservedBlock: {
      borderRadius: '6px',
      border: `1px solid ${secondary[200]}`,
      backgroundColor: secondary[50],
      padding: '0.75em',
      marginTop: '1em',
      textAlign: 'center',
    },
    resetPasswordWrapper: {
      marginBottom: '1.75em',
    },
    textBlock: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'center',
      gap: '0.5em',
    },
  }),
  {
    index: 10,
  }
);
