import { OptionType } from '@/client/generated/graphql';
import { AVAILABLE_FEATURES_QUERY, GET_SELECTED_TARIFF_QUERY, USER_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import { DomainStatus } from '@components/typings/interfaces';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { IFeatures } from '../../../typings';

/**
 * We should get AVAILABLE_FEATURES_QUERY on app
 * bootstrap. So we assume that this query oftentimes will be
 * retrieved from cache.
 */
export const useDomainFeatures = () => {
  const { data, loading, error } = useQuery(AVAILABLE_FEATURES_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const featuresTyped = data?.getAvailableFeatures?.features as IFeatures | undefined;

  return { features: featuresTyped, loading, error };
};

export const isFeatureAvailable = (
  feature: string | undefined,
  availableList: IFeatures | undefined
): boolean => {
  if (!feature || !availableList) {
    return false;
  }
  if (!availableList || Object.keys(availableList).length === 0) {
    return false;
  }
  if (availableList[feature] !== undefined) {
    return availableList[feature];
  }
  if (availableList['*'] !== undefined) {
    return availableList['*'];
  }
  return false;
};

/**
 * We should get USER_QUERY and GET_SELECTED_TARIFF_QUERY on app
 * bootstrap. So we assume that these queries oftentimes will be
 * retrieved from cache.
 */
export const useDomainOptions = () => {
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const status = getCurrentDomain(userData?.user)?.status || DomainStatus.Unknown;
  const { data, loading, error } = useQuery(GET_SELECTED_TARIFF_QUERY, {
    fetchPolicy: 'cache-first',
  });

  let options: (OptionType | '*')[] = [OptionType.BasicPbx];
  const optionsFromQuery = data?.getDomain.tariff?.options;

  if (
    status === DomainStatus.Demo ||
    status === DomainStatus.Moderating ||
    status === DomainStatus.Confirmed
  ) {
    options = ['*'];
  } else if (optionsFromQuery?.length) {
    options = optionsFromQuery.map((o) => o.type);
  }

  return { options, loading, error };
};

export const isOptionAvailable = (option: string | undefined, tariffOptions: string[]): boolean => {
  if (!option || !tariffOptions) {
    return false;
  }
  return tariffOptions.includes('*') || tariffOptions.includes(option);
};
