import { makeStyles, CheckboxProps, Theme } from '@material-ui/core';

export const useCheckboxStyles = makeStyles<Theme, { color: CheckboxProps['color'] }>(
  ({ color: { tertiary, primary, secondary } }: Theme) => ({
    root: {
      padding: '0 0.5em 0 0',
    },
    primary: {
      color: primary[700],
    },
    secondary: {
      color: secondary[700],
    },
    disabled: {
      color: tertiary[300],
    },
  }),
  {
    index: 1,
  }
);
