// @ts-ignore - DatePicker internal types may not be seen by other packages
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import FormFieldBase from '@shared/components/FormFieldBase';
import { mergeValidateFuncsAndObjects } from '@shared/utils';
// eslint-disable-next-line import/no-duplicates
import { format, isDate, isValid, startOfDay } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ruLocale from 'date-fns/locale/ru';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MuiTextFieldProps } from '@material-ui/pickers/_shared/PureDateInput';
import { DateFieldProps } from './DateField.interfaces';
import { useDateFieldStyles } from './DateField.styles';

/** Поле для ввода даты. Работает только с react-hook-form.
 * В качестве значения может быть `Date | null`.
 * `null` появляется в случае, когда поле пустое.
 * Также следует помнить, что Date может быть InvalidDate.
 * */
export const DateField = ({
  label,
  name,
  validate: validateFromProps,
  disableFuture,
  disablePast,
  disabled,
  readonly,
  required,
  minDate,
  onChange: onChangeFromProps,
}: DateFieldProps) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const classes = useDateFieldStyles();
  const fieldName = name || 'date';
  const defaultFormValue = watch(fieldName);
  const [translate] = useTranslation();
  const defaultControlValue = defaultFormValue || new Date();
  const errorMessageFromRhf = errors?.[fieldName] ? errors[fieldName]?.message?.toString() : '';

  const validate = (value: Date | null) => {
    if (readonly) {
      return true;
    }
    if (value === null) {
      if (required) {
        return translate('EMPTY_FIELD_DATE');
      }
      return true;
    }
    if (isDate(value) && !isValid(value)) {
      return translate('INVALID_DATE');
    }

    const currentDate = new Date();
    const currentDateAtMidnight = startOfDay(currentDate);
    const valueAtMidnight = startOfDay(value);

    if (disablePast && valueAtMidnight < currentDateAtMidnight) {
      return translate('A_LATER_DATE_WAS_EXPECTED');
    }
    if (disableFuture && valueAtMidnight > currentDateAtMidnight) {
      return translate('AN_EARLIER_DATE_WAS_EXPECTED');
    }
    if (minDate && isValid(minDate) && valueAtMidnight < minDate) {
      return translate('A_LATER_DATE_WAS_EXPECTED');
    }
    if (!minDate && valueAtMidnight.getFullYear() < 1900) {
      return translate('A_LATER_DATE_WAS_EXPECTED');
    }
    return true;
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ruLocale}>
      <Controller
        name={fieldName}
        defaultValue={defaultControlValue}
        rules={{ validate: mergeValidateFuncsAndObjects(validateFromProps, validate) }}
        render={({ field: { onChange, value, ref } }) => (
          <DatePicker
            minDate={minDate}
            label={label}
            value={value}
            disabled={disabled}
            disablePast={disablePast}
            allowSameDateSelection
            className={classes.dateField}
            DialogProps={{ disableBackdropClick: true }}
            disableFuture={disableFuture}
            mask={'__.__.____'}
            showDaysOutsideCurrentMonth
            ref={ref}
            onChange={(date: number | Date | null) => {
              onChangeFromProps?.();
              if (date === null || (isDate(date) && !isValid(date))) {
                onChange(date);
                return;
              }
              onChange(new Date(format(date, "yyyy-MM-dd'T'09:00:00'.000Z'")));
            }}
            // props: MuiTextFieldProps is necessary to avoid incorrect ts check errors
            renderInput={(props: MuiTextFieldProps) => {
              const { inputProps, InputProps, ...rest } = props;

              return (
                <FormFieldBase
                  {...rest}
                  error={Boolean(errors?.[fieldName])}
                  helperText={errorMessageFromRhf}
                  InputProps={{
                    endAdornment: !readonly ? InputProps?.endAdornment : undefined,
                    inputProps: {
                      ...inputProps,
                      placeholder: '__.__.____',
                      readOnly: readonly,
                    },
                  }}
                />
              );
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
