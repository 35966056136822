import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useDialogPayForNumberStyle = makeStyles(
  () => ({
    numberActionsDialogContent: {
      textAlign: 'left',
      whiteSpace: 'pre-line',
    },
    numberActionsDialogActions: {
      padding: '2em 0 0',
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
