export enum FeaturesType {
  Dashboard = 'dashboard',
  History = 'history',
  OwnHistory = 'own-history',
  Statistics = 'statistics',
  StatisticsEmployees = 'statistics-employees',
  StatisticsNumbers = 'statistics-numbers',
  Employees = 'employees',
  Departments = 'departments',
  Calls = 'calls',
  IncomingCalls = 'incoming-calls',
  OutgoingCalls = 'outgoing-calls',
  CallsRestrictions = 'calls-restrictions',
  BasicSettings = 'basic-settings',
  BlackList = 'blacklist',
  Notifications = 'notifications',
  BusinessCards = 'business-cards',
  OwnNumbers = 'own-numbers',
  Integrations = 'integrations',
  Documents = 'documents',
  Balance = 'balance',
  Numbers = 'numbers',
  Assistants = 'assistants',
  AssistantsUI = 'assistants-ui',
  Tariff = 'tariff',
  IntegrationBasic = 'integration-basic',
  IntegrationAmo = 'integration-amo',
  IntegrationBitrix24 = 'integration-bitrix24',
  IntegrationMegaPlan = 'integration-megaplan',
  IntegrationRetail = 'integration-retail',
  IntegrationOdinS = 'integration-1c',
}

export interface IFeatures {
  [key: string]: boolean;
}
