import React, { ChangeEvent, PropsWithChildren, useMemo } from 'react';
import { Chip, Popper, PopperProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import { CheckEmptyIcon, CheckIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { useTranslation } from 'react-i18next';
import { clearWhiteSpace } from '@shared/utils';
import FormFieldBase from '@shared/components/FormFieldBase';
import { ISelectTagsFieldProps, onChangeValueType } from './SelectTagsField.interfaces';
import { useSelectTagsFieldStyles } from './SelectTagsField.styles';

export const SelectTagsField = <T,>({
  data,
  label,
  value,
  titleKey,
  valueKey,
  placeholder,
  defaultValue,
  className,
  onChange,
}: ISelectTagsFieldProps<T>) => {
  const classes = useSelectTagsFieldStyles();
  const [translate] = useTranslation();

  const options = useMemo(() => data.map((option) => String(option[titleKey])), [data, titleKey]);

  const isTagFromList = (option: string) =>
    data.find((dataItem) => String(dataItem[titleKey]) === option);

  const defaultRenderOption = (option: string, { selected }: AutocompleteRenderOptionState) =>
    option ? (
      <Flex className={classes.optionLine} alignItems={'center'}>
        {selected ? (
          <CheckIcon className={classes.optionLineIcon} />
        ) : (
          <CheckEmptyIcon className={classes.optionLineIcon} />
        )}
        <Typography color={'tertiary900'} type={'text3'}>
          {option || ''}
        </Typography>
      </Flex>
    ) : null;

  function handleChange(event: ChangeEvent<Record<string, unknown>>, selectedValues: string[]) {
    if (onChange) {
      const result = selectedValues.reduce(
        (res: onChangeValueType, item: string) => {
          const valueIdItem = isTagFromList(item);
          if (valueIdItem) {
            res.IdList.push(valueIdItem[valueKey]);
            res.valuesList.push(item);
            return res;
          }
          const filteredItem = clearWhiteSpace(item);
          if (filteredItem !== '') {
            res.customList.push(filteredItem);
            res.valuesList.push(filteredItem);
          }
          return res;
        },
        { IdList: [], customList: [], valuesList: [] }
      );
      onChange(result);
    }
  }

  const renderPopperComponent = (popperProps: PropsWithChildren<PopperProps>) => {
    const { children, ...rest } = popperProps;
    // @ts-ignore Неправильная типизация возвращаемая от PropsWithChildren
    const isHasNode = children?.props?.children[2] || null;
    if (!isHasNode) return null;
    return <Popper {...rest}>{children}</Popper>;
  };

  return (
    <Autocomplete
      freeSolo
      multiple
      value={value}
      className={className}
      options={options}
      defaultValue={defaultValue}
      onChange={handleChange}
      clearText={translate('CLEAR_TAGS')}
      renderTags={(tagValue: readonly string[], getTagProps) => (
        <div style={{ width: '100%' }}>
          {tagValue.map((option: string, index: number) => (
            <Chip
              key={`tag-${option}`}
              variant="outlined"
              label={option}
              classes={{
                root: isTagFromList(option) ? classes.tagFromList : undefined,
                deleteIcon: classes.tagFromListRemove,
              }}
              {...getTagProps({ index })}
            />
          ))}
        </div>
      )}
      renderOption={defaultRenderOption}
      renderInput={(params) => (
        <FormFieldBase
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            classes: { root: classes.root },
          }}
        />
      )}
      // @ts-ignore - incorrect types for popper
      PopperComponent={renderPopperComponent}
    />
  );
};

export default SelectTagsField;
