import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DOMAIN_ALL_NUMBERS_QUERY, USER_QUERY } from '@/client/queries';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { Skeleton } from '@material-ui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INITIALIZE_EMPLOYEE_MUTATION } from '@/client/mutations';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography/Typography';
import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import Translate from '@shared/components/Translate';
import { NumberStatus } from '@/client/generated/graphql';
import PasswordField from '@shared/components/PasswordField';
import { deserializeProfile } from '@/features/Profile/Profile.utils';
import FormErrorMessage from '@components/FormErrorMessage';
import { useWelcomeDialogStyles } from './WelcomeDialog.styles';

interface IFormFields {
  password: string;
  passwordConfirm: string;
}

export const WelcomeDialog = () => {
  const classes = useWelcomeDialogStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const { data: { user = undefined } = {}, loading } = useQuery(USER_QUERY);
  const formMethods = useForm<IFormFields>();
  const { handleSubmit, setError } = formMethods;
  const { features } = useDomainFeatures();
  const nextRoute = useMemo(
    () => (isFeatureAvailable('dashboard', features) ? '/dashboard/' : '/history/'),
    [features]
  );
  const onCompleted = () => navigate(nextRoute);
  const [initializeEmployee, { loading: initializeLoading, error }] = useMutation(
    INITIALIZE_EMPLOYEE_MUTATION,
    {
      onCompleted,
      refetchQueries: [{ query: USER_QUERY }],
    }
  );
  const { data: numbersData, loading: loadingPhone } = useQuery(DOMAIN_ALL_NUMBERS_QUERY);

  const { domainNumbers = [] } = numbersData || {};

  const phone = domainNumbers.find((number) => {
    if (number.__typename === 'NumbersModel') {
      return number.numberStatus === NumberStatus.Bought;
    }
    return false;
  })?.phone;
  const reservedPhone = domainNumbers.find(
    (number) =>
      number.__typename === 'NumbersModel' && number.numberStatus === NumberStatus.Reserved
  )?.phone;
  const profile = deserializeProfile(user);

  const handleFormSubmit = useCallback(
    async (formData: IFormFields) => {
      const currentEmployeeId = user?.domains?.find(({ current }) => current)?.employees?.[0]?.id;
      const { password, passwordConfirm } = formData;
      if (password !== passwordConfirm) {
        setError('passwordConfirm', {
          type: 'error',
          message: translate('PASSWORD_CONFIRMATION_DOES_NOT_MATCH'),
        });
        return;
      }
      if (currentEmployeeId) {
        initializeEmployee({
          variables: {
            data: {
              id: currentEmployeeId,
              name: profile.name,
              position: profile.position,
              password,
            },
          },
        });
      }
    },
    [user, setError, translate, initializeEmployee, profile]
  );

  const renderContent = () => {
    if (loading || loadingPhone) {
      return <Skeleton />;
    }

    return (
      <>
        <div className={classes.textBlock}>
          <Typography>{translate('PERSONAL_NUMBER_DEDICATED')}</Typography>
          <Typography color={'tertiary900'} type={'text2'} bold>
            {formatPhone(phone || '')}
          </Typography>
        </div>
        {reservedPhone && (
          <div className={classes.reservedBlock}>
            <Translate
              i18nKey={'RESERVED_NUMBER_DEDICATED'}
              values={{
                reservedNumber: formatPhone(reservedPhone),
              }}
              components={{
                b: <b />,
                n: <br />,
                t: <Typography color="tertiary900" type={'text4'} />,
              }}
            />
          </div>
        )}
        <Typography className={classes.descriptionText} bold>
          {translate('ENTER_PASSWORD_TO_ACCESS_PBX')}
        </Typography>
        <FormProvider {...formMethods}>
          <form
            id={'welcome-form'}
            action={''}
            onSubmit={handleSubmit(handleFormSubmit)}
            className={classes.form}
          >
            <div className={classes.resetPasswordWrapper}>
              <PasswordField
                scoring
                generation={false}
                required
                name={'password'}
                label={translate('NEW_PASSWORD')}
              />
            </div>
            <div className={classes.resetPasswordWrapper}>
              <PasswordField
                scoring={false}
                generation={false}
                required
                name={'passwordConfirm'}
                label={translate('CONFIRM_NEW_PASSWORD')}
              />
            </div>
            <Button
              title={translate('LET_GO')}
              form={'welcome-form'}
              type={'submit'}
              className={classes.button}
              loading={initializeLoading}
            />
            {error && <FormErrorMessage errors={error?.graphQLErrors} />}
          </form>
        </FormProvider>
      </>
    );
  };

  return (
    <Dialog
      aria-labelledby={'welcome-dialog'}
      onClose={() => navigate(nextRoute)}
      open
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div className={classes.header}>
        <Typography color={'tertiary900'} bold>
          {`${translate('WELCOME').slice(0, -1)}, ${profile.name}!`}
        </Typography>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.pbxTextBlock}>
          <Typography color={'primary700'} type={'text2'}>
            {translate('SOFT_SWITCH_SUCCESSFULLY_CREATED')}
          </Typography>
        </div>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeDialog;
