import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '@shared/components/CheckboxField/CheckboxField';
import { CallRestrictionType } from '@/client/generated/graphql';
import { useCallsRestrictionsStyles } from '../CallsRestrictions.styles';

export const CallRestrictionsDirectionsField: FunctionComponent<{
  disabled?: boolean;
  onChange?: () => void;
  showOnly?: boolean;
  getValues?: {
    [key: string]: unknown;
  };
}> = ({ disabled, onChange, showOnly, getValues }) => {
  const classes = useCallsRestrictionsStyles();
  const [translate] = useTranslation();

  const isDisabled = (objectKey: string) => {
    if (getValues && showOnly) {
      return !getValues[objectKey];
    }
    return false;
  };

  return (
    <div className={classes.container}>
      <CheckboxField
        name={'local'}
        onChange={onChange}
        label={translate('COMPANY_CALLS')}
        readOnly
      />
      <CheckboxField
        name={'mg'}
        disabled={isDisabled(CallRestrictionType.Mg)}
        onChange={onChange}
        readOnly={showOnly}
        label={translate('COUNTRY_CALLS', { region: translate('RU_DIRECTION') })}
      />
      <CheckboxField
        name={'mn'}
        disabled={isDisabled(CallRestrictionType.Mn)}
        onChange={onChange}
        readOnly={showOnly && disabled}
        label={translate('INTERNATIONAL_CALLS')}
      />
    </div>
  );
};
