import { makeStyles, Theme } from '@material-ui/core/styles';
import { IDrawerStylesProps } from './Drawer.interfaces';

const closeButtonWidth = '3em';

export const useDrawerStyles = makeStyles<Theme, IDrawerStylesProps>(
  ({ color: { base, tertiary } }) => ({
    root: {},
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: (props) => props.width,
    },
    header: {
      position: 'relative',
      width: '100%',
      flex: '0 0 3em',
      textAlign: 'center',
      color: base,
      backgroundColor: tertiary[700],
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: '1.125em',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: `calc(100% - ${closeButtonWidth})`,
      paddingLeft: closeButtonWidth,
    },
    closeButton: {
      padding: 0,
      color: base,
      flex: `0 0 ${closeButtonWidth}`,
      border: 'none',
      height: '100%',
      '&:hover': {
        color: tertiary[300],
      },
    },
    content: {
      padding: '2.1562em 2em',
      flexGrow: 1,
      overflowY: 'auto',
    },
    footer: {
      padding: '0 2em .5em',
      flex: '0 0 4.5em',
      display: 'flex',
      alignItems: 'center',
      fontSize: '1em',

      borderTop: (props) => (props.hasFooterDivider ? `1px solid ${tertiary[100]}` : 'none'),
      boxShadow: (props) =>
        props.hasFooterDivider ? `0px -0.75em 0.5em -0.5em ${tertiary[100]}` : 'none',
    },
    primaryButton: {
      minWidth: '10em',
    },
    secondaryButton: {
      marginLeft: '1em',
    },
    tertiaryButton: {
      marginLeft: 'auto',
    },
    buttonLabel: {
      fontSize: '0.875em',
    },
  })
);
