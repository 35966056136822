import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import MessageDialog from '@shared/components/MessageDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  GET_PROMISED_PAYMENT_SUM_QUERY,
  GET_RECOMMENDED_PAYMENT_QUERY,
  USER_QUERY,
} from '@/client/queries';
import {
  CREATE_PROMISED_PAYMENT_MUTATION,
  REGISTER_ORDER_FL_MUTATION,
  REGISTER_ORDER_UL_MUTATION,
} from '@/client/mutations';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import Button from '@shared/components/Button';
import {
  MirIcon,
  TBankIcon,
  SBPIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@shared/assets/images/icons';
import ControlButtons from '@shared/components/ControlButtons';
import Flex from '@shared/components/Flex';
import { formatDate, PROMISE_PAYMENT_MIN_AMOUNT, toPrecision } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { addDays, format } from 'date-fns';
import { PROMISE_PAYMENT_DAYS_AMOUNT } from '@/features/FinancialCabinet/Balance/Balance.constants';
import Preloader from '@shared/components/Preloader';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { getStorageItem } from '@components/storage/storage';
import NumberField from '@shared/components/NumberField';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { ClientType } from '@/client/generated/graphql';
import { useBalanceDialogStyle } from './BalanceDialog.styles';
import { IBalanceDialogProps } from './BalanceDialog.interfaces';

export const BalanceDialog: FunctionComponent<IBalanceDialogProps> = ({
  isFillBalanceDialogOpen,
  isPromisePaymentDialogOpen,
  onCloseBalanceDialog,
  onClosePromiseDialog,
  onPromisePaymentSuccess,
  onSuccessCallbackUrl,
}) => {
  const classes = useBalanceDialogStyle();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isSumRecommended, setIsSumRecommended] = useState(false);
  const [openBankingDetails, setOpenBankingDetails] = useState(false);
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const {
    data: promisedPaymentData,
    error: promisePaymentError,
    refetch: refetchPromisedPayment,
  } = useQuery(GET_PROMISED_PAYMENT_SUM_QUERY);
  const { checkPromisedPaymentSum: { sum: promisedPaymentSum = 0 } = {} } =
    promisedPaymentData || {};
  const [registerOrderFL, { loading: loadingOrderFL }] = useMutation(REGISTER_ORDER_FL_MUTATION);
  const [registerOrderUL, { loading: loadingOrderUL }] = useMutation(REGISTER_ORDER_UL_MUTATION);
  const [
    createPromisePayment,
    { loading: loadingPromisePayment, error: createPromisePaymentError },
  ] = useMutation(CREATE_PROMISED_PAYMENT_MUTATION, {
    refetchQueries: [{ query: GET_PROMISED_PAYMENT_SUM_QUERY }],
  });
  const [
    recommendedPayment,
    {
      data: getRecommendedPayment,
      error: recommendedPaymentError,
      loading: loadingRecommendedPayment,
    },
  ] = useLazyQuery(GET_RECOMMENDED_PAYMENT_QUERY, {
    fetchPolicy: 'network-only',
  });
  const recommendedPaymentSum = getRecommendedPayment?.getRecommendedPayment?.payment;
  const recommendedBalance = toPrecision(recommendedPaymentSum || 0, false);
  const domain = userData && getCurrentDomain(userData.user);
  const formMethods = useForm();
  const { watch, setError, clearErrors, setValue, setFocus } = formMethods;
  const sAmount = searchParams.get('amount');
  const sPayment = searchParams.get('payment');
  const token = getStorageItem('token');
  const amount = watch('amount');

  const successPayment = sPayment && sPayment !== 'fail';
  const failedPayment = sPayment === 'fail';
  const filterFieldValue = (fieldValue: string) => {
    if (Number(fieldValue) >= 0) {
      return fieldValue;
    }
    let result;
    const pattern = /^\d*,\d+$/gm;
    if (pattern.test(fieldValue)) {
      result = fieldValue.replace(',', '.');
    }
    result = Number(result).toFixed(2);
    return result;
  };

  const setFieldErrors = useCallback(
    (nmb: number | string) => {
      const paymentAmount = Number(nmb);
      if (!nmb || paymentAmount === 0) {
        setError('amount', { type: 'error', message: translate('INVALID_AMOUNT_EMPTY') });
      } else if (paymentAmount < 200) {
        setError('amount', { type: 'error', message: translate('TOO_SMALL_AMOUNT') });
      } else if (paymentAmount > 1000000) {
        setError('amount', { type: 'error', message: translate('TOO_MUCH_AMOUNT') });
      } else {
        setError('amount', { type: 'error', message: translate('INVALID_AMOUNT') });
      }
    },
    [setError, translate]
  );

  const promisePaymentErrorObject =
    promisePaymentError?.graphQLErrors[0] || createPromisePaymentError?.graphQLErrors[0];

  const handleRegisterOrderFL = useCallback(() => {
    if (loadingOrderFL) return;
    const amountValue = filterFieldValue(amount);
    if (
      Number.isNaN(Number(amountValue)) ||
      Number(amountValue) < 200 ||
      Number(amountValue) > 1000000
    ) {
      setFieldErrors(amountValue);
    } else {
      clearErrors('amount');
      const amountValueNumber = Math.round(Number(amountValue) * 100);
      registerOrderFL({
        variables: {
          amount: amountValueNumber,
          callback: onSuccessCallbackUrl,
        },
      })
        .then((res) => {
          if (!res.data) {
            Sentry.captureException(
              Error(
                "Request 'registerOrderFL' succeeded, but there is no data. Cannot change href to 'registerOrderFl' field."
              )
            );
            return;
          }
          window.location.href = res.data.registerOrderFL;
        })
        .catch(() => {
          setShowErrorDialog(true);
          onCloseBalanceDialog();
        });
    }
  }, [
    amount,
    clearErrors,
    loadingOrderFL,
    onCloseBalanceDialog,
    onSuccessCallbackUrl,
    registerOrderFL,
    setFieldErrors,
  ]);

  const handleRegisterOrderUL = useCallback(() => {
    if (loadingOrderUL) return;
    const amountValue = filterFieldValue(amount);
    if (
      Number.isNaN(Number(amountValue)) ||
      Number(amountValue) < 200 ||
      Number(amountValue) > 1000000
    ) {
      setFieldErrors(amountValue);
    } else {
      const amountValueNumber = Math.round(Number(amountValue) * 100);
      registerOrderUL({
        variables: {
          amount: amountValueNumber,
        },
      })
        .then((res) => {
          onCloseBalanceDialog();
          searchParams.set('amount', (amountValueNumber / 100).toString());

          if (!res.data) {
            Sentry.captureException(
              Error(
                "Request 'registerOrderUL' succeeded, but there is no data. Cannot use 'registerOrderUL' field."
              )
            );
          } else {
            searchParams.set('payment', res.data.registerOrderUL);
          }

          setSearchParams(searchParams);
        })
        .catch(() => {
          setShowErrorDialog(true);
          onCloseBalanceDialog();
        });
    }
  }, [
    amount,
    loadingOrderUL,
    onCloseBalanceDialog,
    registerOrderUL,
    searchParams,
    setFieldErrors,
    setSearchParams,
  ]);

  const handleCreatePromisePayment = useCallback(() => {
    if (loadingPromisePayment) return;
    createPromisePayment().then(() => {
      refetchPromisedPayment().then();
      onPromisePaymentSuccess?.();
      onClosePromiseDialog?.();
      setShowSuccessDialog(true);
    });
  }, [
    loadingPromisePayment,
    createPromisePayment,
    refetchPromisedPayment,
    onPromisePaymentSuccess,
    onClosePromiseDialog,
  ]);

  useEffect(() => {
    if (isFillBalanceDialogOpen && !getRecommendedPayment && !recommendedPaymentError) {
      recommendedPayment();
    }
  }, [getRecommendedPayment, isFillBalanceDialogOpen, recommendedPayment, recommendedPaymentError]);

  useEffect(() => {
    if (amount) {
      setValue('amount', amount.replace(/[^\d.,]/gm, ''));
    }
  }, [amount, setValue]);

  useEffect(() => {
    if (!amount && recommendedPaymentSum && !isSumRecommended) {
      setValue('amount', String(recommendedPaymentSum.toFixed(0)));
      setIsSumRecommended(true);
      setFocus('amount');
    }
  }, [amount, isSumRecommended, recommendedPaymentSum, setFocus, setValue]);

  function handleAmountFieldFocus() {
    clearErrors('amount');
  }

  function handleCloseSuccessDialog() {
    searchParams.delete('amount');
    searchParams.delete('payment');
    setSearchParams(searchParams);
  }

  function handleCloseBalanceDialog() {
    onCloseBalanceDialog();
  }

  function handleClosePromiseDialog() {
    onClosePromiseDialog?.();
  }

  function handleCloseErrorsDialog() {
    setShowErrorDialog(false);
    searchParams.delete('amount');
    searchParams.delete('payment');
    setSearchParams(searchParams);
  }

  const renderBankingDetails = () => {
    return openBankingDetails ? (
      <div>
        <div className={classes.bankingDetails}>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('ORGANIZATION')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'ИП ЖИСЛИН ИВАН БОРИСОВИЧ'}</Typography>
            </div>
          </Flex>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('INN')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'502901528795'}</Typography>
            </div>
          </Flex>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('OGRN_OGRNIP')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'315774600295495'}</Typography>
            </div>
          </Flex>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('CHECKING_ACCOUNT')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'40802.810.7.38000169437'}</Typography>
            </div>
          </Flex>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('BIK')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'044525225'}</Typography>
            </div>
          </Flex>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('BANK')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'ПАО СБЕРБАНК'}</Typography>
            </div>
          </Flex>
          <Flex justifyContent={'flexStart'} direction={'row'} className={classes.detailsRow}>
            <div className={classes.detailsCaption}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('CORRESPONDENT_ACCOUNT_SHORT')}
              </Typography>
            </div>
            <div className={classes.detailsData}>
              <Typography type={'text4'}>{'30101.810.4.00000000225'}</Typography>
            </div>
          </Flex>
        </div>
        <Button clear onClick={() => setOpenBankingDetails(false)}>
          <Typography type={'text3'} underline color={'link600'}>
            {translate('HIDE_BANKING_DETAILS')}
          </Typography>
          <ChevronUpIcon className={classes.chevron} />
        </Button>
      </div>
    ) : (
      <div>
        <Button clear onClick={() => setOpenBankingDetails(true)}>
          <Typography type={'text3'} underline color={'link600'}>
            {translate('SHOW_BANKING_DETAILS')}
          </Typography>
          <ChevronDownIcon className={classes.chevron} />
        </Button>
      </div>
    );
  };

  const renderBalanceText = () => {
    return (
      <div className={classes.balanceTextContainer}>
        <Typography type={'text3'} bold color={'tertiary900'}>
          {translate('AMOUNT_TO_PAY')}
        </Typography>
        <Flex alignItems="center">
          <Typography type={'text4'} color={'tertiary900'} className={classes.marginRight05}>
            {translate('RECOMMEND_BALANCE')}
          </Typography>
          {loadingRecommendedPayment ? (
            <Preloader size="small" />
          ) : (
            <Typography
              type={'text4'}
              color={'tertiary900'}
              bold
            >{`${recommendedBalance} ₽`}</Typography>
          )}
        </Flex>
      </div>
    );
  };

  const renderModalContent = () => {
    if (!domain) {
      return <div />;
    }

    const clientType = domain.client?.type;

    if (clientType === ClientType.Fl) {
      return (
        <>
          {renderBalanceText()}
          <div className={classes.text}>
            <NumberField
              name={'amount'}
              placeholder={translate('MIN_PAYMENT')}
              onFocus={handleAmountFieldFocus}
              numberFormat={'sum'}
              InputProps={{ endAdornment: <TBankIcon className={classes.card} /> }}
            />
          </div>
          <div className={classes.container}>
            <div className={classes.cardsCell}>
              <Typography type={'text3'} color={'tertiary900'}>
                {translate('FILL_CARDS_DESCRIPTION')}
              </Typography>
            </div>
            <Flex className={classes.iconsContainer}>
              <SBPIcon className={clsx(classes.card, classes.sbpIcon)} />
              <MirIcon className={classes.card} />
            </Flex>
          </div>
          {renderBankingDetails()}
          <ControlButtons
            rootClass={classes.buttons}
            confirmTitle={'TO_PAYMENT'}
            cancelTitle={'CANCEL'}
            cancelVariant="secondary"
            justifyContent={'start'}
            flexDirection={'row-reverse'}
            onConfirmClick={handleRegisterOrderFL}
            onCancelClick={handleCloseBalanceDialog}
            loading={loadingOrderFL}
            small
          />
        </>
      );
    }
    if (clientType === ClientType.Ul || clientType === ClientType.Ip) {
      return (
        <>
          {renderBalanceText()}
          <div>
            <NumberField
              name={'amount'}
              placeholder={translate('MIN_PAYMENT')}
              onFocus={handleAmountFieldFocus}
              numberFormat={'sum'}
            />
          </div>
          <ControlButtons
            rootClass={classes.buttons}
            confirmTitle={'GENERATE_INVOICE'}
            cancelTitle={'CANCEL'}
            cancelVariant="secondary"
            justifyContent={'start'}
            flexDirection={'row-reverse'}
            onConfirmClick={handleRegisterOrderUL}
            onCancelClick={handleCloseBalanceDialog}
            loading={loadingOrderUL}
            small
          />
        </>
      );
    }
    return <div />;
  };

  const renderPromisePaymentContent = () => {
    if (promisePaymentErrorObject) {
      const errorType = promisePaymentErrorObject?.extensions?.exception as
        | { message?: string; availabilityDate?: string }
        | undefined;

      if (errorType?.message === 'IS_CREDIT_IN_LAST_TWO_MONTHS') {
        if (errorType?.availabilityDate) {
          const { day, month } = formatDate(new Date(errorType?.availabilityDate));
          return (
            <>
              <div className={classes.centerBlock}>
                <Translate
                  i18nKey="PROMISE_PAYMENT_ERROR_TEXT_01"
                  components={{
                    t: <Typography type={'text3'} color={'tertiary900'} />,
                    n: <br />,
                  }}
                />
              </div>
              <div className={classes.centerBlock}>
                <Translate
                  i18nKey="PROMISE_PAYMENT_ERROR_TEXT_02"
                  values={{ paymentDate: `${day} ${translate(month)}` }}
                  components={{
                    t: <Typography type={'text3'} color={'tertiary900'} />,
                    tp: <Typography type={'text3'} color={'primary700'} />,
                    n: <br />,
                  }}
                />
              </div>
              <div className={classes.btnSimpleBlock}>
                <Button title={translate('CLOSE')} onClick={handleClosePromiseDialog} smallHeight />
              </div>
            </>
          );
        }
        return (
          <>
            <div className={classes.centerBlock}>
              <Translate
                i18nKey="PROMISE_PAYMENT_ERROR_TEXT_04"
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  n: <br />,
                }}
              />
            </div>
            <div className={classes.btnSimpleBlock}>
              <Button title={translate('CLOSE')} onClick={handleClosePromiseDialog} smallHeight />
            </div>
          </>
        );
      }
      if (errorType?.message === 'BALANCE_ABOVE_300') {
        return (
          <>
            <div className={classes.centerBlock}>
              <Translate
                i18nKey="PROMISE_PAYMENT_ERROR_TEXT_03"
                values={{ amount: PROMISE_PAYMENT_MIN_AMOUNT }}
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  n: <br />,
                }}
              />
            </div>
            <div className={classes.btnSimpleBlock}>
              <Button title={translate('CLOSE')} onClick={handleClosePromiseDialog} smallHeight />
            </div>
          </>
        );
      }
    }
    if (promisedPaymentData) {
      return (
        <>
          <div className={classes.centerBlock}>
            <Translate
              i18nKey="PROMISE_PAYMENT_DESCRIPTION"
              components={{
                t: <Typography type={'text3'} color={'tertiary900'} />,
                n: <br />,
              }}
            />
          </div>
          <div className={classes.centerBlock}>
            <Typography type={'text1'} color={'primary700'}>
              {toPrecision(promisedPaymentSum)} ₽
            </Typography>
          </div>
          <div className={classes.centerBlock}>
            <Translate
              i18nKey="PROMISE_PAYMENT_NOTE"
              values={{
                paymentDate: format(addDays(new Date(), PROMISE_PAYMENT_DAYS_AMOUNT), 'dd.MM.yyyy'),
              }}
              components={{
                t: <Typography type={'text3'} color={'tertiary900'} />,
                tp: <Typography type={'text3'} color={'primary700'} />,
                n: <br />,
              }}
            />
          </div>
          <ControlButtons
            rootClass={classes.promisePaymentButtons}
            confirmTitle={'CONFIRM'}
            confirmDisable={promisedPaymentSum <= 0}
            cancelTitle={'CANCEL'}
            cancelVariant="secondary"
            justifyContent={'start'}
            flexDirection={'row-reverse'}
            onConfirmClick={handleCreatePromisePayment}
            onCancelClick={handleClosePromiseDialog}
            loading={loadingPromisePayment}
            small
          />
        </>
      );
    }
    return null;
  };

  const renderModalContentSuccess = () => {
    const clientType = domain?.client?.type;

    if (clientType === ClientType.Fl) {
      return (
        <>
          <div className={classes.centerBlock}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('BALANCE_DESCRIPTION_SUCCESS')}
            </Typography>
          </div>
          <div className={classes.centerBlock}>
            <Typography type={'text1'} color={'primary700'}>
              {sAmount ? toPrecision(Number(sAmount) / 100) : 0} ₽
            </Typography>
          </div>
          <div className={classes.btnBlock}>
            <Button title={translate('CLOSE')} onClick={handleCloseSuccessDialog} smallHeight />
          </div>
        </>
      );
    }
    if (clientType === ClientType.Ul || clientType === ClientType.Ip) {
      return (
        <>
          <div className={classes.centerBlock}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('INVOICE_DESCRIPTION')}
            </Typography>
          </div>
          <div className={classes.centerBlock}>
            <Typography type={'text1'} color={'primary700'}>
              {sAmount ? toPrecision(Number(sAmount)) : 0} ₽
            </Typography>
          </div>
          <div className={classes.centerBlock}>
            <Translate
              i18nKey="INVOICE_RESULT_LINK_DESCRIPTION"
              components={{
                b: <Typography color={'tertiary900'} type={'text3'} />,
                t: <Typography type={'text3'} color={'link600'} className={classes.linkText} />,
                n: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                  <a
                    rel={'noreferrer'}
                    target={'_blank'}
                    href={sPayment ? `${sPayment}&token=${token}` : undefined}
                    className={classes.link}
                  />
                ),
              }}
            />
          </div>
          <div className={classes.btnBlock}>
            <Button title={translate('CLOSE')} onClick={handleCloseSuccessDialog} smallHeight />
          </div>
        </>
      );
    }

    return <Preloader />;
  };

  const renderModalContentErrors = () => {
    const clientType = domain?.client?.type;

    if (clientType === ClientType.Fl) {
      return (
        <>
          <div className={classes.centerBlock}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('BALANCE_DESCRIPTION_ERROR')}
            </Typography>
          </div>
          <div className={classes.btnBlock}>
            <Button
              title={translate('CLOSE')}
              onClick={handleCloseErrorsDialog}
              classes={{ root: classes.defaultElementWidth11 }}
              smallHeight
            />
          </div>
        </>
      );
    }
    if (clientType === ClientType.Ul || clientType === ClientType.Ip) {
      return (
        <>
          <div className={classes.centerBlock}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('INVOICE_DESCRIPTION_ERROR')}
            </Typography>
          </div>
          <div className={classes.btnBlock}>
            <Button
              title={translate('CLOSE')}
              onClick={handleCloseErrorsDialog}
              classes={{ root: classes.defaultElementWidth11 }}
              smallHeight
            />
          </div>
        </>
      );
    }
    return <Preloader />;
  };

  const renderSuccessPaymentContent = () => {
    if (showSuccessDialog) {
      return (
        <>
          <div className={classes.centerBlock}>
            <Translate
              i18nKey="PROMISE_PAYMENT_SUCCESS_TEXT_01"
              values={{
                paymentDate: format(addDays(new Date(), PROMISE_PAYMENT_DAYS_AMOUNT), 'dd.MM.yyyy'),
              }}
              components={{
                t: <Typography type={'text3'} color={'tertiary900'} />,
                p: <Typography type={'text3'} color={'primary700'} />,
                n: <br />,
              }}
            />
          </div>
          <div className={classes.btnBlock}>
            <Button
              title={translate('CLOSE')}
              onClick={() => setShowSuccessDialog(false)}
              smallHeight
            />
          </div>
        </>
      );
    }
    return null;
  };

  const renderMessages = () => {
    if (!domain) {
      return null;
    }

    const clientType = domain.client?.type;

    return (
      <>
        <MessageDialog
          isOpen={isFillBalanceDialogOpen}
          contentClass={classes.dialog}
          onCancel={handleCloseBalanceDialog}
          title={translate('FILL_UP_TITLE')}
          renderContent={<FormProvider {...formMethods}>{renderModalContent()}</FormProvider>}
        />
        <MessageDialog
          isOpen={!!isPromisePaymentDialogOpen}
          contentClass={
            promisePaymentErrorObject ? classes.promiseErrorDialog : classes.promiseDialog
          }
          onCancel={handleClosePromiseDialog}
          title={
            promisePaymentErrorObject
              ? translate('SERVICE_UNAVAILABLE')
              : translate('PROMISED_PAYMENT')
          }
          renderContent={renderPromisePaymentContent()}
        />
        <MessageDialog
          isOpen={!!sAmount && successPayment}
          contentClass={classes.successDialog}
          onCancel={handleCloseSuccessDialog}
          title={
            clientType === ClientType.Fl
              ? translate('BALANCE_REPLENISHED')
              : translate('INVOICE_ISSUED')
          }
          renderContent={renderModalContentSuccess()}
        />
        <MessageDialog
          isOpen={showErrorDialog || failedPayment}
          contentClass={classes.successDialog}
          onCancel={handleCloseErrorsDialog}
          title={
            clientType === ClientType.Fl
              ? translate('BALANCE_REPLENISHED_ERROR')
              : translate('INVOICE_ISSUED_ERROR')
          }
          renderContent={renderModalContentErrors()}
        />
        <MessageDialog
          isOpen={showSuccessDialog}
          contentClass={classes.successDialog}
          onCancel={() => setShowSuccessDialog(false)}
          title={translate('PROMISED_PAYMENT')}
          renderContent={renderSuccessPaymentContent()}
        />
      </>
    );
  };

  return <FormProvider {...formMethods}>{renderMessages()}</FormProvider>;
};

export default BalanceDialog;
