import { scenarioSettingsType, scenariosListType } from '../IntegrationForm.interfaces';
import WhereToFindFirstImg from './images/whereToFind1.png';
import WhereToFindSecondImg from './images/whereToFind2.png';
import WhereToFindThirdImg from './images/whereToFind3.png';

export const OmnideskScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'INCOMING_SCENARIOS_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_02'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_07',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_18', 'INCOMING_SCENARIOS_ITEM_19'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_09',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_20'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_08',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_18', 'INCOMING_SCENARIOS_ITEM_21'],
    },
  ],
  [
    {
      title: 'OUTGOING_SCENARIOS_TITLE_06',
      edit: scenarioSettingsType.Always,
      options: ['OUTGOING_SCENARIOS_ITEM_16'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_07',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_18', 'INCOMING_SCENARIOS_ITEM_19'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_05',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_18', 'OUTGOING_SCENARIOS_ITEM_17'],
    },
  ],
  [
    {
      title: 'GENERAL_SCENARIOS_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['GENERAL_SCENARIOS_ITEM_07'],
    },
  ],
];

export const OMNIDESK_SLIDER_IMAGES = [
  { title: 'WHERE_SEARCH_OMNIDESK_MESSAGE', img: WhereToFindFirstImg },
  { title: 'WHERE_SEARCH_OMNIDESK_MESSAGE', img: WhereToFindSecondImg },
  { title: 'WHERE_SEARCH_OMNIDESK_MESSAGE', img: WhereToFindThirdImg }
];
