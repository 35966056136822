import { useQuery } from '@apollo/client';
import { COMMON_OUTGOING_NUMBER_QUERY } from '@/client/queries';
import { useMemo } from 'react';

export function useDefaultCommonOutgoingNumber(): {
  commonNumber: { [key: number]: string } | null;
} {
  const { data: commonOutgoingData } = useQuery<{
    getCommonOutgoingNumber: { numberId: number; number: { phone: string } };
  }>(COMMON_OUTGOING_NUMBER_QUERY);
  return useMemo(() => {
    if (!commonOutgoingData) return { commonNumber: null };
    const commonNumberId = commonOutgoingData?.getCommonOutgoingNumber?.numberId;
    const commonNumberPhone = commonOutgoingData?.getCommonOutgoingNumber.number.phone;
    return { commonNumber: { [commonNumberId as number]: commonNumberPhone as string } };
  }, [commonOutgoingData]);
}
