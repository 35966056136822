import React, { FunctionComponent } from 'react';
import { PhoneIncomingIcon, PhoneOutgoingIcon } from '@shared/assets/images/icons';
import { ICellProps } from '@components/LazyTable';
import { ICallDetailsList } from '../CallDetails.interfaces';
import { useCallDetailsStyle } from '../CallDetails.styles';

export const CallDetailsDirectionIconCell: FunctionComponent<ICellProps<ICallDetailsList>> = ({
  item,
}) => {
  const classes = useCallDetailsStyle();

  if (item) {
    const { direction } = item;

    const renderIncomingIcon = () => {
      if (direction === 'IN') {
        return <PhoneIncomingIcon className={classes.links} />;
      }
      return <PhoneOutgoingIcon className={classes.success} />;
    };

    return renderIncomingIcon();
  }

  return <div />;
};
