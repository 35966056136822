import { TFunction } from 'i18next';
import { TariffOptionModel } from '@/client/generated/graphql';

export const getComputedMinEmployeesTooltipText = (options: {
  skipDiscount?: boolean;
  accountAmount: number;
  currentEmployeeCount: number;
  accountOption: TariffOptionModel | undefined;
  translate: TFunction;
}) => {
  const {
    accountAmount,
    accountOption,
    translate,
    skipDiscount = false,
    currentEmployeeCount,
  } = options;
  const accountMin = accountOption?.params?.min || 0;
  const discountAmount = accountOption?.domainTariffOptionParams?.discountLimit || 0;

  const min = skipDiscount ? accountMin : Math.max(accountMin, discountAmount);

  const computedMinAccount = min > currentEmployeeCount ? min : currentEmployeeCount;
  let accountMinMessage;

  if (!skipDiscount && discountAmount > accountMin && accountAmount === discountAmount) {
    accountMinMessage = translate('TARIFF_CHANGE_EMPLOYEE_LESS_WARNING_06');
  } else if (accountAmount === computedMinAccount && min >= currentEmployeeCount) {
    accountMinMessage = translate('TARIFF_CHANGE_EMPLOYEE_LESS_WARNING_04', { amount: min });
  } else if (accountAmount === computedMinAccount && min < currentEmployeeCount) {
    accountMinMessage = translate('TARIFF_CHANGE_EMPLOYEE_LESS_WARNING_05');
  }

  return {
    computedMinAccount,
    accountMinMessage,
  };
};

export const getComputedMinIntegrationsTooltipText = (
  integrationAmount: number,
  currentIntegrationCount: number,
  translate: TFunction
) => {
  let integrationMinMessage;

  if (integrationAmount && integrationAmount === currentIntegrationCount) {
    integrationMinMessage = translate('TARIFF_CHANGE_INTEGRATIONS_LESS_WANING');
  }

  return {
    computedMinIntegrations: currentIntegrationCount,
    integrationMinMessage,
  };
};
