import React from 'react';
import { Button as ButtonMUI, Dialog } from '@material-ui/core';
import Typography from '@shared/components/Typography';
import { XIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import { IMessageDialogProps } from './MessageDialog.interfaces';
import { useMessageDialogStyles } from './MessageDialog.styles';

export const MessageDialog = ({
  title,
  message,
  isOpen,
  isShowCloseBtn = true,
  onCancel,
  renderContent,
  renderControls,
  contentClass,
  paperClass,
  buttonsBlockClass,
  disableEnforceFocus = false,
  disableBackdropClick = false,
  disableClose,
  bodyWrapperClass,

  buttonSuccessProps,
  buttonErrorProps,
  buttonCancelProps,
}: IMessageDialogProps) => {
  const classes = useMessageDialogStyles();
  const [translate] = useTranslation();

  const onDialogClose = () => {
    if (disableClose) {
      return;
    }
    if (onCancel) {
      onCancel();
    }
  };

  const renderControlsLocal = () => {
    if (renderControls) {
      return <div className={clsx(classes.buttonBlock, buttonsBlockClass)}>{renderControls}</div>;
    }

    if (!buttonSuccessProps && !buttonErrorProps && !buttonCancelProps) {
      return null;
    }

    return (
      <div className={clsx(classes.buttonBlock, buttonsBlockClass)}>
        {buttonCancelProps && (
          <Button
            {...buttonCancelProps}
            className={clsx(
              classes.buttonControl,
              classes.buttonControlMargin,
              buttonCancelProps.className
            )}
            variant={buttonCancelProps.variant ?? 'secondary'}
          >
            {buttonCancelProps.children ?? (
              <Typography>{translate(buttonCancelProps.titleCode ?? 'CANCEL')}</Typography>
            )}
          </Button>
        )}

        {buttonErrorProps && (
          <Button
            {...buttonErrorProps}
            className={clsx(
              classes.buttonControl,
              classes.buttonControlMargin,
              buttonErrorProps.className
            )}
            variant={buttonErrorProps.variant ?? 'primary'}
            color={buttonErrorProps.color ?? 'error'}
          >
            {buttonErrorProps.children ?? (
              <Typography color="base">
                {translate(buttonErrorProps.titleCode ?? 'DELETE')}
              </Typography>
            )}
          </Button>
        )}

        {buttonSuccessProps && (
          <Button
            {...buttonSuccessProps}
            className={clsx(classes.buttonControl, buttonSuccessProps.className)}
            variant={buttonSuccessProps.variant ?? 'primary'}
          >
            {buttonSuccessProps.children ?? (
              <Typography color="base">
                {translate(buttonSuccessProps.titleCode ?? 'SAVE')}
              </Typography>
            )}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Dialog
      classes={{ paper: clsx(paperClass, classes.paper) }}
      disableEnforceFocus={disableEnforceFocus}
      open={isOpen}
      onClose={onDialogClose}
      disableBackdropClick={disableBackdropClick}
    >
      <div className={classes.header}>
        <Typography color={'tertiary900'} bold>
          {title}
        </Typography>
        {isShowCloseBtn && (
          <ButtonMUI
            className={classes.buttonClose}
            disabled={disableClose}
            onClick={onDialogClose}
            type="button"
          >
            <XIcon />
          </ButtonMUI>
        )}
      </div>
      <div className={clsx(classes.bodyWrapper, bodyWrapperClass)}>
        <div className={clsx(classes.content, contentClass)}>
          {renderContent || (
            <div className={classes.centerBlock}>
              <Typography type={'text3'} color={'tertiary900'}>
                {message}
              </Typography>
            </div>
          )}
          {renderControlsLocal()}
        </div>
      </div>
    </Dialog>
  );
};

export default MessageDialog;
