import { clearWhiteSpace } from '@shared/utils';
import React, { ChangeEvent, FocusEvent } from 'react';
import { FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import FormFieldUI from '../FormFieldUI';
import { FormFieldBaseProps } from './FormFieldBase.interfaces';

type IsError = boolean;
type HelperText = React.ReactNode;
type GetHelperTextStateFunc = () => [IsError, HelperText];

const FormFieldBase = ({
  name,
  onChange,
  onBlur,
  select,
  type,
  error: errorFromProps,
  helperText,

  // `new-password` value helps prevent chrome autocomplete miss-behavior
  // It's like `off`, but it works better with Chrome.
  // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
  autoComplete = 'new-password',
  InputProps: InputPropsFromProps,
  ...props
}: FormFieldBaseProps) => {
  const formMethods = useFormContext();
  const InputProps = {
    ...InputPropsFromProps,
    autoComplete: InputPropsFromProps?.autoComplete || autoComplete,
  };

  const getHelperTextState: GetHelperTextStateFunc = () => {
    let isError = Boolean(errorFromProps);
    let helperTextLocal = helperText;
    const isHelperTextAString = typeof helperText === 'string';

    if (isError && isHelperTextAString) {
      return [isError, helperTextLocal];
    }

    const isThisComponentBoundToRhf = formMethods && name;

    if (!isThisComponentBoundToRhf) {
      return [isError, helperTextLocal];
    }

    let errorDataFromRHF: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;

    if (formMethods.formState.errors[name]) {
      errorDataFromRHF = formMethods.formState.errors[name];
      isError = true;
    } else if (name.includes('.')) {
      // Maybe this is a field from a set of fields? Like `aNumber.0`, for example
      const nameSplit = name.split('.');
      const fieldSetName = nameSplit[0];
      const fieldSetIndex = nameSplit[1];
      const fieldSetIndexAsNumber = Number.parseInt(String(fieldSetIndex), 10);

      if (fieldSetName && !Number.isNaN(fieldSetIndexAsNumber)) {
        const arrayOfErrors = formMethods.formState.errors[fieldSetName];
        if (
          Array.isArray(arrayOfErrors) &&
          typeof arrayOfErrors[fieldSetIndexAsNumber] === 'object' &&
          arrayOfErrors[fieldSetIndexAsNumber] !== null &&
          arrayOfErrors[fieldSetIndexAsNumber].message
        ) {
          errorDataFromRHF = arrayOfErrors[fieldSetIndexAsNumber];
          isError = true;
        }
      }
    }

    if (errorDataFromRHF) {
      helperTextLocal = isHelperTextAString
        ? helperTextLocal
        : String(errorDataFromRHF.message || 'Error');
    }

    return [isError, helperTextLocal];
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value || null;
    if (!select && targetValue) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = clearWhiteSpace(targetValue);
    }
    if (!select && type === 'number' && targetValue) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = targetValue.replace(/\D/gm, ''); // ?
    }
    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const targetValue = event.target.value || null;
    if (!select && targetValue && targetValue.endsWith(' ')) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = targetValue.trim();
    }
    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  const [isError, calculatedHelperText] = getHelperTextState();

  return (
    <FormFieldUI
      name={name}
      error={isError}
      helperText={calculatedHelperText}
      type={type}
      select={select}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={InputProps}
      {...props}
    />
  );
};

export default FormFieldBase;
