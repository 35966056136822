import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import { GET_BITRIX_IS_KNOWN_MUTATION } from '@/client/mutations';
import MessageDialog from '@shared/components/MessageDialog';
import { integrationBitrixInstallOptions } from '@/features/Integrations/Integrations.constants';
import ControlButtons from '@shared/components/ControlButtons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import IntegrationIconBitrix from '@shared/assets/images/icons/bitrix24-puzzle.png';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { IIntegrationAuthorizationProps } from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';

export const BitrixCRMAuthorization: FunctionComponent<IIntegrationAuthorizationProps> = ({
  isOpen,
  onClose,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useFormContext();
  const navigate = useNavigate();
  const { watch, setError, clearErrors } = formMethods;
  const bitrixUrl = watch('bitrixUrl');
  const [searchParams] = useSearchParams();
  const sError = searchParams.get('error');
  const [getIsBitrixAppInstalled, { loading, data: bitrixInstallData }] = useMutation(
    GET_BITRIX_IS_KNOWN_MUTATION
  );
  const { isBitrixAppInstalled: { url: bitrixAppUrl = '' } = {} } = bitrixInstallData || {};
  const [errorDialogState, setErrorDialogState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: '',
  });
  const [notInstallDialogShow, setNotInstallDialogShow] = useState(false);

  useEffect(() => {
    if (sError) {
      setErrorDialogState((prevState) => ({
        ...prevState,
        isOpen: true,
        message: 'SOMETHING_WRONG_MESSAGE',
      }));
      onClose?.();
    }
  }, [sError]);

  function handleBitrixAppInstall() {
    if (!bitrixUrl) {
      setError(
        'bitrixUrl',
        { type: 'error', message: translate('BITRIX_EMPTY_URL') },
        { shouldFocus: true }
      );
      return;
    }
    if (
      !validator.isURL(bitrixUrl, {
        protocols: ['http', 'https'],
        allow_trailing_dot: false,
      })
    ) {
      setError(
        'bitrixUrl',
        { type: 'error', message: translate('BITRIX_INVALID_URL') },
        { shouldFocus: true }
      );
      return;
    }
    let bitrix = bitrixUrl;
    if (!bitrixUrl.match(/^https?:\/\//)) {
      bitrix = `https://${bitrix}`;
    }
    getIsBitrixAppInstalled({
      variables: {
        data: {
          bitrix,
        },
      },
    })
      .then((res) => {
        if (res.data && res.data.isBitrixAppInstalled) {
          if (!res.data.isBitrixAppInstalled.confirmed) {
            setNotInstallDialogShow(true);
          } else {
            window.location.href = res.data.isBitrixAppInstalled.url;
          }
        }
      })
      .catch((error) => {
        const errorMessage = getErrorMessageFromGraphqlError(error);
        let message = '';
        if (errorMessage === 'bitrix must be an URL address') {
          message = 'BITRIX_INTEGRATION_ERROR_BAD_URL';
        }
        if (errorMessage === 'Integration already exists') {
          message = 'BITRIX_INTEGRATION_ERROR_EXIST';
        }
        setErrorDialogState((prevState) => ({
          ...prevState,
          isOpen: true,
          message,
        }));
      });
  }

  function handleCloseErrorDialog() {
    setErrorDialogState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    if (sError) {
      navigate('/integrations');
    }
  }

  function handleCloseInstallNotification() {
    setNotInstallDialogShow(false);
  }

  function handleInstallBitrixApp() {
    if (bitrixAppUrl) {
      window.open(bitrixAppUrl);
    }
  }

  return (
    <>
      <MessageDialog
        isOpen={isOpen}
        contentClass={classes.integrationDialog}
        title={translate('INTEGRATION_INSTALL_TITLE')}
        onCancel={() => onClose?.()}
        renderContent={
          <>
            <div className={classes.integrationAuthFieldBlockSimple}>
              <FormFieldRhfUncontrolled
                label={translate('BITRIX_ADDRESS_NAME')}
                name={'bitrixUrl'}
                required
                onChange={() => clearErrors('bitrixUrl')}
              />
            </div>
            <Flex justifyContent={'flexEnd'} alignItems={'flexEnd'}>
              <Button
                className={classes.installCRMButton}
                title={translate('INSTALL_CRM')}
                loading={loading}
                onClick={() => handleBitrixAppInstall()}
                smallHeight
              />
            </Flex>
          </>
        }
      />
      <MessageDialog
        isOpen={errorDialogState.isOpen}
        contentClass={classes.integrationDialog}
        title={translate(errorDialogState.title)}
        message={translate(errorDialogState.message)}
        onCancel={handleCloseErrorDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
        }
      />
      <MessageDialog
        isOpen={notInstallDialogShow}
        contentClass={classes.integrationResponsibleErrorDialog}
        title={translate('INTEGRATION_BITRIX24_TITLE')}
        onCancel={() => handleCloseInstallNotification()}
        renderContent={
          <>
            <div className={classes.integrationIconBig}>
              <img src={IntegrationIconBitrix} alt={''} />
            </div>
            <Flex className={classes.integrationInstallItemContainer} direction={'column'}>
              {integrationBitrixInstallOptions.map((item, index) => (
                <Flex key={`install-note-${index}`} className={classes.integrationInstallItem}>
                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={classes.integrationInstallCircleNumber}
                  >
                    <Typography type={'text3'} color={'tertiary900'}>
                      {index + 1}
                    </Typography>
                  </Flex>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {translate(item)}
                  </Typography>
                </Flex>
              ))}
            </Flex>
            <ControlButtons
              confirmTitle={'INSTALL'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              justifyContent={'center'}
              rootConfirmStyles={classes.defaultElementWidth11}
              onConfirmClick={handleInstallBitrixApp}
              onCancelClick={handleCloseInstallNotification}
              small
            />
          </>
        }
      />
    </>
  );
};

export default memo(BitrixCRMAuthorization);
