import merge from 'lodash.merge';
import responsiveFontSizes, {
  ResponsiveFontSizesOptions,
} from '@material-ui/core/styles/responsiveFontSizes';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import createMyTheme from './createMyTheme';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '50em',
      },
    },
    MuiPickersStaticWrapper: {
      root: {
        minWidth: '15em',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        width: '16em',
        maxHeight: '18.75em',
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiAutocomplete: {
      popper: {
        backgroundColor: 'white',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'unset',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Onest-Regular, sans-serif'],
        /* Overwrite the default to keep the scrollbar always visible */
        '::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          width: '7px',
          height: '7px',
        },
        '::-webkit-scrollbar-thumb': {
          'border-radius': '4px',
          'background-color': 'rgba(0,0,0,.5)',
          '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0.5em 0.5em 0.5em 0',
      },
    },
  },
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    color: {
      base: '#fff',
      primary: {
        50: '#E9FEFE',
        100: '#BFFFFF',
        200: '#8FFCFC',
        300: '#25FDFD',
        400: '#1FE7E7',
        500: '#10D5D7',
        600: '#06C0C2',
        700: '#009D9F',
        800: '#008384',
        900: '#007474',
      },
      secondary: {
        50: '#EEF2FF',
        100: '#E0E7FF',
        200: '#C7D2FE',
        300: '#A5B4FC',
        400: '#818CF8',
        500: '#6366F1',
        600: '#4F46E5',
        700: '#4338CA',
        800: '#3730A3',
        900: '#312E81',
      },
      tertiary: {
        50: '#F6F6FC',
        100: '#EFEFF8',
        200: '#DFDFEE',
        300: '#C9C9D8',
        400: '#9999A8',
        500: '#71717A',
        600: '#52525B',
        700: '#3F3F46',
        800: '#27272A',
        900: '#18181B',
      },
      danger: {
        50: '#FFF1EE',
        100: '#FFE7E7',
        200: '#FFCBC9',
        300: '#FFA5A4',
        400: '#FF7F7E',
        500: '#FF5959',
        600: '#F25454',
        700: '#E34C4E',
        800: '#C64044',
        900: '#AA3339',
      },
      success: {
        50: '#F3FBF9',
        100: '#E6F7F3',
        200: '#CEF0E8',
        300: '#9DE1D1',
        400: '#6CD2B9',
        500: '#3BC3A2',
        600: '#0AB48B',
        700: '#08906F',
        800: '#066C53',
        900: '#044838',
      },
      links: {
        50: '#F2F8FF',
        100: '#E5F1FF',
        200: '#CCE3FE',
        300: '#99C7FD',
        400: '#66ABFC',
        500: '#338FFB',
        600: '#0073FA',
        700: '#005CC8',
        800: '#004596',
        900: '#002E64',
      },
      warning: {
        50: '#FFF8F2',
        100: '#FFF2E6',
        200: '#FFE5CC',
        300: '#FFCA99',
        400: '#FFB067',
        500: '#FF9534',
        600: '#FF7B01',
        700: '#CC5A01',
        800: '#993D01',
        900: '#662500',
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.DARK,

    // Пока здесь все цвета светлой схемы, т.к. темных цветов пока нету
    color: {
      base: '#fff',
      primary: {
        50: '#E9FEFE',
        100: '#BFFFFF',
        200: '#8FFCFC',
        300: '#25FDFD',
        400: '#1FE7E7',
        500: '#10D5D7',
        600: '#06C0C2',
        700: '#009D9F',
        800: '#008384',
        900: '#007474',
      },
      secondary: {
        50: '#EEF2FF',
        100: '#E0E7FF',
        200: '#C7D2FE',
        300: '#A5B4FC',
        400: '#818CF8',
        500: '#6366F1',
        600: '#4F46E5',
        700: '#4338CA',
        800: '#3730A3',
        900: '#312E81',
      },
      tertiary: {
        50: '#F6F6FC',
        100: '#EFEFF8',
        200: '#DFDFEE',
        300: '#C9C9D8',
        400: '#9999A8',
        500: '#71717A',
        600: '#52525B',
        700: '#3F3F46',
        800: '#27272A',
        900: '#18181B',
      },
      danger: {
        50: '#FFF1EE',
        100: '#FFE7E7',
        200: '#FFCBC9',
        300: '#FFA5A4',
        400: '#FF7F7E',
        500: '#FF5959',
        600: '#F25454',
        700: '#E34C4E',
        800: '#C64044',
        900: '#AA3339',
      },
      success: {
        50: '#F3FBF9',
        100: '#E6F7F3',
        200: '#CEF0E8',
        300: '#9DE1D1',
        400: '#6CD2B9',
        500: '#3BC3A2',
        600: '#0AB48B',
        700: '#08906F',
        800: '#066C53',
        900: '#044838',
      },
      links: {
        50: '#F2F8FF',
        100: '#E5F1FF',
        200: '#CCE3FE',
        300: '#99C7FD',
        400: '#66ABFC',
        500: '#338FFB',
        600: '#0073FA',
        700: '#005CC8',
        800: '#004596',
        900: '#002E64',
      },
      warning: {
        50: '#FFF8F2',
        100: '#FFF2E6',
        200: '#FFE5CC',
        300: '#FFCA99',
        400: '#FFB067',
        500: '#FF9534',
        600: '#FF7B01',
        700: '#CC5A01',
        800: '#993D01',
        900: '#662500',
      },
    },
    shadows: strongShadows,
  },
];

const customOptions = {
  gradients: {
    main: '#27272A',
  },
  backgrounds: {
    dark: {
      main: '#27272A',
      dark: '#503c50',
      light: '#54485A',
    },
  },
};

export function createTheme(
  {
    theme,
    direction,
    fontSizesOptions,
  }: {
    theme: string;
    direction?: 'ltr' | 'rtl' | undefined;
    fontSizesOptions?: ResponsiveFontSizesOptions;
  } = { theme: THEMES.LIGHT }
) {
  let themeConfig = themeConfigs.find(({ name }) => name === theme);

  if (!themeConfig) {
    // eslint-disable-next-line
    console.warn(new Error(`The theme ${theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  // TODO: fix typings
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let result = createMyTheme(merge({}, baseConfig, { direction }, themeConfig), customOptions);

  if (fontSizesOptions) {
    result = responsiveFontSizes(result, fontSizesOptions);
  }

  return result;
}
