import { useEffect } from 'react';
import { DomainStatus } from '@components/typings/interfaces';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { getCurrentDomain } from '@/utils/getCurrentDomain';

export const useDisabledDomainStatus = (disabledStatuses: DomainStatus[]): void => {
  const navigate = useNavigate();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const status = getCurrentDomain(userData?.user)?.status || DomainStatus.Unknown;

  useEffect(() => {
    if (disabledStatuses && disabledStatuses.indexOf(status) >= 0) {
      navigate('/');
    }
  }, [navigate, status, disabledStatuses]);
};

export const useEnabledDomainStatus = (EnabledStatuses: DomainStatus[]): void => {
  const navigate = useNavigate();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const status = getCurrentDomain(userData?.user)?.status || DomainStatus.Unknown;

  useEffect(() => {
    if (status !== DomainStatus.Unknown && EnabledStatuses && EnabledStatuses.indexOf(status) < 0) {
      navigate('/');
    }
  }, [navigate, status, EnabledStatuses]);
};
