import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchField from '@shared/components/SwitchField';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { UPDATE_DOMAIN_MUTATION } from '@/client/mutations';
import Typography from '@shared/components/Typography';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { useSettingsPagesStyles } from '../SettingsPage.styles';
import { SettingsPageTemplate } from '../SettingsPageTemplate';

interface IFormFields {
  blf?: boolean;
}

export const SettingsBLF: FunctionComponent = () => {
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const [updateDomain, { loading }] = useMutation(UPDATE_DOMAIN_MUTATION, {
    refetchQueries: [{ query: USER_QUERY }],
  });
  const [translate] = useTranslation();
  const classes = useSettingsPagesStyles();
  const navigate = useNavigate();
  const formMethods = useForm<IFormFields>();
  const domain = userData && getCurrentDomain(userData.user);
  const { handleSubmit, watch, reset } = formMethods;
  const isBlfChecked = watch('blf');
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [state, setState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  useEffect(() => {
    if (domain) {
      reset({ blf: domain?.settings.blf });
    }
  }, [domain, domain?.settings.blf, reset]);

  useEffect(() => {
    setState({
      isBlocking: domain?.settings.blf !== isBlfChecked,
      action: ConfirmAction.Edit,
    });
  }, [isBlfChecked, domain?.settings.blf]);

  useEffect(() => {
    if (state.action === ConfirmAction.Cancel && !state.isBlocking) {
      navigate('/settings');
    }
  }, [state, navigate]);

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleBLFFormSubmit(submitData: IFormFields) {
    if (loading) return;
    setState({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    updateDomain({
      variables: {
        data: {
          settings: { ...submitData },
        },
      },
    }).then(() => {
      navigate(blockedPath || '/settings');
    });
  }

  function handleCancelChanges() {
    setState({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  }

  if (!userData) {
    return null;
  }

  return (
    <SettingsPageTemplate
      form={'settings-blf'}
      title={'BLF'}
      onCancel={handleCancelChanges}
      isLoading={loading}
    >
      <div className={classes.description}>
        <Typography color={'tertiary900'} type={'text3'}>
          {translate('SETTING_BLF_DESCRIPTION')}
        </Typography>
      </div>
      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleBLFFormSubmit)}
          id={'settings-blf'}
        >
          <SwitchField name={'blf'} label={isBlfChecked ? translate('ON') : translate('OFF')} />
        </form>
        <ConfirmDialog
          isBlocked={state.isBlocking}
          onNavigationBlocked={getBlockedPath}
          onSaveChanges={handleSubmit(handleBLFFormSubmit)}
        />
      </FormProvider>
    </SettingsPageTemplate>
  );
};
