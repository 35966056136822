import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { IButtonGroupSwitchProps } from './ButtonGroupSwitch.interfaces';
import { useButtonGroupSwitchStyle } from './ButtonGroupSwitch.styles';

export const ButtonGroupSwitch: FunctionComponent<IButtonGroupSwitchProps> = ({
  data,
  onClick,
  defaultValue,
}) => {
  const buttonsRefList = useRef<Array<HTMLDivElement | null>>([]);
  const classes = useButtonGroupSwitchStyle();
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedItemValues, setSelectedItemValues] = useState<{ width: number; left: number }>({
    width: 0,
    left: 0,
  });
  const { width, left } = selectedItemValues;

  const setSelectedElementValues = useCallback(() => {
    if (selectedItem !== null) {
      const selectedRef = buttonsRefList.current;
      if (
        selectedRef &&
        selectedRef[selectedItem] &&
        width !== selectedRef[selectedItem]?.clientWidth
      ) {
        setSelectedItemValues({
          width: buttonsRefList.current[selectedItem]?.clientWidth || 0,
          left: buttonsRefList.current[selectedItem]?.offsetLeft || 0,
        });
      }
    } else {
      setSelectedItemValues({ width: 0, left: 0 });
    }
  }, [selectedItem, width, buttonsRefList]);

  useEffect(() => {
    window.addEventListener('resize', setSelectedElementValues);
    return () => {
      window.removeEventListener('resize', setSelectedElementValues);
    };
  }, [setSelectedElementValues]);

  useEffect(() => {
    setSelectedElementValues();
  });

  useEffect(() => {
    if (!selectedItem && selectedItem !== 0) {
      const defaultSelectedArrayItem = data.find((itm) => itm.value === defaultValue);
      const defaultSelectedIndex = defaultSelectedArrayItem
        ? data.indexOf(defaultSelectedArrayItem)
        : null;
      setSelectedItem(defaultSelectedIndex || 0);
    }
  }, [data, defaultValue, selectedItem]);

  function handleButtonClick(value: string | number, index: number) {
    setSelectedItem(index);
    onClick(value, index);
  }

  const renderButtonsList = () =>
    data.map((buttonElement, index) => {
      const { value, title } = buttonElement;
      const setRefList = (el: HTMLDivElement | null, ind: number) => {
        buttonsRefList.current[ind] = el;
        return buttonsRefList;
      };
      return (
        <div
          className={classes.buttonGroupContainer}
          ref={(el) => setRefList(el, index)}
          key={`buttonSwitch-${value}-${index}`}
        >
          <Button
            disableRipple
            disableElevation
            clear
            onClick={() => handleButtonClick(value, index)}
          >
            <Typography className={classes.buttonGroupItem} color={'tertiary900'} type={'text4'}>
              {title}
            </Typography>
          </Button>
        </div>
      );
    });

  return (
    <Flex className={classes.root}>
      <div style={{ width, left }} className={classes.buttonGroupSelectedBackground} />
      {renderButtonsList()}
    </Flex>
  );
};

export default ButtonGroupSwitch;
