import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ChevronDownIcon, ChevronUpIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import BodyContainer from '@/layouts/BodyContainer';
import { useSettingsPagesStyles } from '../SettingsPage.styles';
import { settingToneBlocks } from './SettingsTone.list';
import SettingsToneContent from './SettingsTone.content';

export const SettingsTone: FunctionComponent = () => {
  const classes = useSettingsPagesStyles();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [translate] = useTranslation();

  const handleChange = (panel: string) => (event: ChangeEvent<unknown>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <BodyContainer>
      <div className={classes.toneWrapper}>
        {settingToneBlocks.map((blockname, index) => {
          const currentExpanded = expanded === `panel-${index}`;

          return (
            <MuiAccordion
              classes={{ root: classes.accordion }}
              square
              expanded={expanded === `panel-${index}`}
              onChange={handleChange(`panel-${index}`)}
              key={index}
            >
              <MuiAccordionSummary
                classes={{
                  root: classes.accordionSumRoot,
                  content: classes.accordionTitle,
                  expanded: classes.accordionTitleExpanded,
                }}
                aria-controls="panel1d-content"
                id={`panel-${index}`}
              >
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate(blockname)}
                </Typography>
                {currentExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </MuiAccordionSummary>
              <MuiAccordionDetails classes={{ root: classes.accordionBody }}>
                <SettingsToneContent name={blockname} />
              </MuiAccordionDetails>
            </MuiAccordion>
          );
        })}
      </div>
    </BodyContainer>
  );
};
