import React from 'react';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { ResultOptionsType } from '@components/typings/interfaces';
import { CheckIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import { useTariffFormStyle } from './TariffForm.styles';

type Props = {
  optionsList: ResultOptionsType[] | null;
};

export const TariffIncludedOptionsList = ({ optionsList }: Props) => {
  const [translate] = useTranslation();
  const classes = useTariffFormStyle();

  const formatOptionText = (optionText: string) => {
    const textParts = optionText.split('<b>');
    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {textParts[0]}
        {textParts[1] && (
          <Typography bold type={'default'} color={'tertiary900'}>
            {textParts[1].replace('</b>', '')}
          </Typography>
        )}
      </Typography>
    );
  };

  const renderIncludedTariffOptions = () => {
    if (!optionsList) return null;

    return (
      <>
        <div className={classes.tariffTitle}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('PBX_BASE_FUNCTIONAL')}
          </Typography>
        </div>
        <Flex className={classes.tariffIncludedOptionWrapper} direction={'column'}>
          {optionsList[0]?.options.map((includedOption: string, index: number) => (
            <div key={`${includedOption}-${index}`} className={classes.tariffIncludedLine}>
              <CheckIcon className={classes.tariffLIIcon} />
              {formatOptionText(includedOption)}
            </div>
          ))}
        </Flex>
      </>
    );
  };

  return renderIncludedTariffOptions();
};

export default TariffIncludedOptionsList;
