export const newClientDelaysOptions = [
  { value: 1, titleCode: 'ONE_DAY' },
  { value: 7, titleCode: 'ONE_WEEK' },
  { value: 14, titleCode: 'TWO_WEEK' },
  { value: 31, titleCode: 'ONE_MONTH' },
  { value: 62, titleCode: 'TWO_MONTH' },
  { value: 186, titleCode: 'SIX_MONTH' },
];

export const DEFAULT_NEW_CLIENT = 31;
export const MAX_MESSAGE_LENGTH = 402;
