import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthForm } from '@/layouts/Auth/AuthForm/AuthForm';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPasswordPage: FunctionComponent = () => {
  const [translate] = useTranslation();

  return <AuthForm heading={translate('ENTER_NEW_PASSWORD')} content={<ResetPasswordForm />} />;
};
