import React, { FunctionComponent } from 'react';
import { Diagram } from '@components/Diagram';
import { ChartConfiguration, ChartType, InteractionItem } from 'chart.js';

export type BarDiagramConfiguration = Pick<ChartConfiguration<ChartType>, 'options' | 'data'> & {
  onClick?: (points: InteractionItem[]) => void;
};

export const BarDiagram: FunctionComponent<BarDiagramConfiguration> = ({
  options,
  data,
  onClick,
}) => <Diagram type={'bar'} options={options} data={data} onClick={onClick} />;

export default BarDiagram;
