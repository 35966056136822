import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useForgotPasswordDialogStyles = makeStyles(
  () => ({
    form: {
      marginTop: '1em',
    },
    message: {
      height: 'auto',
      marginTop: '.125em',
      textAlign: 'left',
    },
    hidden: {
      display: 'none',
      height: 0,
    },
    ...styleConstants,
  }),
  {
    index: 3,
  }
);
