/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { ICountryData } from './phoneNumbers.interfaces';

export const PHONE_CODES: { [key: string]: ICountryData } = {
  '1': {
    cityCodeLength: 3,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'USA',
  },
  '1246': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Barbados',
  },
  '1264': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Anguilla',
  },
  '1268': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'AntiguaAndBarbuda',
  },
  '1284': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Bahamas',
  },
  '1340': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'USVirginIslands',
  },
  '1345': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'CaymanIslands',
  },
  '1441': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Bermuda',
  },
  '1473': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Grenada',
  },
  '1649': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'TurksCaicos',
  },
  '1664': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Montserrat',
  },
  '1670': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'CommonwealthOfTheNorthernMarianaIslands',
  },
  '1671': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Guam',
  },
  '1758': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'St.Lucia',
  },
  '1767': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Dominica',
  },
  '1784': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'St.VincentAndTheGrenadines',
  },
  '1787': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'PuertoRico',
  },
  '1809': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Dominican',
  },
  '1868': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'TrinidadAndTobago',
  },
  '1869': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'St.KittsAndNevis',
  },
  '1876': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Jamaica',
  },
  '1907': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Alaska',
  },
  '20': {
    cityCodeLength: 2,
    exceptions: [2, 3, 1221],
    exceptions_max: 4,
    exceptions_min: 1,
    name: 'Egypt',
  },
  '21': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Algeria',
  },
  '212': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Morocco',
  },
  '216': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Tunisia',
  },
  '218': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Libya',
  },
  '220': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Gambia',
  },
  '221': {
    cityCodeLength: 3,
    exceptions: [63, 64, 67, 68, 82, 83, 84, 85, 86, 87, 90, 93, 94, 95, 96, 97, 98, 99],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Senegal',
  },
  '222': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Mauritania',
  },
  '223': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Mali',
  },
  '224': {
    cityCodeLength: 2,
    exceptions: [4],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Guinea',
  },
  '225': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Ivory',
  },
  '226': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'BurkinaFaso',
  },
  '227': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Niger',
  },
  '228': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Togolese',
  },
  '229': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Benin',
  },
  '230': {
    cityCodeLength: 3,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Mauritius',
  },
  '231': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Liberia',
  },
  '232': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'SierraLeone',
  },
  '233': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Ghana',
  },
  '234': {
    cityCodeLength: 2,
    exceptions: [1, 2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Nigeria',
  },
  '235': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Chad',
  },
  '236': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'CentralAfricanRepublic',
  },
  '237': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Cameroon',
  },
  '238': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'CapeVerde',
  },
  '239': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'SaoTome-e-Principe',
  },
  '240': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'EquatorialGuinea',
  },
  '241': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'GaboneseRepublic',
  },
  '242': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Congo',
  },
  '243': {
    cityCodeLength: 2,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'DemocraticRepublic(ex.Zaire)',
  },
  '244': {
    cityCodeLength: 2,
    exceptions: [9],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Angola',
  },
  '245': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Guinea-Bissau',
  },
  '246': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'DiegoGarcia',
  },
  '247': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Ascension',
  },
  '248': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Seychelles',
  },
  '249': {
    cityCodeLength: 3,
    exceptions: [21, 51, 41, 31, 61, 11],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Sudan',
  },
  '250': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'RwandeseRepublic',
  },
  '251': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Ethiopia',
  },
  '252': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Somalia',
  },
  '253': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Djibouti',
  },
  '254': {
    cityCodeLength: 3,
    exceptions: [11, 2, 37],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Kenya',
  },
  '255': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Tanzania',
  },
  '256': {
    cityCodeLength: 2,
    exceptions: [481, 485, 493],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Uganda',
  },
  '257': {
    cityCodeLength: 2,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Burundi',
  },
  '258': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Mozambique',
  },
  '259': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Zanzibar',
  },
  '260': {
    cityCodeLength: 1,
    exceptions: [26],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Zambia',
  },
  '261': {
    cityCodeLength: 3,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Madagascar',
  },
  '262': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'ReunionIslands',
  },
  '263': {
    cityCodeLength: 2,
    exceptions: [9, 4, 637, 718],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Zimbabwe',
  },
  '264': {
    cityCodeLength: 2,
    exceptions: [811, 812, 813],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Namibia',
  },
  '265': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Malawi',
  },
  '266': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Lesotho',
  },
  '267': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Botswana',
  },
  '268': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Swaziland',
  },
  '269': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'ComorosAndMayotteIsland',
  },
  '27': {
    cityCodeLength: 2,
    exceptions: [149, 1782, 1773, 444],
    exceptions_max: 4,
    exceptions_min: 3,
    name: 'SouthAfrica',
  },
  '290': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'St.Helena',
  },
  '291': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Eritrea',
  },
  '297': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Aruba',
  },
  '298': {
    cityCodeLength: 0,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'FaeroeIslands',
  },
  '299': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Greenland',
  },
  '30': {
    cityCodeLength: 3,
    exceptions: [1, 41, 81, 51, 61, 31, 71, 93, 94, 95, 97556, 97557],
    exceptions_max: 5,
    exceptions_min: 1,
    name: 'Greece',
  },
  '31': {
    cityCodeLength: 3,
    exceptions: [
      4160, 2268, 2208, 5253, 78, 72, 33, 20, 55, 26, 35, 74, 76, 40, 77, 10, 70, 75, 73, 38, 50,
      15, 30, 58, 43, 24, 46, 13, 23, 45, 53, 61, 62, 65,
    ],
    exceptions_max: 4,
    exceptions_min: 2,
    name: 'Netherlands',
  },
  '32': {
    cityCodeLength: 2,
    exceptions: [2, 9, 7, 3, 476, 477, 478, 495, 496],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Belgium',
  },
  '33': {
    cityCodeLength: 3,
    exceptions: [32, 14, 38, 59, 55, 88, 96, 28, 97, 42, 61],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'France',
  },
  '3395': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Corsica',
  },
  '34': {
    cityCodeLength: 3,
    exceptions: [4, 6, 3, 5, 96, 93, 94, 91, 95, 98],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Spain',
  },
  '3428': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Canary',
  },
  '350': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Gibraltar',
  },
  '351': {
    cityCodeLength: 2,
    exceptions: [1, 2, 96, 676, 765, 96765],
    exceptions_max: 5,
    exceptions_min: 1,
    name: 'Azores',
  },
  '352': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Luxembourg',
    zeroHack: true,
  },
  '353': {
    cityCodeLength: 2,
    exceptions: [1, 402, 507, 902, 905, 509, 502, 903, 506, 504, 404, 405],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'IrishRepublic',
  },
  '354': {
    cityCodeLength: 3,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Iceland',
  },
  '355': {
    cityCodeLength: 3,
    exceptions: [65, 62, 52, 64, 82, 7426, 42, 63],
    exceptions_max: 4,
    exceptions_min: 2,
    name: 'Albania',
  },
  '356': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Malta',
  },
  '357': {
    cityCodeLength: 2,
    exceptions: [2, 3, 91, 92, 93, 94, 95, 96, 98],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Cyprus',
  },
  '358': {
    cityCodeLength: 2,
    exceptions: [6, 5, 2, 8, 9, 3],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Finland',
  },
  '359': {
    cityCodeLength: 3,
    exceptions: [
      2, 56, 62, 94, 92, 52, 32, 76, 64, 84, 82, 44, 42, 38, 46, 5722, 73, 66, 58, 68, 34, 86, 54,
      6071, 7443, 5152, 7112, 7128, 9744, 9527, 5731, 8141, 3041, 6514, 6151, 3071, 9131, 7142,
      3145, 8362, 3751, 6191, 9171, 2031, 7181, 6141, 7133, 5561, 3542, 3151, 3561, 7481, 3181,
      5514, 3134, 6161, 4761, 5751, 3051,
    ],
    exceptions_max: 4,
    exceptions_min: 1,
    name: 'Bulgaria',
  },
  '36': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Hungary',
  },
  '370': {
    cityCodeLength: 3,
    exceptions: [5, 37, 46, 45, 41],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Lithuania',
  },
  '371': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Latvia',
  },
  '372': {
    cityCodeLength: 2,
    exceptions: [2, 7],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Estonia',
  },
  '373': {
    cityCodeLength: 2,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Moldova',
  },
  '374': {
    cityCodeLength: 2,
    exceptions: [1, 460, 520, 4300, 680, 860, 830, 550, 490, 570],
    exceptions_max: 4,
    exceptions_min: 1,
    name: 'Armenia',
  },
  '375': {
    cityCodeLength: 4,
    exceptions: [17, 163, 162, 232, 222],
    exceptions_max: 3,
    exceptions_min: 2,
    name: 'Belarus',
  },
  '376': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Andorra',
  },
  '377': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Monaco',
  },
  '378': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'RepublicOfSanMarino',
  },
  '380': {
    cityCodeLength: 4,
    exceptions: [
      44, 432, 1762, 562, 622, 412, 522, 564, 53615, 642, 322, 448, 629, 512, 482, 532, 3355, 1821,
      403, 222, 1852, 356, 3371, 267, 3443, 1694, 1965, 3058, 1627, 3385, 3356, 2718, 3370, 3260,
      3231, 2785, 309, 2857, 2957, 2911, 294, 1705, 3, 295, 3250, 3387, 2523, 3246, 2674, 1854,
      3433, 1711, 251, 2958, 2477, 2984, 307, 542, 352, 572, 552, 382, 472, 462, 654,
    ],
    exceptions_max: 5,
    exceptions_min: 1,
    name: 'Ukraine',
    zeroHack: true,
  },
  '381': {
    cityCodeLength: 2,
    exceptions: [230],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Yugoslavia',
  },
  '385': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Croatia',
  },
  '386': {
    cityCodeLength: 2,
    exceptions: [608, 602, 601],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Slovenia',
  },
  '387': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'BosniaAndHerzegovina',
  },
  '389': {
    cityCodeLength: 2,
    exceptions: [903, 901, 902],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Macedonia',
  },
  '39': {
    cityCodeLength: 3,
    exceptions: [
      71, 80, 35, 51, 30, 15, 41, 45, 33, 70, 74, 95, 31, 90, 2, 59, 39, 81, 49, 75, 85, 50, 6, 19,
      79, 55, 330, 333, 335, 339, 360, 347, 348, 349,
    ],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Italy',
    zeroHack: true,
  },
  '40': {
    cityCodeLength: 2,
    exceptions: [1, 941, 916, 981],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Romania',
  },
  '41': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Switzerland',
  },
  '4175': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Liechtenstein',
  },
  '420': {
    cityCodeLength: 3,
    exceptions: [5, 49, 67, 66, 17, 48, 35, 68, 69, 40, 19, 2, 47, 38],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'CzechRepublic',
  },
  '421': {
    cityCodeLength: 3,
    exceptions: [7, 89, 95, 92, 91],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'SlovakRepublic',
  },
  '43': {
    cityCodeLength: 4,
    exceptions: [1, 662, 732, 316, 512, 463],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Austria',
  },
  '44': {
    cityCodeLength: 4,
    exceptions: [
      21, 91, 44, 41, 51, 61, 31, 121, 117, 141, 185674, 18383, 15932, 116, 151, 113, 171, 181, 161,
      207, 208, 158681, 115, 191, 177681, 114, 131, 18645,
    ],
    exceptions_max: 6,
    exceptions_min: 2,
    name: 'UnitedKingdom',
  },
  '45': {
    cityCodeLength: 2,
    exceptions: [
      9, 6, 7, 8, 1, 5, 3, 4, 251, 243, 249, 276, 70777, 80827, 90107, 90207, 90417, 90517,
    ],
    exceptions_max: 5,
    exceptions_min: 1,
    name: 'Denmark',
  },
  '46': {
    cityCodeLength: 3,
    exceptions: [33, 21, 31, 54, 44, 13, 46, 40, 19, 63, 8, 60, 90, 18, 42],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Sweden',
  },
  '47': {
    cityCodeLength: 1,
    exceptions: [43, 83, 62],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Norway',
  },
  '48': {
    cityCodeLength: 2,
    exceptions: [
      192, 795, 862, 131, 135, 836, 115, 604, 641, 417, 601, 602, 603, 605, 606, 501, 885,
    ],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Poland',
  },
  '49': {
    cityCodeLength: 4,
    exceptions: [
      651, 241, 711, 981, 821, 30, 971, 671, 921, 951, 521, 228, 234, 531, 421, 471, 961, 281, 611,
      365, 40, 511, 209, 551, 641, 34202, 340, 351, 991, 771, 906, 231, 203, 211, 271, 911, 212,
      841, 631, 721, 561, 221, 831, 261, 341, 871, 491, 591, 451, 621, 391, 291, 89, 395, 5021, 571,
      441, 781, 208, 541, 69, 331, 851, 34901, 381, 33638, 751, 681, 861, 581, 731, 335, 741, 461,
      761, 661, 345, 481, 34203, 375, 385, 34204, 361, 201, 33608, 161, 171, 172, 173, 177, 178,
      179,
    ],
    exceptions_max: 5,
    exceptions_min: 2,
    name: 'Germany',
  },
  '500': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Falkland',
  },
  '501': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Belize',
  },
  '502': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Guatemala',
  },
  '503': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'ElSalvador',
  },
  '504': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Honduras',
  },
  '505': {
    cityCodeLength: 3,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Nicaragua',
  },
  '506': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Costa',
  },
  '507': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Panama',
  },
  '508': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'SaintPierreEtMiquelon',
  },
  '509': {
    cityCodeLength: 1,
    exceptions: [330, 420, 510, 851],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Haiti',
  },
  '51': {
    cityCodeLength: 2,
    exceptions: [1, 194, 198, 193, 190, 1877, 1878, 1879],
    exceptions_max: 4,
    exceptions_min: 1,
    name: 'Peru',
  },
  '52': {
    cityCodeLength: 2,
    exceptions: [473, 181, 981, 112, 331, 5, 8, 951, 771, 492, 131, 246, 961, 459, 747],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Mexico',
  },
  '53': {
    cityCodeLength: 2,
    exceptions: [680, 5, 8, 7, 686, 322, 419, 433, 335, 422, 692, 516, 226],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Cuba',
  },
  '54': {
    cityCodeLength: 4,
    exceptions: [291, 11, 297, 223, 261, 299, 358, 341, 387, 381, 342],
    exceptions_max: 3,
    exceptions_min: 2,
    name: 'Argentina',
  },
  '55': {
    cityCodeLength: 2,
    exceptions: [
      243, 187, 485, 186, 246, 533, 173, 142, 473, 125, 495, 138, 482, 424, 192, 247, 484, 144, 442,
      532, 242, 245, 194, 182, 123, 474, 486,
    ],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Brazil',
  },
  '56': {
    cityCodeLength: 2,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Chile',
  },
  '57': {
    cityCodeLength: 2,
    exceptions: [1, 5, 7, 2, 4, 816],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Colombia',
  },
  '58': {
    cityCodeLength: 2,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Venezuela',
  },
  '590': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'FrenchAntilles',
  },
  '591': {
    cityCodeLength: 3,
    exceptions: [69, 4, 2, 92, 52, 3, 46],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Bolivia',
  },
  '592': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Guyana',
  },
  '593': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Ecuador',
  },
  '594': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'FrenchGuiana',
  },
  '595': {
    cityCodeLength: 2,
    exceptions: [541, 521],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'Paraguay',
  },
  '596': {
    cityCodeLength: 0,
    exceptions: [
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 38, 39, 40, 41, 42, 43, 44, 45,
      46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
      69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
    ],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Martinique',
  },
  '597': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Suriname',
  },
  '598': {
    cityCodeLength: 3,
    exceptions: [42, 2],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Uruguay',
  },
  '599': {
    cityCodeLength: 1,
    exceptions: [46],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'NetherlandsAntilles',
  },
  '60': {
    cityCodeLength: 1,
    exceptions: [86, 88, 82, 85, 10, 18],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Malaysia',
  },
  '61': {
    cityCodeLength: 1,
    exceptions: [14, 15, 16, 17, 18, 19, 41],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Australia',
  },
  '62': {
    cityCodeLength: 3,
    exceptions: [22, 61, 21, 33, 36, 39, 35, 34, 24, 31, 81, 82],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Indonesia',
  },
  '63': {
    cityCodeLength: 2,
    exceptions: [455, 4661, 2150, 2155, 452, 2],
    exceptions_max: 4,
    exceptions_min: 1,
    name: 'Philippines',
  },
  '64': {
    cityCodeLength: 1,
    exceptions: [20, 21, 25, 26, 29],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'NewZealand',
  },
  '65': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Singapore',
  },
  '66': {
    cityCodeLength: 2,
    exceptions: [2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Thailand',
  },
  '670': {
    cityCodeLength: 0,
    exceptions: [2348],
    exceptions_max: 4,
    exceptions_min: 4,
    name: 'NorthernMarianaIslands',
  },
  '671': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Guam',
  },
  '672': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'ChristmasIsland',
  },
  '6723': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Norfolk',
  },
  '673': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Brunei',
  },
  '674': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Nauru',
  },
  '675': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'PapuaNewGuinea',
  },
  '676': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Tonga',
  },
  '677': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'SolomonIslands',
  },
  '678': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Vanuatu',
  },
  '679': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Fiji',
  },
  '680': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Palau',
  },
  '681': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'WallisAndFutuna',
  },
  '682': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'CookIslands',
  },
  '683': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'NiueIslands',
  },
  '684': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'AmericanSamoa',
  },
  '685': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'WesternSamoa',
  },
  '686': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Kiribati',
  },
  '687': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'NewCaledonia',
  },
  '688': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Tuvalu',
  },
  '689': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'FrenchPolynesia',
  },
  '690': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Tokelau',
  },
  '691': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Micronesia',
  },
  '692': {
    cityCodeLength: 1,
    exceptions: [873],
    exceptions_max: 3,
    exceptions_min: 3,
    name: 'MarshallIslands',
  },
  '7': {
    cityCodeLength: 5,
    exceptions: [
      39022, 3916322, 35355, 86150, 35140, 30239, 39142, 35365, 862, 87772, 85511, 86342, 82134,
      35335, 86350, 34993, 44, 30231, 34758, 86731, 34346, 83531, 41145, 38553, 49158, 49244, 84578,
      35359, 424340, 42434, 86557, 38255, 47234, 84671, 84446, 48753, 83535, 34150, 38537, 35242,
      8553, 41142, 42665, 42142, 42722, 86133, 3951, 39518, 48267, 38453, 42441, 81376, 49430,
      42362, 81555, 86152, 83330, 35132, 87147, 83431, 86732, 86560, 84542, 86137, 34547, 42361,
      48733, 34363, 34395, 818, 8182, 34774, 48744, 86739, 34365, 35351, 38241, 39045, 34771, 8512,
      84552, 83454, 83434, 85141, 39151, 34391, 87142, 35159, 81743, 87247, 48448, 85569, 86357,
      40156, 38515, 84591, 84240, 34362, 39542, 34751, 34742, 86634, 48438, 8453, 39148, 84545,
      34166, 30232, 84592, 40145, 30131, 3852, 84253, 48454, 86354, 81661, 83532, 84143, 84151,
      81141, 48231, 84676, 84141, 47149, 86154, 86313, 83364, 4722, 34716, 48742, 84153, 38452,
      41641, 81756, 35232, 38577, 81437, 34792, 86155, 34377, 48250, 38258, 39041, 35334, 41131,
      34554, 81831, 39175, 34674, 38445, 34369, 42733, 86737, 84140, 48457, 39044, 34743, 3854,
      42155, 42738, 42622, 34714, 30133, 38564, 4162, 34766, 86549, 34376, 49451, 48761, 42151,
      83333, 39157, 39034, 39162, 86312, 81366, 48238, 48640, 84673, 86395, 39118, 84672, 38169,
      34770, 83436, 83442, 84245, 48542, 47136, 34550, 39159, 84747, 47534, 47246, 48539, 81664,
      41161, 39168, 84667, 3953, 48545, 86156, 4832, 85594, 35352, 86559, 34773, 35342, 49435,
      34756, 38572, 84495, 38571, 34155, 84142, 81666, 47236, 42137, 35233, 87131, 47237, 48132,
      81153, 8162, 81622, 81738, 81836, 48745, 30264, 41133, 35143, 34762, 34748, 49349, 35164,
      34368, 34345, 38444, 81759, 48676, 34319, 83335, 86358, 48264, 84243, 82131, 34557, 41132,
      49354, 423, 8672, 4922, 81744, 8442, 86392, 49453, 83631, 8443, 47473, 48768, 8172, 85142,
      49636, 47235, 81373, 81662, 81363, 38565, 42334, 84593, 82151, 473, 49644, 84568, 34145,
      49450, 81370, 82146, 83537, 81378, 48341, 82130, 86157, 81746, 48233, 42153, 49233, 48131,
      83334, 49355, 48534, 47551, 48135, 35362, 49437, 38539, 81371, 40159, 81131, 86141, 87951,
      49447, 87779, 34141, 48675, 48165, 47132, 35239, 49633, 34546, 48340, 38822, 84577, 38516,
      84468, 84158, 84731, 49238, 47133, 38157, 86159, 47261, 34163, 35344, 86540, 8712, 47461,
      81755, 47241, 87152, 86361, 86160, 40151, 38463, 40143, 30145, 49241, 34768, 35252, 42356,
      48538, 84461, 47465, 83336, 34151, 81136, 48147, 81651, 87240, 84563, 48153, 39167, 39144,
      86555, 86733, 84235, 86162, 47150, 47536, 49622, 48649, 81135, 47462, 47463, 35144, 48672,
      47468, 42442, 35367, 49679, 86368, 86546, 48746, 48144, 83447, 49621, 48732, 84458, 86377,
      48332, 30256, 48447, 84573, 48166, 34717, 48333, 86370, 49640, 86132, 343, 84554, 41652,
      84452, 47467, 41531, 83444, 48146, 38593, 35138, 82139, 39133, 39115, 85143, 39138, 34741,
      49144, 48155, 84564, 8793, 87934, 87961, 35145, 48741, 87932, 39197, 391987, 391988, 391989,
      47148, 39566, 47535, 84862, 48445, 844542, 84454, 48344, 48432, 48334, 49648, 30251, 86378,
      41636, 34542, 49333, 38562, 47471, 30136, 30137, 39512, 48648, 38592, 39165, 81556, 48734,
      48265, 81554, 49666, 8412, 38595, 49153, 83645, 49632, 86355, 35240, 39169, 40150, 86552,
      87878, 84155, 86359, 41658, 39514, 86376, 35136, 48358, 38517, 48645, 48662, 47552, 85140,
      87132, 38179, 47151, 38530, 83458, 48262, 83337, 41155, 38243, 83538, 41649, 4932, 493, 84579,
      47243, 30140, 34795, 34134, 39135, 3412, 82140, 38459, 47478, 48449, 86545, 84732, 85144,
      39173, 35337, 84467, 81843, 49353, 47553, 84241, 83449, 82145, 86542, 39174, 34355, 3952,
      84654, 34537, 38173, 84144, 49631, 34715, 84254, 34551, 34794, 8362, 42375, 86193, 86345,
      49139, 83448, 81742, 49442, 34553, 843, 39562, 39116, 84472, 38155, 30249, 81454, 4012, 84549,
      86163, 38551, 34779, 4842, 48249, 34153, 84156, 86360, 3439, 86365, 41546, 38514, 42349,
      49248, 85145, 84457, 34375, 83533, 81533, 86164, 39161, 35153, 34744, 34132, 39137, 87879,
      48335, 35256, 38253, 81841, 48167, 34765, 34313, 81856, 84246, 35133, 30234, 49131, 35149,
      47157, 35147, 35251, 34341, 86318, 48234, 49669, 86636, 35364, 39192, 39193, 39194, 39195,
      34158, 3842, 83433, 48274, 35231, 84735, 30141, 35155, 87239, 34154, 83341, 83643, 83338,
      48735, 48236, 81375, 84663, 84660, 49331, 34718, 48754, 39568, 49237, 81757, 81368, 48456,
      8332, 34357, 83361, 81531, 81362, 42354, 83339, 47537, 38464, 87937, 81740, 34133, 48338,
      84466, 48347, 49676, 49624, 48336, 38578, 84653, 81535, 49232, 83453, 34667, 39143, 38244,
      48442, 83534, 39154, 83632, 82132, 81553, 49443, 49661, 38160, 38254, 48674, 84146, 49245,
      48355, 49352, 4217, 84743, 83539, 48242, 34677, 84147, 81451, 48434, 81858, 41639, 86393,
      47156, 35139, 39036, 49143, 47147, 86142, 35152, 38170, 47231, 42435, 48667, 38531, 81459,
      4942, 84476, 83342, 81837, 84455, 47541, 83439, 86550, 38842, 87770, 84650, 38446, 48346,
      49236, 34776, 48663, 84550, 84675, 83530, 47247, 86541, 81137, 38535, 34164, 861, 47262,
      47469, 49432, 40164, 30245, 83443, 39134, 34314, 34343, 34740, 34394, 38575, 391, 83551,
      48145, 84560, 86367, 48237, 30230, 85146, 84657, 81659, 38251, 48643, 86138, 38167, 38519,
      86161, 86131, 35361, 48257, 83540, 83637, 84157, 84237, 38566, 83343, 34761, 84462, 35148,
      81149, 39136, 3522, 86147, 48743, 35341, 86556, 4712, 87964, 35249, 30149, 47131, 38576,
      35154, 34344, 34710, 86168, 47158, 39422, 30235, 38590, 35151, 30142, 86169, 84733, 42377,
      34669, 81450, 47466, 83344, 35237, 47464, 87252, 86543, 49357, 86145, 85515, 84478, 38456,
      4876, 42663, 83345, 41137, 87935, 48271, 42355, 39145, 86565, 48677, 85147, 4742, 81758,
      48261, 81538, 81364, 81139, 48354, 812, 84148, 49628, 81439, 81372, 83346, 84161, 49344,
      49663, 42357, 84551, 47140, 48543, 38175, 81668, 48444, 83441, 34618, 41322, 41653, 3519,
      8772, 38844, 84244, 86633, 41143, 42443, 4944555, 48253, 35236, 81660, 34138, 84162, 83347,
      48679, 34777, 48431, 85563, 38513, 49446, 47155, 81663, 83634, 38443, 83542, 84567, 38168,
      86341, 35356, 8722, 48339, 3466, 34643, 81434, 47146, 35379, 38475, 49247, 34764, 38247,
      85549, 34798, 48446, 35135, 35150, 86315, 49157, 41533, 86319, 87922, 39132, 41136, 49130,
      38570, 84463, 86553, 47545, 34749, 35247, 30255, 30241, 49638, 34139, 86736, 84565, 35234,
      84150, 48275, 38256, 48148, 81536, 83541, 47542, 83635, 86314, 47533, 48452, 38174, 499, 495,
      86192, 39141, 81653, 34719, 81455, 34994, 83348, 8152, 49234, 38158, 30143, 47546, 48646,
      38474, 48544, 8552, 48342, 83349, 34995, 39155, 8732, 38161, 8662, 41162, 85171, 49634, 84163,
      86635, 48647, 87143, 85172, 81151, 42436, 84164, 86554, 34356, 48531, 48266, 83350, 40162,
      49431, 30242, 30248, 41147, 40144, 84670, 34713, 86558, 3461, 84443, 49444, 3466, 8555, 34396,
      39171, 84154, 3435, 30252, 38165, 34533, 34342, 84247, 84494, 42135, 81754, 84165, 34318,
      38555, 86544, 38532, 84447, 34750, 39150, 41634, 38152, 81852, 48138, 38560, 48343, 41644,
      86195, 3843, 84635, 49141, 48762, 84444, 39549, 35363, 87938, 42359, 86149, 81143, 8617,
      86548, 39147, 35339, 383, 48673, 81144, 84238, 3537, 84562, 84255, 34370, 8352, 86352, 86369,
      84557, 48547, 47233, 83636, 34949, 49651, 42444, 87141, 83368, 3919, 34964, 81747, 41134,
      34672, 35156, 81838, 86396, 42666, 48439, 47141, 82133, 38159, 48736, 40142, 35130, 49670,
      84576, 38166, 34767, 84475, 35330, 86738, 34678, 35158, 81657, 41341, 41138, 81552, 48258,
      30253, 81436, 42376, 84456, 3812, 41346, 34544, 83352, 38845, 81839, 83353, 81138, 4862,
      48622, 3532, 49642, 49641, 83354, 83365, 86375, 35375, 83641, 38471, 48235, 81152, 49438,
      86144, 84661, 42437, 42141, 49439, 84248, 38511, 86191, 49643, 38172, 41342, 49334, 81145,
      84492, 38510, 38252, 83639, 39140, 49440, 81650, 84152, 42737, 48230, 35348, 47548, 38245,
      34392, 35338, 48441, 48535, 42154, 81669, 49346, 86373, 84555, 30236, 47544, 8142, 30134,
      41522, 4152, 38573, 35235, 49243, 82142, 81148, 83355, 40155, 39117, 49145, 84561, 81433,
      47554, 48752, 35160, 49339, 81832, 35345, 81133, 48349, 42345, 38257, 81658, 49675, 83351,
      81365, 42344, 41144, 48664, 86347, 34350, 40158, 35238, 38163, 86165, 81532, 81551, 49448,
      35357, 47135, 83543, 42431, 81134, 38556, 84656, 48345, 48149, 48546, 41637, 40157, 84445,
      48549, 30243, 86143, 81379, 47134, 84736, 42735, 38466, 38442, 49155, 86631, 47242, 81456,
      47142, 8112, 81122, 84574, 81452, 81142, 49146, 49345, 49653, 81146, 81147, 34614, 34372,
      49452, 84239, 49254, 34668, 34754, 41647, 47245, 49646, 48244, 47531, 38512, 34397, 34364,
      86379, 47555, 48232, 47238, 84596, 48331, 38563, 86340, 49336, 84544, 38561, 86394, 41645,
      83438, 48134, 48536, 863, 84540, 38557, 84453, 48141, 49627, 83451, 38156, 84145, 4855, 49137,
      47152, 49132, 4912, 49356, 84741, 35331, 34763, 34922, 86372, 846, 84548, 41163, 48272, 83357,
      49152, 49148, 35333, 8342, 83422, 34147, 8452, 38178, 49133, 47556, 35161, 35243, 48142,
      39042, 39513, 40153, 86547, 40152, 35366, 84477, 83358, 41643, 81140, 35354, 30139, 8184,
      81842, 81537, 34310, 38242, 3823, 86166, 48356, 81431, 38164, 41347, 81745, 34159, 86356,
      84233, 84464, 49654, 84655, 84167, 49667, 83633, 34315, 41642, 34775, 41654, 49156, 38568,
      40163, 42331, 86146, 34555, 81374, 83362, 42632, 42452, 4812, 38536, 35146, 81530, 49242,
      40161, 83375, 42138, 83638, 34675, 38598, 81733, 49436, 49626, 42146, 47154, 38594, 38533,
      35336, 81655, 48246, 35346, 39033, 81430, 48665, 47532, 30135, 39131, 84168, 81369, 82149,
      48455, 49142, 42352, 49135, 84479, 30246, 8652, 47476, 84249, 84230, 84493, 81652, 48263,
      38559, 48348, 83432, 49151, 86153, 86151, 47543, 4725, 84566, 86563, 34739, 3473, 38259,
      47244, 81132, 49664, 48763, 47143, 49433, 49235, 49231, 48353, 342, 83369, 41135, 81457,
      48330, 3462, 84473, 84242, 49434, 41345, 48451, 39119, 34373, 8212, 34374, 48130, 34152,
      81752, 34347, 38567, 34360, 38151, 86344, 34940, 38448, 39563, 30132, 49620, 34371, 38591,
      84169, 4752, 38171, 86316, 30146, 34597, 34997, 81748, 87261, 48435, 39164, 84558, 87771,
      86397, 35347, 38473, 39046, 86158, 4822, 48222, 38154, 38246, 49343, 48136, 83445, 86148,
      83446, 48755, 47474, 86632, 84234, 86638, 41537, 41167, 47153, 86130, 38447, 81367, 86196,
      34511, 38597, 47557, 34745, 8482, 42446, 3822, 38454, 38552, 83456, 48251, 48268, 81361,
      81739, 35349, 35111, 35163, 82138, 38534, 84742, 42156, 48666, 48352, 86167, 83340, 34712,
      4872, 87777, 34349, 34361, 84543, 38843, 48533, 30144, 42447, 41656, 38176, 35332, 38518,
      3452, 39158, 38449, 34130, 47558, 34516, 35166, 42432, 48532, 38579, 42732, 48137, 48255,
      39156, 48731, 35165, 3012, 30238, 48443, 8422, 81559, 47559, 48351, 83359, 34541, 34676,
      83363, 83544, 87145, 85510, 84442, 81150, 82144, 47472, 82135, 39543, 86140, 4234, 39032,
      41532, 39535, 3955, 41534, 38848, 39565, 41141, 41154, 41344, 39541, 39545, 41539, 87875,
      86351, 38150, 38599, 38847, 35167, 82137, 86135, 38554, 81753, 81737, 347, 49154, 82147,
      34791, 39146, 83332, 47144, 41655, 34746, 48437, 35157, 48239, 42339, 84465, 49341, 4212,
      38569, 87873, 87773, 41153, 34671, 85148, 81732, 48453, 30237, 48140, 47477, 81654, 48139,
      81830, 42433, 47137, 48678, 30148, 42347, 48642, 84744, 86371, 38596, 35241, 83545, 86391,
      81741, 86349, 83437, 84639, 47475, 38574, 35230, 35168, 42149, 34796, 84651, 351, 84231,
      47159, 39546, 8202, 8782, 38153, 49652, 39031, 85149, 30265, 84474, 48756, 47232, 40141,
      41157, 86317, 35169, 49672, 86734, 3022, 34797, 38840, 81665, 39567, 41151, 49441, 49138,
      48644, 39436, 35253, 87146, 39149, 34358, 34769, 34136, 35358, 39153, 49449, 87135, 35257,
      49645, 49637, 49147, 38849, 47248, 81751, 38558, 39510, 87136, 30266, 83546, 84159, 84652,
      84648, 30244, 41651, 81656, 38550, 39035, 81749, 83536, 35245, 48133, 39139, 49351, 48751,
      49656, 38177, 47145, 35244, 41152, 49657, 84722, 86735, 41542, 49347, 4242, 42422, 35134,
      34161, 38451, 35248, 83644, 49246, 49337, 83366, 48436, 83457, 41343, 34747, 42371, 4112,
      34162, 34535, 83549, 34760, 83548, 34157, 83367, 34531, 4852, 48143, 48766, 35368, 84745,
      38455, 84746, 38441, 416332, 851111, 391379, 4154451, 4154459, 4154455, 41544513, 3525, 9142,
      3511, 4741, 4764, 411533, 4966, 4964, 4967, 498, 423370, 423630, 831, 8313, 8642, 482502,
      4826300, 800, 3654, 36563, 36560, 3652, 36567, 36554, 36555, 36559, 36566, 36564, 36562,
      36569, 3654, 36561, 36557, 36562, 3654, 36565, 8692, 953, 903, 916, 926, 90, 84232, 727,
    ],
    exceptions_max: 8,
    exceptions_min: 2,
    name: 'Russia',
  },
  '81': {
    cityCodeLength: 3,
    exceptions: [78, 45, 44, 75, 93, 52, 25, 6, 11, 22, 54, 3, 48, 92, 53, 82, 1070, 3070, 4070],
    exceptions_max: 4,
    exceptions_min: 1,
    name: 'Japan',
  },
  '82': {
    cityCodeLength: 3,
    exceptions: [32, 62, 51, 2, 53, 42, 64, 16, 17, 18, 19],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Korea;Republic',
  },
  '84': {
    cityCodeLength: 2,
    exceptions: [511, 350, 4, 8],
    exceptions_max: 3,
    exceptions_min: 1,
    name: 'Vietnam',
  },
  '850': {
    cityCodeLength: 4,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Korea;Dem.PeoplesRepublic',
  },
  '852': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'HongKong',
  },
  '853': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Macau',
  },
  '855': {
    cityCodeLength: 2,
    exceptions: [1881, 1591, 1720],
    exceptions_max: 4,
    exceptions_min: 4,
    name: 'Cambodia',
  },
  '856': {
    cityCodeLength: 2,
    exceptions: [9],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Laos',
  },
  '86': {
    cityCodeLength: 3,
    exceptions: [
      20, 29, 10, 22, 27, 28, 21, 24, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358, 1359,
      1360, 1361, 1362, 1363, 1364, 1365, 1366, 1367, 1368, 1369, 1370, 1371, 1372, 1373, 1374,
      1375, 1376, 1377, 1378, 1379, 1380, 1381, 1382, 1383, 1384, 1385, 1386, 1387, 1388, 1389,
      1390,
    ],
    exceptions_max: 4,
    exceptions_min: 2,
    name: 'China',
  },
  '880': {
    cityCodeLength: 3,
    exceptions: [51, 2, 41, 81, 91, 31],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Bangladesh',
  },
  '886': {
    cityCodeLength: 1,
    exceptions: [89, 90, 91, 92, 93, 96, 60, 70, 94, 95],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Taiwan',
  },
  '90': {
    cityCodeLength: 3,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Turkey',
  },
  '91': {
    cityCodeLength: 3,
    exceptions: [11, 22, 33, 44, 40],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'India',
  },
  '9162': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Cocos',
  },
  '92': {
    cityCodeLength: 3,
    exceptions: [8288, 4521, 4331, 51, 21, 42, 61, 91, 71],
    exceptions_max: 4,
    exceptions_min: 2,
    name: 'Pakistan',
  },
  '93': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Afganistan',
  },
  '94': {
    cityCodeLength: 2,
    exceptions: [1, 9, 8],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'SriLanka',
  },
  '95': {
    cityCodeLength: 2,
    exceptions: [1, 2],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Myanmar',
  },
  '960': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Maldives',
  },
  '961': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Lebanon',
  },
  '962': {
    cityCodeLength: 1,
    exceptions: [59, 79, 73, 74, 17],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Jordan',
  },
  '963': {
    cityCodeLength: 2,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Syria',
  },
  '964': {
    cityCodeLength: 3,
    exceptions: [1, 43, 49, 25, 62, 36, 32, 50, 23, 60, 42, 33, 24, 37, 53, 21, 30, 66],
    exceptions_max: 2,
    exceptions_min: 1,
    name: 'Iraq',
  },
  '965': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Kuwait',
  },
  '966': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'SaudiArabia',
  },
  '967': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Yemen;North',
  },
  '968': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Oman',
  },
  '969': {
    cityCodeLength: 2,
    exceptions: [8],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Yemen;South',
  },
  '971': {
    cityCodeLength: 1,
    exceptions: [5079],
    exceptions_max: 4,
    exceptions_min: 4,
    name: 'UnitedArabEmirates',
  },
  '972': {
    cityCodeLength: 1,
    exceptions: [50, 51, 52, 53, 58],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Israel',
  },
  '973': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Bahrain',
  },
  '974': {
    cityCodeLength: 0,
    exceptions: [
      48, 59, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 222, 223, 224, 225, 226, 227,
    ],
    exceptions_max: 3,
    exceptions_min: 2,
    name: 'Qatar',
  },
  '975': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Bhutan',
  },
  '976': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Mongolia',
  },
  '977': {
    cityCodeLength: 2,
    exceptions: [1],
    exceptions_max: 1,
    exceptions_min: 1,
    name: 'Nepal',
  },
  '98': {
    cityCodeLength: 3,
    exceptions: [61, 11, 31, 51, 41, 21, 81, 71],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Iran',
  },
  '992': {
    cityCodeLength: 0,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Tajikistan',
  },
  '993': {
    cityCodeLength: 1,
    exceptions: [],
    exceptions_max: 0,
    exceptions_min: 0,
    name: 'Turkmenistan',
  },
  '994': {
    cityCodeLength: 3,
    exceptions: [12, 1445, 1302],
    exceptions_max: 4,
    exceptions_min: 2,
    name: 'Azerbaijan',
  },
  '995': {
    cityCodeLength: 3,
    exceptions: [32, 34],
    exceptions_max: 2,
    exceptions_min: 2,
    name: 'Georgia',
  },
  '996': {
    cityCodeLength: 4,
    exceptions: [31, 37, 313, 39, 35, 32, 34],
    exceptions_max: 3,
    exceptions_min: 2,
    name: 'Kyrgyzstan',
  },
  '998': {
    cityCodeLength: 4,
    exceptions: [71, 74, 65, 67, 72, 75, 79, 69, 61, 66, 76, 62, 73, 677, 673],
    exceptions_max: 3,
    exceptions_min: 2,
    name: 'Uzbekistan',
  },
};
