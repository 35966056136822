import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IEmployee } from '@components/typings/interfaces';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import HighlightedText from '@components/HighlightedText';
import clsx from 'clsx';
import { Role } from '@/client/generated/graphql';
import { getRole } from '@/utils';
import { useEmployeesStyles } from '../Employees.styles';

export const EmployeeNameCell: FunctionComponent<ICellProps<IEmployee>> = ({ item }) => {
  const [translate] = useTranslation();
  const classes = useEmployeesStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;
  const isEmailSearch = useMemo(
    () => !!(searchText && item?.email?.toUpperCase().includes(searchText?.toUpperCase())),
    [item, searchText]
  );

  if (item) {
    const { name, email } = item;
    const role = getRole(item.role);
    const title = name ?? '';
    const description = role && role !== Role.User ? translate(role.toUpperCase()) : '';

    return (
      <Flex direction={'column'} className={classes.defaultElementWidthFull}>
        <HighlightedText type={'text'} text={title} query={searchText} />
        {isEmailSearch && searchText && (
          <HighlightedText
            className={classes.marginTop025}
            type={'text'}
            text={email}
            query={searchText}
          />
        )}
        <Typography
          type={'text4'}
          color={'tertiary400'}
          className={clsx(classes.secondaryText, { [classes.marginTop025]: isEmailSearch })}
        >
          {description}
        </Typography>
      </Flex>
    );
  }

  return <div />;
};
