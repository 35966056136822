import React, { forwardRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeGrid as GridList } from 'react-window';
import Preloader from '@shared/components/Preloader';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import Typography from '@shared/components/Typography';
import { setTopStyle } from '@/features/Documents/Contract/Contract.constants';
import clsx from 'clsx';
import { NumberCatalogModel } from '@/client/generated/graphql';
import { PhoneNumberLazyScrollTableProps, RowRenderType } from '../Contract.interfaces';
import { useContractStyle } from '../Contract.styles';

const PADDING_SIZE = 16;

export const PhoneNumberLazyScrollTable = (props: PhoneNumberLazyScrollTableProps) => {
  const {
    allDisabled,
    hasNextPage,
    isNextPageLoading,
    items,
    loadNextPage,
    onNumberSelect,
    selectedNumbers,
    selectedItem,
    gridListOuterElementRef,
  } = props;

  const classes = useContractStyle();

  const itemCount = hasNextPage ? items.length + 1 : items.length + 1;
  const loadMoreItems = isNextPageLoading ? () => null : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  function handleNumberClick(
    numberItem: NumberCatalogModel,
    itemIndex: number,
    itemSelected: boolean
  ) {
    if (allDisabled || itemSelected) {
      return;
    }
    if (onNumberSelect) {
      onNumberSelect(numberItem, itemIndex);
    }
  }

  const rowPhoneNumberRender = ({ columnIndex, rowIndex, style }: RowRenderType) => {
    const itemIndex = rowIndex * 2 + columnIndex;
    if (!isItemLoaded(itemIndex)) {
      return (
        <div
          key={itemIndex}
          style={{
            ...style,
            top: setTopStyle(style, PADDING_SIZE),
          }}
          className={
            columnIndex % 2 === 0 ? classes.phoneNumberLeftCell : classes.phoneNumberRightCell
          }
        >
          <div className={classes.phoneNumberPreloader}>
            <Preloader />
          </div>
        </div>
      );
    }
    if (items.length && items[itemIndex] !== undefined) {
      const item = items[itemIndex];
      const selected = selectedNumbers.find(
        (selectedNumber) => selectedNumber.phone === item.phone
      );
      const focused = selectedItem === itemIndex;
      return (
        <div
          key={itemIndex}
          style={{
            ...style,
            top: setTopStyle(style, PADDING_SIZE),
          }}
          className={
            columnIndex % 2 === 0 ? classes.phoneNumberLeftCell : classes.phoneNumberRightCell
          }
        >
          <div
            className={clsx(classes.phoneNumberCell, {
              [classes.phoneNumberCellDisabled]: selected || allDisabled,
              [classes.phoneNumberCellFocused]: !selected && focused,
            })}
            onClick={() => handleNumberClick(item, itemIndex, Boolean(selected))}
          >
            <Typography type={'text3'} color={'tertiary900'}>
              {formatPhone(item.phone)}
            </Typography>
          </div>
        </div>
      );
    }
    return null;
  };

  const innerElementType = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ style, ...rest }, ref) => {
      let parsedHeightNumber = parseFloat(String(style?.height));
      if (Number.isNaN(parsedHeightNumber)) {
        parsedHeightNumber = 0;
      }

      const height = `${parsedHeightNumber + PADDING_SIZE * 2}px`;

      return (
        <div
          ref={ref}
          style={{
            ...style,
            height,
          }}
          {...rest}
        />
      );
    }
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <GridList
              className={classes.list}
              ref={ref}
              outerRef={gridListOuterElementRef}
              columnCount={2}
              innerElementType={innerElementType}
              columnWidth={width / 2 - 16}
              height={height}
              rowCount={itemCount / 2}
              rowHeight={52}
              width={width}
              onItemsRendered={(gridProps) => {
                onItemsRendered({
                  overscanStartIndex: gridProps.overscanRowStartIndex * 2,
                  overscanStopIndex: gridProps.overscanRowStopIndex * 2,
                  visibleStartIndex: gridProps.visibleRowStartIndex * 2,
                  visibleStopIndex: gridProps.visibleRowStopIndex * 2,
                });
              }}
            >
              {rowPhoneNumberRender}
            </GridList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};
