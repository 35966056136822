import React, {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Drawer from '@components/Drawer';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import SelectField from '@shared/components/SelectField';
import Flex from '@shared/components/Flex';
import SwitchField from '@shared/components/SwitchField';
import RadioGroup from '@shared/components/RadioGroup';
import Radio from '@shared/components/Radio';
import { FormControlLabel } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_AMO_PIPELINES_QUERY } from '@/client/queries';
import { CheckboxField } from '@shared/components/CheckboxField/CheckboxField';
import clsx from 'clsx';
import SelectTagsField, { onChangeValueType } from '@components/SelectTagsField';
import { setStringMaxLength } from '@components/utils';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';
import { getSettingsObjectKeys } from '../IntegrationForm.constants';
import {
  amoLeadType,
  amoSettingContactType,
  amoSettingLeadType,
  amoSettingTaskType,
  amoSettingUnsortedType,
  IAMOPipelineDataList,
  IScenarioSettingsType,
  responsibleObjectKey,
  scenarioDirectionEnum,
  scenarioSettingsType,
} from '../IntegrationForm.interfaces';
import {
  amoLeadResponsibleObjectKey,
  amoTaskResponsibleObjectKey,
  createConditionOptions,
  tagsOptionList,
} from './AmoCRM.constants';
import { useAmoScenarioFormPropsHooks } from './hooks/useAmoScenarioFormProps.hooks';
import { IntegrationContext } from '@/features/Integrations/IntegrationForm/IntegrationForm';

export const AmoCRMSettingsForm: FunctionComponent<IScenarioSettingsType> = ({
  isOpen,
  onClose,
  onComplete,
}) => {
  const { scenarioType, scenarioData, comparedEmployee, rawEmployee, integrationId } =
    useContext(IntegrationContext);
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { setValue, watch, reset, handleSubmit, clearErrors } = formMethods;
  const isLeadActive = watch('isLeadActive');
  const isTaskActive = watch('isTaskActive');
  const pipeLineId = watch('pipeline');
  const leadType = watch('leadType');
  const isContactTask = watch('contactLead');
  const { title: settingTitle, edit } = scenarioType || {};
  const leadResponsibleKey = amoLeadResponsibleObjectKey(edit);
  const taskResponsibleKey = amoTaskResponsibleObjectKey(edit);
  const { direction, status, type } = getSettingsObjectKeys(edit);
  const [leadTagsList, setLeadTagsList] = useState<onChangeValueType | null>(null);
  const [contactTagsList, setContactTagsList] = useState<onChangeValueType | null>(null);
  // TODO handle lazy query error
  const [getAmoPipelines, { data: amoPipelines }] = useLazyQuery(GET_AMO_PIPELINES_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      id: integrationId,
    },
  });

  const pipelineList = (amoPipelines?.getAmoPipelines || []).map(
    (pipeItem: IAMOPipelineDataList) => ({
      ...pipeItem,
      name: setStringMaxLength(pipeItem.name, 33),
      statuses: pipeItem.statuses.map((pipeStatus) => ({
        ...pipeStatus,
        name: setStringMaxLength(pipeStatus.name, 33),
      })),
    })
  );

  const pipelineStatuses = useMemo(() => {
    if (!pipeLineId || !pipelineList) return [];
    const currentPipelineStatuses = pipelineList.find(
      (p: IAMOPipelineDataList) => p.id === Number(pipeLineId)
    );
    return currentPipelineStatuses
      ? currentPipelineStatuses.statuses.filter((pStatus) => pStatus.type !== 1)
      : [];
  }, [pipelineList, pipeLineId]);

  const {
    leadTitle,
    leadTitleDescription,
    taskTitleDescription,
    tagsOption,
    isCommonSettings,
    taskResponsibleList,
    rawEmployeeList,
    responsibleDefaultList,
    responsibleWithEmployeeList,
    checkPipelineInList,
    checkPipelineStatusInList,
  } = useAmoScenarioFormPropsHooks(direction, edit, comparedEmployee, rawEmployee);

  useEffect(() => {
    clearErrors();
  }, [clearErrors, edit]);

  useEffect(() => {
    if (!amoPipelines && integrationId) {
      getAmoPipelines();
    }
  }, [amoPipelines, getAmoPipelines, integrationId]);

  useEffect(() => {
    if (isOpen && scenarioData && Object.keys(scenarioData).length) {
      const setTagsAllValues = (
        data: { IdList: Array<unknown>; customList: Array<string> } | null
      ) => {
        if (!data) return [];
        const idValues = data.IdList.reduce((result: Array<string>, idTagElement) => {
          const item = tagsOptionList.find((rawTagsOption) => rawTagsOption.id === idTagElement);
          if (item) {
            result.push(translate(item.title));
          }
          return result;
        }, []);
        return [...idValues, ...data.customList];
      };

      setTimeout(() => {
        if (direction === scenarioDirectionEnum.Common) {
          if (scenarioData[direction]?.defaultEmployee) {
            setValue('isLeadActive', true);
            setValue('manager', scenarioData[direction]?.defaultEmployee);
          } else {
            setValue('isLeadActive', false);
            setValue('manager', '');
          }
        } else {
          const isCurrentSettingFilled = scenarioData[direction]?.[type]?.[status];
          if (isCurrentSettingFilled?.lead) {
            setValue('isLeadActive', true);
            setValue('leadType', amoLeadType.Task);
            const leadSetting = isCurrentSettingFilled?.lead as amoSettingLeadType;
            const isLeadResponsibleFilled =
              leadSetting?.responsible || leadSetting?.defaultResponsible;
            if (isLeadResponsibleFilled) {
              setValue('leadResponsible', isLeadResponsibleFilled);
            }
            if (typeof leadSetting.create === 'string') {
              setValue('createStatus', leadSetting.create);
            }
            setValue('pipeline', leadSetting.pipeline_id);
            if (leadSetting.status_id) {
              setValue('pipelineStatus', leadSetting.status_id);
            }
            setLeadTagsList({
              IdList: leadSetting.dynamic_tags || [],
              customList: leadSetting.tags_names || [],
              valuesList: setTagsAllValues({
                IdList: leadSetting.dynamic_tags || [],
                customList: leadSetting.tags_names || [],
              }),
            });
          } else if (isCurrentSettingFilled?.unsorted) {
            const unsortedSetting = isCurrentSettingFilled?.unsorted as amoSettingUnsortedType;
            setValue('leadType', amoLeadType.Unsorted);
            setValue('isLeadActive', true);
            setValue('pipeline', unsortedSetting.pipeline_id);
            const isUnsortedSettingFilled =
              unsortedSetting?.responsible || unsortedSetting?.defaultResponsible;
            if (isUnsortedSettingFilled) {
              setValue('leadResponsible', isUnsortedSettingFilled);
            }
          } else if (isCurrentSettingFilled?.contact) {
            const contactSetting = isCurrentSettingFilled?.contact as amoSettingContactType;
            setValue('leadType', amoLeadType.Contact);
            setValue('isLeadActive', true);
            if (contactSetting?.defaultResponsible) {
              setValue('leadResponsible', contactSetting?.defaultResponsible);
            }
            setLeadTagsList({
              IdList: contactSetting.dynamic_tags || [],
              customList: contactSetting.tags_names || [],
              valuesList: setTagsAllValues({
                IdList: contactSetting.dynamic_tags || [],
                customList: contactSetting.tags_names || [],
              }),
            });
            if (contactSetting?.lead) {
              setValue('contactLead', true);
              setValue('pipeline', contactSetting?.lead?.pipeline_id);
              setValue('pipelineStatus', contactSetting?.lead?.status_id);
              setContactTagsList({
                IdList: contactSetting?.lead?.dynamic_tags || [],
                customList: contactSetting?.lead?.tags_names || [],
                valuesList: setTagsAllValues({
                  IdList: contactSetting?.lead?.dynamic_tags || [],
                  customList: contactSetting?.lead?.tags_names || [],
                }),
              });
            }
          } else {
            setValue('isLeadActive', false);
            setValue('contactLead', false);
            setValue('pipeline', '');
            setValue('pipelineStatus', '');
            if (
              edit === scenarioSettingsType.IncomingUnknown ||
              edit === scenarioSettingsType.IncomingMissedUnknown
            ) {
              setValue('leadType', amoLeadType.Contact);
            }
            if (
              edit === scenarioSettingsType.IncomingKnown ||
              edit === scenarioSettingsType.IncomingMissedKnown
            ) {
              setValue('leadType', amoLeadType.Unsorted);
            }
            setLeadTagsList(null);
            setContactTagsList(null);
          }
          if (isCurrentSettingFilled?.task) {
            const taskSetting = isCurrentSettingFilled?.task as amoSettingTaskType;
            if (typeof taskSetting === 'boolean') {
              setValue('isTaskActive', true);
            } else {
              setValue('isTaskActive', true);
              const isTaskResponsibleFilled =
                taskSetting?.responsible || taskSetting?.defaultResponsible;
              if (isTaskResponsibleFilled) {
                setValue('taskResponsible', isTaskResponsibleFilled);
              }
            }
          } else {
            setValue('isTaskActive', false);
            setValue('taskResponsible', '');
          }
        }
      });
    } else {
      reset();
      setLeadTagsList(null);
      setContactTagsList(null);
    }
  }, [scenarioData, isOpen, direction, type, status, setValue, translate, reset, edit]);

  function handleChangeLeadType() {
    setValue('pipeline', '');
    setValue('pipelineStatus', '');
    setValue('leadResponsible', '');
    setLeadTagsList(null);
    setContactTagsList(null);
    clearErrors();
  }

  const handleSettingsSubmit = useCallback(
    (formData) => {
      const { leadResponsible, taskResponsible, manager, pipeline, pipelineStatus, createStatus } =
        formData || {};
      const { IdList: leadDynamicTags, customList: leadTags } = leadTagsList || {};
      const { IdList: contactDynamicTags, customList: contactTags } = contactTagsList || {};
      const isUnsortedOption =
        edit === scenarioSettingsType.IncomingKnown ||
        edit === scenarioSettingsType.IncomingMissedKnown ||
        edit === scenarioSettingsType.IncomingUnknown ||
        edit === scenarioSettingsType.IncomingMissedUnknown;
      const isUnsorted = leadType === amoLeadType.Unsorted && isUnsortedOption;

      const leadResponsibleObject = leadResponsibleKey
        ? { [leadResponsibleKey]: leadResponsible }
        : undefined;

      const taskResponsibleObject = taskResponsibleKey
        ? { [taskResponsibleKey]: taskResponsible }
        : undefined;

      const getComputedLeadObject = () => {
        const contactTask = isContactTask
          ? {
              lead: {
                pipeline_id: pipeline,
                status_id: pipelineStatus,
                dynamic_tags: contactDynamicTags,
                tags_names: contactTags,
              },
            }
          : undefined;
        if (isLeadActive && !isCommonSettings) {
          switch (edit) {
            case scenarioSettingsType.IncomingKnown:
            case scenarioSettingsType.IncomingMissedKnown:
            case scenarioSettingsType.OutgoingKnown:
            case scenarioSettingsType.OutgoingMissedKnown: {
              return {
                lead: {
                  create: createStatus,
                  pipeline_id: pipeline,
                  status_id: pipelineStatus,
                  dynamic_tags: leadDynamicTags,
                  tags_names: leadTags,
                  ...leadResponsibleObject,
                },
              };
            }
            case scenarioSettingsType.IncomingUnknown:
            case scenarioSettingsType.IncomingMissedUnknown: {
              return {
                contact: {
                  create: true,
                  dynamic_tags: leadDynamicTags,
                  tags_names: leadTags,
                  ...leadResponsibleObject,
                  ...contactTask,
                },
              };
            }
            case scenarioSettingsType.OutgoingUnknown:
            case scenarioSettingsType.OutgoingMissedUnknown: {
              return {
                contact: {
                  create: true,
                  dynamic_tags: leadDynamicTags,
                  tags_names: leadTags,
                  ...contactTask,
                },
              };
            }
            default: {
              return undefined;
            }
          }
        }
        return undefined;
      };
      const leadObject = getComputedLeadObject();

      const getComputedUnsortedObject = () => {
        if (isLeadActive && !isCommonSettings) {
          switch (edit) {
            case scenarioSettingsType.IncomingKnown:
            case scenarioSettingsType.IncomingUnknown:
            case scenarioSettingsType.IncomingMissedUnknown: {
              return {
                unsorted: {
                  pipeline_id: pipeline,
                  ...leadResponsibleObject,
                },
              };
            }
            case scenarioSettingsType.IncomingMissedKnown: {
              return {
                unsorted: {
                  pipeline_id: pipeline,
                  ...leadResponsibleObject,
                },
              };
            }
            default: {
              return undefined;
            }
          }
        }
        return undefined;
      };
      const unsortedObject = getComputedUnsortedObject();

      const lead = isUnsorted ? unsortedObject : leadObject;

      const getComputedTaskObject = () => {
        if (isTaskActive && !isCommonSettings) {
          return { task: taskResponsibleObject ? { ...taskResponsibleObject } : true };
        }
        return undefined;
      };
      const task = getComputedTaskObject();

      const defaultEmployee =
        isLeadActive && isCommonSettings ? { defaultEmployee: Number(manager) } : undefined;

      onComplete({
        direction,
        type,
        status,
        data: {
          ...lead,
          ...task,
          ...defaultEmployee,
        },
      });
    },
    [
      leadTagsList,
      contactTagsList,
      leadType,
      leadResponsibleKey,
      taskResponsibleKey,
      isLeadActive,
      isCommonSettings,
      direction,
      type,
      status,
      isContactTask,
      edit,
      isTaskActive,
      onComplete,
    ]
  );

  const renderLeadForm = () => {
    const renderLeadUnsortedForm = () => (
      <div className={classes.integrationScenarioNumbersList}>
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectField
            label={translate('PIPELINE_FROM_CRM')}
            data={pipelineList}
            name={'pipeline'}
            valueKey={'id'}
            titleKey={'name'}
            className={classes.defaultElementWidth21}
            validate={(pipeline: string) => {
              if (!isLeadActive) {
                return true;
              }
              if (checkPipelineInList(pipelineList, pipeline)) {
                return translate('CHOOSE_PIPELINE') as string;
              }
              return true;
            }}
          />
        </div>
        {(edit === scenarioSettingsType.IncomingKnown ||
          edit === scenarioSettingsType.IncomingMissedKnown ||
          edit === scenarioSettingsType.IncomingUnknown ||
          edit === scenarioSettingsType.IncomingMissedUnknown) && (
          <div className={classes.scenarioSettingMenuItemSmall}>
            <SelectField
              data={
                edit === scenarioSettingsType.IncomingMissedKnown ||
                edit === scenarioSettingsType.IncomingUnknown ||
                edit === scenarioSettingsType.IncomingMissedUnknown
                  ? responsibleWithEmployeeList
                  : responsibleDefaultList
              }
              label={translate('RESPONSIBLE')}
              name={'leadResponsible'}
              valueKey={'key'}
              titleKey={'value'}
              translating
              className={classes.defaultElementWidth21}
              validate={(leadResponsible: string) => {
                if (!isLeadActive) {
                  return true;
                }
                if (!leadResponsible || leadResponsible === '') {
                  return translate('RESPONSIBLE_EMPTY_TEXT') as string;
                }
                return true;
              }}
            />
          </div>
        )}
      </div>
    );
    const renderLeadTaskForm = () => (
      <div className={classes.integrationScenarioNumbersList}>
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectField
            data={createConditionOptions}
            label={translate('CREATE_DEAL_CONDITION')}
            name={'createStatus'}
            valueKey={'key'}
            titleKey={'value'}
            translating
            className={classes.defaultElementWidth21}
            validate={(createCondition: string) => {
              if (!isLeadActive) {
                return true;
              }
              if (!createCondition || createCondition === '') {
                return translate('CREATE_DEAL_CONDITION_EMPTY') as string;
              }
              return true;
            }}
          />
        </div>
        <div className={classes.CRMSettingsBorderLine} />
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectField
            label={translate('PIPELINE_FROM_CRM')}
            data={pipelineList}
            name={'pipeline'}
            valueKey={'id'}
            titleKey={'name'}
            className={classes.defaultElementWidth21}
            validate={(pipeline: string) => {
              if (!isLeadActive) {
                return true;
              }
              if (checkPipelineInList(pipelineList, pipeline)) {
                return translate('CHOOSE_PIPELINE') as string;
              }
              return true;
            }}
          />
        </div>
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectField
            label={translate('PIPELINE_STATUS')}
            data={pipelineStatuses}
            name={'pipelineStatus'}
            valueKey={'id'}
            titleKey={'name'}
            disabled={pipelineStatuses.length === 0}
            className={classes.defaultElementWidth21}
            validate={(pipelineStatus: string) => {
              if (!isLeadActive) {
                return true;
              }
              if (checkPipelineStatusInList(pipelineStatuses, pipelineStatus)) {
                return translate('CHOOSE_PIPELINE_STATUS') as string;
              }
              return true;
            }}
          />
        </div>
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectField
            data={
              edit === scenarioSettingsType.IncomingKnown ||
              edit === scenarioSettingsType.OutgoingKnown ||
              edit === scenarioSettingsType.OutgoingMissedKnown
                ? responsibleDefaultList
                : responsibleWithEmployeeList
            }
            label={translate('RESPONSIBLE')}
            name={'leadResponsible'}
            valueKey={'key'}
            titleKey={'value'}
            translating
            className={classes.defaultElementWidth21}
            validate={(leadResponsible: string) => {
              if (!isLeadActive) {
                return true;
              }
              if (!leadResponsible || leadResponsible === '') {
                return translate('RESPONSIBLE_EMPTY_TEXT') as string;
              }
              return true;
            }}
          />
        </div>
        <div className={classes.scenarioSettingMenuItemSmall}>
          <SelectTagsField
            className={classes.defaultElementWidth21}
            data={tagsOption}
            placeholder={translate('CHOOSE_OR_ENTER_CUSTOM')}
            titleKey={'title'}
            valueKey={'id'}
            value={leadTagsList?.valuesList || []}
            onChange={setLeadTagsList}
            label={translate('ADD_TAGS')}
          />
        </div>
      </div>
    );
    const renderLeadContactForm = () => (
      <div
        className={clsx(
          classes.integrationScenarioNumbersList,
          classes.integrationSettingsNoPadding
        )}
      >
        <div className={classes.integrationSettingsWrapper}>
          {edit !== scenarioSettingsType.OutgoingUnknown &&
          edit !== scenarioSettingsType.OutgoingMissedUnknown ? (
            <div className={classes.scenarioSettingMenuItemSmall}>
              <SelectField
                data={
                  edit === scenarioSettingsType.IncomingMissedUnknown ||
                  edit === scenarioSettingsType.IncomingUnknown
                    ? responsibleWithEmployeeList
                    : responsibleDefaultList
                }
                label={translate('RESPONSIBLE')}
                name={'leadResponsible'}
                valueKey={'key'}
                titleKey={'value'}
                translating
                className={classes.defaultElementWidth21}
                validate={(leadResponsible: string) => {
                  if (!isLeadActive) {
                    return true;
                  }
                  if (!leadResponsible || leadResponsible === '') {
                    return translate('RESPONSIBLE_EMPTY_TEXT') as string;
                  }
                  return true;
                }}
              />
            </div>
          ) : null}
          <div className={classes.scenarioSettingMenuItemSmall}>
            <SelectTagsField
              className={classes.defaultElementWidth21}
              data={tagsOption}
              titleKey={'title'}
              valueKey={'id'}
              placeholder={translate('CHOOSE_OR_ENTER_CUSTOM')}
              value={leadTagsList?.valuesList || []}
              onChange={setLeadTagsList}
              label={translate('ADD_TAGS')}
            />
          </div>
        </div>
        <div className={classes.integrationSettingsContactWrapper}>
          <div className={classes.scenarioSettingMenuItemTiny}>
            <CheckboxField name={'contactLead'} label={translate('CREATE_DEAL')} />
          </div>
          {isContactTask && (
            <>
              <div className={classes.scenarioSettingMenuItemSmall}>
                <SelectField
                  label={translate('PIPELINE_FROM_CRM')}
                  data={pipelineList}
                  name={'pipeline'}
                  valueKey={'id'}
                  titleKey={'name'}
                  className={classes.defaultElementWidth21}
                  validate={(pipeline: string) => {
                    if (!isLeadActive) {
                      return true;
                    }
                    if (checkPipelineInList(pipelineList, pipeline)) {
                      return translate('CHOOSE_PIPELINE') as string;
                    }
                    return true;
                  }}
                />
              </div>
              <div className={classes.scenarioSettingMenuItemSmall}>
                <SelectField
                  label={translate('PIPELINE_STATUS')}
                  data={pipelineStatuses}
                  name={'pipelineStatus'}
                  valueKey={'id'}
                  titleKey={'name'}
                  disabled={pipelineStatuses.length === 0}
                  className={classes.defaultElementWidth21}
                  validate={(pipelineStatus: string) => {
                    if (!isLeadActive) {
                      return true;
                    }
                    if (checkPipelineStatusInList(pipelineStatuses, pipelineStatus)) {
                      return translate('CHOOSE_PIPELINE_STATUS') as string;
                    }
                    return true;
                  }}
                />
              </div>
              <div className={classes.scenarioSettingMenuItem}>
                <SelectTagsField
                  className={classes.defaultElementWidth21}
                  data={tagsOption}
                  titleKey={'title'}
                  valueKey={'id'}
                  placeholder={translate('CHOOSE_OR_ENTER_CUSTOM')}
                  value={contactTagsList?.valuesList || []}
                  onChange={setContactTagsList}
                  label={translate('ADD_TAGS')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );

    switch (edit) {
      case scenarioSettingsType.IncomingKnown:
      case scenarioSettingsType.IncomingMissedKnown: {
        return (
          <div className={classes.scenarioSettingMenuItemSmall}>
            <RadioGroup defaultValue={amoLeadType.Unsorted} name={'leadType'}>
              <FormControlLabel
                value={amoLeadType.Unsorted}
                control={
                  <Radio
                    onChange={handleChangeLeadType}
                    className={classes.CRMSettingsRadio}
                    color={'secondary'}
                  />
                }
                label={translate('CREATE_UNSORTED')}
              />
              {leadType === amoLeadType.Unsorted && renderLeadUnsortedForm()}
              <FormControlLabel
                value={amoLeadType.Task}
                control={
                  <Radio
                    onChange={handleChangeLeadType}
                    className={classes.CRMSettingsRadio}
                    color={'secondary'}
                  />
                }
                label={translate('CREATE_NEW_DEAL')}
              />
              {leadType === amoLeadType.Task && renderLeadTaskForm()}
            </RadioGroup>
          </div>
        );
      }
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingMissedUnknown: {
        return (
          <div className={classes.scenarioSettingMenuItemSmall}>
            <RadioGroup defaultValue={amoLeadType.Contact} name={'leadType'}>
              <FormControlLabel
                value={amoLeadType.Contact}
                control={
                  <Radio
                    onChange={handleChangeLeadType}
                    className={classes.CRMSettingsRadio}
                    color={'secondary'}
                  />
                }
                label={translate('CREATE_CONTACT')}
              />
              {leadType === amoLeadType.Contact && renderLeadContactForm()}
              <FormControlLabel
                value={amoLeadType.Unsorted}
                control={
                  <Radio
                    onChange={handleChangeLeadType}
                    className={classes.CRMSettingsRadio}
                    color={'secondary'}
                  />
                }
                label={translate('CREATE_UNSORTED')}
              />
              {leadType === amoLeadType.Unsorted && renderLeadUnsortedForm()}
            </RadioGroup>
          </div>
        );
      }
      case scenarioSettingsType.OutgoingKnown:
      case scenarioSettingsType.OutgoingMissedKnown: {
        return renderLeadTaskForm();
      }
      case scenarioSettingsType.OutgoingUnknown:
      case scenarioSettingsType.OutgoingMissedUnknown: {
        return renderLeadContactForm();
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <Drawer
      title={settingTitle || ''}
      variant={'persistent'}
      open={isOpen}
      classes={{ paper: classes.defaultElementWidth33 }}
      size={'lg'}
      primaryButton={
        !isLeadActive && !isTaskActive
          ? {
              title: 'APPLY',
              onClick: handleSettingsSubmit,
            }
          : {
              title: 'APPLY',
              props: {
                type: 'submit',
                form: 'scenarios-settings-form',
              },
            }
      }
      secondaryButton={{
        title: 'CANCEL',
        onClick: onClose,
      }}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSettingsSubmit)} id={'scenarios-settings-form'}>
          <div className={classes.scenarioSettingMenuContent}>
            <Flex className={classes.integrationOnOffSwitch}>
              <SwitchField
                name={'isLeadActive'}
                customLabel={
                  <Typography type={'text2'} color={'tertiary900'}>
                    {translate(leadTitle)}
                  </Typography>
                }
              />
            </Flex>
            <Flex
              className={!isLeadActive ? classes.responsibleWrapper : undefined}
              direction={'column'}
            >
              {leadTitleDescription && (
                <div className={classes.scenarioSettingMenuTitle}>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {leadTitleDescription}
                  </Typography>
                </div>
              )}
              {leadResponsibleKey && renderLeadForm()}
              {isCommonSettings && (
                <div className={classes.scenarioSettingMenuItem}>
                  <SelectField
                    data={rawEmployeeList.map((i) => ({ ...i, key: Number(i.key) }))}
                    name={'manager'}
                    valueKey={'key'}
                    titleKey={'value'}
                    defaultValue={'responsible'}
                    className={classes.defaultElementWidth21}
                    validate={(manager: string) => {
                      if (!isLeadActive) {
                        return true;
                      }
                      if (!manager || manager === '') {
                        return translate('CHOOSE_RESPONSIBLE_MANAGER') as string;
                      }
                      return true;
                    }}
                  />
                </div>
              )}
            </Flex>
            {!isCommonSettings && (
              <>
                <Flex className={classes.integrationOnOffSwitch}>
                  <SwitchField
                    name={'isTaskActive'}
                    customLabel={
                      <Typography type={'text2'} color={'tertiary900'}>
                        {translate('CREATE_TASK')}
                      </Typography>
                    }
                  />
                </Flex>
                <Flex
                  className={!isTaskActive ? classes.responsibleWrapper : undefined}
                  direction={'column'}
                >
                  <div className={classes.integrationResponsibleMessage}>
                    {taskTitleDescription && (
                      <div className={classes.scenarioSettingMenuTitle}>
                        <Typography type={'text3'} color={'tertiary900'}>
                          {taskTitleDescription}
                        </Typography>
                      </div>
                    )}
                    {taskResponsibleKey ? (
                      <div className={classes.integrationScenarioNumbersList}>
                        <div className={classes.scenarioSettingMenuItemSmall}>
                          <SelectField
                            data={taskResponsibleList}
                            label={translate('RESPONSIBLE')}
                            name={'taskResponsible'}
                            valueKey={'key'}
                            titleKey={'value'}
                            defaultValue={
                              taskResponsibleKey === responsibleObjectKey.Responsible
                                ? 'responsible'
                                : ''
                            }
                            translating={leadResponsibleKey === responsibleObjectKey.Responsible}
                            validate={(taskResponsible: string) => {
                              if (!isTaskActive) {
                                return true;
                              }
                              if (!taskResponsible || taskResponsible === '') {
                                return translate('RESPONSIBLE_EMPTY_TEXT') as string;
                              }
                              return true;
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={classes.scenarioSettingMenuTitle}>
                        <Typography type={'text3'} color={'tertiary900'}>
                          {translate('CREATE_TASK_FOR_RESPONSIBLE')}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Flex>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default memo(AmoCRMSettingsForm);
