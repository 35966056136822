import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const useTariffsStyle = makeStyles(
  ({ color: { base, tertiary, secondary, success, danger, primary } }) => ({
    tariffContent: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      padding: '0 2em',
    },
    tariffDialogBodyWrapper: {
      margin: '0 1.5em 1.5em 1.5em',
    },
    tariffOptionsList: {
      display: 'flex',
      paddingTop: '2em',
    },
    tariffTitle: {
      paddingBottom: '1em',
    },
    tariffRequestDialogWrapper: {
      padding: '0 0.5em 1em',
      minHeight: '15em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    tariffPaymentDialogCenter: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      paddingBottom: '1em',
    },
    tariffMessageContent: {
      textAlign: 'left',
      paddingBottom: '1.5em',
    },
    tariffPaymentInformationText: {
      paddingBottom: '0.5em',
    },
    tariffActionButton: {
      width: '12em',
      margin: '0 1em',
    },
    tariffChangedOptionLine: {
      padding: '1em 0',
      borderBottom: `solid 1px ${tertiary[200]}`,
    },
    tariffBalanceInformationLine: {
      borderTop: `solid 1px ${tertiary[900]}`,
      paddingTop: '0.5em',
    },
    tariffBalanceInformation: {
      padding: '0.25em 0',
    },
    tariffBalanceInformationDescription: {
      paddingTop: '0.5em',
    },
    tariffArchiveWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: '44em',
      height: '100%',
      margin: '13em auto 0',
    },
    alertIcon: {
      color: primary['700'],
      opacity: '50%',
      height: '4em',
      width: '4em',
    },
    infoIcon: {
      color: tertiary['500'],
      marginRight: '0.5em',
    },
    warningIcon: {
      color: danger['600'],
      marginRight: '0.5em',
      minWidth: '1em',
      minHeight: '1em',
    },
    successIcon: {
      color: success['600'],
      marginRight: '0.5em',
    },
    tariffAdditionalOption: {
      padding: '1em 1.5em',
      borderTop: `solid 1px ${tertiary[300]}`,
      '&:first-child': {
        borderTop: 'none',
      },
    },
    tariffOptionSwitchCell: {
      minWidth: '3em',
      paddingTop: '0.125em',
      alignSelf: 'flex-start',
    },
    tariffOptionInfoCell: {
      width: '100%',
    },
    tariffOptionActionCell: {
      minWidth: '20em',
      minHeight: '2.5em',
    },
    tariffOptionActionCellWrapper: {
      width: '17.5em',
    },
    tariffOptionPriceCell: {
      minWidth: '11em',
      minHeight: '2.5em',
    },
    tariffAdditionalOptionDescription: {
      paddingTop: '0.125em',
    },
    tariffMultipleOption: {
      paddingRight: '1em',
    },
    tariffHiddenOption: {
      display: 'none',
    },
    tariffAdditionalTextPadding: {
      paddingRight: '1.5em',
    },
    tariffAdditionalFunctionalWrapper: {
      backgroundColor: tertiary[50],
      borderRadius: '0.75em',
      width: '71em',
    },
    tariffActionsWrapper: {
      padding: '0.5em 0',
      width: '71em',
    },
    tariffButton: {
      minWidth: '11em',
      marginLeft: '1.5em',
    },
    tariffOptionWarningWrapper: {
      paddingTop: '0.5em',
    },
    tariffOptionWarning: {
      borderRadius: '6px',
      padding: '0.75em',
      backgroundColor: base,
      border: `solid 1px ${tertiary[200]}`,
    },
    tariffLink: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    tooltip: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
    },
    tooltipRecordRetentionInfo: {
      width: pxToEm(304),
    },
    recordsStoringOptionLabelText: {
      marginLeft: '1.85em',
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
