import { makeStyles } from '@material-ui/core/styles';

export const useButtonGroupSwitchStyle = makeStyles(
  ({
    color: { base, tertiary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    root: {
      backgroundColor: tertiary[100],
      borderRadius: '5px',
      padding: '2px',
      position: 'relative',
    },
    buttonGroupContainer: {
      position: 'relative',
      zIndex: 1,
    },
    buttonGroupItem: {
      padding: '0.25em 0.875em',
    },
    buttonGroupSelectedBackground: {
      backgroundColor: base,
      borderRadius: '5px',
      position: 'absolute',
      zIndex: 0,
      top: '2px',
      bottom: '2px',
      transition: createTransition(['left'], {
        easing: sharp,
        duration: enteringScreen,
      }),
    },
  }),
  {
    index: 5,
    name: 'buttonGroupSwitch',
  }
);
