import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const NotificationStyles = makeStyles(
  ({ color: { tertiary, danger, primary } }) => ({
    root: {
      width: '56em',
      height: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      paddingLeft: '2em',
    },
    actions: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '& > :not(:first-child)': {
        marginLeft: '1em',
      },
    },
    button: {
      width: '14em',
      maxHeight: '2.5em',
      margin: '0.25em 0 1em',
    },
    cancelButton: {
      width: '8em',
      margin: '0.25em 0 1em',
    },
    confirmPopupDialog: {
      width: '22.5em',
    },

    confirmPopupText: {
      paddingBottom: '1.5em',
    },
    integrationRadioGroup: {
      display: 'flex',
      width: '23.5em',
      justifyContent: 'space-between',
    },
    redirectRadioGroup: {
      marginTop: '0.5em',
    },
    phoneNumberCard: {
      width: '21em',
      marginTop: '0.5em',
      position: 'relative',
      border: `solid 1px ${tertiary[100]}`,
      backgroundColor: tertiary[50],
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '1.5em 2em',
      '&:before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '0.875em',
        top: '-18px',
        borderWidth: '9px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${tertiary[100]} transparent`,
      },
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '0.875em',
        top: '-16px',
        borderWidth: '8px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${tertiary[50]} transparent`,
      },
    },
    employeeOrDepCard: {
      width: '21em',
      marginTop: '0.5em',
      position: 'relative',
      border: `solid 1px ${tertiary[100]}`,
      backgroundColor: tertiary[50],
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '1.5em 2em',
      '&:before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '13.25em',
        top: '-18px',
        borderWidth: '9px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${tertiary[100]} transparent`,
      },
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '13.25em',
        top: '-16px',
        borderWidth: '8px',
        borderStyle: 'solid',
        borderColor: `transparent transparent ${tertiary[50]} transparent`,
      },
    },
    recipientCard: {
      width: '36em',
      marginTop: '0.5em',
      position: 'relative',
      backgroundColor: tertiary[50],
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '1.5em 0.5em 1em 2em',
      '&:before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '-17px',
        top: '31px',
        borderWidth: '8.5px',
        borderStyle: 'solid',
      },
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '-15px',
        top: '31px',
        borderWidth: '8px',
        borderStyle: 'solid',
        borderColor: `transparent ${tertiary[50]} transparent transparent`,
      },
    },
    recipientCardNormal: {
      border: `solid 1px ${tertiary[100]}`,
      '&:before': {
        borderColor: `transparent ${tertiary[100]} transparent transparent`,
      },
    },
    recipientCardError: {
      border: `solid 1px ${danger[600]}`,
      '&:before': {
        borderColor: `transparent ${danger[600]} transparent transparent`,
      },
    },
    recipientCardTop: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '1.25em',
      marginBottom: '0.5em',
    },
    notifyTitle: {
      margin: '0.85em 0',
    },
    redirectRow: {
      height: '1.5em',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.5em',
    },
    expandContactsWrapper: {
      margin: '1em 0',
      '&:last-child': {
        margin: '1em 0 0 0 ',
      },
    },
    redirectText: {
      marginLeft: '1em',
    },
    redirectType: {
      width: '30%',
      color: tertiary[900],
    },
    notificationRecipientContainer: {
      display: 'flex',
      width: '54em',
      minHeight: '4em',
      justifyContent: 'space-between',
    },
    notificationFieldContainer: {
      width: '17em',
      paddingBottom: '1.25em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    addRecipientButton: {
      width: '17em',
    },
    additionalContainer: {
      margin: '2em 0 4.5em',
      borderTop: `1px solid ${tertiary[200]}`,
      paddingTop: '2em',
    },
    additionalFields: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        paddingTop: '1em',
      },
    },
    customOption: {
      display: 'flex',
      flexFlow: 'column nowrap',
      pointerEvents: 'none',
      height: '2.5em',
      justifyContent: 'center',
      width: '100%',
      padding: '0.7em 2.612em 0.75em 0.9em',
    },
    recipientCardErrorText: {
      height: '1.25em',
    },
    glyph: {
      marginTop: '-0.25em',
      marginRight: '0.5em',
      color: primary[700],
    },
    notificationIcon: {
      marginLeft: '0.25em',
      color: tertiary[400],
      '&:hover': {
        color: tertiary[400],
      },
    },
    trashIcon: {
      color: tertiary[400],
      '&:hover': {
        color: danger[700],
      },
    },
    expandBlockLeft: {
      minWidth: '30%',
      maxWidth: '30%',
      paddingRight: '1em',
    },
    textWrapper: {
      width: '100%',
      display: 'block',
    },
    expandBlockRight: {
      marginLeft: '0',
      marginRight: '2em',
      borderTop: `1px solid ${tertiary[200]}`,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
