import React, { FunctionComponent } from 'react';
import Preloader from '@shared/components/Preloader';
import { usePagePreloaderStyle } from './PagePreloader.styles';

export const PagePreloader: FunctionComponent = () => {
  const classes = usePagePreloaderStyle();

  return (
    <div className={classes.body}>
      <Preloader size={'large'} />
    </div>
  );
};

export default PagePreloader;
