import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import clsx from 'clsx';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import zxcvbn from 'zxcvbn';
import { passwordScores } from './PasswordField.constants';
import { InputPasswordProps } from './PasswordField.interfaces';
import { usePasswordFieldStyles } from './PasswordField.styles';
import { customPasswordGenerator } from './modules/CustomPasswordGenerator';
import { PasswordGenerateControls } from './modules/PasswordGenerateControls';
import PasswordScore from './modules/PasswordScore';
import { PasswordVisibilityControls } from './modules/PasswordVisibilityControls';

export const PasswordField = ({
  classNameContainer,
  generation = true,
  scoring = true,
  onChange,
  name = 'password',
  label,
  required,
  disabled,
  placeholder,
  InputProps,
  ...rest
}: InputPasswordProps) => {
  const classes = usePasswordFieldStyles();
  const [translate] = useTranslation();
  const {
    setValue,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [score, setScore] = useState<keyof typeof passwordScores | undefined>();
  const [showPassword, setShowPassword] = useState(false);

  const fieldType = showPassword ? 'text' : 'password';
  const isThereFieldError = errors?.[name]?.message !== undefined;

  const refTriggerRevalidationTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handlePasswordChange = useCallback(
    (value: string) => {
      let resultScore;
      let resultTitleCode;
      if (required || scoring) {
        const { score: passwordScore } = zxcvbn(value);
        resultScore = passwordScore;
        const { titleCode: scoredCode } = passwordScores[passwordScore];
        resultTitleCode = scoredCode;
      }
      if (value === '' || value === null) {
        resultScore = undefined;
        resultTitleCode = '';
      }
      if (generation) {
        setScore(resultScore);

        // trigger validation with updated score after component re-render
        if (refTriggerRevalidationTimeout.current) {
          clearTimeout(refTriggerRevalidationTimeout.current);
        }
        refTriggerRevalidationTimeout.current = setTimeout(() => {
          trigger(name);
          refTriggerRevalidationTimeout.current = null;
        }, 100);
      }
      if (onChange) {
        onChange({
          value,
          score: resultTitleCode,
        });
      }
    },
    [generation, name, onChange, required, scoring, trigger]
  );

  const generatePassword = useCallback(() => {
    const password = customPasswordGenerator();
    clearErrors(name);
    setValue(name, password);
    handlePasswordChange(password);
  }, [clearErrors, name, setValue, handlePasswordChange]);

  const togglePasswordVisibility = useCallback(() => {
    if (!InputProps?.readOnly && !disabled) setShowPassword((prevState) => !prevState);
  }, [InputProps, disabled]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    handlePasswordChange(value);
  };

  const getEndInputAdornment = () => (
    <PasswordVisibilityControls
      onClick={togglePasswordVisibility}
      isShowing={showPassword}
      disabled={!!disabled || !!InputProps?.readOnly}
    />
  );

  const validatePassword = (value: string) => {
    const pattern = /^[a-zA-Z0-9.,:;!#$%&@`'"*+/=?^_~|<>(){}[\]\-\\ ]*$/;
    if (scoring && !pattern.test(value)) {
      setScore(undefined);
      return translate('PROFILE_PASSWORD_CONTAINS_FORBIDDEN_LETTERS');
    }
    if (required && (value === '' || value === null)) {
      return translate('PASSWORD_EMPTY');
    }
    if (scoring && score !== undefined && value && score <= 2) {
      return translate('WEAK');
    }
    return true;
  };

  const getScoreHelperText = () => {
    if (isThereFieldError) {
      // we will retrieve error helper text down in the components chain in this case
      return null;
    }
    return <PasswordScore score={score} />;
  };

  return (
    <div className={clsx(classes.container, classNameContainer)}>
      <div className={classes.generationWrapper}>
        {generation && <PasswordGenerateControls onClick={generatePassword} disabled={disabled} />}
      </div>
      <FormFieldRhfUncontrolled
        name={name}
        type={fieldType}
        onChange={handleChange}
        label={label}
        placeholder={placeholder ?? translate('PASSWORD')}
        validate={validatePassword}
        disabled={disabled}
        InputProps={{
          endAdornment: getEndInputAdornment(),
          ...InputProps,
          inputProps: {
            maxLength: 25,
          },
        }}
        helperText={getScoreHelperText()}
        {...rest}
      />
    </div>
  );
};

export default PasswordField;
