import { makeStyles } from '@material-ui/core/styles';

export const usePageErrorStyle = makeStyles(({ color: { primary } }) => ({
  body: {
    maxHeight: '25em',
    height: '100%',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  alertIcon: {
    minWidth: '4em',
    minHeight: '4em',
    color: primary[700],
    opacity: '50%',
    marginBottom: '2.5em',
  },
}));
