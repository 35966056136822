import React, { FunctionComponent } from 'react';
import { Diagram } from '@components/Diagram';
import { ChartConfiguration, ChartType, InteractionItem } from 'chart.js';

export type DoughnutDiagramConfiguration = Pick<
  ChartConfiguration<ChartType>,
  'options' | 'data'
> & {
  onClick?: (points: InteractionItem[]) => void;
  onHover?: (points: InteractionItem[]) => void;
};

export const DoughnutDiagram: FunctionComponent<DoughnutDiagramConfiguration> = ({
  options,
  data,
  onClick,
  onHover,
}) => (
  <Diagram type={'doughnut'} options={options} data={data} onClick={onClick} onHover={onHover} />
);

export default DoughnutDiagram;
