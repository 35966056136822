import { makeStyles } from '@material-ui/core/styles';
import { IThemeOptions } from '@components/theme';
import { Theme } from '@material-ui/core';

export const useSchedulerDayStyles = makeStyles(
  ({
    color: { base, tertiary, secondary, links, success, primary, danger, warning },
  }: IThemeOptions & Theme) => ({
    drawerContent: {
      overflowY: 'hidden',
    },
    chart: {
      display: 'flex',
      flexFlow: 'column',
      padding: '1.5em 0',
      borderBottom: `1px solid ${tertiary[100]}`,
    },
    cellTertiary: {
      border: `1px solid ${tertiary[300]}`,
      opacity: 0.4,
      zIndex: 0,
    },
    cellSuccess: {
      background: success[200],
      border: `1px solid ${success[500]}`,
      '&$cellActive': {
        zIndex: 2,
        background: success[500],
        border: `1px solid ${success[500]}`,
      },
    },
    cellPrimary: {
      background: primary[200],
      border: `1px solid ${primary[500]}`,
      '&$cellActive': {
        zIndex: 2,
        background: primary[500],
        border: `1px solid ${primary[500]}`,
      },
    },
    cellSecondary: {
      background: secondary[200],
      border: `1px solid ${secondary[500]}`,
      '&$cellActive': {
        zIndex: 2,
        background: secondary[500],
        border: `1px solid ${secondary[500]}`,
      },
    },
    cellLinks: {
      background: links[200],
      border: `1px solid ${links[500]}`,
      '&$cellActive': {
        zIndex: 2,
        background: links[500],
        border: `1px solid ${links[500]}`,
      },
    },
    cellWarning: {
      background: warning[200],
      border: `1px solid ${warning[500]}`,
      '&$cellActive': {
        zIndex: 2,
        background: warning[600],
        border: `1px solid ${warning[800]}`,
      },
    },
    cellActive: {},
    indicator: {
      height: '16px',
      maxHeight: '16px',
      minHeight: '16px',
      width: '16px',
      maxWidth: '16px',
      minWidth: '16px',
      margin: '0 16px 0 8px',
      borderRadius: '3px',
    },
    default: {
      background: base,
      border: `1px solid ${tertiary[300]}`,
    },
    success: {
      background: success[300],
    },
    error: {
      background: danger[300],
    },
    primary: {
      background: primary[300],
    },
    links: {
      background: links[300],
    },
    hourMark: {
      height: '0.5em',
      width: '1px',
      background: tertiary[600],
      display: 'flex',
    },
    controlsActions: {
      padding: '1.5em 0',
    },
    scaleLabels: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
    },
    scaleMarks: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      padding: '0 0.0625px 0.25em',
      width: '100%',
    },
    chartPeriods: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    controlsList: {
      display: 'flex',
      flexFlow: 'column nowrap',
      overflow: 'auto',
      height: '100%',
    },
  })
);
