/* eslint-disable no-continue */
export const clearWhiteSpace = (value: string | undefined): string => {
  if (!value) return '';
  return value
    .replace(/(^\s*)/gi, '')
    .replace(/[ ]{2,}/gi, ' ')
    .replace(/[\n]{2,}/gi, '\n')
    .replace(/\n +/, '\n');
};

export function removeAllDotsExceptFirst(str: string) {
  let result = '';
  let isFirstDotFound = false;

  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '.') {
      if (!isFirstDotFound) {
        result += '.';
        isFirstDotFound = true;
      }
      continue;
    }
    result += str[i];
  }

  return result;
}

export function keepOnlyNCharactersAfterDot(str: string, n: number) {
  let result = '';
  let thereWasADot = false;
  let countSymbolsAfterDot = 0;

  for (let i = 0; i < str.length; i += 1) {
    if (thereWasADot) {
      if (countSymbolsAfterDot >= n) {
        return result;
      }
      countSymbolsAfterDot += 1;
    }

    if (str[i] === '.') {
      thereWasADot = true;
    }

    result += str[i];
  }

  return result;
}

export function keepOnlyLeadingMinus(str: string) {
  const isThereLeadingMinus = str[0] === '-';
  return isThereLeadingMinus ? `-${str.slice(1).replace(/-/g, '')}` : str.replace(/-/g, '');
}
