import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { Button, Input } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import { ChevronDownIcon, ChevronUpIcon } from '@shared/assets/images/icons';
import { useTimeFieldStyles } from './TimeField.styles';
// TODO: move to common helpers
const getInitialHours = (time = '') => {
  const match = /^(\d\d):(\d\d)$/gi.exec(time);
  if (match && match[1]) {
    const numberValue = Number(match[1]);
    if (Number.isFinite(numberValue) && numberValue < 24) {
      return match[1];
    }
  }
  return '';
};

const getInitialMinutes = (time = '') => {
  const match = /^(\d\d):(\d\d)$/gi.exec(time);
  if (match && match[2]) {
    const numberValue = Number(match[2]);
    if (Number.isFinite(numberValue) && numberValue < 60) {
      return match[2];
    }
  }
  return '';
};

export const TimeField = ({
  name = '',
  increment = 5,
  onFocus,
  autoFocus,
  value,
  onChange,
  validate,
}: // TODO: Typings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
{
  [key: string]: any;
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useFormContext() || {};
  const formValue = value === undefined ? watch(name) || '' : value || '';
  const hoursControl = useRef<HTMLInputElement | null>(null);
  const minutesControl = useRef<HTMLInputElement | null>(null);

  const classes = useTimeFieldStyles({});

  useEffect(() => {
    if (register && name) {
      register(name);
    }
  }, [register, name]);

  const handleChangeMinutes = useCallback(
    ({ target: { value: inputValue, ...targetRest }, ...rest }: ChangeEvent<HTMLInputElement>) => {
      let result = '';
      const filteredValue = inputValue.replace(/[^\d]/gi, '');
      const numberValue = Number(filteredValue);
      if (Number.isFinite(numberValue)) {
        result = filteredValue;
        if (numberValue > 59 || filteredValue.length > 2) {
          result = filteredValue.slice(-2);
        }
        if (Number(result) > 59) {
          result = result.slice(-1);
        }
        result = result.padStart(2, '0');
      }
      const hours = getInitialHours(formValue) || '00';
      if (name && validate) {
        const validateResult = validate(`${hours}:${result}`);
        if (validateResult !== true) {
          setError(name, { message: validateResult });
        }
      }
      if (setValue && name) {
        setValue(name, `${hours}:${result}`);
      }
      if (onChange) {
        onChange({ target: { value: `${hours}:${result}`, ...targetRest }, ...rest });
      }
    },
    [formValue, name, onChange, setError, setValue, validate]
  );

  const handleChangeHours = useCallback(
    ({ target: { value: inputValue, ...targetRest }, ...rest }: ChangeEvent<HTMLInputElement>) => {
      let minutesTransaction = '';
      let result = '';
      let filteredValue = inputValue.replace(/[^\d]/gi, '');
      const numberValue = Number(filteredValue);
      if (numberValue > 24) {
        minutesTransaction = filteredValue.slice(-1).padStart(2, '0');
        if (filteredValue.length > 2) {
          filteredValue = filteredValue.slice(0, 2);
        }
      } else {
        filteredValue = filteredValue.slice(-2);
      }
      if (Number.isFinite(numberValue)) {
        result = filteredValue.padStart(2, '0');
      }
      const minutes = getInitialMinutes(formValue) || '00';
      if (setValue && name) {
        setValue(name, `${result}:${minutesTransaction || minutes || '00'}`);
      }
      if (name && validate) {
        const validateResult = validate(`${result}:${minutesTransaction || minutes || '00'}`);
        if (validateResult !== true) {
          setError(name, { message: validateResult });
        }
      }
      if (onChange) {
        onChange({
          target: { value: `${result}:${minutesTransaction || minutes || '00'}`, ...targetRest },
          ...rest,
        });
      }
      if (minutesTransaction) {
        minutesControl?.current?.focus();
        minutesControl?.current?.select();
      }
    },
    [formValue, name, onChange, setError, setValue, validate]
  );

  const handleIncrement = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const minutes = getInitialMinutes(formValue);
      const hours = getInitialHours(formValue);
      let nextMinutes = Number(minutes || '0');
      const modulo = nextMinutes % increment;
      nextMinutes += increment - modulo;
      let nextHours: number | undefined;
      if (nextMinutes >= 60) {
        nextMinutes -= 60;
        nextHours = Number(hours || '00') + 1;
        if (nextHours >= 24) {
          nextHours -= 24;
        }
      }
      const resultHours =
        nextHours !== undefined ? `${nextHours}`.padStart(2, '0') : hours.padStart(2, '0');
      const resultMinutes = `${nextMinutes}`.padStart(2, '0');
      if (setValue && name) {
        setValue(name, `${resultHours}:${resultMinutes}`);
      }
      if (name && validate) {
        const validateResult = validate(`${resultHours}:${resultMinutes}`);
        if (validateResult !== true) {
          setError(name, { message: validateResult });
        }
      }
      if (onChange) {
        onChange({
          ...e,
          target: { value: `${resultHours}:${resultMinutes}`, ...(e?.target || {}) },
        });
      }
      minutesControl?.current?.focus();
      minutesControl?.current?.select();
    },
    [formValue, increment, name, onChange, setError, setValue, validate]
  );

  const handleDecrement = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const minutes = getInitialMinutes(formValue);
      const hours = getInitialHours(formValue);
      let nextMinutes = Number(minutes || '0');
      const modulo = nextMinutes % increment;
      nextMinutes -= modulo || increment;
      let nextHours: number | undefined;
      if (nextMinutes < 0) {
        nextMinutes += 60;
        nextHours = Number(hours || '00') - 1;
        if (nextHours < 0) {
          nextHours += 24;
        }
      }
      const resultHours =
        nextHours !== undefined ? `${nextHours}`.padStart(2, '0') : hours.padStart(2, '0');
      const resultMinutes = `${nextMinutes}`.padStart(2, '0');
      if (setValue && name) {
        setValue(name, `${resultHours}:${resultMinutes}`);
      }
      if (onChange) {
        onChange({
          ...e,
          target: { value: `${resultHours}:${resultMinutes}`, ...(e?.target || {}) },
        });
      }
      if (name && validate) {
        const validateResult = validate(`${resultHours}:${resultMinutes}`);
        if (validateResult !== true) {
          setError(name, { message: validateResult });
        }
      }
      minutesControl?.current?.focus();
      minutesControl?.current?.select();
    },
    [formValue, increment, name, onChange, setError, setValue, validate]
  );

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Button className={classes.increaseButton} tabIndex={'-1'} onClick={handleIncrement}>
          <ChevronUpIcon />
        </Button>
        <div className={classes.controlGroup}>
          <Input
            autoFocus={autoFocus}
            inputRef={hoursControl}
            value={getInitialHours(formValue)}
            onChange={handleChangeHours}
            onFocus={(e) => {
              hoursControl?.current?.select();
              if (onFocus) {
                onFocus(e);
              }
            }}
            placeholder={'--'}
            disableUnderline
            classes={{ input: classes.hoursInput }}
          />
          <div className={classes.separator}>{':'}</div>
          <Input
            inputRef={minutesControl}
            value={getInitialMinutes(formValue)}
            onFocus={(e) => {
              if (getInitialHours(formValue)) {
                minutesControl?.current?.select();
                if (onFocus) {
                  onFocus(e);
                }
              } else {
                hoursControl?.current?.focus();
                hoursControl?.current?.select();
              }
            }}
            onChange={handleChangeMinutes}
            placeholder={'--'}
            disableUnderline
            classes={{ input: classes.minutesInput }}
          />
        </div>
        <Button className={classes.decreaseButton} tabIndex={'-1'} onClick={handleDecrement}>
          <ChevronDownIcon />
        </Button>
      </div>
      {!!errors?.[name]?.message && (
        <Typography color={'danger600'} type={'text5'} className={classes.validationError}>
          {String(errors?.[name]?.message)}
        </Typography>
      )}
    </div>
  );
};

export default TimeField;
