import {
  scenarioSettingsType,
  scenariosListType,
} from '@/features/Integrations/IntegrationForm/IntegrationForm.interfaces';

export const YClientsScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'SCENARIO_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_01'],
    },
    {
      title: 'SCENARIO_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_03'],
    },
  ],
];
