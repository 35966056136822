import {
  FormHelperTextProps as FormHelperTextPropsType,
  InputAdornment,
  InputAdornmentProps,
  InputProps as InputPropsType,
  TextField as MuiTextField,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useId, useMemo } from 'react';
import { FormFieldUIProps } from './FormFieldUI.interfaces';
import {
  useAdornmentStyles,
  useFormFieldUIStyles,
  useHelperTextStyles,
  useLabelStyles,
} from './FormFieldUI.styles';

type EndAdornmentProps = {
  FieldAdornmentProps?: InputAdornmentProps;
  endAdornment?: React.ReactNode;
};

type InputPropsTypeModified = InputPropsType & {
  notched?: boolean;
};

const EndAdornment = ({ FieldAdornmentProps, endAdornment }: EndAdornmentProps) => {
  const classes = useAdornmentStyles();

  if (!endAdornment) {
    return null;
  }

  return (
    <InputAdornment
      classes={classes}
      {...(FieldAdornmentProps || {})}
      position={FieldAdornmentProps?.position || 'end'}
    >
      {endAdornment}
    </InputAdornment>
  );
};

const FormFieldUI = ({
  name = '',
  type = 'text',
  label,
  variant = 'outlined',
  select,
  InputProps: InputPropsFromProps,
  InputLabelProps,
  FieldAdornmentProps = { position: 'end' },
  multiline,
  value,
  error,
  FormHelperTextProps,
  ...props
}: FormFieldUIProps) => {
  const classes = useFormFieldUIStyles();
  const labelClasses = useLabelStyles();
  const helperTextClasses = useHelperTextStyles();
  const helperTextWrapperId = useId();

  const classesComputed = useMemo(
    () => ({
      ...classes,
      ...(InputPropsFromProps?.classes || {}),
      root: clsx(classes.root, InputPropsFromProps?.classes?.root),
      input: clsx(classes.input, InputPropsFromProps?.classes?.input),
      inputMarginDense: clsx(
        classes.inputMarginDense,
        InputPropsFromProps?.classes?.inputMarginDense
      ),
    }),
    [classes, InputPropsFromProps]
  );

  const InputProps: InputPropsTypeModified = useMemo(() => {
    const result: InputPropsTypeModified = {
      name,
      notched: false,
      'aria-describedby': helperTextWrapperId,
      ...InputPropsFromProps,
      endAdornment: InputPropsFromProps?.endAdornment ? (
        <EndAdornment
          FieldAdornmentProps={FieldAdornmentProps}
          endAdornment={InputPropsFromProps?.endAdornment}
        />
      ) : null,
      classes: classesComputed,
    };

    if (variant !== 'outlined') {
      result.disableUnderline = !select;
    }

    return result;
  }, [
    FieldAdornmentProps,
    InputPropsFromProps,
    classesComputed,
    name,
    select,
    variant,
    helperTextWrapperId,
  ]);

  const formHelperTextProps: Partial<FormHelperTextPropsType> = {
    ...FormHelperTextProps,
    id: helperTextWrapperId,
    classes: {
      ...helperTextClasses,
      root: clsx(helperTextClasses.root, FormHelperTextProps?.classes?.root),
    },
  };

  if (error) {
    formHelperTextProps.role = 'alert';
  }

  const inputLabelProps = {
    shrink: true,
    disableAnimation: true,
    ...(InputLabelProps || {}),
    classes: {
      ...labelClasses,
      ...(InputLabelProps?.classes || {}),
    },
  };

  return (
    <MuiTextField
      name={name}
      value={value}
      type={type}
      variant={variant}
      select={select}
      multiline={multiline}
      label={label}
      margin={'dense'}
      className={classes.root}
      InputProps={InputProps}
      InputLabelProps={inputLabelProps}
      error={error}
      FormHelperTextProps={formHelperTextProps}
      {...props}
    />
  );
};

export default FormFieldUI;
