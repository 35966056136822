import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';

export const useScenariosStyle = makeStyles(
  ({ color: { tertiary, primary } }: Theme & IThemeOptions) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    tableRoot: {
      tableLayout: 'fixed',
    },
    drawerInternalContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      paddingTop: 0,
      paddingBottom: 0,
    },
    drawerWrapper: {
      backgroundColor: tertiary[50],
    },
    drawerFooter: {
      paddingLeft: '2.250em',
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    addScenarioBtn: {
      width: '14em',
      height: '2em',
    },
    actionSmallButton: {
      padding: '0.5em',
      marginRight: '1.25em',
      '&:last-of-type': {
        marginRight: '0',
      },
    },
    bigCancelApplication: {
      width: '30%',
      height: '2em',
      margin: '1.5em 0 1em',
    },
    scenarioCard: {
      display: 'flex',
      minWidth: '41em',
      padding: '1.5em',
      borderRadius: '0.75em',
      backgroundColor: tertiary[50],
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: tertiary['100'],
      },
    },
    scenarioCardActive: {
      backgroundColor: tertiary['100'],
      outline: `1px solid ${primary[600]}`,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '93%',
      padding: '2em 2em',
    },
    formContent: {
      width: '23em',
      '& > *': {
        marginTop: '2em',
      },
    },
    addScenarioActions: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '14.5em',
    },
    drawerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'column',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      backgroundColor: tertiary['50'],
    },
    scenarioExampleCardBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    scenarioExampleCard: {
      flex: '0 1 10.5em',
      backgroundColor: 'white',
      borderRadius: '0.75em',
      border: `1px solid ${tertiary[300]}`,
      padding: '1.5em',
      marginBottom: '1em',
      '&:first-child': {
        marginTop: '2em',
      },
    },
    scenarioExampleCardTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '24em',
    },
    scenarioExampleCardTitle: {
      marginBottom: '0.5em',
      maxWidth: '20em',
    },
    scenarioExampleCardDescription: {
      whiteSpace: 'pre-wrap',
    },
    scenarioExampleCardControlsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '1em',
    },
    scenarioExampleCardRowContent: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    scenarioExampleCardButtonsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '28.5em',
      height: '2em',
      alignItems: 'end',
    },
    scenarioExampleCardBtnWrapper: {
      display: 'flex',
    },
    audioListenSmallControlButton: {
      alignItems: 'center',
      width: '2em',
      height: '2em',
      padding: '0.75em 0.5em',
    },
    schemaControlButton: {
      alignItems: 'center',
      fontSize: '1em',
      padding: '0.325em 1.125em',
      width: '5em',
      height: '2em',
      marginLeft: '0.5em',
    },
    closeIcon: {
      marginLeft: '1em',
      cursor: 'pointer',
      '&:hover': {
        color: primary[600],
      },
    },
    scenarioCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    scenarioCardLeftSide: {
      display: 'flex',
      flexDirection: 'column',
      width: '31em',
    },
    scenarioCardTitle: {
      paddingBottom: '0.5em',
    },
    scenarioCardRightSide: {
      display: 'flex',
      flexDirection: 'column',
      width: '5.75em',
      padding: '0.5em 0',
    },
    scenarioCardFeeBlock: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '0.5em',
    },
    scenarioCardControlsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    btnScenarioChoose: {
      width: '8em',
      height: '2em',
      backgroundColor: 'white',
      paddingTop: '3px',
      border: `solid 1px ${tertiary['300']}`,
      borderRadius: '0.375em',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: primary['100'],
        border: `solid 1px ${primary['600']}`,
      },
    },
    btnScenarioRadio: {
      marginTop: '-0.25em',
      paddingLeft: '1em',
    },
    btnScenarioExampleWidth: {
      width: '6em',
      height: '2em',
      '&:hover': {
        backgroundColor: primary['100'],
        border: `solid 1px ${primary['600']}`,
      },
    },
    btnScenarioApplication: {
      width: '10.75em',
      marginTop: '1.65em',
      height: '2em',
      backgroundColor: 'white',
      paddingTop: '3px',
      border: `solid 1px ${tertiary['300']}`,
      borderRadius: '5px',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: primary['100'],
        border: `solid 1px ${primary['600']}`,
      },
    },
    modalContentContainer: {
      paddingBottom: '0.5em',
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      scrollbarGutter: 'stable',
    },
    modalScenarioDialog: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'max-content',
    },
    dialogPaper: {
      maxWidth: 'max-content',
    },
    modalDialogRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      padding: '1em 1em',
    },
    modalScenarioImageOverlay: {
      overflow: 'auto',
      maxWidth: '100%',
      height: 'auto',
    },
    actionsContainer: {
      paddingTop: '1.5em',
      textAlign: 'right',
    },
    dialogContentCenter: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    buttonsContainerSmall: {
      paddingTop: '2em',
      textAlign: 'right',
    },
    activeBackground: {
      backgroundColor: primary['100'],
      border: `1px solid ${primary[600]}`,
    },
    marketDialog: {
      width: '24em',
      textAlign: 'left',
      whiteSpace: 'pre-line',
    },
    individualDialog: {
      width: '21em',
      textAlign: 'left',
      whiteSpace: 'pre-line',
    },
    aFontSize: {
      fontSize: '1.125em',
    },
    ...styleConstants,
  }),
  {
    index: 15,
  }
);
