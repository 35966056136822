import React from 'react';
import { ArrowRightIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import { useOutgoingCallsStyles } from '@/features/OutgoingCalls/OutgoingCalls.styles';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { OutgoingCallData } from '../OutgoingCalls';

export const OutgoingCallPhoneCell = ({ item }: ICellProps<OutgoingCallData>) => {
  const { number } = item || {};
  const classes = useOutgoingCallsStyles();
  const phone = number?.phone || ' - ';
  const city = number?.cityName;
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  return (
    <div className={classes.contentContainer}>
      <ArrowRightIcon className={classes.arrowIcon} />
      <div className={classes.description}>
        <HighlightedText type={'phone'} text={phone} query={searchText} />
        <Typography type={'text4'} color={'tertiary600'} className={classes.secondaryText}>
          {city || ''}
        </Typography>
      </div>
    </div>
  );
};
