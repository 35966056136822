import React from 'react';
import { Radio as MuiRadio, RadioProps } from '@material-ui/core';
import clsx from 'clsx';
import { useRadioStyles } from '@shared/components/Radio/Radio.styles';

export const Radio = ({ color, className, ...props }: RadioProps) => {
  const classes = useRadioStyles();

  return (
    <MuiRadio
      className={clsx(classes.root, className)}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      size={'small'}
      {...props}
    />
  );
};

export default Radio;
