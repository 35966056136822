import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signout } from '@components/client/client';
import { crucialDataLoadState$ } from '@/App';
import PreloaderPanel from '@shared/components/PreloaderPanel';

let isSignoutInProgress = false;

export const SignOutForm = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignoutInProgress) {
      return;
    }
    isSignoutInProgress = true;
    crucialDataLoadState$.status.set('none');
    signout().finally(() => {
      isSignoutInProgress = false;
      navigate('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PreloaderPanel />;
};
