import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import {
  IAMOPipelineDataList,
  ISettingInstalledProps,
  optionSettingType,
  rawDataListType,
  scenarioDirectionEnum,
  scenarioSettingsItemType,
  scenarioSettingsType,
} from '../../IntegrationForm.interfaces';
import { leadResponsibleForCalls, taskResponsibleForCalls } from '../../IntegrationForm.constants';
import { useScenarioSettingsLineProps } from '../../hooks/useScenarioSettingsLineProps.hooks';

export const ScenarioSettingsLine: FunctionComponent<{
  APIName: IntegrationList;
  integrationId?: number;
  scenarioItem: scenarioSettingsItemType;
  optionIndex: number;
  isOptionInstalled?: string | number;
  direction: scenarioDirectionEnum;
  comparedRawEmployee: rawDataListType | null;
  isScenarioSettingInstalled: ISettingInstalledProps | null;
  crmName: string | undefined;
}> = ({
  APIName,
  integrationId,
  scenarioItem,
  optionIndex,
  isOptionInstalled,
  direction,
  comparedRawEmployee,
  isScenarioSettingInstalled,
  crmName,
}) => {
  const {
    edit,
    installed: installText,
    notInstalled: notInstallText,
    unsortedInstalled: unsortedInstallText,
  } = scenarioItem;
  const [translate] = useTranslation();
  const isLeadOption = optionIndex === 0;
  const isTaskOption = optionIndex === 1;

  const { pipelineList, selectedSettingType } = useScenarioSettingsLineProps(
    APIName,
    isScenarioSettingInstalled,
    optionIndex,
    integrationId
  );

  const optionInstallText = useMemo(() => {
    if (selectedSettingType) {
      return selectedSettingType === optionSettingType.Unsorted ? unsortedInstallText : installText;
    }
    return installText;
  }, [installText, unsortedInstallText, selectedSettingType]);

  const responsibleNameText = () => {
    if (!isOptionInstalled || isOptionInstalled === 'on') return '';
    if (direction !== scenarioDirectionEnum.Common) {
      const isTalker = isOptionInstalled === 'talker';
      const isResponsible = isOptionInstalled === 'responsible';
      const notResponsibleOrTalker = !isTalker && !isResponsible;
      if (isLeadOption) {
        let responsibleTransKey = translate(leadResponsibleForCalls[direction][isOptionInstalled]);
        if (
          isResponsible &&
          (edit === scenarioSettingsType.IncomingUnknown ||
            edit === scenarioSettingsType.IncomingMissedUnknown)
        ) {
          responsibleTransKey = translate('DUTY_OFFICER');
        }
        if (isTalker && edit === scenarioSettingsType.IncomingMissedUnknown) {
          responsibleTransKey = translate('WHO_MISSED_CALL');
        }
        if (notResponsibleOrTalker) {
          const pipelineName = pipelineList.find(
            (pipeItem: IAMOPipelineDataList) => pipeItem.id === isOptionInstalled
          );
          if (selectedSettingType === optionSettingType.Unsorted) {
            return ` ${pipelineName?.name || ''}`;
          }
          if (selectedSettingType === optionSettingType.Contact) {
            return '';
          }
          if (selectedSettingType === optionSettingType.Lead) {
            if (pipelineName) {
              return ` ${pipelineName?.name || ''}`;
            }
            responsibleTransKey = translate('RESPONSIBLE_MANAGER_RECEIVING_CALL');
          }
        }
        return `, ${translate('RESPONSIBLE').toLowerCase()} - ${responsibleTransKey.toLowerCase()}`;
      }
      if (isTaskOption) {
        if (notResponsibleOrTalker) {
          return '';
        }
        return `, ${translate(
          taskResponsibleForCalls[direction][isOptionInstalled]
        ).toLowerCase()}`;
      }
    }
    return `${comparedRawEmployee?.find((e) => Number(e.id) === Number(isOptionInstalled))?.name}`;
  };

  if (isScenarioSettingInstalled && notInstallText && optionInstallText) {
    const customTextBlock = () => {
      if (isOptionInstalled) {
        return (
          <>
            {translate(optionInstallText[optionIndex], { crmName })}
            <Typography
              type={'default'}
              color={direction === scenarioDirectionEnum.Common ? 'primary700' : 'tertiary700'}
            >
              {responsibleNameText()}
            </Typography>
          </>
        );
      }
      return translate(notInstallText[optionIndex]);
    };
    return (
      <Typography type={'text4'} color={'tertiary700'}>
        {customTextBlock()}
      </Typography>
    );
  }

  return (
    <Typography type={'text4'} color={'tertiary700'}>
      {translate(scenarioItem.options[optionIndex], { crmName })}
    </Typography>
  );
};

export default ScenarioSettingsLine;
