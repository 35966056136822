import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useFilterPBXStyle = makeStyles(
  ({ color: { base, tertiary } }) => ({
    '@keyframes open': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    filterContainer: {
      position: 'relative',
      '& > div': {
        padding: 0,
      },
    },
    filterTrigger: {
      width: '20em',
      margin: 0,
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '4px',
      '& *': {
        cursor: 'pointer',
      },
    },
    input: {
      padding: '0.345em 0.5em 0.345em 0.825em',
      '&:read-only': {
        backgroundColor: base,
      },
    },
    filterWrapper: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      top: 0,
      width: '20em',
      height: '30em',
      backgroundColor: base,
      borderRadius: '0 0 0.75em 0.75em',
      boxShadow: '0px 0.5em 1.25em rgba(0, 0, 0, 0.1)',
      animation: '$open linear 0.2s',
      zIndex: 5,
    },
    titleClearIcon: {
      position: 'absolute',
      zIndex: 6,
      right: '1em',
      top: '0.5em',
      backgroundColor: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    searchInputRoot: {
      width: '100%',
      height: '2em',
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '3px',
      padding: '0 1em',
      background: '#fff',
    },
    clearIcon: {
      width: '1em',
      height: '1em',
      '& > :hover': {
        opacity: '.6',
        cursor: 'pointer',
      },
    },
    optionsWrapper: {
      overflow: 'auto',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: '23.5em',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    optionsList: {
      display: 'flex',
      flexDirection: 'column',
      width: '18em',
      paddingRight: '1.5em',
      whiteSpace: 'nowrap',
      overflowX: 'clip',
    },
    checkboxControl: {
      margin: '0.375em',
      padding: 0,
    },
    nothingFoundText: {
      textAlign: 'center',
      paddingLeft: '3.5em',
    },
    blockSubstrate: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '1.75em',
      backgroundColor: tertiary[50],
      margin: '0.5em 0',
    },
    groupTitle: {
      padding: '0 1em',
    },
    btnWrapper: {
      padding: '0.75em 0',
      borderTop: `1px solid ${tertiary[50]}`,
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
