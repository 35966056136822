import { UPDATE_COMMON_CALLS_RESTRICTIONS_MUTATION } from '@/client/mutations';
import { COMMON_CALLS_RESTRICTION_QUERY } from '@/client/queries';
import { CallRestrictionsDirectionsField } from '@/features/CallsRestrictions/modules';
import BodyContainer from '@/layouts/BodyContainer';
import { useMutation } from '@apollo/client';
import Drawer from '@components/Drawer';
import { CallRestrictionType } from '@/client/generated/graphql';
import Typography from '@shared/components/Typography';
import React, { FunctionComponent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCommonCallRestrictionsStyles } from './CommonCallsRestrictions.styles';

export type CommonCallRestrictionsProps = {
  restriction?: CallRestrictionType[];
};

export const CommonCallRestrictions: FunctionComponent<CommonCallRestrictionsProps> = ({
  restriction = [],
}: CommonCallRestrictionsProps) => {
  const classes = useCommonCallRestrictionsStyles({});
  const [translate] = useTranslation();
  const [isChanged, setIsChanged] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [updateCommonCallsRestrictions, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_COMMON_CALLS_RESTRICTIONS_MUTATION);

  const formMethods = useForm<Partial<{ local?: boolean; mg?: boolean; mn?: boolean }>>({
    defaultValues: {
      local: true,
      mg: restriction?.includes(CallRestrictionType.Mg),
      mn: restriction?.includes(CallRestrictionType.Mn),
    },
  });
  const { handleSubmit } = formMethods;

  const handleClose = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const handleSubmitForm = (formData: { local?: boolean; mn?: boolean; mg?: boolean }) => {
    if (updateLoading) return;
    const selectedRestriction: CallRestrictionType[] = [];
    if (formData.local) {
      selectedRestriction.push(CallRestrictionType.Local);
    }
    if (formData.mg) {
      selectedRestriction.push(CallRestrictionType.Mg);
    }
    if (formData.mn) {
      selectedRestriction.push(CallRestrictionType.Mn);
    }

    const options = {
      variables: {
        data: {
          restriction: selectedRestriction,
        },
      },
      refetchQueries: [{ query: COMMON_CALLS_RESTRICTION_QUERY }],
    };

    updateCommonCallsRestrictions(options)
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  };

  const handleChanges = () => {
    setIsChanged(true);
  };

  return (
    <BodyContainer>
      <Drawer
        title={'COMMON_RULES'}
        ModalProps={{ disableBackdropClick: isChanged }}
        elevation={4}
        open
        size={'xs'}
        primaryButton={{
          title: 'SAVE',
          props: {
            form: 'edit-personal-rules',
            type: 'submit',
            loading: updateLoading,
          },
        }}
        secondaryButton={{
          title: 'CANCEL',
          onClick: handleClose,
          props: {
            disabled: updateLoading,
          },
        }}
        onClose={handleClose}
      >
        <FormProvider {...formMethods}>
          <form
            id={'edit-personal-rules'}
            action={''}
            onSubmit={handleSubmit(handleSubmitForm)}
            className={classes.form}
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('ALLOWED_CALLS_DIRECTIONS')}
            </Typography>
            <div className={classes.formContent}>
              <CallRestrictionsDirectionsField onChange={handleChanges} />
            </div>
            {updateError && (
              <Typography type={'text4'} color={'danger600'}>
                {translate('SOMETHING_WENT_WRONG')}
              </Typography>
            )}
          </form>
        </FormProvider>
      </Drawer>
    </BodyContainer>
  );
};

export default CommonCallRestrictions;
