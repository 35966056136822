import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography/Typography';
import { AuthFormStyle } from './AuthForm.styles';

type AuthFormProps = {
  heading: string;
  text?: string;
  content: React.ReactNode;
};

export const AuthForm: FunctionComponent<AuthFormProps> = ({ heading, text, content }) => {
  const classes = AuthFormStyle();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography bold color={'tertiary900'} type={'text1'}>
          {heading}
        </Typography>
      </div>
      {!!text && (
        <div className={classes.text}>
          <Typography color={'tertiary900'} type={'text3'}>
            {text}
          </Typography>
        </div>
      )}
      <div className={classes.content}>{content}</div>
    </div>
  );
};
