import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlayIcon, XIcon } from '@shared/assets/images/icons';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SYNTHESIS_AUDIO_MUTATION, UPLOAD_FILE_MUTATION } from '@/client/mutations';
import { DOWNLOAD_URL_QUERY } from '@/client/queries';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { Button } from '@shared/components/Button/Button';
import RadioGroup from '@shared/components/RadioGroup';
import Radio from '@shared/components/Radio';
import UploadField, { uploadFieldOnChangeData } from '@components/UploadField';
import { setUploadFieldProgress } from '@components/utils';
import Preloader from '@shared/components/Preloader';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { useMessageFieldsStyles } from './MessageFields.styles';
import { IMessageFieldsProps } from './MessageFields.interfaces';

export enum AudioMessageTypes {
  Audio = 'AUDIO',
  Text = 'TEXT',
}

export const MessageFields = ({
  required = false,
  disabled = false,
  defaultValue,
  maxLength = null,
  onChanges,
  setIsSaveButtonLoading,
}: IMessageFieldsProps) => {
  const classes = useMessageFieldsStyles();
  const [translate] = useTranslation();

  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);
  const { watch, setError, setValue, register } = useFormContext();
  const messageType = watch('messageType');
  const message = watch('message');
  const fileHash = watch('fileHash');
  const fileUrl = watch('fileUrl');

  useEffect(() => {
    register('fileName');
    register('fileHash');
    register('fileUrl');
  }, [register]);

  const [isExpanded, setExpanded] = useState<boolean>(false);

  const onCompleted = () => {
    setExpanded(true);
  };

  const [
    synthesisAudio,
    {
      data: dataSynthesisAudio,
      loading: loadingSyntheticAudio,
      // error, TODO: handle errors
    },
  ] = useMutation(SYNTHESIS_AUDIO_MUTATION, { onCompleted });
  const syntheticAudioUrl = dataSynthesisAudio?.synthesis.url;

  const [
    getFileUrl, // TODO handle error
    {
      data: { downloadUrl = undefined } = {},
      loading: loadingAudioUrl,
      // TODO: handle errors
    },
  ] = useLazyQuery(DOWNLOAD_URL_QUERY, { onCompleted });

  const handleChanges = () => {
    if (typeof onChanges === 'function') {
      onChanges();
    }
  };

  useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled, setExpanded]);

  useEffect(() => {
    const urlValue = messageType === AudioMessageTypes.Audio ? downloadUrl : syntheticAudioUrl;
    setValue('fileUrl', urlValue);
  }, [messageType, setValue, downloadUrl, syntheticAudioUrl]);

  const handlePlay = useCallback(() => {
    if (fileUrl) {
      setExpanded(true);
    } else if (fileHash && messageType === AudioMessageTypes.Audio) {
      getFileUrl({ variables: { fileHash } });
    } else if (message && messageType === AudioMessageTypes.Text) {
      synthesisAudio({ variables: { text: message } });
    } else {
      setError('fileHash', {
        type: 'error',
        message: translate('AUDIO_FILE_IS_NOT_CHOSEN'),
      });
    }
  }, [fileUrl, fileHash, messageType, message, getFileUrl, synthesisAudio, setError, translate]);

  function handleAudioUpload({ urlName, hashName, progressName, file }: uploadFieldOnChangeData) {
    if (!file) {
      return;
    }
    setIsSaveButtonLoading?.(true);
    handleChanges();
    uploadFile({
      variables: { file },
      context: {
        fetchOptions: {
          onProgress: ({ loaded, total }: ProgressEvent) =>
            setUploadFieldProgress(loaded, total, setValue, progressName),
        },
      },
    })
      .then((res) => {
        setValue(urlName, res?.data?.uploadFile?.url);
        setValue(hashName, res?.data?.uploadFile?.filename);
      })
      .catch((error) => {
        let msg = translate('UPLOAD_ERROR');
        const messageFromError = getErrorMessageFromGraphqlError(error);
        if (messageFromError === 'content type error') {
          msg = translate('UPLOAD_AUDIO_ERROR');
        }
        setError(hashName, {
          type: 'error',
          message: msg,
        });
      })
      .finally(() => {
        setIsSaveButtonLoading?.(false);
      });
  }

  const renderFieldControls = () => (
    <div hidden={messageType !== AudioMessageTypes.Audio}>
      <UploadField
        className={classes.uploadField}
        accept={'audio/*'}
        onChange={handleAudioUpload}
        required={required && messageType === AudioMessageTypes.Audio}
        disabled={disabled}
        onCancel={() => setExpanded(false)}
        label={translate('CHOOSE_AUDIO_FILE')}
        requiredText={translate('CHOOSE_FILE')}
      />
    </div>
  );

  const renderAudioSectionControlContent = () => {
    if (loadingSyntheticAudio || loadingAudioUrl) {
      return <Preloader size={'medium'} color={'success'} />;
    }

    return (
      <>
        <PlayIcon className={classes.audioSectionControlButtonIcon} />
        {translate('LISTEN')}
      </>
    );
  };

  const renderAudioSectionControl = () => {
    if (isExpanded) {
      return null;
    }
    return (
      <Button
        variant={'secondary'}
        className={classes.audioSectionControlButton}
        disabled={
          disabled ||
          (!fileHash && messageType === AudioMessageTypes.Audio) ||
          (!message && messageType === AudioMessageTypes.Text) ||
          loadingSyntheticAudio ||
          loadingAudioUrl
        }
        onClick={handlePlay}
      >
        {renderAudioSectionControlContent()}
      </Button>
    );
  };

  const renderAudioSection = () => {
    if (!isExpanded || !fileUrl) {
      return null;
    }

    return (
      <div className={classes.audioControlsSection}>
        <AudioPlayer autoPlay isPaused={false} isExpanded collapseOnEnd={false} source={fileUrl} />
        <Button
          variant={'tertiary'}
          className={classes.audioControlButton}
          onClick={() => setExpanded(false)}
        >
          <XIcon />
        </Button>
      </div>
    );
  };

  return (
    <FormGroup>
      <RadioGroup defaultValue={defaultValue || ''} name={'messageType'}>
        <div className={classes.root}>
          <div hidden={isExpanded}>
            <FormControlLabel
              value={AudioMessageTypes.Text}
              control={
                <Radio
                  color={'secondary'}
                  disabled={disabled}
                  onChange={handleChanges}
                  className={classes.radioButton}
                />
              }
              label={translate('TEXT_TO_VOICE')}
            />
            <FormControlLabel
              value={AudioMessageTypes.Audio}
              control={
                <Radio
                  color={'secondary'}
                  disabled={disabled}
                  onChange={handleChanges}
                  className={classes.radioButton}
                />
              }
              label={translate('AUDIO_FILE')}
            />
          </div>
          {renderAudioSection()}
          {renderAudioSectionControl()}
        </div>
        <div className={classes.message} hidden={messageType !== AudioMessageTypes.Text}>
          <FormFieldRhfUncontrolled
            onChange={() => {
              setValue('fileUrl', '');
              setExpanded(false);
              handleChanges();
            }}
            name={'message'}
            fullWidth
            multiline
            rows={4}
            validate={(value: string) => {
              if (disabled || messageType !== AudioMessageTypes.Text || !!value || !required) {
                return true;
              }
              return translate('ENTER_MESSAGE') as string;
            }}
            disabled={disabled}
            inputProps={{ maxLength }}
          />
        </div>
        {renderFieldControls()}
      </RadioGroup>
    </FormGroup>
  );
};

export default MessageFields;
