import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import Drawer from '@components/Drawer';
import Typography from '@shared/components/Typography';
import { IDay, IHour, IPeriod, ISchedulePeriod } from '../Scheduler/Scheduler.interfaces';
import { useSchedulerDayStyles } from './SchedulerDay.styles';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';
import { getDayFiller } from '../Scheduler/Scheduler.helpers';
import SchedulerDayPeriodControls from './SchedulerDayPeriod';

const SchedulerDay = ({
  day,
  availablePeriods,
  onChange,
  onCancel,
}: {
  day: IDay;
  index: number;
  availablePeriods: ISchedulePeriod[];
  onChange: (day: IDay) => void;
  onCancel: () => void;
}) => {
  const classes = useSchedulerDayStyles({});
  const [translate] = useTranslation();

  const [daySchedule, setDaySchedule] = useState(day);
  const [periods, setPeriods] = useState<(IPeriod & { type: SchedulePeriodsTypes })[]>([]);
  const [newPeriods, setNewPeriods] = useState<(IPeriod & { type: SchedulePeriodsTypes })[]>([]);
  const [activePeriod, setActivePeriod] = useState<number | undefined>();
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setPeriods(
      daySchedule.hours.reduce<(IPeriod & { type: SchedulePeriodsTypes })[]>(
        (result, hour, hourIndex) => {
          const definePeriods = (index: number) => {
            let nextStarted = index;
            const currentPeriodType = hour.minutes[index];
            const currentlyStarted = index;
            const nextPeriodIndex = hour.minutes.findIndex(
              (minute, minuteIndex) => minuteIndex > nextStarted && minute !== currentPeriodType
            );
            nextStarted = nextPeriodIndex !== -1 ? nextPeriodIndex : 60;
            const end = hourIndex * 60 + nextStarted - 1;
            if (result.length && result[result.length - 1].type === currentPeriodType) {
              result[result.length - 1] = { ...result[result.length - 1], end };
            } else {
              result.push({
                start: hourIndex * 60 + currentlyStarted,
                end,
                type: currentPeriodType,
              });
            }
            if (nextStarted < 60) definePeriods(nextStarted);
          };

          definePeriods(0);

          return result;
        },
        []
      )
    );
  }, [daySchedule]);

  const handleChanges = useCallback(() => {
    onChange(daySchedule);
    onCancel();
  }, [daySchedule, onCancel, onChange]);

  const handleUIChanges = () => {
    setIsChanged(true);
  };

  const handleChangePeriod = (period: IPeriod, type = SchedulePeriodsTypes.NonWorkingHours) => {
    setDaySchedule((prevState) => getDayFiller([period], type)(prevState, 0));
    handleUIChanges();
  };
  // TODO: get rid of exploiting NaN type
  const handleAddPeriod = () => {
    setNewPeriods((prevState) => [
      ...prevState,
      { start: NaN, end: NaN, type: SchedulePeriodsTypes.WorkingHours },
    ]);
    handleUIChanges();
  };

  const handleChangeNewPeriod =
    (index: number) => (changes: IPeriod, type: SchedulePeriodsTypes) => {
      setNewPeriods((prevState) => {
        const newState = prevState.map((pe) => ({ ...pe }));
        newState.splice(index, 1);
        return newState;
      });
      handleChangePeriod(changes, type);
    };

  const handleRemoveNewPeriod = (index: number) => () => {
    setActivePeriod(undefined);
    setNewPeriods((prevState) => prevState.filter((_, i) => i !== index));
  };

  const renderScaleLabel = (data: IHour | undefined, hourIndex: number) => {
    if (!((hourIndex + 1) % 6) || hourIndex === 0) {
      const hours = `${hourIndex ? hourIndex + 1 : hourIndex}`.padStart(2, '0');

      return (
        <Typography key={`scale-hour-${hourIndex}`} type={'text4'} color={'tertiary900'}>
          {`${hours}:00`}
        </Typography>
      );
    }
    return null;
  };

  const renderHourMark = (data: IHour | undefined, hourIndex: number) => (
    <div key={`day-scale-mark-${hourIndex}`} className={classes.hourMark} />
  );

  const renderPeriod = ({ start, end, type }: IPeriod & { type: string }, index: number) => (
    <div
      key={`period-${start}`}
      className={clsx({
        [classes.cellTertiary]: type === SchedulePeriodsTypes.NonWorkingHours,
        [classes.cellSuccess]: type === SchedulePeriodsTypes.WorkingHours,
        [classes.cellWarning]: type === SchedulePeriodsTypes.Custom1,
        [classes.cellLinks]: type === SchedulePeriodsTypes.Custom2,
        [classes.cellSecondary]: type === SchedulePeriodsTypes.Custom3,
        [classes.cellActive]: activePeriod !== undefined && activePeriod === index,
      })}
      style={{ width: `${(end - start) / (1439 / 100)}%`, height: '2em' }}
    />
  );

  const renderExistingPeriod = (
    period: IPeriod & { type: SchedulePeriodsTypes },
    index: number
  ) => {
    if (period.type && period.type !== SchedulePeriodsTypes.NonWorkingHours) {
      return (
        <SchedulerDayPeriodControls
          key={`day-period-${index}`}
          id={`day-period-${index}`}
          initialPeriod={period}
          activePeriod={activePeriod === index}
          setActivePeriod={() => setActivePeriod(index)}
          setInactive={() => setActivePeriod(undefined)}
          onChange={handleChangePeriod}
          onRemove={handleChangePeriod}
          availablePeriods={availablePeriods}
          onChanges={handleUIChanges}
        />
      );
    }
    return null;
  };

  const renderNewPeriod = (period: IPeriod & { type: SchedulePeriodsTypes }, index: number) => {
    if (period.type && period.type !== SchedulePeriodsTypes.NonWorkingHours) {
      return (
        <SchedulerDayPeriodControls
          key={`new-period-${index}`}
          id={`new-period-${index}`}
          initialPeriod={period}
          activePeriod={activePeriod === index}
          setActivePeriod={() => setActivePeriod(index + periods.length)}
          setInactive={() => setActivePeriod(undefined)}
          onChange={handleChangeNewPeriod(index)}
          onRemove={handleRemoveNewPeriod(index)}
          availablePeriods={availablePeriods}
          onChanges={handleUIChanges}
        />
      );
    }
    return null;
  };

  return (
    <Drawer
      title={translate(day.titleCode)}
      width={'39em'}
      ModalProps={{ disableBackdropClick: isChanged }}
      elevation={4}
      open
      onClose={onCancel}
      contentClass={classes.drawerContent}
      primaryButton={{
        title: 'CONFIRM',
        onClick: handleChanges,
      }}
      secondaryButton={{
        title: 'CANCEL',
        onClick: onCancel,
      }}
    >
      <div className={classes.chart}>
        <div className={classes.scaleLabels}>{daySchedule.hours.map(renderScaleLabel)}</div>
        <div className={classes.scaleMarks}>
          {[...daySchedule.hours, undefined].map(renderHourMark)}
        </div>
        <div className={classes.chartPeriods}>{periods.map(renderPeriod)}</div>
      </div>
      <div className={classes.controlsList}>
        {periods.map(renderExistingPeriod)}
        {newPeriods.map(renderNewPeriod)}
        <div className={classes.controlsActions}>
          <Button
            title={translate('ADD')}
            fullWidth
            variant={'secondary'}
            onClick={handleAddPeriod}
            disabled={activePeriod !== undefined}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SchedulerDay;
