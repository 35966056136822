import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_INTEGRATION_DETAILS_QUERY } from '@/client/queries';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MessageDialog from '@shared/components/MessageDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { PreviewDialog } from '@components/PreviewDialog/PreviewDialog';
import WhereToFindImg from './images/roistat_help.png';
import { IIntegrationStaticAuthorizationProps } from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';

export const RoistatCRMAuthorization: FunctionComponent<IIntegrationStaticAuthorizationProps> = ({
  url,
  integrationId,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const navigate = useNavigate();
  const { setValue, clearErrors } = formMethods;
  const [searchParams] = useSearchParams();
  const sError = searchParams.get('error');
  // TODO handle lazy query error
  const [getIntegrationDetails, { data: currentIntegrationData }] = useLazyQuery(
    GET_INTEGRATION_DETAILS_QUERY
  );
  const currentIntegration = currentIntegrationData?.getIntegrationDetails;
  const [whereToFindShow, setWhereToFindShow] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: '',
  });

  useEffect(() => {
    if (integrationId && !url) {
      getIntegrationDetails({
        variables: {
          id: integrationId,
        },
      });
    }
  }, [integrationId, url, getIntegrationDetails]);

  useEffect(() => {
    if (currentIntegration || url) {
      setValue('crmAddress', currentIntegration?.url || url);
    }
  }, [currentIntegration, setValue, url]);

  useEffect(() => {
    if (sError) {
      setErrorDialogState((prevState) => ({
        ...prevState,
        isOpen: true,
        message: 'SOMETHING_WRONG_MESSAGE',
      }));
    }
  }, [sError]);

  function handleCloseErrorDialog() {
    setErrorDialogState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    if (sError) {
      navigate('/integrations');
    }
  }

  function handleWhereToFindClick() {
    setWhereToFindShow(true);
  }

  return (
    <>
      {!integrationId ? (
        <div className={classes.integrationAuthTitleBlock}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('ROISTAT_INSTALL_APP_TITLE')}
          </Typography>
        </div>
      ) : null}
      <div className={classes.integrationAuthFieldBlock}>
        <FormFieldRhfUncontrolled
          label={translate('ROISTAT_ADDRESS')}
          name={'crmAddress'}
          InputProps={{ readOnly: !!(currentIntegration || url) }}
          validate={(value: string) =>
            !value.match(/^https:\/\/cloud.roistat.com\/(projects\/\d+\/?|.+projectId=\d+)/)
              ? translate('ROISTAT_INVALID_URL')
              : true
          }
          onChange={() => clearErrors('crmAddress')}
        />
      </div>
      <div className={classes.integrationAuthFieldBlock}>
        {!integrationId ? (
          <Button
            onClick={handleWhereToFindClick}
            clear
            className={classes.integrationAuthWhereToFindButton}
          >
            <Typography type={'text4'} color={'link600'} underline>
              {`${translate('WHERE_SEARCH')}?`}
            </Typography>
          </Button>
        ) : null}
        <FormFieldRhfUncontrolled
          label={translate('API_KEY')}
          name={'crmKey'}
          InputProps={{ readOnly: !!(currentIntegration || url) }}
          validate={(value: string) => (!value ? translate('ROISTAT_INVALID_KEY') : true)}
          onChange={() => clearErrors('crmKey')}
        />
      </div>
      <MessageDialog
        isOpen={errorDialogState.isOpen}
        contentClass={classes.integrationDialog}
        title={translate(errorDialogState.title)}
        message={translate(errorDialogState.message)}
        onCancel={handleCloseErrorDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
        }
      />
      <PreviewDialog
        isOpen={whereToFindShow}
        onCancel={() => setWhereToFindShow(false)}
        data={[
          {
            title: {
              key: 'WHERE_SEARCH_ROISTAT_MESSAGE',
              components: {
                t: <Typography color={'tertiary900'} type={'text3'} />,
                b: <Typography color={'tertiary900'} type={'default'} bold />,
              },
            },
            img: WhereToFindImg,
          },
        ]}
      />
    </>
  );
};

export default memo(RoistatCRMAuthorization);
