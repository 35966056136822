import { useEffect, useMemo } from 'react';
import {
  GET_AMO_EMPLOYEE_LIST_QUERY,
  GET_BITRIX_EMPLOYEES_LIST_QUERY,
  GET_RETAIL_EMPLOYEE_LIST_QUERY,
} from '@/client/queries';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import {
  employeeAmoListType,
  employeeBitrixListType,
  employeeRetailListType,
} from '../IntegrationForm.interfaces';

export function useCRMEmployee(CRMType: IntegrationList, id?: number) {
  const [translate] = useTranslation();
  const { queryName, responseKey } = useMemo(() => {
    switch (CRMType) {
      case IntegrationList.AmoCrm: {
        return {
          queryName: GET_AMO_EMPLOYEE_LIST_QUERY,
          responseKey: 'getAmoEmployeesList',
        };
      }
      case IntegrationList.Bitrix24: {
        return {
          queryName: GET_BITRIX_EMPLOYEES_LIST_QUERY,
          responseKey: 'getBitrixEmployeesList',
        };
      }
      case IntegrationList.RetailCRM: {
        return {
          queryName: GET_RETAIL_EMPLOYEE_LIST_QUERY,
          responseKey: 'getRetailCrmUsersList',
        };
      }
      default: {
        return {
          queryName: GET_BITRIX_EMPLOYEES_LIST_QUERY,
          responseKey: 'getBitrixEmployeesList',
        };
      }
    }
  }, [CRMType]);

  const [getIntegrationEmployeeList, { data: employeeData, error: employeeListError }] =
    useLazyQuery(queryName, {
      variables: { data: { id } },
    });

  const employeeList = employeeData ? employeeData[responseKey] : null;

  useEffect(() => {
    if (!employeeData && !employeeListError) {
      getIntegrationEmployeeList();
    }
  }, [CRMType, employeeData, employeeListError, getIntegrationEmployeeList, id]);

  const setEmployeeList = () => {
    if (!employeeList) return null;
    let rawEmployeeList = null;
    if (CRMType === IntegrationList.Bitrix24) {
      rawEmployeeList = employeeList.map((bitrixEmployee: employeeBitrixListType) => {
        const { firstName, lastName, secondName, email, id: employeeId } = bitrixEmployee || {};
        let employeeName = '';
        if (!firstName && !lastName && !secondName && !email) {
          employeeName = `${translate('NO_NAME')} ${employeeId}`;
        } else if (!firstName && email) {
          employeeName += email || '';
        } else {
          employeeName += firstName || '';
          employeeName += secondName ? ` ${secondName}` : '';
          employeeName += lastName ? ` ${lastName}` : '';
        }
        return {
          id: Number(employeeId),
          email,
          name: employeeName,
        };
      });
    }
    if (CRMType === IntegrationList.AmoCrm) {
      rawEmployeeList = employeeList.map((amoEmployee: employeeAmoListType) => {
        const { name, email, id: employeeId } = amoEmployee || {};
        let employeeName = '';
        if (!name && !email) {
          employeeName = `${translate('NO_NAME')} ${employeeId}`;
        } else if (!name && email) {
          employeeName += email || '';
        } else {
          employeeName += name || '';
        }
        return {
          id: Number(employeeId),
          email,
          name: employeeName,
        };
      });
    }
    if (CRMType === IntegrationList.RetailCRM) {
      rawEmployeeList = employeeList.map((retailEmployee: employeeRetailListType) => {
        const { firstName, lastName, patronymic, email, id: employeeId } = retailEmployee || {};
        let employeeName = '';
        if (!firstName && !lastName && !patronymic && !email) {
          employeeName = `${translate('NO_NAME')} ${employeeId}`;
        } else if (!firstName && email) {
          employeeName += email || '';
        } else {
          employeeName += firstName || '';
          employeeName += patronymic ? ` ${patronymic}` : '';
          employeeName += lastName ? ` ${lastName}` : '';
        }
        return {
          id: Number(employeeId),
          email,
          name: employeeName,
        };
      });
    }
    if (!rawEmployeeList) return null;
    return rawEmployeeList;
  };

  const integrationEmployeeList = setEmployeeList();

  return {
    integrationEmployee: integrationEmployeeList,
    error: employeeListError,
    refetch: getIntegrationEmployeeList,
  };
}
