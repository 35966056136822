import {
  MessageSquareIcon,
  PhoneForwardedIcon,
  NumbersIcon,
  VoicemailIcon,
  FaxIcon,
  MicIcon,
} from '@shared/assets/images/icons';
import { IMenuItem } from '@components/Menu';
import { ScenariosTypes, SchedulingMode } from './IncomingNumber.interfaces';

export const SchedulingModesOptions = [
  { value: SchedulingMode.ATC, titleCode: 'AROUND_THE_CLOCK' },
  { value: SchedulingMode.Scheduled, titleCode: 'SCHEDULED' },
];

export const redirectOptions = [
  { value: ScenariosTypes.RedirectToNumber, titleCode: 'EXTERNAL_NUMBER' },
  { value: ScenariosTypes.RedirectToEmployee, titleCode: 'TO_EMPLOYEE' },
  { value: ScenariosTypes.RedirectToDepartment, titleCode: 'TO_DEPARTMENT' },
];

export const settingTypeCurrentList = {
  [ScenariosTypes.RedirectToNumber]: 'REDIRECT_TO_NUMBER',
  [ScenariosTypes.RedirectToEmployee]: 'REDIRECT_TO_EMPLOYEE',
  [ScenariosTypes.RedirectToDepartment]: 'REDIRECT_TO_DEPARTMENT',
};

export const voiceMenuButtonsOptions = [
  { value: '1', titleCode: 'BUTTON_1' },
  { value: '2', titleCode: 'BUTTON_2' },
  { value: '3', titleCode: 'BUTTON_3' },
  { value: '4', titleCode: 'BUTTON_4' },
  { value: '5', titleCode: 'BUTTON_5' },
  { value: '6', titleCode: 'BUTTON_6' },
  { value: '7', titleCode: 'BUTTON_7' },
  { value: '8', titleCode: 'BUTTON_8' },
  { value: '9', titleCode: 'BUTTON_9' },
  { value: '0', titleCode: 'BUTTON_0' },
  // { value: '#', titleCode: 'HASH_BUTTON' },
  // { value: '*', titleCode: 'ASTERISK_BUTTON' },
];

export const numberSettingsTitleCodes: Map<string, string> = new Map([
  [ScenariosTypes.Message, 'GREETING_MESSAGE'],
  [ScenariosTypes.SmartGreeting, 'SMART_GREETING'],
  [ScenariosTypes.VoiceMenu, 'VOICE_MENU'],
  [ScenariosTypes.RedirectToNumber, 'REDIRECT_TO_NUMBER'],
  [ScenariosTypes.RedirectToEmployee, 'REDIRECT_TO_EMPLOYEE'],
  [ScenariosTypes.RedirectToDepartment, 'REDIRECT_TO_DEPARTMENT'],
  [ScenariosTypes.Voicemail, 'REDIRECT_TO_VOICEMAIL'],
  [ScenariosTypes.Fax, 'FAX'],
]);

export const incomingNumberSettingsMenuItems: IMenuItem<ScenariosTypes | 'separator'>[] = [
  {
    id: ScenariosTypes.Message,
    Icon: MessageSquareIcon,
    titleCode: 'MESSAGE',
  },
  {
    id: 'separator',
  },
  {
    id: ScenariosTypes.SmartGreeting,
    Icon: MicIcon,
    titleCode: 'SMART_GREETING',
  },
  {
    id: 'separator',
  },
  {
    id: ScenariosTypes.VoiceMenu,
    Icon: NumbersIcon,
    titleCode: 'VOICE_MENU',
  },
  {
    id: 'separator',
  },
  {
    id: ScenariosTypes.RedirectToNumber,
    Icon: PhoneForwardedIcon,
    titleCode: 'REDIRECT_TO_NUMBER',
  },
  {
    id: ScenariosTypes.RedirectToEmployee,
    Icon: PhoneForwardedIcon,
    titleCode: 'REDIRECT_TO_EMPLOYEE',
  },
  {
    id: ScenariosTypes.RedirectToDepartment,
    Icon: PhoneForwardedIcon,
    titleCode: 'REDIRECT_TO_DEPARTMENT',
  },
  {
    id: 'separator',
  },
  {
    id: ScenariosTypes.Voicemail,
    Icon: VoicemailIcon,
    titleCode: 'VOICEMAIL',
  },
  {
    id: ScenariosTypes.Fax,
    Icon: FaxIcon,
    titleCode: 'FAX',
  },
];

export const callDistributionTimeoutOptions = [
  { value: 5, titleCode: 'FIVE_SECONDS' },
  { value: 10, titleCode: 'TEN_SECONDS' },
  { value: 15, titleCode: 'FIFTEEN_SECONDS' },
  { value: 20, titleCode: 'TWENTY_SECONDS' },
  { value: 25, titleCode: 'TWENTY_FIVE_SECONDS' },
  { value: 30, titleCode: 'THIRTY_SECONDS' },
];
export const redirectEmployeeTimeoutOptions = [
  { value: 10, titleCode: 'TEN_SECONDS' },
  { value: 15, titleCode: 'FIFTEEN_SECONDS' },
  { value: 20, titleCode: 'TWENTY_SECONDS' },
  { value: 30, titleCode: 'THIRTY_SECONDS' },
  { value: 60, titleCode: 'MINUTE' },
];
export const redirectDepartmentTimeoutOptions = [
  { value: 15, titleCode: 'FIFTEEN_SECONDS' },
  { value: 20, titleCode: 'TWENTY_SECONDS' },
  { value: 30, titleCode: 'THIRTY_SECONDS' },
  { value: 60, titleCode: 'MINUTE' },
  { value: 120, titleCode: 'TWO_MINUTES' },
  { value: 300, titleCode: 'FIVE_MINUTES' },
  { value: 600, titleCode: 'TEN_MINUTES' },
];

export const defaultMessages = new Map<ScenariosTypes, string>([
  [ScenariosTypes.Message, 'DEFAULT_MESSAGE_SCENARIO_TEXT'],
  [ScenariosTypes.VoiceMenu, 'DEFAULT_VOICE_MENU_SCENARIO_TEXT'],
  [ScenariosTypes.RedirectToDepartment, 'DEFAULT_REDIRECT_TO_DEPARTMENT_SCENARIO_TEXT'],
  [ScenariosTypes.RedirectToEmployee, 'DEFAULT_REDIRECT_TO_EMPLOYEE_SCENARIO_TEXT'],
  [ScenariosTypes.RedirectToNumber, 'DEFAULT_REDIRECT_TO_NUMBER_SCENARIO_TEXT'],
  [ScenariosTypes.Voicemail, 'DEFAULT_VOICEMAIL_SCENARIO_TEXT'],
  [ScenariosTypes.Fax, 'DEFAULT_FAX_SCENARIO_TEXT'],
]);
