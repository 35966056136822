import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { IAssistant } from '@components/typings/interfaces';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { useScenariosStyle } from '../Scenarios.styles';

export const ScenarioNameCell: FunctionComponent<ICellProps<IAssistant>> = ({ item }) => {
  const classes = useScenariosStyle();

  if (!item) {
    return <div />;
  }

  const { name, marketAssistant } = item;
  return (
    <Flex direction={'column'} className={classes.defaultElementWidthFull}>
      <Typography className={classes.textOverflow} type={'text3'} color={'tertiary900'}>
        {marketAssistant?.name || name}
      </Typography>
    </Flex>
  );
};
