import { makeStyles, Theme } from '@material-ui/core/styles';

export const useSidebarStyles = makeStyles<Theme>(
  ({
    color: { base, tertiary },
    zIndex: { drawer: drawerZIndex },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { leavingScreen, enteringScreen },
    },
    gradients: { main: gradientMain },
    spacing,
    breakpoints: { up, down },
    mixins: { toolbar: toolbarMixin },
  }) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: drawerZIndex + 1,
      transition: createTransition(['width', 'margin'], {
        easing: sharp,
        duration: leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: '15em',
      width: 'calc(100% - 15em)',
      transition: createTransition(['width', 'margin'], {
        easing: sharp,
        duration: enteringScreen,
      }),
    },
    menuButton: {
      marginRight: '2.25em',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: '15em',
      borderRight: 'none',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      overflowY: 'auto',
      transition: createTransition('width', {
        easing: sharp,
        duration: enteringScreen,
      }),
    },
    drawerOpen: {
      width: '15em',
      [down('sm')]: {
        width: spacing(10),
      },
    },
    drawerClose: {
      overflowX: 'hidden',
      width: spacing(10) + 1,
      [up('sm')]: {
        width: spacing(10),
      },
      transition: createTransition('width', {
        easing: sharp,
        duration: leavingScreen,
      }),
    },
    paper: {
      border: 'none',
      background: gradientMain,
      overflowX: 'hidden',
      transition: createTransition('width', {
        easing: sharp,
        duration: enteringScreen,
      }),
      minHeight: '530px',
    },
    toolbar: {
      display: 'block',
      ...toolbarMixin,
      color: base,
      overflow: 'hidden',
      overflowY: 'hidden',
      marginTop: '-6px',
    },
    logoButton: {
      fontSize: '1em',
      display: 'flex',
      textAlign: 'left',
      outline: 'none',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      transition: 'all .2s',
      height: '7.625em',
    },
    logoCollapsed: {
      padding: '1.25em 0 0 0',
      justifyContent: 'center',
    },
    logoExpanded: {
      padding: '1.25em 0 0 2em',
    },
    iconColor: {
      color: base,
    },
    gutters: {
      padding: '1em 2em',
    },
    listItem: {
      height: '5em',
      position: 'absolute',
      bottom: '0em',
      paddingLeft: '2em',
      color: base,
      '&:hover': {
        background: tertiary[700],
      },
    },
    listItemIcon: {
      width: '1em',
      minWidth: '1em',
      maxWidth: '1em',
      height: '1em',
      minHeight: '1em',
      maxHeight: '1em',
      marginRight: '1em',
      color: 'inherit',
    },
    listItemText: {
      visibility: 'hidden',
      color: 'inherit',
      opacity: 0,
      transition: 'visibility 0s, opacity 0.3s linear',
    },
    listItemTextActive: {
      visibility: 'visible',
      opacity: 1,
    },
    icon: {
      width: '1em',
      height: '1em',
    },
  }),
  {
    index: 1,
  }
);
