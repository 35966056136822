import React, { memo, useCallback, useMemo } from 'react';
import { useDesktopPageStyle } from '@/features/Desktop/DesktopPage.styles';
import { useTranslation } from 'react-i18next';
import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_TELEPHONY_COSTS_QUERY, USER_QUERY } from '@/client/queries';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { BarDiagram } from '@components/Diagram';
import { findWeekDay, formatDay, toPrecision } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { getDateList } from '@/utils';
import { addDays, format, subDays } from 'date-fns';
import { InteractionItem, TooltipPositioner, TooltipYAlignment } from 'chart.js';
import Preloader from '@shared/components/Preloader';
import { useNavigate } from 'react-router-dom';
import PageError from '@shared/components/PageError';
import { findMonth, weekNames } from '../DesktopPage.constants';
import { ITelephonyDaysResult } from '../DesktopPage.interfaces';

type DayValues = {
  todayAmount: number;
  minAmount: number;
  maxAmount: number;
  minDayName: string;
  maxDayName: string;
  todayDayName: string;
};

export const TelephonyWidget = () => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();
  const theme: IThemeColorOptions = useTheme();

  const { data } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const user = data?.user;

  const currentDomain = getCurrentDomain(user);
  const createdAt = currentDomain?.createdAt ? new Date(currentDomain?.createdAt) : new Date();
  const {
    data: dashboardTelephoneCosts,
    loading,
    error: { graphQLErrors: getTelephoneCostsErrors = null } = {},
  } = useQuery(GET_DASHBOARD_TELEPHONY_COSTS_QUERY);
  const telephoneCosts = dashboardTelephoneCosts?.dashboardTelephonyCosts;
  const navigate = useNavigate();

  const { currentWeek, endWeek } = getDateList();
  const now = new Date();
  let currentWeekDay = now.getDay() - 1;
  let weekStart = 0;
  if (createdAt > subDays(now, 7)) {
    weekStart = createdAt.getDay() - 1;
    if (weekStart < 0) weekStart = 6;
  }
  if (currentWeekDay < 0) currentWeekDay = 6;

  const currentWeekDates = useMemo(() => {
    const startWeekDay = format(currentWeek, 'dd');
    const endWeekDay = format(endWeek, 'dd');
    const startMonthIndex = findMonth(currentWeek.getMonth());
    const endMonthIndex = findMonth(endWeek.getMonth());
    const startDateString = `${formatDay(startWeekDay)} ${translate(startMonthIndex)}`;
    const endDateString = `${formatDay(endWeekDay)} ${translate(endMonthIndex)}`;
    return `${startDateString} - ${endDateString}`;
  }, [currentWeek, endWeek, translate]);

  const setDayNameValue = useCallback(
    (date: Date, index: number): string => {
      const calcDate = format(addDays(date, index), 'dd.MM');
      const dayIndex = index + 1;
      return `${translate(findWeekDay(dayIndex === 7 ? 0 : dayIndex))} ${calcDate}`;
    },
    [translate]
  );

  const daysValues = useMemo((): DayValues => {
    const defaultValues: DayValues = {
      todayAmount: 0,
      minAmount: 0,
      maxAmount: 0,
      minDayName: '-',
      maxDayName: '-',
      todayDayName: '-',
    };

    if (!telephoneCosts) {
      return defaultValues;
    }

    return telephoneCosts.reduce(
      (result: ITelephonyDaysResult, item: number | null, index: number) => {
        const itemValue = item || 0;
        let { maxAmount, maxDayName, minAmount, minDayName, todayDayName, todayAmount } = result;
        if (currentWeekDay === index) {
          todayDayName = setDayNameValue(currentWeek, index);
          todayAmount = itemValue;
        }
        if (itemValue >= maxAmount && currentWeekDay >= index) {
          maxAmount = itemValue;
          maxDayName = setDayNameValue(currentWeek, index);
        }
        if (
          (itemValue < minAmount || minDayName === '-') &&
          currentWeekDay >= index &&
          index >= weekStart
        ) {
          minAmount = itemValue;
          minDayName = setDayNameValue(currentWeek, index);
        }
        return {
          todayAmount,
          todayDayName,
          maxAmount,
          minAmount,
          maxDayName,
          minDayName,
        };
      },
      { ...defaultValues }
    );
  }, [telephoneCosts, weekStart, currentWeekDay, setDayNameValue, currentWeek]);

  const telephonyDiagramData = useMemo(() => {
    const { success } = theme?.color || {};
    const weekLabels = weekNames.map((e, index) => setDayNameValue(currentWeek, index).split(' '));
    const barData = telephoneCosts
      ? telephoneCosts.map((e: number | null) => (e === null ? 0 : e))
      : [];
    return {
      labels: weekLabels,
      datasets: [
        {
          data: barData,
          backgroundColor: success[300],
          hoverBackgroundColor: success[600],
          borderWidth: 0,
          borderRadius: {
            topLeft: 50,
            topRight: 50,
            bottomLeft: 0,
            bottomRight: 0,
          },
          barThickness: 10,
        },
      ],
    };
  }, [theme?.color, telephoneCosts, setDayNameValue, currentWeek]);

  const barDiagramOptions = useMemo(() => {
    const { base, tertiary } = theme?.color || {};
    return {
      responsible: true,
      scales: {
        y: {
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          position: 'average' as TooltipPositioner,
          yAlign: 'center' as TooltipYAlignment,
          backgroundColor: base,
          borderColor: tertiary[200],
          borderWidth: 1,
          titleColor: tertiary[700],
          padding: 12,
          bodyColor: tertiary[900],
          displayColors: false,
          titleFont: {
            size: 12,
            weight: 'normal',
            family: 'Onest-Regular',
          },
          bodyFont: {
            size: 12,
            weight: 'bold',
            family: 'Onest-Regular',
          },
          callbacks: {
            title(context: Array<{ dataIndex: number }>) {
              const { dataIndex } = context[0] || {};
              return format(addDays(currentWeek, dataIndex), 'dd.MM.yyyy') as string;
            },
            label(context: { raw: unknown }) {
              const { raw } = context || {};
              return `${toPrecision(String(raw))} ₽` as string;
            },
          },
        },
      },
    };
  }, [currentWeek, theme?.color]);

  const handlePointClick = useCallback(
    (element: InteractionItem[]) => {
      if (element[0]) {
        const startDate = addDays(currentWeek, element[0].index).toISOString();
        const endDate = addDays(currentWeek, element[0].index + 1).toISOString();
        const historyPath = `/cabinet/details/?from=${startDate}&to=${endDate}&period=custom`;
        setTimeout(() => {
          navigate(historyPath);
        });
      }
    },
    [currentWeek, navigate]
  );

  if (loading) {
    return (
      <Flex justifyContent={'center'} alignItems={'center'} className={classes.desktopSection}>
        <Preloader size={'large'} />
      </Flex>
    );
  }

  if (getTelephoneCostsErrors) {
    return (
      <div className={classes.desktopSection}>
        <PageError />
      </div>
    );
  }

  return (
    <div className={classes.desktopSection}>
      <Flex
        className={classes.desktopSectionHeader}
        justifyContent={'spaceBetween'}
        alignItems={'center'}
      >
        <Typography type={'text2'} color={'tertiary900'} bold>
          {translate('COMMUNICATION_COSTS')}
        </Typography>
        <div className={classes.desktopTelephonyRightSection}>
          <Typography type={'text4'} color={'tertiary900'}>
            {currentWeekDates}
          </Typography>
        </div>
      </Flex>
      <Flex justifyContent={'spaceBetween'}>
        <div className={classes.desktopTelephonyDiagram}>
          <BarDiagram
            options={barDiagramOptions}
            data={telephonyDiagramData}
            onClick={handlePointClick}
          />
        </div>
        <div className={classes.desktopTelephonyRightSection}>
          <Flex direction={'column'} className={classes.desktopTelephonyInfo}>
            <Flex direction={'column'}>
              <Flex justifyContent={'spaceBetween'} className={classes.desktopTelephonyLine}>
                <Typography type={'text4'} color={'tertiary900'}>
                  {translate('PERIOD_OPT_TODAY')}
                </Typography>
                <Typography type={'text3'} color={'success600'} bold>
                  {`${toPrecision(daysValues.todayAmount)} ₽`}
                </Typography>
              </Flex>
              <Typography type={'text4'} color={'tertiary500'}>
                {daysValues.todayDayName}
              </Typography>
            </Flex>
          </Flex>
          <Flex direction={'column'} className={classes.desktopTelephonyInfo}>
            <Flex direction={'column'}>
              <Flex justifyContent={'spaceBetween'} className={classes.desktopTelephonyLine}>
                <Typography type={'text4'} color={'tertiary900'}>
                  {translate('MAXIMUM')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {`${toPrecision(daysValues.maxAmount)} ₽`}
                </Typography>
              </Flex>
              <Typography type={'text4'} color={'tertiary500'}>
                {daysValues.maxDayName}
              </Typography>
            </Flex>
          </Flex>
          <Flex direction={'column'} className={classes.desktopTelephonyInfo}>
            <Flex direction={'column'}>
              <Flex justifyContent={'spaceBetween'} className={classes.desktopTelephonyLine}>
                <Typography type={'text4'} color={'tertiary900'}>
                  {translate('MINIMUM')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {`${toPrecision(daysValues.minAmount)} ₽`}
                </Typography>
              </Flex>
              <Typography type={'text4'} color={'tertiary500'}>
                {daysValues.minDayName}
              </Typography>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

export default memo(TelephonyWidget);
