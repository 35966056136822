/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
import { DownloadIcon } from '@shared/assets/images/icons';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import downloadFileWithProgress, { OnError } from '@shared/utils/downloadFileWithProgress';
import Button, { ButtonProps } from '@shared/components/Button';
import ProgressSpinner from '@shared/components/ProgressSpinner';

type Props = ButtonProps & {
  url: string | null | undefined;

  filename?: string;
  classNameRootProgressSpinner?: string;

  onCancel?: () => void;
  onDownloadError?: OnError;
  onDownloadSuccess?: () => void;
};

const useButtonDownloadStyles = makeStyles(() => ({
  root: {
    width: '2em',
    height: '2em',
    padding: 0,
  },
  rootDownloading: {
    border: 0,
    borderRadius: '100%',
  },
}));

const ButtonDownload = ({
  url,
  onDownloadError,
  onDownloadSuccess,
  onCancel,
  classNameRootProgressSpinner,
  filename,
  ...rest
}: Props) => {
  const classes = useButtonDownloadStyles();
  /** A number between [0; 1] */
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const tryingToDownloadRef = useRef(false);
  const downloadAbortControllerRef = useRef<AbortController | null>(null);

  const cancelDownloading = () => {
    if (downloadAbortControllerRef.current) {
      downloadAbortControllerRef.current.abort();
      downloadAbortControllerRef.current = null;
    }
  };

  useEffect(() => {
    return cancelDownloading;
  }, []);

  const downloadFile = async () => {
    if (!url || tryingToDownloadRef.current) {
      return;
    }
    tryingToDownloadRef.current = true;

    const abortController = new AbortController();
    downloadAbortControllerRef.current = abortController;

    downloadFileWithProgress({
      abortController,
      url,
      filename: filename || url.split('/').slice(-1).pop(),
      setIsDownloading: (downloading) => {
        if (!downloading) {
          tryingToDownloadRef.current = false;
          downloadAbortControllerRef.current = null;
        }
        setIsDownloading(downloading);
      },
      setDownloadProgress,
      onSuccess: onDownloadSuccess,
      onError: onDownloadError,
      onCancel,
    });
  };

  const handleClick = () => {
    if (isDownloading) {
      cancelDownloading();
      return;
    }
    downloadFile();
  };

  return (
    <Button
      variant="secondary"
      {...rest}
      className={clsx(classes.root, isDownloading && classes.rootDownloading, rest.className)}
      onClick={handleClick}
      disabled={!url || rest.disabled}
    >
      {isDownloading ? (
        <ProgressSpinner classNameRoot={classNameRootProgressSpinner} progress={downloadProgress} />
      ) : (
        <DownloadIcon />
      )}
    </Button>
  );
};

export default ButtonDownload;
