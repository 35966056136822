import { CallDirection, HistoryModel } from '@/client/generated/graphql';
import { FilterButtonIconTypes } from '@/components/ButtonFilter/ButtonFilter.interfaces';

export interface IHistoryRowsData {
  id: number;
  uuid: string;
  direction: string;
  status: HistoryStatus;
  employee: string;
  employeeId: number;
  departmentId: number;
  other: IHistoryDetails;
  client: string;
  via: string;
  date: Date;
  provision: string;
  duration: string;
  record: string;
  fax: string;
  transcription: boolean;
}

export type callTypeInterface = {
  [key: string]: JSX.Element;
};

export interface IPlayerState {
  isPlayerExpanded: boolean;
  pause: undefined | boolean;
  isOpen: boolean;
}

export enum callTypes {
  Incoming = 'incoming',
  IncomingMissed = 'incoming-missed',
  Outgoing = 'outgoing',
  OutgoingMissed = 'outgoing-missed',
}

export interface IHistoryList {
  id: number;
  uuid: string;
  call: callTypes | FilterButtonIconTypes;
  employee: string;
  client: string;
  start: Date;
  date: {
    week: string;
    day: string;
    month: string;
    monthShort: string;
  };
  time: string;
  wait: string;
  duration: string;
  record?: string | null;
  fax?: string | null;
  transcription: boolean;
  employeeId?: number | null;
  departmentId?: number | null;
  other: IHistoryDetails;
  via: string;
  isInBlackList: boolean;
  blackListComment: string | null;
}

export interface IHistoryDetails {
  ivr?: boolean;
  voicemail?: boolean;
  greeting?: boolean;
  fax?: boolean;
  number?: string;
  mobile?: string;
}

export interface IHistoryPost {
  from: string;
  to: string;
  fromTime: string;
  toTime: string;
  search: string | null;
  phone: string | null;
  via: string | null;
  employee: number | null;
  department: number | null;
  other: string | null;
  direction: CallDirection | null;
  missed: boolean | null;
  totals: boolean;
  rows: boolean;
  offset: number | null;
  limit: number;
}

export interface IHistoryState {
  dataLine: string;
  history?: HistoryModel;
}

export enum HistoryStatus {
  Success = 'Success',
  Missed = 'Missed',
  NotAvailable = 'NotAvailable',
  Forbidden = 'Forbidden',
  Busy = 'Busy',
  Unknown = 'Unknown',
  Blacklist = 'Blacklist',
}

export enum HistoryDirection {
  In = 'In',
  Out = 'Out',
  Internal = 'Internal',
}
