import { matchPath, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { IRoute } from './index';

export interface IPath {
  category?: string;
  subcategory?: string;
  identifier?: string;
  subidentifier?: string;
}

export const useRoutes = (routesMap?: Map<string, IRoute>): { path: IPath; route?: IRoute } => {
  const location = useLocation();
  const categoryMatch = matchPath('/:category', location.pathname);
  const subcategoryMatch = matchPath('/:category/:subcategory', location.pathname);
  const identifierMatch = matchPath('/:category/:subcategory/:identifier', location.pathname);
  const subidentifierMatch = matchPath(
    '/:category/:subcategory/:identifier/:subidentifier',
    location.pathname
  );

  const {
    params: {
      category = undefined,
      subcategory = undefined,
      identifier = undefined,
      subidentifier = undefined,
    } = {},
  } = categoryMatch || subcategoryMatch || identifierMatch || subidentifierMatch || {};

  const route = useMemo(() => {
    if (routesMap) {
      if (subidentifier && routesMap.has(subidentifier)) {
        return routesMap.get(subidentifier);
      }
      if (identifier && routesMap.has(identifier)) {
        return routesMap.get(identifier);
      }
      if (identifier && routesMap.has(subcategory || '')) {
        return (
          routesMap.get(subcategory || '')?.children?.reduce<{ exact: boolean; route?: IRoute }>(
            (result, item) => {
              const { path } = item;
              const { exact } = result;
              if (path !== '/' && !exact && (path === identifier || path?.startsWith(':'))) {
                return { exact: path === identifier, route: item };
              }
              return result;
            },
            { exact: false, route: undefined }
          ).route ||
          routesMap.get(subcategory || '') ||
          routesMap.get(category || '')
        );
      }
      return routesMap.get(subcategory || '') || routesMap.get(category || '');
    }
    return undefined;
  }, [routesMap, subidentifier, identifier, subcategory, category]);

  return {
    path: {
      category,
      subcategory,
      identifier,
      subidentifier,
    },
    route,
  };
};
