import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useFilterByNumbersStyle = makeStyles(
  ({ color: { base, tertiary, links } }) => ({
    '@keyframes open': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    filterBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      padding: '0.375em 1em',
      maxHeight: '2em',
      width: '20em',
      backgroundColor: tertiary[100],
      borderRadius: '0.375em',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    filterTrigger: {
      width: '20em',
      margin: 0,
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '4px',
      '& *': {
        cursor: 'pointer',
      },
    },
    input: {
      padding: '0.345em 0.5em 0.345em 0.825em',
      '&:read-only': {
        backgroundColor: base,
      },
    },
    filterContainer: {
      position: 'relative',
      '& > div': {
        padding: 0,
      },
    },
    filterWrapper: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      justifyContent: 'space-between',
      top: 0,
      width: '20em',
      height: '30em',
      backgroundColor: base,
      borderRadius: '0 0 0.75em 0.75em',
      boxShadow: '0px 0.5em 1.25em rgba(0, 0, 0, 0.1)',
      animation: '$open linear 0.2s',
      zIndex: 5,
    },
    optionsWrapper: {
      overflow: 'auto',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: '18.5em',
      width: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '0 1em',
    },
    clearIcon: {
      width: '1em',
      height: '1em',
      '& > :hover': {
        opacity: '.6',
        cursor: 'pointer',
      },
    },
    searchInputRoot: {
      width: '20em',
      height: '2em',
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '3px',
      padding: '0 1em',
      background: '#fff',
    },
    checkboxControl: {
      margin: '0.375em',
      marginLeft: '0',
      padding: '0 0 3px',
    },
    resetButton: {
      padding: '0 0 0 1em',
      border: 'none',
      textDecoration: 'underline',
      color: links[600],
      '& > span > span': {
        color: links[600],
      },
    },
    resetButtonDisabled: {
      color: tertiary[200],
      '& > span > span': {
        color: tertiary[200],
      },
    },
    counterRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '1em 1em 0',
    },
    btnWrapper: {
      padding: '0.75em 0',
      borderTop: `1px solid ${tertiary[50]}`,
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
