import { makeStyles } from '@material-ui/core';

export const useButtonIconStyles = makeStyles(({ color: { danger } }) => ({
  root: {
    fontSize: '1em',
    width: '2.125em',
    height: '2.125em',
    padding: 0,
  },
  buttonContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContentWrapperError: {
    '& path': {
      fill: danger[600],
    },
  },
}));
