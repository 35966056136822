import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import SortFilter from '@components/SortFilter';
import { UseEmployeesStatisticStyles } from '../StatisticsByEmployees.styles';

export const EmployeesStatisticAvgDurationHeaderCell = () => {
  const [translate] = useTranslation();
  const classes = UseEmployeesStatisticStyles();

  return (
    <Flex className={classes.rightCell} alignItems={'center'} justifyContent={'flexEnd'}>
      <SortFilter
        title={translate('AVERAGE_DURATION')}
        filterName={'avgDuration'}
        group={['totalCalls', 'avgDuration']}
      />
    </Flex>
  );
};

export default EmployeesStatisticAvgDurationHeaderCell;
