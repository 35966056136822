import {
  GetIntegrationDetailsQuery,
  IncomingNumbersQuery,
  IntegrationStatus,
} from '@/client/generated/graphql';
import { IntegrationList } from '@components/typings/interfaces/integrations';

export interface IAMOPipelineDataList {
  id: number;
  isMain: boolean;
  isUnsortedOn: boolean;
  name: string;
  sort: number;
  statuses: Array<IAMOPipelineStatusList>;
}

export interface IAMOPipelineStatusList {
  color: string;
  id: number;
  isEditable: boolean;
  name: string;
  sort: number;
  type: number;
}

export interface IUsedIntegrationList {
  id: number;
  name: string;
  type: string;
}

export interface ISettingInstalledProps {
  [key: string]: {
    value: string | number;
    type: string;
  };
}

export type Integration = GetIntegrationDetailsQuery['getIntegrationDetails'] | null | undefined;

export type IncomingNumber = IncomingNumbersQuery['boughtDomainNumbers'][number];

export interface INumberValue {
  id: number;
  domainIntegrationId: number;
  domainIntegrationScenarioId: number;
  domainNumberId: number;
  responsible: boolean;
  numberInfo: {
    id: number;
    domainId: number;
    phone: string;
  };
}

export type crmConfigurationsType = {
  settingsTitle?: string;
  settingsType?: scenarioType;
  settings?: Array<scenariosListType>;
  responsible?: boolean;
  isCustomSetting?: boolean;
  isScenarioTabsHidden?: boolean;
  isSettingsAlwaysAvailable?: boolean;
};

export type apiInformationType = {
  [key: string]: {
    title: string;
    description: string;
    installDescription?: string;
    sideLogo: boolean;
    document: boolean;
    paid: boolean;
    configuration: crmConfigurationsType;
  };
};

export enum compareStatus {
  Compared = 'compared',
  NotCompare = 'notCompare',
  NotSelected = 'notSelected',
}

export enum IntegrationNextType {
  Department = 'DEPARTMENT',
  Employee = 'EMPLOYEE',
  Default = 'DEFAULT',
}

export enum scenarioType {
  Simple = 'SIMPLE',
  Advanced = 'ADVANCED',
}

export enum amoLeadType {
  Unsorted = 'Unsorted',
  Task = 'Task',
  Contact = 'Contact',
}

export enum optionSettingType {
  Lead = 'lead',
  Task = 'task',
  Contact = 'contact',
  Unsorted = 'unsorted',
  Site = 'site',
  Common = 'common',
}

export enum responsibleObjectKey {
  Responsible = 'responsible',
  DefaultResponsible = 'defaultResponsible',
  Contract = 'contract',
}

export enum scenarioSettingsType {
  Always = 'ALWAYS',
  Responsible = 'RESPONSIBLE',
  Sites = 'SITES',
  IncomingKnown = 'INCOMING_KNOWN',
  IncomingMissedKnown = 'INCOMING_MISSED_KNOWN',
  IncomingUnknown = 'INCOMING_UNKNOWN',
  IncomingMissedUnknown = 'INCOMING_MISSED_UNKNOWN',
  OutgoingKnown = 'OUTGOING_KNOWN',
  OutgoingMissedKnown = 'OUTGOING_MISSED_KNOWN',
  OutgoingUnknown = 'OUTGOING_UNKNOWN',
  OutgoingMissedUnknown = 'OUTGOING_MISSED_UNKNOWN',
  CommonCallBack = 'COMMON_CALL_BACK',
  CommonCallHistory = 'COMMON_CALL_HISTORY',
}

export enum scenarioDirectionEnum {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  Common = 'common',
  RetailCrm = 'retailCrm',
}

export enum scenarioTypeEnum {
  Known = 'known',
  Unknown = 'unknown',
  Site = 'site',
}

export enum scenarioStatusEnum {
  Success = 'success',
  Missed = 'missed',
}

export type IResponsibleForCalls = {
  [key in scenarioDirectionEnum.Incoming | scenarioDirectionEnum.Outgoing]: {
    [key: string]: string;
  };
};

export interface IConfirmData {
  playInfo: boolean;
  dialTimeout: number;
  next: IntegrationNextType;
  nextId: number | null;
  numbers: Array<string | number>;
  message?: string;
  fileHash?: string;
  fileName?: string;
}

export interface ICallForResponsibleType {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: IConfirmData | null) => void;
}

export type scenarioSettingsItemType = {
  active?: boolean;
  edit?: scenarioSettingsType;
  title: string;
  options: Array<string>;
  notInstalled?: Array<string>;
  installed?: Array<string>;
  unsortedInstalled?: Array<string>;
};

export type bitrixSettingTaskType = {
  text: string;
  title: string;
  auditor?: string;
  responsible?: string;
  defaultResponsible?: string;
};

export type bitrixSettingLeadType = {
  create: boolean;
  responsible?: string;
  defaultResponsible?: string;
};

export type amoSettingLeadType = {
  create: string | boolean;
  responsible?: string | number;
  defaultResponsible?: string | number;
  pipeline_id: number;
  status_id: number;
  tags_names?: Array<string>;
  dynamic_tags?: Array<string>;
};

export type amoSettingContactType = {
  create: boolean;
  tags_names?: Array<string>;
  dynamic_tags?: Array<string>;
  defaultResponsible: number;
  lead?: {
    pipeline_id: number;
    status_id: number;
    tags_names?: Array<string>;
    dynamic_tags?: Array<string>;
  };
};

export type amoSettingUnsortedType = {
  pipeline_id: number;
  responsible?: string;
  defaultResponsible?: number;
};

export type amoSettingTaskType =
  | {
      responsible?: string | number;
      defaultResponsible?: number;
    }
  | boolean;

export type settingLeadType = bitrixSettingLeadType | amoSettingLeadType;
export type settingTaskType = bitrixSettingTaskType | amoSettingTaskType;
export type settingUnsortedType = amoSettingUnsortedType;
export type settingContactType = amoSettingContactType;

export type settingCallDirectionType = {
  [scenarioTypeEnum.Known]?: {
    [scenarioStatusEnum.Success]?: {
      lead?: settingLeadType;
      contact?: settingContactType;
      unsorted?: settingUnsortedType;
      task?: settingTaskType;
    };
    [scenarioStatusEnum.Missed]?: {
      lead?: settingLeadType;
      contact?: settingContactType;
      unsorted?: settingUnsortedType;
      task?: settingTaskType;
    };
  };
  [scenarioTypeEnum.Unknown]?: {
    [scenarioStatusEnum.Success]?: {
      lead?: settingLeadType;
      contact?: settingContactType;
      unsorted?: settingUnsortedType;
      task?: settingTaskType;
    };
    [scenarioStatusEnum.Missed]?: {
      lead?: settingLeadType;
      contact?: settingContactType;
      unsorted?: settingUnsortedType;
      task?: settingTaskType;
    };
  };
};

export type settingCommonType = {
  defaultEmployee?: string;
};

export type settingSiteType = {
  sites: rawComparedSitesListType;
};

export type completeSettingData = {
  direction: string;
  type: string;
  status: string;
  data: {
    lead?: settingLeadType;
    unsorted?: settingUnsortedType;
    task?: settingTaskType;
    common?: settingCommonType;
    contact?: amoSettingTaskType;
    retailCrm?: settingSiteType;
  };
};

export type employeeBitrixListType = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  personalMobile: string | null;
  secondName: string | null;
};

export type employeeRetailListType = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  patronymic: string | null;
  phone: string | null;
};

export type employeeAmoListType = {
  email: string;
  id: number;
  name: string | null;
};

export type crmEmployeeListType = {
  id: number;
  email: string;
  name: string;
};

export type comparedEmployeeObjectValue = { id: number; crmId: string; name: string; ext: string };
export type comparedSitesObjectValue = {
  id: number;
  crmId: string;
  name: string;
  siteName: string;
};

export type comparedEmployeeListType = {
  [key: string]: comparedEmployeeObjectValue | Array<string>;
};
export type comparedSitesListType = {
  [key: string]: comparedSitesObjectValue | Array<string>;
};
export type rawDataListType = { id: string; name: string }[];
export type rawComparedEmployeeListType = { [key: string]: string };
export type rawComparedSitesListType = { [key: string]: Array<any> | string };

export interface IScenarioSettingsType {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (settingData: completeSettingData) => void;
}

export interface IIntegrationScenarioTabsType {
  onTabClick: (tabIndex: number, tabEvent: string) => void;
  onTabBlur: (tabValue?: string) => void;
  onError: () => void;
}

export interface IScenariosActiveSettingsTabType {
  onNumbersChange: (selectedNumbers: selectedNumbersType) => void;
  onPageDataChange: () => void;
  onScenarioEdit: (scenarioItem: scenarioSettingsItemType) => void;
  onScenarioDelete: (scenarioIndex: number) => void;
  onTabChange: () => void;
  onOtherScenarioEdit: () => void;
}

export interface IResponsibleSettingState {
  isOpen: boolean;
  data: IConfirmData | null;
}

export type ScenarioSettingsCompleteDataType = {
  [scenarioDirectionEnum.Incoming]?: settingCallDirectionType;
  [scenarioDirectionEnum.Outgoing]?: settingCallDirectionType;
  [scenarioDirectionEnum.Common]?: settingCommonType;
  [scenarioDirectionEnum.RetailCrm]?: settingSiteType;
};

export interface IScenarioSettingState {
  isOpen: boolean;
  scenarioType: scenarioSettingsItemType | null;
  data: ScenarioSettingsCompleteDataType | null;
}

export type comparedEmployeeListState = {
  compared: comparedEmployeeListType | null;
  raw: rawDataListType | null;
};

export interface IIntegrationAuthorizationProps {
  isOpen: boolean;
  onClose?: () => void;
}

export interface IIntegrationStaticAuthorizationProps {
  integrationId?: number;
  url?: string | null;
}

export interface ICRMIntegrationEmployeesProps {
  integrationId?: number;
  integrationStatus?: IntegrationStatus;
  rawComparedEmployee?: rawComparedEmployeeListType;
  APIName: IntegrationList;
  onChange?: (
    comparedEmployeeList: comparedEmployeeListType,
    bitrixEmployeeList: rawDataListType
  ) => void;
  employeesError?: boolean;
}

export interface ICRMIntegrationSitesProps {
  selectedNumbers: IncomingNumber[] | null | undefined;
  rawComparedSites: rawComparedSitesListType | null | undefined;
  onChange?: (comparedSiteList: comparedSitesListType, retailSiteList: rawDataListType) => void;
}

export type employeeUserType = {
  id: string;
  name: string;
  phone: string;
  email: string;
  isActive: boolean;
};

export type siteListType = {
  id: number;
  name: string;
  code: string;
};

export type employeeListType = {
  user: employeeUserType;
  ext: string;
};

export type selectedNumbersType = {
  [key: string]: IncomingNumber;
};

export interface IScenarioNumbersProps {
  availableDomainNumbers?: IncomingNumber[];
  selectedNumbers?: selectedNumbersType;
  onNumbersChange?: (selectedNumbers: selectedNumbersType) => void;
}

export interface IScenarioSettingProps {
  responsibleSetting?: IConfirmData | null;
  scenarioSettings?: ScenarioSettingsCompleteDataType | null;
  selectedNumbers?: selectedNumbersType;
  scenarioNumbers?: string;
  isResponsibleActive?: boolean;
  currentIndex: number;
  name: string;
}

export type scenariosListType = Array<scenarioSettingsItemType>;

export type listedObjectKeysType = { [key: string]: any } | undefined;

export type scenarioSettingResultType = {
  name: string;
  isAllDomainNumbersUsed: boolean;
  numbers: {
    domainNumberId: number;
    responsible: boolean;
  }[];
  settings:
    | {
        responsible: Pick<IConfirmData, 'playInfo' | 'dialTimeout' | 'next' | 'nextId'>;
        [scenarioDirectionEnum.Incoming]?: settingCallDirectionType;
        [scenarioDirectionEnum.Outgoing]?: settingCallDirectionType;
        [scenarioDirectionEnum.Common]?: settingCommonType;
        [scenarioDirectionEnum.RetailCrm]?: settingSiteType;
      }
    | unknown;
};

export interface IContextStateType {
  integrationId?: number;
  isIntegrationEdit: boolean;
  APIName: IntegrationList;
  integrationName: string;
  isPreview: boolean;
  integrationStatus: IntegrationStatus;
  integrationSettings: IScenarioSettingProps[];
  scenarioIndex: number;
  scenarioType: scenarioSettingsItemType | null;
  scenarioData: ScenarioSettingsCompleteDataType | null;
  comparedEmployee: comparedEmployeeListType | null;
  responsibleData: IConfirmData | null;
  rawEmployee: rawDataListType | null;
  selectedNumbers?: selectedNumbersType;
  responsibleNumbersList?: IncomingNumber[];
  availableDomainNumbers?: IncomingNumber[];
  isScenarioTabsAvailable: boolean;
  isTabsHidden: boolean;
  isScenarioNameEdit: boolean;
  isSettingsAvailable: boolean;
  newTabAvailable: boolean;
  isEmployeeListAvailable: boolean;
  scenarioNumbers: string;
}
