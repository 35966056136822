import React, { FunctionComponent } from 'react';

import { LogoHorizontalSecondaryIcon } from '@shared/assets/images/icons/logos';
import { Outlet, useMatch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { AuthStyle } from './AuthLayout.styles';

export const AuthLayout: FunctionComponent = () => {
  const classes = AuthStyle();
  const categoryMatch = useMatch('/:category');
  const showBg = useMediaQuery('(min-width:960px)', { noSsr: true });

  const bgList = {
    signup: classes.signBackground,
    'confirm-signup': classes.signBackground,
    signin: classes.signBackground,
    'forgot-password': classes.signBackground,
    'restore-password': classes.signBackground,
  };

  const bgClass = bgList[(categoryMatch?.params?.category as keyof typeof bgList) || 'signup'];

  return (
    <main className={classes.authContent}>
      <div className={classes.root}>
        {showBg && (
          <div className={`${classes.image} ${bgClass}`}>
            <LogoHorizontalSecondaryIcon width={160} height={40} />
          </div>
        )}
        {!showBg && <LogoHorizontalSecondaryIcon className={classes.smallScreenIcon} />}
        <div className={classes.side}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};
