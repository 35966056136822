import { makeStyles } from '@material-ui/core';
import { AlertTriangleIcon, CheckCircleIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import MessageDialog from '../MessageDialog/MessageDialog';
import { IMessageDialogProps } from '../MessageDialog/MessageDialog.interfaces';
import Typography from '../Typography';

type Props = Omit<IMessageDialogProps, 'message'> & {
  status: 'success' | 'error';
  showCloseButton?: boolean;
};

const useStyles = makeStyles(({ color: { success, warning } }) => ({
  iconWrapper: {
    marginBottom: '1.25em',

    display: 'flex',
    justifyContent: 'center',
  },
  iconSuccess: {
    '& path': {
      fill: success[600],
    },
  },
  iconError: {
    '& path': {
      fill: warning[600],
    },
  },
  icon: {
    width: '2em',
    height: '2em',
  },
  buttonsBlockClass: {
    marginTop: '2em',
    height: '2em',
  },
  buttonClose: {
    height: '2em',
  },
}));

const MessageDialogWithStatus = ({
  status,
  buttonsBlockClass,
  showCloseButton = true,

  renderContent,
  renderControls,
  onCancel,
  ...props
}: Props) => {
  const classes = useStyles();
  const [translate] = useTranslation();

  const renderIcon = () => {
    switch (status) {
      case 'success':
        return <CheckCircleIcon className={clsx(classes.icon, classes.iconSuccess)} />;
      case 'error':
        return <AlertTriangleIcon className={clsx(classes.icon, classes.iconError)} />;
      default:
        return status satisfies never;
    }
  };

  return (
    <MessageDialog
      {...props}
      buttonsBlockClass={clsx(classes.buttonsBlockClass, buttonsBlockClass)}
      onCancel={onCancel}
      renderContent={
        <>
          <div className={classes.iconWrapper}>{renderIcon()}</div>
          {renderContent}
        </>
      }
      renderControls={
        <>
          {showCloseButton && (
            <Button className={classes.buttonClose} onClick={onCancel}>
              <Typography color="base">{translate('CLOSE')}</Typography>
            </Button>
          )}
          {renderControls}
        </>
      }
    />
  );
};

export default MessageDialogWithStatus;
