import { ICellProps } from '@components/LazyTable';
import { Dialog } from '@material-ui/core';
import { EditIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import ControlButtons from '@shared/components/ControlButtons';
import Typography from '@shared/components/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NumberStatus } from '@/client/generated/graphql';
import { IncomingNumber } from '../IncomingNumbers.interfaces';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberActionsCell = ({ item }: ICellProps<IncomingNumber>) => {
  const classes = useIncomingNumbersStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  if (item.__typename === 'FmcNumberModel') {
    return <div />;
  }

  const { id, numberStatus } = item;

  if (!numberStatus || numberStatus === NumberStatus.Reserved) {
    return null;
  }

  return (
    <div className={classes.numberActionsBody}>
      <div className={classes.numberActionsContent}>
        <Button
          className={classes.numberActionsActionButton}
          variant={'secondary'}
          onClick={() => navigate(`/calls/incoming/${id}`)}
          // disabled={numberStatus === IncomingNumberStatusType.Restricted} // TODO ask
        >
          <EditIcon />
        </Button>
      </div>
      <Dialog
        aria-labelledby={'delete-dialog-title'}
        onClose={() => setOpen(false)}
        open={open}
        className={classes.numberActionsDialog}
        classes={{ paper: classes.defaultElementWidth21 }}
      >
        <div className={classes.numberActionsDialogTitle}>
          <Typography color={'tertiary900'} type={'text2'}>
            {translate('INCOMING_NUMBER_SWITCH_OFF_PROMPT')}
          </Typography>
        </div>
        <div className={classes.numberActionsDialogContent}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('INCOMING_NUMBER_SWITCH_OFF_WARNING')}
            {'\xa0'}
            {translate('INCOMING_NUMBER_SWITCH_OFF_DESCRIPTION')}
          </Typography>
        </div>
        <div className={classes.numberActionsDialogActions}>
          <ControlButtons
            confirmTitle={'SWITCH_OFF'}
            cancelTitle={'LEAVE'}
            flexDirection={'row-reverse'}
            justifyContent={'start'}
            onConfirmClick={() => setOpen(false)}
            onCancelClick={() => setOpen(false)}
            rootConfirmStyles={classes.defaultElementWidthHalf}
          />
        </div>
      </Dialog>
    </div>
  );
};
