import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Button from '@shared/components/Button';
import { XIcon, MessageSquareIcon, MoreVerticalIcon } from '@shared/assets/images/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_BLACK_LIST_QUERY, TRANSCRIPTION_QUERY } from '@/client/queries';
import { getStorageItem } from '@components/storage/storage';
import clsx from 'clsx';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { Transcription } from '@/components/Transcription';
import { ICellProps } from '@components/LazyTable';
import { ContextButton } from '@components/ContextButton/ContextButton';
import Flex from '@shared/components/Flex';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { ADD_TO_BLACKLIST_MUTATION } from '@/client/mutations';
import MessageDialog from '@shared/components/MessageDialog';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import { FormProvider, useForm } from 'react-hook-form';
import ControlButtons from '@shared/components/ControlButtons';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import Translate from '@shared/components/Translate';
import { formatPhone } from '@components/utils';
import ButtonDownload from '@shared/components/ButtonDownload';
import getBasicButtonDownloadHandleErrorCallback from '@components/utils/getBasicButtonDownloadHandleErrorCallback';
import { getRole } from '@/utils';
import { Role } from '@/client/generated/graphql';
import { useHistoryStyle } from '../HistoryPage.styles';
import { callTypes, IHistoryList, IPlayerState } from '../HistoryPage.interfaces';

interface IFormFields {
  comment?: string;
}

export const ActionsCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const classes = useHistoryStyle();
  const [translate] = useTranslation();
  const formMethods = useForm<IFormFields>();
  const { handleSubmit } = formMethods;

  // TODO handle lazy query error
  const [getTranscription, { data: historyTranscription }] = useLazyQuery(TRANSCRIPTION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const { data: dataAuthStateQuery } = useQuery<{ role?: string }>(AUTHENTICATION_STATE_QUERY);
  const role = getRole(dataAuthStateQuery?.role);

  const [addToBlackList, { loading: addToBlackListLoading }] = useMutation(
    ADD_TO_BLACKLIST_MUTATION,
    {
      refetchQueries: [{ query: GET_BLACK_LIST_QUERY, variables: { data: { search: null } } }],
    }
  );

  const rows = historyTranscription?.getHistoryTranscription?.rows || undefined;
  const audioPanelRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<IPlayerState>({
    isPlayerExpanded: false,
    pause: undefined,
    isOpen: false,
  });
  const [showErrorDialog, setShowErrorDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const [showContext, setShowContext] = useState(false);
  const [isBlacklistDialogOpen, setIsBlacklistDialogOpen] = useState(false);

  const isRoleOperator = Role.Operator === role;

  function handleClickPlay() {
    if (item) {
      setState((prevState) => ({
        ...prevState,
        isPlayerExpanded: true,
        pause: false,
      }));
    }
  }

  function handleClosePlayer() {
    setState((prevState) => ({
      ...prevState,
      pause: true,
      isPlayerExpanded: false,
    }));
  }

  function handleTranscriptionClick() {
    if (!rows && typeof item?.id === 'number') {
      getTranscription({ variables: { id: item.id } });
    }
    setState((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  }

  function handleClose() {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  function getDownloadTranscriptionURL() {
    const token = getStorageItem('token');
    const url = process.env.API_URL;

    if (token && url) {
      return `${url}/history/${item?.id}/transcription/report?token=${token}`;
    }

    return '';
  }

  const handleCloseErrorDialog = useCallback(() => {
    setShowErrorDialog({
      isOpen: false,
      title: '',
      message: '',
    });
  }, []);

  const handleAddToBlackList = useCallback(
    (formData: IFormFields) => {
      if (item?.client) {
        addToBlackList({ variables: { data: { phone: item?.client, comment: formData.comment } } })
          .then(() => {
            setShowContext(false);
          })
          .catch(() => {
            setShowErrorDialog({
              isOpen: true,
              title: 'SOMETHING_WENT_WRONG',
              message: 'ERROR_TEXT',
            });
          });
      }
    },
    [addToBlackList, item?.client]
  );

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (audioPanelRef && !audioPanelRef.current?.contains(event?.target as Node)) {
        if (state.isPlayerExpanded) {
          handleClosePlayer();
        }
      }
    };

    if (audioPanelRef.current) {
      document.addEventListener('mousedown', clickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [state.isPlayerExpanded]);

  const computedClasses = clsx(classes.audioWrap, {
    [classes.show]: state.isPlayerExpanded,
    [classes.hide]: !state.isPlayerExpanded,
  });
  if (!item) {
    return <div />;
  }

  const { call, record = '', transcription, client, employee } = item;
  const fax = item.fax || '';

  const isMissed = call === callTypes.IncomingMissed || call === callTypes.OutgoingMissed;

  const renderContextOptions = () => {
    return (
      <Button clear disabled={item.isInBlackList} onClick={() => setIsBlacklistDialogOpen(true)}>
        <Flex
          fullWidth
          className={clsx(classes.contextItemBlock, {
            [classes.contextItemBlockDisabled]: item.isInBlackList,
          })}
        >
          <Typography>{translate('ADD_IN_BLACK_LIST')}</Typography>
        </Flex>
      </Button>
    );
  };

  const renderBlacklistDialog = () => {
    return (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleAddToBlackList)} id={'blacklist'}>
          <div className={classes.marginBottom05}>
            <Typography color="primary700">{formatPhone(item.client)}</Typography>
          </div>
          <Translate
            i18nKey={'ADD_IN_BLACK_LIST_COMMENT'}
            components={{
              p: <Typography color={'tertiary900'} type={'text3'} />,
              br: <br />,
            }}
          />
          <div className={classes.marginTop1}>
            <FormFieldRhfUncontrolled
              name={'comment'}
              label={translate('COMMENT')}
              multiline
              rows={2}
            />
          </div>
          <div className={classes.marginTop2}>
            <ControlButtons
              confirmTitle={'CONFIRM'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              form={'blacklist'}
              justifyContent={'start'}
              flexDirection={'row-reverse'}
              onCancelClick={() => setIsBlacklistDialogOpen(false)}
              loading={addToBlackListLoading}
              small
            />
          </div>
        </form>
      </FormProvider>
    );
  };

  return (
    <div ref={audioPanelRef} className={classes.audioPanel}>
      {isMissed && fax.length > 0 && (
        <ButtonDownload
          className={clsx(classes.button)}
          url={fax}
          onDownloadError={getBasicButtonDownloadHandleErrorCallback(translate)}
        />
      )}

      {!isMissed && (
        <>
          <div className={computedClasses}>
            <div
              className={state.isPlayerExpanded ? classes.defaultElementWidth20 : classes.button}
            >
              {record && (
                <AudioPlayer
                  isPaused={state.pause}
                  isExpanded={state.isPlayerExpanded}
                  collapseOnEnd={false}
                  onClick={handleClickPlay}
                  source={record}
                />
              )}
            </div>
            {record && (
              <ButtonDownload
                className={classes.button}
                url={record}
                onDownloadError={getBasicButtonDownloadHandleErrorCallback(translate)}
              />
            )}
            <div className={classes.button}>
              {transcription && (
                <Button
                  variant={'secondary'}
                  onClick={handleTranscriptionClick}
                  className={classes.playerButton}
                >
                  <MessageSquareIcon />
                </Button>
              )}
            </div>
            {!isRoleOperator && (
              <div className={classes.button}>
                <ContextButton
                  isOpen={showContext}
                  outsideClick={() => setShowContext(false)}
                  button={
                    <Button
                      variant={'secondary'}
                      className={classes.playerButton}
                      onClick={() => setShowContext(!showContext)}
                    >
                      <MoreVerticalIcon />
                    </Button>
                  }
                  content={renderContextOptions()}
                />
              </div>
            )}
            {state.isPlayerExpanded && (
              <div className={classes.button}>
                <Button
                  variant={'secondary'}
                  color={'error'}
                  onClick={handleClosePlayer}
                  className={classes.playerButton}
                >
                  <XIcon />
                </Button>
              </div>
            )}
          </div>
          {state.isOpen && (
            <Transcription
              downloadURL={getDownloadTranscriptionURL()}
              onClose={handleClose}
              data={rows}
              client={client}
              employee={employee}
              isOpen={state.isOpen}
            />
          )}
        </>
      )}
      <MessageDialog
        isOpen={showErrorDialog.isOpen}
        title={translate(showErrorDialog.title)}
        message={translate(showErrorDialog.message)}
        onCancel={handleCloseErrorDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
        }
      />
      <MessageDialog
        isOpen={isBlacklistDialogOpen}
        title={translate('ARE_YOU_SURE')}
        onCancel={() => setIsBlacklistDialogOpen(false)}
        renderContent={renderBlacklistDialog()}
        contentClass={classes.blacklistDialog}
      />
    </div>
  );
};
