import { PaymentServiceModelWithId } from '@/utils/paymentServiceModel';
import { ICellProps } from '@components/LazyTable';
import Typography from '@shared/components/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const BalanceAmountHeaderCell = ({
  item,
  column,
}: ICellProps<PaymentServiceModelWithId>) => {
  const [translate] = useTranslation();

  if (item) {
    return (
      <Typography type={'text4'} color={'tertiary400'}>
        {translate(column.label)}
      </Typography>
    );
  }

  return <div />;
};
