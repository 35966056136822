import { DocumentModel } from '@/client/generated/graphql';
import { ICellProps } from '@components/LazyTable';
import Typography from '@shared/components/Typography';
import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';

export const DocumentsDateCell: FunctionComponent<ICellProps<DocumentModel>> = ({ item }) => {
  if (item) {
    const { signingAt, createdAt } = item;
    return (
      <Typography color={'tertiary600'} type={'text3'}>
        {format(new Date(signingAt || createdAt), 'dd.MM.yyyy')}
      </Typography>
    );
  }

  return <div />;
};
