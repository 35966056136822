import React, { FunctionComponent } from 'react';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { IPayment } from '../Payments.interfaces';

export const PaymentDateCell: FunctionComponent<ICellProps<IPayment>> = ({ item }) => {
  const { createdAt } = item || {};
  const formattedDate = createdAt ? format(new Date(createdAt), 'dd.MM.yyyy') : '';
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  if (!item) {
    return <div />;
  }

  return <HighlightedText type={'text'} text={formattedDate} query={searchText} />;
};
