import { makeStyles } from '@material-ui/core/styles';

export const useLazyTableStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    tableHeader: {
      backgroundColor: tertiary['50'],
    },
    headLine: {
      padding: '0.5em 1.5em 0 2em',
      height: '2em',
      minHeight: '2em',
      boxShadow: `inset 0px -1px 0px ${tertiary['100']}`,
      '& > div': {
        height: 'auto !important',
      },
    },
    bodyLine: {
      borderBottom: `solid 1px ${tertiary[100]}`,
      padding: '1em 1.5em 1em 2em',
      '&:hover': {
        backgroundColor: tertiary['50'],
      },
    },
    tableCell: {
      boxSizing: 'content-box',
      color: 'inherit',
      paddingLeft: '1.5em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:first-child': {
        paddingLeft: 0,
      },
    },
    tableBodyWrapper: {
      position: 'relative',
      height: '100%',
    },
    emptyMessageCell: {
      borderBottom: `solid 1px ${tertiary[100]}`,
      height: '4em',
      padding: '1em 1.5em 1em 1.5em',
    },
    list: {
      willChange: 'opacity !important',

      '&::-webkit-scrollbar': {
        width: '9px',
      },

      '&::-webkit-scrollbar-thumb': {
        border: '1px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        borderRadius: '8px',
      },
    },
  }),
  {
    name: 'LazyTable',
    index: 1,
  }
);
