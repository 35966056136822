import clsx from 'clsx';
import React from 'react';
import { PreloaderProps } from './Preloader.interfaces';
import { usePreloaderStyle } from './Preloader.styles';

export const Preloader = ({ className, color, size }: PreloaderProps) => {
  const classes = usePreloaderStyle();

  const preloaderClasses = clsx(
    classes.root,
    {
      [classes.success]: !color || color === 'success',
      [classes.danger]: color === 'error',
      [classes.sizeDefault]: !size,
      [classes.sizeSmall]: size === 'small',
      [classes.sizeMedium]: size === 'medium',
      [classes.sizeLarge]: size === 'large',
    },
    className
  );

  return <span className={preloaderClasses} />;
};

export default Preloader;
