import React, { FunctionComponent } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { IContextButton } from './ContextButton.interfaces';
import { useContextButtonStyles } from './ContextButton.styles';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';

export const ContextButton: FunctionComponent<IContextButton> = ({
  button,
  content,
  outsideClick,
  placement= 'left',
  isOpen= false
}) => {
  const classes = useContextButtonStyles();

  const computerPopperClasses = clsx(classes.contextButtonPopper, {
    [classes.contextButtonPopperPlacementLeft]: placement === 'left',
    [classes.contextButtonPopperPlacementRight]: placement === 'right',
  });

  return (
    <ClickAwayListener onClickAway={outsideClick}>
      <div className={classes.contextButtonRoot}>
        {button}
        {isOpen ? (
          <Flex direction={'column'} className={computerPopperClasses}>
            {content}
          </Flex>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};
