import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useRedirectScenariosStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      '& .MuiInputBase-root:not(.MuiInputBase-multiline)': {
        width: '70%',
      },
    },
    balloonCard: {
      backgroundColor: tertiary[50],
      width: 'auto',
      maxWidth: '47em',
      minWidth: '10.75em',
      borderRadius: '5px',
      border: `1px solid ${tertiary[100]}`,
      padding: '0 2em 2em',
      position: 'relative',
      marginTop: '1.5em',
      marginBottom: '5px',
      overflow: 'visible',
      '&::before': {
        top: '-0.5em',
        left: '2em',
        transform: 'rotate(45deg)',
        width: '0.875em',
        height: '0.875em',
        content: '""',
        border: `1px solid ${tertiary[100]}`,
        background: tertiary[50],
        position: 'absolute',
        borderRight: 'none',
        borderBottom: 'none',
      },
    },
    formContent: {
      display: 'flex',
      flexFlow: 'column',
      marginTop: '1.25em',
    },
    redirectSelect: {
      paddingBottom: '2em',
      width: '12em',
    },
    redirectCustomLine: {
      padding: '0.825em 0.5em 0.825em 1em',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    redirectTitle: {
      width: '95%',
      paddingRight: '1em',
    },
    redirectExt: {
      width: '2em',
      display: 'flex',
      justifyContent: 'start',
    },
    formCaption: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      marginTop: '2em',
      '&:first-child': {
        marginTop: 0,
      },
    },
    selectionBadge: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.5em 0 0',
    },
    fields: {
      marginTop: '1em',
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
