import { useState, useCallback, useRef, useEffect } from 'react';

export type SetObjectStateArg<S> = ((prev: S) => S) | Partial<S>;

/**
 * Хук аналогичен useState, однако когда в состоянии объект, мы можем более удобно его поменять.
 * Например, в состоянии `{name: 'Andrew', age: 25}`. Чтобы обновить только поле `name` мы можем использовать
 * `setState({name: 'Bogdan'})`. При этом `age` останется в значении `25`.
 * Однако это не работает с глубоко вложенными объектами. В этом случае нужно использовать привычное
 * обновление состояния через callback - `setState(prev => ({...prev}))`.
 */
export function useObjectState<S>(initialState: S) {
  const [state, setStateOriginal] = useState<S>(initialState);

  const stateRef = useRef<S>(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const setStateProgressive = useCallback((newStateOrCallback: SetObjectStateArg<S>) => {
    if (typeof newStateOrCallback === 'object' && newStateOrCallback !== null) {
      setStateOriginal((prev) => ({ ...prev, ...newStateOrCallback }));
      return;
    }
    setStateOriginal(newStateOrCallback);
  }, []);

  return [state, setStateProgressive, stateRef] as const;
}
