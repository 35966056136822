export function setStorageItem<T>(name: string, item: T): void {
  if (item) {
    localStorage.setItem(name, JSON.stringify(item));
  } else {
    localStorage.removeItem(name);
  }
}

export function getStorageItem<T>(name: string): T | undefined {
  const item = localStorage.getItem(name);
  try {
    if (typeof item === 'string') {
      return JSON.parse(item);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return undefined;
}
