import { useState } from 'react';
import { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';

export function useConfirmDialogStatus() {
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [blockedState, setBlockedState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  return { blockedPath, setBlockedPath, blockedState, setBlockedState };
}
