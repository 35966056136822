import { Shadows } from '@material-ui/core/styles/shadows';

const softColor1 = '#706078';
const softColor2 = '#54485A';

export const softShadows: Shadows = [
  'none',
  '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
  `0 0 1px 0 ${softColor1}, 0 2px 2px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 3px 4px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 3px 4px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 4px 6px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 4px 6px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 4px 8px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 5px 8px -2px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 6px 12px -4px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 7px 12px -4px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 6px 16px -4px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 7px 16px -4px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 8px 18px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 9px 18px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 10px 20px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 11px 20px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 12px 22px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 13px 22px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 14px 24px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 16px 28px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 18px 30px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 20px 32px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 22px 34px -8px ${softColor2}`,
  `0 0 1px 0 ${softColor1}, 0 24px 36px -8px ${softColor2}`,
];

const strongColor1 = 'rgba(0,0,0,0.70)';
const strongColor2 = 'rgba(0,0,0,0.50)';

export const strongShadows: Shadows = [
  'none',
  `0 0 1px 0 ${strongColor1}, 0 3px 4px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 2px 2px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 3px 4px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 3px 4px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 4px 6px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 4px 6px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 4px 8px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 5px 8px -2px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 6px 12px -4px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 7px 12px -4px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 6px 16px -4px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 7px 16px -4px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 8px 18px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 9px 18px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 10px 20px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 11px 20px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 12px 22px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 13px 22px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 14px 24px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 16px 28px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 18px 30px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 20px 32px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 22px 34px -8px ${strongColor2}`,
  `0 0 1px 0 ${strongColor1}, 0 24px 36px -8px ${strongColor2}`,
];
