import { useState } from 'react';
import { IErrorDialogText } from '../ErrorDialog.interfaces';

export const useErrorDialog = () => {
  const [showErrorDialog, setShowErrorDialog] = useState({
    showDialog: false,
    title: '',
    message: '',
  });

  function handleShowErrorDialog(errorTexts?: IErrorDialogText) {
    setShowErrorDialog({
      title: errorTexts?.titleText ?? 'SOMETHING_WENT_WRONG',
      message: errorTexts?.messageText ?? 'SOMETHING_WRONG_MESSAGE',
      showDialog: true,
    });
  }

  function handleCloseErrorDialog() {
    setShowErrorDialog({
      title: '',
      message: '',
      showDialog: false,
    });
  }

  return { showErrorDialog, handleShowErrorDialog, handleCloseErrorDialog };
};
