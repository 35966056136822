import React, { FunctionComponent } from 'react';
import { Button } from '@shared/components/Button/Button';
import { SearchIcon } from '@shared/assets/images/icons';
import { getStorageItem } from '@components/storage/storage';
import { ICellProps } from '@components/LazyTable';
import { IPayment } from '../Payments.interfaces';
import { usePaymentsStyle } from '../Payments.styles';

export const PaymentActionsCell: FunctionComponent<ICellProps<IPayment>> = ({ item }) => {
  const classes = usePaymentsStyle();
  const { pdfUrl } = item || {};

  function handleDocumentViewClick() {
    const token = getStorageItem('token');
    if (token && pdfUrl) {
      const link = `${pdfUrl}&token=${token}`;
      window.open(link);
    }
  }

  if (!item) {
    return <div />;
  }

  return (
    <Button
      className={classes.documentViewBtn}
      variant={'secondary'}
      onClick={() => handleDocumentViewClick()}
    >
      <SearchIcon />
    </Button>
  );
};
