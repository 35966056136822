import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { IAssistant } from '@components/typings/interfaces';
import { ICellProps } from '@components/LazyTable';

export const ScenarioPackageCell: FunctionComponent<ICellProps<IAssistant>> = ({ item }) => {
  if (!item) {
    return <div />;
  }

  const { minutesConsumption, telephonyService } = item;

  return (
    <>
      <Typography
        type={'text3'}
        color={
          telephonyService?.prepaid &&
          minutesConsumption &&
          minutesConsumption > telephonyService?.prepaid
            ? 'danger600'
            : 'inherit'
        }
      >
        {minutesConsumption || 0}
      </Typography>
      {' / '}
      <Typography type={'text3'} color={'inherit'}>{`${
        telephonyService?.prepaid ? telephonyService?.prepaid : '-'
      } м`}</Typography>
    </>
  );
};
