import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FilterIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import MessageDialog from '@shared/components/MessageDialog';
import CheckboxField from '@shared/components/CheckboxField';
import clsx from 'clsx';
import ControlButtons from '@shared/components/ControlButtons';
import { NotificationEntity } from '@components/typings/interfaces';
import { ICellProps } from '@components/LazyTable';
import { useNotificationsStyles } from '../SettingsNotifications.styles';
import { IMissedCalls, INotificationsFilterParams } from '../SettingsNotifications.interface';

export const NotificationsStatusHeaderCell: FunctionComponent<ICellProps<IMissedCalls>> = () => {
  const classes = useNotificationsStyles();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const entityFilter = searchParams.getAll('filter');

  const formMethods = useForm<INotificationsFilterParams>({
    defaultValues: {
      selectAll: !entityFilter?.length || entityFilter.includes('all'),
      department: entityFilter.includes('department'),
      employee: entityFilter.includes('employee'),
    },
  });
  const { reset, handleSubmit, watch, setValue } = formMethods;
  const numbersChecked = watch('domainNumber');
  const departmentChecked = watch('department');
  const employeeChecked = watch('employee');
  const computedClass = clsx(classes.defaultIcon, {
    [classes.filterActive]: entityFilter.length && !entityFilter.includes('all'),
  });

  const resetForm = useCallback(() => {
    reset({
      selectAll: true,
      domainNumber: true,
      department: true,
      employee: true,
    });
  }, [reset]);

  const handleFilterClick = () => {
    setOpen(true);
    if (entityFilter) {
      const formParams: INotificationsFilterParams = {
        selectAll: false,
        domainNumber: false,
        department: false,
        employee: false,
      };
      entityFilter.forEach((el: string) => {
        formParams[el as keyof INotificationsFilterParams] = true;
      });
      reset(formParams);
    }
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleSubmitForm = useCallback(
    ({
      domainNumber,
      department,
      employee,
      selectAll,
    }: {
      domainNumber?: boolean;
      department?: boolean;
      employee?: boolean;
      selectAll?: boolean;
    }) => {
      searchParams.delete('filter');
      if (selectAll) {
        searchParams.set('filter', 'all');
      } else {
        if (domainNumber) {
          searchParams.append('filter', NotificationEntity.Number);
        }
        if (department) {
          searchParams.append('filter', NotificationEntity.Department);
        }
        if (employee) {
          searchParams.append('filter', NotificationEntity.Employee);
        }
      }
      setSearchParams(searchParams);
      setOpen(false);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (
      (!numbersChecked && !departmentChecked && !employeeChecked) ||
      (numbersChecked && departmentChecked && employeeChecked)
    ) {
      resetForm();
    } else {
      setValue('selectAll', false);
    }
  }, [employeeChecked, departmentChecked, resetForm, numbersChecked, setValue]);

  const renderIcon = () => (
    <FilterIcon
      className={computedClass}
      width={16}
      height={16}
      onClick={() => handleFilterClick()}
    />
  );

  return (
    <>
      {renderIcon()}
      <MessageDialog
        isOpen={open}
        onCancel={handleFilterClose}
        contentClass={classes.defaultElementWidth20}
        title={translate('FILTER_BY_TYPE')}
        renderContent={
          <FormProvider {...formMethods}>
            <form
              id={'notifications-filter'}
              action={''}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <CheckboxField
                className={classes.checkboxControl}
                name={'selectAll'}
                label={translate('SELECT_ALL')}
                onClick={resetForm}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'domainNumber'}
                label={translate('NUMBERS')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'department'}
                label={translate('DEPARTMENTS')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'employee'}
                label={translate('EMPLOYEES')}
              />
              <ControlButtons
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                form={'notifications-filter'}
                onCancelClick={() => setOpen(false)}
                rootClass={classes.marginTop1}
                small
              />
            </form>
          </FormProvider>
        }
      />
    </>
  );
};
