import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';

export const useEmployeesSelectionStyles = makeStyles(
  ({ color: { tertiary } }: IThemeOptions & Theme) => ({
    listItem: {
      userSelect: 'none',
      padding: '0.675em 0em 0.675em 1em',
      borderRadius: '6px',
      margin: '0 0 0.5em 0',
      border: '1px solid transparent',
      outline: 'none',
      '&:last-child': {
        marginBottom: '1em',
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundColor: tertiary[100],
    },
    listItemSelected: {
      background: tertiary[200],
    },
    listItemEmpty: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: '0.675em 1em',
      borderRadius: '6px',
      backgroundColor: tertiary[100],
    },
    drawerContent: {
      padding: '2em 0',
      display: 'flex',
      flexFlow: 'column',
      overflow: 'auto',
    },
    searchInputRoot: {
      width: '25em',
      border: '1px solid #D1C9D5',
      borderRadius: '3px',
      padding: '0.25em 1em',
      background: '#fff',
    },
    clearIcon: {
      width: '1em',
      height: '1em',
      '& > :hover': {
        opacity: '.6',
        cursor: 'pointer',
      },
    },
    title: {
      wordBreak: 'break-all',
      overflow: 'hidden',
      maxHeight: '2em',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    listItemWrapper: {
      width: '95%',
    },
    listItemExtWrapper: {
      width: '3em',
      paddingLeft: '1.25em',
    },
    actions: {
      display: 'flex',
      padding: '2em 2em 4em',
      marginTop: 'auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
