import { makeStyles } from '@material-ui/core/styles';

export const useFramelessAccordionStyles = makeStyles(
  () => ({
    accordion: {
      backgroundColor: 'initial',
      boxShadow: 'none',
      paddingTop: '0.25em',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
      '&.Mui-expanded': {
        margin: '0',
      },
    },
    accordionSumRoot: {
      padding: '0',
      minHeight: '32px',
    },
    accordionHeader: {
      display: 'flex',
      margin: '0',
      minHeight: '2em',
    },
    accordionHeaderText: {
      lineHeight: '2em',
    },
    accordionTitleExpanded: {
      '&.Mui-expanded': {
        minHeight: '2em',
      },
      '& .Mui-expanded': {
        margin: '0',
      },
    },
    accordionBody: {
      padding: '0.5em 0 0em',
      display: 'block',
    },
  }),
  {
    index: 1,
  }
);
