import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { LinkStyle } from './Link.styles';
import { LinkProps } from './Link.interfaces';

export const Link: FunctionComponent<LinkProps> = (props) => {
  const classes = LinkStyle();
  const { to, children, target } = props;

  return (
    <NavLink target={target} to={to} className={classes.root}>
      {children}
    </NavLink>
  );
};

export default Link;
