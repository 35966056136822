import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_BUSINESS_CARDS_QUERY } from '@/client/queries';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@shared/components/Button';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import { SmsIcon } from '@shared/assets/images/icons';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import Translate from '@shared/components/Translate';
import Breadcrumbs from '@/components/Breadcrumbs';
import QuickSearch from '@components/QuickSearch';
import { FormProvider, useForm } from 'react-hook-form';
import { useBusinessCardsStyles } from './SettingsBusinessCards.styles';
import { BusinessCardData } from './SettingsBusinessCards.interface';
import {
  BusinessCardsActionsCell,
  BusinessCardsMessage,
  BusinessCardsMessageFormat,
  BusinessCardsNumber,
} from './modules';

export const businessCardsColumns: IColumn<BusinessCardData>[] = [
  {
    id: 'format',
    verticalAlign: 'baseline',
    label: 'MESSAGE_FORMAT',
    Renderer: BusinessCardsMessageFormat,
    width: '15%',
  },
  {
    id: 'number',
    verticalAlign: 'baseline',
    width: '25%',
    label: 'NUMBER',
    Renderer: BusinessCardsNumber,
  },
  {
    id: 'message',
    verticalAlign: 'baseline',
    width: '40%',
    label: 'MESSAGE',
    Renderer: BusinessCardsMessage,
  },
  {
    id: 'cellActions',
    label: '',
    verticalAlign: 'baseline',
    width: '25%',
    align: 'right',
    Renderer: BusinessCardsActionsCell,
  },
];

export const SettingsBusinessCards = () => {
  const [translate] = useTranslation();
  const classes = useBusinessCardsStyles();
  const navigate = useNavigate();
  const formMethods = useForm();
  const [searchParams] = useSearchParams();
  const sSearch = searchParams.get('search')?.toUpperCase();

  const {
    data: allBusinessCardsData,
    loading: businessCardsLoading,
    error: businessCardsError,
  } = useQuery(GET_BUSINESS_CARDS_QUERY);
  const businessCardsData = allBusinessCardsData?.getAllBusinessCardsConfigs;

  const tableData = useMemo(
    () =>
      sSearch && businessCardsData?.length
        ? businessCardsData?.filter(
            ({ smsTemplate, sources }) =>
              smsTemplate?.toUpperCase().includes(sSearch) ||
              sources.find((source) => source.allNumbers || source.number?.phone?.includes(sSearch))
          )
        : businessCardsData,
    [businessCardsData, sSearch]
  );

  if (businessCardsLoading) {
    return <PagePreloader />;
  }

  if (businessCardsError) {
    return <PageError />;
  }

  const renderEmptyContent = () => {
    if (!businessCardsLoading) {
      if (!businessCardsData?.length) {
        return (
          <ContentHint isContentCenter contentWidth={32}>
            <SmsIcon className={classes.SmsIcon} />
            <Translate
              i18nKey="ADD_BUSINESS_CARDS"
              components={{
                n: <Typography color={'tertiary600'} type={'text3'} />,
                l: <br />,
              }}
            />
          </ContentHint>
        );
      }
      if (tableData?.length === 0 && sSearch) {
        return (
          <CellPreloader>
            <Typography color={'tertiary900'} type={'text3'}>
              {translate('NOTHING_FOUND')}
            </Typography>
          </CellPreloader>
        );
      }
    }
    return null;
  };

  return (
    <FormProvider {...formMethods}>
      <BodyContainer disableOverflow>
        <div className={classes.headerContainer}>
          <Breadcrumbs>
            {
              <div>
                <QuickSearch parameterName={'search'} />
                <Button
                  title={translate('ADD_GREETING_CARD')}
                  className={classes.action}
                  onClick={() => navigate('/business-cards/greetings/add')}
                />
              </div>
            }
          </Breadcrumbs>
        </div>
        <Flex direction={'column'} className={classes.root}>
          <LazyTable<BusinessCardData>
            columns={businessCardsColumns}
            data={tableData || []}
            renderEmptyDataMessage={renderEmptyContent()}
          />
        </Flex>
      </BodyContainer>
    </FormProvider>
  );
};
