import { makeStyles } from '@material-ui/core/styles';

export const useTooltipStyles = makeStyles(
  ({ color: { secondary } }) => ({
    root: {
      border: `1px solid ${secondary[200]}`,
      background: secondary[50],
      boxShadow: 'none',
      overflowX: 'unset',
      overflowY: 'unset',
      fontSize: '1em',
      padding: '1.25em 1em 1.25em 1em',
      maxWidth: 'initial',
    },
    tooltip: {
      border: `1px solid ${secondary[200]}`,
      background: secondary[50],
    },
    tooltipBase: {
      boxShadow: 'none',
      overflowX: 'unset',
      overflowY: 'unset',
      fontSize: '1em',
      padding: '0.75em 1em',
      maxWidth: 'initial',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      pointerEvents: 'auto',
    },
    content: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
    },
    actions: {
      display: 'flex',
      alignItems: 'baseline',
      padding: '0.1em',
    },
    anchorContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    arrow: {
      color: secondary[50],
      '&::before': {
        border: `1px solid ${secondary[200]}`,
        borderRadius: '2px',
      },
    },
  }),
  {
    index: 2,
  }
);
