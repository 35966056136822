import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';

export const usePreviewDialogStyles = makeStyles(
  ({ color: { primary, tertiary } }) => ({
    previewTextWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      minHeight: pxToEm(41),
    },
    previewTextNumber: {
      flex: '0 0 auto',
      marginRight: '5px',
    },
    imageWrapper: {
      width: '100%',

      marginTop: pxToEm(16),

      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: pxToEm(408),
      borderRadius: '6px',
      border: `1px solid ${tertiary[200]}`,
      background: tertiary[50],
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    selectSlideControlsOuterWrapper: {
      marginTop: pxToEm(16),

      display: 'flex',
      justifyContent: 'center',
    },
    selectSlideControls: {
      flex: `1 0 ${pxToEm(132)}`,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    chevronButton: {
      width: pxToEm(32),
      height: pxToEm(32),
      borderRadius: '12px',

      '&:hover': {
        backgroundColor: tertiary[100],
      },
    },
    dot: {
      width: pxToEm(8),
      height: pxToEm(8),
      borderRadius: '100%',
      backgroundColor: tertiary[200],
      margin: '0 4px',
    },
    selectedDot: {
      backgroundColor: primary[700],
    },
    dialogBodyWrapper: {
      width: pxToEm(696),
    },
    dialogContent: {
      width: 'unset',
    },
  }),
  {
    index: 3,
  }
);
