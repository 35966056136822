import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';

export const useAlertStyles = makeStyles(
  ({ color: { base, success, warning, danger, links } }) => ({
    root: {
      display: 'flex',

      borderRadius: '12px',
      boxSizing: 'border-box',
      padding: `${pxToEm(7)} 1em`,
    },

    leftBlock: {
      flex: '0 0 20px',
      marginRight: pxToEm(12),
      marginTop: pxToEm(5),
    },
    iconWrapper: {
      width: pxToEm(20),
      height: pxToEm(20),
      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },

    rightBlock: {
      flex: '1 1 100%',
    },
    rightBlockTopButtons: {
      float: 'right',
      display: 'flex',
    },
    rightBlockTopButtonClose: {
      fontSize: '1em',
      padding: 0,

      width: '2em',
      height: '2em',

      minWidth: 'unset',

      marginLeft: '1em',
    },
    rightBlockTopButtonAction: {
      maxWidth: 130,
      minWidth: 'unset',
    },
    rightBlockButtonActionTypography: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    xIcon: {
      width: '1em',
      height: '1em',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    spaceDividerMarginTop: {
      marginTop: pxToEm(4),
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    descriptionWrapper: {
      marginBottom: pxToEm(7),
    },
    description: {
      wordBreak: 'break-word',
      overflow: 'hidden',
    },

    rootFilledSuccess: {
      backgroundColor: success[600],
      border: `1px solid ${success[600]}`,
    },
    rootOutlinedSuccess: {
      backgroundColor: base,
      border: `1px solid ${success[400]}`,
    },
    rootStandardSuccess: {
      backgroundColor: success[50],
      border: `1px solid ${success[50]}`,
    },

    rootFilledWarning: {
      backgroundColor: warning[600],
      border: `1px solid ${warning[600]}`,
    },
    rootOutlinedWarning: {
      backgroundColor: base,
      border: `1px solid ${warning[400]}`,
    },
    rootStandardWarning: {
      backgroundColor: warning[50],
      border: `1px solid ${warning[50]}`,
    },

    rootFilledInfo: {
      backgroundColor: links[600],
      border: `1px solid ${links[600]}`,
    },
    rootOutlinedInfo: {
      backgroundColor: base,
      border: `1px solid ${links[400]}`,
    },
    rootStandardInfo: {
      backgroundColor: links[50],
      border: `1px solid ${links[50]}`,
    },

    rootFilledError: {
      backgroundColor: danger[600],
      border: `1px solid ${danger[600]}`,
    },
    rootOutlinedError: {
      backgroundColor: base,
      border: `1px solid ${danger[400]}`,
    },
    rootStandardError: {
      backgroundColor: danger[50],
      border: `1px solid ${danger[50]}`,
    },

    iconColorSuccess: {
      color: success[600],
      '& path': {
        fill: success[600],
      },
    },
    iconColorWarning: {
      color: warning[600],
      '& path': {
        fill: warning[600],
      },
    },
    iconColorInfo: {
      color: links[600],
      '& path': {
        fill: links[600],
      },
    },
    iconColorError: {
      color: danger[600],
      '& path': {
        fill: danger[600],
      },
    },
    iconColorBase: {
      color: base,
      '& path': {
        fill: base,
      },
    },
    tertiary900: {
      color: 'tertiary900',
    },
    base: {
      color: base,
    },
  }),
  {
    index: 3,
  }
);
