import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const useIntegrationFormStyles = makeStyles(
  ({
    color: { tertiary, secondary, base, success, links, danger, primary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    alertCircleIcon: {
      color: danger[600],
      marginRight: '0.5em',
    },
    checkIcon: {
      color: success[600],
      marginRight: '0.5em',
    },
    content: {
      width: '100%',
      minWidth: '1192px',
      height: '100%',
      padding: '0 2em 2em',
    },
    integrationCard: {
      padding: '2em',
      boxSizing: 'border-box',
      width: '70em',
      border: `solid 1px ${tertiary[300]}`,
      borderRadius: '0.75em',
      marginBottom: '2em',
    },
    integrationTitle: {
      padding: '0 0 0.75em 0',
    },
    integrationDescription: {
      width: '50em',
      whiteSpace: 'pre-wrap',
    },
    integrationPDFButton: {
      paddingTop: '1.5em',
    },
    integrationNameField: {
      paddingTop: '1.5em',
    },
    integrationDownloadBtn: {
      display: 'flex',
      width: '15em',
      height: '2em',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      padding: '0 1.25em',
      backgroundColor: tertiary[100],
      borderRadius: '4px',
      textDecoration: 'none',
      color: tertiary[900],
      transition: createTransition('background-color', {
        easing: sharp,
        duration: enteringScreen,
      }),
      '&:hover': {
        backgroundColor: tertiary[200],
      },
    },
    integrationBottomField: {
      width: '23em',
      margin: 0,
    },
    integrationAuthBlock: {
      width: '70em',
      marginBottom: '2em',
      borderRadius: '0.75em',
    },
    integrationAuthTitleBlock: {
      paddingTop: '1em',
      paddingBottom: '1.5em',
      '&:first-child': {
        paddingTop: '0',
      },
    },
    integrationAuthFieldBlock: {
      paddingBottom: '1.5em',
      position: 'relative',
      width: '24em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    integrationAuthFieldBlockSimple: {
      paddingBottom: '1.5em',
      position: 'relative',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    integrationNumberTabs: {
      padding: '1em 2em 0',
      backgroundColor: base,
      borderBottom: `solid 1px ${tertiary[50]}`,
    },
    integrationNumberTabsTitle: {
      padding: '1em 2em 0',
    },
    integrationNumberTabsFull: {
      padding: '2em',
    },
    scenarioTabTitle: {
      '& > *:not(:last-child)': {
        paddingBottom: '0.5em',
      },
    },
    integrationNumberTabsBg: {
      backgroundColor: tertiary[50],
    },
    integrationAuthWhereFindBlock: {
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      paddingBottom: '1.5em',
    },
    sliderWhereFindBlock: {
      height: '37em',
      backgroundSize: 'inherit',
    },
    sliderWhereFindBlockSmall: {
      height: '25em',
      backgroundSize: 'inherit',
    },
    scenarioTab: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: '0.5em',
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      borderBottom: 'solid 2px transparent',
      marginRight: '1em',
      '& .MuiButton-label': {
        fontSize: '1em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    scenarioTabActive: {
      borderColor: primary[700],
    },
    integrationNumberTabsContent: {
      padding: '1.25em 2em',
    },
    integrationScenarioNumbersList: {
      width: '27em',
      margin: '0.5em 0',
      position: 'relative',
      border: `solid 1px ${tertiary[100]}`,
      backgroundColor: tertiary[50],
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '1.5em 2em',
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: '1.5em',
        top: '-0.5em',
        borderBottom: `solid 0.5em ${tertiary[50]}`,
        borderLeft: 'solid 0.5em transparent',
        borderRight: 'solid 0.5em transparent',
      },
    },
    integrationScenarioNotificationPadding: {
      width: 'auto',
      padding: '1.5em 1em',
    },
    integrationSettingsNoPadding: {
      padding: 0,
    },
    integrationSettingsWrapper: {
      padding: '1.5em 2em 0.5em',
    },
    integrationSettingsContactWrapper: {
      backgroundColor: tertiary[100],
      padding: '0.75em 2em 0',
    },
    integrationNumberItemWrapper: {
      padding: '1em 0',
    },
    integrationNumberItemList: {
      marginBottom: '1em',
    },
    integrationNumberItem: {
      padding: '1em',
      minHeight: '5.125em',
      backgroundColor: base,
      borderRadius: '4px',
      marginBottom: '1em',
    },
    integrationTextEllipsis: {
      maxWidth: '10em',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    integrationResponsibleError: {
      textAlign: 'left',
      paddingBottom: '1em',
    },
    integrationResponsibleUl: {
      margin: 0,
      paddingBottom: '1.5em',
      paddingLeft: '1.5em',
    },
    integrationNumberInfoAddress: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: tertiary[600],
    },
    integrationNumberDeleteButton: {
      padding: '1em',
      color: tertiary[600],
    },
    integrationAvailableNumberMessage: {
      padding: '0.5em 1em',
      backgroundColor: base,
      borderRadius: '4px',
      marginBottom: '1em',
    },
    integrationNumbersNotSelectedWarning: {
      padding: '0 0 0.25em 0',
    },
    integrationScenarioLine: {
      padding: '2em',
      borderTop: `solid 1px ${tertiary[100]}`,
      '&:first-child': {
        borderTop: 'none',
      },
    },
    scenarioSettingButton: {
      height: '2em',
    },
    integrationScenarioIcon: {
      width: '1.5em',
      minWidth: '1.5em',
      height: '1em',
      paddingTop: '1px',
    },
    integrationScenarioNameTitle: {
      marginBottom: '0.5em',
    },
    integrationPointIcon: {
      color: tertiary[900],
    },
    integrationCheckIcon: {
      color: success[600],
    },
    integrationScenarioStatus: {
      display: 'flex',
      alignItems: 'center',
      color: success[600],
    },
    integrationScenarioStatusIcon: {
      marginRight: '0.5em',
    },
    integrationBottomLeftControls: {
      '& > *': {
        marginRight: '1em',
      },
    },
    integrationAddButton: {
      width: '100%',
      backgroundColor: base,
      borderColor: tertiary[300],
    },
    integrationConfigButton: {
      backgroundColor: base,
      width: '9em',
      borderColor: tertiary[300],
    },
    confirmBtnLarge: {
      width: '12.5em',
      height: '2.5em',
    },
    selectOptionLine: {
      minHeight: '3.5em',
      padding: '0.5em 1em',
    },
    chooseNumberRoot: {
      color: 'black',
      '& .MuiOutlinedInput-root': {
        '&:first-child': {
          backgroundColor: base,
          minHeight: '4em',
        },
      },
    },
    integrationIdentifierLogo: {
      maxWidth: '12em',
    },
    alertIcon: {
      color: secondary[600],
      margin: '0 0.5em',
    },
    integrationDialog: {
      width: '24em',
    },
    integrationDialogWrappedText: {
      whiteSpace: 'pre-wrap',
    },
    integrationResponsibleErrorDialog: {
      width: '30em',
    },
    integrationDeleteScenarioDialog: {
      width: '20em',
    },
    integrationDeleteScenarioMessage: {
      textAlign: 'left',
      paddingBottom: '1.5em',
    },
    scenarioSettingMenuHeader: {
      height: '3em',
      position: 'relative',
      backgroundColor: tertiary[800],
      textAlign: 'center',
      boxSizing: 'border-box',
      padding: '0.75em 0',
    },
    scenarioSettingMenuHeaderClose: {
      position: 'absolute',
      padding: '0.65em',
      right: '1em',
      top: '0.25em',
      color: base,
      '&:hover': {
        borderColor: 'transparent',
      },
    },
    scenarioSettingMenuContent: {
      position: 'absolute',
      top: '3em',
      overflow: 'auto',
      padding: '2em 2em 0 2em',
      wordBreak: 'break-word',
      left: 0,
      right: 0,
      bottom: '4.5em',
    },
    scenarioSettingMenuTitle: {
      paddingBottom: '1em',
    },
    scenarioSettingMenuItem: {
      paddingBottom: '2em',
    },
    scenarioSettingMenuItemSmall: {
      paddingBottom: '1em',
    },
    scenarioSettingMenuItemTiny: {
      paddingBottom: '0.75em',
    },
    scenarioSettingMenuItemBox: {
      width: '24.5em',
      paddingRight: '0.5em',
      paddingBottom: '1em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    scenarioSettingMenuSelectBox: {
      width: '24.5em',
      paddingRight: '0.5em',
      paddingBottom: '1em',
    },
    responsibleNumberDeleteButton: {
      padding: 0,
      width: '2.5em',
      height: '2.5em',
    },
    responsibleWrapper: {
      position: 'relative',
      '&:after': {
        position: 'absolute',
        height: '100%',
        content: '""',
        left: '-1em',
        right: '-1em',
        top: 0,
        backgroundColor: base,
        opacity: 0.8,
        zIndex: 2,
      },
    },
    scenarioLabelItem: {
      paddingBottom: '0.5em',
    },
    responsibleAvailableNumberMessage: {
      padding: '0.5em 1em',
      width: '24em',
      boxSizing: 'border-box',
      backgroundColor: base,
      border: `solid 1px ${tertiary[50]}`,
      borderRadius: '4px',
    },
    responsibleButtons: {
      boxSizing: 'border-box',
      padding: '0 2em',
      height: '4.5em',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: base,
      borderTop: `solid 1px ${tertiary[50]}`,
      '& > *': {
        marginRight: '1em',
      },
    },
    integrationResponsibleMessage: {
      paddingBottom: '1em',
    },
    numberOptionLine: {
      padding: '0.821em 3em 0.821em 1em',
    },
    integrationOnOffSwitch: {
      padding: '0 0 1em 0',
    },
    integrationAppInstallButton: {
      width: '17em',
    },
    integrationInstallNote: {
      width: '50em',
      padding: '1em',
      borderRadius: '6px',
      marginTop: '1em',
      border: `solid 1px ${secondary[200]}`,
      backgroundColor: secondary[50],
    },
    integrationDocument: {
      marginTop: '1em',
    },
    integrationInstallTitle: {
      paddingBottom: '1em',
    },
    integrationScenarioAddTab: {
      width: '3em',
      height: '3em',
      padding: '0 0.5em',
      boxSizing: 'border-box',
      borderLeft: `solid 1px ${tertiary[50]}`,
      borderRight: `solid 1px ${tertiary[50]}`,
    },
    integrationScenarioTab: {
      minWidth: '13em',
      maxWidth: '13em',
      height: '3em',
      padding: '0 2em',
      boxSizing: 'border-box',
    },
    integrationScenarioTabEdit: {
      minWidth: '13em',
      maxWidth: '13em',
      height: '3em',
      minHeight: '3em',
      maxHeight: '3em',
      padding: '0 0.5em',
      boxSizing: 'border-box',
    },
    integrationScenarioTabActive: {
      backgroundColor: base,
    },
    integrationButtonTab: {
      border: 'none',
      borderBottom: 'solid 1px transparent',
      borderLeft: `solid 1px ${tertiary[200]}`,
      borderRadius: 0,
      marginBottom: '-1px',
      '&:hover': {
        borderLeft: `solid 1px ${tertiary[200]}`,
      },
    },
    integrationButtonTabActive: {
      minWidth: '13em',
      maxWidth: '13em',
      borderBottom: `solid 1px ${base}`,
      '&:hover': {
        borderBottom: `solid 1px ${base}`,
      },
    },
    integrationButtonTabAdd: {
      borderRight: 'none',
      '&:hover': {
        borderRight: 'none',
      },
    },
    integrationScenarioAddIcon: {
      color: tertiary[900],
    },
    integrationScenarioSwitchButton: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      borderBottom: `solid 1px ${tertiary[200]}`,
      width: '2em',
      zIndex: 2,
      '&:after': {
        content: '""',
        display: 'none',
        position: 'absolute',
        top: 0,
        bottom: '-1px',
        width: '1em',
        opacity: '0.2',
      },
    },
    integrationScenarioSwitchButtonLeftShadow: {
      '&:after': {
        left: '100%',
        display: 'block',
        background: `linear-gradient(90deg, ${tertiary[200]} 10%, rgba(255, 255, 255, 0) 100%)`,
      },
    },
    integrationScenarioSwitchButtonRightShadow: {
      '&:after': {
        right: '100%',
        display: 'block',
        background: `linear-gradient(270deg, ${tertiary[200]} 10%, rgba(255, 255, 255, 0) 100%)`,
      },
    },
    integrationScenarioSwitchButtonLeftBorder: {
      borderLeft: `solid 1px ${tertiary[200]}`,
    },
    integrationScenarioSwitchButtonWrapper: {
      width: '2em',
      height: '3em',
      borderBottom: `solid 1px ${tertiary[200]}`,
    },
    integrationScenarioContainer: {
      borderRadius: '0.75em',
      overflow: 'hidden',
      border: `solid 1px ${tertiary[200]}`,
    },
    integrationScenarioTabMain: {
      position: 'relative',
      backgroundColor: tertiary[50],
    },
    integrationScenarioTabContainer: {
      display: 'flex',
      width: '100%',
      height: '3em',
      margin: '0 2em',
      overflow: 'hidden',
      position: 'relative',
      boxSizing: 'border-box',
      '&:after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        height: '1px',
        backgroundColor: tertiary[200],
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    integrationScenarioTabOverflowContainer: {
      display: 'flex',
      position: 'relative',
      zIndex: 1,
      left: 0,
      transition: createTransition(['left'], {
        easing: sharp,
        duration: enteringScreen,
      }),
    },
    integrationBackCallContainer: {
      padding: '1.5em',
      marginTop: '1em',
      marginBottom: '1em',
      backgroundColor: secondary[50],
      border: `1px solid ${secondary[200]}`,
      textAlign: 'center',
      borderRadius: '0.75em',
    },
    integrationSettingFilterAll: {
      padding: '0.3em 0',
    },
    integrationSettingFilter: {
      padding: '0.1em 0',
    },
    integrationSettingFilterButtons: {
      paddingTop: '1.5em',
      textAlign: 'right',
    },
    integrationIconBig: {
      margin: '0 0.4em',
      textAlign: 'center',
    },
    integrationInstallItem: {
      padding: '0.625em 0',
    },
    integrationInstallCircleNumber: {
      width: '2em',
      height: '2em',
      minWidth: '2em',
      borderRadius: '50%',
      marginRight: '0.75em',
      backgroundColor: secondary[100],
    },
    integrationInstallItemContainer: {
      padding: '0.5em 0 1.5em 0',
    },
    integrationIdentifierClampText: {
      maxWidth: '15em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: links[600],
    },
    integrationSwitch: {
      alignItems: 'center',
      paddingBottom: '0.75em',
      '& > *:first-child': {
        marginLeft: 0,
      },
    },
    integrationSwitchLabel: {
      padding: '0 1.25em 0 0',
      marginTop: '2px',
    },
    integrationAuthWhereToFindButton: {
      position: 'absolute',
      right: 0,
      zIndex: 1,
    },
    responsibleSingleNumber: {
      padding: '0.5em 1em',
      backgroundColor: tertiary[100],
      borderRadius: '3px',
    },
    CRMEmployeeListHide: {
      display: 'none',
    },
    CRMEmployeeList: {
      width: '22em',
      paddingBottom: '1em',
    },
    CRMSiteList: {
      width: '45%',
      paddingBottom: '1em',
    },
    CRMEmployeeIcon: {
      paddingBottom: '1em',
    },
    CRMEmployeeFilterButton: {
      marginBottom: '1em',
      color: tertiary[600],
      '&:hover': {
        color: tertiary[700],
      },
    },
    CRMEmployeeInstructionIcon: {
      color: danger[600],
    },
    CRMEmployeeEqualIcon: {
      color: tertiary[400],
    },
    CRMEmployeeSummaryHeading: {
      paddingRight: '1em',
    },
    CRMEmployeeError: {
      color: danger[600],
    },
    CRMEmployeeAlertIcon: {
      marginRight: '1em',
      height: '1.5em',
      width: '1.5em',
      marginBottom: '0.125em',
    },
    CRMEmployeesList: {
      width: '22em',
    },
    CRMEmployeesError: {
      border: `1px solid ${danger[600]}`,
      borderRadius: '0.75em',
    },
    CRMSettingsBorderLine: {
      width: '17em',
      borderBottom: `solid 1px ${tertiary[200]}`,
      margin: '0.5em 0 1.5em 0',
    },
    CRMSettingsRadio: {
      padding: '0.5em 0.5em 0.5em 0',
    },
    CRMEmployeesWrapper: {
      width: '50em',
    },
    CRMEmployeesUpdateListWrapper: {
      position: 'relative',
    },
    CRMEmployeesUpdateList: {
      position: 'absolute',
      right: 0,
    },
    employeeRoot: {
      padding: 0,
      display: 'block',
    },
    integrationPreviewLogo: {
      marginRight: '1em',
    },
    integrationPlank: {
      marginTop: '0.25em',
      width: '4.5em',
      padding: '0.125em 0.25em',
      backgroundColor: links[50],
      borderRadius: '4px',
    },
    integrationPreviewBlock: {
      paddingBottom: '1em',
    },
    selectedFilterButton: {
      color: primary[700],
    },
    installCRMButton: {
      minWidth: pxToEm(200),
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
