import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useStyledMessageBlockStyles = makeStyles(
  ({ color: { tertiary, danger } }) => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
    },
    audioSectionControlButton: {
      padding: '0.25em 0.5em',
      width: '7.875em',
      height: '2em',
    },
    audioSectionControlButtonIcon: {
      marginRight: '0.5em',
    },
    headWrapper: {
      height: '2em',
    },
    textAreaWrapper: {
      width: '100%',
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '0.25em',
      marginTop: '1em',
      padding: '0.75em 1em',
      minHeight: '8em',
    },
    textAreaWrapperError: {
      border: `1px solid ${danger[600]}`,
    },
    areaRoot: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      margin: 'initial',
      border: 'none',
      fontSize: '0.875em',
      fontFamily: 'inherit',
      padding: '0.25em 0',
      lineHeight: '150%',
      minHeight: '5.5em',
      '&:focus': {
        outline: 'none',
      },
    },
    audioControlsSection: {
      width: '100%',
      display: 'flex',
      marginLeft: '0.7em',
      alignItems: 'center',
    },
    audioControlButton: {
      padding: '0.5em 0.5em',
      marginLeft: '0.5em',
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
