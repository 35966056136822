export enum SizeUnits {
  Bytes = 'BYTES',
  Kilobytes = 'KB',
  Megabytes = 'MB',
  Gigabytes = 'GB',
  TeraBytes = 'TB',
  Petabytes = 'PB',
  Exabytes = 'EB',
  Zettabytes = 'ZB',
  Yottabytes = 'YB',
}

export enum TimeUnits {
  Milliseconds = 'Milliseconds',
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
}
