import { CHANGE_PASSWORD_MUTATION } from '@/client/mutations';
import { useMutation } from '@apollo/client';
import { refreshAuthenticationState } from '@components/client/client';
import { setStorageItem } from '@components/storage/storage';
import * as Sentry from '@sentry/react';
import PasswordChangeModal, {
  ChangePasswordSafeFunc,
} from '@shared/components/PasswordChangeModal';
import { areAllAndNewPasswordEqualError, isInvalidPasswordError } from '@shared/utils/apollo';
import React, { FunctionComponent } from 'react';

export interface IProfileChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const ProfileChangePassword: FunctionComponent<{
  onClose: () => void;
  open: boolean;
}> = ({ onClose, open }) => {
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);

  const changePasswordSafe: ChangePasswordSafeFunc = async ({ oldPassword, newPassword }) => {
    const result = await changePassword({ variables: { data: { oldPassword, newPassword } } })
      .then((data) => {
        return { type: 'success', data } as const;
      })
      .catch((error) => {
        return { type: 'error', error } as const;
      });

    if (result.type === 'success') {
      if (!result.data.data) {
        Sentry.captureException(
          Error('No data is present in success password change mutation response.')
        );
        return { type: 'error', error: 'unknown' } as const;
      }
      const { accessToken, refreshToken } = result.data.data.changePassword;
      setStorageItem('token', accessToken);
      setStorageItem('refreshToken', refreshToken);
      refreshAuthenticationState();
      return { type: 'success' };
    }

    if (isInvalidPasswordError(result.error)) {
      return { type: 'error', error: 'wrong-password' } as const;
    }
    if (areAllAndNewPasswordEqualError(result.error)) {
      return { type: 'error', error: 'old-and-new-passwords-equal' } as const;
    }
    return { type: 'error', error: 'unknown' } as const;
  };

  return (
    <PasswordChangeModal onClose={onClose} changePasswordSafe={changePasswordSafe} open={open} />
  );
};

export default ProfileChangePassword;
