import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { pluralForm } from '@components/utils/helpers';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import clsx from 'clsx';
import HighlightedText from '@components/HighlightedText';
import { CallsRestrictionsQuery } from '@/client/generated/graphql';
import { useCallsRestrictionsStyles } from '../CallsRestrictions.styles';

export const CallRestrictionsNameCell = ({
  item,
}: ICellProps<CallsRestrictionsQuery['getCallRestrictions'][number]>) => {
  const { department, employee } = item || {};
  const classes = useCallsRestrictionsStyles();
  const [translate] = useTranslation();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;
  const name = employee?.user?.name || department?.name || '';
  const employeesCount = department?.employees?.length || 0;

  const description = () => {
    if (department) {
      return translate(pluralForm(employeesCount, 'COUNT_EMPLOYEES_IN_DEPARTMENT'), {
        count: employeesCount,
      });
    }
    return '';
  };

  return (
    <div className={classes.rowContent}>
      <div className={clsx(classes.nameRowMargin, classes.textOverflow)}>
        <HighlightedText type={'text'} text={name} query={searchText} />
      </div>
      <Typography type={'text4'} color={'tertiary600'} className={classes.secondaryText}>
        {description()}
      </Typography>
    </div>
  );
};
