import { PaymentServiceModel } from '@/client/generated/graphql';

export type PaymentServiceModelWithId = PaymentServiceModel & { id: string };

export const supplyPaymentServiceModelsWithIds = (models: PaymentServiceModel[]) => {
  const map = new Map<string, number>();
  const result: PaymentServiceModelWithId[] = [];

  for (let i = 0; i < models.length; i += 1) {
    const model = models[i];
    const uniqueModelString = `${model.amount},${model.date},${model.name},${model.type}`;
    const valueFromMap = map.get(uniqueModelString);

    if (typeof valueFromMap === 'undefined') {
      map.set(uniqueModelString, 1);
      result.push({ ...model, id: `${uniqueModelString}1` });
      continue;
    }

    map.set(uniqueModelString, valueFromMap + 1);
    result.push({ ...model, id: `${uniqueModelString}${valueFromMap + 1}` });
  }

  return result;
};
