import React, { FunctionComponent } from 'react';
import { IEmployee } from '@components/typings/interfaces';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';

export const EmployeePhoneCell: FunctionComponent<ICellProps<IEmployee>> = ({ item }) => {
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  if (item) {
    const { phone = '' } = item;

    return <HighlightedText type={'phone'} text={phone} query={searchText} />;
  }

  return <div />;
};
