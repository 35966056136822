import React, { FunctionComponent } from 'react';
import { Card } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { useSettingsCardStyles } from './SettingsCard.styles';
import { ISettingsCardProps } from './SettingsCard.interfaces';

export const SettingsCard: FunctionComponent<ISettingsCardProps> = ({
  title,
  icon: Icon,
  backgroundIcon: BackgroundIcon,
  description,
  link,
}) => {
  const classes = useSettingsCardStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  return (
    <Card className={classes.root} onClick={() => navigate(link)}>
      <div className={classes.body}>
        <div className={classes.title}>
          <Icon className={classes.titleIcon} />
          <div className={classes.titleText}>
            <Typography bold color={'tertiary900'} type={'text2'}>
              {translate(title)}
            </Typography>
          </div>
        </div>
        <div className={classes.description}>
          <Typography color={'tertiary600'} type={'text4'}>
            {translate(description)}
          </Typography>
        </div>
      </div>
      <BackgroundIcon className={classes.backgroundIcon} />
    </Card>
  );
};
