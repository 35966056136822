import { graphql } from '@/client/generated';

export const USER_QUERY = graphql(`
  query User {
    user {
      id
      name
      email
      avatar
      missedCallsNotifications
      phone
      position
      createdAt
      lastLoggedAt
      isActive
      isPasswordRequired
      domains {
        id
        timezoneId
        domain
        current
        status
        settings {
          extLength
          allowEmergencyServices
          blf
          displayNumberName
          holdMusic {
            url
            type
            hash
            name
            systemHoldURL
          }
          assistants {
            type
            count
          }
        }
        createdAt
        employees {
          id
          role
          position
          ext
          current
          callForwarding
          callForwardingTimeout
          phone
          departments {
            id
            name
          }
        }
        client {
          id
          name
          type
          contract
          status
        }
        params {
          docs {
            assistants
            numbers
            passport
            tariff
          }
        }
      }
    }
  }
`);

export const AVAILABLE_FEATURES_QUERY = graphql(`
  query AvailableFeatures {
    getAvailableFeatures {
      features
    }
  }
`);

export const EMPLOYEES_QUERY = graphql(`
  query Employees {
    employees {
      id
      role
      position
      ext
      hasSipRegistration
      phone
      callForwarding
      callForwardingTimeout
      user {
        id
        name
        phone
        email
        isActive
      }
    }
  }
`);

export const EMPLOYEE_QUERY = graphql(`
  query Employee($id: Int!) {
    employee(data: { id: $id }) {
      id
      position
      role
      ext
      login
      phone
      callForwarding
      callForwardingTimeout
      user {
        name
        email
        phone
      }
    }
  }
`);

export const EMPLOYEE_QUERY_ADMIN = graphql(`
  query EmployeeForAdmin($id: Int!, $domainId: Int!) {
    employeeForAdmin(data: { id: $id, domainId: $domainId }) {
      id
      position
      role
      ext
      login
      sipPassword
      phone
      callForwarding
      callForwardingTimeout
      user {
        name
        email
        phone
      }
    }
  }
`);

export const EMPLOYEE_REGISTRATIONS_QUERY = graphql(`
  query EmployeeRegistrations($id: Int!) {
    employeeRegistrations(data: { id: $id }) {
      ext
      contacts {
        node
        transport
        userAgent
        contact
        expires
      }
      via
    }
  }
`);

export const CAN_REMOVE_EMPLOYEE_QUERY = graphql(`
  query CanRemoveEmployee($id: Int!) {
    canRemoveEmployee(data: { id: $id }) {
      confirmed
    }
  }
`);

export const DEPARTMENTS_QUERY = graphql(`
  query Departments {
    departments {
      id
      name
      ext
      employees {
        isActive
        id
      }
      activeEmployeesCount
    }
  }
`);

export const CAN_REMOVE_DEPARTMENT_QUERY = graphql(`
  query CanRemoveDepartment($id: Int!) {
    canRemoveDepartment(data: { id: $id }) {
      confirmed
    }
  }
`);

export const DEPARTMENT_QUERY = graphql(`
  query Department($id: Int!) {
    department(id: $id) {
      id
      name
      ext
      employees {
        ext
        id
        phone
        isActive
        user {
          id
          name
          email
          phone
        }
      }
    }
  }
`);

export const INCOMING_NUMBERS_QUERY = graphql(`
  query IncomingNumbers {
    boughtDomainNumbers {
      __typename
      ... on NumbersModel {
        id
        name
        phone
        prefix
        numberStatus: status
        city
        periods
        scheduler
        hasSchedule
        responsible {
          id
          type
          name
        }
        integrations {
          id
          type
          name
        }
      }
      ... on FmcNumberModel {
        id
        name
        phone
        fmcNumberStatus: status
        city
        periods
        scheduler
        hasSchedule
        responsible {
          id
          type
          name
        }
        integrations {
          id
          type
          name
        }
      }
    }
  }
`);

export const INCOMING_NUMBER_QUERY = graphql(`
  query IncomingNumber($data: GetNumber!) {
    getNumber(data: $data) {
      __typename
      ... on NumbersModel {
        id
        name
        hasSchedule
        periods
        scheduler
        destination
        responsible {
          id
          type
          name
        }
        integrations {
          id
          type
          name
          status
          settings {
            next
            nextId
            playInfo
            dialTimeout
          }
        }
      }
      ... on FmcNumberModel {
        id
        name
        hasSchedule
        periods
        scheduler
        destination
        responsible {
          id
          type
          name
        }
        integrations {
          id
          type
          name
          status
          settings {
            next
            nextId
            playInfo
            dialTimeout
          }
        }
      }
    }
  }
`);

export const INCOMING_NUMBER_PHONE_QUERY = graphql(`
  query IncomingNumberPhone($data: GetNumber!) {
    getNumber(data: $data) {
      __typename
      ... on NumbersModel {
        id
        name
        phone
      }
      ... on FmcNumberModel {
        id
        name
        phone
      }
    }
  }
`);

export const HISTORY_QUERY = graphql(`
  query History($conditions: GetHistory!) {
    getHistory(conditions: $conditions) {
      totals {
        direction
        status
        count
      }
      rows {
        id
        uuid
        direction
        status
        employee
        client
        via
        date
        provision
        duration
        record
        fax
        transcription
        employeeId
        departmentId
        other
      }
    }
  }
`);

export const OWN_HISTORY_QUERY = graphql(`
  query OwnHistory($conditions: GetHistory!) {
    getOwnHistory(conditions: $conditions) {
      totals {
        direction
        status
        count
      }
      rows {
        id
        direction
        status
        employee
        client
        via
        date
        provision
        duration
        record
        fax
        transcription
        employeeId
        departmentId
        other
      }
    }
  }
`);

export const HAS_HISTORY_CALLS_QUERY = graphql(`
  query HasHistoryCalls {
    hasHistoryCalls
  }
`);

export const TRANSCRIPTION_QUERY = graphql(`
  query Transcription($id: Int!) {
    getHistoryTranscription(conditions: { id: $id }) {
      rows {
        who
        time
        text
      }
    }
  }
`);

export const BALANCE_QUERY = graphql(`
  query Balance {
    getBalance {
      balance
      credit
    }
  }
`);

export const BALANCE_DETAILS_QUERY = graphql(`
  query BalanceDetails($from: String!, $to: String!, $offset: Int, $limit: Int) {
    getReportPaymentsServices(
      searchCriteria: { from: $from, to: $to, offset: $offset, limit: $limit }
    ) {
      name
      type
      date
      amount
      burnt
    }
  }
`);

export const CALL_DETAILS_QUERY = graphql(`
  query CallDetails($from: String!, $to: String!, $search: String!, $offset: Int, $limit: Int) {
    getReportTelephony(
      searchCriteria: { from: $from, to: $to, search: $search, offset: $offset, limit: $limit }
    ) {
      rows {
        client
        local
        direction
        zone
        date
        duration
        cost
      }
      total
    }
  }
`);

export const TIMEZONES_QUERY = graphql(`
  query Timezones {
    timezones {
      id
      label
      tzCode
      offset
      mskOffset
    }
  }
`);

export const DOMAIN_NUMBERS_QUERY = graphql(`
  query DomainNumbers {
    boughtDomainNumbers {
      __typename
      ... on NumbersModel {
        id
        phone
        city
        hasBusinessCard
        name
        type
        integrations {
          id
          type
          name
          settings {
            next
            nextId
          }
        }
      }
      ... on FmcNumberModel {
        id
        phone
        city
        hasBusinessCard
        name
        integrations {
          id
          type
          name
          settings {
            next
            nextId
          }
        }
      }
    }
  }
`);

export const DOMAIN_ALL_NUMBERS_QUERY = graphql(`
  query DomainAllNumbers {
    domainNumbers {
      __typename
      ... on NumbersModel {
        id
        numbersCatalogId
        phone
        name
        prefix
        city
        type
        code
        category
        numberStatus: status
        statusTill
        periods
        scheduler
        hasSchedule
        responsible {
          id
          type
          name
        }
        integrations {
          id
          type
          name
          status
          settings {
            next
            nextId
          }
        }
        tariff {
          setupFee
          monthlyFee
          setupDiscount
          monthlyDiscount
          isSetupDiscountAbsolute
          isMonthlyDiscountAbsolute
          baseSetupFee
          baseMonthlyFee
        }
      }
      ... on FmcNumberModel {
        id
        name
        phone
        fmcNumberStatus: status
        city
        statusTill
        periods
        scheduler
        hasSchedule
        responsible {
          id
          type
          name
        }
        integrations {
          id
          type
          name
          status
          settings {
            next
            nextId
          }
        }
      }
    }
  }
`);

export const CAN_BOUGHT_NUMBER_QUERY = graphql(`
  query CanBoughtNumber($domainNumberId: Int!) {
    canBoughtNumber(data: { domainNumberId: $domainNumberId }) {
      balance
      totalNumberCost
    }
  }
`);

export const OUTGOING_CALLS_QUERY = graphql(`
  query OutgoingCalls {
    getOutgoingNumbers {
      id
      numberId
      number {
        phone
        cityName
      }
      departmentId
      department {
        name
        employees {
          id
        }
      }
      employeeId
      employee {
        position
        user {
          name
        }
      }
    }
  }
`);

export const COMMON_OUTGOING_NUMBER_QUERY = graphql(`
  query CommonOutgoingNumber {
    getCommonOutgoingNumber {
      id
      number {
        phone
      }
      numberId
    }
  }
`);

export const CALLS_RESTRICTIONS_QUERY = graphql(`
  query CallsRestrictions {
    getCallRestrictions {
      id
      restriction
      departmentId
      department {
        name
        employees {
          id
        }
      }
      employeeId
      employee {
        position
        user {
          name
        }
      }
    }
  }
`);

export const COMMON_CALLS_RESTRICTION_QUERY = graphql(`
  query CommonCallsRestriction {
    getCommonCallRestriction {
      id
      restriction
    }
  }
`);

export const IS_CLIENT_CREATED_QUERY = graphql(`
  query IsClientCreated {
    isClientCreated {
      status
      token
      refreshToken
      domain
    }
  }
`);

export const DOWNLOAD_URL_QUERY = graphql(`
  query DownloadUrl($fileHash: String!) {
    downloadUrl(filename: $fileHash)
  }
`);

export const GET_USED_EXTENSIONS_QUERY = graphql(`
  query GetUsedExtensions {
    getUsedExtensions {
      ext
    }
  }
`);

export const GET_USER_NAME_BY_EMAIL_QUERY = graphql(`
  query GetUserNameByEmail($email: String!) {
    getUserNameByEmail(data: { email: $email })
  }
`);

export const CHECK_IF_EMAIL_EXIST_LOCAL_QUERY = graphql(`
  query CheckIfEmailExistLocal($email: String!) {
    isEmailExistLocal(data: { email: $email }) {
      exist
      local
    }
  }
`);

export const GET_SUGGEST_COMPANY_QUERY = graphql(`
  query GetSuggestCompany($searchText: String!, $type: String!) {
    suggestCompany(data: { searchText: $searchText, type: $type }) {
      name
      fullName
      inn
      kpp
      ogrn
      address
      shortAddress
      person
      position
    }
  }
`);

export const GET_ACTIVE_TARIFF_QUERY = graphql(`
  query GetActiveTariff {
    getActiveTariffs {
      id
      name
      active
      description
      options {
        id
        name
        type
        remark
        setupFee
        monthlyFee
        baseSetupFee
        baseMonthlyFee
        category
        params
        setupDiscount
        monthlyDiscount
        isSetupDiscountAbsolute
        isMonthlyDiscountAbsolute
      }
    }
  }
`);

export const GET_SELECTED_TARIFF_QUERY = graphql(`
  query GetSelectedTariff {
    getDomain {
      id
      domain
      employeesNumber
      settings {
        extLength
        blf
        allowEmergencyServices
        displayNumberName
        holdMusic {
          url
          type
          hash
          name
          systemHoldURL
        }
        assistants {
          type
          count
        }
      }
      status
      currentEmployeesCount
      tariff {
        id
        name
        active
        options {
          id
          name
          type
          remark
          setupFee
          monthlyFee
          baseSetupFee
          baseMonthlyFee
          category
          params
          domainTariffOptionParams
          setupDiscount
          monthlyDiscount
          isSetupDiscountAbsolute
          isMonthlyDiscountAbsolute
        }
      }
      tariffChangeRequest {
        id
        tariffId
        status
        options {
          tariffOptionId
          type
          count
        }
        createdAt
      }
      params {
        docs {
          passport
          tariff
          numbers
          assistants
        }
      }
    }
  }
`);

export const GET_CODE_LIST_QUERY = graphql(`
  query GetCodeList {
    getAvailableCityCodes {
      id
      code
      regionId
      name
    }
  }
`);

export const GET_NUMBER_LIST_QUERY = graphql(`
  query GetNumberList(
    $code: Int!
    $category: NumberCategory!
    $search: String!
    $limit: Int!
    $offset: Int
  ) {
    searchNumbers(
      data: { code: $code, category: $category, search: $search, limit: $limit, offset: $offset }
    ) {
      rows {
        id
        phone
        prefix
        status
        city
        type
        code
        category
        tariff {
          setupFee
          monthlyFee
          setupDiscount
          monthlyDiscount
          isSetupDiscountAbsolute
          isMonthlyDiscountAbsolute
          baseSetupFee
          baseMonthlyFee
        }
        statusTill
      }
      total
    }
  }
`);

export const GET_SUGGEST_BANK_QUERY = graphql(`
  query GetSuggestBank($searchText: String!) {
    suggestBank(searchText: $searchText) {
      name
      shortName
      bik
      correspondentAccount
      address
    }
  }
`);

export const GET_SUGGEST_FMS_QUERY = graphql(`
  query GetSuggestFms($searchText: String!) {
    suggestFMS(searchText: $searchText) {
      name
      code
    }
  }
`);

export const GET_DOCUMENTS_QUERY = graphql(`
  query GetDocumentsQuery {
    getDocuments {
      id
      clientId
      domainId
      type
      number
      comment
      signingAt
      createdAt
      document
    }
  }
`);

export const CLIENT_QUERY = graphql(`
  query Client {
    getClient {
      id
      name
      type
      params {
        contacts {
          phones
          emails
        }
      }
      individualPassport {
        id
        clientId
        name
        gender
        legalAddress
        postalAddress
        series
        number
        placeOfIssue
        dateOfIssue
        departmentCode
        scans
        createdAt
        updatedAt
        deletedAt
        inn
      }
      legalPassport {
        id
        clientId
        name
        person
        genitiveCasePerson
        actBasis
        position
        genitiveCasePosition
        inn
        kpp
        ogrn
        legalAddress
        postalAddress
        bankName
        account
        bik
        correspondentAccount
        bankAddress
        validFrom
        validTo
        createdAt
        updatedAt
        deletedAt
      }
      domains {
        id
        domain
        status
      }
    }
  }
`);

export const GET_INTEGRATIONS_QUERY = graphql(`
  query GetIntegrations {
    getIntegrations {
      id
      domainId
      name
      status
      type
      createdAt
      updatedAt
      deletedAt
      params
    }
  }
`);

export const GET_INTEGRATION_DETAILS_QUERY = graphql(`
  query GetIntegrationDetails($id: Int!) {
    getIntegrationDetails(data: { id: $id }) {
      id
      domainId
      name
      type
      token
      clientToken
      url
      status
      scenarios {
        id
        domainIntegrationId
        name
        isAllDomainNumbersUsed
        numbers {
          id
          domainIntegrationId
          domainIntegrationScenarioId
          domainNumberId
          responsible
          numberInfo {
            __typename
            ... on NumbersModel {
              id
              domainId
              phone
              name
              hasSchedule
              prefix
              city
              type
              code
              category
              scheduler
              integrations {
                id
                type
                name
              }
              responsible {
                id
                type
                name
              }
            }
            ... on FmcNumberModel {
              id
              domainId
              phone
              name
              city
              hasSchedule
              scheduler
              integrations {
                id
                type
                name
              }
              responsible {
                id
                type
                name
              }
            }
          }
        }
        settings {
          responsible {
            playInfo
            dialTimeout
            next
            nextId
            message
            fileHash
            fileName
          }
          incoming
          outgoing
          common
          retailCrm {
            sites
          }
        }
      }
      params
      createdAt
      updatedAt
      deletedAt
    }
  }
`);

export const GET_ASSISTANTS_QUERY = graphql(`
  query GetAssistants {
    getAssistants {
      id
      domainId
      status
      type
      sum
      name
      ext
      document {
        document
      }
      onceService {
        cost
      }
      minutesConsumption
      telephonyService {
        cost
        prepaid
        oneUnitCost
      }
      marketAssistant {
        id
        type
        name
      }
      statusDate
      createdAt
    }
  }
`);

export const GET_ALL_MISSED_CALLS_QUERY = graphql(`
  query GetAllMissedCalls {
    getAllMissedCallConfigs {
      id
      domainId
      allNumbers
      domainNumber {
        id
        phone
      }
      employee {
        id
        name
      }
      department {
        id
        name
      }
      receivers {
        id
        missedCallSourceId
        employee {
          id
          name
          phone
          email
        }
        department {
          id
          name
          numberCount
          emailCount
        }
        external
        notificationTypes
      }
    }
  }
`);

export const GET_DEPARTMENTS_AND_EMPLOYEES_QUERY = graphql(`
  query GetDepartmentsAndEmployees {
    departmentsAndEmployees {
      id
      listId
      name
      ext
      type
      email
      emailsCount
      phone
      phonesCount
      employeesCount
    }
  }
`);

export const GET_ONE_MISSED_CALL_QUERY = graphql(`
  query GetOneMissedCall($id: Int!) {
    getOneMissedCallConfig(data: { missedCallId: $id }) {
      id
      domainId
      allNumbers
      domainNumber {
        id
        phone
      }
      employee {
        id
        name
      }
      department {
        id
        name
      }
      receivers {
        id
        missedCallSourceId
        employee {
          id
          name
          phone
          email
        }
        department {
          id
          name
          numberCount
          emailCount
          employeesCount
        }
        external
        notificationTypes
      }
    }
  }
`);

export const GET_BUSINESS_CARDS_QUERY = graphql(`
  query GetBusinessCards {
    getAllBusinessCardsConfigs {
      id
      smsTemplate
      newClientDelay
      addContacts
      active
      sources {
        id
        domainId
        businessCardId
        allNumbers
        number {
          id
          phone
        }
      }
    }
  }
`);

export const GET_ALL_APOLOGY_SMS_QUERY = graphql(`
  query GetAllApologySms {
    getAllApologyMessagesSettings {
      id
      messageTemplate
      active
      allNumbers
      triggerTime
      domainNumbers {
        id
        phone
      }
    }
  }
`);

export const GET_APOLOGY_SMS_BY_ID_QUERY = graphql(`
  query GetApologySmsById($id: Int!) {
    getApologyMessagesSettingsById(id: $id) {
      id
      messageTemplate
      active
      allNumbers
      triggerTime
      domainNumbers {
        id
        phone
      }
    }
  }
`);

export const GET_ONE_BUSINESS_CARD_QUERY = graphql(`
  query GetOneBusinessCard($id: Int!) {
    getOneBusinessCardConfig(data: { businessCardSettingId: $id }) {
      id
      smsTemplate
      newClientDelay
      addContacts
      active
      sources {
        id
        domainId
        businessCardId
        allNumbers
        number {
          id
          phone
        }
      }
    }
  }
`);

export const MAX_CONTACT_LENGTH_QUERY = graphql(`
  query MaxContactLength {
    maxContactLength {
      length
    }
  }
`);

export const GET_BLACK_LIST_QUERY = graphql(`
  query GetBlackList($data: GetBlackList!) {
    getBlackList(data: $data) {
      rows {
        id
        domainId
        phone
        comment
        weekCalls
        yearCalls
      }
      total
    }
  }
`);

export const GET_HISTORY_STATISTICS_QUERY = graphql(`
  query GetHistoryStatistics($data: GetHistoryStatistics!) {
    getHistoryStatistics(data: $data) {
      timePeriod
      inSuccess
      inNotSuccess
      outSuccess
      outNotSuccess
    }
  }
`);

export const GET_NUMBERS_STATISTICS_QUERY = graphql(`
  query GetNumbersStatistics($data: GetNumbersStatistics!) {
    getNumbersStatistics(data: $data) {
      numberId
      stat {
        timePeriod
        inSuccess
        inNotSuccess
        outSuccess
        outNotSuccess
      }
    }
  }
`);

export const GET_PROMISED_PAYMENT_SUM_QUERY = graphql(`
  query GetPromisedPaymentSum {
    checkPromisedPaymentSum {
      sum
    }
  }
`);

export const GET_RECOMMENDED_PAYMENT_QUERY = graphql(`
  query GetRecommendedPayment {
    getRecommendedPayment {
      payment
    }
  }
`);

export const GET_BITRIX_EMPLOYEES_LIST_QUERY = graphql(`
  query GetBitrixEmployeesList($data: DomainIntegrationId!) {
    getBitrixEmployeesList(data: $data) {
      id
      email
      firstName
      lastName
      secondName
      personalMobile
    }
  }
`);

export const GET_AMO_EMPLOYEE_LIST_QUERY = graphql(`
  query GetAmoEmployeeList($data: DomainIntegrationId!) {
    getAmoEmployeesList(data: $data) {
      id
      email
      name
    }
  }
`);

export const GET_RETAIL_EMPLOYEE_LIST_QUERY = graphql(`
  query GetRetailEmployeeList($data: DomainIntegrationId!) {
    getRetailCrmUsersList(data: $data) {
      id
      email
      firstName
      lastName
      patronymic
      phone
    }
  }
`);

export const GET_PAYMENTS_QUERY = graphql(`
  query GetPayments {
    getPayments {
      id
      sum
      createdAt
      pdfUrl
    }
  }
`);

export const GET_EMPLOYEES_STATISTICS_QUERY = graphql(`
  query GetEmployeesStatistics($data: GetEmployeesStatistics!) {
    getEmployeesStatistics(data: $data) {
      employeeId
      name
      inSuccess
      inNotSuccess
      outSuccess
      outNotSuccess
      inAvgDuration
      outAvgDuration
      avgDuration
    }
  }
`);

export const GET_DASHBOARD_BALANCE_INFO_QUERY = graphql(`
  query GetDashboardBalanceInfo {
    dashboardBalanceInfo {
      currentBalance
      balanceAfterRefill
      averageExpense
      credit
      promisedPaymentAvailability
      promisedPaymentAvailabilityDate
      promisedPaymentAvailableSum
      promisedPaymentCreatedDate
      promisedPaymentBurntDate
      tariffName
      employeesLimit
      employeesCount
      numbersCount
    }
  }
`);

export const GET_DASHBOARD_TELEPHONY_COSTS_QUERY = graphql(`
  query GetDashboardTelephonyCosts {
    dashboardTelephonyCosts
  }
`);

export const GET_DASHBOARD_CALL_STATISTICS_QUERY = graphql(`
  query GetDashboardCallStatistics($period: CallStatisticsPeriod!) {
    dashboardCallStatistics(period: $period) {
      inSuccess
      inNotSuccess
      outSuccess
      outNotSuccess
    }
  }
`);

export const GET_DASHBOARD_HOURLY_CALL_STATISTICS_QUERY = graphql(`
  query GetDashboardHourlyCallStatistics {
    dashboardHourlyCallStatistics {
      inSuccess
      inNotSuccess
      outSuccess
      outNotSuccess
      timePeriod
    }
  }
`);

export const GET_AMOCRM_INSTALL_URL_QUERY = graphql(`
  query GetAmocrmInstallUrl {
    getAmoInstallURL
  }
`);

export const GET_AMO_PIPELINES_QUERY = graphql(`
  query GetAmoPipelines($id: Int!) {
    getAmoPipelines(data: { id: $id }) {
      id
      name
      sort
      isMain
      isUnsortedOn
      statuses {
        id
        name
        sort
        isEditable
        color
        type
      }
    }
  }
`);

export const GET_RETAIL_SITES_QUERY = graphql(`
  query GetRetailSites($data: DomainIntegrationId!) {
    getRetailCrmSitesList(data: $data) {
      id
      name
      code
    }
  }
`);

export const GET_SYSTEM_HOLD_QUERY = graphql(`
  query GetSystemHold {
    getSystemHoldURL
  }
`);
