import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { CSSProperties } from 'react';

export type ColorVariantType = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export type IColorVariants = {
  [key in ColorVariantType]: CSSProperties['color'];
};

const Saturation = {
  50: undefined,
  100: undefined,
  200: undefined,
  300: undefined,
  400: undefined,
  500: undefined,
  600: undefined,
  700: undefined,
  800: undefined,
  900: undefined,
};

export interface IThemeOptions extends ThemeOptions {
  color: {
    base: CSSProperties['color'];
    primary: IColorVariants;
    secondary: IColorVariants;
    tertiary: IColorVariants;
    danger: IColorVariants;
    success: IColorVariants;
    links: IColorVariants;
    warning: IColorVariants;
  };
  gradients: {
    main: CSSProperties['color'];
    light: CSSProperties['color'];
    dark: CSSProperties['color'];
  };
  backgrounds: {
    dark: {
      main: CSSProperties['color'];
      dark: CSSProperties['color'];
      light: CSSProperties['color'];
    };
  };
}

export default function createMyTheme(
  options: IThemeOptions,
  customOptions: IThemeOptions = {
    color: {
      base: undefined,
      primary: Saturation,
      secondary: Saturation,
      tertiary: Saturation,
      danger: Saturation,
      success: Saturation,
      links: Saturation,
      warning: Saturation,
    },
    gradients: {
      main: undefined,
      light: undefined,
      dark: undefined,
    },
    backgrounds: {
      dark: {
        main: undefined,
        dark: undefined,
        light: undefined,
      },
    },
  }
) {
  return createMuiTheme({
    ...options,
    ...customOptions,
  });
}
