import React from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import { PhoneOutgoingIcon, PhoneIncomingIcon, PhoneMissedIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { UseEmployeesStatisticStyles } from '../StatisticsByEmployees.styles';
import { callType } from '../../StatisticsByCalls/StatisticsByCalls.constants';

export const EmployeesStatisticCallsHeaderCell = () => {
  const classes = UseEmployeesStatisticStyles();
  const [searchParams] = useSearchParams();
  const sCallType = searchParams.get('callType') || callType.allCalls;
  const [translate] = useTranslation();

  return (
    <Flex fullWidth justifyContent={'spaceBetween'}>
      {sCallType === callType.allCalls ? (
        <>
          <div className={classes.headerCallsContainer}>
            <PhoneIncomingIcon
              className={clsx(classes.links, classes.empStatisticMarginHalfRight)}
              width={16}
              height={16}
            />
            <Typography type={'text4'} color={'tertiary500'}>
              {translate('SUCCESSFUL_INCOMING')}
            </Typography>
          </div>
          <div className={classes.headerCallsContainer}>
            <Typography
              className={classes.empStatisticMarginHalfRight}
              type={'text4'}
              color={'tertiary500'}
            >
              {translate('SUCCESSFUL_OUTGOING')}
            </Typography>
            <PhoneOutgoingIcon className={classes.success} width={16} height={16} />
          </div>
        </>
      ) : (
        <>
          <Flex alignItems={'center'}>
            <PhoneOutgoingIcon
              className={clsx(classes.links, classes.empStatisticMarginHalfRight, {
                [classes.success]: sCallType === callType.outgoing,
              })}
              width={16}
              height={16}
            />
            <Typography type={'text4'} color={'tertiary500'}>
              {translate('INCOMING_SUCCESS')}
            </Typography>
          </Flex>
          <Flex alignItems={'center'}>
            <Typography type={'text4'} color={'tertiary500'}>
              {sCallType === callType.incoming
                ? translate('INCOMING_OUT')
                : translate('OUTGOING_OUT')}
            </Typography>
            {sCallType === callType.outgoing ? (
              <PhoneMissedIcon
                className={clsx(classes.empStatisticMarginHalfLeft, classes.warning)}
              />
            ) : (
              <PhoneIncomingIcon
                className={clsx(
                  classes.empStatisticMarginHalfLeft,
                  sCallType === callType.incoming ? classes.danger : classes.warning
                )}
                width={16}
                height={16}
              />
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
