import { OptionType, TariffOptionModel } from '@/client/generated/graphql';
import { IAdditionalOptionsType } from 'typings/tariffs';

export const getTariffComputedPrices = (
  optionList: IAdditionalOptionsType,
  selectedOptions: Array<boolean | undefined>,
  currentTariffOptions?: TariffOptionModel[],
  accountAmount?: number,
  integrationsAmount?: number,
  storeRecordsId?: number,
  isDiscountLoss?: boolean
) => {
  const additionalOptionsKeyList = Object.keys(optionList);

  return additionalOptionsKeyList.reduce(
    (result: { price: Array<number>; discountPrice: Array<number> }, keyName, keyIndex) => {
      const isOptionSelected = selectedOptions?.[keyIndex];
      if (!isOptionSelected) {
        result.price.push(0);
        result.discountPrice.push(0);
        return result;
      }
      const multiplier = keyName === OptionType.Integrations ? integrationsAmount || 1 : 1;
      const oList = optionList[keyName];

      let sOption: TariffOptionModel | undefined;
      let isPerEmployeePrice = false;
      let sOptionDiscountPrice = 0;
      let sOptionPrice = 0;
      let optionDiscountPrice = 0;
      let optionPrice = 0;

      const areRecordsOptions = keyName === 'Records';

      if (areRecordsOptions) {
        const recordsIndividualOption = oList.find(
          (option) => typeof option.domainTariffOptionParams?.days === 'number'
        );
        if (recordsIndividualOption) {
          isPerEmployeePrice = recordsIndividualOption?.params?.perEmployee;
          sOptionPrice = recordsIndividualOption.baseMonthlyFee || 0;
          sOptionDiscountPrice = recordsIndividualOption.monthlyFee || 0;
        } else {
          const storeOption = oList.find((item) => item.id === storeRecordsId);
          isPerEmployeePrice = storeOption?.params?.perEmployee;

          sOption = currentTariffOptions?.find((opt) => opt.id === storeRecordsId);
          sOptionDiscountPrice = sOption?.monthlyFee || 0;
          sOptionPrice = sOption?.baseMonthlyFee || 0;
          optionDiscountPrice = storeOption?.monthlyFee || 0;
          optionPrice = storeOption?.baseMonthlyFee || 0;
        }
      } else {
        const option = oList[0];
        isPerEmployeePrice = option?.params?.perEmployee;
        sOption = currentTariffOptions?.find((opt) => opt.type === keyName);
        sOptionDiscountPrice = sOption?.monthlyFee || 0;
        sOptionPrice = sOption?.baseMonthlyFee || 0;
        optionDiscountPrice = option?.monthlyFee || 0;
        optionPrice = option?.baseMonthlyFee || 0;
      }

      const calcOptionPrice = sOptionPrice || optionPrice;
      const calcOptionDiscountPrice = sOptionDiscountPrice || optionDiscountPrice;

      const computedPrice = isPerEmployeePrice
        ? (accountAmount || 0) * calcOptionPrice
        : calcOptionPrice;
      const computedDiscountPrice = isPerEmployeePrice
        ? (accountAmount || 0) * calcOptionDiscountPrice
        : calcOptionDiscountPrice;

      let resultPrice = computedPrice * multiplier;
      const resultDiscountPrice = computedDiscountPrice * multiplier;

      const isDiscount = resultPrice > resultDiscountPrice && !isDiscountLoss;

      // If this situation happens, it means there were
      // prices modifications made on backend side, we should
      // use bigger value, although code is a little counter-intuitive
      if (resultPrice < resultDiscountPrice) {
        resultPrice = resultDiscountPrice;
      }

      result.price.push(resultPrice);
      if (isDiscount) {
        result.discountPrice.push(resultDiscountPrice);
      } else {
        result.discountPrice.push(0);
      }
      return result;
    },
    { price: [], discountPrice: [] }
  );
};
