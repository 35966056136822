import { makeStyles } from '@material-ui/core/styles';

export const useVoiceMenuScenarioStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  formContentRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    marginTop: '2em',
    '&:first-child': {
      marginTop: 0,
    },
  },
  fieldSet: {
    marginTop: '1em',
  },
  formContentColumn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginTop: '1em',
  },
  drawerText: {
    whiteSpace: 'normal',
  },
  list: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginTop: '1em',
  },
  keyButtonCheckboxWrapper: {
    padding: '0.25em 0',
  },
});
