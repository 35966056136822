import { makeStyles } from '@material-ui/core/styles';

export const useConfirmDialogStyles = makeStyles(({ color: { tertiary } }) => ({
  dialog: {
    padding: '1.25em 1.5em',
  },
  paper: {
    borderRadius: '0.75em',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.75em',
    paddingLeft: '1.5em',
    paddingRight: '.75em',
    borderBottom: `1px solid ${tertiary[200]}`,
  },
  text: {
    paddingBottom: '2em',
    textAlign: 'left',
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    cursor: 'pointer',
    right: '1em',
    top: '0.875em',
  },
}));
