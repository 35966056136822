import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDepartmentsStyles } from '@/features/Departments/Departments.styles';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { Department } from '../Departments';

export const DepartmentNameCell = ({ item }: ICellProps<Department>) => {
  const [translate] = useTranslation();
  const classes = useDepartmentsStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  if (item) {
    const { name = '', activeEmployeesCount, employees } = item;

    let description = '';
    if (!employees || employees.length === 0) {
      description = translate('DEPT_NO_EMPLOYEES');
    } else if (!activeEmployeesCount) {
      description = translate('DEPT_NO_ACTIVE_EMPLOYEES');
    }

    return (
      <div className={classes.cellContent}>
        <HighlightedText type={'text'} text={name || ''} query={searchText} />
        <Typography type={'text4'} color={'danger600'} className={classes.secondaryText}>
          {description}
        </Typography>
      </div>
    );
  }

  return <div />;
};

export default DepartmentNameCell;
