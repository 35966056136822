import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@shared/components/Typography';
import ControlButtons from '@shared/components/ControlButtons';
import { XIcon } from '@shared/assets/images/icons';
import { Location, State } from 'history';
import { useConfirmDialogStyles } from './ConfirmDialog.styles';
import { ConfirmDialogProps, ConfirmDialogState } from './ConfirmDialog.interfaces';

export const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  isBlocked,
  onSaveChanges,
  onNavigationBlocked,
}) => {
  const classes = useConfirmDialogStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [translate] = useTranslation();
  const [{ open, canLeave, path }, setState] = useState<ConfirmDialogState>({
    open: false,
    path: null,
    canLeave: false,
  });

  const getPath = (l: Location<State>) => l.pathname.concat(l.search.replace(/^\?/, ''));

  useBlocker(
    (r) => {
      const navigatePath = r.location.pathname;
      if (getPath(r.location) !== getPath(location)) {
        setState((prevState) => ({
          ...prevState,
          path: navigatePath,
          open: true,
        }));
        if (onNavigationBlocked) {
          onNavigationBlocked(navigatePath);
        }
      }
    },
    canLeave ? false : isBlocked
  );

  useEffect(() => {
    if (canLeave && path) {
      navigate(path);
    }
  }, [canLeave, path, navigate]);

  function handleDiscardChanges() {
    setState((prevState) => ({
      ...prevState,
      canLeave: true,
    }));
  }

  function handleSaveChanges() {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
    onSaveChanges();
  }

  function handleCloseDialog() {
    setState((prevState) => ({
      ...prevState,
      path: null,
      open: false,
    }));
  }

  return (
    <Dialog classes={{ paper: classes.paper }} open={open}>
      <div className={classes.header}>
        <Typography type={'text3'} color={'tertiary900'} bold>
          {translate('SAVE_CHANGES')}?
        </Typography>
        <XIcon className={classes.close} onClick={handleCloseDialog} />
      </div>
      <DialogContent classes={{ root: classes.dialog }}>
        <div className={classes.text}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('CANCEL_CHANGES_WARNING')}
          </Typography>
        </div>
        <div className={classes.controls}>
          <ControlButtons
            confirmTitle={'SAVE_CHANGES'}
            cancelTitle={'NOT_SAVE'}
            cancelVariant="secondary"
            flexDirection={'row-reverse'}
            justifyContent={'start'}
            onCancelClick={handleDiscardChanges}
            onConfirmClick={handleSaveChanges}
            small
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
