import React, { FunctionComponent, useEffect, useState } from 'react';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import { REMOVE_EMPLOYEE_MUTATION } from '@/client/mutations';
import {
  CAN_REMOVE_EMPLOYEE_QUERY,
  EMPLOYEES_QUERY,
  GET_SELECTED_TARIFF_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GraphQLError } from 'graphql';
import Typography from '@shared/components/Typography';
import { IEmployee } from '@components/typings/interfaces';
import MessageDialog from '@shared/components/MessageDialog';
import { formatPhone } from '@components/utils';
import { getCurrentEmployee } from '@/utils/getCurrentEmployee';
import { getRole, hasSystemRole } from '@/utils';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { Role } from '@/client/generated/graphql';
import { useEmployeesStyles } from '../Employees.styles';

export const EmployeeActionsCell: FunctionComponent<ICellProps<IEmployee>> = ({ item }) => {
  const classes = useEmployeesStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { data: { user } = {} } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const currentEmployee = getCurrentEmployee(user);
  const role = getRole(item.role);

  const [removeEmployee, { error: errorRemoveEmployee, loading: loadingRemoveEmployee }] =
    useMutation(REMOVE_EMPLOYEE_MUTATION, {
      variables: {
        id: Number(item?.id),
      },
      refetchQueries: [{ query: EMPLOYEES_QUERY }, { query: GET_SELECTED_TARIFF_QUERY }],
    });

  const { loading: loadingCanRemoveEmployee, error: errorCanRemoveEmployee } = useQuery(
    CAN_REMOVE_EMPLOYEE_QUERY,
    {
      variables: {
        id: Number(item?.id),
      },
      skip: !open,
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (errorRemoveEmployee) {
      setOpen(true);
    }
  }, [errorRemoveEmployee]);

  const isEmployeeOwner = role === Role.Owner;

  const isPermissionsInsufficient =
    currentEmployee?.role !== Role.Owner &&
    isEmployeeOwner &&
    !hasSystemRole(currentEmployee?.role);

  const isEmployeeCurrent = currentEmployee?.id === item?.id;

  const handleConfirmDialogClose = () => {
    setOpen(false);
  };

  const handleRemoveEmployee = () => {
    if (loadingRemoveEmployee) return;
    removeEmployee().then(() => setOpen(false));
  };

  function handleItemClick() {
    setOpen(true);
  }

  const renderDeleteButton = () => {
    if (isEmployeeCurrent || isPermissionsInsufficient) return null;
    return (
      <Button
        variant={'secondary'}
        color={'error'}
        classes={{ root: classes.actionButton }}
        loading={loadingCanRemoveEmployee}
        onClick={handleItemClick}
      >
        <TrashIcon />
      </Button>
    );
  };

  const renderConfirmDialogContent = () => (
    <>
      <div className={classes.removeEmployeeCenterBlock}>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('ALL_EMPLOYEE_DATA')}
        </Typography>
        <Typography className={classes.removeEmployeeNameText} type={'text3'} color={'primary700'}>
          {item?.name || ''}
        </Typography>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('DELETE_EMPLOYEE_WARNING')}
        </Typography>
      </div>
      <ControlButtons
        confirmTitle={'REMOVE'}
        cancelTitle={'CANCEL'}
        cancelVariant="secondary"
        onConfirmClick={handleRemoveEmployee}
        onCancelClick={handleConfirmDialogClose}
        loading={loadingRemoveEmployee}
        justifyContent={'start'}
        flexDirection={'row-reverse'}
        confirmColor={'error'}
        small
      />
    </>
  );

  const renderUnableDeleteDialogContent = ({
    titleCode,
    hintCode,
    params,
  }: {
    titleCode: string;
    hintCode: string;
    params: { suffix?: string; numbers?: string[]; integrations?: string[] };
  }) => (
    <>
      <div className={classes.removeEmployeeCenterBlock}>
        <Typography className={classes.marginBottom1} type={'text3'} color={'tertiary900'}>
          {translate(titleCode, params)}
        </Typography>
        {params.numbers?.map((phone: string) => (
          <Typography
            className={classes.removeEmployeeNumberItem}
            key={phone}
            type={'text3'}
            color={'tertiary900'}
            bold
          >
            {formatPhone(phone)}
          </Typography>
        ))}
        {params.integrations?.map((integration: string, index: number) => (
          <Typography
            className={classes.removeEmployeeNumberItem}
            key={`integration_${index}`}
            type={'text3'}
            color={'tertiary900'}
            bold
          >
            {integration}
          </Typography>
        ))}
        {hintCode && (
          <Typography className={classes.marginTop1} type={'text3'} color={'tertiary900'}>
            {translate(hintCode)}
          </Typography>
        )}
      </div>
      <Flex justifyContent={'flexEnd'}>
        <Button title={translate('CLOSE')} onClick={handleConfirmDialogClose} smallHeight />
      </Flex>
    </>
  );

  const renderInsufficientPermissionsDialogContent = () => (
    <>
      <div className={classes.removeEmployeeCenterBlock}>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('INSUFFICIENT_PERMISSIONS')}
        </Typography>
      </div>
      <div className={classes.textAlignCenter}>
        <Button title={translate('CLOSE')} onClick={handleConfirmDialogClose} smallHeight />
      </div>
    </>
  );

  const renderDialogContent = () => {
    const error = errorCanRemoveEmployee || errorRemoveEmployee;
    if (error?.graphQLErrors?.length) {
      const errorDescription = error?.graphQLErrors.reduce(
        (
          result: {
            titleCode: string;
            hintCode: string;
            params: { suffix?: string; numbers?: string[]; integrations?: string[] };
          },
          {
            extensions: {
              exception: { message = '', affectedNumbers = [], affectedIntegrations = [] } = {},
            } = {},
          }: GraphQLError
        ) => {
          switch (message) {
            case 'CANNOT_DELETE_EMPLOYEE_DUE_NUMBERS': {
              return {
                titleCode: 'CANNOT_DELETE_EMPLOYEE_DUE_NUMBERS',
                hintCode: 'CONFIGURE_INCOMING_CALLS_TO_ANOTHER_EMPLOYEE_AND_TRY_AGAIN',
                params: {
                  suffix: translate(
                    affectedNumbers?.length > 1 ? 'MANY_NUMBERS' : 'ONE_NUMBER'
                  )?.toLowerCase(),
                  numbers: affectedNumbers?.map(({ phone = '' }) => phone),
                },
              };
            }
            case 'CANNOT_DELETE_EMPLOYEE_DUE_INTEGRATIONS': {
              return {
                titleCode: 'CANNOT_DELETE_EMPLOYEE_DUE_INTEGRATIONS',
                hintCode: '',
                params: {
                  suffix: translate(
                    affectedIntegrations?.length > 1 ? 'MANY_INTEGRATIONS' : 'ONE_INTEGRATION'
                  ),
                  integrations: affectedIntegrations?.map(({ name = '' }) => name),
                },
              };
            }
            case 'CANNOT_DELETE_EMPLOYEE_DUE_VOICEMAIL':
              return {
                titleCode: 'CANNOT_DELETE_EMPLOYEE_DUE_VOICEMAIL',
                hintCode: 'CONFIGURE_VOICEMAIL_TO_ANOTHER_EMPLOYEE_AND_TRY_AGAIN',
                params: {},
              };
            default:
              return result;
          }
        },
        {
          titleCode: 'SOMETHING_WENT_WRONG',
          hintCode: 'PLEASE_TRY_AGAIN_LATER',
          params: {},
        }
      );

      return (
        <MessageDialog
          isOpen={open}
          title={translate('UNABLE_TO_DELETE')}
          contentClass={classes.removeEmployeeDialog}
          onCancel={handleConfirmDialogClose}
          renderContent={renderUnableDeleteDialogContent(errorDescription)}
        />
      );
    }

    if (isPermissionsInsufficient) {
      return (
        <MessageDialog
          isOpen={open}
          title={translate('INSUFFICIENT_PERMISSIONS')}
          contentClass={classes.removeEmployeeDialog}
          onCancel={handleConfirmDialogClose}
          renderContent={renderInsufficientPermissionsDialogContent()}
        />
      );
    }

    return (
      <MessageDialog
        isOpen={open}
        title={translate('DELETE_EMPLOYEE_PROMPT')}
        contentClass={classes.removeEmployeeDialog}
        onCancel={handleConfirmDialogClose}
        renderContent={renderConfirmDialogContent()}
      />
    );
  };

  if (item) {
    const { id } = item;

    return (
      <>
        <Button
          variant={'secondary'}
          className={classes.actionButton}
          onClick={() => navigate(`/employee/employees/${id}`)}
        >
          <EditIcon />
        </Button>
        {renderDeleteButton()}
        {open && renderDialogContent()}
      </>
    );
  }

  return <div />;
};
