export enum FilterButtonIconTypes {
  All = 'all',
  Incoming = 'incoming',
  IncomingMissed = 'incoming-missed',
  Outgoing = 'outgoing',
  OutgoingMissed = 'outgoing-missed',
}

export interface IButtonFilterProps {
  title: string;
  id: number | string;
  selected: boolean;
  disabled?: boolean;
  border?: boolean;
  count?: number;
  onClick?: (id: string) => void;
  icon?: FilterButtonIconTypes;
}
