import React from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { Department } from '../Departments';

export const DepartmentCountCell = ({ column, item }: ICellProps<Department>) => {
  if (!item) {
    return <div />;
  }

  let value = 0;

  if (column.id === 'employeesCount') {
    value = item.employees?.length || 0;
  }
  if (column.id === 'activeEmployeesCount') {
    value = item.activeEmployeesCount || 0;
  }

  return (
    <Typography type={'text3'} color={!value ? 'danger600' : 'inherit'}>
      {value}
    </Typography>
  );
};

export default DepartmentCountCell;
