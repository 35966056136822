import React, {
  FocusEvent,
  FunctionComponent,
  KeyboardEvent,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import Button from '@shared/components/Button';
import { PlusIcon, ChevronLeftIcon, ChevronRightIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import { IntegrationContext } from '@/features/Integrations/IntegrationForm/IntegrationForm';
import { IIntegrationScenarioTabsType, IScenarioSettingProps } from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';

export const ScenariosTabs: FunctionComponent<IIntegrationScenarioTabsType> = ({
  onTabClick,
  onTabBlur,
  onError,
}) => {
  const {
    newTabAvailable,
    integrationSettings,
    isScenarioNameEdit,
    scenarioIndex,
    isTabsHidden,
    isScenarioTabsAvailable,
  } = useContext(IntegrationContext) || {};
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const tabRoot = useRef<HTMLDivElement | null>(null);
  const tabList = useRef<Array<HTMLDivElement | null>>([]);
  const [tabState, setTabState] = useState<{ tabIndex: number; left: number }>({
    tabIndex: 0,
    left: 0,
  });

  const rootWidth = tabRoot?.current?.clientWidth || 0;
  const tabsWidth = (tabList?.current || []).reduce((res, el) => res + (el?.clientWidth || 0), 50);

  function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      // @ts-ignore Компонент материала передает неправильный тип по этому событию
      const { target: { value: targetValue = undefined } = {} } = event || {};
      event.preventDefault();
      onTabBlur(targetValue);
    }
  }

  function setRefList(el: HTMLDivElement | null, ind: number) {
    tabList.current[ind] = el;
    return tabList;
  }

  function handleScenarioNameEditBlur(event: FocusEvent<HTMLInputElement>) {
    const { target: { value: targetValue = undefined } = {} } = event || {};
    onTabBlur(targetValue);
  }

  function handleSwitchButtonScroll(direction: string, currentIndex: number) {
    const isLeftButton = direction === 'left' && currentIndex !== 0;
    const elementIndex = isLeftButton ? currentIndex - 1 : currentIndex + 1;
    const scrollPosition = tabList?.current?.[elementIndex]?.offsetLeft || 0;
    setTabState({
      left: scrollPosition,
      tabIndex: elementIndex,
    });
  }

  function handleNewTabClick() {
    if (newTabAvailable) {
      onTabClick(integrationSettings?.length || 0, 'new');
      const tabWidth = (tabList?.current || [])[0]?.clientWidth || 0;
      if (tabsWidth + tabWidth - tabState.left > rootWidth) {
        handleSwitchButtonScroll('right', tabState.tabIndex);
      }
    } else {
      onError();
    }
  }

  useEffect(() => {
    if (
      tabList?.current?.[tabState.tabIndex] === null ||
      tabList?.current?.[tabState.tabIndex] === undefined
    ) {
      handleSwitchButtonScroll('left', (scenarioIndex || 0) + 1);
    }
  }, [scenarioIndex, tabList, tabState.tabIndex]);

  const renderDefaultSummary = () => (
    <Flex className={classes.integrationScenarioTabMain}>
      <Flex alignItems={'center'} className={classes.integrationScenarioTab}>
        <Typography type={'text3'} color={'primary700'}>
          {translate('CAPABILITIES')}
        </Typography>
      </Flex>
    </Flex>
  );

  const renderIntegrationTabs = () => {
    if (!integrationSettings) {
      return [];
    }
    return integrationSettings.map((item: IScenarioSettingProps, index: number) => {
      const isScenarioTabSelected = scenarioIndex === index;
      if (isScenarioTabSelected && isScenarioNameEdit) {
        return (
          <div key={`scenarioSummary-${index}`} ref={(el: HTMLDivElement) => setRefList(el, index)}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              className={clsx(
                classes.integrationScenarioTabEdit,
                classes.integrationScenarioTabActive
              )}
            >
              <FormFieldRhfUncontrolled
                onKeyDown={handleKeyDown}
                onBlur={handleScenarioNameEditBlur}
                name={'scenarioName'}
              />
            </Flex>
          </div>
        );
      }
      return (
        <div key={`scenarioTab-${index}`} ref={(el: HTMLDivElement) => setRefList(el, index)}>
          <Button
            onClick={() => onTabClick(index, isScenarioTabSelected ? 'edit' : 'switch')}
            className={clsx(classes.integrationButtonTab, {
              [classes.integrationButtonTabActive]: isScenarioTabSelected,
            })}
            clear
          >
            <Flex
              key={`scenarioSummary-${index}`}
              justifyContent={'flexStart'}
              alignItems={'center'}
              className={clsx(classes.integrationScenarioTab, {
                [classes.integrationScenarioTabActive]: isScenarioTabSelected,
              })}
            >
              <Typography
                className={classes.integrationTextEllipsis}
                type={'text3'}
                color={'primary700'}
              >
                {item.name}
              </Typography>
            </Flex>
          </Button>
        </div>
      );
    });
  };

  const renderSwitchButton = (direction: string) => {
    const isLeftButton = direction === 'left';
    const isLeftButtonVisible = isLeftButton && tabState.left !== 0;
    const isRightButtonVisible = !isLeftButton && tabsWidth - tabState.left > rootWidth;
    const isButtonVisible = isLeftButtonVisible || isRightButtonVisible;
    const directionIcon = isLeftButton ? (
      <ChevronLeftIcon className={classes.integrationScenarioAddIcon} />
    ) : (
      <ChevronRightIcon className={classes.integrationScenarioAddIcon} />
    );

    return (
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        className={clsx(classes.integrationScenarioSwitchButton, {
          [classes.integrationScenarioSwitchButtonLeftShadow]: isLeftButton && isButtonVisible,
          [classes.integrationScenarioSwitchButtonRightShadow]: !isLeftButton && isButtonVisible,
        })}
        style={isLeftButton ? { left: 0 } : { right: 0 }}
      >
        {isButtonVisible ? (
          <Button onClick={() => handleSwitchButtonScroll(direction, tabState.tabIndex)} clear>
            <Flex
              className={clsx(classes.integrationScenarioSwitchButtonWrapper, {
                [classes.integrationScenarioSwitchButtonLeftBorder]: !isLeftButton,
              })}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {directionIcon}
            </Flex>
          </Button>
        ) : null}
      </Flex>
    );
  };

  const renderScenarioSummary = () => {
    if (isTabsHidden) return null;
    return (
      <Flex className={classes.integrationScenarioTabMain}>
        {renderSwitchButton('left')}
        <div ref={tabRoot} className={classes.integrationScenarioTabContainer}>
          <div
            style={{ left: `-${tabState.left}px` }}
            className={classes.integrationScenarioTabOverflowContainer}
          >
            {renderIntegrationTabs()}
            <Button
              onClick={() => handleNewTabClick()}
              className={clsx(classes.integrationButtonTab, classes.integrationButtonTabAdd)}
              clear
            >
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.integrationScenarioAddTab}
              >
                <PlusIcon className={classes.integrationScenarioAddIcon} />
              </Flex>
            </Button>
          </div>
        </div>
        {renderSwitchButton('right')}
      </Flex>
    );
  };

  return isScenarioTabsAvailable ? renderScenarioSummary() : renderDefaultSummary();
};

export default memo(ScenariosTabs);
