import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { IIndicatorProps } from '@components/Indicator/Indicator.interfaces';
import { useStyles } from './Indicator.styles';

export const Indicator: FunctionComponent<IIndicatorProps> = ({
  size = 8,
  className,
  status,
  middleCircle,
}) => {
  const classes = useStyles({ size });

  const classNames = clsx([classes.indicator, className], {
    [classes.active!]: status === 'active',
    [classes.inactive!]: status === 'inactive',
    [classes.error!]: status === 'error',
  });

  return (
    <div className={classNames}>{middleCircle && <div className={classes.middleCircle} />}</div>
  );
};

export default Indicator;
