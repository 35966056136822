import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import { callType } from '../../StatisticsByCalls/StatisticsByCalls.constants';
import { IStatisticsByEmployees } from '../../StatisticsByCalls/StatisticsByCalls.interfaces';
import { UseEmployeesStatisticStyles } from '../StatisticsByEmployees.styles';

export const EmployeesStatisticAverageDuration: FunctionComponent<
  ICellProps<IStatisticsByEmployees>
> = ({ item }) => {
  const [searchParams] = useSearchParams();
  const classes = UseEmployeesStatisticStyles();
  const sCallType = searchParams.get('callType');
  const { inAvgDuration = 0, avgDuration = 0, outAvgDuration = 0 } = item || {};

  const avgDurationValue = () => {
    let avgValue = avgDuration;
    if (sCallType === callType.incoming) avgValue = inAvgDuration;
    if (sCallType === callType.outgoing) avgValue = outAvgDuration;
    const hours = Math.floor(avgValue / 60 / 60);
    const minutes = Math.floor(avgValue / 60) - hours * 60;
    const seconds = Math.floor(avgValue % 60);
    const formattedValue = {
      hours: hours > 0 ? hours.toString().padStart(2, '0') : '',
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
    return `${formattedValue.hours && `${formattedValue.hours}:`}${formattedValue.minutes}:${
      formattedValue.seconds
    }`;
  };

  return (
    <div className={classes.rightCell}>
      <Typography type={'text3'} color={'tertiary900'}>
        {avgDurationValue()}
      </Typography>
    </div>
  );
};
