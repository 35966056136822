import { TariffOptionModel } from '@/client/generated/graphql';
import { ResultOptionsType } from '@components/typings/interfaces';

export const getAccountsPrices = (
  baseDomainsOption?: TariffOptionModel,
  additionalDomainsOption?: TariffOptionModel,
  tariffDataInfo?: ResultOptionsType
) => {
  const {
    accountPrice = 0,
    baseAccountPrice = 0,
    additionalAccountPrice = 0,
    baseAdditionalAccountPrice = 0,
  } = tariffDataInfo || {};

  const curAccountPrice = baseDomainsOption?.monthlyFee || 0;
  const curBaseAccountPrice = baseDomainsOption?.baseMonthlyFee || 0;

  const curAdditionalAccountPrice = additionalDomainsOption?.monthlyFee || 0;
  const curBaseAdditionalAccountPrice = additionalDomainsOption?.baseMonthlyFee || 0;

  const sAccountPrice = curAccountPrice || accountPrice;
  const sBaseAccountPrice = curBaseAccountPrice || baseAccountPrice;

  const sAdditionalAccountPrice = curAdditionalAccountPrice || additionalAccountPrice;
  const sBaseAdditionalAccountPrice = curBaseAdditionalAccountPrice || baseAdditionalAccountPrice;

  const isAccountDiscount = sAccountPrice !== sBaseAccountPrice;
  const isAdditionalAccountDiscount = sAdditionalAccountPrice !== sBaseAdditionalAccountPrice;

  return {
    accountPrice: sAccountPrice,
    baseAccountPrice: sBaseAccountPrice,
    additionalAccountPrice: sAdditionalAccountPrice,
    baseAdditionalAccountPrice: sBaseAdditionalAccountPrice,
    isAccountDiscount,
    isAdditionalAccountDiscount,
  };
};
