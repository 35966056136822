import { REMOVE_RESERVED_NUMBER_MUTATION } from '@/client/mutations';
import { useMutation } from '@apollo/client';
import { formatPhone, toPrecision } from '@components/utils';
import { TrashIcon } from '@shared/assets/images/icons';
import Preloader from '@shared/components/Preloader';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import { useReservedNumberStyle } from './ReservedNumber.styles';
import { IReservedNumberProps } from './ReservedNumber.interfaces';

export const ReservedNumber: FunctionComponent<IReservedNumberProps> = ({
  id,
  phone,
  city,
  category,
  setupFee,
  monthlyFee,
  onComplete,
}) => {
  const classes = useReservedNumberStyle();
  const [translate] = useTranslation();

  const [deleteReservedNumber, { loading: loadingDeleteNumber }] = useMutation(
    REMOVE_RESERVED_NUMBER_MUTATION
  );

  function handleDeleteReservedNumber() {
    deleteReservedNumber({ variables: { domainNumberId: id } }).then(() => onComplete());
  }

  return (
    <div className={classes.phoneLine}>
      <div className={clsx(classes.defaultElementWidth12, classes.paddingLeft1)}>
        <Typography type={'text3'} color={'tertiary900'}>
          {formatPhone(phone)}
        </Typography>
      </div>
      <div className={classes.defaultElementWidth12}>
        <Typography type={'text3'} color={'tertiary900'}>
          {city}
        </Typography>
      </div>
      <div className={classes.defaultElementWidth9}>
        {category && (
          <Typography type={'text3'} color={'tertiary900'}>
            {translate(`NUMBER_${category}`)}
          </Typography>
        )}
      </div>
      <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
        <Typography type={'text3'} color={'tertiary900'}>
          {setupFee ? toPrecision(setupFee, false) : 0} ₽
        </Typography>
      </div>
      <div
        className={clsx(
          classes.defaultElementWidth7,
          classes.textAlignRight,
          classes.phoneNumberPaddingCell
        )}
      >
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('RUB_PER_MONTH_SHORT', {
            amount: monthlyFee ? toPrecision(monthlyFee, false) : 0,
          })}
        </Typography>
      </div>
      <Button
        className={classes.phoneNumberCancelButton}
        onClick={handleDeleteReservedNumber}
        variant={'tertiary'}
      >
        {loadingDeleteNumber ? <Preloader size="small" /> : <TrashIcon />}
      </Button>
    </div>
  );
};
