import React, { FunctionComponent, useState } from 'react';
import Button from '@shared/components/Button';
import { useNavigate } from 'react-router-dom';
import { TrashIcon, EditIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import MessageDialog from '@shared/components/MessageDialog';
import Translate from '@shared/components/Translate';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { useMutation } from '@apollo/client';
import { REMOVE_MISSED_CALL_CONFIG_MUTATION } from '@/client/mutations';
import { GET_ALL_MISSED_CALLS_QUERY } from '@/client/queries';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import { useNotificationsStyles } from '../SettingsNotifications.styles';
import { IMissedCalls } from '../SettingsNotifications.interface';

export const NotificationsActionsCell: FunctionComponent<ICellProps<IMissedCalls>> = ({ item }) => {
  const navigate = useNavigate();
  const classes = useNotificationsStyles();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [translate] = useTranslation();
  const notificationNumber = item?.domainNumber
    ? formatPhone(item?.domainNumber.phone)
    : item?.employee?.name || item?.department?.name;
  const dialogReceiversText = item?.allNumbers ? translate('ALL_NUMBERS') : notificationNumber;

  const [removeNotification, { loading: loadingRemoveNotification }] = useMutation(
    REMOVE_MISSED_CALL_CONFIG_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_ALL_MISSED_CALLS_QUERY,
        },
      ],
    }
  );

  const handleRemoveNotification = () => {
    removeNotification({
      variables: {
        data: {
          missedCallId: Number(item?.id),
        },
      },
    });
    setIsRemoveDialogOpen(false);
  };

  const handleClosePopupDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  return (
    <>
      <Button
        variant="secondary"
        className={classes.actionButton}
        onClick={() => navigate(`/settings/notifications/${item?.id}`)}
      >
        <EditIcon />
      </Button>
      <Button
        variant={'secondary'}
        color={'error'}
        classes={{ root: classes.actionButton }}
        onClick={() => setIsRemoveDialogOpen(true)}
      >
        <TrashIcon />
      </Button>
      <MessageDialog
        isOpen={isRemoveDialogOpen}
        title={translate('DELETE_EMPLOYEE_PROMPT')}
        onCancel={handleClosePopupDialog}
        contentClass={classes.confirmPopupDialog}
        renderContent={
          <>
            <div className={classes.confirmPopupText}>
              <Translate
                i18nKey={'REMOVE_NOTIFICATION_INFORMATION'}
                values={{
                  dialogReceiversText,
                }}
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  s: <Typography type={'text3'} color={'primary700'} medium />,
                }}
              />
            </div>
            <ControlButtons
              confirmColor={'error'}
              confirmTitle={'REMOVE'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              onConfirmClick={() => handleRemoveNotification()}
              onCancelClick={handleClosePopupDialog}
              loading={loadingRemoveNotification}
              justifyContent={'start'}
              flexDirection={'row-reverse'}
              small
            />
          </>
        }
      />
    </>
  );
};
