import React, { PropsWithChildren } from 'react';
import { RadioGroup as MuiRadioGroup, RadioGroupProps } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useRadioGroupStyles } from './RadioGroup.styles';

export const RadioGroup = ({
  name = '',
  defaultValue = '',
  children,
  ...props
}: PropsWithChildren<Omit<RadioGroupProps, 'defaultValue'> & { defaultValue?: string }>) => {
  const classes = useRadioGroupStyles();
  const { control, watch } = useFormContext();
  const defaultFormValue = watch(name);
  const defaultControlValue = defaultFormValue || defaultValue;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultControlValue}
      render={({ field }) => (
        <MuiRadioGroup
          className={classes.root}
          defaultValue={defaultControlValue}
          {...props}
          {...field}
        >
          {children}
        </MuiRadioGroup>
      )}
    />
  );
};

export default RadioGroup;
