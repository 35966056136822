import { Validate } from 'react-hook-form';

type MergeValidateFuncsAndObjectsFnArg<T> =
  | Validate<T>
  | Record<string, Validate<T>>
  | undefined
  | null;

function isValidateFn<T>(value: unknown): value is Validate<T> {
  return typeof value === 'function';
}

function isValidateObject<T>(value: unknown): value is Record<string, Validate<T>> {
  return typeof value === 'object' && value !== null;
}

export function mergeValidateFuncsAndObjects<T>(
  ...args: MergeValidateFuncsAndObjectsFnArg<T>[]
): Record<string, Validate<T>> {
  let uniqueIncrementId = 0;

  const resultValidateObject: Record<string, Validate<T>> = {};

  for (let i = 0; i < args.length; i += 1) {
    const arg = args[i];

    if (isValidateFn(arg)) {
      resultValidateObject[uniqueIncrementId] = arg;
      uniqueIncrementId += 1;
    } else if (isValidateObject(arg)) {
      for (const value of Object.values(arg)) {
        resultValidateObject[uniqueIncrementId] = value;
        uniqueIncrementId += 1;
      }
    }
  }

  return resultValidateObject;
}
