import React, { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useState } from 'react';
import clsx from 'clsx';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import { PlusIcon, TrashIcon } from '@shared/assets/images/icons';
import { InputAdornment } from '@material-ui/core';
import Typography from '@shared/components/Typography';
import FormFieldBase from '@shared/components/FormFieldBase';
import { useSchedulerPeriodsStyles } from './SchedulerPeriods.styles';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';
import { customPeriods, initialPeriods } from '../Scheduler/Scheduler.constants';
import { ISchedulePeriod } from '../Scheduler/Scheduler.interfaces';

export const SchedulerPeriods = ({
  setActivePeriod,
  addPeriod,
  removePeriod,
  periods,
  activePeriod,
  changePeriod,
}: {
  activePeriod: SchedulePeriodsTypes;
  setActivePeriod: (id: SchedulePeriodsTypes) => void;
  periods: ISchedulePeriod[];
  addPeriod: () => void;
  removePeriod: (e: MouseEvent<SVGElement>, key: SchedulePeriodsTypes) => void;
  changePeriod: (item: ISchedulePeriod) => void;
}) => {
  const [translate] = useTranslation();
  const classes = useSchedulerPeriodsStyles();
  const [editingPeriod, setEditingPeriod] = useState<SchedulePeriodsTypes | undefined>();
  const [value, setValue] = useState<string | undefined>();
  const [error, setError] = useState<SchedulePeriodsTypes | undefined>();

  const renderControl = (period: ISchedulePeriod, index?: number) => {
    const { key, titleCode, name } = period;
    const controlClass = clsx(classes.control, {
      [classes.active]: key === activePeriod && !editingPeriod,
    });

    const controlCaptionClass = clsx(classes.controlCaptionText, {
      [classes.activeCaptionText]: key === activePeriod && !editingPeriod,
      [classes.inactiveCaptionText]: !(key === activePeriod && !editingPeriod),
    });

    const handleValueChange =
      (periodKey: SchedulePeriodsTypes) =>
      ({ target: { value: targetValue } }: ChangeEvent<HTMLInputElement>) => {
        if (targetValue.length < 21) {
          setError(undefined);
          setValue(targetValue);
          if (
            periods.some(
              ({ name: periodName, titleCode: periodTitleCode, key: currentKey }) =>
                periodKey !== currentKey &&
                (periodName || translate(periodTitleCode)) === targetValue
            )
          ) {
            setError(periodKey);
          } else if (error && error === periodKey) {
            setError(undefined);
          }
        }
      };

    const indicatorClass = clsx(classes.indicator, {
      [classes.default]: key === SchedulePeriodsTypes.NonWorkingHours,
      [classes.success]: key === SchedulePeriodsTypes.WorkingHours,
      [classes.warning]: key === SchedulePeriodsTypes.Custom1,
      [classes.links]: key === SchedulePeriodsTypes.Custom2,
      [classes.secondary]: key === SchedulePeriodsTypes.Custom3,
    });

    const handleBlur = ({ target: { value: newName } }: FocusEvent<HTMLInputElement>) => {
      if (
        newName &&
        periods.some(
          ({ name: periodName, key: periodKey, titleCode: periodTitleCode }) =>
            periodKey !== period.key && (periodName || translate(periodTitleCode)) === newName
        )
      ) {
        setError(period.key);
      } else {
        changePeriod({ ...period, name: newName });
        setEditingPeriod(undefined);
        setValue(undefined);
      }
    };

    const handleKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (!e.defaultPrevented && e.key === 'Enter' && !error) {
        changePeriod({ ...period, name: (e.target as HTMLInputElement).value });
        setEditingPeriod(undefined);
        setValue(undefined);
        e.preventDefault();
      }
      e.stopPropagation();
    };

    const startAdornment = (
      <InputAdornment position={'start'}>
        <div className={indicatorClass} />
      </InputAdornment>
    );

    const inputProps = {
      classes: { root: classes.input, input: classes.inputBase },
      startAdornment,
    };

    const validationMessage = error && error === key ? translate('PERIOD_ALREADY_EXIST') : '\xa0';

    if (editingPeriod === key) {
      return (
        <div key={key} className={classes.controlGroup}>
          <FormFieldBase
            autoFocus
            InputProps={inputProps}
            onChange={handleValueChange(key)}
            value={value || ''}
            onKeyDown={handleKeydown}
            onBlur={handleBlur}
          />
          <Typography color={'danger600'} type={'text5'} className={classes.validationError}>
            {validationMessage}
          </Typography>
        </div>
      );
    }

    const handleClick = () => {
      if (!error) {
        if (
          key === activePeriod &&
          key !== SchedulePeriodsTypes.NonWorkingHours &&
          key !== SchedulePeriodsTypes.WorkingHours
        ) {
          const newValue = name || translate(titleCode) || '';
          if (
            name &&
            periods.some(
              ({ name: periodName, key: periodKey }) => periodName === newValue && key !== periodKey
            )
          ) {
            setError(key);
          }
          setEditingPeriod(key);
          setValue(newValue);
        } else {
          setActivePeriod(key);
        }
      }
    };

    const renderControlIcon = () => {
      if (
        index &&
        key !== SchedulePeriodsTypes.WorkingHours &&
        key === activePeriod &&
        key !== editingPeriod
      ) {
        return (
          <TrashIcon
            className={classes.controlIcon}
            onClick={(e: MouseEvent<SVGElement>) => removePeriod(e, key)}
          />
        );
      }
      return <div className={classes.controlStab} />;
    };

    return (
      <div key={`period-control-${key}`} className={classes.controlGroup}>
        <Button
          variant={'text'}
          title={name || translate(titleCode)}
          onClick={handleClick}
          className={controlClass}
        >
          <div className={classes.controlContent}>
            <div className={classes.controlCaption}>
              <div className={indicatorClass} />
              <div className={controlCaptionClass}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {name || translate(titleCode)}
                </Typography>
              </div>
            </div>
            {renderControlIcon()}
          </div>
        </Button>
        <Typography color={'danger600'} type={'text5'} className={classes.validationError}>
          {validationMessage}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        {periods
          .filter(
            ({ key }) =>
              key !== SchedulePeriodsTypes.ATC && key !== SchedulePeriodsTypes.NonWorkingHours
          )
          .map(renderControl)}
        {periods.filter(
          ({ key }) =>
            key !== SchedulePeriodsTypes.ATC && key !== SchedulePeriodsTypes.NonWorkingHours
        ).length !==
          initialPeriods.length + customPeriods.length - 1 && (
          <Button
            key={'period-control-add'}
            variant={'secondary'}
            onClick={addPeriod}
            className={classes.addButton}
          >
            <PlusIcon />
          </Button>
        )}
      </div>
      <div className={classes.controls}>
        {[
          periods.find(({ key }) => key === SchedulePeriodsTypes.NonWorkingHours) || {
            ...initialPeriods[0],
          },
        ].map(renderControl)}
      </div>
    </div>
  );
};

export default SchedulerPeriods;
