import { scenarioSettingsType, scenariosListType } from '../IntegrationForm.interfaces';
import PlanfixFirstImg from './images/planfixfirst.png';
import PlanfixSecondImg from './images/planfixsecond.png';

export const PlanfixScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'INCOMING_SCENARIOS_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_23'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_07',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_22'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.Responsible,
      options: ['INCOMING_SCENARIOS_ITEM_04'],
    },
  ],
  [
    {
      title: 'OUTGOING_SCENARIOS_TITLE_08',
      edit: scenarioSettingsType.Always,
      options: ['OUTGOING_SCENARIOS_ITEM_18'],
    },
    {
      title: 'OUTGOING_SCENARIOS_TITLE_07',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_22'],
    },
  ],
  [
    {
      title: 'GENERAL_SCENARIOS_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['GENERAL_SCENARIOS_ITEM_08'],
    },
  ],
];

export const PLANFIX_SLIDER_IMAGES = [
  { title: 'WHERE_SEARCH_PLANFIX_MESSAGE', img: PlanfixFirstImg },
  { title: 'WHERE_SEARCH_PLANFIX_MESSAGE_02', img: PlanfixSecondImg }
];
