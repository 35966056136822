export function compressImage(
  blob: Blob,
  maxWidth: number,
  maxHeight: number,
  format = 'image/jpeg',
  quality = 1
) {
  let blobURL = '';

  return new Promise<Blob | null>((resolve, reject) => {
    const img = new Image();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    blobURL = URL.createObjectURL(blob);

    img.onload = () => {
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth || img.height > maxHeight) {
        const aspectRatio = img.width / img.height;
        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = maxWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = maxHeight * aspectRatio;
        }
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      canvas.toBlob(
        (blobObj) => {
          resolve(blobObj);
        },
        format,
        quality
      );
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = blobURL;
  }).finally(() => {
    URL.revokeObjectURL(blobURL);
  });
}

export const canvasToBlob = (canvas: HTMLCanvasElement | undefined): Promise<Blob | null> =>
  new Promise((resolve) => {
    if (!canvas) {
      resolve(null);
    } else {
      canvas.toBlob((fileContent: Blob | null) => {
        resolve(fileContent);
      });
    }
  });

export function getImageFormatFromBase64(base64String: string) {
  const regex = /^data:image\/(jpeg|jpg|png|gif|bmp);/;

  const match = base64String.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return null;
}

export function isBase64ImageValid(base64String: string) {
  const img = new Image();

  return new Promise<boolean>((resolve, reject) => {
    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = base64String;
  });
}
