import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { IHistoryList } from '../HistoryPage.interfaces';

export const DateCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const [translate] = useTranslation();

  if (item) {
    const { date } = item;
    const val = `${date.day} ${translate(date.monthShort)}`;

    return (
      <Typography type={'text3'} color={'tertiary600'}>
        {val}
      </Typography>
    );
  }

  return <div />;
};
