import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  COMMON_OUTGOING_NUMBER_QUERY,
  DOMAIN_NUMBERS_QUERY,
  OUTGOING_CALLS_QUERY,
} from '@/client/queries';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { EditIcon, PhoneIcon } from '@shared/assets/images/icons';
import QuickSearch from '@components/QuickSearch';
import Button from '@shared/components/Button';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import Typography from '@shared/components/Typography';
import Breadcrumbs from '@/components/Breadcrumbs';
import PagePreloader from '@components/PagePreloader';
import Translate from '@shared/components/Translate';
import PageError from '@shared/components/PageError';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { OutgoingCallsQuery } from '@/client/generated/graphql';
import { isCertainGraphqlErrorCodeInApolloError } from '@shared/utils/apollo';
import OutgoingCallsCommonNumber from '../OutgoingCallsCommonNumber/OutgoingCallsCommonNumber';
import { useOutgoingCallsStyles } from './OutgoingCalls.styles';
import OutgoingCall from '../OutgoingCall/OutgoingCall';
import {
  OutgoingCallActionsCell,
  OutgoingCallTypeHeaderCell,
  OutgoingCallNameCell,
  OutgoingCallPhoneCell,
  OutgoingCallTypeCell,
} from './modules';

export type OutgoingCallData = OutgoingCallsQuery['getOutgoingNumbers'][number];

export const OutgoingCalls: FunctionComponent = () => {
  const classes = useOutgoingCallsStyles();
  const [translate] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    loading = false,
    error = false,
    data: outgoingData,
    called,
  } = useQuery(OUTGOING_CALLS_QUERY);

  const { data: domainNumbersData, called: domainNumbersCalled } = useQuery(DOMAIN_NUMBERS_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const domainNumbers = domainNumbersData?.boughtDomainNumbers;

  const noNumbers = !domainNumbers || domainNumbers.length === 0;
  const allOutgoingCallsData = outgoingData?.getOutgoingNumbers;
  const [searchParams, setSearchParams] = useSearchParams();
  const sQuery = searchParams.get('query')?.toUpperCase();
  const filterQuery = searchParams.getAll('filter');
  const outgoingCallId = searchParams.get('id');

  const formMethods = useForm({
    defaultValues: {
      query: sQuery,
    },
  });

  const {
    data: commonOutgoingData,
    loading: commonNumberLoading,
    error: commonNumberError,
  } = useQuery<{ getCommonOutgoingNumber: { numberId: number; number: { phone: string } } }>(
    COMMON_OUTGOING_NUMBER_QUERY
  );

  const { getCommonOutgoingNumber: { numberId = undefined, number: { phone = '' } = {} } = {} } =
    commonOutgoingData || {};

  const columns: IColumn<OutgoingCallData>[] = [
    {
      id: 'type',
      label: 'TYPE',
      minWidth: 16,
      width: '3em',
      Renderer: OutgoingCallTypeCell,
      HeaderRenderer: () => <OutgoingCallTypeHeaderCell disabled={noNumbers} />,
    },
    {
      id: 'name',
      label: 'EMPLOYEES_AND_DEPARTMENTS',
      minWidth: 170,
      Renderer: OutgoingCallNameCell,
    },
    {
      id: 'phone',
      label: 'PERSONAL_OUTGOING_NUMBER',
      minWidth: 230,
      width: '30%',
      Renderer: OutgoingCallPhoneCell,
    },
    { id: 'actions', label: '', minWidth: 170, Renderer: OutgoingCallActionsCell, align: 'right' },
  ];

  const tableData = useMemo(() => {
    if (!allOutgoingCallsData) {
      return [];
    }

    const filteredData = sQuery
      ? allOutgoingCallsData.filter(({ department, employee, number }) => {
          const cleanedQuery = sQuery.replace(/[( )\s+-]/g, '');
          return (
            department?.name?.toUpperCase().includes(sQuery) ||
            employee?.user?.name?.toUpperCase().includes(sQuery) ||
            number?.phone?.includes(cleanedQuery)
          );
        })
      : allOutgoingCallsData;

    if (!filterQuery.length || filterQuery[0] === 'all') {
      return filteredData;
    }

    return filterQuery.reduce(
      (r, el) => r.concat(filteredData.filter((config) => !!config[el as keyof typeof config])),
      [] as typeof filteredData
    );
  }, [allOutgoingCallsData, filterQuery, sQuery]);

  function handleClickCommonControls() {
    searchParams.set('id', 'common');
    setSearchParams(searchParams);
  }

  const handleClickAddButton = () => {
    if (domainNumbersCalled) {
      if (typeof domainNumbers?.length === 'number' && domainNumbers.length > 1) {
        searchParams.set('id', 'add');
        setSearchParams(searchParams);
      } else {
        setOpen(true);
      }
    }
  };

  const renderEmptyContent = () => {
    if (called && !loading) {
      if (noNumbers) {
        return null;
      }
      if (!allOutgoingCallsData?.length) {
        return (
          <ContentHint isMiddleOfTable>
            <PhoneIcon className={classes.phoneNumberIcon} />
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('ADD_PERSONAL_OUTGOING_NUMBER_DESC')}
            </Typography>
          </ContentHint>
        );
      }
      if (!tableData.length) {
        return (
          <CellPreloader>
            <Typography color={'tertiary900'} type={'text3'}>
              {translate('NOTHING_FOUND')}
            </Typography>
          </CellPreloader>
        );
      }
    }
    return null;
  };

  const renderDrawerContent = () => {
    if (!outgoingCallId) {
      return null;
    }

    switch (outgoingCallId) {
      case 'common':
        return <OutgoingCallsCommonNumber numberId={numberId} />;
      case 'add':
        return <OutgoingCall />;
      default:
        return (
          <OutgoingCall
            item={tableData.find(({ id: outgoingId }) => outgoingId === Number(outgoingCallId))}
          />
        );
    }
  };

  const addButtonClasses = clsx(classes.tableTitleControls, {
    [classes.tablePanelControlsActive]: domainNumbersCalled && domainNumbers?.length,
  });

  const renderOutgoingCallsContent = () => {
    if (loading || commonNumberLoading) return <PagePreloader />;
    if (
      error ||
      (commonNumberError && !isCertainGraphqlErrorCodeInApolloError(commonNumberError, 'NOT_FOUND'))
    ) {
      return <PageError />;
    }

    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <Breadcrumbs>
            <QuickSearch parameterName={'query'} disabled={noNumbers} />
            <Button
              variant={'primary'}
              title={translate('ADD_PERSONAL_NUMBER')}
              className={classes.actionButton}
              onClick={handleClickAddButton}
              disabled={noNumbers}
            />
          </Breadcrumbs>
          <div className={classes.content}>
            {!noNumbers ? (
              <>
                <div className={classes.tablePanel}>
                  <div className={classes.tablePanelHeader} />
                  {!noNumbers && (
                    <div className={classes.tablePanelContent}>
                      <Typography
                        color={'tertiary900'}
                        type={'text2'}
                        medium
                        className={classes.tablePanelText}
                      >
                        {translate('COMPANY_PRIMARY_NUMBER')}
                      </Typography>
                      <Button
                        variant={'primary'}
                        className={addButtonClasses}
                        onClick={handleClickCommonControls}
                      >
                        <Typography color={'inherit'} type={'text3'}>
                          {phone ? formatPhone(phone) : ''}
                        </Typography>
                        {domainNumbersCalled && domainNumbers?.length > 1 && (
                          <EditIcon className={classes.tablePanelControlsIcon} />
                        )}
                      </Button>
                      <Typography
                        color={'tertiary600'}
                        type={'text4'}
                        className={classes.tablePanelHint}
                      >
                        {translate('COMPANY_PRIMARY_NUMBER_HINT')}
                      </Typography>
                    </div>
                  )}
                </div>
                <Flex fullWidth direction={'column'}>
                  <LazyTable<OutgoingCallData>
                    columns={columns}
                    data={tableData}
                    renderEmptyDataMessage={renderEmptyContent()}
                  />
                </Flex>
              </>
            ) : (
              <div className={classes.noNumbers}>
                <PhoneIcon className={classes.phoneNumberIcon} />
                <Typography type={'text3'} color={'tertiary600'}>
                  <Translate i18nKey={'NO_OUTGOING_NUMBERS'} />
                </Typography>
              </div>
            )}
            {renderDrawerContent()}
          </div>
          <Dialog
            aria-labelledby={'delete-dialog-title'}
            onClose={() => setOpen(false)}
            open={open}
            className={classes.dialog}
            classes={{ paper: classes.dialogPaper }}
          >
            <div className={classes.dialogTitle}>
              <Typography color={'tertiary900'} type={'text2'}>
                {translate('YOU_HAVE_ONLY_ONE_NUMBER')}
              </Typography>
            </div>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.textContainer}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('YOU_HAVE_ONLY_ONE_NUMBER_HINT_01')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('YOU_HAVE_ONLY_ONE_NUMBER_HINT_02')}
                </Typography>
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                title={translate('CLOSE')}
                className={classes.defaultElementWidthHalf}
                onClick={() => setOpen(false)}
              />
            </DialogActions>
          </Dialog>
        </Flex>
      </FormProvider>
    );
  };

  return <BodyContainer disableOverflow>{renderOutgoingCallsContent()}</BodyContainer>;
};

export default OutgoingCalls;
