import { PaymentServiceModelWithId } from '@/utils/paymentServiceModel';
import { ICellProps } from '@components/LazyTable';
import Typography from '@shared/components/Typography';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OperationType } from '../Balance.interfaces';
import { useBalanceStyle } from '../Balance.styles';

export const BalanceOperationCell = ({ item }: ICellProps<PaymentServiceModelWithId>) => {
  const [translate] = useTranslation();
  const classes = useBalanceStyle();
  if (item) {
    let cellValue = translate('OPERATION_UNKNOWN');

    if (item.name !== '' && item.name) {
      if (item?.type === OperationType.Credit) {
        cellValue = translate('BALANCE_CREDIT');
        if (item.burnt) {
          cellValue += ` ${translate('UP_TO').toLowerCase()} ${format(
            new Date(item.burnt),
            'dd.MM.yyyy'
          )}`;
        }
      } else if (item?.type === OperationType.Payment) {
        cellValue = translate(item.name);
      } else {
        cellValue = item.name;
      }
    }

    return (
      <div className={classes.operationCell}>
        <Typography type={'text3'} color={'tertiary900'}>
          {cellValue}
        </Typography>
      </div>
    );
  }

  return <div />;
};
