import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { TFunction } from 'i18next';
import * as Sentry from '@sentry/react';
import { OnError } from '@shared/utils/downloadFileWithProgress';

let timeoutGuard: ReturnType<typeof setTimeout> | null = null;
const timeoutGuardDelayMs = 5000;

export default function getBasicButtonDownloadHandleErrorCallback(
  translateFunc: TFunction<'translation', undefined>
) {
  const callback: OnError = (cause, error) => {
    globalNotification$.show('danger', translateFunc('COULD_NOT_DOWNLOAD_FILE'));

    if (timeoutGuard !== null) {
      return;
    }
    timeoutGuard = setTimeout(() => {
      timeoutGuard = null;
    }, timeoutGuardDelayMs);

    Sentry.captureException({ cause, error });
  };

  return callback;
}
