import React, { PropsWithChildren } from 'react';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import { computedAlign } from '../LazyTable.constants';
import { ILazyTableCellType } from './Cell.interfaces';
import { useLazyTableStyles } from '../LazyTable.styles';

export const Cell = <T,>({
  column,
  className,
  children,
  isHeader,
}: PropsWithChildren<ILazyTableCellType<T>>) => {
  const classes = useLazyTableStyles();
  const { className: columnClassName, minWidth, width, maxWidth, align, verticalAlign } = column;

  return (
    <Flex
      justifyContent={computedAlign(align as string)}
      alignItems={computedAlign(verticalAlign as string)}
      className={clsx(classes.tableCell, className || columnClassName)}
      style={{
        width: width || '100%',
        alignSelf: !isHeader ? computedAlign(verticalAlign as string) : 'auto',
        minWidth,
        maxWidth,
      }}
    >
      {children}
    </Flex>
  );
};

export default Cell;
