import { makeStyles } from '@material-ui/core';

export const useSwitchStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      marginRight: 0,
    },
    label: {
      fontSize: '0.875em',
      color: tertiary[900],
      lineHeight: '1em',
      userSelect: 'none',
      paddingTop: '0.25em',
      marginLeft: '0.75em',
    },
  }),
  {
    index: 1,
  }
);
