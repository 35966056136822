import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { IStatisticsByEmployees } from '../../StatisticsByCalls/StatisticsByCalls.interfaces';
import { UseEmployeesStatisticStyles } from '../StatisticsByEmployees.styles';

export const EmployeesStatisticEmployeeCell: FunctionComponent<
  ICellProps<IStatisticsByEmployees>
> = ({ item }) => {
  const { name = '' } = item || {};
  const classes = UseEmployeesStatisticStyles();

  return (
    <Typography className={classes.textOverflow} type={'text3'} color={'tertiary900'}>
      {name}
    </Typography>
  );
};
