import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import ControlButtons from '@shared/components/ControlButtons';
import Typography from '@shared/components/Typography/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SettingsPageTemplateProps } from './SettingsPage.interfaces';
import { useSettingsPagesStyles } from './SettingsPage.styles';
import clsx from 'clsx';

export const SettingsPageTemplate = ({
  title,
  children,
  form,
  onCancel,
  isLoading,
  containerClass,
}: SettingsPageTemplateProps) => {
  const [translate] = useTranslation();
  const classes = useSettingsPagesStyles();
  const navigate = useNavigate();

  function handleCancel() {
    if (onCancel) {
      onCancel();
    } else {
      navigate('/settings');
    }
  }

  return (
    <BodyContainer>
      <div className={classes.insideContainer}>
        {title && (
          <Typography type={'text2'} color={'tertiary900'}>
            {translate(title)}
          </Typography>
        )}
        <div className={clsx(containerClass || classes.container)}>{children}</div>
        <BottomButtons>
          <div className={classes.controls}>
            <ControlButtons
              confirmTitle={'SAVE_CHANGES'}
              cancelTitle={'CANCEL'}
              form={form}
              onCancelClick={handleCancel}
              rootConfirmStyles={classes.confirmButton}
              loading={isLoading}
            />
          </div>
        </BottomButtons>
      </div>
    </BodyContainer>
  );
};
