import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useSmartGreetingStyles = makeStyles(
  ({ color: { secondary } }) => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
    },
    greetingText: {
      fontStyle: 'italic',
    },
    smartGreetingWithoutIntegrationsBlock: {
      marginTop: '1em',
      borderRadius: '0.625em',
      padding: '1em',
      border: `1px solid ${secondary[200]}`,
      backgroundColor: secondary[50],
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
