import { useMemo } from 'react';
import {
  comparedEmployeeListType,
  comparedEmployeeObjectValue,
  IAMOPipelineDataList,
  IAMOPipelineStatusList,
  rawDataListType,
  scenarioDirectionEnum,
  scenarioSettingsType,
} from '@/features/Integrations/IntegrationForm/IntegrationForm.interfaces';
import {
  incomingMissedResponsibleOptions,
  incomingResponsibleOptions,
  incomingUnknownResponsibleOptions,
  outgoingResponsibleOptions,
} from '@/features/Integrations/IntegrationForm/IntegrationForm.constants';
import { tagsOptionList } from '@/features/Integrations/IntegrationForm/AmoCRM/AmoCRM.constants';
import { useTranslation } from 'react-i18next';

export function useAmoScenarioFormPropsHooks(
  direction: scenarioDirectionEnum,
  edit: scenarioSettingsType | undefined,
  comparedEmployee: comparedEmployeeListType | null,
  rawEmployee: rawDataListType | null
) {
  const [translate] = useTranslation();
  const isCommonSettings = useMemo(() => direction === scenarioDirectionEnum.Common, [direction]);

  const comparedEmployeeList = useMemo(() => {
    if (comparedEmployee) {
      return Object.values(comparedEmployee).reduce(
        (
          res: Array<{ key: string; value: string }>,
          emp: comparedEmployeeObjectValue | Array<string>
        ) => {
          if (Array.isArray(emp)) return res;
          return [...res, { key: emp.crmId, value: emp.name }];
        },
        []
      );
    }
    return [];
  }, [comparedEmployee]);

  const rawEmployeeList = useMemo(
    () => (rawEmployee ? rawEmployee.map((i) => ({ key: String(i.id), value: i.name })) : []),
    [rawEmployee]
  );

  const responsibleDefaultList = useMemo(() => {
    if (direction === scenarioDirectionEnum.Incoming) {
      if (edit === scenarioSettingsType.IncomingUnknown) {
        return incomingUnknownResponsibleOptions;
      }
      if (edit === scenarioSettingsType.IncomingMissedKnown) {
        return incomingMissedResponsibleOptions;
      }
      return incomingResponsibleOptions;
    }
    return outgoingResponsibleOptions;
  }, [direction, edit]);

  const responsibleWithEmployeeList = useMemo(() => {
    if (comparedEmployeeList.length === 0) return [];

    if (
      edit !== scenarioSettingsType.IncomingUnknown &&
      edit !== scenarioSettingsType.IncomingMissedUnknown
    ) {
      return [
        {
          key: 'responsible',
          value: translate('CLIENT_RESPONSIBLE'),
        },
        ...comparedEmployeeList,
      ];
    }

    return [...comparedEmployeeList];
  }, [comparedEmployeeList, edit, translate]);

  const tagsOption = useMemo(
    () => tagsOptionList.map((tagItem) => ({ ...tagItem, title: translate(tagItem.title) })),
    [translate]
  );

  const leadTitle = useMemo(() => {
    switch (edit) {
      case scenarioSettingsType.IncomingKnown:
      case scenarioSettingsType.IncomingMissedKnown: {
        return translate('CREATE_LEAD_OR_UNSORTED');
      }
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingMissedUnknown: {
        return translate('CREATE_LEAD_TASK_OR_UNSORTED');
      }
      case scenarioSettingsType.OutgoingUnknown:
      case scenarioSettingsType.OutgoingMissedUnknown: {
        return translate('CREATE_CONTACT_OR_DEAL');
      }
      case scenarioSettingsType.OutgoingKnown:
      case scenarioSettingsType.OutgoingMissedKnown: {
        return translate('CREATE_DEAL');
      }
      case scenarioSettingsType.CommonCallHistory: {
        return translate('SAVE_FROM_RESPONSIBLE_MANAGER');
      }
      default: {
        return '';
      }
    }
  }, [edit, translate]);

  const leadTitleDescription = useMemo(() => {
    const crmName = translate('AmoCRM');
    switch (edit) {
      case scenarioSettingsType.IncomingUnknown: {
        return translate('INCOMING_AMO_INCOMING_UNKNOWN_LEAD');
      }
      case scenarioSettingsType.IncomingMissedUnknown: {
        return translate('INCOMING_AMO_INCOMING_MISSED_UNKNOWN_LEAD');
      }
      case scenarioSettingsType.CommonCallHistory: {
        return translate('COMMON_CALLS_HISTORY', { crmName });
      }
      default: {
        return null;
      }
    }
  }, [edit, translate]);

  const taskResponsibleList = useMemo(() => {
    switch (edit) {
      case scenarioSettingsType.OutgoingKnown:
      case scenarioSettingsType.OutgoingMissedKnown:
      case scenarioSettingsType.IncomingKnown: {
        return responsibleDefaultList;
      }
      case scenarioSettingsType.IncomingMissedKnown: {
        return responsibleWithEmployeeList;
      }
      default: {
        return comparedEmployeeList;
      }
    }
  }, [comparedEmployeeList, edit, responsibleDefaultList, responsibleWithEmployeeList]);

  const taskTitleDescription = useMemo(() => {
    const whoReceivedCall = translate('RECEIVER_OF_THE_CALL').toLowerCase();
    const whoMissedCall = translate('WHO_MISSED_CALL_02').toLowerCase();
    switch (edit) {
      case scenarioSettingsType.IncomingUnknown:
      case scenarioSettingsType.IncomingKnown: {
        return translate('INCOMING_UNKNOWN_TASK_DESCRIPTION', { status: whoReceivedCall });
      }
      case scenarioSettingsType.IncomingMissedKnown:
      case scenarioSettingsType.IncomingMissedUnknown: {
        return translate('INCOMING_UNKNOWN_TASK_DESCRIPTION', { status: whoMissedCall });
      }
      default: {
        return null;
      }
    }
  }, [edit, translate]);

  const checkPipelineInList = (pipelineList: IAMOPipelineDataList[], pipeline: string) => {
    if (!pipeline) {
      return true;
    }
    const isInList = pipelineList.find((p: IAMOPipelineDataList) => p.id === Number(pipeline));
    return pipeline === '' || !isInList;
  };

  const checkPipelineStatusInList = (
    pipelineStatusList: IAMOPipelineStatusList[],
    pipelineStatus: string
  ) => {
    if (!pipelineStatus) {
      return true;
    }
    const isInList = pipelineStatusList.find(
      (p: IAMOPipelineStatusList) => p.id === Number(pipelineStatus)
    );
    return pipelineStatus === '' || !isInList;
  };

  return {
    leadTitle,
    leadTitleDescription,
    isCommonSettings,
    comparedEmployeeList,
    rawEmployeeList,
    responsibleDefaultList,
    responsibleWithEmployeeList,
    taskResponsibleList,
    taskTitleDescription,
    tagsOption,
    checkPipelineInList,
    checkPipelineStatusInList,
  };
}
