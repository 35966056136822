import React, { FunctionComponent, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { EMPLOYEES_QUERY, GET_SELECTED_TARIFF_QUERY } from '@/client/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import QuickSearch from '@components/QuickSearch';
import { useTranslation } from 'react-i18next';
import Button from '@shared/components/Button';
import { deserializeEmployee, IEmployee } from '@components/typings/interfaces';
import Breadcrumbs from '@/components/Breadcrumbs';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import Typography from '@shared/components/Typography';
import MessageDialog from '@shared/components/MessageDialog';
import Flex from '@shared/components/Flex';
import LazyTable, { IColumn } from '@components/LazyTable';
import BodyContainer from '@/layouts/BodyContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { useEmployeesStyles } from './Employees.styles';
import {
  EmployeeActionsCell,
  EmployeeStatusCell,
  EmployeeExtCell,
  EmployeeNameCell,
  EmployeePhoneCell,
  EmployeeCallForwardingCell,
  EmployeePositionCell,
} from './modules';

export const columns: IColumn<IEmployee>[] = [
  {
    id: 'active',
    label: '',
    width: '1.5em',
    align: 'left',
    verticalAlign: 'center',
    Renderer: EmployeeStatusCell,
    HeaderRenderer: EmployeeStatusCell,
  },
  {
    id: 'name',
    verticalAlign: 'center',
    label: 'FULL_NAME',
    width: '30%',
    Renderer: EmployeeNameCell,
  },
  {
    id: 'ext',
    verticalAlign: 'center',
    label: 'EXTENSION_NUM',
    Renderer: EmployeeExtCell,
    width: '12%',
  },
  {
    id: 'position',
    width: '20%',
    verticalAlign: 'center',
    label: 'POSITION',
    Renderer: EmployeePositionCell,
  },
  {
    id: 'phone',
    verticalAlign: 'center',
    label: 'NUMBER',
    width: '18%',
    Renderer: EmployeePhoneCell,
  },
  {
    id: 'callForwardingTimeout',
    label: 'FORWARDING',
    verticalAlign: 'center',
    width: '17%',
    Renderer: EmployeeCallForwardingCell,
  },
  {
    id: 'actions',
    verticalAlign: 'center',
    label: '',
    Renderer: EmployeeActionsCell,
    align: 'right',
    width: '9em',
  },
];

interface IFormFields {
  query: string;
}

export const Employees: FunctionComponent = () => {
  const classes = useEmployeesStyles();
  const [translate] = useTranslation();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const query = searchParams.get('query')?.toUpperCase();
  const formMethods = useForm<IFormFields>({
    defaultValues: {
      query,
    },
  });
  const navigate = useNavigate();

  const { loading, data: employeesData, error } = useQuery(EMPLOYEES_QUERY);
  const { employees } = employeesData || { employees: [] };
  const { data: tariffData } = useQuery(GET_SELECTED_TARIFF_QUERY);
  const { currentEmployeesCount = 0, employeesNumber: tariffEmployeesCount = -1 } =
    tariffData?.getDomain || {};

  const tableData = useMemo(
    () =>
      employees
        .filter(
          ({ ext = '', position = '', user: { email = '', name = '', phone = '' } = {} }) =>
            !query ||
            (query &&
              (ext?.includes(query) ||
                position?.toUpperCase().includes(query) ||
                name?.toUpperCase().includes(query) ||
                email?.toUpperCase().includes(query) ||
                `${phone}`.includes(query.replace(/[\s+( )-]+/g, ''))))
        )
        .map(deserializeEmployee),
    [employees, query]
  );

  const handleDialogClose = () => {
    setOpen(false);
  };

  const renderDialogContent = () => (
    <>
      <div className={classes.addEmployeeCenterBlock}>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('MAX_EMPLOYEES_NUMBER_EXCEEDED_DIALOG')}
        </Typography>
      </div>
      <div className={classes.addEmployeeControls}>
        <Button title={translate('CLOSE')} onClick={handleDialogClose} smallHeight />
      </div>
    </>
  );

  const handleAddEmployee = () => {
    if (currentEmployeesCount >= tariffEmployeesCount && tariffEmployeesCount > 0) {
      setOpen(true);
    } else {
      navigate('/employee/employees/add');
    }
  };

  const renderEmployeesContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;

    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <Breadcrumbs>
            <Typography color={'tertiary600'} type={'text3'}>
              {tariffEmployeesCount < 0
                ? translate('EMPLOYEES_CREATED_UNLIM', { count: currentEmployeesCount })
                : translate('EMPLOYEES_CREATED', {
                    count: currentEmployeesCount,
                    maxCount: tariffEmployeesCount,
                  })}
            </Typography>
            <div className={classes.action}>
              <QuickSearch parameterName={'query'} />
            </div>
            <Button
              title={translate('ADD_EMPLOYEE')}
              className={classes.action}
              onClick={handleAddEmployee}
            />
          </Breadcrumbs>
          <LazyTable<IEmployee>
            columns={columns}
            data={tableData}
            emptyDataMessage={translate('NOTHING_FOUND')}
          />
          {open && (
            <MessageDialog
              isOpen={open}
              title={translate('UNABLE_TO_ADD')}
              onCancel={handleDialogClose}
              renderContent={renderDialogContent()}
            />
          )}
        </Flex>
      </FormProvider>
    );
  };

  return <BodyContainer disableOverflow>{renderEmployeesContent()}</BodyContainer>;
};

export default Employees;
