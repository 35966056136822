import { makeStyles, Theme } from '@material-ui/core/styles';

export const useNotificationMessageStyle = makeStyles(({ color: { danger } }: Theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    left: '100%',
    top: '50%',
    padding: '0.75em 0.75em 0.75em 1.5em',
    backgroundColor: danger[50],
    borderColor: danger[100],
    borderRadius: '5px',
    transform: 'translateY(-50%)',
    marginLeft: '1em',
    '&:after': {
      display: 'block',
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      borderBottom: 'solid transparent 8px',
      borderTop: 'solid transparent 8px',
      borderRight: `solid ${danger[50]} 8px`,
      right: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  message: {
    marginRight: '0.75em',
    paddingBottom: '0.1em',
  },
  close: {
    color: danger[600],
    width: '1.5em',
    height: '1.5em',
    cursor: 'pointer',
  },
}));
