import { makeStyles, Theme } from '@material-ui/core/styles';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';

export const useIncomingNumberSettingsTreeStyles = makeStyles<
  Theme & IThemeOptions,
  { treeScale: number }
>(({ color: { tertiary, warning, primary, secondary, base, success, danger, links } }) => ({
  node: {
    borderRadius: '0.3125em',
    display: 'inline-flex',
    border: `1px solid ${tertiary[300]}`,
    backgroundColor: base,
    width: '14em',
    minWidth: '6.25em',
    flexFlow: 'column',
    '&:hover': {
      backgroundColor: tertiary[100],
    },
  },
  nodeContent: {
    flexFlow: 'column',
    '& > :not(:first-child)': {
      borderTop: `1px solid ${tertiary[300]}`,
    },
  },
  newNode: {
    borderRadius: '0.3125em',
    display: 'inline-flex',
    border: `1px solid ${tertiary[300]}`,
    background: base,
    minWidth: '2em',
    height: '2em',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: primary[100],
    },
  },
  nodeTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1em',
  },
  nodeTitleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  nodeTitleClientChoice: {
    padding: '0.75em 0.875em',
  },
  rootNodeTitleText: {
    maxWidth: '10em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '10em',
    textAlign: 'left',
  },
  nodeIcon: {
    width: '1em',
    minWidth: '1em',
    margin: '0 0.75em 0 0',
    color: primary[700],
  },
  nodeIconPrimary: {
    color: primary[700],
  },
  clientChoiceBlock: {
    width: '1em',
    paddingTop: '3px',
    marginRight: '0.875em',
    minWidth: '1em',
    height: '1.5em',
    color: base,
    background: primary[700],
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicator: {
    height: '1em',
    maxHeight: '1em',
    minHeight: '1em',
    width: '1em',
    maxWidth: '1em',
    minWidth: '1em',
    margin: '3px 0.5em 0 0',
    borderRadius: '0.3125em',
  },
  default: {
    background: base,
    border: `1px solid ${tertiary[300]}`,
  },
  success: {
    background: success[500],
  },
  warning: {
    background: warning[600],
  },
  error: {
    background: danger[500],
  },
  primary: {
    background: primary[500],
  },
  links: {
    background: links[500],
  },
  secondary: {
    background: secondary[600],
  },
  active: {
    borderColor: secondary[300],
    background: secondary[200],
  },
  caption: {
    textAlign: 'left',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
    width: '14em',
  },
  message: {
    textAlign: 'initial',
    lineHeight: '1.25em',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden',
    maxWidth: '14em',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
  },
  messageWrapper: {
    textAlign: 'initial',
    boxOrient: 'vertical',
    '& span:first-child': {
      marginBottom: '0.5em',
    },
  },
  text: {
    lineHeight: 1,
  },
  treeWrapper: {
    marginTop: '1em',
    backgroundColor: tertiary[50],
    border: `1px solid ${tertiary[200]}`,
    borderRadius: '5px',
    position: 'relative',
    bottom: '5em',
  },
  scrollLeft: {
    boxShadow: 'inset 1em 0 1em -1em rgba(0,0,0,0.3)',
    width: '2em',
    position: 'absolute',
    height: '100%',
    left: '0',
    top: '0',
    zIndex: 5,
  },
  scrollRight: {
    boxShadow: 'inset -1em 0 1em -1em rgba(0,0,0,0.3)',
    borderRadius: '0 0.3125em 0.3125em 0',
    width: '2em',
    position: 'absolute',
    height: '100%',
    right: '0',
    top: '0',
    zIndex: 5,
  },
  invisible: {
    visibility: 'hidden',
  },
  scrollIcon: {
    width: '2em',
    height: '3em',
    position: 'absolute',
    top: '0',
    fill: `${tertiary[300]}`,
  },
  iconRight: {
    right: '0',
  },
  iconLeft: {
    left: '0',
  },
  treeScaleControlButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '7em',
    position: 'absolute',
    zIndex: 6,
    top: '12em',
    right: '1em',
  },
  treeScalePlus: {
    display: 'flex',
    width: '2em',
    height: '2em',
    padding: 0,
    borderRadius: '50% 50% 0 0',
    backgroundColor: 'white',
    border: `1px solid ${tertiary[500]}`,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    filter: `drop-shadow(0 0 0.25em ${tertiary[300]})`,
    '&:hover': {
      border: `1px solid ${primary[600]}`,
      backgroundColor: primary[50],
      cursor: 'pointer',
      color: 'black',
    },
  },
  treeScalePlusDisabled: {
    color: tertiary[300],
    '&:hover': {
      border: `1px solid ${tertiary[500]}`,
      backgroundColor: 'white',
      cursor: 'default',
      color: tertiary[300],
    },
  },
  treeScaleMinus: {
    display: 'flex',
    width: '2em',
    height: '2em',
    padding: 0,
    borderRadius: '0 0 50% 50%',
    backgroundColor: 'white',
    border: `1px solid ${tertiary[500]}`,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    filter: `drop-shadow(0 0 0.25em ${tertiary[300]})`,
    '&:hover': {
      border: `1px solid ${primary[600]}`,
      backgroundColor: primary[50],
      cursor: 'pointer',
      color: 'black',
    },
  },
  treeScaleDefault: {
    display: 'flex',
    width: '2em',
    height: '2em',
    padding: 0,
    borderRadius: '50%',
    backgroundColor: 'white',
    border: `1px solid ${tertiary[500]}`,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    filter: `drop-shadow(0 0 0.25em ${tertiary[300]})`,
    '&:hover': {
      border: `1px solid ${primary[600]}`,
      backgroundColor: primary[50],
      cursor: 'pointer',
      color: 'black',
    },
  },
  greetingHeader: {
    width: '13em',
    textAlign: 'justify',
  },
  ...styleConstants,
  treeSection: ({ treeScale }) => ({
    padding: '1em',
    overflowX: 'auto',
    flexFlow: 'row',
    width: '100%',
    fontSize: `${treeScale}px`,
    '& ul:first-child': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
}));
