import { TextFieldProps } from '@material-ui/core';
import FormFieldBase from '@shared/components/FormFieldBase';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import { IMaskedFieldProps } from './MaskedField.interfaces';
import { useMaskedFieldStyles } from './MaskedField.styles';

type TypedFixedInputMaskProps = Omit<InputMaskProps, 'children'> & {
  children: (fieldProps: TextFieldProps) => React.ReactNode;
};

const TypeFixedInputMask = InputMask as unknown as (props: TypedFixedInputMaskProps) => JSX.Element;

export const MaskedField = ({
  defaultValue = '',
  name = 'maskedField',
  label = '',
  mask,
  maskChar = null,
  alwaysShowMask,
  formatChars,
  onChanges = undefined,
  validate,
  pattern,
  required,
  onBlur,
  ...props
}: IMaskedFieldProps) => {
  const classes = useMaskedFieldStyles();
  const { watch, control, getFieldState } = useFormContext();
  const defaultFormValue = watch(name);
  const defaultControlValue = defaultFormValue || defaultValue || '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultControlValue}
      rules={{ validate, pattern, required }}
      render={({ field: { onChange, value, ref, ...rest } }) => (
        <TypeFixedInputMask
          {...rest}
          mask={mask}
          maskChar={maskChar}
          value={value || defaultControlValue}
          alwaysShowMask={alwaysShowMask}
          formatChars={formatChars}
          onChange={(e) => {
            if (typeof onChange === 'function') {
              onChange(e);
            }
            if (typeof onChanges === 'function') {
              onChanges(e);
            }
          }}
          onBlur={() => onBlur?.(value)}
        >
          {(fieldProps) => (
            <FormFieldBase
              {...props}
              {...fieldProps}
              label={label}
              error={Boolean(getFieldState(name)?.error)}
              inputRef={ref}
              required={required}
              classes={{ root: classes.root }}
            />
          )}
        </TypeFixedInputMask>
      )}
    />
  );
};
