import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { useSelectionAreaStyles } from './SelectionArea.styles';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';

export interface ISelectionAreaProps {
  left?: number;
  top?: number;
  height?: number;
  width?: number;
  x?: number;
  y?: number;
  activePeriod?: SchedulePeriodsTypes;
}

export const SelectionArea = ({ left, top, height, width, activePeriod }: ISelectionAreaProps) => {
  const classes = useSelectionAreaStyles();

  if (left !== undefined && top !== undefined) {
    const computedStyle: CSSProperties = {
      position: 'fixed',
      zIndex: 4,
      bottom: 'auto',
      right: 'auto',
      top,
      left,
      height,
      width,
      opacity: 0.6,
    };

    const computedClass = clsx({
      [`${classes.areaTertiary}`]: activePeriod === SchedulePeriodsTypes.NonWorkingHours,
      [`${classes.areaSuccess}`]: activePeriod === SchedulePeriodsTypes.WorkingHours,
      [`${classes.areaWarning}`]: activePeriod === SchedulePeriodsTypes.Custom1,
      [`${classes.areaLinks}`]: activePeriod === SchedulePeriodsTypes.Custom2,
      [`${classes.areaSecondary}`]: activePeriod === SchedulePeriodsTypes.Custom3,
    });

    return <div style={computedStyle} className={computedClass} />;
  }

  return null;
};

export default SelectionArea;
