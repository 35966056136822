import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';

export const useSnackbarStyles = makeStyles(() => ({
  alert: {
    width: pxToEm(320),
    maxHeight: '50vh',
    overflowY: 'auto',
  },
}));
