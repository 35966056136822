import React, { useCallback, useEffect, useState } from 'react';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { XIcon, TrashIcon } from '@shared/assets/images/icons';
import TimeField from '@components/TimeField/TimeField';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import { IPeriod, ISchedulePeriod } from '../Scheduler/Scheduler.interfaces';
import { formatHHmmToMinutes, formatMinutesToHHmm } from '../Scheduler/Scheduler.helpers';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';
import { useSchedulerDayPeriodStyles } from './SchedulerDayPeriod.styles';

export const SchedulerDayPeriodControls = (props: {
  id: string;
  onRemove: (period: IPeriod) => void;
  initialPeriod: IPeriod & { type: SchedulePeriodsTypes };
  availablePeriods: ISchedulePeriod[];
  onChange: (period: IPeriod, type: SchedulePeriodsTypes) => void;
  activePeriod: boolean;
  setActivePeriod: () => void;
  setInactive: () => void;
  onChanges?: () => void;
}) => {
  const {
    onChange,
    onRemove,
    initialPeriod,
    availablePeriods,
    activePeriod,
    setActivePeriod,
    setInactive,
    id,
    onChanges,
  } = props;

  const classes = useSchedulerDayPeriodStyles();
  const [translate] = useTranslation();
  const [active, setActive] = useState(false);
  const [formError, setFormError] = useState('');

  const formMethods = useForm<{ start: string; end: string; type: SchedulePeriodsTypes }>();
  const { reset, handleSubmit, setError, clearErrors } = formMethods;

  const handleChanges = () => {
    if (typeof onChanges === 'function') {
      onChanges();
    }
  };

  const validatePeriod = useCallback(
    ({ start, end, type }: { start: string; end: string; type: SchedulePeriodsTypes }) => {
      let isValid = true;
      if (!end || !start || !type) {
        if (!type) {
          setError('type', { message: translate('CHOOSE_PERIOD'), type: 'required' });
        }
        if (!start) {
          setError('start', { type: 'required' });
        }
        if (!end) {
          setError('end', { type: 'required' });
        }
        isValid = false;
      }
      const startMinutes = formatHHmmToMinutes(start) || 0;
      const endMinutes = formatHHmmToMinutes(end) || 0;
      if (endMinutes <= startMinutes) {
        setFormError(translate('START_TIME_LATER_THAN_END_RANGE_ERROR'));
        isValid = false;
      } else if (endMinutes - startMinutes < 15) {
        setFormError(translate('TOO_SHORT_RANGE_ERROR'));
        isValid = false;
      }
      return isValid;
    },
    [setError, translate]
  );

  const handleResetForm = useCallback(() => {
    let formData: { start: string; end: string; type?: SchedulePeriodsTypes } = {
      start: '00:00',
      end: '00:00',
      type: undefined,
    };
    if (initialPeriod) {
      const end =
        formatMinutesToHHmm(
          initialPeriod?.end % 5 === 4 ? initialPeriod?.end + 1 : initialPeriod?.end
        ) || '00';
      const start = formatMinutesToHHmm(initialPeriod?.start) || '00';
      formData = {
        ...initialPeriod,
        start,
        end,
      };
    }
    reset(formData);

    setActive(Number.isNaN(initialPeriod?.start));
    // eslint-disable-next-line no-unused-expressions
    Number.isNaN(initialPeriod?.start) ? setActivePeriod() : undefined;
  }, [initialPeriod, reset, setActivePeriod]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  const handleSubmitForm = useCallback(
    ({ start, end, type }: { start: string; end: string; type: SchedulePeriodsTypes }) => {
      clearErrors(['start', 'end', 'type']);
      setFormError('');
      const isDataValid = validatePeriod({ start, end, type });
      if (isDataValid) {
        const nextStart = formatHHmmToMinutes(start) || 0;
        const nextMinutes = formatHHmmToMinutes(end) || 0;
        const nextEnd = nextMinutes % 5 === 0 && nextMinutes ? nextMinutes - 1 : nextMinutes;

        onChange(
          {
            start: nextStart,
            end: nextEnd,
          },
          type
        );

        if (initialPeriod.start < nextStart) {
          onChange(
            {
              start: initialPeriod.start,
              end: nextStart - 1 || 0,
            },
            SchedulePeriodsTypes.NonWorkingHours
          );
        }
        setInactive();
      }
    },
    [clearErrors, initialPeriod.start, onChange, setInactive, validatePeriod]
  );

  const renderSubmissionControls = () => {
    if (initialPeriod?.start ? activePeriod : active) {
      return (
        <Button
          title={translate('APPLY')}
          form={id}
          type={'submit'}
          variant={'secondary'}
          fullWidth
          className={classes.formSubmissionControls}
        />
      );
    }
    return null;
  };

  const renderCancellationControls = () => {
    const activated = initialPeriod?.start ? activePeriod : active;
    const clickHandler =
      activated && !Number.isNaN(initialPeriod?.start) && !Number.isNaN(initialPeriod?.end)
        ? () => {
            handleResetForm();
            clearErrors(['start', 'end', 'type']);
            setFormError('');
            setInactive();
            setActive(false);
          }
        : () => onRemove(initialPeriod);
    const Icon = activated ? XIcon : TrashIcon;

    return (
      <Button
        variant={'secondary'}
        color={'error'}
        className={classes.cancelButton}
        onClick={clickHandler}
      >
        <Icon />
      </Button>
    );
  };

  const renderCustomOption = ({
    data: { value, title = '' },
  }: {
    data: { value: SchedulePeriodsTypes | ''; title: string };
  }) => (
    <div className={classes.customOption}>
      <div
        className={clsx(classes.indicator, {
          [classes.default]: value === SchedulePeriodsTypes.NonWorkingHours,
          [classes.success]: value === SchedulePeriodsTypes.WorkingHours,
          [classes.warning]: value === SchedulePeriodsTypes.Custom1,
          [classes.links]: value === SchedulePeriodsTypes.Custom2,
          [classes.secondary]: value === SchedulePeriodsTypes.Custom3,
        })}
      />
      <span>{title}</span>
    </div>
  );

  return (
    <FormProvider {...formMethods}>
      <form
        id={id}
        action={''}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit(handleSubmitForm)(event);
        }}
        className={clsx(classes.form, {
          [classes.formActive]: initialPeriod?.start ? activePeriod : active,
        })}
      >
        <div className={classes.formControls}>
          <div className={classes.periodField}>
            <div className={classes.timeFieldset}>
              <TimeField
                autoFocus={!initialPeriod?.start}
                onFocus={() => {
                  setActive(true);
                  setActivePeriod();
                }}
                onChange={handleChanges}
                name={'start'}
              />
              <div className={classes.separator} />
              <TimeField
                onFocus={() => {
                  setActive(true);
                  setActivePeriod();
                }}
                onChange={handleChanges}
                name={'end'}
              />
            </div>
            <Typography color={'danger600'} type={'text5'} className={classes.validationError}>
              {formError || ''}
            </Typography>
          </div>
          <SelectField
            onFocus={() => {
              setActive(true);
              setActivePeriod();
            }}
            name={'type'}
            valueKey={'value'}
            titleKey={'title'}
            onChange={handleChanges}
            data={[
              ...(Number.isNaN(initialPeriod?.start) || Number.isNaN(initialPeriod?.end)
                ? [{ value: '' as SchedulePeriodsTypes, title: translate('NOTHING_CHOSEN') }]
                : []),
              ...availablePeriods
                .filter(
                  ({ key }) =>
                    key !== SchedulePeriodsTypes.ATC && key !== SchedulePeriodsTypes.NonWorkingHours
                )
                .map(({ key: value, titleCode, name }) => ({
                  value,
                  title: name || translate(titleCode || '') || '',
                })),
            ]}
            Renderer={renderCustomOption}
          />
          {renderCancellationControls()}
        </div>
        {renderSubmissionControls()}
      </form>
    </FormProvider>
  );
};

export default SchedulerDayPeriodControls;
