import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { usePrevious } from '@/common/hooks/usePrev.hooks';
import Typography from '@shared/components/Typography';
import { IColorVariants } from '@components/theme';
import SelectionArea, { ISelectionAreaProps } from './SelectionArea';
import {
  IDay,
  IHour,
  IPeriod,
  ISchedulePeriod,
  ISelectionSegmentsRange,
} from './Scheduler.interfaces';
import { getDays, hours, hourSegments } from './Scheduler.constants';
import { SchedulePeriodsTypes } from '../IncomingNumber.interfaces';
import { getDayFiller, getPeriodsBySegmentsRange } from './Scheduler.helpers';
import { useSchedulerStyles } from './Scheduler.styles';
import SchedulerDay from '../DayScheduler/SchedulerDay';

export const Scheduler = ({
  activePeriod,
  availablePeriods,
  schedule,
  setSchedule,
  onScheduleChanged,
}: {
  activePeriod: SchedulePeriodsTypes;
  availablePeriods: ISchedulePeriod[];
  schedule: IDay[];
  setSchedule: (callback: (prevState: IDay[]) => IDay[]) => void;
  onScheduleChanged: () => void;
}) => {
  const [translate] = useTranslation();
  const classes = useSchedulerStyles();
  const {
    color: { tertiary, links, success, primary, warning },
  } = useTheme();
  const colorMap: { [key: string]: IColorVariants } = {
    [SchedulePeriodsTypes.NonWorkingHours]: tertiary,
    [SchedulePeriodsTypes.WorkingHours]: success,
    [SchedulePeriodsTypes.Custom1]: warning,
    [SchedulePeriodsTypes.Custom2]: links,
    [SchedulePeriodsTypes.Custom3]: primary,
  };

  const [selectionPeriods, setSelectionPeriods] = useState<ISelectionSegmentsRange | undefined>(
    undefined
  );
  const [selectionParameters, setSelectionParameters] = useState<ISelectionAreaProps | undefined>(
    undefined
  );
  const [selectionActive, setSelectionActive] = useState<boolean>(false);
  const prevSelectionActive = usePrevious(selectionActive);
  const scheduleRef = useRef<HTMLDivElement | null>(null);
  const [selectedDay, selectDay] = useState<number | undefined>(undefined);

  const handleChanges = useCallback(() => {
    if (selectionPeriods) {
      const { startDay, endDay, startHour, endHour, startSegment, endSegment } = selectionPeriods;
      const isSingleActiveSegmentSelected =
        startDay === endDay &&
        startHour === endHour &&
        startSegment === endSegment &&
        schedule[startDay].hours[startHour].minutes
          .slice(startSegment * 15, startSegment * 15 + 14)
          .some((type) => type === activePeriod);
      const selectionType = isSingleActiveSegmentSelected
        ? SchedulePeriodsTypes.NonWorkingHours
        : activePeriod;
      setSchedule((prevState) =>
        prevState.map(getDayFiller(getPeriodsBySegmentsRange(selectionPeriods), selectionType))
      );
      if (onScheduleChanged) onScheduleChanged();
    }
  }, [selectionPeriods, schedule, activePeriod, setSchedule, onScheduleChanged]);

  const handleChangeDay = (day: IDay, dayIndex: number) => {
    setSchedule((prevState) => {
      const newState = [...prevState];
      newState[dayIndex] = day;
      return newState;
    });
    if (onScheduleChanged) onScheduleChanged();
  };

  const handleSelectionPeriods = (eventTarget: EventTarget | null) => {
    if (eventTarget instanceof HTMLDivElement) {
      const [elementId, dayId, hourId, segmentId] =
        /^row-(\d+)-cell-(\d+)-segment-(\d+)$/.exec(eventTarget?.id) ?? [];
      if (elementId) {
        setSelectionPeriods((prevState) => {
          const endDay = Number(dayId);
          const endHour = Number(hourId);
          const endSegment = Number(segmentId) % hourSegments.length;
          const {
            startDay = endDay,
            startHour = endHour,
            startSegment = endSegment,
          } = prevState || {};
          return { startDay, startHour, startSegment, endDay, endHour, endSegment };
        });
      }
    }
  };

  const handleMouseDown = useCallback(
    ({ pageX, pageY, target }: MouseEvent<HTMLDivElement>) => {
      setSelectionParameters({ x: pageX, y: pageY, left: pageX, top: pageY, width: 0, height: 0 });
      setSelectionActive(true);
      handleSelectionPeriods(target);
    },
    [setSelectionParameters]
  );
  // TODO: rework due to dependencies updates does not provided as expected
  const handleMouseMove = useCallback(
    ({ target, pageX, pageY }: HTMLElementEventMap['mousemove']) => {
      setSelectionParameters((prevState) => {
        if (prevState) {
          const { left, top, x, y } = prevState;
          return {
            ...prevState,
            left: x && x > pageX ? pageX : left,
            top: y && y > pageY ? pageY : top,
            width: Math.abs(x ? x - pageX : 0),
            height: Math.abs(y ? y - pageY : 0),
          };
        }
        return undefined;
      });
      handleSelectionPeriods(target);
    },
    [setSelectionParameters]
  );

  const handleMouseUp = useCallback(() => {
    setSelectionActive(false);
    scheduleRef?.current?.removeEventListener('mousemove', handleMouseMove);
    scheduleRef?.current?.removeEventListener('mouseleave', handleMouseUp);
    scheduleRef?.current?.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('scroll', handleMouseUp, true);
  }, [handleMouseMove]);

  useEffect(() => {
    if (!prevSelectionActive && selectionActive) {
      scheduleRef?.current?.addEventListener('mousemove', handleMouseMove);
      scheduleRef?.current?.addEventListener('mouseleave', handleMouseUp);
      scheduleRef?.current?.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('scroll', handleMouseUp, true);
    } else if (prevSelectionActive && !selectionActive) {
      handleChanges();
      setSelectionPeriods(undefined);
      setSelectionParameters(undefined);
    }
  }, [
    prevSelectionActive,
    selectionActive,
    scheduleRef,
    handleMouseMove,
    handleMouseUp,
    handleChanges,
  ]);

  const renderMarkers = (data: IHour | undefined, hourIndex: number) => {
    if (hourIndex % 2 || hourIndex === 0 || hourIndex === 23) {
      const hoursValue = !hourIndex ? hourIndex : hourIndex + 1;
      return (
        <div key={`scale-hour-${hoursValue}`} className={classes.scaleCell}>
          <Typography type={'text4'} color={'tertiary900'}>{`${hoursValue}:00`}</Typography>
        </div>
      );
    }
    return null;
  };
  // TODO: store types string into enumerable
  const renderSegment =
    (hourMinutes: string[], dayIndex: number, hourIndex: number) =>
    ({ start, end }: IPeriod, segmentIndex: number) => {
      const id = `row-${dayIndex}-cell-${hourIndex}-segment-${segmentIndex}`;
      const minutes = hourMinutes.slice(start, end);
      const firstType = minutes[0];
      const lastType = minutes[minutes.length - 1];
      const active = firstType === activePeriod || lastType === activePeriod;
      const firstInHour = !segmentIndex;
      const lastInHour = segmentIndex === hourSegments.length - 1;
      const multicolor = firstType !== lastType;
      const firstInPeriod =
        (firstInHour
          ? firstType !== schedule[dayIndex]?.hours[hourIndex - 1]?.minutes[59]
          : firstType !== hourMinutes[start - 1]) ||
        (multicolor &&
          (lastType === activePeriod || firstType === SchedulePeriodsTypes.NonWorkingHours));
      const lastInPeriod =
        (lastInHour
          ? lastType !== schedule[dayIndex]?.hours[hourIndex + 1]?.minutes[0]
          : lastType !== hourMinutes[end + 1]) ||
        (multicolor &&
          (firstType === activePeriod || lastType === SchedulePeriodsTypes.NonWorkingHours));
      const isSegmentTypeof = (type: string) =>
        (!multicolor && firstType === type) ||
        (multicolor && activePeriod === type && (firstType === type || lastType === type)) ||
        (multicolor &&
          type !== SchedulePeriodsTypes.NonWorkingHours &&
          activePeriod !== type &&
          (firstType === type || lastType === type) &&
          firstType !== activePeriod &&
          lastType !== activePeriod);
      const classNames = clsx(classes.segment, {
        [classes.segmentActive]: active,
        [classes.segmentContrast]: active,
        [classes.segmentFirst]: firstInPeriod,
        [classes.segmentLast]: lastInPeriod,
        [classes.segmentLinks]: isSegmentTypeof(SchedulePeriodsTypes.Custom2),
        [classes.segmentTertiary]: isSegmentTypeof(SchedulePeriodsTypes.NonWorkingHours),
        [classes.segmentSuccess]: isSegmentTypeof(SchedulePeriodsTypes.WorkingHours),
        [classes.segmentWarning]: isSegmentTypeof(SchedulePeriodsTypes.Custom1),
        [classes.segmentSecondary]: isSegmentTypeof(SchedulePeriodsTypes.Custom3),
      });
      let style;
      if (multicolor) {
        const firstColor =
          firstType !== SchedulePeriodsTypes.NonWorkingHours
            ? colorMap?.[firstType]?.[firstType === activePeriod ? 200 : 100]
            : '#fff';
        const secondColor =
          lastType !== SchedulePeriodsTypes.NonWorkingHours
            ? colorMap?.[lastType]?.[lastType === activePeriod ? 200 : 100]
            : '#fff';
        style = {
          background: `linear-gradient(90deg, ${firstColor} 0%, ${secondColor} 100%)`,
          zIndex: 2,
        };
      }
      return <div id={id} key={id} className={classNames} style={style} />;
    };

  const renderCell = (rowIndex: number) => (cellData: IHour, cellIndex: number) => {
    const classNames = clsx(classes.cell, {
      [classes.cellHoverPrimary]: activePeriod === SchedulePeriodsTypes.Custom2,
      [classes.cellHoverSuccess]: activePeriod === SchedulePeriodsTypes.WorkingHours,
      [classes.cellHoverLinks]: activePeriod === SchedulePeriodsTypes.Custom1,
      [classes.cellHoverSecondary]: activePeriod === SchedulePeriodsTypes.Custom3,
    });
    const { minutes } = cellData;
    return (
      <div key={`row-${rowIndex}-cell-${cellIndex}`} className={classNames}>
        {hourSegments.map(renderSegment(minutes, rowIndex, cellIndex))}
      </div>
    );
  };

  const renderRow = (row: IDay, rowIndex: number) => (
    <div key={`row-${rowIndex}`} className={classes.row}>
      {row.hours.map(renderCell(rowIndex))}
    </div>
  );

  const renderSchedulerSidebar = () => {
    if (selectedDay !== undefined) {
      return (
        <SchedulerDay
          day={schedule[selectedDay as number]}
          index={selectedDay as number}
          availablePeriods={availablePeriods}
          onChange={(day: IDay) => handleChangeDay(day, selectedDay as number)}
          onCancel={() => selectDay(undefined)}
        />
      );
    }
    return null;
  };

  const renderDayControl = ({ id, titleCode, abbreviationCode, weekend }: IDay) => (
    <div
      key={id}
      className={classes.dayControl}
      title={translate(titleCode)}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={() => {
        selectDay(id);
        handleMouseUp();
      }}
    >
      <Typography type={'text3'} color={weekend ? 'danger600' : 'tertiary900'}>
        {translate(abbreviationCode)}
      </Typography>
    </div>
  );

  return (
    <>
      <div ref={scheduleRef} className={classes.root} onMouseDown={handleMouseDown}>
        <SelectionArea {...(selectionParameters || {})} activePeriod={activePeriod} />
        <div className={classes.daysControls}>
          <div className={classes.emptyCell} />
          {getDays().map(renderDayControl)}
        </div>
        <div className={classes.chart}>
          <div className={classes.scale}>{hours.map(renderMarkers)}</div>
          <div className={classes.table}>{schedule.map(renderRow)}</div>
        </div>
      </div>
      {renderSchedulerSidebar()}
    </>
  );
};

export default Scheduler;
