import { makeStyles } from '@material-ui/core/styles';

export const useSchedulerPeriodsStyles = makeStyles(
  ({ color: { base, secondary, primary, links, tertiary, danger, success, warning } }) => ({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    controls: {
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: '1em',
      },
    },
    control: {
      width: '100%',
      minWidth: '12.5em',
      height: '2.5em',
      padding: '0 1em',
      border: 'none',
      backgroundColor: tertiary[50],
      '&:hover': {
        backgroundColor: tertiary[100],
      },
    },
    controlContent: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      width: '11.5em',
      justifyContent: 'space-between',
    },
    controlCaption: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    controlCaptionText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: '0.5em',
      paddingTop: '0.125em',
    },
    activeCaptionText: {
      maxWidth: '8.5em',
    },
    inactiveCaptionText: {
      maxWidth: '10.5em',
    },
    indicator: {
      height: '1em',
      maxHeight: '1em',
      minHeight: '1em',
      width: '1em',
      maxWidth: '1em',
      minWidth: '1em',
      borderRadius: '0.3125em',
    },
    default: {
      background: base,
      border: `0.0625em solid ${tertiary[300]}`,
    },
    success: {
      background: success[500],
    },
    error: {
      background: danger[500],
    },
    primary: {
      background: primary[500],
    },
    links: {
      background: links[500],
    },
    warning: {
      background: warning[600],
    },
    secondary: {
      background: secondary[600],
    },
    active: {
      background: tertiary[100],
      border: `2px solid ${tertiary[400]}`,
      '&:hover': {
        borderColor: tertiary[400],
      },
    },
    addButton: {
      borderStyle: 'dashed',
      padding: '0.69em',
    },
    input: {
      background: 'transparent',
      padding: '0 1em',
    },
    controlGroup: {
      display: 'flex',
      flexFlow: 'column',
      width: '12.35em',
      minHeight: '4.25em',
    },
    inputBase: {
      padding: '0.75em 0',
    },
    controlIcon: {
      cursor: 'pointer',
    },
    controlStab: {
      width: '16px',
      minWidth: '16px',
      display: 'none',
    },
    validationError: {
      marginTop: '0.5em',
      marginBottom: '0.125em',
    },
  }),
  {
    index: 1,
  }
);
