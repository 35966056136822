import clsx from 'clsx';
import React from 'react';
import Preloader from '@shared/components/Preloader';
import { usePreloaderPanelStyles } from './PreloaderPanel.styles';

interface IPreloadPanelProps {
  className?: string;
}

const PreloaderPanel = ({ className }: IPreloadPanelProps) => {
  const classes = usePreloaderPanelStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Preloader />
    </div>
  );
};

export default PreloaderPanel;
