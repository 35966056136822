import * as React from 'react';
import { memo, useCallback } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import clsx from 'clsx';
import Preloader from '@shared/components/Preloader';
import Typography from '@shared/components/Typography';
import { useButtonStyle } from './Button.styles';
import { ButtonProps } from './Button.interfaces';

export const Button = ({
  variant = 'primary',
  title,
  titleCode,
  titleNative,
  children,
  color,
  clear,
  noHover,
  lowBorder,
  size = 'medium',
  rounded,
  disableElevation = true,
  classes = {},
  className,
  disabled,
  loading,
  onClick,
  smallHeight,
  classNameTypography,
  ...rest
}: ButtonProps) => {
  const buttonClasses = useButtonStyle();

  const textValue = titleCode || title;
  const labelClasses = clsx(
    buttonClasses.label,
    loading && buttonClasses.labelLoading,
    classes?.label
  );

  const computedClasses = clsx(
    buttonClasses.root,
    rounded ? buttonClasses.borderRadius20 : buttonClasses.borderRadius6,
    {
      [buttonClasses.disabled]: disabled,

      [buttonClasses.primary]: variant === 'primary',
      [buttonClasses.primaryLoading]:
        (variant === 'primary' || variant === 'secondaryFilled') && !color && loading,
      [buttonClasses.primaryError]:
        (variant === 'primary' || variant === 'secondaryFilled') && color === 'error',
      [buttonClasses.primaryErrorLoading]:
        (variant === 'primary' || variant === 'secondaryFilled') && color === 'error' && loading,
      [buttonClasses.primaryDisabled]: variant === 'primary' && disabled,

      [buttonClasses.secondary]: variant === 'secondary',
      [buttonClasses.secondaryError]: variant === 'secondary' && color === 'error',
      [buttonClasses.secondaryDisabled]: variant === 'secondary' && disabled,

      [buttonClasses.secondaryFilled]: variant === 'secondaryFilled',
      [buttonClasses.secondaryFilledDisabled]: variant === 'secondaryFilled' && disabled,

      [buttonClasses.tertiary]: variant === 'tertiary',

      [buttonClasses.icon]: variant === 'icon',

      [buttonClasses.text]: variant === 'text',

      [buttonClasses.clear]: clear,

      [buttonClasses.smallHeight]: smallHeight,
    },
    className
  );
  const clickCallback = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (loading) {
        event.preventDefault();
        return;
      }
      onClick?.(event);
    },
    [loading, onClick]
  );

  const renderButtonContext = () => {
    if (loading) {
      return (
        <span className={buttonClasses.loaderWrapper}>
          <Preloader size={'medium'} color={color} />
        </span>
      );
    }
    if (children) {
      return children;
    }

    const buttonTextColor = () => {
      if (disabled) {
        return 'tertiary300';
      }
      if (variant === 'primary' || (variant === 'secondaryFilled' && color === 'error')) {
        return 'base';
      }
      return 'tertiary900';
    };

    return (
      <Typography
        className={clsx(buttonClasses.typography, classNameTypography)}
        color={buttonTextColor()}
        type={'text3'}
      >
        {textValue}
      </Typography>
    );
  };

  return (
    <MuiButton
      classes={{
        ...classes,
        label: labelClasses,
        disableElevation: clsx(buttonClasses.disableElevation, classes.disableElevation),
      }}
      className={computedClasses}
      disableElevation={disableElevation}
      disabled={disabled}
      title={titleNative}
      size={size}
      onClick={clickCallback}
      {...rest}
    >
      {renderButtonContext()}
    </MuiButton>
  );
};

export default memo(Button) as typeof Button;
