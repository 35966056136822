export const isArraysValuesEqual = (firstArray: Array<unknown>, secondArray: Array<unknown>) => {
  if (firstArray && secondArray) {
    if (firstArray.length !== secondArray.length) {
      return false;
    }
    return firstArray.reduce((res, item) => {
      if (!secondArray.includes(item)) {
        return false;
      }
      return res;
    }, true);
  }
  return false;
};
