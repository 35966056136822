import { DomainStatus, UserQuery } from '@/client/generated/graphql';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { NavigateFunction } from 'react-router';

export const goToDocumentsIfCurrentDomainStatusIsDemo = (
  navigate: NavigateFunction,
  userQuery?: UserQuery
) => {
  const currentDomain = getCurrentDomain(userQuery?.user);
  if (currentDomain?.status === DomainStatus.Demo) {
    navigate('/documents/documents-list');
  }
};
