import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import MessageDialog from '../MessageDialog';
import MessageDialogWithStatus from '../MessageDialogWithStatus';
import PasswordField from '../PasswordField';
import Typography from '../Typography';
import { PasswordChangeFields, PasswordChangeModalProps } from './PasswordChangeModal.types';

const usePasswordChangeModalStyles = makeStyles(({ color: { tertiary, success } }) => ({
  passwordField: {
    display: 'block',
    '& + &': {
      marginTop: '2em',
    },
  },
  controlButtonsWrapper: {
    marginTop: '2em',

    display: 'flex',
    justifyContent: 'end',

    height: '2em',
  },
  btnChangePassword: {
    borderRadius: '6px',
    marginLeft: '.5em',
    width: pxToEm(142),
    padding: 0,
  },
  hidden: {
    opacity: 0,
    display: 'none',
    height: 0,
  },
  messageSuccess: {
    margin: '1.5em 0',
    fontSize: '0.875em',
  },
  iconSuccessContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconSuccess: {
    fontSize: '1em',
    width: '2em',
    height: '2em',
    fill: success[600],
  },
  content: {
    width: '20em',
  },
  dialogSuccess: {
    width: pxToEm(296),
  },
}));

const PasswordChangeModal = ({ changePasswordSafe, onClose, open }: PasswordChangeModalProps) => {
  const classes = usePasswordChangeModalStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [translate] = useTranslation();

  const openRef = useRef(open); // is used to prevent wrong behavior caused by closure
  useEffect(() => {
    openRef.current = open;
  }, [open]);

  const formMethods = useForm<PasswordChangeFields>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeated: '',
    },
  });

  const handleFormSubmit = async (fields: PasswordChangeFields) => {
    setIsLoading(true);
    const result = await changePasswordSafe(fields);

    if (result.type === 'success') {
      formMethods.reset();
      setIsLoading(false);
      if (openRef.current) {
        setIsSuccessModalOpen(true);
        onClose();
      }
      return;
    }

    switch (result.error) {
      case 'old-and-new-passwords-equal': {
        formMethods.setError('oldPassword', {
          type: 'old-and-new-passwords-equal',
          message: translate('NEW_AND_OLD_PASSWORDS_EQUALS'),
        });
        break;
      }
      case 'wrong-password': {
        formMethods.setError('oldPassword', {
          type: 'wrong-password',
          message: translate('INVALID_PASSWORD'),
        });
        break;
      }
      case 'unknown': {
        formMethods.setError('newPassword', {
          type: 'unknown',
          message: translate('UNKNOWN_ERROR'),
        });
        break;
      }
      default: {
        result.error satisfies never;
      }
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
    setIsSuccessModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <>
      <MessageDialog
        isOpen={open && !isSuccessModalOpen}
        title={translate('PASSWORD_CHANGE')}
        onCancel={handleClose}
        contentClass={classes.content}
        paperClass={clsx(isSuccessModalOpen && classes.hidden)}
        renderContent={
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
              <PasswordField
                name="oldPassword"
                classNameContainer={classes.passwordField}
                placeholder=""
                label="Старый пароль"
                required
                disabled={isLoading}
                scoring={false}
                generation={false}
              />
              <PasswordField
                name="newPassword"
                classNameContainer={classes.passwordField}
                placeholder=""
                label="Новый пароль"
                required
                disabled={isLoading}
              />
              <PasswordField
                name="newPasswordRepeated"
                classNameContainer={classes.passwordField}
                placeholder=""
                label="Повторите новый пароль"
                required
                disabled={isLoading}
                scoring={false}
                generation={false}
                validate={(v: string) => {
                  if (v !== formMethods.getValues('newPassword')) return 'Пароли не совпадают';
                  return true;
                }}
              />
              <div className={classes.controlButtonsWrapper}>
                <Button variant="secondary" onClick={handleClose}>
                  <Typography pxToEmSize={14}>Отменить</Typography>
                </Button>
                <Button
                  variant="primary"
                  loading={isLoading}
                  type="submit"
                  className={classes.btnChangePassword}
                >
                  <Typography pxToEmSize={14} color="base">
                    Сменить пароль
                  </Typography>
                </Button>
              </div>
            </form>
          </FormProvider>
        }
      />
      <MessageDialogWithStatus
        contentClass={classes.dialogSuccess}
        isOpen={isSuccessModalOpen}
        status="success"
        title={translate('PASSWORD_IS_CHANGED')}
        renderContent={
          <Typography>
            {translate('THE_NEW_PASSWORD_WILL_BE_APPLIED_TO_ACCESS_ALL_YOUR_PBX')}
          </Typography>
        }
        onCancel={handleSuccessModalClose}
      />
    </>
  );
};

export default PasswordChangeModal;
