import React, { FunctionComponent, memo, useContext } from 'react';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import { IScenarioSettingsType } from '../../IntegrationForm.interfaces';
import { BitrixCRMSettingsForm } from '../../Bitrix24/BitrixCRMSettingsForm';
import { AmoCRMSettingsForm } from '../../AmoCRM/AmoCRMSettingsForm';
import { RetailCRMSettingsForm } from '../../RetailCRM/RetailCRMSettingsForm';
import { IntegrationContext } from '../../IntegrationForm';

export const ScenarioSettings: FunctionComponent<IScenarioSettingsType> = (props) => {
  const { APIName } = useContext(IntegrationContext);

  const renderSettingsForm = () => {
    if (APIName === IntegrationList.Bitrix24) {
      return <BitrixCRMSettingsForm {...props} />;
    }
    if (APIName === IntegrationList.AmoCrm) {
      return <AmoCRMSettingsForm {...props} />;
    }
    if (APIName === IntegrationList.RetailCRM) {
      return <RetailCRMSettingsForm {...props} />;
    }
    return null;
  };

  return renderSettingsForm();
};

export default memo(ScenarioSettings);
