import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subMonths,
  subWeeks,
} from 'date-fns';

export enum PeriodValues {
  Today = 'today',
  Yesterday = 'yesterday',
  CurrentMonth = 'currentMonth',
  PastMonth = 'pastMonth',
  Custom = 'custom',
}

export interface IDataOptionType {
  [key: string]: {
    from: Date;
    to: Date;
    title: string;
    value: PeriodValues;
  };
}

export const getDateList = (date?: Date) => {
  const now = date || new Date();
  const currentDay = endOfDay(now);
  const startDay = startOfDay(now);
  const startYesterday = startOfYesterday();
  const endYesterday = endOfYesterday();
  const currentWeek = startOfWeek(now, { weekStartsOn: 1 });
  const endWeek = endOfWeek(now, { weekStartsOn: 1 });
  const pastWeek = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
  const endPastWeek = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
  const startMonth = startOfMonth(now);
  const endMonth = endOfMonth(now);
  const startPastMonth = startOfMonth(subMonths(now, 1));
  const endPastMonth = endOfMonth(subMonths(now, 1));
  const currentYear = startOfYear(now);
  const endYear = endOfYear(now);
  const startWholeYear = startOfMonth(subMonths(now, 12));
  const endWholeYear = endMonth;

  return {
    currentDay,
    startDay,
    startYesterday,
    endYesterday,
    currentWeek,
    endWeek,
    pastWeek,
    endPastWeek,
    startMonth,
    endMonth,
    startPastMonth,
    endPastMonth,
    currentYear,
    endYear,
    startWholeYear,
    endWholeYear,
  };
};

export const useDateOptions = (allowed: PeriodValues[] | null): IDataOptionType => {
  const {
    currentDay,
    startDay,
    startYesterday,
    endYesterday,
    startMonth,
    endMonth,
    startPastMonth,
    endPastMonth,
  } = getDateList();
  if (!allowed) {
    allowed = [
      PeriodValues.Today,
      PeriodValues.Yesterday,
      PeriodValues.CurrentMonth,
      PeriodValues.PastMonth,
      PeriodValues.Custom,
    ];
  }
  const opts = {
    today: {
      from: startDay,
      to: currentDay,
      title: 'PERIOD_OPT_TODAY',
      value: PeriodValues.Today,
    },
    yesterday: {
      from: startYesterday,
      to: endYesterday,
      title: 'PERIOD_OPT_PASTDAY',
      value: PeriodValues.Yesterday,
    },
    currentMonth: {
      from: startMonth,
      to: endMonth,
      title: 'PERIOD_OPT_MONTH',
      value: PeriodValues.CurrentMonth,
    },
    pastMonth: {
      from: startPastMonth,
      to: endPastMonth,
      title: 'PERIOD_OPT_PASTMONTH',
      value: PeriodValues.PastMonth,
    },
    custom: {
      from: startDay,
      to: currentDay,
      title: 'PERIOD_OPT_CUSTOM',
      value: PeriodValues.Custom,
    },
  };
  return allowed.reduce((obj, key) => {
    obj[key] = opts[key];
    return obj;
  }, {} as IDataOptionType);
};
