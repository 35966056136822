import { makeStyles } from '@material-ui/core';

export const usePasswordFieldStyles = makeStyles(
  {
    container: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      paddingBottom: '0.25em',
    },
    generationWrapper: {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 3,
      lineHeight: '0.75em',
    },
    generationSpan: {
      lineHeight: '0.75em',
    },
  },
  {
    index: 1,
  }
);
