import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@shared/components/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IRoute } from '@components/Routes';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import { useSidebarItemsStyles } from './SidebarItem.styles';

export const SidebarItem = ({
  path = '',
  titleCode = '',
  icon,
  expanded,
}: IRoute & { expanded?: boolean }): JSX.Element => {
  const [translate] = useTranslation();
  const [active, setActive] = useState(false);
  const classes = useSidebarItemsStyles();

  const handleIn = () => {
    setActive(true);
  };

  const handleOut = () => {
    setActive(false);
  };

  return (
    <NavLink
      to={`/${path}`}
      className={classes.link}
      activeClassName={classes.activeLink}
      onMouseEnter={handleIn}
      onMouseLeave={handleOut}
    >
      <ListItem
        button={false}
        classes={{
          gutters: classes.gutters,
          root: classes.listItem,
        }}
      >
        {!active || expanded ? (
          <ListItemIcon classes={{ root: classes.listItemIcon }}>{icon}</ListItemIcon>
        ) : (
          <TooltipHover
            placement={'right'}
            title={
              <Typography type={'text3'} color={'base'} bold>
                {translate(titleCode)}
              </Typography>
            }
            tooltipClass={classes.tooltip}
            arrowClass={classes.arrow}
            disableHoverListener
            open={active}
          >
            <ListItemIcon
              classes={{ root: clsx(classes.listItemIcon, classes.listItemIconActive) }}
            >
              {icon}
            </ListItemIcon>
          </TooltipHover>
        )}
        <ListItemText
          disableTypography
          classes={{
            root: clsx(classes.listItemText, { [classes.listItemTextActive]: expanded }),
          }}
        >
          <Typography type={'text3'} color={'inherit'}>
            {translate(titleCode)}
          </Typography>
        </ListItemText>
      </ListItem>
    </NavLink>
  );
};

export default SidebarItem;
