import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useIncomingNumbersStyles = makeStyles(
  ({ color: { primary, secondary, warning, tertiary, base } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    row: {
      whiteSpace: 'nowrap',
      marginBottom: '0.325em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    numberTypeBody: {
      paddingTop: '0.25em',
      color: 'inherit',
    },
    icon: {
      color: secondary[600],
    },
    numberContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    numberPeriods: {
      display: 'flex',
      paddingBottom: '0.625em',
      paddingRight: '1em',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
    },
    numberPeriodName: {
      minWidth: '11em',
      marginRight: '1em',
    },
    numberActionsBody: {
      color: 'inherit',
      padding: '1.25em 0 1em 1em',
      verticalAlign: 'top',
    },
    numberActionsContent: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row-reverse nowrap',
      '& > :not(:last-child)': {
        marginLeft: '1em',
      },
    },
    numberActionsDialog: {
      padding: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    numberActionsDialogContent: {
      padding: '0.75em 2.4em',
      textAlign: 'center',
    },
    numberActionsDialogTitle: {
      fontWeight: 'bold',
      lineHeight: '150%',
      textAlign: 'center',
      padding: '2em',
    },
    numberActionsDialogActions: {
      paddingTop: '2em',
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    numberActionsActionButton: {
      height: '2em',
      width: '2em',
      padding: 'initial',
    },
    rowClass: {
      '&:hover span': {
        color: primary[800],
      },
    },
    noNumbers: {
      position: 'absolute',
      top: '12em',
      height: '15em',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    phoneNumberIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    reservedNumberWarning: {
      color: warning[600],
    },
    reservedNumberIcon: {
      color: warning[600],
      marginLeft: 'auto',
    },
    reservedNumberAlertIcon: {
      height: '1em',
      width: '1em',
      margin: '0.125em 0.5em 0 0',
    },
    integrationIconWrapper: {
      position: 'relative',
      width: '2em',
      height: '2em',
      marginRight: '0.5em',
      boxSizing: 'border-box',
    },
    integrationIcon: {
      borderRadius: '6px',
      height: '100%',
      width: '100%',

      '& > svg': {
        width: '1em',
        height: '1em',
      },
    },
    integrationIconDisabled: {
      opacity: 0.3,
    },
    integrationGrouped: {
      position: 'relative',
      padding: '0.5em',
    },
    integrationArrow: {
      padding: '0.5em 0',
      width: '1em',
      height: '2em',
    },
    integrationUserIcon: {
      transform: 'scale(1.5)',
    },
    integrationIconLine: {
      marginTop: '0.5em',
      '&:first-child': {
        marginTop: 0,
      },
    },
    integrationIconDivider: {
      marginTop: '0.5em',
      height: '1px',
      backgroundColor: tertiary[300],
    },
    integrationDisabledIcon: {
      position: 'absolute',
      width: '0.75em',
      height: '0.75em',
      bottom: 0,
      right: 0,
      color: warning[600],
      backgroundColor: base,
      borderRadius: '50%',

      '& svg': {
        width: '10px',
        height: '10px',
      },
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
