import { makeStyles } from '@material-ui/core/styles';

export const useLazyTableCellSkeletonStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    skeletonImage: {
      backgroundColor: tertiary[50],
      borderRadius: '3px',
      minWidth: '2.285px',
      marginLeft: '-0.5em',
    },
    skeletonTextLong: {
      backgroundColor: tertiary[50],
      borderRadius: '3px',
      maxWidth: '16em',
      height: '1em',
    },
    skeletonText: {
      backgroundColor: tertiary[50],
      borderRadius: '8px',
      maxWidth: '14em',
      height: '0.875em',
    },
    skeletonActionsCell: {
      justifyContent: 'flex-end',
      width: '100%',
      '& > *': {
        marginLeft: '1em',
      },
    },
  }),
  {
    name: 'LazyTableCellSkeleton',
    index: 1,
  }
);
