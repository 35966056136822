import { makeStyles } from '@material-ui/core/styles';

export const CallDistributionFieldsStyle = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {},
    balloonCard: {
      backgroundColor: tertiary[50],
      width: 'auto',
      maxWidth: '47em',
      minWidth: '10.75em',
      borderRadius: '5px',
      border: `1px solid ${tertiary[100]}`,
      padding: '0 2em 1em 2em',
      position: 'relative',
      marginTop: '0.625em',
      marginBottom: '1em',
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
      '&::before': {
        top: '-0.5em',
        left: '2em',
        transform: 'rotate(45deg)',
        width: '0.875em',
        height: '0.875em',
        content: '""',
        border: `1px solid ${tertiary[100]}`,
        background: tertiary[50],
        position: 'absolute',
        borderRight: 'none',
        borderBottom: 'none',
      },
    },
    select: {
      marginTop: '1em',
    },
    cardTitle: {
      margin: '1em 0 0',
    },
  }),
  {
    index: 1,
  }
);
