import { makeStyles } from '@material-ui/core';
import styleConstants from '@components/styles';

export const useSettingsBlackListStyles = makeStyles(
  ({ color: { primary } }) => ({
    removeNumberDialog: {
      width: '21em',
    },
    formField: {
      marginTop: '1em',
    },
    addNumberActions: {
      marginTop: '2em',
    },
    cancelBtn: {
      width: '10em',
    },
    removePopupText: {
      paddingBottom: '1.5em',
      textAlign: 'left',
    },
    NumberCell: {
      marginLeft: '0.8em',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1em 0 1em 2em',
    },
    root: {
      width: '100%',
      height: '100%',
    },
    action: {
      width: '14em',
      height: '2em',
      margin: '0 1em',
    },
    actionButton: {
      height: '2em',
      width: '2em',
      padding: 'initial',
    },
    addBlackListContent: {
      '& > :not(:first-child)': {
        marginTop: '1.5em',
      },
    },
    phoneIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
