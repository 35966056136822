import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { IIncomingNumberScenario } from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import SwitchField from '@shared/components/SwitchField';
import CheckboxField from '@shared/components/CheckboxField';
import Typography from '@shared/components/Typography';
import MessageFields from '@/components/MessageFields/MessageFields';
import { defaultMessages, voiceMenuButtonsOptions } from '../../IncomingNumber.constants';
import { ScenariosTypes } from '../../IncomingNumber.interfaces';
import { useVoiceMenuScenarioStyles } from './VoiceMenuScenario.styles';
import {
  IVoiceMenuScenarioProps,
  IVoiceMenuSettingsFormData,
} from './VoiceMenuScenario.interfaces';

export const VoiceMenuScenario = ({
  onAddChild,
  onChangeChild,
  onSave,
  onRemoveChild,
  settings,
  onCancel,
  allSettings,
  onChanges,
  setIsSaveButtonLoading,
}: IVoiceMenuScenarioProps) => {
  const classes = useVoiceMenuScenarioStyles();
  const [translate] = useTranslation();

  const [children, setChildren] = useState<{ [key: string]: IIncomingNumberScenario }>({});

  const getChoiceId = (): string => `${Math.random().toString(36).substr(2, 9)}`;
  const handleChanges = () => {
    if (typeof onChanges === 'function') {
      onChanges();
    }
  };

  const formMethodsDefaultValues: IVoiceMenuSettingsFormData = useMemo(
    () => ({
      message: settings?.id
        ? settings?.message
        : translate(defaultMessages.get(ScenariosTypes.VoiceMenu) || '', ''),
      messageType: settings?.messageType || 'TEXT',
      isMessageActive: Boolean(settings?.isMessageActive ?? true),
      fileName: settings?.fileName,
      fileUrl: settings?.fileUrl,
      fileHash: settings?.fileHash,
      isExtensionDealingActive: settings?.id ? settings?.isExtensionDealingActive : true,
      checkboxButton: [false, false, false, false, false, false, false, false, false, false],
    }),
    [settings, translate]
  );

  const formMethods = useForm<IVoiceMenuSettingsFormData>({
    defaultValues: formMethodsDefaultValues,
  });

  const { handleSubmit, setValue } = formMethods;

  useEffect(() => {
    const getInitialChildren = () => {
      const noChoiceId = getChoiceId();
      return {
        [noChoiceId]: {
          id: noChoiceId,
          type: ScenariosTypes.ClientChoice,
          button: '?',
        },
      };
    };
    const newChildren =
      settings?.children && settings?.children.length
        ? settings?.children.reduce(
            (result: { [key: string]: IIncomingNumberScenario | undefined }, childId: string) => {
              // eslint-disable-next-line no-param-reassign
              result[childId] = allSettings?.[childId];
              return result;
            },
            {}
          )
        : getInitialChildren();
    setChildren(newChildren);
  }, [allSettings, settings, settings?.children]);

  useEffect(() => {
    const selectedButtons = Object.values(children).filter(
      (item: IIncomingNumberScenario) => item && item?.button !== '?'
    );
    if (selectedButtons.length) {
      selectedButtons.forEach((child: IIncomingNumberScenario) => {
        setValue(`checkboxButton.${child.button}`, true);
      });
    }
  }, [children, setValue, settings?.children]);

  useEffect(() => {
    if (!settings?.children || !settings?.children.length) {
      setValue('checkboxButton.1', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitForm = (data: IVoiceMenuSettingsFormData) => {
    const { id: scenarioId = '', children: scenarioChildren = [], type: scenarioType } = settings;
    let newChildren = children;
    if (scenarioId) {
      const nodesToRemove = scenarioChildren.filter(
        (id: string) =>
          children[id].button &&
          children[id].button !== '?' &&
          !data.checkboxButton[Number(children[id]?.button)]
      );
      nodesToRemove.forEach((removeId: string) => {
        onRemoveChild(removeId, scenarioId);
        delete newChildren[removeId];
      });
    }
    const nodesToAdd = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      if (
        data.checkboxButton[i] &&
        !scenarioChildren.some((id: string) => children[id]?.button === String(i))
      ) {
        const newChild = {
          id: getChoiceId(),
          type: ScenariosTypes.ClientChoice,
          button: String(i),
        };
        newChildren = {
          ...newChildren,
          [newChild?.id]: newChild,
        };
        nodesToAdd.push(newChild);
      }
    }
    setChildren(newChildren);
    nodesToAdd.forEach(onAddChild);
    onSave({
      ...settings,
      type: scenarioType || ScenariosTypes.VoiceMenu,
      ...data,
      isMessageActive: true,
      fileUrl: undefined,
      children: Object.keys(newChildren)
        .filter((key) => newChildren[key])
        .sort((keyA, keyB) => {
          const childrenAButton = newChildren[keyA]?.button;
          const childrenBButton = newChildren[keyB]?.button;
          if (
            !childrenAButton ||
            !childrenBButton ||
            childrenAButton === '0' ||
            childrenBButton === '0' ||
            childrenAButton === '?' ||
            childrenBButton === '?'
          ) {
            if (childrenAButton === '?' && childrenBButton) {
              return 1;
            }
            if (childrenBButton === '?' && childrenAButton) {
              return -1;
            }
            if (childrenAButton === '0' && childrenBButton) {
              return 1;
            }
            if (childrenBButton === '0' && childrenAButton) {
              return -1;
            }
            return 0;
          }
          return Number(childrenAButton) - Number(childrenBButton);
        }),
    });
    Object.keys(newChildren).forEach((key) => {
      const child = newChildren[key];
      if (child) {
        onChangeChild(child);
      }
    });
    onCancel();
  };

  const renderKeyButtonsList = () =>
    voiceMenuButtonsOptions.map((buttonItem, index) => (
      <div key={`checkbox-${index}`} className={classes.keyButtonCheckboxWrapper}>
        <CheckboxField
          onChange={handleChanges}
          name={`checkboxButton.${buttonItem.value}`}
          label={translate(buttonItem.titleCode)}
        />
      </div>
    ));

  return (
    <div className={classes.root}>
      <FormProvider {...formMethods}>
        <form action={''} id={'voice-menu-scenario'} onSubmit={handleSubmit(handleSubmitForm)}>
          <div>
            <div className={classes.formContentRow}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('MESSAGE')}
              </Typography>
            </div>
            <div className={classes.fieldSet}>
              <MessageFields
                required
                onChanges={handleChanges}
                setIsSaveButtonLoading={setIsSaveButtonLoading}
              />
            </div>
            <div className={classes.formContentRow}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('CHOICES')}
              </Typography>
            </div>
            <div className={classes.formContentColumn}>
              <Typography type={'text3'} color={'tertiary900'}>
                {translate('CHOICES_HINT')}
              </Typography>
            </div>
            <div className={classes.list}>{renderKeyButtonsList()}</div>
            <div className={classes.formContentRow}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('EXTENSION_NUMBERS_DEALING')}
              </Typography>
              <SwitchField name={'isExtensionDealingActive'} onChanges={handleChanges} />
            </div>
            <div className={classes.formContentColumn}>
              <Typography type={'text3'} color={'tertiary900'} className={classes.drawerText}>
                {translate('EXTENSION_NUMBERS_DEALING_HINT')}
              </Typography>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default VoiceMenuScenario;
