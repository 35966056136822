import { makeStyles } from '@material-ui/core/styles';

export const useCellStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    message: {
      padding: '1em 1.5em 1em 1.5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
  }),
  {
    name: 'CellPreloader',
    index: 1,
  }
);
