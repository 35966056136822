import { makeStyles } from '@material-ui/core/styles';

export const useMessageScenarioStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
    },
    caption: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      marginBottom: '1.5em',
    },
  }),
  {
    index: 1,
  }
);
