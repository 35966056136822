import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { useCallDetailsStyle } from '../CallDetails.styles';
import { ICallDetailsList } from '../CallDetails.interfaces';

export const CallDetailsPBSCell: FunctionComponent<ICellProps<ICallDetailsList>> = ({ item }) => {
  const classes = useCallDetailsStyle();
  const [searchParams, setSearchParams] = useSearchParams();
  const sSearch = searchParams.get('search');

  function handleClientClick() {
    searchParams.set('search', item?.local || '');
    searchParams.set('group', 'phone');
    setSearchParams(searchParams);
  }

  if (item) {
    const { local } = item;
    return (
      <div className={classes.textHover} onClick={handleClientClick}>
        <HighlightedText type={'phone'} text={local} query={sSearch} />
      </div>
    );
  }

  return <div />;
};
