import React, { FunctionComponent, memo, useState } from 'react';
import {
  StaticDatePicker as MuiStaticDatePicker,
  LocalizationProvider,
} from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import FormFieldBase from '@shared/components/FormFieldBase';
import { StaticDatePickerProps } from './StaticDatePicker.interfaces';
import { useStaticDatePickerStyles } from './StaticDatePicker.styles';

export const StaticDatePicker: FunctionComponent<StaticDatePickerProps> = ({
  name,
  value,
  disableFuture = true,
  minDate,
  disabled,
  onChange,
}) => {
  const classes = useStaticDatePickerStyles();
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const dateValue = selectedDate || value;

  function handleDataChange(date: Date | null) {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }
  }

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ruLocale}>
      <MuiStaticDatePicker
        className={classes.staticDatePicker}
        onChange={handleDataChange}
        disabled={disabled}
        minDate={minDate}
        showToolbar={false}
        disableFuture={disableFuture}
        showDaysOutsideCurrentMonth
        value={dateValue}
        renderInput={(props) => <FormFieldBase {...props} name={name} />}
      />
    </LocalizationProvider>
  );
};

export default memo(StaticDatePicker);
