import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { XIcon, SearchIcon, ChevronUpIcon, ChevronDownIcon } from '@shared/assets/images/icons';
import ControlButtons from '@shared/components/ControlButtons';
import CheckboxField from '@shared/components/CheckboxField';
import Typography from '@shared/components/Typography';
import { ClickAwayListener, InputAdornment, TextField } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateWithMemo } from '@/utils';
import { IFilterBlock, IFilterConfig, IFilterPBXProps } from './FilterByPBX.interfaces';
import { useFilterPBXStyle } from './FilterByPBX.styles';

export const FilterByPBX: FunctionComponent<IFilterPBXProps> = ({
  data,
  onFilterChange,
  loading,
  filterTitle,
}) => {
  const classes = useFilterPBXStyle();
  const [translate] = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [filterText, changeFilterText] = useState('');
  const formMethods = useForm();
  const { stateValues, setStateValues, savePrevState, restoreState } = useStateWithMemo<{
    [key: string]: number[];
  }>();
  const isSelectedValuesEmpty = !Object.keys(stateValues || {}).length;
  const filteredData: IFilterBlock[] = useMemo(() => {
    if (!data) return [];
    return data.map((block) => ({
      title: block.title,
      data: block.data.filter((listItem) => {
        if (listItem.value !== null) {
          if (filterText.length) {
            return listItem.title.toUpperCase().includes(filterText.toUpperCase());
          }
          return listItem;
        }
        return null;
      }),
    }));
  }, [data, filterText]);

  const isAllSelected = useMemo(() => {
    if (!data) return false;
    const rawDataLength = data.reduce((res, item) => res + item.data.length, 0);
    const selectedDataLength = Object.values(stateValues || {}).reduce(
      (res, item) => res + item.length,
      0
    );
    return rawDataLength === selectedDataLength;
  }, [data, stateValues]);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (!checked) {
        setStateValues({});
        return;
      }

      setStateValues(
        filteredData.reduce((result, block, index) => {
          const allDataValues = block.data.map((el) => el.value);
          return {
            ...result,
            [index]: allDataValues,
          };
        }, {})
      );
    },
    [filteredData, setStateValues]
  );

  useEffect(() => {
    if (!loading && !stateValues) {
      handleSelectAll(true);
    }
  }, [handleSelectAll, loading, stateValues]);

  function handleOpen() {
    if (filterText) {
      changeFilterText('');
    }
    savePrevState();
    setOpen(true);
  }

  function handleClickAway() {
    if (filterText) {
      changeFilterText('');
    }
    restoreState();
    setOpen(false);
  }

  function handleCancelClick() {
    restoreState();
    setOpen(false);
  }

  function setCheckedStatus(index: number, value: number) {
    if (stateValues && stateValues[index]) {
      return stateValues[index].includes(value);
    }
    return false;
  }

  function handleSubmit() {
    setOpen(false);

    if (!onFilterChange) {
      return;
    }

    if (isAllSelected) {
      onFilterChange({});
      return;
    }

    onFilterChange(stateValues);
  }

  function handleClearFilter() {
    handleSelectAll(true);
    onFilterChange({});
  }

  const handleChooseValue = (groupIndex: number, value: number, isChecked: boolean) => {
    setStateValues((prevState) => {
      const prevStateArray = [...(prevState?.[groupIndex] || [])];
      if (prevStateArray.includes(value) && !isChecked) {
        const index = prevStateArray.indexOf(value);
        prevStateArray.splice(index, 1);
      } else {
        prevStateArray.push(value);
      }

      return {
        ...prevState,
        [groupIndex]: prevStateArray,
      };
    });
  };

  const handleFilterChange = (value: string) => {
    changeFilterText(value);
  };

  return (
    <div className={classes.filterContainer}>
      <FormProvider {...formMethods}>
        {filterTitle && !isAllSelected && !isOpen && (
          <XIcon className={classes.titleClearIcon} onClick={handleClearFilter} />
        )}
        <FormFieldRhfUncontrolled
          name={'filterByPBX'}
          className={classes.filterTrigger}
          classes={{ root: classes.input }}
          onClick={handleOpen}
          defaultValue={translate('ALL_EMPLOYEES')}
          value={filterTitle && `${filterTitle}`}
          InputProps={{
            endAdornment: isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />,
            classes: { input: classes.input },
            readOnly: true,
          }}
        />
        {isOpen && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.filterWrapper}>
              <TextField
                name={'search'}
                type={'text'}
                placeholder={translate('SEARCH')}
                InputProps={{
                  classes: { root: classes.searchInputRoot },
                  disableUnderline: true,
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      {filterText.length ? (
                        <div className={classes.clearIcon}>
                          <XIcon onClick={() => changeFilterText('')} />
                        </div>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
                value={filterText}
                onChange={({ target: { value = '' } = {} }) => handleFilterChange(value)}
              />
              <div className={classes.optionsWrapper}>
                {!filterText && (
                  <CheckboxField
                    name={'selectAll'}
                    className={classes.checkboxControl}
                    label={translate('SELECT_ALL')}
                    onChange={handleSelectAll}
                    checked={isAllSelected}
                  />
                )}
                {filteredData.map((block, index) => (
                  <Fragment key={`block-${index}`}>
                    <div className={classes.blockSubstrate}>
                      <Typography
                        className={classes.groupTitle}
                        type={'text4'}
                        color={'tertiary600'}
                      >
                        {data[index]?.title}
                      </Typography>
                    </div>
                    <div className={classes.optionsList}>
                      {block.data.length ? (
                        block.data.map((el: IFilterConfig, i) => (
                          <CheckboxField
                            key={`el-${index}-${i}`}
                            name={`el-${index}-${i}`}
                            className={classes.checkboxControl}
                            checked={setCheckedStatus(index, el.value || 0)}
                            label={`${el.title} ${
                              el.additional && el.additional >= 1 ? `(${el.additional})` : ''
                            }`}
                            onChange={(isChecked: boolean) =>
                              handleChooseValue(index, el.value || 0, isChecked)
                            }
                          />
                        ))
                      ) : (
                        <Typography
                          className={classes.nothingFoundText}
                          type={'text3'}
                          color={'tertiary900'}
                        >
                          {!loading ? translate('NOTHING_FOUND') : ''}
                        </Typography>
                      )}
                    </div>
                  </Fragment>
                ))}
              </div>
              <ControlButtons
                rootClass={classes.btnWrapper}
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelUnderline
                onConfirmClick={handleSubmit}
                onCancelClick={handleCancelClick}
                confirmDisable={isSelectedValuesEmpty}
                justifyContent={'center'}
                rootConfirmStyles={classes.defaultElementWidth9}
              />
            </div>
          </ClickAwayListener>
        )}
      </FormProvider>
    </div>
  );
};

export default FilterByPBX;
