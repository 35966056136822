import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ASSISTANTS_QUERY, USER_QUERY } from '@/client/queries';
import PageError from '@shared/components/PageError';
import PagePreloader from '@components/PagePreloader';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/components/Button/Button';
import { CommercialDomainStatuses, DomainStatus, IAssistant } from '@components/typings/interfaces';
import { useEnabledDomainStatus } from '@/common/hooks';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import LazyTable, { IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { useScenariosStyle } from './Scenarios.styles';
import {
  ScenarioActionsCell,
  ScenarioNameCell,
  ScenarioOnceCostCell,
  ScenarioPackageCell,
  ScenarioPeriodicCostCell,
  ScenarioStatusCell,
} from './modules';

const columns: IColumn<IAssistant>[] = [
  {
    id: 'name',
    verticalAlign: 'center',
    label: 'SCENARIO_TEMPLATE',
    Renderer: ScenarioNameCell,
  },
  {
    id: 'setupFee',
    verticalAlign: 'center',
    label: 'SCENARIO_ONCE_COST',
    Renderer: ScenarioOnceCostCell,
  },
  {
    id: 'monthlyFee',
    verticalAlign: 'center',
    label: 'SCENARIO_PERIODIC_COST',
    Renderer: ScenarioPeriodicCostCell,
  },
  {
    id: 'minutePackage',
    verticalAlign: 'center',
    label: 'SCENARIO_MINUTES_PACKAGES',
    Renderer: ScenarioPackageCell,
  },
  { id: 'status', verticalAlign: 'center', label: 'STATUS', Renderer: ScenarioStatusCell },
  {
    id: 'actions',
    verticalAlign: 'center',
    align: 'right',
    label: '',
    Renderer: ScenarioActionsCell,
  },
];

export const ScenariosList: FunctionComponent = () => {
  const classes = useScenariosStyle();
  const [translate] = useTranslation();
  const { data: getAssistantsData, loading, error } = useQuery(GET_ASSISTANTS_QUERY);
  const { data: userData } = useQuery(USER_QUERY);
  const allGetAssistantsData = getAssistantsData?.getAssistants;
  const navigate = useNavigate();

  useEnabledDomainStatus(CommercialDomainStatuses);
  const status = getCurrentDomain(userData?.user)?.status;

  const renderAssistantsDetails = () => (
    <LazyTable<IAssistant>
      columns={columns}
      data={allGetAssistantsData}
      emptyDataMessage={translate('NORESULT_SCENARIOS')}
    />
  );

  const renderScenariosContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;
    return (
      <Flex direction={'column'} className={classes.root}>
        <Breadcrumbs>
          <Button
            className={classes.addScenarioBtn}
            title={translate('ADD_SCENARIO')}
            onClick={() => navigate('/scenarios-list/scenarios/add')}
            disabled={status === DomainStatus.PreDeleted}
          />
        </Breadcrumbs>
        {renderAssistantsDetails()}
      </Flex>
    );
  };

  return <BodyContainer disableOverflow>{renderScenariosContent()}</BodyContainer>;
};
