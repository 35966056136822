import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    employees: {
      fields: {
        merge(existing, incoming) {
          return { ...existing, ...incoming };
        },
      },
    },
  },
});
