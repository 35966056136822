import { makeStyles } from '@material-ui/core';
import { XIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import React from 'react';

type ProgressSpinnerProps = {
  /** A number within [0; 1] range */
  progress: number;

  classNameRoot?: string;
};

const useProgressSpinnerStyles = makeStyles(({ color: { primary, tertiary } }) => ({
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  progressSpinner: {
    width: '2em',
    height: '2em',

    position: 'relative',
    display: 'inline-block',
  },
  progressCircle: {
    transform: 'rotate(-90deg)',
  },
  progressCircleBackground: {
    fill: 'none',
    stroke: tertiary[200],
    strokeWidth: 15,
  },
  progressCircleProgress: {
    fill: 'none',
    stroke: primary[700],
    strokeWidth: 15,
    transition: 'stroke-dashoffset 0.35s',
    transformOrigin: 'center',
    animation: '$rotation linear 1s infinite',
  },
  iconCross: {
    position: 'absolute',

    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ProgressSpinner = ({ progress, classNameRoot }: ProgressSpinnerProps) => {
  const classes = useProgressSpinnerStyles();

  const circleRadius = 50;
  const circleCircumference = 2 * Math.PI * circleRadius;

  // Calculate the stroke-dasharray and stroke-dashoffset based on the progress
  const strokeDasharray = circleCircumference;
  const strokeDashoffset = circleCircumference - progress * circleCircumference;

  return (
    <div className={clsx(classes.progressSpinner, classNameRoot)}>
      <svg className={classes.progressCircle} width="100%" height="100%" viewBox="0 0 120 120">
        <circle className={classes.progressCircleBackground} cx="60" cy="60" r={circleRadius} />
        <circle
          className={classes.progressCircleProgress}
          cx="60"
          cy="60"
          r={circleRadius}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <XIcon className={classes.iconCross} />
    </div>
  );
};

export default ProgressSpinner;
