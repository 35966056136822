import React, { FunctionComponent, useRef } from 'react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartConfiguration,
  ChartEvent,
  ChartType,
  InteractionItem,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import { ChartJSOrUndefined, ForwardedRef } from 'react-chartjs-2/dist/types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  BarController,
  BarElement,
  ArcElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

export type DiagramConfiguration = Pick<
  ChartConfiguration<ChartType>,
  'type' | 'options' | 'data' | 'plugins'
> & {
  onClick?: (points: InteractionItem[]) => void;
  onHover?: (points: InteractionItem[]) => void;
};

export interface IDatasetsData {
  hidden: boolean;
  label: string;
  data: Array<number | null>;
  backgroundColor: string;
  hoverBackgroundColor: string;
  borderColor: string;
  numberId: number;
}

export const Diagram: FunctionComponent<DiagramConfiguration> = ({
  type,
  options = {},
  data,
  plugins,
  onClick,
  onHover,
}) => {
  const chartRef = useRef<ForwardedRef<ChartJSOrUndefined>>(null);

  const getNearestPoints = (event: ChartEvent): InteractionItem[] => {
    if (chartRef?.current) {
      const chart = chartRef?.current as unknown as ChartJS;
      if (chart && event.native) {
        return chart?.getElementsAtEventForMode(event.native, 'nearest', { intersect: true }, true);
      }
    }
    return [];
  };

  // eslint-disable-next-line no-param-reassign
  options.onClick = (event: ChartEvent) => {
    const points = getNearestPoints(event);
    if (onClick) {
      onClick(points);
    }
  };

  // eslint-disable-next-line no-param-reassign
  options.onHover = (event: ChartEvent) => {
    const target = event?.native?.target as HTMLElement;
    const points = getNearestPoints(event);
    if (onHover) {
      onHover(points);
    }
    if (points.length > 0) {
      target.style.cursor = 'pointer';
      return;
    }
    if (target?.style) {
      target.style.cursor = 'default';
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore incompatible 'ref' type
  return <Chart type={type} ref={chartRef} options={options} data={data} plugins={plugins} />;
};

export default Diagram;
