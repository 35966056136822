import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useHighlightedStyles = makeStyles(
  () => ({
    root: {
      display: 'block',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
