import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useNotificationsStyles = makeStyles(
  ({ color: { primary, secondary, tertiary, danger, base, links } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    actions: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0em 1em 0.7em',
      position: 'sticky',
      top: 0,
      zIndex: 9,
      background: base,
    },
    actionButton: {
      color: 'inherit',
      height: '2em',
      width: '2em',
      padding: 'initial',
      '&:not(:first-child)': {
        marginLeft: '1em',
      },
    },
    body: {
      position: 'relative',
      display: 'flex',
      height: '100%',
    },
    confirmPopupDialog: {
      width: '22.5em',
    },
    confirmPopupControls: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    confirmPopupText: {
      paddingBottom: '1.5em',
    },
    headFilterIcon: {
      width: '1.5em',
      background: tertiary[50],
      height: '2.25em',
      color: tertiary[400],
      padding: '0 1.85em',
      lineHeight: 0,
      textAlign: 'end',
      borderBottom: '1px solid #fff',
    },
    span: {
      whiteSpace: 'pre-wrap',
    },
    rowRootIcons: {
      verticalAlign: 'baseline',
    },
    iconsContainer: {
      height: '1.25em',
      padding: '0.5em 0',
      width: '3em',
      '&:first-child': {
        paddingTop: '0',
      },
    },
    alertContainer: {
      display: 'flex',
      height: '100%',
      verticalAlign: 'top',
      margin: '1.55em 0 0',
    },
    iconMargin: {
      marginTop: '2px',
      marginRight: '1em',
      minWidth: '1em',
    },
    receiverContainer: {
      display: 'flex',
      width: '100%',
      textOverflow: 'ellipsis',
      padding: '0.5em 0',
      '&:first-child': {
        paddingTop: '0',
      },
    },
    sortingTypeSms: {
      order: 1,
    },
    sortingTypeEmail: {
      order: 2,
    },
    textCell: {
      marginLeft: '1em',
    },
    arrowsContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5em',
      height: '1.25em',
      justifyContent: 'space-evenly',
      '&:first-child': {
        paddingTop: '0',
      },
    },
    breadcrumbs: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      padding: '0 1.5em',
    },
    arrow: {
      transform: 'rotate(180deg)',
      color: tertiary[400],
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > *': {
        marginLeft: '1em',
      },
    },
    addButton: {
      height: '2em',
      width: '14em',
    },
    dialog: {
      padding: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogPaper: {
      maxWidth: '21em',
      width: '21em',
    },
    defaultIcon: {
      color: tertiary[400],
      zIndex: 3,
      '&:hover': {
        color: primary[700],
        cursor: 'pointer',
      },
    },
    danger: {
      color: danger[600],
    },
    secondary: {
      color: secondary[600],
    },
    primary: {
      color: primary[700],
    },
    filterActive: {
      color: primary[600],
    },
    links: {
      color: links[600],
    },
    dialogContent: {
      padding: '0',
      textAlign: 'center',
    },
    dialogTitle: {
      fontWeight: 'bold',
      lineHeight: '150%',
      textAlign: 'center',
      padding: '2em 0 1em',
    },
    dialogActions: {
      padding: '1.5em 2em 2em',
      justifyContent: 'center',
    },
    confirmButton: {
      width: '60%',
    },
    checkboxControl: {
      margin: '0.375em 0',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      width: '100%',
      overflow: 'auto',
    },
    SmsIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
