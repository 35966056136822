import React from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { DocumentModel } from '@/client/generated/graphql';

export const DocumentsNumberCell = ({ item }: ICellProps<DocumentModel>) => {
  if (item) {
    return (
      <Typography color={'tertiary600'} type={'text3'}>
        {item.number}
      </Typography>
    );
  }

  return <div />;
};
