import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';
import ruRU from './translations/ru/translation.json';
import enUS from './translations/en/translation.json';

export const defaultNS = 'translation' as const;

export const resources = {
  ru: { translation: ruRU },
  en: { translation: enUS },
} as const;

i18next
  // .use(XHR)
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    detection: {
      lookupFromPathIndex: 0,
      lookupQuerystring: 'lng',
    },
    react: {},
    fallbackLng: process.env.DEFAULT_LANGUAGE || 'ru',
    ns: ['translation'],
    defaultNS,
    interpolation: {
      skipOnVariables: false,
    },
    nsSeparator: ':', // namespace separator
    keySeparator: '.', // key separator
    saveMissing: false,
    debug: false,
    lng: process.env.DEFAULT_LANGUAGE || 'ru',
    resources,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseMissingKeyHandler: (missing: any) => {
      if (process.env.NODE_ENV === 'development' && missing) {
        // eslint-disable-next-line
        console.warn('MISSING TRANSLATION:', missing);
      }
      return missing;
    },
  });

export { i18next as i18nClient };
