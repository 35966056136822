import { CSSProperties } from 'react';

const textStyleConstants = {
  // Форматирование текста
  textToUpperCase: {
    textTransform: 'uppercase' as CSSProperties['textTransform'],
  },
  textToLowerCase: {
    textTransform: 'lowercase' as CSSProperties['textTransform'],
  },
  textToCapitalize: {
    textTransform: 'capitalize' as CSSProperties['textTransform'],
  },
  textOverflow: {
    overflow: 'hidden' as CSSProperties['overflow'],
    textOverflow: 'ellipsis' as CSSProperties['textOverflow'],
    whiteSpace: 'nowrap' as CSSProperties['whiteSpace'],
  },
  textAlignCenter: {
    textAlign: 'center' as CSSProperties['textAlign'],
  },
  textAlignLeft: {
    textAlign: 'left' as CSSProperties['textAlign'],
  },
  textAlignRight: {
    textAlign: 'right' as CSSProperties['textAlign'],
  },
  textUnderline: {
    textDecoration: 'underline' as CSSProperties['textDecoration'],
  },
  textNoneDecoration: {
    textDecoration: 'none' as CSSProperties['textDecoration'],
  },
  searchSpan: {
    whiteSpace: 'nowrap' as CSSProperties['whiteSpace'],
    overflow: 'hidden' as CSSProperties['overflow'],
    textOverflow: 'ellipsis' as CSSProperties['textOverflow'],
  },
  notCopiedText: {
    userSelect: 'none' as CSSProperties['userSelect'],
  },
};

export default textStyleConstants;
