import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@shared/components/Typography';
import { setWideSidebarState } from '@components/client/client';
import { ArrowMinimizeIcon, ArrowMaximizeIcon } from '@shared/assets/images/icons';
import { useSidebarStyles } from './Sidebar.styles';
import { getStorageItem, setStorageItem } from './Sidebar.helpers';
import { IRoute } from '../Routes';
import { ISidebarItemProps } from './Sidebar.interfaces';

export interface ISidebarProps {
  items: IRoute[];
  LogoHorizontal?: ReactNode;
  LogoVertical?: ReactNode;
  ItemRender: (props: ISidebarItemProps) => JSX.Element;
  expandable?: boolean;
}

export const Sidebar: FunctionComponent<ISidebarProps> = ({
  items = [],
  ItemRender,
  expandable,
  LogoVertical,
  LogoHorizontal,
}) => {
  const [open, setOpen] = useState(false);
  const [translate] = useTranslation();
  const [pinned, setPinned] = useState(
    !!getStorageItem<{ isSidebarPinned?: boolean }>('settings')?.isSidebarPinned
  );
  const [openTimeout, setOpenTimeout] = useState<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );
  const classes = useSidebarStyles({ open });
  const isMinPageSize = useMediaQuery('(min-width:1024px)', { noSsr: true });
  const isNormalPageSize = useMediaQuery('(min-width:1440px)', { noSsr: true });
  const isWide = isNormalPageSize && !pinned;
  const expanded = expandable && ((open && !pinned) || isWide);

  useEffect(() => {
    if (isNormalPageSize) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isNormalPageSize]);

  useEffect(() => {
    if (!expanded) setOpen(false);
  }, [expanded]);

  useEffect(() => {
    setWideSidebarState(isWide);
  }, [isWide]);

  const handlePinToggle = () => {
    const isSidebarPinned = !!getStorageItem<{ isSidebarPinned?: boolean }>('settings')
      ?.isSidebarPinned;
    setStorageItem<{ isSidebarPinned?: boolean }>('settings', {
      isSidebarPinned: !isSidebarPinned,
    });
    setPinned(!isSidebarPinned);
    if (isSidebarPinned) {
      setOpen(true);
    }
  };

  const handleDrawerOpen = () => {
    if (isMinPageSize && !isNormalPageSize && !pinned) {
      const t = setTimeout(() => setOpen(true), 1000);
      setOpenTimeout(t);
    }
  };

  const handleDrawerClose = () => {
    if (openTimeout) {
      if (!isNormalPageSize) setOpen(false);
      clearTimeout(openTimeout);
    }
    if (!isNormalPageSize) setOpen(false);
  };

  const renderItem = ({ path, ...routeProps }: IRoute & { expanded?: boolean }) => (
    <ItemRender key={path} path={path} expanded={expanded} {...routeProps} />
  );

  const renderLogo = () => {
    if (expanded && LogoHorizontal) return LogoHorizontal;
    if (LogoVertical) return LogoVertical;
    return <div />;
  };

  return (
    <Drawer
      variant={'permanent'}
      className={clsx(classes.drawer, {
        [classes.drawerOpen!]: expanded,
        [classes.drawerClose!]: !expanded,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen!]: expanded,
          [classes.drawerClose!]: !expanded,
        }),
      }}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
    >
      <button
        className={clsx(
          classes.logoButton,
          expanded ? classes.logoExpanded : classes.logoCollapsed
        )}
        onClick={() => window.open('https://gravitel.ru')}
        type="button"
      >
        {renderLogo()}
      </button>
      <div className={classes.toolbar}>
        <List>{items.map(renderItem)}</List>
      </div>
      {isMinPageSize && expandable && (
        <ListItem
          button
          classes={{
            gutters: clsx({ [classes.gutters!]: !isNormalPageSize }),
            root: classes.listItem,
          }}
          onClick={handlePinToggle}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            {pinned ? (
              <ArrowMaximizeIcon className={classes.icon} />
            ) : (
              <ArrowMinimizeIcon className={classes.icon} />
            )}
          </ListItemIcon>
          <ListItemText
            disableTypography
            classes={{
              root: clsx(classes.listItemText, { [classes.listItemTextActive!]: expanded }),
            }}
          >
            <Typography type={'text3'} color={'inherit'}>
              {translate('COLLAPSE_SIDEBAR')}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
    </Drawer>
  );
};

export default Sidebar;
