import { makeStyles } from '@material-ui/core/styles';
import signBg from './images/main-background.png';

export const AuthStyle = makeStyles(
  ({ color: { tertiary, base }, breakpoints: { down } }) => ({
    authContent: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      [down('sm')]: {
        backgroundColor: base,
        display: 'flex',
        justifyContent: 'center',
      },
    },
    root: {
      display: 'flex',
      height: '100%',
    },
    image: {
      flex: 'auto',
      boxSizing: 'border-box',
      padding: '2em 2.5em',
      textAlign: 'left',
      backgroundColor: tertiary[50],
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    smallScreenIcon: {
      position: 'absolute',
      top: '1em',
      left: '2em',
      width: '11em',
      height: '2.5em',
      margin: '1em 0 2em',
    },
    signBackground: {
      backgroundImage: `url(${signBg})`,
    },
    side: {
      width: '34em',
      maxWidth: '34em',
      minWidth: '34em',
      flex: '0 0 34em',
      boxSizing: 'border-box',
      padding: '2em 3em',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: base,
      [down('sm')]: {
        padding: '1em 1.5em',
      },
    },
    helmet: {
      height: '2em',
      flex: '0 0 2em',
      textAlign: 'right',
    },
    content: {
      display: 'flex',
      flex: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  {
    index: 1,
  }
);
