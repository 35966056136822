import React, { useEffect } from 'react';
import { useRoutes } from '@components/Routes';
import { privateRoutesPropsMap } from '@/routes';

type Props = {
  children: React.ReactNode;
};

type YmFunc = {
  (yaMetrikaUrl: string, action: string, params: string | Record<string, boolean>): void;

  l?: number;
  a?: unknown[];
};

const windowYm = window as typeof window & {
  ym: YmFunc;
};

export const YAMetrika = ({ children }: Props) => {
  const { route = {}, path = {} } = useRoutes(privateRoutesPropsMap);
  // const yaId = parseInt(process.env.YA_METRIKA ||'', 10);

  const ym = (action: string, params: string | Record<string, boolean>) => {
    if (typeof windowYm.ym !== 'function' || !process.env.YA_METRIKA) {
      return;
    }
    windowYm.ym(process.env.YA_METRIKA, action, params);
  };

  useEffect(() => {
    let strPath = '/';
    if (path.category) {
      strPath += path.category;
    }
    if (path.subcategory) {
      strPath += `/${path.subcategory}`;
    }
    if (path.identifier) {
      strPath += path.identifier === 'add' ? '/add' : '/edit';
    }
    ym('hit', strPath);
  }, [route, path]);

  const init = () => {
    ym('init', {
      clickmap: true,
      trackLinks: true,
      trackHash: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  };

  useEffect(() => {
    if (!process.env.YA_METRIKA) return;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://mc.yandex.ru/metrika/tag.js';
    script.onload = () => init();
    // eslint-disable-next-line func-names
    windowYm.ym = function (...args) {
      (windowYm.ym.a = windowYm.ym.a || []).push(...args);
      windowYm.ym.l = new Date().valueOf();
    };
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript?.parentNode?.insertBefore(script, firstScript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default YAMetrika;
