const widthStyleConstants = {
  // Размеры элементов по ширине
  defaultElementWidthHalf: {
    width: '50%',
  },
  defaultElementWidthFull: {
    width: '100%',
  },
  defaultElementWidth3: {
    width: '3em',
  },
  defaultElementWidth4: {
    width: '4em',
  },
  defaultElementWidth5: {
    width: '5em',
  },
  defaultElementWidth6: {
    width: '6em',
  },
  defaultElementWidth7: {
    width: '7em',
  },
  defaultElementWidth8: {
    width: '8em',
  },
  defaultElementWidth9: {
    width: '9em',
  },
  defaultElementWidth10: {
    width: '10em',
  },
  defaultElementWidth11: {
    width: '11em',
  },
  defaultElementWidth12: {
    width: '12em',
  },
  defaultElementWidth13: {
    width: '13em',
  },
  defaultElementWidth14: {
    width: '14em',
  },
  defaultElementWidth15: {
    width: '15em',
  },
  defaultElementWidth16: {
    width: '16em',
  },
  defaultElementWidth17: {
    width: '17em',
  },
  defaultElementWidth18: {
    width: '18em',
  },
  defaultElementWidth19: {
    width: '19em',
  },
  defaultElementWidth20: {
    width: '20em',
  },
  defaultElementWidth21: {
    width: '21em',
  },
  defaultElementWidth22: {
    width: '22em',
  },
  defaultElementWidth24: {
    width: '24em',
  },
  defaultElementWidth25: {
    width: '25em',
  },
  defaultElementWidth26: {
    width: '26em',
  },
  defaultElementWidth32: {
    width: '32em',
  },
  defaultElementWidth33: {
    width: '33em',
  },
  defaultElementWidth40: {
    width: '40em',
  },
  defaultElementWidth44: {
    width: '44em',
  },
  defaultElementWidth50: {
    width: '50em',
  },
  defaultElementWidth53: {
    width: '53em',
  },
  defaultElementWidth70: {
    width: '70em',
  },
};

export default widthStyleConstants;
