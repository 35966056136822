import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Typography from '@shared/components/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Drawer from '@components/Drawer';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import { XIcon, PlayIcon } from '@shared/assets/images/icons';
import {
  BUY_INDIVIDUAL_ASSISTANT_MUTATION,
  BUY_MARKET_ASSISTANT_MUTATION,
} from '@/client/mutations';
import { BALANCE_QUERY } from '@/client/queries';
import MessageDialog from '@shared/components/MessageDialog';
import { toPrecision } from '@components/utils';
import RadioGroup from '@shared/components/RadioGroup';
import Translate from '@shared/components/Translate';
import clsx from 'clsx';
import { getMonthRatio } from '@/utils/getMonthRatio';
import { BASIC_SCENARIOS, IScenarioContent } from '@/../typings/basicScenarios';
import { scenarioTypes } from '@components/typings/interfaces';
import ControlButtons from '@shared/components/ControlButtons';
import BottomButtons from '@/layouts/BottomButtons';
import BodyContainer from '@/layouts/BodyContainer';
import { useScenariosStyle } from './Scenarios.styles';
import { scenarioList } from './Scenarios.constants';

export const AddScenario: FunctionComponent = () => {
  const classes = useScenariosStyle();
  const formMethods = useForm();
  const { watch } = formMethods;
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [isExampleDrawerOpen, setIsExampleDrawerOpen] = useState(false);
  const [activePlayerIndex, setActivePlayerIndex] = useState<number | undefined>(undefined);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [schemaIndex, setSchemaIndex] = useState<null | number>(null);
  const [showErrorDialog, setShowErrorDialog] = useState({
    showDialog: false,
    title: '',
    message: '',
  });
  const [buyMarketAssistant, { loading: loadingBuyMarketAssistant }] = useMutation(
    BUY_MARKET_ASSISTANT_MUTATION
  );
  const [buyIndividualAssistant] = useMutation(BUY_INDIVIDUAL_ASSISTANT_MUTATION);

  const [getCanBoughtNumber, { data: balanceData }] = useLazyQuery(BALANCE_QUERY, {
    onCompleted: () => {
      setModalIsOpen(true);
    },
    onError: () => {
      setShowErrorDialog({
        showDialog: true,
        title: 'SOMETHING_WENT_WRONG',
        message: 'SOMETHING_WENT_WRONG',
      });
    },
    fetchPolicy: 'network-only',
  });

  const userBalance = balanceData?.getBalance?.balance;
  const assistantType = watch('assistantType');
  const isMarketAssistant = assistantType === scenarioTypes.Base;
  const isIndividualAssistant = assistantType === scenarioTypes.Individual;

  const scenarioPrice = useMemo(() => {
    const filteredAssistant = scenarioList.find((scenario) => scenario.type === assistantType);
    if (filteredAssistant?.type === scenarioTypes.Base) {
      return (
        filteredAssistant.monthPrice + (filteredAssistant.installationPrice || 0) * getMonthRatio()
      );
    }
    return 0;
  }, [assistantType]);
  const canPay = useMemo(() => userBalance >= scenarioPrice, [scenarioPrice, userBalance]);
  const restCost = useMemo(() => userBalance - scenarioPrice, [scenarioPrice, userBalance]);

  function handleExampleStartPlay(index: number) {
    setActivePlayerIndex(index);
  }

  function handleCloseDrawer() {
    if (activePlayerIndex !== undefined) {
      setActivePlayerIndex(undefined);
    }
    setIsExampleDrawerOpen(false);
  }

  function handleCloseSchemaDialog() {
    setSchemaIndex(null);
  }

  const handleFormSubmit = useCallback(() => {
    if (isMarketAssistant) {
      buyMarketAssistant({
        variables: {
          data: {
            marketAssistantId: 1,
          },
        },
      }).then(() => {
        navigate('/scenarios-list');
      });
    }
    if (isIndividualAssistant) {
      buyIndividualAssistant();
    }
  }, [
    buyIndividualAssistant,
    buyMarketAssistant,
    isIndividualAssistant,
    isMarketAssistant,
    navigate,
  ]);

  const handleCloseErrorDialog = useCallback(() => {
    setShowErrorDialog({
      showDialog: false,
      title: '',
      message: '',
    });
  }, [setShowErrorDialog]);

  function handleOpenDialog() {
    if (isExampleDrawerOpen) {
      handleCloseDrawer();
    }
    if (isIndividualAssistant) {
      handleFormSubmit();
      setModalIsOpen(true);
    } else {
      getCanBoughtNumber();
    }
  }

  function handleCloseDialog() {
    setModalIsOpen(false);
  }

  function returnModalTitle() {
    if (isMarketAssistant && canPay) {
      return translate('PAY_FOR_SCENARIO');
    }
    if (isMarketAssistant && !canPay) {
      return translate('NUMBER_BOUGHT_TITLE_NO_MONEY');
    }
    if (isIndividualAssistant) {
      return translate('APPLICATION_SENT');
    }
    return '';
  }

  const renderBuyScenarioDialogBody = () => (
    <>
      <Translate
        i18nKey="BUY_MARKET_SCENARIO"
        values={{
          totalCost: toPrecision(`${scenarioPrice}`),
          restCost: toPrecision(`${restCost}`),
        }}
        components={{
          t: <Typography type={'text3'} color={'tertiary900'} />,
          b: <Typography type={'default'} color={'primary700'} />,
        }}
      />
      <ControlButtons
        confirmTitle={'CONFIRM'}
        cancelTitle={'CANCEL'}
        cancelVariant="secondary"
        onConfirmClick={handleFormSubmit}
        onCancelClick={handleCloseDialog}
        loading={loadingBuyMarketAssistant}
        rootClass={classes.actionsContainer}
        justifyContent="start"
        flexDirection="row-reverse"
        small
      />
    </>
  );

  const renderNotEnoughFundsDialogBody = () => (
    <>
      <Translate
        i18nKey="BUY_MARKET_SCENARIO_ERROR"
        components={{
          t: <Typography type={'text3'} color={'tertiary900'} />,
          l: <Typography type={'text3'} color={'primary700'} />,
          b: <br />,
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
          n: <a rel={'noreferrer'} href={'/cabinet/balance'} className={classes.aFontSize} />,
        }}
      />
      <div className={classes.actionsContainer}>
        <Button title={translate('CLOSE')} onClick={() => setModalIsOpen(false)} smallHeight />
      </div>
    </>
  );

  const renderBuyIndividualScenarioDialogBody = () => (
    <>
      <Typography type={'text3'} color={'tertiary900'}>
        {translate('MANAGER_WILL_CONTACT')}
      </Typography>
      <div className={classes.actionsContainer}>
        <Button
          title={translate('CLOSE')}
          onClick={() => navigate('/scenarios-list')}
          smallHeight
        />
      </div>
    </>
  );

  const renderModalContent = () => {
    if (isMarketAssistant && canPay) {
      return renderBuyScenarioDialogBody();
    }
    if (isMarketAssistant && !canPay) {
      return renderNotEnoughFundsDialogBody();
    }
    if (isIndividualAssistant) {
      return renderBuyIndividualScenarioDialogBody();
    }
    return null;
  };

  const renderExampleScenariosCard = (el: IScenarioContent, index: number) => (
    <div className={classes.scenarioExampleCard} key={`scenarioCard-${index}`}>
      <div className={classes.scenarioExampleCardBlock}>
        <div className={classes.scenarioExampleCardTextContainer}>
          <div className={classes.scenarioExampleCardTitle}>
            <Typography type={'text3'} color={'tertiary900'} bold>
              {translate(el.title)}
            </Typography>
          </div>
          <Typography
            className={classes.scenarioExampleCardDescription}
            type={'text4'}
            color={'tertiary900'}
          >
            {translate(el.description)}
          </Typography>
        </div>
      </div>
      <div className={classes.scenarioExampleCardControlsContainer}>
        {activePlayerIndex !== index ? (
          <div className={classes.scenarioExampleCardRowContent}>
            <div className={classes.scenarioExampleCardButtonsBlock}>
              <div className={classes.scenarioExampleCardBtnWrapper}>
                <Button
                  size={'small'}
                  variant={'secondary'}
                  className={classes.audioListenSmallControlButton}
                  onClick={() => handleExampleStartPlay(index)}
                >
                  <PlayIcon />
                </Button>
                <Button
                  variant={'secondary'}
                  title={translate('SCHEMA')}
                  className={classes.schemaControlButton}
                  onClick={() => {
                    setSchemaIndex(index);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.scenarioExampleCardRowContent}>
            <AudioPlayer
              source={el.audio}
              isPaused={false}
              isExpanded
              collapseOnEnd={false}
              side={'left'}
              autoPlay
            />
            <XIcon className={classes.closeIcon} onClick={() => setActivePlayerIndex(undefined)} />
          </div>
        )}
      </div>
    </div>
  );

  const renderScenarioCards = () => {
    if (scenarioList) {
      return scenarioList.map((card, index) => {
        const isActiveCard = assistantType === card.type;
        return (
          <div
            key={`scenarioCard${index}`}
            className={clsx(classes.scenarioCard, { [classes.scenarioCardActive]: isActiveCard })}
          >
            <div className={classes.scenarioCardContainer}>
              <div className={classes.scenarioCardLeftSide}>
                <Typography
                  className={classes.scenarioCardTitle}
                  type={'text2'}
                  color={'tertiary900'}
                  bold
                >
                  {translate(card.title)}
                </Typography>
                <Typography type={'text3'} color={'tertiary600'}>
                  {translate(card.description)}
                </Typography>
              </div>
              <div className={classes.scenarioCardRightSide}>
                {card.type === scenarioTypes.Base ? (
                  <>
                    <div className={classes.scenarioCardFeeBlock}>
                      <Typography type={'text4'} color={'tertiary500'}>
                        {translate('INST_WORKS')}
                      </Typography>
                      <Typography type={'text4'} color={'tertiary900'} medium>
                        {`${card.installationPrice} ₽`}
                      </Typography>
                    </div>
                    <div className={classes.scenarioCardFeeBlock}>
                      <Typography type={'text4'} color={'tertiary500'}>
                        {translate('MONTH_PRICE')}
                      </Typography>
                      <Typography type={'text4'} color={'tertiary900'} medium>
                        {`${card.monthPrice} ₽/м.`}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <div className={classes.scenarioCardFeeBlock}>
                    <Typography type={'text4'} color={'tertiary500'}>
                      {translate('SCENARIO_ONCE_COST')}
                    </Typography>
                    <Typography type={'text4'} color={'tertiary900'} medium>
                      {`${translate('OF')} ${card.monthPrice} ₽`}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.scenarioCardControlsContainer}>
              {card.type === scenarioTypes.Base ? (
                <>
                  <FormControlLabel
                    className={clsx(
                      classes.btnScenarioChoose,
                      isMarketAssistant && classes.activeBackground
                    )}
                    value={scenarioTypes.Base}
                    control={<Radio className={classes.btnScenarioRadio} color={'secondary'} />}
                    label={translate('CHOOSE')}
                    onClick={() => isExampleDrawerOpen && handleCloseDrawer()}
                  />
                  <Button
                    variant={'secondary'}
                    title={translate('EXAMPLES')}
                    classes={{ root: classes.btnScenarioExampleWidth }}
                    onClick={() => setIsExampleDrawerOpen(true)}
                  />
                </>
              ) : (
                <FormControlLabel
                  className={clsx(
                    classes.btnScenarioApplication,
                    isIndividualAssistant && classes.activeBackground
                  )}
                  value={scenarioTypes.Individual}
                  control={<Radio className={classes.btnScenarioRadio} color={'secondary'} />}
                  label={translate('SUBMIT_APPLICATION')}
                  onClick={() => isExampleDrawerOpen && handleCloseDrawer()}
                />
              )}
            </div>
          </div>
        );
      });
    }
    return null;
  };

  const renderExampleDrawer = () => (
    <Drawer
      wrapperClass={classes.drawerWrapper}
      contentClass={classes.drawerInternalContent}
      footerClass={classes.drawerFooter}
      title={'EXAMPLES'}
      variant={'persistent'}
      open={isExampleDrawerOpen}
      size={'md'}
      primaryButton={{
        title: 'CLOSE',
        onClick: handleCloseDrawer,
      }}
      onClose={handleCloseDrawer}
    >
      {BASIC_SCENARIOS.map(renderExampleScenariosCard)}
    </Drawer>
  );

  const renderErrorDialog = () => (
    <MessageDialog
      isOpen={showErrorDialog.showDialog}
      title={translate(showErrorDialog.title)}
      message={translate(showErrorDialog.message)}
      onCancel={handleCloseErrorDialog}
      renderControls={
        <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
      }
    />
  );

  const renderExamplesScenarioContent = () => (
    <div className={classes.modalScenarioDialog}>
      <div className={classes.modalScenarioImageOverlay}>
        <img
          src={BASIC_SCENARIOS[schemaIndex || 0].schema}
          alt={BASIC_SCENARIOS[schemaIndex || 0].title}
        />
      </div>
      <Button title={translate('CLOSE')} onClick={handleCloseSchemaDialog} />
    </div>
  );

  return (
    <BodyContainer disableOverflow>
      <div className={classes.root}>
        <FormProvider {...formMethods}>
          <div className={classes.contentContainer}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('CHOOSE_SCENARIO_TITLE')}
            </Typography>
            <RadioGroup defaultValue={'none'} name={'assistantType'}>
              <div className={classes.formContent}>{renderScenarioCards()}</div>
            </RadioGroup>
            <div className={classes.addScenarioActions}>
              <BottomButtons>
                <ControlButtons
                  justifyContent={'start'}
                  confirmTitle={'CONFIRM'}
                  cancelTitle={'CANCEL'}
                  cancelVariant={'text'}
                  cancelUnderline
                  onConfirmClick={handleOpenDialog}
                  onCancelClick={() => navigate('/scenarios-list')}
                  confirmDisable={!isMarketAssistant && !isIndividualAssistant}
                />
              </BottomButtons>
            </div>
          </div>
        </FormProvider>
      </div>
      {renderExampleDrawer()}
      <MessageDialog
        isOpen={schemaIndex !== null}
        title={`${translate('SCENARIO_SCHEMA')}: ${translate(
          BASIC_SCENARIOS[schemaIndex || 0].title
        )}`}
        contentClass={classes.modalContentContainer}
        onCancel={handleCloseSchemaDialog}
        renderContent={renderExamplesScenarioContent()}
        disableEnforceFocus
        paperClass={classes.dialogPaper}
      />
      <MessageDialog
        isOpen={isModalOpen}
        contentClass={isMarketAssistant ? classes.marketDialog : classes.individualDialog}
        onCancel={handleCloseDialog}
        title={returnModalTitle()}
        renderContent={<FormProvider {...formMethods}>{renderModalContent()}</FormProvider>}
      />
      {showErrorDialog.showDialog && renderErrorDialog()}
    </BodyContainer>
  );
};
