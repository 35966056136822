import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { ProfileStyles } from '../Profile.styles';
import { IProfileCard } from '../ProfileFormInterfaces';

export const ProfileCard: FunctionComponent<IProfileCard> = ({ icon, title, content }) => {
  const classes = ProfileStyles();
  const [translate] = useTranslation();

  return (
    <Flex className={classes.profileCard} direction={'column'}>
      <Flex className={classes.profileCardHeader} alignItems={'center'}>
        {icon}
        <Typography
          className={classes.profileSmallCardTitle}
          type={'text2'}
          color={'tertiary900'}
          bold
        >
          {translate(title)}
        </Typography>
      </Flex>
      {content}
    </Flex>
  );
};
