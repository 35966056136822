import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useBalanceDialogStyle = makeStyles(
  ({ color: { links } }) => ({
    dialog: {
      width: '21em',
    },
    promiseDialog: {
      width: '23em',
    },
    promiseErrorDialog: {
      width: '25em',
    },
    successDialog: {
      width: '20em',
    },
    promisePaymentButtons: {
      paddingTop: '1.5em',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '0.5em',
    },
    cardsCell: {
      maxWidth: '50%',
    },
    card: {
      height: '2em',
      width: 'fit-content',
    },
    balanceTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '0.5em',
      flexWrap: 'nowrap',
      '& > span': {
        paddingBottom: '0.5em',
      },
    },
    buttons: {
      paddingTop: '1.5em',
    },
    centerBlock: {
      paddingBottom: '1em',
    },
    btnBlock: {
      textAlign: 'right',
    },
    btnSimpleBlock: {
      paddingTop: '1em',
      textAlign: 'right',
    },
    text: {
      paddingBottom: '1.5em',
    },
    link: {
      textDecoration: 'none',
    },
    linkText: {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    bankingDetails: {
      borderRadius: '0.5em',
      backgroundColor: links[50],
      margin: '0.5em 0',
      padding: '0.5em 1em',
    },
    chevron: {
      marginLeft: '0.5em',
      color: links[600],
    },
    detailsRow: {
      gap: '0.75em',
      paddingTop: '0.25em',
      '&:last-child $detailsData': {
        borderBottom: 'none',
      },
    },
    detailsCaption: {
      width: '5.75em',
    },
    detailsData: {
      width: '12.5em',
      borderBottom: `1px solid ${links[200]}`,
      paddingBottom: '0.25em',
    },
    iconsContainer: {
      alignItems: 'center',
      gap: '1em',
    },
    sbpIcon: {
      maxWidth: '3.25em',
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);
