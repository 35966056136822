export enum ModalType {
  Reserved = 'Reserved',
  Developing = 'Developing',
  PreDeletedBought = 'PreDeletedBought',
  PreDeletedFrozen = 'PreDeletedFrozen',
  PreFreeze = 'PreFreeze',
  Frozen = 'Frozen',
  BoughtToFreeze = 'BoughtToFreeze',
  BoughtToDelete = 'BoughtToDelete',
  Error = 'Error',
  Closed = 'Closed',
}
