export const setUploadFieldProgress = (
  loaded: number,
  total: number,
  setProgress: unknown,
  name: string
) => {
  const percents = Number(((loaded / total) * 100).toFixed(0));
  if (typeof setProgress === 'function') {
    setProgress(name, Number.isNaN(percents) ? 0 : percents);
  }
};
