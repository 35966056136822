import { ChevronDownIcon, ChevronUpIcon } from '@shared/assets/images/icons';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import ruLocale from 'date-fns/locale/ru';
import { USER_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import CheckboxField from '@shared/components/CheckboxField';
import MaskedField from '@shared/components/MaskedField';
import { timeToNumber } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { TextField } from '@material-ui/core';
import { DateRange, LocalizationProvider, StaticDateRangePicker } from '@material-ui/pickers';
import ControlButtons from '@shared/components/ControlButtons';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
// eslint-disable-next-line import/no-duplicates
import { endOfDay, format, isValid, startOfDay } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { dateFormat } from './PeriodSelect.constants';
import { IPeriodItemKey, IPeriodProps, IPeriodState } from './PeriodSelect.interfaces';
import { usePeriodStyle } from './PeriodSelect.styles';

export default function PeriodSelect<T>({
  datePeriod,
  periodName,
  onPeriodChange,
  position,
  periodList,
  selectTime,
  time,
}: IPeriodProps<T>) {
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const minDate = getCurrentDomain(userData?.user)?.createdAt;
  const classes = usePeriodStyle();
  const [translate] = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isPeriodsReload, setIsPeriodReload] = useState(false);
  const now = new Date();
  const startDay = startOfDay(now);
  const currentDay = endOfDay(now);

  const formMethods = useForm({
    defaultValues: {
      period: periodName,
      datePeriod: '',
      byTime: false,
      fromDate: datePeriod ? format(datePeriod.from, dateFormat) : format(startDay, dateFormat),
      toDate: datePeriod ? format(datePeriod.to, dateFormat) : format(currentDay, dateFormat),
      fromTime: '00:00',
      toTime: '23:59',
    },
  });

  const [state, setState] = useState<IPeriodState>({
    range: [datePeriod?.from || startDay, datePeriod?.to || currentDay],
    period: periodName || 'today',
  });
  const { setValue, setError, clearErrors, watch } = formMethods;
  const isByTimeOn = watch('byTime');
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');
  const fromTime = watch('fromTime');
  const toTime = watch('toTime');

  const dateMaskChars = (dateValue: string) => {
    let [m, d] = '[0-9]';
    if (dateValue.length) {
      m = dateValue[0] === '3' ? '[0-1]' : '[0-9]';
      d = dateValue[3] === '1' ? '[0-2]' : '[1-9]';
    }
    return { '0': '[0]', '1': '[0-1]', '2': '[2]', '3': '[0-3]', '4': m, '5': d, '9': '[0-9]' };
  };

  const formatChars = (fieldValue: string) => {
    const h = fieldValue.length && fieldValue[0] === '2' ? '[0-3]' : '[0-9]';
    return { '2': '[0-2]', '3': h, '5': '[0-5]', '9': '[0-9]' };
  };

  const isTimeError = useMemo(
    () => timeToNumber(fromTime) > timeToNumber(toTime) && fromDate === toDate,
    [fromTime, toTime, fromDate, toDate]
  );

  const selectOptionsList = useMemo(
    () =>
      Object.values(periodList).map((item: IPeriodItemKey<T>) => ({
        value: item.value,
        titleCode: item.title,
      })),
    [periodList]
  );

  const computedClasses = clsx(classes.periodWindow, {
    [classes.periodWindowPositionDefault]: !position,
    [classes.periodWindowPositionRight]: position === 'right',
  });

  const disabledOptions = useMemo(() => {
    const result: Array<string> = [];

    if (!minDate) return result;

    const nowDateObject = new Date();
    const domainCreationDate = new Date(minDate);

    const [createDate, nowDate] = [domainCreationDate.getDate(), nowDateObject.getDate()];
    const [createMonth, nowMonth] = [domainCreationDate.getMonth(), nowDateObject.getMonth()];
    const [createYear, nowYear] = [domainCreationDate.getFullYear(), nowDateObject.getFullYear()];

    const yearAndMonthSame = createYear === nowYear && createMonth === nowMonth;

    if (yearAndMonthSame) result.push('pastMonth');
    if (yearAndMonthSame && createDate === nowDate) result.push('yesterday');

    return result;
  }, [minDate]);

  useEffect(() => {
    const { fromTime: sFromTime, toTime: sToTime } = time || {};
    if (sFromTime && sToTime && selectTime) {
      setValue('byTime', true);
      setValue('fromTime', sFromTime);
      setValue('toTime', sToTime);
    } else {
      setValue('byTime', false);
    }
  }, [setValue, time, selectTime]);

  useEffect(() => {
    if (selectTime) {
      if (isTimeError) {
        setError('fromTime', { type: 'error', message: '' });
        setError('toTime', { type: 'error', message: '' });
      } else {
        clearErrors();
      }
    }
  }, [clearErrors, isTimeError, setError, translate, selectTime]);

  useEffect(() => {
    if (periodName) {
      setIsPeriodReload(true);
    }
  }, [periodName]);

  useEffect(() => {
    if (isPeriodsReload) {
      setValue('period', periodName || 'today');
      setValue('datePeriod', translate(periodList[periodName || 'today'].title));
      setValue(
        'fromDate',
        datePeriod ? format(datePeriod.from, dateFormat) : format(startDay, dateFormat)
      );
      setValue(
        'toDate',
        datePeriod ? format(datePeriod.to, dateFormat) : format(currentDay, dateFormat)
      );
      setState({
        period: periodName || 'today',
        range: [datePeriod?.from || startDay, datePeriod?.to || currentDay],
      });
      setIsPeriodReload(false);
    }
  }, [
    datePeriod,
    currentDay,
    startDay,
    periodName,
    periodList,
    setValue,
    translate,
    isPeriodsReload,
  ]);

  function handleDateChange(e: ChangeEvent<HTMLInputElement> | undefined, dateRange: string) {
    const fieldValue = e?.target?.value;
    const isDirectionFrom = dateRange === 'from';
    if (fieldValue && !fieldValue.includes('_') && minDate) {
      const min = new Date(minDate);
      const max = new Date();
      const changedDate = new Date(fieldValue.split('.').reverse().join(', '));
      const siblingDate = new Date(
        ((isDirectionFrom ? toDate : fromDate) || '').split('.').reverse().join(', ')
      );
      const isValidDate = isValid(changedDate);
      const isSiblingDateValid = isValid(siblingDate);
      if (isValidDate) {
        let computedChangedDate = changedDate;
        let computedSiblingDate = siblingDate;
        if (computedChangedDate > max) computedChangedDate = max;
        if (computedChangedDate < min) computedChangedDate = min;
        if (isDirectionFrom && isSiblingDateValid && computedChangedDate > computedSiblingDate) {
          computedSiblingDate = computedChangedDate;
          setValue('toDate', format(computedChangedDate, dateFormat));
        }
        if (!isDirectionFrom && isSiblingDateValid && computedChangedDate < computedSiblingDate) {
          computedSiblingDate = computedChangedDate;
          setValue('fromDate', format(computedChangedDate, dateFormat));
        }
        setValue(isDirectionFrom ? 'fromDate' : 'toDate', format(computedChangedDate, dateFormat));
        if (isSiblingDateValid) {
          setValue('period', 'custom');
          setState({
            period: 'custom',
            range: [
              isDirectionFrom ? computedChangedDate : computedSiblingDate,
              isDirectionFrom ? computedSiblingDate : computedChangedDate,
            ],
          });
        }
      }
    }
  }

  function handleDateBlur(fieldValue: string, dateRange: string) {
    const changedDate = new Date(fieldValue.split('.').reverse().join(', '));
    const isValidDate = isValid(changedDate);
    if (!fieldValue || !isValidDate) {
      const isDirectionFrom = dateRange === 'from';
      const computedPrevDate = isDirectionFrom
        ? state.range[0] || startDay
        : state.range[1] || currentDay;
      setValue(isDirectionFrom ? 'fromDate' : 'toDate', format(computedPrevDate, dateFormat));
    }
  }

  function handleCalendarDateChange(newDate: DateRange<Date>) {
    if (newDate[0] && newDate[1]) {
      setValue('period', 'custom');
      setValue('fromDate', format(newDate[0], dateFormat));
      setValue('toDate', format(newDate[1], dateFormat));
      setState({
        period: 'custom',
        range: [newDate[0], endOfDay(new Date(newDate[1]))],
      });
    }
  }

  function handlePeriodClick() {
    setOpen(!isOpen);
  }

  function handlePeriodClose() {
    setOpen(false);
  }

  function handleSelectPeriodChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const { from, to } = periodList[value];
    const maxTo = currentDay > to ? to : currentDay;
    setValue('fromDate', format(from, dateFormat));
    setValue('toDate', format(maxTo, dateFormat));
    setState({
      period: value,
      range: [from, maxTo],
    });
  }

  function handlePeriodApply() {
    const { period, range } = state;
    setOpen(false);
    setValue('datePeriod', translate(periodList[period].title));
    if (onPeriodChange) {
      if (range[0] && range[1]) {
        setValue('fromDate', format(range[0], dateFormat));
        setValue('toDate', format(range[1], dateFormat));
        const periodDates = {
          from: startOfDay(range[0]),
          to: endOfDay(range[1]),
          period,
        };
        const periodTimes = isByTimeOn && selectTime ? { fromTime, toTime } : {};
        onPeriodChange({ ...periodDates, ...periodTimes });
      }
    }
  }

  return (
    <div className={classes.periodContainer}>
      <FormProvider {...formMethods}>
        <FormFieldRhfUncontrolled
          name={'datePeriod'}
          size={'small'}
          className={classes.periodTrigger}
          onClick={handlePeriodClick}
          defaultValue={translate(periodList[periodName || 'today'].title)}
          InputProps={{
            readOnly: true,
            endAdornment: isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />,
            classes: { input: classes.input },
          }}
        />
        <div className={clsx(computedClasses, !isOpen && classes.hidden)}>
          <div className={classes.periodCalendar}>
            <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ruLocale}>
              <StaticDateRangePicker
                className={classes.rangePicker}
                showDaysOutsideCurrentMonth
                displayStaticWrapperAs="desktop"
                disableAutoMonthSwitching
                disableFuture
                allowSameDateSelection
                minDate={minDate}
                value={state.range}
                calendars={3}
                onChange={handleCalendarDateChange}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.periodControls}>
            <div>
              <div className={classes.periodTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('CHOOSE_PERIOD')}
                </Typography>
              </div>
              <div className={classes.periodSelect}>
                <SelectField
                  name={'period'}
                  translating
                  valueKey={'value'}
                  titleKey={'titleCode'}
                  defaultValue={state.period}
                  data={selectOptionsList}
                  disabledOptions={disabledOptions}
                  onChange={handleSelectPeriodChange}
                />
              </div>
              <div className={classes.fieldsWrapper}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('FROM')}
                </Typography>
                <div className={classes.fieldSize}>
                  <MaskedField
                    mask={'34.15.2099'}
                    maskChar={'_'}
                    name={'fromDate'}
                    formatChars={dateMaskChars(fromDate)}
                    onChanges={(e) => handleDateChange(e, 'from')}
                    onBlur={(value) => handleDateBlur(value, 'from')}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                  />
                </div>
              </div>
              <div className={classes.fieldsWrapper}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('TO')}
                </Typography>
                <div className={classes.fieldSize}>
                  <MaskedField
                    mask={'34.15.2099'}
                    maskChar={'_'}
                    name={'toDate'}
                    formatChars={dateMaskChars(toDate)}
                    onChanges={(e) => handleDateChange(e, 'to')}
                    onBlur={(value) => handleDateBlur(value, 'to')}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                  />
                </div>
              </div>
              {selectTime && (
                <>
                  <div className={classes.fieldsWrapper}>
                    <CheckboxField name={'byTime'} label={translate('BY_TIME')} />
                  </div>
                  <Flex
                    alignItems={'center'}
                    justifyContent={'spaceBetween'}
                    className={classes.timeWrapper}
                  >
                    {isByTimeOn && (
                      <>
                        <Typography type={'text3'} color={'tertiary900'}>
                          {translate('FROM')}
                        </Typography>
                        <div className={classes.timeSize}>
                          <MaskedField
                            mask={'23:59'}
                            size={'small'}
                            maskChar={'0'}
                            name={'fromTime'}
                            helperText=""
                            formatChars={formatChars(fromTime)}
                            className={classes.timeRoot}
                            InputProps={{
                              classes: { root: classes.timeRoot, input: classes.input },
                            }}
                          />
                        </div>
                        <Typography type={'text3'} color={'tertiary900'}>
                          {translate('TO')}
                        </Typography>
                        <div className={classes.timeSize}>
                          <MaskedField
                            mask={'23:59'}
                            size={'small'}
                            maskChar={'0'}
                            name={'toTime'}
                            helperText=""
                            formatChars={formatChars(toTime)}
                            className={classes.timeRoot}
                            InputProps={{
                              classes: { root: classes.timeRoot, input: classes.input },
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Flex>
                </>
              )}
            </div>
            <div className={classes.fieldsButtons}>
              <ControlButtons
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                confirmDisable={isByTimeOn && isTimeError}
                onConfirmClick={handlePeriodApply}
                onCancelClick={handlePeriodClose}
              />
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
