import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  SearchIcon,
  DownloadIcon,
  XIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import getBasicButtonDownloadHandleErrorCallback from '@components/utils/getBasicButtonDownloadHandleErrorCallback';
import Typography from '@shared/components/Typography';
import { Button } from '@shared/components/Button/Button';
import FormFieldBase from '@shared/components/FormFieldBase';
import ButtonDownload from '@shared/components/ButtonDownload';
import {
  IHistoryState,
  IScrollItems,
  ISpanItems,
  ITrascription,
  TranscriptionProps,
} from './Transcription.interfaces';
import { TranscriptionStyles } from './Transcription.styles';

const matchedTextList: ISpanItems = {};

export const Transcription: FunctionComponent<TranscriptionProps> = ({
  isOpen,
  client,
  employee,
  data,
  onClose,
  downloadURL,
}) => {
  const classes = TranscriptionStyles();
  const [translate] = useTranslation();
  const chatContainer = useRef<HTMLDivElement | null>(null);

  const setStateData = (d: ITrascription[] | undefined) => {
    if (!d) return [];
    return d.reduce((res: ITrascription[], item) => {
      res.push({
        ...item,
        high: '',
      });
      return res;
    }, []);
  };

  const [localData, setLocalData] = useState(setStateData(data));
  const [searchVal, setSearchVal] = useState('');
  const [scrollItems, setScrollItems] = useState<IScrollItems>({});
  const [state, setState] = useState<IHistoryState>({ max: 0, cur: 0 });

  const computedClasses = clsx(classes.wrap, {
    [classes.show]: isOpen,
  });

  const highlightedText = (txt: string, high: string, indx: number) => {
    const getIndex = (textname: string, search: string) => {
      const title = textname.toLowerCase();
      const searchValue = search.toLowerCase();
      return title.indexOf(searchValue);
    };
    if (high !== '') {
      return txt.split(' ').map((itemText, index) => {
        if (getIndex(itemText, high) === -1) {
          return (
            <Typography key={`${indx}${index}`} type={'text4'} color={'tertiary900'}>
              {itemText}{' '}
            </Typography>
          );
        }
        const stringStart = itemText.substr(0, getIndex(itemText, high));
        let stringMatch = '';
        const stringEnd = `${itemText.substr(getIndex(itemText, high) + high.length)} `;
        let countIndex = getIndex(itemText, high);
        for (let i = 0; i < high.length; i += 1) {
          stringMatch += itemText.slice(countIndex, countIndex + 1);
          countIndex += 1;
        }
        return (
          <span
            key={`${indx}${index}`}
            ref={(el: HTMLSpanElement) => {
              matchedTextList[`${indx}${index}`] = el;
            }}
          >
            <Typography type={'text4'} color={'tertiary900'}>
              {stringStart}
              <Typography type={'default'} color={'primary700'}>
                {stringMatch}
              </Typography>
              {stringEnd}
            </Typography>
          </span>
        );
      });
    }
    return (
      <Typography type={'text4'} color={'tertiary900'}>
        {txt}
      </Typography>
    );
  };

  const formatTime = (time: string) => {
    const pattern = /^0+|[+]0+/;
    const fTime = time.split(':');
    const h = fTime[0] === '00' ? '' : `${fTime[0]}ч. `;
    const m = fTime[1] === '00' ? '' : `${fTime[1]}м. `;
    const fSecond = fTime[2].split('.');
    const s = () => {
      if (fSecond[0] === '00') {
        return '0c';
      }
      if (fSecond[0].match(pattern)) {
        return `${fSecond[0].replace(/0/, '')}c.`;
      }
      return `${fSecond[0]}c.`;
    };
    return h + m + s();
  };

  useEffect(() => {
    const childIndex = scrollItems[state.cur];
    const item = matchedTextList[childIndex];
    item?.scrollIntoView({ behavior: 'smooth' });
  }, [scrollItems, state.cur]);

  useEffect(() => {
    setLocalData(setStateData(data));
  }, [data]);

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const val = value.toLowerCase();
    if (data) {
      const { z, items, dataResult } = data.reduce<{
        z: number;
        items: IScrollItems;
        dataResult: ITrascription[];
      }>(
        (result, item, index) => {
          const itemText = item.text.toLowerCase();
          result.dataResult.push({ ...item, high: itemText.includes(val) ? val : '' });
          if (item.text.length) {
            item.text.split(' ').forEach((text, count) => {
              if (text.toLowerCase().includes(val)) {
                // eslint-disable-next-line no-param-reassign
                result.z += 1;
                // eslint-disable-next-line no-param-reassign
                result.items = {
                  ...result.items,
                  [result.z]: `${index}${count}`,
                };
              }
            });
          }
          return result;
        },
        { z: 0, items: [], dataResult: [] }
      );
      setState({ cur: z !== 0 ? 1 : 0, max: z });
      setLocalData(dataResult);
      setScrollItems(items);
    }
    setSearchVal(value);
  }

  function handleSearchClear() {
    setSearchVal('');
    setLocalData(setStateData(data));
  }

  function handleViewNextMatch() {
    if (state.cur !== state.max) {
      setState({ ...state, cur: (state.cur += 1) });
    }
  }

  function handleViewPrevMatch() {
    if (state.cur !== 0 && state.cur !== 1) {
      setState({ ...state, cur: (state.cur -= 1) });
    }
  }

  function handleCloseClick() {
    if (onClose) {
      onClose();
    }
  }

  const renderSuffix = () =>
    searchVal.length === 0 ? (
      <SearchIcon />
    ) : (
      <div className={classes.controls}>
        <Typography medium type={'text4'} color={'tertiary900'}>
          {state.cur} / {state.max}
        </Typography>
        <div className={classes.switch}>
          <ChevronDownIcon
            onClick={handleViewNextMatch}
            className={state.cur !== state.max ? classes.closeIcon : classes.disabledIcon}
          />
          <ChevronUpIcon
            onClick={handleViewPrevMatch}
            className={
              state.cur !== 0 && state.cur !== 1 ? classes.closeIcon : classes.disabledIcon
            }
          />
        </div>
        <XIcon className={classes.closeIcon} onClick={handleSearchClear} />
      </div>
    );

  return (
    <div className={computedClasses}>
      <div className={classes.window}>
        <div className={classes.close}>
          <Button onClick={handleCloseClick} variant="icon" className={classes.buttonClose}>
            <XIcon />
          </Button>
        </div>
        <div className={classes.header}>
          <Typography medium type={'text2'} color={'tertiary900'}>
            {translate('RECORDED_TEXT')}
          </Typography>
          <div className={classes.headerButtons}>
            <FormFieldBase
              size="small"
              value={searchVal}
              InputProps={{
                endAdornment: renderSuffix(),
                classes: { input: classes.inputAdornedEnd },
              }}
              onChange={handleSearch}
            />
            <div className={classes.download}>
              <ButtonDownload
                url={downloadURL}
                onDownloadError={getBasicButtonDownloadHandleErrorCallback(translate)}
              />
            </div>
          </div>
        </div>
        <div className={classes.info}>
          <div className={clsx(classes.employeeInfo, classes.cell)}>
            <Typography medium type={'text5'} color={'tertiary900'}>
              {translate('EMPLOYEE')}
            </Typography>
            <Typography type={'text5'} color={'tertiary500'}>
              {employee}
            </Typography>
          </div>
          <div className={clsx(classes.clientInfo, classes.cell)}>
            <Typography medium type={'text5'} color={'tertiary900'}>
              {translate('CLIENT')}
            </Typography>
            <Typography type={'text5'} color={'tertiary500'}>
              {formatPhone(client)}
            </Typography>
          </div>
        </div>
        <div ref={chatContainer} className={classes.chat}>
          {localData
            ? localData.map((item: ITrascription, index: number) => (
                <div
                  key={index}
                  className={item.who === 'client' ? classes.itemRight : classes.itemLeft}
                >
                  <div
                    className={clsx(
                      classes.message,
                      item.who === 'client' ? classes.from : classes.to
                    )}
                  >
                    {highlightedText(item.text, item.high ? item.high : '', index)}
                    <div
                      className={clsx(
                        classes.time,
                        item.who === 'client' ? classes.timeFrom : classes.timeTo
                      )}
                    >
                      <Typography type={'text5'} color={'tertiary300'}>
                        {formatTime(item.time)}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
