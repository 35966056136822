import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import MessageDialog from '@shared/components/MessageDialog';
import { useMutation } from '@apollo/client';
import {
  SearchIcon,
  TrashIcon,
  SnowflakeCrossedIcon,
  SnowflakeIcon,
} from '@shared/assets/images/icons';
import { AssistantStatus, IAssistant } from '@components/typings/interfaces';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CANCEL_ASSISTANT_DELETION_MUTATION,
  PRE_DELETE_ASSISTANT_MUTATION,
  PRE_FREEZE_ASSISTANT_MUTATION,
  UNFREEZE_ASSISTANT_MUTATION,
} from '@/client/mutations';
import { toPrecision } from '@components/utils';
import { GET_ASSISTANTS_QUERY } from '@/client/queries';
import { getStorageItem } from '@components/storage/storage';
import { getMonthRatio } from '@/utils/getMonthRatio';
import ControlButtons from '@shared/components/ControlButtons';
import Translate from '@shared/components/Translate';
import { ICellProps } from '@components/LazyTable';
import { useScenariosStyle } from '../Scenarios.styles';
import { ModalType } from '../Scenarios.interfaces';

export const ScenarioActionsCell: FunctionComponent<ICellProps<IAssistant>> = ({ item }) => {
  const { status, id, document, telephonyService, sum } = item || {};
  const [translate] = useTranslation();
  const classes = useScenariosStyle();
  const [currentModal, setCurrentModal] = useState<ModalType>(ModalType.Closed);
  const formMethods = useForm();
  const { handleSubmit } = formMethods;
  const cancellationStatus =
    status === AssistantStatus.PreFrozen ||
    status === AssistantStatus.PreDeletedFrozen ||
    status === AssistantStatus.PreDeletedBought;
  const developingStatus =
    status === AssistantStatus.Developing || status === AssistantStatus.Reserved;
  const refetchAssistantsQuery = { refetchQueries: [{ query: GET_ASSISTANTS_QUERY }] };
  const assistantCost = (telephonyService?.cost ? telephonyService.cost : sum) || 0;

  const [
    predeleteAssistant,
    { error: errorPredeleteAssistant, loading: loadingPredeleteAssistant },
  ] = useMutation(PRE_DELETE_ASSISTANT_MUTATION, refetchAssistantsQuery);
  const [
    cancelDeletionAssistant,
    { error: errorCancelDeletionAssistant, loading: loadingCancelDeletionAssistant },
  ] = useMutation(CANCEL_ASSISTANT_DELETION_MUTATION, refetchAssistantsQuery);

  const [
    prefreezeAssistant,
    { error: errorPrefreezeAssistant, loading: loadingPrefreezeAssistant },
  ] = useMutation(PRE_FREEZE_ASSISTANT_MUTATION, refetchAssistantsQuery);

  const [unfreezeAssistant, { error: errorUnfreezeAssistant, loading: loadingUnfreezeAssistant }] =
    useMutation(UNFREEZE_ASSISTANT_MUTATION, refetchAssistantsQuery);

  function handleOpenFreezeAssistant() {
    setCurrentModal(ModalType.BoughtToFreeze);
  }

  function handleOpenDeleteAssistant() {
    setCurrentModal(ModalType.BoughtToDelete);
  }

  function handleOpenUnfreezeAssistant() {
    setCurrentModal(ModalType.Frozen);
  }

  function handleCloseDialog() {
    setCurrentModal(ModalType.Closed);
  }

  function handleOpenTypeCancelDialog() {
    if (status === AssistantStatus.PreFrozen) {
      setCurrentModal(ModalType.PreFreeze);
    }
    if (status === AssistantStatus.PreDeletedFrozen) {
      setCurrentModal(ModalType.PreDeletedFrozen);
    }
    if (status === AssistantStatus.PreDeletedBought) {
      setCurrentModal(ModalType.PreDeletedBought);
    }
  }

  function handleCancelDeletionAssistant() {
    cancelDeletionAssistant({ variables: { data: { assistantId: id } } }).then(() => {
      setCurrentModal(ModalType.Closed);
    });
  }

  function handleCancelFreezeAssistant() {
    unfreezeAssistant({ variables: { data: { assistantId: id } } }).then(() => {
      setCurrentModal(ModalType.Closed);
    });
  }

  function handleFreezeAssistant() {
    prefreezeAssistant({ variables: { data: { assistantId: id } } }).then(() => {
      setCurrentModal(ModalType.Closed);
    });
  }

  function handleUnfreezeAssistant() {
    unfreezeAssistant({ variables: { data: { assistantId: id } } }).then(() => {
      setCurrentModal(ModalType.Closed);
    });
  }

  function handleDeleteAssistant() {
    predeleteAssistant({ variables: { data: { assistantId: id } } }).then(() => {
      setCurrentModal(ModalType.Closed);
    });
  }

  function handleAssistantSchemaViewClick() {
    let url = process.env.API_URL;
    const token = getStorageItem('token');
    if (token && url) {
      url += `/files/view?filename=${document?.document}&token=${token}`;
      window.open(url);
    }
  }

  useEffect(() => {
    if (
      errorUnfreezeAssistant ||
      errorPredeleteAssistant ||
      errorCancelDeletionAssistant ||
      errorPredeleteAssistant ||
      errorPrefreezeAssistant
    ) {
      setCurrentModal(ModalType.Error);
    }
  }, [
    errorUnfreezeAssistant,
    errorPredeleteAssistant,
    errorCancelDeletionAssistant,
    errorPrefreezeAssistant,
  ]);

  if (!item) {
    return <div />;
  }

  const renderCancelApplicationContent = () => {
    if (
      status === AssistantStatus.PreDeletedBought ||
      status === AssistantStatus.PreDeletedFrozen
    ) {
      return (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleCancelDeletionAssistant)} id={'delete-cancel'}>
            <div className={classes.dialogContentCenter}>
              <Typography type={'text3'} color={'tertiary900'}>
                {translate('ASSISTANT_COME_BACK')}
              </Typography>
              <ControlButtons
                rootClass={classes.buttonsContainerSmall}
                confirmTitle={'CONFIRM'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                form={'delete-cancel'}
                onCancelClick={handleCloseDialog}
                loading={loadingCancelDeletionAssistant}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                small
              />
            </div>
          </form>
        </FormProvider>
      );
    }
    if (status === AssistantStatus.PreFrozen) {
      return (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleCancelFreezeAssistant)} id={'froze-cancel'}>
            <div className={classes.dialogContentCenter}>
              <Typography type={'text3'} color={'tertiary900'}>
                {translate('ASSISTANT_UNFREEZE')}
              </Typography>
              <div className={classes.buttonsContainerSmall}>
                <ControlButtons
                  confirmTitle={'CONFIRM'}
                  cancelTitle={'CANCEL'}
                  cancelVariant="secondary"
                  form={'froze-cancel'}
                  flexDirection={'row-reverse'}
                  justifyContent={'start'}
                  onCancelClick={handleCloseDialog}
                  loading={loadingUnfreezeAssistant}
                  small
                />
              </div>
            </div>
          </form>
        </FormProvider>
      );
    }
    return null;
  };

  const renderFreezeAssistantContent = () => {
    if (status === AssistantStatus.Bought) {
      return (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleFreezeAssistant)} id={'freeze-assistant'}>
            <div className={classes.dialogContentCenter}>
              <Translate
                i18nKey="ASSISTANT_FREEZE"
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  b: <br />,
                }}
              />
              <ControlButtons
                confirmTitle={'CONFIRM'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                form={'freeze-assistant'}
                onCancelClick={handleCloseDialog}
                loading={loadingPrefreezeAssistant}
                rootClass={classes.buttonsContainerSmall}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                small
              />
            </div>
          </form>
        </FormProvider>
      );
    }
    return null;
  };

  const renderUnfreezeAssistantContent = () => {
    if (status === AssistantStatus.Frozen) {
      return (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleUnfreezeAssistant)} id={'unfreeze-assistant'}>
            <div className={classes.dialogContentCenter}>
              <Translate
                i18nKey="UNFREEZE_SCENARIO_DESCRIPTION"
                values={{
                  restMonthCost: toPrecision(getMonthRatio() * assistantCost),
                }}
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  p: <Typography type={'default'} color={'primary700'} />,
                  b: <br />,
                }}
              />
              <ControlButtons
                confirmTitle={'CONFIRM'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                form={'unfreeze-assistant'}
                onCancelClick={handleCloseDialog}
                loading={loadingUnfreezeAssistant}
                rootClass={classes.buttonsContainerSmall}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                small
              />
            </div>
          </form>
        </FormProvider>
      );
    }
    return null;
  };

  const renderDeleteAssistantContent = () => {
    if (status === AssistantStatus.Bought || status === AssistantStatus.Frozen) {
      return (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleDeleteAssistant)} id={'delete-assistant'}>
            <div className={classes.dialogContentCenter}>
              <Translate
                i18nKey="DELETE_ASSISTANT"
                components={{
                  t: <Typography type={'text3'} color={'tertiary900'} />,
                  b: <br />,
                }}
              />
              <ControlButtons
                rootClass={classes.buttonsContainerSmall}
                confirmTitle={'CONFIRM'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                form={'delete-assistant'}
                onCancelClick={handleCloseDialog}
                loading={loadingPredeleteAssistant}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                small
              />
            </div>
          </form>
        </FormProvider>
      );
    }
    return null;
  };

  const renderErrorDialogContent = () => (
    <div className={classes.dialogContentCenter}>
      <Typography type={'text3'} color={'tertiary900'}>
        {translate('ASSISTANT_ERROR')}
      </Typography>
      <div className={classes.buttonsContainerSmall}>
        <Button title={translate('CLOSE')} onClick={handleCloseDialog} smallHeight />
      </div>
    </div>
  );

  const renderModal = () => {
    const modalProps = {
      isOpen: true,
      onCancel: handleCloseDialog,
    };
    switch (currentModal) {
      case ModalType.PreDeletedBought:
        return (
          <MessageDialog
            {...modalProps}
            contentClass={classes.defaultElementWidth21}
            title={translate('ARE_YOU_SURE')}
            renderContent={renderCancelApplicationContent()}
          />
        );
      case ModalType.PreDeletedFrozen:
        return (
          <MessageDialog
            {...modalProps}
            contentClass={classes.defaultElementWidth21}
            title={translate('ARE_YOU_SURE')}
            renderContent={renderCancelApplicationContent()}
          />
        );
      case ModalType.PreFreeze:
        return (
          <MessageDialog
            {...modalProps}
            contentClass={classes.defaultElementWidth21}
            title={translate('ARE_YOU_SURE')}
            renderContent={renderCancelApplicationContent()}
          />
        );
      case ModalType.Frozen:
        return (
          <MessageDialog
            {...modalProps}
            contentClass={classes.defaultElementWidth24}
            title={translate('UNFREEZE_SCENARIO')}
            renderContent={renderUnfreezeAssistantContent()}
          />
        );
      case ModalType.BoughtToFreeze:
        return (
          <MessageDialog
            {...modalProps}
            contentClass={classes.defaultElementWidth32}
            title={translate('ARE_YOU_SURE')}
            renderContent={renderFreezeAssistantContent()}
          />
        );
      case ModalType.BoughtToDelete:
        return (
          <MessageDialog
            {...modalProps}
            contentClass={classes.defaultElementWidth24}
            title={translate('ARE_YOU_SURE')}
            renderContent={renderDeleteAssistantContent()}
          />
        );
      case ModalType.Error:
        return (
          <MessageDialog
            {...modalProps}
            title={translate('SOMETHING_WENT_WRONG')}
            renderContent={renderErrorDialogContent()}
          />
        );
      case ModalType.Closed:
      default:
        return null;
    }
  };

  const renderTypeFreezeButton = () => {
    if (status === AssistantStatus.Frozen) {
      return (
        <Button
          className={classes.actionSmallButton}
          variant={'secondary'}
          onClick={handleOpenUnfreezeAssistant}
        >
          <SnowflakeCrossedIcon />
        </Button>
      );
    }
    return (
      <Button
        className={classes.actionSmallButton}
        variant={'secondary'}
        onClick={handleOpenFreezeAssistant}
      >
        <SnowflakeIcon />
      </Button>
    );
  };

  return (
    <>
      {!cancellationStatus ? (
        <>
          <Button
            className={classes.actionSmallButton}
            variant={'secondary'}
            disabled={!document}
            onClick={() => handleAssistantSchemaViewClick()}
          >
            <SearchIcon />
          </Button>
          {!developingStatus && (
            <>
              {renderTypeFreezeButton()}
              <Button
                className={classes.actionSmallButton}
                variant={'secondary'}
                color={'error'}
                onClick={handleOpenDeleteAssistant}
              >
                <TrashIcon />
              </Button>
            </>
          )}
        </>
      ) : (
        <Button
          className={classes.bigCancelApplication}
          title={translate('NUMBER_UNRESERVED_BUTTON')}
          variant={'secondary'}
          onClick={() => handleOpenTypeCancelDialog()}
        />
      )}
      {renderModal()}
    </>
  );
};
