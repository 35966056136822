import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Redirect: FunctionComponent<{ to: string }> = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => navigate(to, { replace: true }), [navigate, to]);

  return null;
};

export default Redirect;
