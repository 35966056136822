import { numberKeyValueType } from '@components/typings/interfaces';
import Incoming from '@/assets/images/diagram/phone-incoming-blue.png';
import IncomingMissed from '@/assets/images/diagram/phone-incoming-red.png';
import Outgoing from '@/assets/images/diagram/phone-outgoing.png';
import OutgoingMissed from '@/assets/images/diagram/phone-missed.png';
import { callHourlyStatisticsTypes, callStatisticsPeriodTypes } from './DesktopPage.interfaces';

export const weekNames = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const findMonth = (monthNumber: number) => {
  const monthDays: numberKeyValueType = {
    0: 'MONTH_JANUARY',
    1: 'MONTH_FEBRUARY',
    2: 'MONTH_MART',
    3: 'MONTH_APRIL',
    4: 'MONTH_MAY',
    5: 'MONTH_JUNE',
    6: 'MONTH_JULY',
    7: 'MONTH_AUGUST',
    8: 'MONTH_SEPTEMBER',
    9: 'MONTH_OCTOBER',
    10: 'MONTH_NOVEMBER',
    11: 'MONTH_DECEMBER',
  };

  return monthDays[monthNumber];
};

export const callStatisticsPeriodList = [
  {
    title: 'TODAY',
    value: callStatisticsPeriodTypes.Today,
  },
  {
    title: 'WEEK',
    value: callStatisticsPeriodTypes.Week,
  },
  {
    title: 'UP_MONTH',
    value: callStatisticsPeriodTypes.Month,
  },
];

export const callHourlyStatisticsList = [
  {
    title: 'INCOMING',
    value: callHourlyStatisticsTypes.Incoming,
  },
  {
    title: 'OUTGOING',
    value: callHourlyStatisticsTypes.Outgoing,
  },
];

export const callStatisticsList = [
  {
    title: 'OUTGOING',
    key: 'outSuccess',
  },
  {
    title: 'OUTGOING_OUT',
    key: 'outNotSuccess',
  },
  {
    title: 'INCOMING',
    key: 'inSuccess',
  },
  {
    title: 'INCOMING_OUT',
    key: 'inNotSuccess',
  },
];

export const incomingArrowIcons: numberKeyValueType = {
  0: Incoming,
  1: IncomingMissed,
};

export const outgoingArrowIcons: numberKeyValueType = {
  0: Outgoing,
  1: OutgoingMissed,
};

export const incomingTitles: numberKeyValueType = {
  0: 'INCOMING',
  1: 'INCOMING_OUT',
};

export const outgoingTitles: numberKeyValueType = {
  0: 'OUTGOING',
  1: 'OUTGOING_OUT',
};

export const callHourlyStatisticsTicksList = [0, 4, 8, 12, 16, 20, 23];
