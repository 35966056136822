// TODO update typescript version for better inference
// in order to remove "as const"

export const DEFAULT = 'default' as const;
export const TEXT1 = 'text1' as const;
export const TEXT2 = 'text2' as const;
export const TEXT3 = 'text3' as const;
export const TEXT4 = 'text4' as const;
export const TEXT5 = 'text5' as const;
export const COLOR_INHERIT = 'inherit' as const;
export const COLOR_BASE = 'base' as const;
export const COLOR_PRIMARY800 = 'primary800' as const;
export const COLOR_PRIMARY500 = 'primary500' as const;
export const COLOR_PRIMARY600 = 'primary600' as const;
export const COLOR_PRIMARY700 = 'primary700' as const;
export const COLOR_SECONDARY600 = 'secondary600' as const;
export const COLOR_TERTIARY900 = 'tertiary900' as const;
export const COLOR_TERTIARY800 = 'tertiary800' as const;
export const COLOR_TERTIARY700 = 'tertiary700' as const;
export const COLOR_TERTIARY600 = 'tertiary600' as const;
export const COLOR_TERTIARY500 = 'tertiary500' as const;
export const COLOR_TERTIARY400 = 'tertiary400' as const;
export const COLOR_TERTIARY300 = 'tertiary300' as const;
export const COLOR_TERTIARY200 = 'tertiary200' as const;
export const COLOR_DANGER400 = 'danger400' as const;
export const COLOR_DANGER600 = 'danger600' as const;
export const COLOR_SUCCESS600 = 'success600' as const;
export const COLOR_LINKS500 = 'link500' as const;
export const COLOR_LINKS600 = 'link600' as const;
export const COLOR_WARNING600 = 'warning600' as const;

export const TextColors = {
  COLOR_INHERIT,
  COLOR_BASE,
  COLOR_PRIMARY800,
  COLOR_PRIMARY500,
  COLOR_PRIMARY600,
  COLOR_PRIMARY700,
  COLOR_SECONDARY600,
  COLOR_TERTIARY900,
  COLOR_TERTIARY800,
  COLOR_TERTIARY700,
  COLOR_TERTIARY600,
  COLOR_TERTIARY500,
  COLOR_TERTIARY400,
  COLOR_TERTIARY300,
  COLOR_TERTIARY200,
  COLOR_DANGER400,
  COLOR_DANGER600,
  COLOR_SUCCESS600,
  COLOR_LINKS500,
  COLOR_LINKS600,
  COLOR_WARNING600,
};

export const TextSize = {
  DEFAULT,
  TEXT1,
  TEXT2,
  TEXT3,
  TEXT4,
  TEXT5,
};
