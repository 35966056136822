import { useMutation } from '@apollo/client';
import {
  CREATE_INTEGRATION_MUTATION,
  DELETE_INTEGRATION_MUTATION,
  UPDATE_INTEGRATION_MUTATION,
  UPDATE_INTEGRATION_ACTIVATION_MUTATION,
} from '@/client/mutations';

export function useIntegrationHandlers() {
  const [createIntegration] = useMutation(CREATE_INTEGRATION_MUTATION);
  const [updateIntegration] = useMutation(UPDATE_INTEGRATION_MUTATION);
  const [deleteIntegration, { loading: loadingDeleteIntegration }] = useMutation(
    DELETE_INTEGRATION_MUTATION
  );
  const [updateIntegrationActivation, { loading: loadingUpdateIntegrationActivation }] =
    useMutation(UPDATE_INTEGRATION_ACTIVATION_MUTATION);

  return {
    createIntegration,
    updateIntegration,
    deleteIntegration,
    loadingDeleteIntegration,
    updateIntegrationActivation,
    loadingUpdateIntegrationActivation,
  };
}
