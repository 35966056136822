import React, { FunctionComponent, useEffect } from 'react';
import { Card, FormControlLabel, FormGroup } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import { CallDistributionFieldsStyle } from './CallDistributionFields.style';
import { ICallDistributionFieldsProps } from './CallDistributionFields.interfaces';

export enum CallDistributionTypes {
  Multicall = 'MULTICALL',
  Cycled = 'CYCLED',
  Progressive = 'PROGRESSIVE',
}

export const CallDistributionFields: FunctionComponent<ICallDistributionFieldsProps> = ({
  options = [],
  defaultValue: defaultFormValue = '',
  onChange,
}) => {
  const classes = CallDistributionFieldsStyle();
  const { watch, setValue } = useFormContext();
  const distribution = watch('callDistributionType');
  const [translate] = useTranslation();
  const distributionType = watch('callDistributionType');
  const timeoutValue = watch('timeout');

  useEffect(() => {
    if (options && !timeoutValue) {
      setValue('timeout', options[2]?.value);
    }
  }, [options, setValue, distributionType, timeoutValue]);

  const handleChanges = () => {
    if (typeof onChange === 'function') {
      onChange();
    }
  };

  return (
    <FormGroup className={classes.root}>
      <RadioGroup defaultValue={defaultFormValue} name={'callDistributionType'}>
        <FormControlLabel
          value={CallDistributionTypes.Multicall}
          control={<Radio color={'secondary'} />}
          onChange={handleChanges}
          label={translate('MULTICALL')}
        />
        {distribution === CallDistributionTypes.Multicall && (
          <Card className={classes.balloonCard} elevation={0}>
            <Typography type={'text3'} className={classes.cardTitle}>
              {translate('DEPARTMENT_MULTICALL_DISTRIBUTION_HINT')}
            </Typography>
          </Card>
        )}

        <FormControlLabel
          value={CallDistributionTypes.Cycled}
          control={<Radio color={'secondary'} />}
          onChange={handleChanges}
          label={translate('CYCLED')}
        />
        {distribution === CallDistributionTypes.Cycled && (
          <Card className={classes.balloonCard} elevation={0}>
            <Typography type={'text3'} className={classes.cardTitle}>
              {translate('DEPARTMENT_CYCLED_DISTRIBUTION_HINT')}
            </Typography>
            <SelectField
              name={'timeout'}
              valueKey={'value'}
              titleKey={'titleCode'}
              data={options}
              defaultValue={options[4]?.value}
              onChange={handleChanges}
              translating
              className={classes.select}
              label={translate('CALL_DISTRIBUTION_TIMEOUT')}
            />
          </Card>
        )}
        <FormControlLabel
          value={CallDistributionTypes.Progressive}
          control={<Radio color={'secondary'} />}
          onChange={handleChanges}
          label={translate('PROGRESSIVE')}
        />
        {distribution === CallDistributionTypes.Progressive && (
          <Card className={classes.balloonCard} elevation={0}>
            <Typography type={'text3'} className={classes.cardTitle}>
              {translate('DEPARTMENT_PROGRESSIVE_DISTRIBUTION_HINT')}
            </Typography>
            <SelectField
              name={'timeout'}
              valueKey={'value'}
              titleKey={'titleCode'}
              data={options}
              defaultValue={options[4]?.value}
              onChange={handleChanges}
              translating
              className={classes.select}
              label={translate('CALL_DISTRIBUTION_TIMEOUT')}
            />
          </Card>
        )}
      </RadioGroup>
    </FormGroup>
  );
};

export default CallDistributionFields;
