import { makeStyles } from '@material-ui/core';

export const useSelectTagsFieldStyles = makeStyles(
  ({ color: { base, primary } }) => ({
    root: {
      background: base,
      '& .MuiInputAdornment-root': {
        top: '1.25em',
        right: '0.25em',
      },
    },
    optionLine: {
      padding: '0.125em 0',
    },
    optionLineIcon: {
      marginRight: '0.5em',
      color: '#009D9F',
    },
    tagFromList: {
      background: primary[700],
      color: base,
      border: 'none',
      '& svg': {
        color: base,
      },
    },
    tagFromListRemove: {
      '&:hover': {
        color: primary[200],
      },
    },
  }),
  {
    index: 1,
    name: 'SelectTagsField',
  }
);
