import { makeStyles } from '@material-ui/core/styles';

export const useContextButtonStyles = makeStyles(
  ({ color: { base } }) => ({
    '@keyframes showBlock': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    contextButtonRoot: {
      display: 'inline-block',
      position: 'relative',
    },
    contextButtonPopper: {
      animation: '$showBlock linear 0.2s',
      background: base,
      borderRadius: '12px',
      minWidth: '17.5em',
      position: 'absolute',
      boxShadow: '0 8px 20px 0 #0000001A',
    },
    contextButtonPopperPlacementLeft: {
      top: 0,
      right: 'calc(100% + 1em)'
    },
    contextButtonPopperPlacementRight: {
      top: 0,
      left: 'calc(100% + 1em)'
    }
  }),
  {
    index: 2,
  }
);
