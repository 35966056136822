import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { CallsRestrictionsQuery, CallRestrictionType } from '@/client/generated/graphql';
import { useCallsRestrictionsStyles } from '../CallsRestrictions.styles';

export const CallRestrictionsDirectionsCell = ({
  item,
}: ICellProps<CallsRestrictionsQuery['getCallRestrictions'][number]>) => {
  const [translate] = useTranslation();
  const classes = useCallsRestrictionsStyles();
  const { restriction = [] } = item || {};

  if (item) {
    const renderDirections = () => {
      if (restriction.length === 3) {
        return (
          <Typography type={'text3'} color={'inherit'}>
            {translate('ALL_DIRECTIONS')}
          </Typography>
        );
      }
      return restriction.map((code, index) => {
        if (code === CallRestrictionType.Mg) {
          return (
            <Typography key={`${item?.id}_${code}_${index}`} type={'text3'} color={'inherit'}>
              {translate('COUNTRY_CALLS', { region: translate('RU_DIRECTION') })}
            </Typography>
          );
        }
        if (code === CallRestrictionType.Mn) {
          return (
            <Typography key={`${item?.id}_${code}_${index}`} type={'text3'} color={'inherit'}>
              {translate('INTERNATIONAL_CALLS')}
            </Typography>
          );
        }
        if (code === CallRestrictionType.Local) {
          return (
            <Typography key={`${item?.id}_${code}_${index}`} type={'text3'} color={'inherit'}>
              {translate('COMPANY_CALLS')}
            </Typography>
          );
        }
        return null;
      });
    };

    return (
      <div className={classes.rowContent}>
        <div className={classes.description}>{renderDirections()}</div>
      </div>
    );
  }

  return <div />;
};
