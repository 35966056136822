import { TrashIcon, ChevronUpDownIcon } from '@shared/assets/images/icons';
import { CREATE_DEPARTMENT_MUTATION, UPDATE_DEPARTMENT_MUTATION } from '@/client/mutations';
import { DEPARTMENT_QUERY, GET_USED_EXTENSIONS_QUERY } from '@/client/queries';
import { useFormErrors } from '@components/common/formErrors.hooks';
import { usePrevious } from '@/common/hooks/usePrev.hooks';
import EmployeesSelection from '@/features/Department/modules/EmployeesSelection/EmployeesSelection';
import BodyContainer from '@/layouts/BodyContainer';
import { getAllowedEmergenceNumbersStatus, getExtLength, getFreeExtensions } from '@/utils';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import FormErrorMessage from '@components/FormErrorMessage';
import Button from '@shared/components/Button';
import ControlButtons from '@shared/components/ControlButtons';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import BottomButtons from '@/layouts/BottomButtons';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import ComboBoxField from '@shared/components/ComboBoxField';
import * as Sentry from '@sentry/react';
import { Employee, FormData } from './Department.interfaces';
import { useDepartmentStyles, useSortableListClasses } from './Department.styles';

export const Department = () => {
  const classes = useDepartmentStyles();
  const sortableListClasses = useSortableListClasses();
  const [translate] = useTranslation();
  const isEmergencyServicesAllowed = getAllowedEmergenceNumbersStatus();
  const extLength = getExtLength();
  const navigate = useNavigate();
  const idMatch = useMatch('/:category/:subcategory/:id');
  const { params: { id = '' } = {} } = idMatch || {};
  const [activeEmployees, setActiveEmployees] = useState<Employee[]>([]);
  const [inactiveEmployees, setInactiveEmployees] = useState<Employee[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  const [
    getDepartment,
    {
      called: getDepartmentCalled,
      loading: getDepartmentLoading,
      data,
      error: { graphQLErrors: getDepartmentErrors = [] } = {},
    },
  ] = useLazyQuery(DEPARTMENT_QUERY);
  const department = data?.department;
  const prevGetDepartmentLoading = usePrevious(getDepartmentLoading);

  const [
    updateDepartment,
    {
      called: updateDepartmentCalled,
      loading: updateDepartmentLoading,
      error: { graphQLErrors: updateDepartmentErrors = [] } = {},
    },
  ] = useMutation(UPDATE_DEPARTMENT_MUTATION);

  const [
    createDepartment,
    {
      loading: createDepartmentLoading,
      called: createDepartmentCalled,
      error: { graphQLErrors: createDepartmentErrors = [] } = {},
    },
  ] = useMutation(CREATE_DEPARTMENT_MUTATION);

  const {
    data: extensionData,
    loading: loadingExtensions,
    error: loadingExtensionsError,
  } = useQuery<{ getUsedExtensions?: { ext: string }[] }>(GET_USED_EXTENSIONS_QUERY);
  const { getUsedExtensions: extensions = undefined } = extensionData || {
    getUsedExtensions: undefined,
  };

  const formMethods = useForm<FormData>({
    defaultValues:
      Number.isFinite(Number(id)) && department
        ? { name: department.name || '', ext: department.ext || '' }
        : { name: '', ext: '' },
  });
  const { reset, handleSubmit } = formMethods;
  const [isExtChanged, setExtChanged] = useState(false);
  useFormErrors(createDepartmentErrors, formMethods);
  useFormErrors(updateDepartmentErrors, formMethods);

  useEffect(() => {
    const idAsNumber = Number(id);
    if (Number.isFinite(idAsNumber)) {
      getDepartment({ variables: { id: idAsNumber } });
    } else if (id !== 'add') {
      navigate('/employee/departments');
    }
  }, [navigate, id, getDepartment]);

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Cancel && !isChanged.isBlocking) {
      navigate(blockedPath || '/employee/departments');
    }
  }, [blockedPath, isChanged, navigate]);

  useEffect(() => {
    if (
      (updateDepartmentCalled && !updateDepartmentLoading && !updateDepartmentErrors.length) ||
      (createDepartmentCalled && !createDepartmentLoading && !createDepartmentErrors.length)
    ) {
      navigate(blockedPath || '/employee/departments');
    }
  }, [
    blockedPath,
    navigate,
    updateDepartmentCalled,
    updateDepartmentLoading,
    updateDepartmentErrors.length,
    createDepartmentCalled,
    createDepartmentLoading,
    createDepartmentErrors.length,
  ]);

  useEffect(() => {
    if (getDepartmentCalled && !getDepartmentLoading && prevGetDepartmentLoading) {
      const dept = department;
      reset({ name: department?.name || '', ext: department?.ext || '' });
      const { deptActiveEmployees, deptInactiveEmployees } = (dept?.employees || []).reduce<{
        deptActiveEmployees: Employee[];
        deptInactiveEmployees: Employee[];
      }>(
        (result, item) => {
          if (item.isActive) {
            result.deptActiveEmployees.push(item);
          } else {
            result.deptInactiveEmployees.push(item);
          }
          return result;
        },
        { deptActiveEmployees: [], deptInactiveEmployees: [] }
      );
      setActiveEmployees(deptActiveEmployees);
      setInactiveEmployees(deptInactiveEmployees);
    }
  }, [reset, department, getDepartmentCalled, getDepartmentLoading, prevGetDepartmentLoading]);

  const handleSubmitForm = useCallback(
    ({ name, ext }: FormData) => {
      if (createDepartmentLoading || updateDepartmentLoading) return;

      setIsChanged({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });

      const isCreating = id === 'add';
      const employeesMapped = [...activeEmployees, ...inactiveEmployees].map((employee) => ({
        id: employee.id,
        isActive: Boolean(employee.isActive),
      }));

      if (isCreating) {
        createDepartment({
          variables: { data: { name, ext, employees: employeesMapped } },
        });
        return;
      }

      const idAsNumber = Number(id);
      if (Number.isNaN(idAsNumber)) {
        Sentry.captureException(Error('Cannot update department, id in NaN.'));
        return;
      }
      updateDepartment({
        variables: { data: { id: idAsNumber, name, ext, employees: employeesMapped } },
      });
    },
    [
      createDepartmentLoading,
      updateDepartmentLoading,
      id,
      createDepartment,
      updateDepartment,
      activeEmployees,
      inactiveEmployees,
      setIsChanged,
    ]
  );

  const deselectEmployee = useCallback(
    (isActive: boolean, index: number) => {
      const predicate = (_: Employee, i: number) => i !== index;
      if (isActive) {
        setActiveEmployees([...activeEmployees].filter(predicate));
        setIsChanged({
          isBlocking: true,
          action: ConfirmAction.Edit,
        });
      } else {
        setInactiveEmployees([...inactiveEmployees].filter(predicate));
        setIsChanged({
          isBlocking: true,
          action: ConfirmAction.Edit,
        });
      }
    },
    [activeEmployees, inactiveEmployees]
  );

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (source?.droppableId && destination?.droppableId) {
        const isActive = destination?.droppableId === 'active';
        setIsChanged({
          isBlocking: true,
          action: ConfirmAction.Edit,
        });
        if (source?.droppableId !== destination?.droppableId) {
          const newActiveEmployees = [...activeEmployees];
          const newInactiveEmployees = [...inactiveEmployees];
          const targetList = isActive ? newActiveEmployees : newInactiveEmployees;
          const sourceList = isActive ? newInactiveEmployees : newActiveEmployees;
          const [moved] = sourceList.splice(source.index, 1);
          targetList.splice(destination.index, 0, { ...moved, isActive });
          setActiveEmployees(newActiveEmployees);
          setInactiveEmployees(newInactiveEmployees);
        } else {
          const targetList = isActive ? [...activeEmployees] : [...inactiveEmployees];
          const [moved] = targetList.splice(source.index, 1);
          targetList.splice(destination.index, 0, moved);
          if (isActive) {
            setActiveEmployees(targetList);
          } else {
            setInactiveEmployees(targetList);
          }
        }
      }
    },
    [activeEmployees, inactiveEmployees, setIsChanged]
  );

  const selectEmployee = useCallback(
    (items: Employee[]) => {
      setActiveEmployees([
        ...activeEmployees,
        ...items.map((item) => ({ ...item, isActive: true })),
      ]);
      setIsChanged({
        isBlocking: true,
        action: ConfirmAction.Edit,
      });
    },
    [activeEmployees, setIsChanged]
  );

  const renderListItem =
    (isActive: boolean) =>
    ({ id: employeeId, ext, user }: Employee, index: number) => (
      <Draggable
        key={`${isActive ? 'active' : 'inactive'}-${index}`}
        draggableId={`${isActive ? 'active' : 'inactive'}-${index}-${employeeId}`}
        index={index}
      >
        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
          <div
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            className={clsx(sortableListClasses.listItem, {
              [sortableListClasses.listItemDragging]: isDragging,
            })}
          >
            {isActive && !isDragging && (
              <div className={classes.listItemLabel}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {index + 1}
                </Typography>
              </div>
            )}
            <div
              className={clsx(classes.listItemContent, {
                [classes.listItemContentDanger]: !isActive && !isDragging,
                [classes.listItemContentDragging]: isDragging,
              })}
            >
              <Flex className={classes.listEmployeeWrapper} justifyContent={'spaceBetween'}>
                <div className={classes.listItemTitle}>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {user.name ?? ''}
                  </Typography>
                </div>
                <Typography
                  className={classes.listEmployeeExt}
                  type={'text3'}
                  color={'tertiary900'}
                >
                  {ext ?? ''}
                </Typography>
              </Flex>
              <div className={classes.listItemIcon}>
                <ChevronUpDownIcon />
              </div>
            </div>
            {!isDragging && (
              <div
                className={classes.listItemAction}
                onClick={() => deselectEmployee(isActive, index)}
              >
                <TrashIcon />
              </div>
            )}
          </div>
        )}
      </Draggable>
    );

  const { extensionsOptions = [], defaultExt = '' } =
    loadingExtensions || loadingExtensionsError || !extensions
      ? { extensionsOptions: [], defaultExt: '' }
      : getFreeExtensions(extensions, isEmergencyServicesAllowed, extLength);

  useEffect(() => {
    if (!department?.ext && defaultExt && !isExtChanged) {
      formMethods.setValue('ext', defaultExt);
    }
  }, [department, formMethods, defaultExt, isExtChanged]);

  const formErrors = useMemo(
    () => [...updateDepartmentErrors, ...createDepartmentErrors, ...getDepartmentErrors],
    [createDepartmentErrors, getDepartmentErrors, updateDepartmentErrors]
  );

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
    setExtChanged(true);
  }

  function handleCancelChanges() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
    navigate(blockedPath || '/employee/departments');
  }

  return (
    <BodyContainer customRootClass={classes.contentBottomSpace}>
      <div className={classes.root}>
        <FormProvider {...formMethods}>
          <form
            id={'department-form'}
            action={''}
            onSubmit={handleSubmit(handleSubmitForm)}
            className={classes.form}
            noValidate
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DEPARTMENT_DATA')}
            </Typography>
            <div className={classes.formContent}>
              <FormFieldRhfUncontrolled
                name={'name'}
                label={translate('DEPARTMENT_NAME')}
                validate={(value: string) =>
                  value === '' ? (translate('EMPTY_FIELD_DEPARTMENT') as string) : true
                }
                onChange={handleFormChange}
              />
              <ComboBoxField
                name="ext"
                valueKey="ext"
                freeSolo
                maxLength={extLength}
                showOptionsIfEmpty={false}
                data={extensionsOptions}
                placeholder={translate('CHOOSE')}
                label={translate('EXTENSION_NUMBER')}
                onChange={handleFormChange}
                onInputChange={(_e, _val, reason) => {
                  if (reason === 'reset') return;
                  handleFormChange();
                }}
                validate={(extValue: string) => {
                  const value = Number(extValue);
                  if (!extValue) {
                    return translate('EXTENSION_NUMBER_REQUIRED') as string;
                  }
                  if (extValue.length !== extLength) {
                    return translate('EXTENSION_NUMBER_WRONG_LENGTH', { length: extLength });
                  }
                  if (Number.isNaN(value)) {
                    return translate('INVALID_EXTENSION');
                  }
                  if (value >= 100 && value <= 199 && !isEmergencyServicesAllowed) {
                    return translate('EXTENSION_NUMBER_FOR_EMERGENCY') as string;
                  }
                  return true;
                }}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => option.ext.startsWith(inputValue));
                }}
              />
            </div>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DEPARTMENT_STRUCTURE')}
            </Typography>
            <div className={classes.formListSection}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <div>
                  <Droppable droppableId={'active'}>
                    {(
                      { droppableProps, innerRef: listInnerRef, placeholder },
                      { isDraggingOver }
                    ) => (
                      <div
                        {...droppableProps}
                        ref={listInnerRef}
                        className={clsx(sortableListClasses.list, {
                          [sortableListClasses.listDraggingOver]: isDraggingOver,
                        })}
                      >
                        <div className={sortableListClasses.listTitle}>
                          <Typography type={'text4'} color={'tertiary900'} bold>
                            {translate('AVAILABLE_EMPLOYEES')}
                          </Typography>
                        </div>
                        {activeEmployees.map(renderListItem(true))}
                        {placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div className={classes.formContent}>
                    <Button
                      title={translate('ADD_EMPLOYEES')}
                      variant={'secondary'}
                      fullWidth
                      className={clsx(classes.addEmployee, { [classes.addEmployeeOpen]: open })}
                      onClick={() => setOpen(true)}
                    />
                    <EmployeesSelection
                      open={open}
                      onClose={() => setOpen(false)}
                      selected={[...activeEmployees, ...inactiveEmployees]}
                      onSelect={selectEmployee}
                    />
                  </div>
                </div>
                <div>
                  <Droppable droppableId={'inactive'}>
                    {(
                      { droppableProps, innerRef: listInnerRef, placeholder },
                      { isDraggingOver }
                    ) => (
                      <div
                        {...droppableProps}
                        ref={listInnerRef}
                        className={clsx(sortableListClasses.list, {
                          [sortableListClasses.listDraggingOver]: isDraggingOver,
                        })}
                      >
                        <div className={sortableListClasses.listTitle}>
                          <Typography type={'text4'} color={'tertiary900'} bold>
                            {translate('TEMPORARY_DISABLED_EMPLOYEES')}
                          </Typography>
                        </div>
                        <div className={classes.defaultElementWidth24}>
                          {inactiveEmployees.map(renderListItem(false))}
                        </div>
                        <div
                          className={clsx(sortableListClasses.dropListPlaceholder, {
                            [sortableListClasses.dropListPlaceholderDraggingOver]: isDraggingOver,
                          })}
                        >
                          <Typography type={'text4'} color={'inherit'}>
                            {translate('DRAG_EMPLOYEE_HERE')}
                          </Typography>
                        </div>
                        {placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
            <BottomButtons>
              <div className={classes.actions}>
                <ControlButtons
                  confirmTitle={'SAVE_CHANGES'}
                  cancelTitle={'CANCEL'}
                  form={'department-form'}
                  onCancelClick={handleCancelChanges}
                  rootConfirmStyles={classes.confirmButton}
                  loading={createDepartmentLoading || updateDepartmentLoading}
                  cancelUnderline
                />
              </div>
            </BottomButtons>
            <FormErrorMessage errors={formErrors} />
          </form>
          <ConfirmDialog
            isBlocked={isChanged.isBlocking}
            onNavigationBlocked={getBlockedPath}
            onSaveChanges={handleSubmit(handleSubmitForm)}
          />
        </FormProvider>
      </div>
    </BodyContainer>
  );
};

export default Department;
