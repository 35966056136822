import React, { FunctionComponent } from 'react';
import { IEmployee } from '@components/typings/interfaces';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import clsx from 'clsx';
import HighlightedText from '@components/HighlightedText';
import { useEmployeesStyles } from '../Employees.styles';

export const EmployeePositionCell: FunctionComponent<ICellProps<IEmployee>> = ({ item }) => {
  const classes = useEmployeesStyles();
  const { position = '' } = item || {};
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  return (
    <div className={clsx(classes.defaultElementWidthFull, classes.textOverflow)}>
      <HighlightedText type={'text'} text={position} query={searchText} />
    </div>
  );
};
