export function resultify(arg) {
  if (arg instanceof Promise) {
    return arg
      .then((data) => ({ type: 'success', data }))
      .catch((error) => ({ type: 'error', error }));
  }
  if (arg instanceof Function) {
    return (...args) => {
      try {
        const data = arg(...args);
        if (data instanceof Promise) {
          return data
            .then((dataFromPromise) => ({ type: 'success', data: dataFromPromise }))
            .catch((error) => ({ type: 'error', error }));
        }
        return { type: 'success', data };
      } catch (error) {
        return { type: 'error', error };
      }
    };
  }

  return undefined;
}
