import {
  responsibleObjectKey,
  scenarioSettingsType,
  scenariosListType,
} from '../IntegrationForm.interfaces';

export const integrationRetailInstallOptions = [
  'RETAIL_INTEGRATION_INSTALL_NOTE_01',
  'RETAIL_INTEGRATION_INSTALL_NOTE_02',
];

export const retailScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'INCOMING_SCENARIOS_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_23'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['INCOMING_SCENARIOS_ITEM_03'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_11',
      edit: scenarioSettingsType.Sites,
      options: ['INCOMING_SCENARIOS_ITEM_24'],
      notInstalled: ['INCOMING_SCENARIOS_ITEM_24'],
      installed: ['INCOMING_SCENARIOS_ITEM_24'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.Responsible,
      options: ['INCOMING_SCENARIOS_ITEM_04'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_07',
      edit: scenarioSettingsType.IncomingUnknown,
      options: ['INCOMING_SCENARIOS_ITEM_25'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_04'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_06'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_08',
      edit: scenarioSettingsType.IncomingMissedUnknown,
      options: ['INCOMING_SCENARIOS_ITEM_25'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_04'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_06'],
    },
  ],
  [
    {
      title: 'OUTGOING_SCENARIOS_TITLE_081',
      edit: scenarioSettingsType.Always,
      options: ['OUTGOING_SCENARIOS_ITEM_18'],
    },
    {
      title: 'OUTGOING_MISSED',
      edit: scenarioSettingsType.OutgoingUnknown,
      options: ['INCOMING_SCENARIOS_ITEM_26'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_04'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_06'],
    },
    {
      title: 'OUTGOING_MISSED_UNKNOWN',
      edit: scenarioSettingsType.OutgoingMissedUnknown,
      options: ['INCOMING_SCENARIOS_ITEM_26'],
      notInstalled: ['SCENARIOS_SETTING_INSTALL_OFF_04'],
      installed: ['SCENARIOS_SETTING_INSTALL_ON_06'],
    },
  ],
  [
    {
      title: 'GENERAL_SCENARIOS_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['GENERAL_SCENARIOS_ITEM_08'],
    },
    {
      title: 'GENERAL_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.CommonCallHistory,
      options: ['GENERAL_SCENARIOS_ITEM_04'],
      notInstalled: ['GENERAL_SCENARIOS_ITEM_04'],
      installed: ['SCENARIOS_SETTING_INSTALL_COMMON'],
    },
  ],
];

export const retailLeadResponsibleObjectKey = (
  settingType?: scenarioSettingsType
): responsibleObjectKey | null => {
  switch (settingType) {
    case scenarioSettingsType.IncomingUnknown:
    case scenarioSettingsType.IncomingMissedUnknown:
      return responsibleObjectKey.DefaultResponsible;
    default:
      return null;
  }
};
