import { IDepartmentRaw } from './department';
import { IEmployeeRaw } from './employee';

export enum callRestrictionEntity {
  Employee = 'employee',
  Department = 'department',
}

export enum CallRestriction {
  Local = 'Local',
  Mn = 'Mn',
  Mg = 'Mg',
}
export interface ICallsRestriction {
  id: number;
  name: string;
  entity?: callRestrictionEntity;
  employeeId?: number | null;
  departmentId?: number | null;
  restriction: CallRestriction[];
  role?: string;
  employeesCount?: number;
  employee?: IEmployeeRaw;
  department?: IDepartmentRaw;
}

export interface ICallRestrictionRaw {
  id: number;
  domainId: number;
  departmentId?: number;
  employeeId?: number;
  employee?: IEmployeeRaw;
  department?: IDepartmentRaw;
  restriction: CallRestriction[];
}

export interface IRestrictionsCallsFilterParams {
  selectAll?: boolean;
  department?: boolean;
  employee?: boolean;
}
