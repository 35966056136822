import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import { useTheme } from '@material-ui/core/styles';
import { SunIcon, SunriseIcon, SunsetIcon, MoonIcon } from '@shared/assets/images/icons';
import { SmartGreetingTimeSlotsTypes } from '../SmartGreeting.constants';

export function useTimeSlots() {
  const theme: IThemeColorOptions = useTheme();
  const { warning, danger, secondary, tertiary } = theme?.color || {};
  const disabledIconColor = tertiary[300];

  const smartGreetingTimeSlots = [
    {
      type: SmartGreetingTimeSlotsTypes.Morning,
      Icon: SunriseIcon,
      greetingCode: 'GOOD_MORNING',
      iconColor: warning[400],
      from: '06:00',
      to: '12:00',
    },
    {
      type: SmartGreetingTimeSlotsTypes.Day,
      Icon: SunIcon,
      greetingCode: 'GOOD_AFTERNOON',
      iconColor: warning[600],
      from: '12:00',
      to: '18:00',
    },
    {
      type: SmartGreetingTimeSlotsTypes.Evening,
      Icon: SunsetIcon,
      greetingCode: 'GOOD_EVENING',
      iconColor: danger[600],
      from: '18:00',
      to: '23:59',
    },
    {
      type: SmartGreetingTimeSlotsTypes.Night,
      Icon: MoonIcon,
      greetingCode: 'GOOD_NIGHT',
      iconColor: secondary[600],
      from: '00:00',
      to: '06:00',
    },
  ];

  return { smartGreetingTimeSlots, disabledIconColor };
}
