import { makeStyles, Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';

export const useBottomButtonsStyles = makeStyles<Theme & IThemeOptions>(
  ({ color: { base, tertiary }, spacing, breakpoints: { down } }) => ({
    wrapper: {
      height: '5.125em',
      position: 'fixed',
      justifyContent: 'start',
      alignItems: 'center',
      borderTop: `solid 1px ${tertiary['100']}`,
      boxShadow: '0px -5px 10px 6px rgba(238, 238, 238, 0.6)',
      display: 'flex',
      padding: '0 2em',
      bottom: 0,
      right: 0,
      left: spacing(10),
      backgroundColor: base,
      zIndex: 7,
      [down('md')]: {
        right: '1em',
        bottom: '1.1em',
      },
    },
    wideSidebarMargin: {
      marginLeft: '9em',
    },
    ...styleConstants,
  })
);
