import { PaymentServiceModelWithId } from '@/utils/paymentServiceModel';
import { ICellProps } from '@components/LazyTable';
import Typography from '@shared/components/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OperationType } from '../Balance.interfaces';

export const BalanceTypeCell = ({ item }: ICellProps<PaymentServiceModelWithId>) => {
  const [translate] = useTranslation();

  if (item) {
    let cellValue = '';
    switch (item.type) {
      case OperationType.Payment:
        cellValue = 'BALANCE_PAYMENT';
        break;
      case OperationType.Credit:
        cellValue = 'BALANCE_CREDIT';
        break;
      case OperationType.OneTime:
      case OperationType.FreezedPayment:
        cellValue = 'BALANCE_ONETIME';
        break;
      case OperationType.Periodic:
      case OperationType.Telephony:
        cellValue = 'BALANCE_PERIODIC';
        break;
      default:
        break;
    }

    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {translate(cellValue)}
      </Typography>
    );
  }

  return <div />;
};
