import React from 'react';
import { createRoot } from 'react-dom/client';

import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import client from '@components/client/client';
import YAMetrika from '@/components/YaMetrika';
import { createTheme, THEMES } from '@shared/theme';
import { i18nClient } from '@shared/i18n';
import ErrorBoundaryCommon from '@shared/components/ErrorBoundaryCommon';
import { makeStyles } from '@material-ui/styles';
import { pxToEm } from '@shared/utils/styles';
import App from './App';

if (process.env.SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: 'aicall-web-ui',
  });
}

const theme = createTheme({ theme: THEMES.LIGHT });

const root = createRoot(document.getElementById('root')!);

const useErrorBoundaryCommonWrapperStyles = makeStyles(() => ({
  pageErrorBody: {
    marginTop: pxToEm(250),
  },
}));

const ErrorBoundaryCommonWrapper = ({ children }: { children: React.ReactNode }) => {
  const classes = useErrorBoundaryCommonWrapperStyles();

  return (
    <ErrorBoundaryCommon pageErrorBodyClassName={classes.pageErrorBody}>
      {children}
    </ErrorBoundaryCommon>
  );
};

root.render(
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18nClient}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundaryCommonWrapper>
            <YAMetrika>
              <App />
            </YAMetrika>
          </ErrorBoundaryCommonWrapper>
        </BrowserRouter>
      </MuiThemeProvider>
    </I18nextProvider>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
