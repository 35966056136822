import React from 'react';
import { useNumbersStyle } from '@/features/FinancialCabinet/Numbers/Numbers.styles';
import { INumberDiscountProps } from '@/features/FinancialCabinet/Numbers/Numbers.interfaces';
import type { NumberModelLocal } from './Numbers.interfaces';

export const NumberDiscount = ({ discount }: INumberDiscountProps) => {
  const classes = useNumbersStyle();
  const postfix = '%';

  // формируем вывод информации
  return (
    <span className={classes.numberDiscount}>
      (-{discount}
      {postfix})
    </span>
  );
};

export const deserializeNumber = (data: NumberModelLocal) => {
  const { numberStatus, statusTill, ...resetProps } = data;

  return {
    ...resetProps,
    statusTill,
    numberStatus,
  };
};
