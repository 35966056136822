import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import { scenarioType } from '../IntegrationForm.interfaces';
import { apiInformation } from '../IntegrationForm.constants';

export function useScenarioPropsHooks(APIName: IntegrationList) {
  const [translate] = useTranslation();
  const [scenarioActiveTab, setScenarioActiveTab] = useState(0);
  const isBitrix24Integration = useMemo(() => APIName === IntegrationList.Bitrix24, [APIName]);
  const isAmoCRMIntegration = useMemo(() => APIName === IntegrationList.AmoCrm, [APIName]);
  const isGravitelIntegration = useMemo(() => APIName === IntegrationList.Gravitel, [APIName]);
  const isYClientsIntegration = useMemo(() => APIName === IntegrationList.YClients, [APIName]);
  const isAPIIntegration = useMemo(() => APIName === IntegrationList.API, [APIName]);
  const isOmnideskIntegration = useMemo(() => APIName === IntegrationList.Omnidesk, [APIName]);
  const isPlanfixIntegration = useMemo(() => APIName === IntegrationList.Planfix, [APIName]);
  const isRoistatIntegration = useMemo(() => APIName === IntegrationList.Roistat, [APIName]);
  const isRetailCRMIntegration = useMemo(() => APIName === IntegrationList.RetailCRM, [APIName]);
  const isSimpleCallsIntegration = useMemo(
    () => APIName === IntegrationList.ProstieZvonki,
    [APIName]
  );

  const { scenarioInformationTabs, isSimpleScenario, crmName } = useMemo(
    () => ({
      crmName: translate(apiInformation[APIName].title),
      isSimpleScenario: apiInformation[APIName].configuration?.settingsType === scenarioType.Simple,
      scenarioInformationTabs: apiInformation[APIName].configuration?.settings,
    }),
    [APIName, translate]
  );

  return {
    scenarioActiveTab,
    setScenarioActiveTab,
    scenarioInformationTabs,
    isSimpleScenario,
    isBitrix24Integration,
    isAmoCRMIntegration,
    isGravitelIntegration,
    isAPIIntegration,
    isYClientsIntegration,
    isOmnideskIntegration,
    isPlanfixIntegration,
    isRoistatIntegration,
    isRetailCRMIntegration,
    isSimpleCallsIntegration,
    crmName,
  };
}
