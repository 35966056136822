import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import Typography from '@shared/components/Typography';
import { IAssistant } from '@components/typings/interfaces';
import { ICellProps } from '@components/LazyTable';

export const ScenarioOnceCostCell: FunctionComponent<ICellProps<IAssistant>> = ({ item }) => {
  if (!item) {
    return <div />;
  }

  const { sum, onceService } = item;

  const cost = sum || onceService?.cost;
  const cellValue = cost ? `${toPrecision(cost, false)} ₽` : '-';
  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {cellValue}
    </Typography>
  );
};
