import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { SquareIcon, CheckSquareIcon, MinusSquareIcon } from '@shared/assets/images/icons';
import { useCheckboxStyles } from './Checkbox.styles';

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  color,
  className,
  disabled,
  ...props
}) => {
  const classes = useCheckboxStyles({ color });

  const icon = <SquareIcon />;

  const indeterminateIcon = <MinusSquareIcon />;

  const checkboxIcon = (
    <CheckSquareIcon
      className={clsx({
        [classes.secondary!]: color === 'secondary',
        [classes.primary!]: color === 'primary',
        [classes.disabled!]: disabled,
      })}
    />
  );

  return (
    <MuiCheckbox
      icon={icon}
      color={color}
      disabled={disabled}
      checkedIcon={checkboxIcon}
      indeterminateIcon={indeterminateIcon}
      className={clsx(classes.root, className)}
      classes={{ disabled: classes.disabled, root: classes.root }}
      size={'small'}
      {...props}
    />
  );
};

export default Checkbox;
