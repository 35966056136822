import React, {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ComboBoxField, { ComboBoxFieldReasonType } from '@shared/components/ComboBoxField';
import Button from '@shared/components/Button';
import { TrashIcon } from '@shared/assets/images/icons';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import Drawer from '@components/Drawer';
import { useTranslation } from 'react-i18next';
import CheckboxField from '@shared/components/CheckboxField';
import SelectField from '@shared/components/SelectField/SelectField';
import { formatNumbersOptions } from '@/features/Integrations/IntegrationForm/IntegrationForm.constants';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import { useQuery } from '@apollo/client';
import { GET_DEPARTMENTS_AND_EMPLOYEES_QUERY } from '@/client/queries';
import SwitchField from '@shared/components/SwitchField';
import Flex from '@shared/components/Flex';
import { IDepOrEmployees } from '@components/typings/interfaces';
import MessageFields from '@/components/MessageFields';
import clsx from 'clsx';
import { AudioMessageTypes } from '@/components/MessageFields/MessageFields';
import { waitingTimeOptions } from '@components/utils';
import { IntegrationContext } from '@/features/Integrations/IntegrationForm/IntegrationForm';
import {
  ICallForResponsibleType,
  IntegrationNextType,
  IncomingNumber,
} from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';

export const CallsForResponsibleMenu: FunctionComponent<ICallForResponsibleType> = ({
  onClose,
  isOpen,
  onConfirm,
}) => {
  const { integrationName, responsibleData, responsibleNumbersList } =
    useContext(IntegrationContext)!; // TODO make null check
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { setValue, watch, handleSubmit } = formMethods;
  const [selectedNumbers, setSelectedNumbers] = useState<IncomingNumber[]>([]);
  const [isAddButtonVisible, setAddButtonVisible] = useState(true);
  const redirectType = watch('scenarioNumbers');
  const isResponsibleActive = watch('isResponsibleActive');
  const isNotificationActive = watch('notification');
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

  const { data: { departmentsAndEmployees = [] } = {} } = useQuery<{
    departmentsAndEmployees: IDepOrEmployees[];
  }>(GET_DEPARTMENTS_AND_EMPLOYEES_QUERY, { fetchPolicy: 'no-cache' });

  const availableNumbers = useMemo(
    () => (responsibleNumbersList ? formatNumbersOptions(responsibleNumbersList) : []),
    [responsibleNumbersList]
  );
  const canSelectNumbersList = useMemo(
    () =>
      availableNumbers.filter(
        (i) => i.responsible === null || i.responsible?.name === integrationName
      ),
    [availableNumbers, integrationName]
  );

  useEffect(() => {
    if (canSelectNumbersList.length === 1 && selectedNumbers.length === 0) {
      setSelectedNumbers(canSelectNumbersList);
    }
  }, [canSelectNumbersList, selectedNumbers]);

  useEffect(() => {
    if (selectedNumbers.length) {
      selectedNumbers.forEach((itemValue, index) => {
        setValue(`responsibleNumber-${index}`, itemValue.id);
      });
    }
  }, [selectedNumbers, setValue]);

  useEffect(() => {
    if (responsibleData) {
      const { dialTimeout, next, nextId, playInfo, numbers, message, fileHash, fileName } =
        responsibleData;
      setValue('isResponsibleActive', true);
      setValue('waitTime', dialTimeout);
      setValue('notification', playInfo);
      if (playInfo) {
        const notificationKey = message ?? fileHash;
        setValue('messageType', message ? AudioMessageTypes.Text : AudioMessageTypes.Audio);
        setValue(message ? 'message' : 'fileHash', notificationKey);
        if (fileHash) {
          setValue('fileName', fileName);
        }
      }
      if (next !== IntegrationNextType.Default) {
        setValue('scenarioNumbers', 'redirectManager');
        setValue('responsible', `${nextId}_${next}`);
      } else {
        setValue('scenarioNumbers', 'redirectScenario');
      }
      const scenarioNumbersList = numbers.reduce(
        (result: IncomingNumber[], item: string | number) => {
          // TODO strange (number | string) id type, check if comparison is working
          const arrItem = availableNumbers.find((i: IncomingNumber) => i.id === item);
          if (arrItem) {
            result.push(arrItem);
          }
          return result;
        },
        []
      );
      setSelectedNumbers(scenarioNumbersList);
    } else {
      if (isOpen) {
        setValue('isResponsibleActive', true);
      }
      setValue('waitTime', 15);
      setValue('notification', false);
      setValue('scenarioNumbers', 'redirectScenario');
      setSelectedNumbers([]);
    }
  }, [responsibleData, setValue, availableNumbers, isOpen]);

  const setDisabledOption = useCallback(
    (item: IncomingNumber) => {
      if (selectedNumbers.filter((i) => i.id === item.id).length) {
        return true;
      }
      if (item.responsible) {
        return item.responsible.name !== integrationName;
      }
      return false;
    },
    [selectedNumbers, integrationName]
  );

  const handleSelectResponsibleNumber = (
    item: IncomingNumber,
    index: number | null,
    reason: ComboBoxFieldReasonType
  ) => {
    if (reason !== 'select-option') {
      return;
    }

    const newItem = [...selectedNumbers];
    if (index !== null) {
      newItem[index] = item;
    } else {
      newItem.push(item);
      setAddButtonVisible(true);
      setValue('responsibleNumber', '');
    }
    setSelectedNumbers(newItem);
  };

  const handleDeleteResponsibleNumber = useCallback(
    (index: number) => {
      const newItem = [...selectedNumbers];
      newItem.splice(index, 1);
      setSelectedNumbers(newItem);
    },
    [selectedNumbers]
  );

  const handleSubmitResponsibleForm = (formData) => {
    if (selectedNumbers.length === 0) return;
    const {
      notification,
      waitTime,
      responsible,
      scenarioNumbers,
      messageType,
      message,
      fileHash,
      fileName,
    } = formData;
    const setMessageType = () => {
      if (!notification) return undefined;
      return messageType === AudioMessageTypes.Text ? { message } : { fileHash, fileName };
    };
    const nextType =
      scenarioNumbers === 'redirectManager'
        ? responsible.split('_')[1]
        : IntegrationNextType.Default;
    const nextIdType = responsible ? parseInt(responsible.split('_')[0], 10) : null;
    const numbersIdList = selectedNumbers.map((i) => i.id);
    onConfirm({
      playInfo: notification,
      dialTimeout: waitTime,
      next: nextType,
      nextId: nextIdType,
      numbers: numbersIdList,
      ...setMessageType(),
    });
  };

  function handleSwitchResponsible() {
    onConfirm(null);
  }

  const renderNumbersOptions = (numberOption: IncomingNumber) => (
    <Flex direction={'column'} className={classes.numberOptionLine}>
      <Typography type={'text3'} color={'tertiary900'}>
        {numberOption.phone}
      </Typography>
      {numberOption.responsible && numberOption.responsible.name !== integrationName ? (
        <Typography type={'text4'} color={'tertiary600'}>
          {`${translate('USED_IN')} ${numberOption.responsible.name}`}
        </Typography>
      ) : (
        <Typography type={'text4'} color={'tertiary600'}>
          {numberOption.name}
        </Typography>
      )}
    </Flex>
  );

  const renderSelectedResponsibleNumbers = (arr: IncomingNumber[]) => {
    const isNumberNotSelected = arr.length === 0;
    const isSingleNumberUsed = arr.length === 1 && canSelectNumbersList.length === 1;
    const renderSelectedNumbers = () =>
      arr.map((numb, index) => (
        <Flex key={`respNumber-${index}`}>
          <div className={classes.scenarioSettingMenuSelectBox}>
            {isSingleNumberUsed ? (
              <div className={classes.responsibleSingleNumber}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {numb.phone}
                </Typography>
              </div>
            ) : (
              <ComboBoxField
                name={`responsibleNumber-${index}`}
                getOptionDisabled={setDisabledOption}
                valueKey={'id'}
                titleKey={'phone'}
                renderOption={renderNumbersOptions}
                onChange={(e, i, r) => {
                  handleSelectResponsibleNumber(i, index, r);
                }}
                data={availableNumbers}
              />
            )}
          </div>
          {canSelectNumbersList.length !== 1 && (
            <Button
              className={classes.responsibleNumberDeleteButton}
              variant={'tertiary'}
              onClick={() => handleDeleteResponsibleNumber(index)}
            >
              <TrashIcon />
            </Button>
          )}
        </Flex>
      ));

    return (
      <>
        {renderSelectedNumbers()}
        {!isSingleNumberUsed && (
          <Flex>
            {selectedNumbers.length !== canSelectNumbersList.length ? (
              <div className={classes.scenarioSettingMenuItemBox}>
                {isAddButtonVisible ? (
                  <Button
                    title={translate('ADD')}
                    className={classes.integrationAddButton}
                    onClick={() => setAddButtonVisible(false)}
                    variant={'secondary'}
                  />
                ) : (
                  <ComboBoxField
                    name={'responsibleNumber'}
                    placeholder={translate('CHOOSE_NUMBER')}
                    valueKey={'id'}
                    titleKey={'phone'}
                    getOptionDisabled={setDisabledOption}
                    renderOption={renderNumbersOptions}
                    onChange={(e, i, r) => {
                      handleSelectResponsibleNumber(i, null, r);
                    }}
                    data={availableNumbers}
                    validate={() => !isNumberNotSelected}
                  />
                )}
                {isNumberNotSelected && (
                  <div className={classes.integrationNumbersNotSelectedWarning}>
                    <Typography type={'text5'} color={'danger600'}>
                      {translate('CHOOSE_NUMBER_REQUIRED_MESSAGE')}
                    </Typography>
                  </div>
                )}
              </div>
            ) : (
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.responsibleAvailableNumberMessage}
              >
                <Typography type={'text4'} color={'tertiary600'} bold>
                  {translate('NO_AVAILABLE_NUMBERS')}
                </Typography>
              </Flex>
            )}
          </Flex>
        )}
      </>
    );
  };

  return (
    <Drawer
      title={'INCOMING_RESPONSIBLE_TITLE'}
      variant={'persistent'}
      size={'lg'}
      open={isOpen}
      classes={{ paper: classes.defaultElementWidth33 }}
      onClose={onClose}
      primaryButton={
        isResponsibleActive
          ? {
              title: 'APPLY',
              props: {
                type: 'submit',
                form: 'responsible-form',
                loading: isSaveButtonLoading,
              },
            }
          : {
              title: 'APPLY',
              onClick: handleSwitchResponsible,
              props: {
                loading: isSaveButtonLoading,
              },
            }
      }
      secondaryButton={{
        title: 'CANCEL',
        onClick: onClose,
      }}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(handleSubmitResponsibleForm)}
          action={''}
          id={'responsible-form'}
        >
          <div className={classes.scenarioSettingMenuContent}>
            <Flex className={classes.integrationOnOffSwitch}>
              <SwitchField
                name={'isResponsibleActive'}
                customLabel={
                  <Typography type={'text2'} color={'tertiary900'}>
                    {translate('MOVE_TO_RESPONSIBLE_TITLE')}
                  </Typography>
                }
              />
            </Flex>
            <Flex
              className={!isResponsibleActive ? classes.responsibleWrapper : undefined}
              direction={'column'}
            >
              <div className={classes.scenarioSettingMenuTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('RESPONSIBLE_CALLS_TITLE')}
                </Typography>
              </div>
              <div className={classes.scenarioSettingMenuItem}>
                {renderSelectedResponsibleNumbers(selectedNumbers)}
              </div>
              <div className={classes.scenarioSettingMenuItem}>
                <CheckboxField
                  name={'notification'}
                  label={translate('RESPONSIBLE_MESSAGE_FLAG')}
                />
                <div
                  className={clsx(
                    classes.integrationScenarioNumbersList,
                    classes.integrationScenarioNotificationPadding,
                    {
                      [classes.CRMEmployeeListHide]: !isNotificationActive,
                    }
                  )}
                >
                  <MessageFields
                    defaultValue={AudioMessageTypes.Text}
                    required={isNotificationActive}
                    setIsSaveButtonLoading={setIsSaveButtonLoading}
                  />
                </div>
              </div>
              <div className={classes.scenarioSettingMenuTitle}>
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {translate('WAITING_TIME_FROM_MANAGER')}
                </Typography>
              </div>
              <div className={classes.scenarioSettingMenuItem}>
                <SelectField
                  data={waitingTimeOptions}
                  name={'waitTime'}
                  valueKey={'key'}
                  titleKey={'timeValue'}
                  defaultValue={15}
                  className={classes.defaultElementWidth9}
                  translating
                />
              </div>
              <div className={classes.scenarioSettingMenuTitle}>
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {translate('MANAGER_RESPONSE_TITLE')}
                </Typography>
              </div>
              <div className={classes.scenarioSettingMenuItem}>
                <RadioGroup defaultValue={'redirectManager'} name={'scenarioNumbers'}>
                  <FormControlLabel
                    value={'redirectManager'}
                    control={<Radio color={'secondary'} />}
                    label={translate('REDIRECT_MANAGER_TITLE')}
                  />
                  {redirectType === 'redirectManager' && (
                    <div className={classes.integrationResponsibleMessage}>
                      <div className={classes.integrationScenarioNumbersList}>
                        <ComboBoxField
                          name={'responsible'}
                          placeholder={translate('SELECT_EMPLOYEE_OR_DEPARTMENT')}
                          valueKey={'listId'}
                          titleKey={'name'}
                          data={departmentsAndEmployees}
                          groupBy={(option: { type: string }) => translate(option.type || '', '')}
                          validate={(responsibleValue: string) => {
                            if (!responsibleValue) {
                              return translate('EMPTY_RESPONSIBLE_FIELD') as string;
                            }
                            return true;
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <FormControlLabel
                    value={'redirectScenario'}
                    control={<Radio color={'secondary'} />}
                    label={translate('REDIRECT_SCENARIO_TITLE')}
                  />
                </RadioGroup>
              </div>
            </Flex>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default memo(CallsForResponsibleMenu);
