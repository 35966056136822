import React from 'react';
import { IRoute } from '@components/Routes';
import Notification from '@/features/Settings/SettingsNotifications/Notification/Notification';
import BusinessCard from '@/features/Settings/SettingsBusinessCards/BusinessCard/BusinessCard';
import StatisticsByNumbers from '@/features/Statistics/StatisticsByNumbers/StatisticsByNumbers';
import ApologySMS from '@/features/Settings/SettingsApologySMS/ApologySMS/ApologySMS';
import {
  BriefCaseIcon,
  NumbersIcon,
  UserIcon,
  CpuIcon,
  StatisticsIcon,
  SettingsIcon,
  ScenariosIcon,
  HistoryIcon,
  ZapIcon,
  HomeIcon,
} from '@shared/assets/images/icons';
import StatisticsByEmployees from './features/Statistics/StatisticsByEmployees/StatisticsByEmployees';
import { AddScenario } from './features/FinancialCabinet/Scenarios/AddScenario';
import { SettingsNotificationsMissedCalls } from './features/Settings/SettingsNotifications/SettingsNotificationsMissedCalls';
import SettingsNotifications from './features/Settings/SettingsNotifications/SettingsNotifications';
import ScenarioPage from './features/Scenario/ScenarioPage';
import DialogsPage from './features/Dialogs/DialogsPage';
import ContactsPage from './features/Contacts/ContactsPage';
import HistoryPage from './features/History/HistoryPage';
import StatisticsByCalls from './features/Statistics/StatisticsByCalls/StatisticsByCalls';
import { ResetPasswordPage } from './features/ResetPassword/ResetPasswordPage';
import Employees from './features/Employees/Employees';
import CreateEmployee from './features/Employee/CreateEmployee';
import EditEmployee from './features/Employee/EditEmployee';
import Profile from './features/Profile/Profile';
import Departments from './features/Departments/Departments';
import Department from './features/Department/Department';
import IncomingNumbers from './features/IncomingNumbers/IncomingNumbers';
import {
  SettingsBlackList,
  SettingsBLF,
  SettingsBusinessCards,
  SettingsCallRecords,
  SettingsList,
  SettingsSIP,
  SettingsTimeZone,
  SettingsTone,
  SettingsTune,
  SettingsApologySMS,
} from './features/Settings';
import {
  Balance,
  CallDetails,
  Numbers,
  Payments,
  ScenariosList,
  Tariffs,
} from './features/FinancialCabinet';
import { DocumentsList, PayerData } from './features/Documents';
import {
  ContractPersonalData,
  ContractTariff,
  ContractScenario,
  ContractPhoneNumber,
  ContractConfirm,
} from './features/Documents/Contract/modules';

import DesktopPage from './features/Desktop/DesktopPage';
import OutgoingCalls from './features/OutgoingCalls/OutgoingCalls';
import IncomingNumber from './features/IncomingNumber/IncomingNumber';
import CallsRestrictions from './features/CallsRestrictions/CallsRestrictions';
import { AuthLayout } from './layouts/Auth/AuthLayout';
import DashboardLayout from './layouts/Dashboard/DashboardLayout';
import { SignInForm } from './features/SignIn/SignInForm';
import { SignOutForm } from './features/SignOut/SignOutForm';
import Integrations from './features/Integrations/Integrations';
import IntegrationForm from './features/Integrations/IntegrationForm/IntegrationForm';
import { FeaturesType } from '../typings';
import { OptionType } from './client/generated/graphql';

export const operatorPrivateRoutesProps: IRoute[] = [
  {
    path: 'history',
    element: <DashboardLayout />,
    icon: <HistoryIcon />,
    titleCode: 'HISTORY',
    sort: 2,
    feature: FeaturesType.History,
    children: [
      {
        path: '/',
        element: <HistoryPage />,
        titleCode: 'HISTORY',
        excluded: true,
      },
    ],
  },
  {
    path: 'profile',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'PROFILE',
    excluded: true,
    children: [
      {
        path: 'view',
        element: <Profile />,
        titleCode: 'PROFILE',
        excluded: true,
      },
    ],
  },
  {
    path: 'signout',
    element: <SignOutForm />,
    excluded: true,
  },
  {
    path: 'logout',
    element: <SignOutForm />,
    excluded: true,
  },
];

export const userPrivateRoutesProps: IRoute[] = [
  {
    path: 'history',
    element: <DashboardLayout />,
    icon: <HistoryIcon />,
    titleCode: 'HISTORY',
    sort: 2,
    feature: FeaturesType.History,
    children: [
      {
        path: '/',
        element: <HistoryPage />,
        titleCode: 'HISTORY',
        excluded: true,
      },
    ],
  },
  {
    path: 'statistics',
    element: <DashboardLayout />,
    icon: <StatisticsIcon />,
    titleCode: 'STATISTICS',
    option: OptionType.Statistic,
    feature: FeaturesType.Statistics,
    sort: 3,
    children: [
      {
        path: 'by-calls',
        element: <StatisticsByCalls />,
        titleCode: 'STATISTICS',
        breadCrumbTitle: 'STATISTICS_BY_CALLS',
        option: OptionType.Statistic,
        feature: FeaturesType.Statistics,
      },
      {
        path: 'by-numbers',
        element: <StatisticsByNumbers />,
        titleCode: 'STATISTICS',
        breadCrumbTitle: 'STATISTICS_BY_NUMBERS',
        option: OptionType.Statistic,
        feature: FeaturesType.StatisticsNumbers,
      },
      {
        path: 'by-employees',
        element: <StatisticsByEmployees />,
        titleCode: 'STATISTICS',
        breadCrumbTitle: 'STATISTICS_BY_EMPLOYEES',
        option: OptionType.Statistic,
        feature: FeaturesType.StatisticsEmployees,
      },
    ],
  },
  {
    path: 'profile',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'PROFILE',
    excluded: true,
    children: [
      {
        path: 'view',
        element: <Profile />,
        titleCode: 'PROFILE',
        excluded: true,
      },
    ],
  },
  {
    path: 'signout',
    element: <SignOutForm />,
    excluded: true,
  },
  {
    path: 'logout',
    element: <SignOutForm />,
    excluded: true,
  },
];

export const administratorPrivateRoutesProps: IRoute[] = [
  ...userPrivateRoutesProps,
  {
    path: 'employee',
    element: <DashboardLayout />,
    icon: <UserIcon />,
    titleCode: 'EMPLOYEES',
    disableRoute: true,
    sort: 4,
    option: OptionType.Accounts,
    feature: FeaturesType.Employees,
    children: [
      {
        path: 'employees',
        titleCode: 'EMPLOYEES',
        option: OptionType.Accounts,
        feature: FeaturesType.Employees,
        children: [
          {
            path: '/',
            element: <Employees />,
            titleCode: 'EMPLOYEES',
            excluded: true,
          },
          {
            path: 'add',
            element: <CreateEmployee />,
            titleCode: 'ADD_EMPLOYEE',
            excluded: true,
          },
          {
            path: ':id',
            element: <EditEmployee />,
            titleCode: 'EDIT_EMPLOYEE',
            excluded: true,
          },
        ],
      },
      {
        path: 'departments',
        titleCode: 'DEPARTMENTS',
        option: OptionType.Accounts,
        feature: FeaturesType.Departments,
        children: [
          {
            path: '/',
            element: <Departments />,
            titleCode: 'DEPARTMENTS',
            excluded: true,
          },
          {
            path: ':id',
            element: <Department />,
            titleCode: 'EDIT_DEPARTMENT',
            excluded: true,
          },
        ],
      },
    ],
  },
  {
    path: 'assistant',
    element: <DashboardLayout />,
    icon: <CpuIcon />,
    titleCode: 'ASSISTANT',
    disableRoute: true,
    sort: 5,
    option: OptionType.BasicPbx,
    feature: FeaturesType.AssistantsUI,
    children: [
      {
        path: 'scenarios',
        element: <ScenarioPage />,
        titleCode: 'SCENARIOS',
      },
      {
        path: 'dialogs',
        element: <DialogsPage />,
        titleCode: 'DIALOGS',
      },
      {
        path: 'contacts',
        element: <ContactsPage />,
        titleCode: 'CONTACTS',
      },
      {
        path: 'archive',
        element: <div>{'Archives'}</div>,
        titleCode: 'ARCHIVE',
      },
    ],
  },
  {
    path: 'calls',
    element: <DashboardLayout />,
    icon: <NumbersIcon />,
    titleCode: 'CALLS_MANAGEMENT',
    disableRoute: true,
    sort: 6,
    option: OptionType.BasicPbx,
    feature: FeaturesType.Calls,
    children: [
      {
        path: 'incoming',
        titleCode: 'INCOMING_CALLS',
        option: OptionType.BasicPbx,
        feature: FeaturesType.IncomingCalls,
        children: [
          {
            path: '/',
            element: <IncomingNumbers />,
            titleCode: 'EDIT_INCOMING_NUMBER',
            excluded: true,
          },
          {
            path: ':id',
            element: <IncomingNumber />,
            titleCode: 'EDIT_INCOMING_NUMBER',
            excluded: true,
          },
        ],
      },
      {
        path: 'outgoing',
        titleCode: 'OUTGOING_CALLS',
        option: OptionType.BasicPbx,
        feature: FeaturesType.OutgoingCalls,
        children: [
          {
            path: '/',
            element: <OutgoingCalls />,
            titleCode: 'OUTGOING_CALLS',
          },
        ],
      },
      {
        path: 'restrictions',
        titleCode: 'CALLS_RESTRICTIONS',
        option: OptionType.BasicPbx,
        feature: FeaturesType.CallsRestrictions,
        children: [
          {
            path: '/',
            element: <CallsRestrictions />,
            titleCode: 'CALLS_RESTRICTIONS',
          },
        ],
      },
    ],
  },
  {
    path: 'settings',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'SETTINGS',
    disableRoute: true,
    sort: 7,
    option: OptionType.BasicPbx,
    feature: FeaturesType.BasicSettings,
    children: [
      {
        path: '/',
        element: <SettingsList />,
        titleCode: 'SETTINGS',
        option: OptionType.BasicPbx,
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'record-talks',
        element: <SettingsCallRecords />,
        option: OptionType.Records,
        titleCode: 'SETTINGS_RECORD_TALKS',
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'timezone',
        element: <SettingsTimeZone />,
        titleCode: 'SETTINGS_TIMEZONE',
        option: OptionType.BasicPbx,
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'tune',
        element: <SettingsTune />,
        titleCode: 'SETTINGS_TUNE',
        option: OptionType.BasicPbx,
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'blf',
        element: <SettingsBLF />,
        titleCode: 'SETTINGS_BLF',
        option: OptionType.BasicPbx,
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'black-list',
        element: <SettingsBlackList />,
        titleCode: 'SETTINGS_BLACK_LIST',
        option: OptionType.BasicPbx,
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'tone',
        element: <SettingsTone />,
        titleCode: 'SETTINGS_TONE',
        option: OptionType.BasicPbx,
        feature: FeaturesType.BasicSettings,
      },
      {
        path: 'sip',
        element: <SettingsSIP />,
        titleCode: 'SETTINGS_SIP',
      },
      {
        path: 'notifications',
        option: OptionType.Missed,
        element: <SettingsNotifications />,
        titleCode: 'NOTIFICATIONS',
        feature: FeaturesType.Notifications,
        children: [
          {
            path: '/',
            titleCode: 'NOTIFICATIONS',
            element: <SettingsNotificationsMissedCalls />,
          },
          {
            path: 'add',
            element: <Notification />,
            titleCode: 'NOTIFICATION_SETTINGS',
          },
          {
            path: ':id',
            element: <Notification />,
            titleCode: 'NOTIFICATION_SETTINGS',
          },
        ],
      },
    ],
  },
  {
    path: 'business-cards',
    element: <DashboardLayout />,
    titleCode: 'BUSINESS_CARDS',
    option: OptionType.BusinessCards,
    feature: FeaturesType.BusinessCards,
    excluded: true,
    sort: 3,
    children: [
      {
        path: '/greetings',
        titleCode: 'BUSINESS_CARDS',
        breadCrumbTitle: 'SMS_GREETINGS',
        option: OptionType.BusinessCards,
        feature: FeaturesType.BusinessCards,
        children: [
          {
            path: '/',
            element: <SettingsBusinessCards />,
            titleCode: 'BUSINESS_CARDS',
          },
          {
            path: 'add',
            element: <BusinessCard />,
            titleCode: 'SETTING_BUSINESS_CARD',
          },
          {
            path: ':id',
            element: <BusinessCard />,
            titleCode: 'SETTING_BUSINESS_CARD',
          },
        ],
      },
      {
        path: '/apology',
        titleCode: 'BUSINESS_CARDS',
        breadCrumbTitle: 'SMS_APOLOGYS',
        option: OptionType.BusinessCards,
        feature: FeaturesType.BusinessCards,
        children: [
          {
            path: '/',
            element: <SettingsApologySMS />,
            titleCode: 'BUSINESS_CARDS',
          },
          {
            path: 'add',
            element: <ApologySMS />,
            titleCode: 'SETTING_APOLOGY_BUSINESS_CARD',
          },
          {
            path: ':id',
            element: <ApologySMS />,
            titleCode: 'SETTING_APOLOGY_BUSINESS_CARD',
          },
        ],
      },
    ],
  },
  {
    path: 'integrations',
    element: <DashboardLayout />,
    icon: <ZapIcon />,
    titleCode: 'INTEGRATIONS',
    disableRoute: true,
    sort: 8,
    feature: FeaturesType.Integrations,
    children: [
      {
        path: '/',
        element: <Integrations />,
        titleCode: 'INTEGRATIONS',
        feature: FeaturesType.Integrations,
      },
      {
        path: 'available',
        element: <Integrations />,
        titleCode: 'INTEGRATIONS',
        feature: FeaturesType.Integrations,
      },
      {
        path: 'preview-integration',
        element: <IntegrationForm />,
        titleCode: 'INTEGRATION_CONNECTION_FORM',
        feature: FeaturesType.Integrations,
        children: [
          {
            path: ':type',
            element: <IntegrationForm />,
            titleCode: 'INTEGRATION_CONNECTION_FORM',
          },
        ],
      },
      {
        path: 'add-integration',
        element: <IntegrationForm />,
        titleCode: 'INTEGRATION_CONNECTION_FORM',
        feature: FeaturesType.Integrations,
        children: [
          {
            path: ':type',
            element: <IntegrationForm />,
            titleCode: 'INTEGRATION_CONNECTION_FORM',
          },
        ],
      },
      {
        path: 'edit-integration',
        element: <IntegrationForm />,
        titleCode: 'INTEGRATION_CONNECTION_FORM',
        feature: FeaturesType.Integrations,
        children: [
          {
            path: ':id',
            element: <IntegrationForm />,
            titleCode: 'INTEGRATION_CONNECTION_FORM',
          },
        ],
      },
    ],
  },
];

export const ownerPrivateRoutesProps: IRoute[] = [
  ...administratorPrivateRoutesProps,
  {
    path: 'dashboard',
    element: <DashboardLayout />,
    icon: <HomeIcon />,
    titleCode: 'DASHBOARD',
    sort: 1,
    feature: FeaturesType.Dashboard,
    children: [
      {
        path: '/',
        element: <DesktopPage />,
        titleCode: 'DASHBOARD',
        feature: FeaturesType.Dashboard,
        excluded: true,
      },
    ],
  },
  {
    path: 'cabinet',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'FINANCE',
    excluded: true,
    feature: FeaturesType.Balance,
    children: [
      {
        path: 'balance',
        element: <Balance />,
        titleCode: 'BALANCE',
        feature: FeaturesType.Balance,
      },
      {
        path: 'details',
        element: <CallDetails />,
        titleCode: 'CALL_DETAILS',
        feature: FeaturesType.Balance,
      },
      {
        path: 'payments',
        element: <Payments />,
        titleCode: 'ACCOUNTS',
        feature: FeaturesType.Balance,
      },
    ],
  },
  {
    path: 'numbers',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'NUMBERS',
    excluded: true,
    feature: FeaturesType.Numbers,
    children: [
      {
        path: 'numbers',
        element: <Numbers />,
        titleCode: 'NUMBERS',
        feature: FeaturesType.Numbers,
      },
    ],
  },
  {
    path: 'register',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'FINANCE',
    feature: FeaturesType.Documents,
    excluded: true,
    children: [
      {
        path: 'personal-data',
        element: <ContractPersonalData />,
        titleCode: 'DOCUMENTS_FORM',
        feature: FeaturesType.Documents,
      },
      {
        path: 'select-tariff',
        element: <ContractTariff />,
        titleCode: 'DOCUMENTS_FORM',
        feature: FeaturesType.Documents,
      },
      {
        path: 'phone-number',
        element: <ContractPhoneNumber />,
        titleCode: 'DOCUMENTS_FORM',
        feature: FeaturesType.Documents,
      },
      {
        path: 'scenario',
        element: <ContractScenario />,
        titleCode: 'DOCUMENTS_FORM',
        feature: FeaturesType.Documents,
      },
      {
        path: 'confirm',
        element: <ContractConfirm />,
        titleCode: 'DOCUMENTS_FORM',
        feature: FeaturesType.Documents,
      },
    ],
  },
  {
    path: 'tariff-list',
    element: <DashboardLayout />,
    icon: <BriefCaseIcon />,
    titleCode: 'MENU_PRICING',
    excluded: true,
    feature: FeaturesType.Tariff,
    children: [
      {
        path: 'tariff',
        element: <Tariffs />,
        titleCode: 'MENU_PRICING',
        feature: FeaturesType.Tariff,
      },
    ],
  },
  {
    path: 'documents',
    element: <DashboardLayout />,
    icon: <SettingsIcon />,
    titleCode: 'DOCUMENTS',
    feature: FeaturesType.Documents,
    excluded: true,
    children: [
      {
        path: 'documents-list',
        element: <DocumentsList />,
        titleCode: 'DOCUMENTS',
        feature: FeaturesType.Documents,
      },
      {
        path: 'payer-data',
        element: <PayerData />,
        titleCode: 'PAYER_DATA',
        feature: FeaturesType.Documents,
      },
    ],
  },
  {
    path: 'scenarios-list',
    element: <DashboardLayout />,
    icon: <ScenariosIcon />,
    titleCode: 'SCENARIOS',
    feature: FeaturesType.Assistants,
    excluded: true,
    children: [
      {
        path: 'scenarios',
        titleCode: 'SCENARIOS',
        feature: FeaturesType.Assistants,
        children: [
          {
            path: '/',
            element: <ScenariosList />,
            titleCode: 'SCENARIOS',
            excluded: true,
          },
          {
            path: 'add',
            element: <AddScenario />,
            titleCode: 'SCENARIOS',
            excluded: true,
            feature: FeaturesType.Assistants,
          },
        ],
      },
    ],
  },
];
export const systemPrivateRoutesProps: IRoute[] = [...ownerPrivateRoutesProps];
export const routesReducer = (result: Map<string, IRoute>, item: IRoute): Map<string, IRoute> => {
  const { path, children } = item;
  if (path && path !== '/' && path !== 'add' && path !== ':id' && path !== ':type') {
    const params = path.split('/');
    result.set(params[params.length - 1], item);
  }
  if (children && children.length) {
    return new Map([...result.entries(), ...children.reduce(routesReducer, new Map()).entries()]);
  }
  return result;
};

export const privateRoutesPropsMap: Map<string, IRoute> = ownerPrivateRoutesProps.reduce<
  Map<string, IRoute>
>(routesReducer, new Map());

export const publicRoutesProps: IRoute[] = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '/signin',
        element: <SignInForm />,
      },
      {
        path: '/restore-password/:token',
        element: <ResetPasswordPage />,
      },
      {
        path: '/signout',
        element: <SignOutForm />,
      },
    ],
  },
];
