import Button from '@shared/components/Button';
import clsx from 'clsx';
import React from 'react';
import { ButtonIconProps } from './ButtonIcon.interfaces';
import { useButtonIconStyles } from './ButtonIcon.styles';

export const ButtonIcon = ({ icon, color, className, ...rest }: ButtonIconProps) => {
  const classes = useButtonIconStyles();

  const renderContent = () => {
    let result: React.ReactNode | undefined = icon;
    if (!result) {
      return result;
    }
    result = (
      <div
        className={clsx(
          classes.buttonContentWrapper,
          color === 'error' && classes.buttonContentWrapperError
        )}
      >
        {result}
      </div>
    );
    return result;
  };

  return (
    <Button className={clsx(classes.root, className)} color={color} {...rest}>
      {renderContent()}
    </Button>
  );
};
