import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { useSettingsBlackListStyles } from '../SettingsBlackList.styles';
import { IBlackList } from '../SettingsBlackList.interfaces';

export const BlackListNumberCell: FunctionComponent<ICellProps<IBlackList>> = ({ item }) => {
  const classes = useSettingsBlackListStyles();
  const { phone = '' } = item || {};
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('searchText') || null;

  return (
    <div className={classes.NumberCell}>
      <HighlightedText type={'phone'} text={phone} query={searchText} />
    </div>
  );
};
