import { gql } from '@apollo/client';

export const AUTHENTICATION_STATE_QUERY = gql`
  query authenticationState {
    isAuthenticated @client
    role @client
  }
`;

export const WIDE_SIDEBAR_STATE = gql`
  query wideSidebar {
    isWide @client
  }
`;
