import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import Alert from '@shared/components/Alert';
import { pxToEm } from '@/utils';

type Props = {
  errors: string[];

  className?: string;
  scrollInto?: boolean;
  titleCode?: string;
};

export const useFormErrorStyles = makeStyles({
  errorTypography: {
    display: 'block',
  },
  marginTop4: {
    marginTop: pxToEm(4),
  },
});

export const FormErrors = ({
  errors = [],
  titleCode = 'UNABLE_TO_SAVE_INCOMPLETE_SETTINGS',
  className,
  scrollInto = true,
}: Props) => {
  const classes = useFormErrorStyles();
  const [translate] = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const renderError = (text: string, index: number) => {
    if (!text) {
      return null;
    }
    return (
      <div className={classes.marginTop4}>
        <Typography
          className={classes.errorTypography}
          key={`form-error-${index}`}
          color={'tertiary900'}
          type={'text3'}
        >
          {`- ${text}`}
        </Typography>
      </div>
    );
  };

  if (errors?.length) {
    if (scrollInto) {
      containerRef?.current?.scrollIntoView();
    }

    return (
      <Alert
        title={translate(titleCode)}
        status="danger"
        description={errors.map(renderError)}
        rootRef={containerRef}
      />
    );
  }
  return null;
};

export default FormErrors;
