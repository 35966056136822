export enum FormArrowSwitchType {
  Increase = 'increase',
  Decrease = 'decrease',
}

export interface IFormArrowSwitcher {
  rootClass?: string;
  value?: number;
  disabled?: boolean;
  defaultValue?: number;
  name?: string;
  step?: number;
  min?: number;
  max?: number;
  minTooltip?: string;
  maxTooltip?: string;
  onChange?: () => void;
}
