import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const usePaymentsStyle = makeStyles(
  ({ color: { tertiary, primary, base } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    lineItem: {
      paddingLeft: '1em',
    },
    headLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '1em',
      height: '2em',
      minHeight: '2em',
      backgroundColor: tertiary['50'],
      borderBottom: `solid 1px ${base}`,
      '& div': {
        boxSizing: 'content-box',
      },
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    cellLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
      '& div': {
        boxSizing: 'content-box',
      },
      '&:hover': {
        backgroundColor: tertiary['50'],
      },
    },
    nameCellProperties: {
      textAlign: 'left',
      paddingLeft: '2em',
    },
    documentViewBtn: {
      padding: '0.5em',
    },
    textHover: {
      cursor: 'pointer',
      '&:hover *': {
        textDecoration: 'underline',
        color: primary['800'],
      },
    },
    action: {
      display: 'flex',
      height: '2em',
      marginLeft: '1em',
    },
    centerWithMargin: {
      margin: '0 auto',
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
