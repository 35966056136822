export const formatDuration = (dur: number): string => {
  if (Number.isNaN(dur)) {
    return '00:00:00';
  }
  const h = Math.floor(dur / 60 / 60);
  const m = Math.floor((dur - h * 60) / 60);
  const s = Math.floor(dur - h * 60 - m * 60);

  return `${h}:`.padStart(3, '0') + `${m}:`.padStart(3, '0') + `${s}`.padStart(2, '0');
};
