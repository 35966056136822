import { OptionType, TariffModel } from '@/client/generated/graphql';
import { ResultOptionsType } from '@components/typings/interfaces';

type TariffDescription = {
  options: string[];
  remarks: string[];
};

export const getTariffOptionsList = (tariffList: TariffModel[] | null | undefined) => {
  if (!tariffList?.length) {
    return null;
  }

  return tariffList.reduce((result: ResultOptionsType[], item) => {
    let monthFee = 0;
    let fixedFee = 0;
    let accountPrice = 0;
    let accountMin = 0;
    let accountMax = 0;
    let accountDiscount = 0;
    let additionalAccountDiscount = 0;
    let additionalAccountPrice = 0;
    let additionalAccountId = 0;
    let baseAccountPrice = 0;
    let baseAdditionalAccountPrice = 0;
    let accountId = 0;
    let optionsNamesList: Array<string> = [];
    let noteMessage = null;
    let hasDescription = true;
    if (item.description) {
      try {
        const description = JSON.parse(item.description) as TariffDescription;
        if (description?.options?.length > 0) {
          optionsNamesList = description?.options;
          hasDescription = true;
          if (description?.remarks?.length > 0) {
            noteMessage = description?.remarks[0];
          }
        }
      } catch (e) {
        hasDescription = false;
      }
    }
    item.options.forEach((optionItem) => {
      if (optionItem.type === OptionType.AdditionalAccounts) {
        additionalAccountId = optionItem.id;
        if (typeof optionItem.monthlyFee === 'number') {
          additionalAccountPrice = optionItem.monthlyFee;
        }
        if (typeof optionItem.monthlyDiscount === 'number') {
          additionalAccountDiscount = optionItem.monthlyDiscount;
        }
        if (typeof optionItem.baseMonthlyFee === 'number') {
          baseAdditionalAccountPrice = optionItem.baseMonthlyFee;
        }
      } else {
        fixedFee += optionItem.setupFee || 0;
        monthFee += optionItem.monthlyFee || 0;
      }
      if (optionItem.type === OptionType.Accounts) {
        accountId = optionItem.id;
        if (typeof optionItem.monthlyDiscount === 'number') {
          accountDiscount = optionItem.monthlyDiscount;
        }
        if (typeof optionItem.baseMonthlyFee === 'number') {
          baseAccountPrice = optionItem.baseMonthlyFee;
        }

        if (optionItem.params.min) {
          if (typeof optionItem.monthlyFee === 'number') {
            accountPrice = optionItem.monthlyFee;
          }
          accountMin = optionItem.params.min;
        }
        if (optionItem.params.max) {
          accountMax = optionItem.params.max;
        }
      }
      if (!hasDescription) {
        optionsNamesList.push(optionItem.name);
        if (optionItem.remark) {
          noteMessage = optionItem.remark;
        }
      }
    });
    result.push({
      id: item.id,
      options: optionsNamesList,
      fixedPrice: fixedFee,
      monthPrice: monthFee,
      additionalAccountId,
      additionalAccountPrice,
      accountId,
      accountDiscount,
      baseAccountPrice,
      additionalAccountDiscount,
      baseAdditionalAccountPrice,
      accountMin,
      accountMax,
      accountPrice,
      noteMessage,
      tariffName: item.name,
    });
    return result;
  }, []);
};

export default getTariffOptionsList;
