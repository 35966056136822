import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { ICellProps } from '@components/LazyTable';
import { IApologySMS } from '../SettingsApologySMS.interface';

export const ApologySMSMessageFormat: FunctionComponent<ICellProps<IApologySMS>> = ({ item }) => {
  const { domainNumbers, allNumbers } = item || {};
  const [translate] = useTranslation();

  if (!domainNumbers && !allNumbers) return null;

  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {translate('SMS_APOLOGY')}
    </Typography>
  );
};
