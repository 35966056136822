export const getFreeExtensions = (
  extensions: { ext: string }[],
  fullPool?: boolean,
  extLength = 3
) => {
  const suffix = 10 ** (extLength - 1);
  const extensionsOptions = !extensions
    ? []
    : [...Array(9 * suffix).keys()]
        .splice(fullPool || extLength > 3 ? suffix : 200)
        .reduce<string[]>((result, item) => {
          if (!extensions?.some(({ ext }) => ext === `${item}`)) {
            result.push(`${item}`);
          }
          return result;
        }, [])
        .sort((extA, extB) =>
          // eslint-disable-next-line no-nested-ternary
          Number(extA) < Number(extB) ? -1 : Number(extA) > Number(extB) ? 1 : 0
        );

  if (extensionsOptions.length === 0) return {};
  let defaultExt = extensionsOptions.find((item) => Number(item) > 7 * suffix);
  if (!defaultExt) {
    [defaultExt] = extensionsOptions;
  }

  const extensionOptionsAsObject = extensionsOptions.map((ext) => ({ ext }));

  return { extensionsOptions: extensionOptionsAsObject, defaultExt };
};
