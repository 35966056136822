import React, { FunctionComponent } from 'react';
import { Diagram } from '@components/Diagram';
import { Chart as ChartJS, ChartConfiguration, ChartType, InteractionItem } from 'chart.js';
import { ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export type LineDiagramConfiguration = Pick<ChartConfiguration<ChartType>, 'options' | 'data'> & {
  onClick?: (points: InteractionItem[]) => void;
};

export const LineDiagram: FunctionComponent<LineDiagramConfiguration> = ({
  options,
  data,
  onClick,
}) => {
  const theme: ThemeOptions = useTheme();
  const { tertiary } = theme?.color || {};
  const plugins = [
    {
      type: 'line',
      id: 'verticalLine',
      afterDraw: (chart: ChartJS) => {
        const elements = chart.tooltip?.getActiveElements();
        if (elements?.length) {
          const { x } = elements[0]!.element;
          const yAxis = chart.scales.y;
          const { ctx } = chart;
          ctx.save();
          ctx.beginPath();
          if (yAxis) {
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
          }
          ctx.lineWidth = 1;
          // eslint-disable-next-line prefer-destructuring
          ctx.strokeStyle = tertiary[600];
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  return (
    <Diagram type={'line'} options={options} data={data} plugins={plugins} onClick={onClick} />
  );
};

export default LineDiagram;
