import { scenariosListType } from '../IntegrationForm.interfaces';
import SimpleCallsHelp01 from './images/simple_calls_help_01.png';
import SimpleCallsHelp02 from './images/simple_calls_help_02.png';

export const SimpleCallsScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
];

export const SIMPLE_CALLS_SLIDER_IMAGES = [
  { title: 'WHERE_SEARCH_SIMPLE_CALLS_MESSAGE', img: SimpleCallsHelp01 },
  { title: 'WHERE_SEARCH_SIMPLE_CALLS_MESSAGE_02', img: SimpleCallsHelp02 },
];
