import { makeStyles } from '@material-ui/core';

export const UseSortFilterStyles = makeStyles(
  ({ color: { tertiary, primary } }) => ({
    chevronIcon: {
      width: '0.875em',
      height: '0.875em',
      marginLeft: '0.25em',
      color: tertiary[600],
      '&:hover': {
        color: primary[600],
        cursor: 'pointer',
      },
    },
    activeChevron: {
      color: primary[700],
    },
    removeIcon: {
      padding: '2px 0.25em 0',
      '&:hover': {
        cursor: 'pointer',
        opacity: '0.6',
      },
    },
    filtered: {
      color: primary[700],
    },
    filteredIconsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  }),
  {
    index: 2,
  }
);
