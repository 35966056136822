import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import CellPreloader from './CellPreloader';
import CellSkeleton from './CellSkeleton';
import HeaderCell from './HeaderCell';
import { DEFAULT_ROW_HEIGHT, SKELETON_CELL_ROWS_COUNT } from './LazyTable.constants';
import { IColumn, ILazyTableProps, RowRenderType } from './LazyTable.interfaces';
import { useLazyTableStyles } from './LazyTable.styles';
import RowCell from './RowCell';

export const LazyTable = <
  T extends { id: string | number; classRow?: string } = { id: string | number; classRow?: string },
>({
  columns,
  data,
  pending,
  rowLoading,
  tableLoading,
  loadMoreRows,
  emptyDataMessage,
  hasNextPage,
  renderEmptyDataMessage,
  rowHeight,
  customRowHeight,
  beforeRowRenderer,
  contentVerticalAlign = 'flexStart',
  rowClick,
  classes: classesFromProps,
}: ILazyTableProps<T>) => {
  const classes = useLazyTableStyles();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<List | null>(null);
  const dataLength = data.length;
  const itemCount = hasNextPage && hasNextPage() ? dataLength + 1 : dataLength;
  const isItemLoaded = (index: number) => (hasNextPage && !hasNextPage()) || index < dataLength;

  const setRowHeight = (index: number) => {
    if (customRowHeight) {
      return customRowHeight(index);
    }
    return rowHeight || DEFAULT_ROW_HEIGHT;
  };

  useEffect(() => {
    listRef?.current?.resetAfterIndex(0);
  }, [data]);

  const renderHeaderCell = (column: IColumn<T>, index: number) => (
    <HeaderCell<T> key={`table-header-cell-${String(column.id)}-${index}`} column={column} />
  );

  const renderTableBody = () => {
    if (pending) {
      return Array.from(Array(SKELETON_CELL_ROWS_COUNT)).map((item: number, index: number) => (
        <Flex
          key={`table-row-${index}`}
          alignItems={'flexStart'}
          justifyContent={'flexStart'}
          className={classes.bodyLine}
        >
          {columns.map((column: IColumn<T>, cellIndex: number) => (
            <CellSkeleton
              key={`table-cell-skeleton-${String(column.id)}-${cellIndex}`}
              column={column}
              columnIndex={cellIndex}
              maxLength={columns.length}
            />
          ))}
        </Flex>
      ));
    }
    if ((rowLoading && data.length === 0) || tableLoading) {
      return <CellPreloader />;
    }
    if (!loadMoreRows && data.length !== 0) {
      return (
        <Flex direction={'column'} className={classes.tableBodyWrapper}>
          <AutoSizer>
            {({ height, width }) => (
              <div style={{ width, height, overflowY: 'auto', overflowX: 'hidden' }}>
                <div>
                  {data.map((row, rowIndex) => (
                    <React.Fragment key={`table-row-${row.id}`}>
                      {beforeRowRenderer && beforeRowRenderer(rowIndex)}
                      <Flex
                        alignItems={'flexStart'}
                        justifyContent={'flexStart'}
                        className={clsx(classes.bodyLine, row.classRow)}
                        onClick={(event) => rowClick && rowClick(event, row)}
                      >
                        {columns.map((column: IColumn<T>, columnIndex: number) => (
                          <RowCell<T>
                            key={`table-cell-${row.id}-${columnIndex}`}
                            data={row}
                            column={column}
                            row={rowIndex}
                            col={columnIndex}
                          />
                        ))}
                      </Flex>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </AutoSizer>
        </Flex>
      );
    }
    if (loadMoreRows && data.length !== 0) {
      const rowRender = ({ index, style }: RowRenderType) => {
        const rowItem = data[index];

        return (
          <div key={index} style={{ ...style, width: headerRef.current?.offsetWidth }}>
            {!rowItem ? (
              <CellPreloader />
            ) : (
              <>
                {beforeRowRenderer && beforeRowRenderer(index)}
                <Flex
                  key={`table-row-${rowItem.id}`}
                  alignItems={contentVerticalAlign}
                  justifyContent={'flexStart'}
                  className={classes.bodyLine}
                  style={{ height: rowHeight || 'auto' }}
                  onClick={(event) => rowClick && rowClick(event, rowItem)}
                >
                  {columns.map((column: IColumn<T>, cellIndex: number) => (
                    <RowCell<T>
                      key={`table-cell-${rowItem.id}-${cellIndex}`}
                      data={rowItem}
                      column={column}
                    />
                  ))}
                </Flex>
              </>
            )}
          </div>
        );
      };

      return (
        <Flex direction={'column'} className={classes.tableBodyWrapper}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreRows}
                threshold={20}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    style={{ overflowY: 'auto', overflowX: 'hidden' }}
                    className={classes.list}
                    height={height}
                    itemCount={itemCount}
                    itemSize={(index) => setRowHeight(index)}
                    onItemsRendered={onItemsRendered}
                    ref={(r) => {
                      listRef.current = r;
                      return typeof ref === 'function' ? ref(r) : ref;
                    }}
                    width={width}
                  >
                    {rowRender}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Flex>
      );
    }
    return null;
  };

  const renderEmptyMessages = () => {
    if (data.length === 0 && !rowLoading && !tableLoading) {
      if (renderEmptyDataMessage) return renderEmptyDataMessage;
      if (emptyDataMessage) {
        return (
          <Flex
            alignItems={'center'}
            fullWidth
            justifyContent={'center'}
            className={classes.emptyMessageCell}
          >
            <Typography color={'tertiary900'} type={'text3'}>
              {emptyDataMessage}
            </Typography>
          </Flex>
        );
      }
      return null;
    }
    return null;
  };

  return (
    <Flex direction={'column'} className={clsx(classes.root, classesFromProps?.root)}>
      <div ref={headerRef} className={classes.tableHeader}>
        <Flex alignItems={'flexStart'} justifyContent={'flexStart'} className={classes.headLine}>
          {columns.map(renderHeaderCell)}
        </Flex>
      </div>
      {renderTableBody()}
      {renderEmptyMessages()}
    </Flex>
  );
};

export default LazyTable;
