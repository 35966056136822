import React, { FunctionComponent } from 'react';
import { formatDuration } from '@shared/utils';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { ICallDetailsList } from '../CallDetails.interfaces';

export const CallDetailsDurationCell: FunctionComponent<ICellProps<ICallDetailsList>> = ({
  item,
}) => {
  if (item) {
    const { duration } = item;

    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {formatDuration(duration)}
      </Typography>
    );
  }

  return <div />;
};
