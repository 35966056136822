import { OptionType, TariffOptionModel } from '@/client/generated/graphql';

export const getTariffExactOptions = (cTariffOptions: TariffOptionModel[]) => {
  const accountsOption = cTariffOptions.find((opt) => opt.type === OptionType.Accounts);
  const additionalAccountsOption = cTariffOptions.find(
    (opt) => opt.type === OptionType.AdditionalAccounts
  );
  const integrationsOption = cTariffOptions.find((opt) => opt.type === OptionType.Integrations);
  const baseEmployeeCount = accountsOption?.domainTariffOptionParams?.count || 0;
  const discountLimit = accountsOption?.domainTariffOptionParams?.discountLimit || 0;
  const additionalEmployeeCount = additionalAccountsOption?.domainTariffOptionParams?.count || 0;
  const integrationCount = integrationsOption?.domainTariffOptionParams?.count || 0;
  const totalEmployeeCount = baseEmployeeCount + additionalEmployeeCount;

  return {
    accountsOption,
    discountLimit,
    additionalAccountsOption,
    integrationsOption,
    baseEmployeeCount,
    additionalEmployeeCount,
    integrationCount,
    totalEmployeeCount,
  };
};
