import { Role } from '@/client/generated/graphql';

const rolesUpperCased: Record<string, Role | undefined> = {
  [Role.Admin.toUpperCase()]: Role.Admin,
  [Role.Manager.toUpperCase()]: Role.Manager,
  [Role.Operator.toUpperCase()]: Role.Operator,
  [Role.Owner.toUpperCase()]: Role.Owner,
  [Role.Partner.toUpperCase()]: Role.Partner,
  [Role.Supporter.toUpperCase()]: Role.Supporter,
  [Role.Sysadmin.toUpperCase()]: Role.Sysadmin,
  [Role.User.toUpperCase()]: Role.User,
};

export const hasAdminRole = (role: Role | null | undefined): boolean => {
  return (
    role === Role.Admin ||
    role === Role.Owner ||
    role === Role.Manager ||
    role === Role.Supporter ||
    role === Role.Sysadmin
  );
};

export const hasFinancialRole = (role: Role | null | undefined): boolean => {
  return (
    role === Role.Owner ||
    role === Role.Manager ||
    role === Role.Supporter ||
    role === Role.Sysadmin
  );
};

export const hasSystemRole = (role: Role | null | undefined): boolean => {
  return role === Role.Manager || role === Role.Supporter || role === Role.Sysadmin;
};

/**
 * Should be used in places where we get role from server
 * as string and want to convert it to Role enum.
 * This function is used to address issues with Role typings on server.
 * In future, when those issues are resolved, `string` can be removed
 * from union type, or this function may be deleted.
 */
export const getRole = (str: string | null | undefined): Role | null => {
  if (typeof str !== 'string') {
    return null;
  }
  return rolesUpperCased[str.toUpperCase()] || null;
};
