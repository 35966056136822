import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { ICallDetailsList } from '../CallDetails.interfaces';

export const CallDetailsCostCell: FunctionComponent<ICellProps<ICallDetailsList>> = ({ item }) => {
  if (item) {
    const { cost } = item;
    const cellValue = `${toPrecision(cost)} ₽`;

    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {cellValue}
      </Typography>
    );
  }

  return <div />;
};
