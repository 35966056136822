import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useApologySMSStyles = makeStyles(
  ({ color: { primary } }) => ({
    removeApologySMSDialog: {
      width: '24em',
    },
    removePopupText: {
      paddingBottom: '1.5em',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      margin: '1em 0 0',
    },
    root: {
      width: '100%',
      height: '100%',
    },
    action: {
      width: '16em',
      height: '2em',
      margin: '0 1em',
    },
    actionButton: {
      height: '2em',
      width: '2em',
      padding: 'initial',
    },
    numberContainer: {
      display: 'flex',
      maxWidth: '13.5em',
      minWidth: '11em',
      paddingBottom: '0.75em',
      '&:last-child': {
        paddingBottom: '0',
      },
    },
    dispatchContainer: {
      width: '10em',
    },
    actionsFormWrapper: {
      display: 'flex',
      justifyContent: 'end',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '8em',
      marginRight: '1.5em',
      alignItems: 'center',
    },
    SmsIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
