import { OptionType, TariffOptionCategory, TariffOptionModel } from '@/client/generated/graphql';
import {
  GET_ACTIVE_TARIFF_QUERY,
  GET_INTEGRATIONS_QUERY,
  GET_SELECTED_TARIFF_QUERY,
} from '@/client/queries';
import { FREE_INTEGRATION_LIST } from '@/features/Integrations/Integrations.constants';
import {
  getTariffComputedPrices,
  getTariffEmployeePrices,
  getTariffExactOptions,
} from '@/utils/tariff';
import { useQuery } from '@apollo/client';
import { TariffRequestData, TariffRequestOptions } from '@components/typings/interfaces';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import { useMemo } from 'react';
import { IAdditionalOptionsType } from 'typings/tariffs';
import getTariffOptionsList from '../getTariffOptionList';

export function useTariffProps(
  selectedOptions: Array<boolean | undefined>,
  accountAmount?: number,
  integrationsAmount?: number,
  storeRecordsId?: number
) {
  const {
    data: tariffListData,
    refetch: getTariffListData,
    loading: loadingActiveTariff,
  } = useQuery(GET_ACTIVE_TARIFF_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: tariffData,
    refetch: getCurrentTariffData,
    loading: loadingSelectedTariff,
  } = useQuery(GET_SELECTED_TARIFF_QUERY);

  const { data: integrationListData } = useQuery(GET_INTEGRATIONS_QUERY);

  const tariffList = tariffListData?.getActiveTariffs;
  const currentTariff = tariffData?.getDomain?.tariff;
  const currentEmployeeCount: number = tariffData?.getDomain?.currentEmployeesCount || 0;
  const currentTariffEmployeesCount: number = tariffData?.getDomain?.employeesNumber || -1;
  const tariffRequest = tariffData?.getDomain?.tariffChangeRequest;
  const isDataLoading = loadingActiveTariff || loadingSelectedTariff;

  const installedIntegrationsCount: number = useMemo(() => {
    if (!integrationListData) return 0;
    return (
      integrationListData?.getIntegrations.filter(
        (i) => !FREE_INTEGRATION_LIST.includes(i.type as IntegrationList)
      ).length || 0
    );
  }, [integrationListData]);

  const optionsResultList = useMemo(() => getTariffOptionsList(tariffList), [tariffList]);

  const accountOption = useMemo(
    () => tariffList?.[0].options.find((tOption) => tOption.type === OptionType.Accounts),
    [tariffList]
  );

  const isTariffActive = useMemo(() => currentTariff?.active, [currentTariff]);

  const additionalAccountOption = useMemo(
    () => tariffList?.[0].options.find((tOption) => tOption.type === OptionType.AdditionalAccounts),
    [tariffList]
  );

  const additionalTariffOptions: IAdditionalOptionsType = useMemo(() => {
    if (!tariffList) return {};
    const additionalOptions = tariffList[0].options.filter(
      (tOption) =>
        tOption.category === TariffOptionCategory.Additional &&
        tOption.type !== OptionType.AdditionalAccounts &&
        tOption.type !== OptionType.Accounts
    );
    return additionalOptions.reduce(
      (result: IAdditionalOptionsType, aOption: TariffOptionModel) => {
        const currentOption =
          currentTariff?.options.find((opt: TariffOptionModel) => opt.id === aOption.id) || aOption;
        if (currentOption.type === OptionType.Records) {
          const isOptionCreated = result[OptionType.Records];
          if (isOptionCreated) {
            result[OptionType.Records].push(currentOption);
            return result;
          }
          return {
            ...result,
            [OptionType.Records]: [currentOption],
          };
        }
        return {
          ...result,
          [currentOption.type]: [currentOption],
        };
      },
      {}
    );
  }, [tariffList, currentTariff?.options]);

  const additionalOptionsKeyList: string[] = useMemo(
    () => Object.keys(additionalTariffOptions),
    [additionalTariffOptions]
  );

  const { accountsOption, discountLimit, additionalAccountsOption } = useMemo(
    () => getTariffExactOptions(currentTariff?.options || []),
    [currentTariff?.options]
  );

  const newTariffOptions = useMemo(() => {
    if (currentTariff?.options) {
      const tariffOptions = [...currentTariff.options].filter(
        (opt) => tariffRequest?.options.some((req) => req.tariffOptionId === opt.id)
      );

      tariffRequest?.options.forEach((req) => {
        if (!currentTariff.options.some((opt) => req.tariffOptionId === opt.id)) {
          const foundOption = tariffList?.[0].options.find((opt) => opt.id === req.tariffOptionId);
          if (foundOption) {
            tariffOptions.push(foundOption);
          }
        }
      });
      return tariffOptions;
    }
    return [];
  }, [currentTariff?.options, tariffList, tariffRequest?.options]);

  const {
    accountsOption: newAccountsOption,
    additionalAccountsOption: newAdditionalAccountsOption,
  } = useMemo(() => getTariffExactOptions(currentTariff?.options || []), [currentTariff?.options]);

  const isDiscountLoss = useMemo(
    () => (accountAmount || 1) < (discountLimit || 0),
    [accountAmount, discountLimit]
  );
  const tariffRequestData: TariffRequestData = useMemo(() => {
    const defaultData = { accounts: 0, options: {} };
    if (!tariffRequest || !additionalTariffOptions || !optionsResultList) return defaultData;
    const additionalAccountId = additionalAccountOption?.id;
    const accountId = accountOption?.id;

    const requestAccounts: number = tariffRequest.options.reduce((res, opt) => {
      if (opt.tariffOptionId === accountId || opt.tariffOptionId === additionalAccountId) {
        return res + (opt.count || 0);
      }
      return res;
    }, 0);
    const requestOptions = additionalOptionsKeyList.reduce(
      (res: TariffRequestOptions, optionKey: string) => {
        const optionList = additionalTariffOptions[optionKey];
        let inRequestItem;
        if (optionList.length > 1) {
          inRequestItem = optionList.find(
            (opt) => tariffRequest?.options?.some((req) => req.tariffOptionId === opt.id)
          );
        } else {
          const optionId = optionList[0].id;
          inRequestItem = tariffRequest?.options?.find(
            (reqOpt) => reqOpt.tariffOptionId === optionId
          );
        }
        return {
          ...res,
          [optionKey]: {
            isRequested: !!inRequestItem,
            amount: (inRequestItem as { count?: number })?.count || 0,
          },
        };
      },
      {}
    );
    return {
      accounts: requestAccounts,
      options: requestOptions,
    };
  }, [
    tariffRequest,
    additionalTariffOptions,
    optionsResultList,
    additionalAccountOption,
    accountOption,
    additionalOptionsKeyList,
  ]);

  const isTariffRequested =
    tariffRequestData.accounts !== 0 || Object.keys(tariffRequestData.options).length > 0;

  const isFutureDiscountLoss = useMemo(
    () =>
      tariffRequestData?.accounts
        ? (tariffRequestData?.accounts || 1) < (discountLimit || 0)
        : isDiscountLoss,
    [tariffRequestData?.accounts, discountLimit, isDiscountLoss]
  );

  const employeeComputedPrices = useMemo(
    () =>
      getTariffEmployeePrices(
        optionsResultList?.[0],
        currentTariff,
        accountAmount,
        accountsOption,
        additionalAccountsOption,
        isDiscountLoss
      ),
    [
      accountAmount,
      accountsOption,
      additionalAccountsOption,
      currentTariff,
      isDiscountLoss,
      optionsResultList,
    ]
  );
  const nextMonthEmployeeComputedPrices = useMemo(
    () =>
      !isTariffRequested
        ? employeeComputedPrices
        : getTariffEmployeePrices(
            optionsResultList?.[0],
            currentTariff,
            tariffRequestData.accounts,
            newAccountsOption,
            newAdditionalAccountsOption,
            isFutureDiscountLoss
          ),
    [
      currentTariff,
      employeeComputedPrices,
      isFutureDiscountLoss,
      isTariffRequested,
      newAccountsOption,
      newAdditionalAccountsOption,
      optionsResultList,
      tariffRequestData.accounts,
    ]
  );

  const optionsComputedPrices = getTariffComputedPrices(
    additionalTariffOptions,
    selectedOptions,
    currentTariff?.options,
    accountAmount,
    integrationsAmount,
    storeRecordsId,
    isDiscountLoss
  );
  const requestStoreRecordsId = additionalTariffOptions[OptionType.Records]?.find(
    (opt) => tariffRequest?.options?.some((req) => req.tariffOptionId === opt.id)
  )?.id;
  let newIntegrations = integrationsAmount;
  if (isTariffRequested && tariffRequestData?.options[OptionType.Integrations]?.isRequested) {
    newIntegrations =
      (tariffRequest?.options.find((o) => o.type === OptionType.Integrations)?.count || 0) /
      (tariffRequestData?.accounts || accountAmount || 1);
  }
  const newSelectedOptions = !isTariffRequested
    ? selectedOptions
    : additionalOptionsKeyList.map((key: string) => !!tariffRequestData?.options[key]?.isRequested);

  const nextMonthOptionsComputedPrices = useMemo(
    () =>
      !isTariffRequested
        ? optionsComputedPrices
        : getTariffComputedPrices(
            additionalTariffOptions,
            newSelectedOptions,
            newTariffOptions,
            tariffRequestData?.accounts || accountAmount,
            newIntegrations,
            requestStoreRecordsId || storeRecordsId,
            isFutureDiscountLoss
          ),
    [
      accountAmount,
      additionalTariffOptions,
      isFutureDiscountLoss,
      isTariffRequested,
      newIntegrations,
      newTariffOptions,
      newSelectedOptions,
      optionsComputedPrices,
      requestStoreRecordsId,
      storeRecordsId,
      tariffRequestData?.accounts,
    ]
  );

  function updateTariffData() {
    getTariffListData();
    getCurrentTariffData();
  }

  const nextMonthPrice = isFutureDiscountLoss
    ? nextMonthOptionsComputedPrices.price.reduce(
        (sum: number, price: number) => sum + price,
        nextMonthEmployeeComputedPrices.price
      )
    : nextMonthOptionsComputedPrices.discountPrice.reduce(
        (sum: number, discount: number, index: number) =>
          sum + (discount || nextMonthOptionsComputedPrices.price[index]),
        nextMonthEmployeeComputedPrices.discountPrice || nextMonthEmployeeComputedPrices.price
      );

  return {
    accountOption,
    additionalAccountOption,
    additionalOptionsKeyList,
    additionalTariffOptions,
    optionsResultList,
    employeeComputedPrices,
    optionsComputedPrices,
    currentTariff,
    currentEmployeeCount,
    currentTariffEmployeesCount,
    installedIntegrationsCount,
    tariffRequest,
    tariffRequestData,
    isTariffRequested,
    updateTariffData,
    getTariffListData,
    getCurrentTariffData,
    isDiscountLoss,
    nextMonthPrice,
    isTariffActive,
    isDataLoading,
    loadingSelectedTariff,
  };
}
