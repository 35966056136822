import Typography from '@shared/components/Typography';
import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ZXCVBNScore } from 'zxcvbn';
import { passwordScores } from '../PasswordField.constants';

export const PasswordScore: FunctionComponent<{ score?: ZXCVBNScore }> = ({ score }) => {
  const [translate] = useTranslation();

  if (!score) {
    return null;
  }

  const { color, titleCode } = passwordScores[score];
  return (
    <Typography color={color} pxToEmSize={16}>
      {translate(titleCode)}
    </Typography>
  );
};

export default memo(PasswordScore);
