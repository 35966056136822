import React, { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';
import { Divider as MuiDivider, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTranslation } from 'react-i18next';
import { IMenuItem, IMenuProps } from '@components/Menu/Menu.interfaces';
import { useMenuStyles } from '@components/Menu/Menu.styles';
import Typography from '@shared/components/Typography/Typography';

export const Menu: FunctionComponent<PropsWithChildren<IMenuProps>> = <T,>({
  id,
  title,
  className,
  onSelect,
  items,
  children,
}: PropsWithChildren<IMenuProps<T>>) => {
  const classes = useMenuStyles();
  const [translate] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleSelect = (value: T) => () => {
    onSelect(value);
    handleClose();
  };

  const renderItem = (
    { titleCode, Icon, id: itemId }: IMenuItem<T | 'separator'>,
    index: number
  ) => {
    if (itemId === 'separator') {
      return (
        <MuiDivider key={`menu-${id}-item-${itemId}-${index}`} className={classes.menuDivider} />
      );
    }

    return (
      <MuiMenuItem key={`menu-${id}-item-${itemId}-${index}`} onClick={handleSelect(itemId)}>
        <ListItemIcon className={classes.menuIcon}>{Icon ? <Icon /> : undefined}</ListItemIcon>
        <Typography type={'text3'} color={'tertiary900'}>
          {translate(titleCode || 'DEFAULT_MENU_TITLE')}
        </Typography>
      </MuiMenuItem>
    );
  };

  const renderTitle = () => {
    if (title) {
      return (
        <div className={classes.menuTitle}>
          <Typography type={'text4'} color={'tertiary900'}>
            {title}
          </Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div aria-controls={id} aria-haspopup onClick={handleClick} className={className}>
        {children}
      </div>
      <MuiMenu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderTitle()}
        {items.map(renderItem)}
      </MuiMenu>
    </>
  );
};

export default Menu;
