// функция конвертирует произвольную строку в строку формата XX-XX-... или XXX-XX-XX-... в зависимости от четности кол-ва цифр
// исключение строки длиной пять символов X-XX-XX

import { ICountryData } from './phoneNumbers.interfaces';
import { PHONE_CODES } from './phoneNumbers.constants';

export const phoneBlocks = (phone?: string): string => {
  if (!phone) return '';
  if (phone.length === 1) return phone;
  let oddPrefix;
  let evenPart;
  if (phone.length % 2) {
    oddPrefix = phone[0] + (phone.length === 5 ? '-' : '');
    evenPart = phone.substring(1);
  } else {
    oddPrefix = '';
    evenPart = phone;
  }
  // TODO fix
  // eslint-disable-next-line no-unsafe-optional-chaining
  return oddPrefix + evenPart.match(/.{1,2}/g)?.join('-');
};

/* RUSSIA = PHONE_CODES['7'],
    russianCityData, */
export const getCityData = (
  countryData: ICountryData,
  phone: string
): {
  cityCode: string;
  restOfPhone?: string;
} => {
  let zero = false;
  let cityCodeLen;
  let cityCode;
  let restOfPhone;
  // проверяем на наличие нулей в коде города
  if (countryData.zeroHack && phone[0] === '0') {
    zero = true;
    // eslint-disable-next-line no-param-reassign
    phone = phone.substring(1);
  }
  // сначала сравниваем с городами-исключениями
  if (countryData.exceptions_max !== 0) {
    for (
      cityCodeLen = countryData.exceptions_max;
      cityCodeLen >= countryData.exceptions_min;
      // eslint-disable-next-line no-plusplus
      cityCodeLen--
    ) {
      if (countryData.exceptions.indexOf(parseInt(phone.substring(0, cityCodeLen), 10)) >= 0) {
        cityCode = (zero ? '0' : '') + phone.substring(0, cityCodeLen);
        restOfPhone = phone.substring(cityCodeLen);
        break;
      }
    }
  }
  // в случае неудачи с исключениями вырезаем код города в соответствии с длиной по умолчанию
  if (!cityCode) {
    cityCode = phone.substring(0, countryData.cityCodeLength);
    restOfPhone = phone.substring(countryData.cityCodeLength);
  }
  return {
    cityCode,
    restOfPhone,
  };
};

export const isMobilePhone = (countryCode: string, phone: string, operatorCode: number): boolean =>
  // eslint-disable-next-line radix
  countryCode === '7' && parseInt(String(phone[0])) === operatorCode;

export const formatPhone = (
  phone: number | string,
  options?: { asterisk?: boolean; plus?: boolean }
): string => {
  if (!phone) {
    return '';
  }
  if (typeof phone === 'number') {
    // eslint-disable-next-line no-param-reassign
    phone = String(phone);
  }

  const ALL_CHARACTERS_EXCEPT_NUMBERS_REG_EXP = options?.asterisk ? /[^0-9*]+/g : /[^0-9]+/g;

  let plus = true;
  // очистка от лишнего мусора с сохранением информации о "+" в начале номера
  //            plus = (phone[0] === '+');

  let formattedPhone;

  // eslint-disable-next-line no-param-reassign
  phone = phone.replace(ALL_CHARACTERS_EXCEPT_NUMBERS_REG_EXP, '');
  if (options) {
    if (options.plus === false) {
      plus = false;
    }
    // other options
  }
  // Для случая, если кто-то в РФ ввел мобильный или городской номер без "+7" или "8"
  // Т.е. все телефонные номера длиной 10 символов считаются российскими
  // if (!options || !options.strict) {
  //     if (phone.length === 10 && (!options || !options.asterisk)) {
  //         russianCityData = getCityData(RUSSIA, phone);
  //         return '+7 (' + russianCityData.cityCode + ') ' + phoneBlocks(russianCityData.restOfPhone);
  //     }
  // }
  // Если телефон длиннее 7 символов, начинаем поиск страны
  if (phone.length > 7) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const countryCode in PHONE_CODES) {
      const countryData = PHONE_CODES[countryCode]!;
      const countryCodeLen = countryCode.length;
      let cityData;
      if (countryCode === phone.substring(0, countryCodeLen)) {
        // как только страна обнаружена, урезаем телефон до уровня кода города
        // eslint-disable-next-line no-param-reassign
        phone = phone.substring(countryCodeLen);

        if (
          isMobilePhone(countryCode, phone, 9) ||
          (isMobilePhone(countryCode, phone, 8) && phone.length === 10)
        ) {
          const mobileCode = phone.substring(0, 3);
          const restOfPhone = phone.substring(3);
          formattedPhone = `+7 (${mobileCode}) ${phoneBlocks(restOfPhone)}`;
        } else {
          let cData = ' ';
          cityData = getCityData(countryData, phone);
          // Аналог "+7"
          if (countryCode === '8') {
            plus = false;
          }
          if (cityData && cityData.cityCode) {
            cData = ` (${cityData.cityCode}) `;
          }
          formattedPhone =
            (plus ? '+' : '') + countryCode + cData + phoneBlocks(cityData.restOfPhone);
        }
        break;
      }
    }
  } else {
    // возвращаем результат без кода страны и города
    formattedPhone = phoneBlocks(phone);
  }
  return formattedPhone || phone;
};
