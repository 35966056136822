import { makeStyles, Theme } from '@material-ui/core';

export const useMenuStyles = makeStyles(
  ({ color: { primary, tertiary } }: Theme) => ({
    menuIcon: {
      color: primary[700],
      justifyContent: 'center',
      minWidth: '1em',
      marginRight: '1em',
    },
    menuTitle: {
      userSelect: 'none',
      outline: 'none',
      borderBottom: `1px solid ${tertiary[100]}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '1.5em',
      paddingBottom: '0.5em',
    },
    menuDivider: {
      margin: '0.25em 0',
    },
  }),
  {
    index: 1,
  }
);
