/* eslint-disable react-hooks/exhaustive-deps */
import { PauseIcon, PlayIcon, RotateCCWIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import ButtonIcon from '@shared/components/ButtonIcon';
import Preloader from '@shared/components/Preloader';
import Typography from '@shared/components/Typography';
import { useObjectState } from '@shared/hooks/useObjectState';
import { formatDuration } from '@shared/utils';
import clsx from 'clsx';
import React, { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { AudioPlayerProps, AudioPlayerState, CommonProps } from './AudioPlayer.interfaces';
import { useAudioPlayerStyles } from './AudioPlayer.styles';

function ButtonLoading({ state }: CommonProps) {
  return (
    <Button className={state.get().classes.button} variant="secondary">
      <Preloader size="small" />
    </Button>
  );
}

function ButtonPause({ state }: CommonProps) {
  const onClickFromState = state.get().onClick;

  const handleClick = () => {
    state.actions.pause();
    onClickFromState?.();
  };

  return (
    <Button className={state.get().classes.button} variant="secondary" onClick={handleClick}>
      <PauseIcon />
    </Button>
  );
}

function ButtonPlay({ state }: CommonProps) {
  const currentState = state.get().state;
  const onClickFromState = state.get().onClick;

  const handleClick = () => {
    if (currentState === 'initial') {
      state.actions.startAudioLoading();
    }
    if (currentState === 'paused') {
      state.actions.play();
    }
    onClickFromState?.();
  };

  return (
    <Button className={state.get().classes.button} variant="secondary" onClick={handleClick}>
      <PlayIcon />
    </Button>
  );
}

function ButtonError({ state }: CommonProps) {
  const handleClick = () => {
    state.actions.reset();
    state.actions.startAudioLoading();
  };

  return (
    <ButtonIcon
      icon={<RotateCCWIcon />}
      titleNative="Произошла ошибка загрузки. Нажмите, чтобы повторить попытку."
      onClick={handleClick}
      color="error"
      variant="secondary"
    />
  );
}

function AudioPlayerButton({ state }: CommonProps) {
  const currentState = state.get().state;

  if (currentState === 'loading') {
    return <ButtonLoading state={state} />;
  }
  if (currentState === 'paused' || currentState === 'initial') {
    return <ButtonPlay state={state} />;
  }
  if (currentState === 'playing') {
    return <ButtonPause state={state} />;
  }
  if (currentState === 'error') {
    return <ButtonError state={state} />;
  }

  return <div>Error, no button!</div>;
}

function AudioPlayerButtonWrapper({ state }: CommonProps) {
  const { classes, side } = state.get();

  return (
    <div
      className={clsx({
        [classes.orderLeft]: side === 'left',
        [classes.orderRight]: side !== 'left',
      })}
    >
      <AudioPlayerButton state={state} />
    </div>
  );
}

function Expanded({ state }: CommonProps) {
  const lineRef = useRef<HTMLDivElement | null>(null);

  const { duration, time, audioRef, side, isExpanded, classes, state: stateStr } = state.get();

  const computedClasses = clsx(classes.left, {
    [classes.orderLeft]: side === 'right',
    [classes.orderRight]: side === 'left',
  });

  const audioPlayBarPixelsWidth = audioRef?.current
    ? audioRef.current.currentTime / (audioRef.current.duration / 100)
    : 0;

  const computedStyle = {
    width: `${audioPlayBarPixelsWidth}%`,
  };

  const disabled = stateStr === 'error' || stateStr === 'loading';

  const handleBarClick = useCallback(
    ({ currentTarget, pageX }: MouseEvent) => {
      if (audioRef.current === null || disabled) return;

      const wrapStep = (lineRef?.current?.clientWidth || 0) / 100;
      const timeStep = duration / 100;
      const { left } = currentTarget.getBoundingClientRect();
      const currentTime = timeStep * ((pageX - left) / wrapStep);
      audioRef.current.currentTime = currentTime;
      state.set({ time: currentTime });
      if (state.get().state === 'paused') {
        state.actions.play();
      }
    },
    [audioRef, duration, state]
  );

  if (!isExpanded) {
    return null;
  }

  return (
    <div className={computedClasses} ref={lineRef} onClick={handleBarClick}>
      <div className={classes.min}>
        <Typography type={'text5'} color={'tertiary500'}>
          {formatDuration(time)}
        </Typography>
      </div>
      <div className={classes.max}>
        <Typography type={'text5'} color={'tertiary500'}>
          {formatDuration(duration)}
        </Typography>
      </div>
      <div className={clsx(classes.bar, disabled && classes.barDisabled)}>
        <div
          className={clsx(classes.fill, disabled && classes.fillDisabled)}
          style={computedStyle}
        />
      </div>
    </div>
  );
}

export const AudioPlayer = ({
  className,
  source: sourceProp,
  isPaused,
  collapseOnEnd = true,
  isExpanded: isExpandedProp,
  onClick,
  onPlayEnds,
  side = 'right',
  autoPlay = false,
}: AudioPlayerProps) => {
  const classes = useAudioPlayerStyles();
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playbackPromise = useRef(Promise.resolve());

  const [state, setState, stateRef] = useObjectState<AudioPlayerState>({
    state: 'initial',
    duration: 0,
    time: 0,

    side,
    isExpanded: autoPlay || isExpandedProp || false,
    audioRef,
    classes,
    source: sourceProp,
    onClick,
  });

  const onError = () => {
    setState({ state: 'error' });
  };

  const onTimeUpdate = useCallback(() => {
    setState({ time: audioRef.current?.currentTime });
  }, []);

  const onEnded = useCallback(() => {
    setState({ state: 'paused' });

    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }

    if (collapseOnEnd) {
      setState({ isExpanded: false });
    }

    onPlayEnds?.();
  }, [collapseOnEnd, onPlayEnds, setState]);

  const removePlayListenersAndGoToPauseState = () => {
    audioRef.current?.removeEventListener('timeupdate', onTimeUpdate);
    audioRef.current?.removeEventListener('ended', onEnded);
    setState({ state: 'paused' });
  };

  const pause = useCallback(() => {
    if (stateRef.current.state === 'error') {
      return;
    }
    playbackPromise.current.then(() => audioRef.current?.pause());
    removePlayListenersAndGoToPauseState();
  }, []);

  const play = useCallback(() => {
    if (!audioRef.current) {
      return;
    }
    if (!audioRef.current.paused || stateRef.current.state === 'error') {
      return;
    }

    audioRef.current.addEventListener('timeupdate', onTimeUpdate);
    audioRef.current.addEventListener('ended', onEnded);

    playbackPromise.current
      .then(() =>
        setState({
          state: 'playing',
          isExpanded: true,
          duration: audioRef.current!.duration,
        })
      )
      .then(() => audioRef.current?.play())
      .catch(() => {
        removePlayListenersAndGoToPauseState();
      });
    // }
  }, []);

  const onLoadedData = () => {
    if ((audioRef.current?.readyState || 0) >= 3 && stateRef.current.state !== 'paused') {
      play();
    }
  };

  const cleanup = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.removeEventListener('error', onError);
      audioRef.current.removeEventListener('loadeddata', onLoadedData);
      audioRef.current.removeEventListener('ended', onEnded);
      audioRef.current.removeEventListener('timeupdate', onTimeUpdate);

      playbackPromise.current.then(() => {
        audioRef.current?.pause();
        audioRef.current?.remove();
        audioRef.current = null;
      });
    }
  }, []);

  const reset = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.remove();
      audioRef.current = null;
    }
    setState({ state: 'initial', time: 0, duration: 0 });
  }, []);

  const startAudioLoading = (source?: string) => {
    if (stateRef.current.state === 'loading') {
      return;
    }

    const resultSource = source || state.source;
    if (!resultSource) {
      return;
    }

    setState({ state: 'loading' });

    audioRef.current = new Audio(resultSource);
    audioRef.current.addEventListener('loadeddata', onLoadedData);
    audioRef.current.addEventListener('error', onError);
  };

  const localState = {
    get: () => state,
    set: setState,
    actions: {
      startAudioLoading,
      pause,
      play,
      reset,
    },
  };

  useEffect(() => {
    if (autoPlay && sourceProp) {
      startAudioLoading(sourceProp);
    }
  }, []);

  useEffect(() => {
    if (isPaused) {
      pause();
    }
  }, [isPaused]);

  useEffect(() => {
    if (stateRef.current.isExpanded !== isExpandedProp) {
      setState({ isExpanded: isExpandedProp });
    }
  }, [isExpandedProp]);

  useEffect(() => {
    if (sourceProp === stateRef.current.source) {
      return;
    }

    const currentState = stateRef.current.state;
    setState({ source: sourceProp });

    switch (currentState) {
      case 'initial': {
        return;
      }
      case 'paused': {
        reset();
        return;
      }
      case 'loading': {
        reset();
        startAudioLoading(sourceProp);
        return;
      }
      case 'playing': {
        reset();
        startAudioLoading(sourceProp);
        return;
      }
      case 'error': {
        reset();
        startAudioLoading(sourceProp);
        return;
      }
      default: {
        currentState satisfies never;
      }
    }
  }, [sourceProp]);

  useEffect(
    () => () => {
      cleanup();
    },
    []
  );

  return (
    <div className={clsx(classes.root, className)}>
      <Expanded state={localState} />
      <AudioPlayerButtonWrapper state={localState} />
    </div>
  );
};
