import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { useTranslation } from 'react-i18next';
import { toPrecision } from '@components/utils';
import { ResultOptionsType } from '@components/typings/interfaces';
import { useTariffFormStyle } from './TariffForm.styles';

type Props = {
  optionsList: ResultOptionsType[] | null;
  calcEmployeePrice: { price: number; discountPrice: number };
  optionsComputedPrices: { price: Array<number>; discountPrice: Array<number> };
  comparePrice?: boolean;
  nextMonthPrice?: number;
};

export const TariffTotalPrice = ({
  optionsList,
  calcEmployeePrice,
  optionsComputedPrices,
  comparePrice,
  nextMonthPrice,
}: Props) => {
  const [translate] = useTranslation();
  const classes = useTariffFormStyle();
  const [priceInit, setPriceInit] = useState<number | null>(null);

  const { price, discountPrice } = calcEmployeePrice;

  const totalOptionsPriceSum = optionsComputedPrices.price.reduce(
    (res: number, itm: number, ind: number) => {
      let aPrice = itm;
      const discPrice = optionsComputedPrices.discountPrice[ind];
      if (discPrice) {
        aPrice = discPrice;
      }
      return res + aPrice;
    },
    0
  );

  const totalPrice = useMemo(
    () => totalOptionsPriceSum + (discountPrice || price),
    [discountPrice, price, totalOptionsPriceSum]
  );

  useEffect(() => {
    if (!priceInit && comparePrice) {
      setPriceInit(totalPrice);
    }
  }, [comparePrice, priceInit, totalPrice]);

  if (!optionsList) return null;

  return (
    <Flex direction={'column'} justifyContent={'spaceBetween'} className={classes.tariffTotals}>
      <Flex className={classes.tariffTitle} justifyContent={'spaceBetween'}>
        <Typography type={'text3'} color={'tertiary900'} bold>
          {translate('TOTAL_MONTH_COST')}
        </Typography>
        <Typography className={classes.amountTextStyle} type={'text3'} color={'tertiary900'} bold>
          {`${translate('RUB_PER_MONTH', {
            amount: toPrecision(comparePrice && priceInit ? priceInit : totalPrice, false),
          })}`}
        </Typography>
      </Flex>
      {(comparePrice && priceInit && priceInit !== totalPrice) ||
      (nextMonthPrice && nextMonthPrice !== totalPrice) ? (
        <Flex justifyContent={'spaceBetween'}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('TOTAL_FROM_NEW_MONTH_COST')}
          </Typography>
          <Typography className={classes.amountTextStyle} type={'text3'} color={'primary700'} bold>
            {`${translate('RUB_PER_MONTH', {
              amount: toPrecision(
                nextMonthPrice !== totalPrice ? nextMonthPrice || 0 : totalPrice,
                false
              ),
            })}`}
          </Typography>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default TariffTotalPrice;
