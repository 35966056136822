import { makeStyles } from '@material-ui/core/styles';

export const useCommonCallRestrictionsStyles = makeStyles(
  () => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
    },
    formContent: {
      width: '23em',
      marginBottom: '2em',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.5em',
    },
  }),
  {
    index: 1,
  }
);
