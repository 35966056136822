import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ArrowCurveToTopIcon } from '@shared/assets/images/icons';

export const useContentHintStyles = makeStyles(({ color: { tertiary } }) => ({
  root: {
    position: 'absolute',
    right: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    pointerEvents: 'none',
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '2em 0',
    borderRadius: '0.8px',
    textAlign: 'center',
  },
  contentCenter: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '2em 0',
    borderRadius: '0.8px',
    textAlign: 'center',
  },
  icon: {
    marginTop: '2.75em',
    marginRight: '10em',
    width: '6.5em',
    height: '8.5em',
    color: tertiary[600],
  },
  hintBlock: {
    width: (width) => `${width}em`,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '13.1875em',
    marginTop: '-2em',
  },
  hintBlockCenter: {
    width: (width) => `${width}em`,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '-2em auto 0',
  },
  hintBlockMiddleToTable: {
    position: 'absolute',
    top: '13em',
    left: '50%',
  },
  row: {
    borderBottom: `1px solid ${tertiary[100]}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'columns',
    flexWrap: 'nowrap',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column',
    position: 'relative',
  },
}));

export const ContentHint: FunctionComponent<{
  isContentCenter?: boolean;
  isMiddleOfTable?: boolean;
  contentWidth?: string | number;
  children?: React.ReactNode;
}> = ({ children, isContentCenter, isMiddleOfTable, contentWidth = 21 }) => {
  const classes = useContentHintStyles(contentWidth);
  const computedContentClass = clsx(classes.content, {
    [classes.contentCenter]: isContentCenter,
  });

  const computedHintBlock = clsx(classes.hintBlock, {
    [classes.hintBlockMiddleToTable]: isMiddleOfTable,
    [classes.hintBlockCenter]: isContentCenter,
  });

  return (
    <div className={classes.root}>
      <div />
      <div className={computedContentClass}>
        <ArrowCurveToTopIcon className={classes.icon} />
        <div className={computedHintBlock}>{children}</div>
      </div>
    </div>
  );
};

export default ContentHint;
