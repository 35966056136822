import React, { useEffect, useState } from 'react';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary/AccordionSummary';
import Typography from '@shared/components/Typography';
import MuiAccordion from '@material-ui/core/Accordion/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails/AccordionDetails';
import { useFramelessAccordionStyles } from './FramelessAccordion.styles';
import { IFramelessAccordionProps } from './FramelessAccordion.interfaces';

export const FramelessAccordion = ({
  lessTitle,
  expandTitle,
  summaryClasses,
  renderSummary,
  contentRootClass,
  rootClass,
  className,
  children,
  defaultExpand = false,
  forceExpand = false,
}: IFramelessAccordionProps) => {
  const classes = useFramelessAccordionStyles();
  const [expanded, setExpand] = useState(defaultExpand);
  const {
    root: summaryRootClass,
    content: summaryContentClass,
    expanded: summaryExpandedClass,
  } = summaryClasses || {};

  useEffect(() => {
    if (forceExpand) {
      setExpand(true);
    }
  }, [forceExpand, setExpand]);

  return (
    <MuiAccordion
      classes={{ root: rootClass || classes.accordion }}
      className={className}
      expanded={expanded}
      onChange={() => setExpand(!expanded)}
      square
    >
      <MuiAccordionSummary
        classes={{
          root: summaryRootClass || classes.accordionSumRoot,
          content: summaryContentClass || classes.accordionHeader,
          expanded: summaryExpandedClass || classes.accordionTitleExpanded,
        }}
        aria-controls="panel1d-content"
      >
        {renderSummary}
        {expanded ? (
          <Typography
            color={'link600'}
            underline
            type={'text3'}
            className={classes.accordionHeaderText}
          >
            {lessTitle}
          </Typography>
        ) : (
          <Typography
            color={'link600'}
            underline
            type={'text3'}
            className={classes.accordionHeaderText}
          >
            {expandTitle}
          </Typography>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={{ root: contentRootClass || classes.accordionBody }}>
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default FramelessAccordion;
