import agentMp3 from '@client/assets/audio/agent.mp3';
import deliveryMp3 from '@client/assets/audio/delivery.mp3';
import managerMp3 from '@client/assets/audio/manager.mp3';
import eventMp3 from '@client/assets/audio/event.mp3';
import adminMp3 from '@client/assets/audio/admin.mp3';
import nightMp3 from '@client/assets/audio/night.mp3';

import insuranceImg from '@client/assets/images/schemas/insurance-agent.jpg';
import deliveryImg from '@client/assets/images/schemas/delivery-manager.jpg';
import accountImg from '@client/assets/images/schemas/account-manager.jpg';
import eventImg from '@client/assets/images/schemas/event-reminder.jpg';
import medicalImg from '@client/assets/images/schemas/medical-center.jpg';
import nightImg from '@client/assets/images/schemas/night-dispatcher.jpg';

export const BASIC_SCENARIOS = [
  {
    title: 'INSURANCE_AGENT',
    description: 'RESOLVES_ROUTINE_ISSUES',
    audio: agentMp3,
    schema: insuranceImg,
  },
  {
    title: 'DELIVERY_SERVICE_MANAGER',
    description: 'NOTIFY_ABOUT_ORDER',
    audio: deliveryMp3,
    schema: deliveryImg,
  },
  {
    title: 'ACCOUNT_MANAGER',
    description: 'CALLS_ALL_CLIENTS',
    audio: managerMp3,
    schema: accountImg,
  },
  {
    title: 'EVENT_REMINDER',
    description: 'UPCOMING_EVENT_REMINDER',
    audio: eventMp3,
    schema: eventImg,
  },
];

export const BASIC_INCOMING_SCENARIOS = [
  {
    title: 'MEDICAL_CENTER_ADMINISTRATOR',
    description: 'OPENING_OF_CENTER',
    audio: adminMp3,
    schema: medicalImg,
  },
  {
    title: 'NIGHT_DISPATCHER',
    description: 'SOLVE_CLIENTS_PROBLEM',
    audio: nightMp3,
    schema: nightImg,
  },
];

export type IScenarioContent = {
  title: string;
  description: string;
  audio: string;
  schema: string;
};
