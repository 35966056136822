import resultify from './resultify';

const downloadFileSafe = (fileURL: string, fileName?: string) => {
  return resultify(
    fetch(fileURL)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.download = fileName || '';
        a.hidden = true;
        a.href = blobURL;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobURL);
      })
  );
};

export default downloadFileSafe;
