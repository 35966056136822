import clsx from 'clsx';
import React from 'react';
import { IFlexProps } from './Flex.interfaces';
import { useFlexStyles } from './Flex.styles';

export const Flex = ({
  className,
  children,
  justifyContent,
  alignItems,
  fullWidth,
  direction,
  style,
  onClick,
}: IFlexProps) => {
  const classes = useFlexStyles();

  const justifyContentClasses = {
    flexStart: classes.justifyFlexStart,
    flexEnd: classes.justifyFlexEnd,
    center: classes.justifyCenter,
    spaceEvenly: classes.justifySpaceEvenly,
    spaceBetween: classes.justifySpaceBetween,
  };
  const justifyClass = justifyContentClasses[justifyContent || 'flexStart'];

  const alignItemsClasses = {
    flexStart: classes.alignFlexStart,
    flexEnd: classes.alignFlexEnd,
    center: classes.alignCenter,
  };
  const alignClass = alignItemsClasses[alignItems || 'flexStart'];

  const directionClasses = {
    row: classes.directionRow,
    column: classes.directionColumn,
    rowReverse: classes.directionRowReverse,
    columnReverse: classes.directionColumnReverse,
    inherit: classes.directionInherit,
    unset: classes.directionUnset,
  };
  const directionClass = directionClasses[direction || 'row'];

  const computedClasses = clsx(classes.root, className, {
    [justifyClass]: justifyContent,
    [alignClass]: alignItems,
    [directionClass]: direction,
    [classes.fullWidth]: fullWidth,
  });

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    onClick?.(event);
  }

  return (
    <div onClick={handleClick} style={style} className={computedClasses}>
      {children}
    </div>
  );
};

export default Flex;
