import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';

export const useTimeFieldStyles = makeStyles<Theme & IThemeOptions>(
  {
    container: {
      display: 'flex',
      flexFlow: 'column',
    },
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    controlGroup: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#FFFFFF',
      /* Grey / 40 */
      border: '1px solid #D1C9D5',
      boxSizing: 'border-box',
      width: '64px',
      height: '40px',
      minHeight: '40px',
      minWidth: '64px',
    },
    hoursInput: {
      width: '20px',
      padding: 0,
      textAlign: 'right',
    },
    minutesInput: {
      width: '20px',
      padding: 0,
    },
    separator: {
      padding: '0 2px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    increaseButton: {
      background: '#FFFFFF',
      /* Grey / 40 */
      border: '1px solid #D1C9D5',
      boxSizing: 'border-box',
      borderRadius: '3px 0px 0px 3px',
      width: '24px',
      height: '40px',
      minHeight: '40px',
      minWidth: '24px',
      borderRight: 0,
      padding: 0,
    },
    decreaseButton: {
      background: '#FFFFFF',
      /* Grey / 40 */
      border: '1px solid #D1C9D5',
      boxSizing: 'border-box',
      borderRadius: '0px 3px 3px 0px',
      width: '24px',
      height: '40px',
      minHeight: '40px',
      minWidth: '24px',
      borderLeft: 0,
      padding: 0,
    },
    validationError: {
      lineHeight: 1,
      marginTop: '0.5em',
    },
  },
  {
    index: 1,
  }
);
