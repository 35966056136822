import { makeStyles } from '@material-ui/core';

export const usePhoneFieldStyles = makeStyles(
  {
    root: {
      width: '100%',
      '&:hover > label': {
        color: 'inherit',
      },
    },
  },
  {
    index: 1,
  }
);
