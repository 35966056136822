import { RESET_PASSWORD_MUTATION } from '@/client/mutations';
import { useFormErrors } from '@components/common/formErrors.hooks';
import { AuthFormStyle } from '@/layouts/Auth/AuthForm/AuthForm.styles';
import { useMutation } from '@apollo/client';
import FormErrorMessage from '@components/FormErrorMessage';
import Link from '@components/Link';
import Message from '@components/Messages';
import { Button } from '@shared/components/Button/Button';
import PasswordField from '@shared/components/PasswordField';
import Typography from '@shared/components/Typography';
import resultify from '@shared/utils/resultify';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type FormFields = {
  password: string;
  passwordConfirm: string;
};

export const ResetPasswordForm = () => {
  const classes = AuthFormStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const formMethods = useForm<FormFields>({
    defaultValues: { password: '', passwordConfirm: '' },
  });
  const { setError } = formMethods;

  const [resetPassword, { error, loading, data }] = useMutation(RESET_PASSWORD_MUTATION);
  const { token } = useParams();

  useFormErrors(error?.graphQLErrors, formMethods);

  const onSubmit = async ({ password, passwordConfirm }: FormFields) => {
    if (loading) {
      return;
    }
    if (password !== passwordConfirm) {
      setError('passwordConfirm', {
        type: 'error',
        message: translate('PASSWORD_CONFIRMATION_DOES_NOT_MATCH'),
      });
      return;
    }

    const result = await resultify(resetPassword({ variables: { password, token } }));
    if (result.type === 'success') {
      navigate('/signin');
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        {error?.graphQLErrors ? (
          <FormErrorMessage errors={error?.graphQLErrors} />
        ) : (
          <Message data={data?.resetPassword} type="success" />
        )}
        <div className={classes.resetPasswordWrapper}>
          <PasswordField
            scoring
            generation
            required
            name={'password'}
            label={translate('NEW_PASSWORD')}
          />
        </div>
        <div className={classes.resetPasswordWrapper}>
          <PasswordField
            scoring={false}
            generation={false}
            required
            name={'passwordConfirm'}
            label={translate('CONFIRM_NEW_PASSWORD')}
          />
        </div>
        <div className={classes.formActions}>
          <Button
            className={classes.submitButton}
            type={'submit'}
            disableElevation={false}
            fullWidth
            loading={loading}
          >
            <Typography type={'text2'} color={'inherit'}>
              {translate('SAVE_PASSWORD')}
            </Typography>
          </Button>
        </div>
        <div className={classes.link}>
          <Link to="/">
            <Typography color={'tertiary900'} type={'text3'}>{`< ${translate('BACK')}`}</Typography>
          </Link>
        </div>
      </form>
    </FormProvider>
  );
};
