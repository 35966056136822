import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FilterIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  callRestrictionEntity,
  IRestrictionsCallsFilterParams,
} from '@components/typings/interfaces';
import CheckboxField from '@shared/components/CheckboxField';
import ControlButtons from '@shared/components/ControlButtons';
import MessageDialog from '@shared/components/MessageDialog';
import { useCallsRestrictionsStyles } from '../CallsRestrictions.styles';

export const CallRestrictionsTypeHeaderCell: FunctionComponent = () => {
  const classes = useCallsRestrictionsStyles();
  const [translate] = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const entityFilter = searchParams.getAll('filter');
  const computedClass = clsx(classes.headFilterIcon, {
    [classes.primary]: entityFilter.length && !entityFilter.includes('all'),
  });

  const formMethods = useForm<IRestrictionsCallsFilterParams>({
    defaultValues: {
      selectAll: !entityFilter?.length || entityFilter.includes('all'),
      department: entityFilter.includes('department'),
      employee: entityFilter.includes('employee'),
    },
  });
  const { reset, handleSubmit, watch, setValue } = formMethods;
  const employeeChecked = watch('employee');
  const departmentChecked = watch('department');

  const resetForm = useCallback(() => {
    reset({
      selectAll: true,
      employee: true,
      department: true,
    });
  }, [reset]);

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleFilterClick = () => {
    setOpen(true);
    if (entityFilter) {
      const formParams: IRestrictionsCallsFilterParams = {
        selectAll: false,
        department: false,
        employee: false,
      };
      entityFilter.forEach((el: string) => {
        formParams[el as keyof IRestrictionsCallsFilterParams] = true;
      });
      reset(formParams);
    }
  };

  const handleSubmitForm = useCallback(
    ({ department, employee, selectAll }: IRestrictionsCallsFilterParams) => {
      searchParams.delete('filter');
      if (selectAll) {
        searchParams.set('filter', 'all');
      } else {
        if (department) {
          searchParams.append('filter', callRestrictionEntity.Department);
        }
        if (employee) {
          searchParams.append('filter', callRestrictionEntity.Employee);
        }
      }
      setSearchParams(searchParams);
      setOpen(false);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if ((!departmentChecked && !employeeChecked) || (departmentChecked && employeeChecked)) {
      resetForm();
    } else {
      setValue('selectAll', false);
    }
  }, [employeeChecked, departmentChecked, resetForm, setValue]);

  const renderIcon = () => (
    <FilterIcon
      width={16}
      height={16}
      style={{ cursor: 'pointer' }}
      onClick={() => handleFilterClick()}
    />
  );

  return (
    <div className={computedClass}>
      {renderIcon()}
      <MessageDialog
        onCancel={handleFilterClose}
        isOpen={open}
        contentClass={classes.defaultElementWidth21}
        title={translate('FILTER_BY_TYPE')}
        renderContent={
          <FormProvider {...formMethods}>
            <form
              id={'restriction-type-filter'}
              action={''}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <CheckboxField
                className={classes.checkboxControl}
                name={'selectAll'}
                label={translate('SELECT_ALL')}
                onClick={resetForm}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'department'}
                label={translate('DEPARTMENTS')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'employee'}
                label={translate('EMPLOYEES')}
              />
              <ControlButtons
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                rootClass={classes.dialogActions}
                form={'restriction-type-filter'}
                onCancelClick={() => setOpen(false)}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                small
              />
            </form>
          </FormProvider>
        }
      />
    </div>
  );
};
