import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';

export const useOutgoingCallsStyles = makeStyles(
  ({ color: { primary, secondary, tertiary, base } }: Theme & IThemeOptions) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    tablePanel: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '23em',
      minWidth: '23em',
      height: '100%',
    },
    tablePanelHeader: {
      height: '2em',
      minHeight: '2em',
      background: tertiary[50],
      top: '3.45em',
      display: 'flex',
      position: 'sticky',
    },
    tablePanelContent: {
      display: 'flex',
      flexFlow: 'column nowrap',
      borderRight: `1px solid ${tertiary[100]}`,
      padding: '4.5em 1em 2.5em 2em',
      height: '100%',
      alignItems: 'center',
    },
    tableTitleControls: {
      color: tertiary[900],
      background: base,
      marginTop: '2em',
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '0.375em',
      height: 'auto',
      minHeight: '3em',
      padding: '0.75em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '14em',
    },
    tablePanelControlsActive: {
      '&:hover': {
        cursor: 'pointer',
        background: primary[100],
        border: `1px solid ${primary[600]}`,
        color: 'black',
      },
    },
    tablePanelControlsIcon: {
      marginLeft: '0.714em',
    },
    tablePanelText: {
      textAlign: 'center',
      padding: '0 1em',
    },
    tablePanelHint: {
      marginTop: '1em',
      textAlign: 'center',
      padding: '0 2em',
      width: '70%',
    },
    headFilterIcon: {
      background: tertiary[50],
      color: tertiary[400],
      lineHeight: 0,
      textAlign: 'center',
      borderBottom: 'none',
    },
    arrowIcon: {
      color: tertiary[400],
      minWidth: '2em',
    },
    body: {
      padding: '0 2em',
    },
    iconCell: {
      paddingTop: '0.25em',
    },
    primary: {
      color: primary[600],
    },
    secondary: {
      color: secondary[600],
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row-reverse nowrap',
      '& > :not(:last-child)': {
        marginLeft: '1em',
      },
    },
    tableButtonCell: {
      color: 'inherit',
      padding: '0 1em 0 0',
    },
    content: {
      display: 'flex',
      width: '100%',
      minWidth: '74em',
      height: '100%',
      position: 'relative',
    },
    contentContainer: {
      display: 'flex',
      width: '100%',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    rowContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    dialog: {
      padding: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogPaper: {
      width: '21em',
      textAlign: 'center',
      padding: '1em',
    },
    dialogWrapper: {
      borderRadius: '0.75em',
    },
    textContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      minHeight: '12em',
      width: '19em',
      margin: '0 auto',
    },
    dialogContent: {
      padding: '0',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    dialogTitle: {
      fontWeight: 'bold',
      lineHeight: '150%',
      textAlign: 'center',
      padding: '1em 0 1.5em',
    },
    modalTextContainer: {
      textAlign: 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    dialogActions: {
      padding: '1.5em 0 0 0',
      justifyContent: 'flex-end',
    },
    confirmButton: {
      width: '70%',
    },
    dialogFilterActions: {
      padding: '1.5em 0 0 0',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      overflow: 'auto',
    },
    actionButton: {
      width: 'initial',
      height: '2em',
      marginLeft: '1em',
    },
    actionRowButton: {
      height: '2em',
      width: '2em',
      padding: 'initial',
    },
    secondaryText: {
      marginTop: '0.5em',
      color: tertiary[400],
    },
    description: {
      marginLeft: '2em',
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxControl: {
      margin: '0.375em 0',
    },
    noNumbers: {
      position: 'absolute',
      top: '5em',
      height: '15em',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    phoneNumberIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  { index: 2 }
);
