import React, { FunctionComponent } from 'react';
import {
  isFeatureAvailable,
  isOptionAvailable,
  useDomainFeatures,
  useDomainOptions,
} from '@/common/hooks';
import BodyContainer from '@/layouts/BodyContainer';
import { useSettingsPagesStyles } from './SettingsPage.styles';
import { settingCardList } from './SettingsPage.constants';
import { SettingsCard } from './SettingsCard/SettingsCard';

export const SettingsList: FunctionComponent = () => {
  const classes = useSettingsPagesStyles();

  const { features } = useDomainFeatures();
  const { options: domainOptions } = useDomainOptions();

  const renderCardList = () =>
    settingCardList
      .filter(
        (item) =>
          isFeatureAvailable(item.feature, features) &&
          isOptionAvailable(item.option, domainOptions)
      )
      .map((block, index) => (
        <div className={classes.cardBlock} key={index}>
          <SettingsCard key={index} {...block} />
        </div>
      ));

  return (
    <BodyContainer>
      <div className={classes.cardContainer}>{renderCardList()}</div>
    </BodyContainer>
  );
};
