import { makeStyles } from '@material-ui/core';

export const useFlexStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    justifySpaceEvenly: {
      justifyContent: 'space-evenly',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    justifyFlexStart: {
      justifyContent: 'flex-start',
    },
    justifyFlexEnd: {
      justifyContent: 'flex-end',
    },
    alignCenter: {
      alignItems: 'center',
    },
    alignFlexStart: {
      alignItems: 'flex-start',
    },
    alignFlexEnd: {
      alignItems: 'flex-end',
    },
    fullWidth: {
      width: '100%',
    },
    directionRow: {
      flexDirection: 'row',
    },
    directionColumn: {
      flexDirection: 'column',
    },
    directionRowReverse: {
      flexDirection: 'row-reverse',
    },
    directionColumnReverse: {
      flexDirection: 'column-reverse',
    },
    directionInherit: {
      flexDirection: 'inherit',
    },
    directionUnset: {
      flexDirection: 'unset',
    },
  }),
  {
    index: 1,
    name: 'flex',
  }
);
