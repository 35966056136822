import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useEmployeesStyles = makeStyles(
  ({ color: { tertiary, success } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    action: {
      display: 'flex',
      margin: '0.5em',
      height: '2em',
    },
    indicatorIcon: {
      margin: '0.25em 0',
    },
    statusCellHead: {
      background: tertiary[50],
      height: '2em',
      width: '3em',
      padding: '0 0 0 2em',
      color: tertiary[400],
      borderBottom: '1px solid #fff',
    },
    statusCellBody: {
      width: '3em',
      padding: '0 0 0 2em',
      color: 'inherit',
    },
    nameCellBody: {
      color: 'inherit',
      borderBottom: `1px solid ${tertiary[100]}`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    employeeNameWrapper: {
      overflow: 'hidden',
    },
    secondaryText: {
      marginTop: '2px',
    },
    actionButton: {
      height: '2em',
      width: '2em',
      marginLeft: '1em',
      padding: 'initial',
    },
    removeEmployeeCenterBlock: {
      width: '20em',
      paddingBottom: '2em',
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '150%',
    },
    removeEmployeeNumberItem: {
      padding: '0.125em 0',
    },
    removeEmployeeDialog: {
      width: '23em',
    },
    removeEmployeeNameText: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    addEmployeeCenterBlock: {
      width: '18em',
      paddingBottom: '1.5em',
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '150%',
    },
    active: {
      backgroundColor: success['600'],
    },
    addEmployeeControls: {
      width: '100%',
      textAlign: 'right',
    },
    inactive: {
      backgroundColor: tertiary['400'],
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
