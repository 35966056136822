export enum IApologySMSTriggerTime {
  Always = 'ALWAYS',
  WorkTime = 'DURING_WORKING_HOURS',
  NonWorkTime = 'OUT_OF_HOURS',
}

export interface IApologySMS {
  active: boolean;
  allNumbers: boolean;
  id: number;
  triggerTime: string;
  messageTemplate: string;
  domainNumbers: IApologySMSNumber[];
}

export interface IApologySMSNumber {
  id: number;
  phone: string;
}
