export function setStorageItem<T>(name: string, item: T): void {
  if (item) {
    localStorage.setItem(name, JSON.stringify(item));
  } else {
    removeStorageItem(name);
  }
}

export function getStorageItem<T>(name: string): T | undefined {
  const item = localStorage.getItem(name);
  try {
    if (typeof item === 'string') {
      return JSON.parse(item);
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return undefined;
}

export function removeStorageItem(name: string): void {
  localStorage.removeItem(name);
}

export function clearStorage() {
  localStorage.clear();
}

export function clearAuth() {
  removeStorageItem('token');
  removeStorageItem('refreshToken');
}

export default { setStorageItem, getStorageItem, removeStorageItem, clearStorage, clearAuth };
