import { useEffect } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { useTranslation } from 'react-i18next';

type ExceptionFromServer = {
  field?: string;
  message?: string;
};

export const useFormErrors = <T extends FieldValues = FieldValues>(
  errors: readonly GraphQLError[] | undefined,
  formMethods: UseFormReturn<T>
) => {
  const { setError } = formMethods;
  const [translate] = useTranslation();

  useEffect(() => {
    if (errors) {
      errors.forEach((error) => {
        const exception = error.extensions?.exception as ExceptionFromServer | undefined;

        if (exception?.field) {
          setError(exception?.field as Path<T>, {
            message: exception?.message ? translate(exception?.message) : undefined,
            type: 'error',
          });
        }
      });
    }
  }, [errors, setError, translate]);
};
