import React, { useMemo, memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_HOURLY_CALL_STATISTICS_QUERY } from '@/client/queries';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { useTheme } from '@material-ui/core/styles';
import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import ButtonGroupSwitch from '@components/ButtonGroupSwitch';
import { BarDiagram } from '@components/Diagram';
import { formatDate, formatTimeHour } from '@components/utils';
import {
  Chart,
  FontSpec,
  InteractionItem,
  InteractionMode,
  ScriptableTooltipContext,
  TooltipItem,
  TooltipPositioner,
} from 'chart.js';
import clsx from 'clsx';
import Preloader from '@shared/components/Preloader';
import { useNavigate } from 'react-router-dom';
import PageError from '@shared/components/PageError';
import { useDesktopPageStyle } from '../DesktopPage.styles';
import {
  callHourlyStatisticsList,
  callHourlyStatisticsTicksList,
  incomingArrowIcons,
  incomingTitles,
  outgoingArrowIcons,
  outgoingTitles,
} from '../DesktopPage.constants';
import {
  callHourlyStatisticsResultType,
  callHourlyStatisticsTypes,
  callStatisticsDataType,
} from '../DesktopPage.interfaces';

export const CallHourlyStatisticsSectionWidget = () => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();
  const theme: IThemeColorOptions = useTheme();
  const navigate = useNavigate();
  const [hourlyCallsDirection, setHourlyCallsDirection] = useState(
    callHourlyStatisticsTypes.Incoming
  );
  const {
    data: dashboardHourlyCallStatistics,
    loading,
    error: { graphQLErrors: getHourlyStatisticsErrors = null } = {},
  } = useQuery(GET_DASHBOARD_HOURLY_CALL_STATISTICS_QUERY);
  const hourlyCallStatistics = dashboardHourlyCallStatistics?.dashboardHourlyCallStatistics;

  const { day, month } = formatDate(new Date());

  const isIncomingDirection = useMemo(
    () => hourlyCallsDirection === callHourlyStatisticsTypes.Incoming,
    [hourlyCallsDirection]
  );

  const buttonGroupData = useMemo(
    () => callHourlyStatisticsList.map((item) => ({ ...item, title: translate(item.title) })),
    [translate]
  );

  const barDiagramData = useMemo(() => {
    if (hourlyCallStatistics) {
      const { firstColumn, secondColumn } = hourlyCallStatistics.reduce(
        (result: callHourlyStatisticsResultType, item: callStatisticsDataType) => {
          if (isIncomingDirection) {
            result.firstColumn.push(item.inSuccess);
            result.secondColumn.push(item.inNotSuccess);
            return result;
          }
          result.firstColumn.push(item.outSuccess);
          result.secondColumn.push(item.outNotSuccess);
          return result;
        },
        { firstColumn: [], secondColumn: [] }
      );
      const { success, danger, links, warning } = theme?.color || {};
      return {
        labels: hourlyCallStatistics.map((el: callStatisticsDataType, index: number) => {
          if (index === 0) return '00:00';
          if (index === 23) return formatTimeHour(index + 1);
          return formatTimeHour(index);
        }),
        datasets: [
          {
            label: translate(isIncomingDirection ? 'INCOMING' : 'OUTGOING'),
            data: firstColumn,
            backgroundColor: isIncomingDirection ? links[600] : success[600],
            borderWidth: 0,
            stack: 'IN',
          },
          {
            label: translate(isIncomingDirection ? 'INCOMING_OUT' : 'OUTGOING_OUT'),
            data: secondColumn,
            backgroundColor: isIncomingDirection ? danger[600] : warning[600],
            borderWidth: 0,
            stack: 'IN',
          },
        ],
      };
    }
    return {
      datasets: [],
    };
  }, [hourlyCallStatistics, theme?.color, translate, isIncomingDirection]);

  const barDiagramOptions = useMemo(() => {
    const getOrCreateTooltip = (chart: Chart) => {
      let tooltipEl = chart.canvas.parentNode?.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.className = classes.tooltipWrapperClass;

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode?.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context: ScriptableTooltipContext<'line' | 'bar'>) => {
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }
      if (
        tooltip.dataPoints.findIndex((point: TooltipItem<'bar'>) => (point?.raw as number) > 0) < 0
      ) {
        tooltipEl.style.display = 'none';
        return;
      }
      tooltipEl.style.display = 'block';

      if (tooltip.dataPoints) {
        const { dataPoints } = tooltip;
        const rawIndex = dataPoints[0].dataIndex;
        const tableHead = document.createElement('thead');

        const tr = document.createElement('tr');
        tr.style.borderWidth = '0px';

        const th = document.createElement('th');
        th.style.borderWidth = '0px';
        th.style.textAlign = 'left';
        th.style.fontSize = '0.75em';
        th.style.fontWeight = 'normal';
        const text = document.createTextNode(
          `${formatTimeHour(rawIndex)} - ${formatTimeHour(rawIndex + 1)}`
        );

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);

        const tableBody = document.createElement('tbody');
        const dataOrder = [0, 1];
        const isIncoming = hourlyCallsDirection === callHourlyStatisticsTypes.Incoming;
        const iconsList = isIncoming ? incomingArrowIcons : outgoingArrowIcons;
        const titles = isIncoming ? incomingTitles : outgoingTitles;
        const index = dataPoints[0].dataIndex;
        dataOrder.forEach((i: number) => {
          const point = barDiagramData?.datasets[i].data[index];

          const leftTextSpan = document.createElement('span');
          leftTextSpan.className = classes.tooltipLeftTextClass;
          leftTextSpan.append(`${translate(titles[i])}: `);

          const iconPath = iconsList[i];
          const imgIcon = document.createElement('img');
          imgIcon.className = classes.tooltipIconClass;
          imgIcon.src = iconPath;

          const rightTextSpan = document.createElement('span');
          rightTextSpan.className = classes.tooltipRightTextClass;
          let val = point || 0;
          if (i === 0) {
            val += barDiagramData?.datasets[i + 1].data[index] || 0;
          }
          rightTextSpan.append(`${val}`);

          const trd = document.createElement('tr');
          trd.style.backgroundColor = 'inherit';
          trd.style.borderWidth = '0px';

          const td = document.createElement('td');
          td.style.borderWidth = '0px';

          td.appendChild(imgIcon);
          td.appendChild(leftTextSpan);
          td.appendChild(rightTextSpan);
          trd.appendChild(td);
          tableBody.appendChild(trd);
        });

        const tableRoot = tooltipEl.querySelector('table');

        while (tableRoot?.firstChild) {
          tableRoot?.firstChild.remove();
        }

        tableRoot?.appendChild(tableHead);
        tableRoot?.appendChild(tableBody);
      }

      const {
        offsetLeft: positionX,
        width: canvasRawWidth,
        height: canvasRawHeight,
      } = chart.canvas;
      const canvasWidth = canvasRawWidth / window.devicePixelRatio;
      const canvasHeight = canvasRawHeight / window.devicePixelRatio;
      const { y: offsetTop } = chart.canvas.getBoundingClientRect();
      const { innerHeight: windowHeight } = window;
      const bottomPadding = windowHeight - canvasHeight - offsetTop;
      const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = tooltipEl;
      const paddingStepSize = 16;
      const xPosition = positionX + tooltip.caretX;

      const setLeftPosition = () => {
        if (xPosition + tooltipWidth > canvasWidth) {
          return `${xPosition - tooltipWidth - paddingStepSize}px`;
        }
        return `${xPosition + paddingStepSize}px`;
      };

      const setTopPosition = () => {
        if (tooltip.caretY - tooltipHeight / 2 < 0) {
          return `${tooltipHeight / 2}px`;
        }
        if (tooltip.caretY + tooltipHeight / 2 > canvasHeight + bottomPadding) {
          return `${canvasHeight - tooltipHeight / 2}px`;
        }
        return `${tooltip.caretY - paddingStepSize}px`;
      };

      const setArrowClass = () => {
        if (xPosition + tooltipWidth > canvasWidth) {
          return clsx(classes.tooltipWrapperClass, classes.tooltipArrowLeft);
        }
        return clsx(classes.tooltipWrapperClass, classes.tooltipArrowRight);
      };

      tooltipEl.style.opacity = '1';
      tooltipEl.style.minWidth = '6.25em';
      tooltipEl.style.left = setLeftPosition();
      tooltipEl.style.top = setTopPosition();
      tooltipEl.style.font = String((tooltip.options.bodyFont as FontSpec)?.size);
      tooltipEl.className = setArrowClass();
      tooltipEl.style.padding = `${Number(tooltip.options.padding)}px`;
    };

    return {
      responsible: true,
      aspectRatio: 3.5,
      interaction: {
        intersect: false,
        mode: 'index' as InteractionMode,
        axis: 'x' as 'x' | 'y' | 'xy' | 'r',
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest' as TooltipPositioner,
          external: externalTooltipHandler,
        },
      },
      scales: {
        y: {
          ticks: {
            stepSize: 1,
          },
        },
        x: {
          ticks: {
            maxRotation: 0,
            maxTicksLimit: 7,
            autoSkip: false,
            callback(
              val: number | string,
              index: number
            ): string | number | string[] | number[] | null | undefined {
              const isInList = callHourlyStatisticsTicksList.includes(index);
              return isInList ? barDiagramData?.labels?.[val as number] || '' : '';
            },
          },
        },
      },
    };
  }, [barDiagramData, classes, hourlyCallsDirection, translate]);

  function handleButtonGroupSwitchClick(buttonPeriodValue: string | number) {
    setHourlyCallsDirection(buttonPeriodValue as callHourlyStatisticsTypes);
  }

  const handlePointClick = useCallback(
    (element: InteractionItem[]) => {
      if (element[0]) {
        let historyPath: string;
        switch (hourlyCallsDirection) {
          case callHourlyStatisticsTypes.Outgoing:
            historyPath = '/history/?direction=Out';
            break;
          case callHourlyStatisticsTypes.Incoming:
            historyPath = '/history/?direction=In';
            break;
          default:
            historyPath = '/history/?direction=In';
            break;
        }
        const rawIndex = element[0].index;

        historyPath += `&period=today&fromTime=${formatTimeHour(rawIndex)}&toTime=${formatTimeHour(
          rawIndex + 1
        )}`;
        setTimeout(() => {
          navigate(historyPath);
        });
      }
    },
    [navigate, hourlyCallsDirection]
  );

  if (loading && !dashboardHourlyCallStatistics) {
    return (
      <Flex justifyContent={'center'} alignItems={'center'} className={classes.desktopSection}>
        <Preloader size={'large'} />
      </Flex>
    );
  }

  if (getHourlyStatisticsErrors) {
    return (
      <div className={classes.desktopSection}>
        <PageError />
      </div>
    );
  }

  return (
    <div className={classes.desktopSection}>
      <Flex
        className={classes.desktopSectionHeader}
        justifyContent={'spaceBetween'}
        alignItems={'flexStart'}
      >
        <Typography type={'text2'} color={'tertiary900'} bold>
          {`${translate('CALLS_HOURLY_STATISTICS')}, ${day} ${translate(month)}`}
        </Typography>
        <Flex>
          <ButtonGroupSwitch data={buttonGroupData} onClick={handleButtonGroupSwitchClick} />
        </Flex>
      </Flex>
      <Flex className={classes.desktopBarDiagramSection} justifyContent={'spaceBetween'}>
        <BarDiagram options={barDiagramOptions} data={barDiagramData} onClick={handlePointClick} />
      </Flex>
    </div>
  );
};

export default memo(CallHourlyStatisticsSectionWidget);
