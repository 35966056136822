import { TDrawerSizes } from './Drawer.interfaces';

export const getDrawerWidthBySize = (size?: TDrawerSizes) => {
  switch (size) {
    case 'lg':
      return '33em';
    case 'md':
      return '31em';
    case 'xs':
      return '27em';
    case 'sm':
    default:
      return '29em';
  }
};
