import { makeStyles } from '@material-ui/core';

export const useCallForwardingFieldStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    balloonCard: {
      backgroundColor: tertiary[50],
      width: 'auto',
      maxWidth: '23em',
      minWidth: '10.75em',
      height: '6em',
      borderRadius: '6px',
      border: `1px solid ${tertiary[100]}`,
      padding: '0.75em 1.5em',
      position: 'relative',
      marginTop: '1em',
      marginBottom: '0.3125em',
      overflow: 'visible',
      '&::before': {
        top: '-0.5em',
        left: '2.375em',
        transform: 'rotate(45deg)',
        width: '0.875em',
        height: '0.875em',
        content: '""',
        border: `1px solid ${tertiary[100]}`,
        background: tertiary[50],
        position: 'absolute',
        borderRight: 'none',
        borderBottom: 'none',
      },
    },
    label: {
      paddingTop: '0.25em',
      marginLeft: '0.75em',
    },
    selectField: {
      width: '20em',
      paddingTop: '0.5em',
    },
  }),
  {
    index: 1,
  }
);
