import { OptionType, TariffOptionCategory } from '@/client/generated/graphql';
import { PRESELECT_TARIFF_MUTATION } from '@/client/mutations';
import { GET_SELECTED_TARIFF_QUERY } from '@/client/queries';
import { ContractLayout } from '@/features/Documents';
import {
  getComputedMinEmployeesTooltipText,
  getComputedMinIntegrationsTooltipText,
  getTariffExactOptions,
  useTariffProps,
} from '@/utils/tariff';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import { useMutation } from '@apollo/client';
import ConfirmDialog, { IConfirmState } from '@components/ConfirmDialog';
import { ConfirmAction } from '@components/ConfirmDialog/ConfirmDialog.interfaces';
import { FormArrowSwitcher } from '@components/FormArrowSwitcher/FormArrowSwitcher';
import {
  TariffDiscount,
  TariffIncludedOptionsList,
  TariffPriceDiscount,
  TariffTotalPrice,
} from '@components/TariffForm';
import { FormControlLabel } from '@material-ui/core';
import Flex from '@shared/components/Flex';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import SwitchField from '@shared/components/SwitchField';
import Typography from '@shared/components/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { MAX_ACCOUNT_AMOUNT, formType } from '../Contract.constants';
import { PreselectTariffType } from '../Contract.interfaces';
import { useContractStyle } from '../Contract.styles';

export const ContractTariff = () => {
  const classes = useContractStyle();
  const formMethods = useForm();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const { watch, handleSubmit, setValue } = formMethods;
  const accountAmount = Number(watch('accountAmount') || 0);
  const integrationsAmount = Number(watch('integrationAmount') || 0);
  const selectedOptions = watch('additionalOptions');
  const storeRecordsId = Number(watch('records') || 0);
  const [updateClientTariff, { loading }] = useMutation(PRESELECT_TARIFF_MUTATION);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const rootRef = useRef<HTMLDivElement | null>(null);

  const {
    accountOption,
    additionalAccountOption,
    additionalOptionsKeyList,
    additionalTariffOptions,
    optionsResultList,
    currentTariff,
    currentEmployeeCount,
    optionsComputedPrices,
    employeeComputedPrices,
    installedIntegrationsCount,
  } = useTariffProps(selectedOptions, accountAmount, integrationsAmount, storeRecordsId);

  useEffect(() => {
    if (optionsResultList) {
      const { accountMin } = optionsResultList[0] || {};
      if (currentTariff && currentTariff?.active) {
        const cTariffOptions = currentTariff?.options || [];
        const { baseEmployeeCount, additionalEmployeeCount, integrationCount } =
          getTariffExactOptions(cTariffOptions);
        const selectedAccountAmount = baseEmployeeCount + additionalEmployeeCount;
        const { selectedTariffOptions } = additionalOptionsKeyList.reduce(
          (result: { selectedTariffOptions: Array<boolean> }, optionKey) => {
            let isSelected = false;
            if (optionKey === OptionType.Records) {
              const recordsOption = cTariffOptions.find(
                (opt) => additionalTariffOptions[OptionType.Records]?.find((o) => o.id === opt.id)
              );
              if (recordsOption) {
                isSelected = true;
                setValue('records', String(recordsOption.id));
              }
            }
            if (
              currentTariff?.options.find(
                (opt) => opt.type === optionKey && opt.category === TariffOptionCategory.Additional
              )
            ) {
              isSelected = true;
            }
            result.selectedTariffOptions.push(isSelected);
            return result;
          },
          { selectedTariffOptions: [] }
        );
        setValue(
          'accountAmount',
          selectedAccountAmount > currentEmployeeCount
            ? selectedAccountAmount
            : currentEmployeeCount
        );
        setValue('additionalOptions', selectedTariffOptions);
        setValue('integrationAmount', integrationCount / selectedAccountAmount);
      } else {
        setValue(
          'accountAmount',
          accountMin > currentEmployeeCount ? accountMin : currentEmployeeCount
        );
        if (installedIntegrationsCount) {
          const optionIndex = additionalOptionsKeyList.indexOf(OptionType.Integrations);
          setValue('integrationAmount', installedIntegrationsCount);
          setValue(`additionalOptions[${optionIndex}]`, true);
        }
      }
    }
  }, [
    additionalOptionsKeyList,
    currentEmployeeCount,
    optionsResultList,
    setValue,
    currentTariff,
    installedIntegrationsCount,
    additionalTariffOptions,
  ]);

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Finish)
      navigate(blockedPath || '/register/phone-number');
  }, [isChanged, navigate, blockedPath]);

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  const handleTariffSubmit = (formData: Record<string, unknown>) => {
    const { records } = formData || {};
    const {
      id = 0,
      accountMax = 0,
      additionalAccountId = 0,
      accountId = 0,
    } = optionsResultList?.[0] || {};
    const baseAccounts = accountAmount <= accountMax ? accountAmount : accountMax;
    const additionalAccounts = accountAmount <= accountMax ? 0 : accountAmount - accountMax;
    const data: PreselectTariffType = {
      tariffId: id,
      options: [
        {
          tariffOptionId: accountId,
          count: baseAccounts,
        },
      ],
    };
    if (additionalAccounts > 0) {
      data.options.push({
        tariffOptionId: additionalAccountId,
        count: additionalAccounts,
      });
    }
    additionalOptionsKeyList.forEach((optionKey, optionIndex) => {
      if (selectedOptions[optionIndex]) {
        const isIntegrationOption = optionKey === OptionType.Integrations;
        const sIntegrationsAmount = isIntegrationOption
          ? accountAmount * integrationsAmount || 1
          : 1;
        const sOption = additionalTariffOptions[optionKey];
        let sOptionId = sOption[0].id;
        let isPerEmployee = sOption[0].params?.perEmployee || false;
        if (sOption.length > 1 && optionKey === OptionType.Records) {
          const subOption = sOption.find((item) => item.id === Number(records));
          sOptionId = subOption?.id || 0;
          isPerEmployee = subOption?.params?.perEmployee || false;
        }
        // eslint-disable-next-line no-nested-ternary
        const optionCount = isIntegrationOption
          ? sIntegrationsAmount
          : isPerEmployee
          ? accountAmount
          : 1;
        data.options.push({
          tariffOptionId: sOptionId,
          count: optionCount,
        });
      }
    });
    updateClientTariff({
      variables: {
        data,
      },
      refetchQueries: [
        {
          query: GET_SELECTED_TARIFF_QUERY,
        },
      ],
    }).then(() => {
      setIsChanged({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });
    });
  };

  const renderOptionDescription = (optionKey: string, keyIndex: number) => {
    const oList = additionalTariffOptions[optionKey];
    const isOptionSelected = selectedOptions?.[keyIndex];
    let currentTariffOption = currentTariff?.options.find((opt) => opt.type === optionKey);
    if (oList.length > 1) {
      if (optionKey === OptionType.Records) {
        return (
          <>
            <Typography type={'text3'} color={'tertiary900'} bold>
              {translate(optionKey)}
            </Typography>
            <RadioGroup defaultValue={String(oList[0].id)} name={'records'}>
              <Flex direction={'row'}>
                {oList.map((cOption, cIndex) => {
                  currentTariffOption = currentTariff?.options.find((opt) => opt.id === cOption.id);
                  return (
                    <Flex
                      className={classes.tariffMultipleOption}
                      direction={'column'}
                      key={`${cOption.type}-${cIndex}`}
                    >
                      <FormControlLabel
                        value={String(cOption.id)}
                        control={<Radio disabled={!isOptionSelected} color={'secondary'} />}
                        label={translate(`RECORDS_${cOption.params.days}`)}
                      />
                      <Typography
                        className={clsx(
                          classes.tariffAdditionalTextPadding,
                          classes.recordsStoringOptionLabelText
                        )}
                        type={'text4'}
                        color={'tertiary600'}
                      >
                        {`${translate('COST_PER_EMPLOYEE_OPTION', {
                          amount: cOption.baseMonthlyFee,
                        })}`}
                        <TariffDiscount discountData={currentTariffOption || cOption} />
                      </Typography>
                    </Flex>
                  );
                })}
              </Flex>
            </RadioGroup>
          </>
        );
      }
      return null;
    }
    return (
      <>
        <Typography type={'text3'} color={'tertiary900'} bold>
          {oList[0].name}
        </Typography>
        <div className={classes.tariffAdditionalOptionDescription}>
          <Typography
            className={classes.tariffAdditionalTextPadding}
            type={'text4'}
            color={'tertiary600'}
          >
            {`${translate(
              oList[0]?.params?.perEmployee ? 'COST_PER_EMPLOYEE_OPTION' : 'RUB_PER_MONTH',
              {
                amount: oList[0].baseMonthlyFee,
                additional: optionKey === OptionType.Integrations ? translate('PER_CRM') : '',
              }
            )}`}
            <TariffDiscount discountData={currentTariffOption || oList[0]} />
          </Typography>
        </div>
      </>
    );
  };

  const renderActionCell = (optionKey: string, keyIndex: number) => {
    const isOptionSelected = selectedOptions?.[keyIndex];
    const isPerEmployee = additionalTariffOptions[optionKey][0]?.params?.perEmployee || false;
    if (optionKey === OptionType.Integrations) {
      const { integrationMinMessage, computedMinIntegrations } =
        getComputedMinIntegrationsTooltipText(
          integrationsAmount,
          installedIntegrationsCount,
          translate
        );

      return (
        <Flex
          fullWidth
          justifyContent={'spaceBetween'}
          alignItems={'center'}
          className={!isOptionSelected ? classes.tariffHiddenOption : ''}
        >
          <Typography type={'text4'} color={'tertiary600'}>
            {translate('INTEGRATIONS_COUNT')}
          </Typography>
          <FormArrowSwitcher
            minTooltip={integrationMinMessage}
            disabled={!isOptionSelected}
            min={computedMinIntegrations || 1}
            max={MAX_ACCOUNT_AMOUNT}
            name={'integrationAmount'}
            defaultValue={1}
            rootClass={classes.defaultElementWidth7}
          />
        </Flex>
      );
    }
    if (isOptionSelected && isPerEmployee) {
      return (
        <Typography type={'text4'} color={'tertiary600'}>
          {translate('PER_EMPLOYEE', { amount: accountAmount })}
        </Typography>
      );
    }
    return null;
  };

  const renderPrice = (optionKey: string, keyIndex: number) => {
    const isOptionSelected = selectedOptions?.[keyIndex];
    if (!isOptionSelected) return null;
    const price = optionsComputedPrices.price[keyIndex];
    const discountPrice = optionsComputedPrices.discountPrice[keyIndex];

    return <TariffPriceDiscount price={price} discountPrice={discountPrice} />;
  };

  const renderSwitchField = (optionKey: string, keyIndex: number) => {
    if (optionKey === OptionType.Integrations && installedIntegrationsCount) {
      return (
        <TooltipHover
          placement={'bottom'}
          title={
            <Typography className={classes.tooltip} color={'tertiary900'} type={'text4'}>
              {translate('TARIFF_INTEGRATION_SWITCH_OFF_DISABLED')}
            </Typography>
          }
        >
          <SwitchField readOnly name={`additionalOptions[${keyIndex}]`} />
        </TooltipHover>
      );
    }
    return (
      <div onChange={handleFormChange}>
        <SwitchField name={`additionalOptions[${keyIndex}]`} />
      </div>
    );
  };

  const renderAdditionalTariffOptions = () => {
    if (!optionsResultList) return null;

    const { accountMin, accountPrice, additionalAccountPrice, accountMax } =
      optionsResultList[0] || {};
    const { price: employeePrice, discountPrice: employeeDiscountPrice } = employeeComputedPrices;

    const { accountsOption: cAccountOption, additionalAccountsOption: cAdditionalAccountsOption } =
      getTariffExactOptions(currentTariff?.options || []);

    const { computedMinAccount, accountMinMessage } = getComputedMinEmployeesTooltipText({
      accountAmount,
      currentEmployeeCount,
      accountOption: cAccountOption,
      translate,
    });

    return (
      <>
        <div className={classes.tariffTitle}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('ADDITIONAL_FUNCTIONAL')}
          </Typography>
        </div>
        <Flex direction={'column'} className={classes.tariffAdditionalFunctionalWrapper}>
          <Flex alignItems={'center'} className={classes.tariffAdditionalOption}>
            <div className={classes.tariffOptionSwitchCell} />
            <Flex
              direction={'column'}
              alignItems={'flexStart'}
              className={classes.tariffOptionInfoCell}
            >
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate('EMPLOYEES')}
              </Typography>
              <div className={classes.tariffAdditionalOptionDescription}>
                <Typography
                  className={classes.tariffAdditionalTextPadding}
                  type={'text4'}
                  color={'tertiary600'}
                >
                  {`${translate('COST_PER_EMPLOYEE', {
                    amount: accountPrice,
                  })}`}
                  <TariffDiscount discountData={cAccountOption || accountOption} />
                </Typography>
                <Typography
                  className={classes.tariffAdditionalTextPadding}
                  type={'text4'}
                  color={'tertiary600'}
                >
                  {`${translate('DISCOUNT_COST_PER_EMPLOYEE', {
                    amount: accountMax + 1,
                    price: additionalAccountPrice,
                  })}`}
                  <TariffDiscount
                    discountData={cAdditionalAccountsOption || additionalAccountOption}
                  />
                </Typography>
              </div>
            </Flex>
            <Flex
              justifyContent={'flexEnd'}
              alignItems={'center'}
              className={classes.tariffOptionActionCell}
            >
              <Flex
                justifyContent={'spaceBetween'}
                alignItems={'center'}
                className={classes.tariffOptionActionCellWrapper}
              >
                <Typography type={'text4'} color={'tertiary600'}>
                  {`${translate('EMPLOYEE_MIN_COUNT', {
                    amount: accountMin,
                  })}`}
                </Typography>
                <FormArrowSwitcher
                  minTooltip={accountMinMessage}
                  min={computedMinAccount}
                  max={MAX_ACCOUNT_AMOUNT}
                  name={'accountAmount'}
                  defaultValue={accountMin}
                  rootClass={classes.defaultElementWidth7}
                  onChange={handleFormChange}
                />
              </Flex>
            </Flex>
            <Flex justifyContent={'flexEnd'} className={classes.tariffOptionPriceCell}>
              <TariffPriceDiscount price={employeePrice} discountPrice={employeeDiscountPrice} />
            </Flex>
          </Flex>
          {additionalOptionsKeyList.map((optionKey, keyIndex) => (
            <Flex
              key={`${optionKey}-${keyIndex}`}
              alignItems={'center'}
              className={classes.tariffAdditionalOption}
            >
              <Flex alignItems={'flexStart'} className={classes.tariffOptionSwitchCell}>
                {renderSwitchField(optionKey, keyIndex)}
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'flexStart'}
                className={classes.tariffOptionInfoCell}
              >
                {renderOptionDescription(optionKey, keyIndex)}
              </Flex>
              <Flex
                justifyContent={'flexEnd'}
                alignItems={'center'}
                className={classes.tariffOptionActionCell}
              >
                <Flex
                  justifyContent={'spaceBetween'}
                  className={classes.tariffOptionActionCellWrapper}
                >
                  {renderActionCell(optionKey, keyIndex)}
                </Flex>
              </Flex>
              <Flex justifyContent={'flexEnd'} className={classes.tariffOptionPriceCell}>
                {renderPrice(optionKey, keyIndex)}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </>
    );
  };

  return (
    <ContractLayout
      isLoading={loading}
      childrenHeight={rootRef?.current?.clientHeight}
      formId={formType.Tariff}
    >
      <div ref={rootRef} className={classes.personalDataRoot}>
        <FormProvider {...formMethods}>
          <form id={formType.Tariff} onSubmit={handleSubmit(handleTariffSubmit)}>
            <div className={classes.tariffTitle}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('CHOOSE_OPTIONS')}
              </Typography>
            </div>
            <TariffIncludedOptionsList optionsList={optionsResultList} />
            {renderAdditionalTariffOptions()}
            <TariffTotalPrice
              optionsList={optionsResultList}
              calcEmployeePrice={employeeComputedPrices}
              optionsComputedPrices={optionsComputedPrices}
            />
          </form>
        </FormProvider>
        <ConfirmDialog
          isBlocked={isChanged.isBlocking}
          onNavigationBlocked={getBlockedPath}
          onSaveChanges={handleSubmit(handleTariffSubmit)}
        />
      </div>
    </ContractLayout>
  );
};
