import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PROFILE_MUTATION } from '@/client/mutations';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/components/Button/Button';
import { compressImage } from '@shared/utils/images';
import { USER_QUERY } from '@/client/queries';
import { useNavigate } from 'react-router-dom';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import CallForwardingFields from '@components/CallForwadingFields';
import FormErrorMessage from '@components/FormErrorMessage';
import { IDepartment, NotificationTypes, serializeEmployee } from '@components/typings/interfaces';
import Snackbar from '@shared/components/Snackbar';
import Avatar from '@shared/components/Avatar';
import RangePicker from '@shared/components/RangePicker';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import PhoneField from '@shared/components/PhoneField';
import Typography from '@shared/components/Typography';
import ControlButtons from '@shared/components/ControlButtons';
import {
  DocumentIcon,
  ArrowCrossIcon,
  CropIcon,
  EditIcon,
  TrashIcon,
  EmailIcon,
  LockIcon,
  CameraIcon,
} from '@shared/assets/images/icons';
import Flex from '@shared/components/Flex';
import MessageDialog from '@shared/components/MessageDialog';
import AvatarEditor from 'react-avatar-editor';
import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import { useFormErrors } from '@components/common/formErrors.hooks';
import { ProfileStyles } from './Profile.styles';
import ProfileChangePassword from './ProfileChangePassword';
import { IProfileFormData, IUpdateProfileData } from './ProfileFormInterfaces';
import { ProfileCard } from './ProfileCard';
import ProfileChangeEmail from './ProfileChangeEmail';
import { deserializeProfile, deserializeProfileForForm, fileToBase64 } from './Profile.utils';

export const Profile: FunctionComponent = () => {
  const classes = ProfileStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const {
    data,
    loading,
    refetch: refetchProfile,
  } = useQuery(USER_QUERY, {
    fetchPolicy: 'network-only',
  });
  const user = data?.user;
  const profile = deserializeProfile(user);
  const profileAvatar = profile?.avatar;
  const [updateUser, { error, called, loading: loadingUpdate }] =
    useMutation(UPDATE_PROFILE_MUTATION);
  const formMethods = useForm<IProfileFormData>();
  const {
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors },
    clearErrors,
  } = formMethods;
  const [avatarLoadingLocal, setAvatarLoadingLocal] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
  const [isProfileSuccessSubmitNotify, setIsProfileSuccessSubmitNotify] = useState(false);
  const [isProfileUpdated, setIsProfileUpdated] = useState(true);
  const [isUserNameEdit, setIsUserNameEdit] = useState(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isAvatarDialogEditOpen, setIsAvatarDialogEditOpen] = useState(false);
  const [isAvatarRemove, setIsAvatarRemove] = useState(false);
  const [scaleValue, setScaleValue] = useState<number>(1);
  const newAvatar = useRef<AvatarEditor>(null);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [avatarImage, setAvatarImage] = useState<File | string>();
  const userDepartments = useMemo(
    () => profile.departments?.map((department: Partial<IDepartment>) => department.name),
    [profile]
  );
  const callForwardingEnabled = watch('callForwarding');
  const isThereModificationsOnAvatar = Boolean(isAvatarRemove || avatarImage);

  useEffect(() => {
    if (user && !loading && !loadingUpdate && isProfileUpdated) {
      const resetData = deserializeProfileForForm(user);
      reset(resetData);
    }
  }, [reset, user, loadingUpdate, loading, isProfileUpdated]);

  useEffect(() => {
    if (called && !loadingUpdate && !error) {
      navigate(blockedPath || '/profile/view');
    }
  }, [called, error, navigate, blockedPath, loadingUpdate]);

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Cancel) {
      setIsUserNameEdit(false);
      refetchProfile().then();
    }
  }, [isChanged.action, refetchProfile]);

  useFormErrors(error?.graphQLErrors, formMethods);

  const getAvatarUrl = (avatar?: string): string | undefined => {
    if (avatar) {
      return `${process.env.API_URL}/files/avatar/${avatar}`;
    }
    return undefined;
  };

  const imageToBlob = (canvas: HTMLCanvasElement | undefined): Promise<Blob | null> =>
    new Promise((resolve) => {
      if (!canvas) {
        resolve(null);
      } else {
        canvas.toBlob((fileContent: Blob | null) => {
          resolve(fileContent);
        });
      }
    });

  const handleFormSubmit: SubmitHandler<IProfileFormData> = (formData) => {
    if (loading || loadingUpdate) return;
    if (!isChanged.isBlocking) return;
    const newUserData: IUpdateProfileData = {
      data: serializeEmployee(formData),
    };
    if (isUserNameEdit) setIsUserNameEdit(false);
    setIsProfileSuccessSubmitNotify(false);
    setIsProfileUpdated(false);
    updateUser({
      variables: newUserData,
    }).then(() => {
      refetchProfile().then(() => {
        setIsProfileUpdated(true);
      });
      setIsProfileSuccessSubmitNotify(true);
    });
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
  };

  const handleSuccessAvatarUploading = () => {
    refetchProfile().then(() => {
      setIsProfileUpdated(true);
      setIsAvatarDialogEditOpen(false);
      setAvatarImage(undefined);
      setIsAvatarRemove(false);
      setScaleValue(1);
    });
    setIsProfileSuccessSubmitNotify(true);

    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
  };

  const handleUpdateAvatar = () => {
    setAvatarLoadingLocal(true);

    async function run() {
      const newAvatarCurrent = newAvatar?.current;

      if (isAvatarRemove) {
        await updateUser({
          variables: { data: { deleteAvatar: true } },
        }).then(handleSuccessAvatarUploading);
        return;
      }

      const canvas = newAvatarCurrent?.getImage();
      const newAvatarImageBlob = await imageToBlob(canvas);

      if (newAvatarImageBlob === null) {
        setError('avatarError', Error('Ошибка конвертации изображения.'));
        return;
      }

      const compressedBlob = await compressImage(newAvatarImageBlob, 512, 512, 'image/jpeg', 1);

      if (newAvatarImageBlob === null) {
        setError('avatarError', Error('Ошибка сжатия изображения.'));
        return;
      }

      await updateUser({
        variables: { file: compressedBlob },
        context: {
          useMultipart: !!newAvatarImageBlob,
        },
      }).then(handleSuccessAvatarUploading);
    }

    return run().finally(() => {
      setAvatarLoadingLocal(false);
    });
  };

  const handleClose = () => {
    setIsProfileSuccessSubmitNotify(false);
  };

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleClosePopupDialog() {
    if (loadingUpdate || avatarLoadingLocal) return;
    setIsAvatarDialogEditOpen(false);
    setTimeout(() => {
      if (isAvatarRemove) setIsAvatarRemove(false);
      setScaleValue(1);
      setAvatarImage(undefined);
      clearErrors('avatarError');
    }, 200);
  }

  const handleOpenEditAvatarDialog = () => {
    setIsUserNameEdit(false);
    setIsAvatarDialogEditOpen(true);
  };

  function handleCancelClick() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  }

  function handleUploadAvatarError() {
    setAvatarImage(undefined);
    setError('avatarError', {
      type: 'error',
      message: translate('UPLOAD_AVATAR_ERROR'),
    });
  }

  const renderDialog = () => {
    return (
      <>
        <ProfileChangePassword onClose={() => setOpenPassword(false)} open={openPassword} />
        <ProfileChangeEmail
          open={isChangeEmailModalOpen}
          userEmail={user?.email}
          onClose={() => setIsChangeEmailModalOpen(false)}
          onRefetchProfile={() => refetchProfile()}
        />
      </>
    );
  };

  const renderSuccessNotify = () => {
    if (!loadingUpdate) {
      let message = '';
      let openNotification = false;
      if (isProfileSuccessSubmitNotify) {
        message = 'PROFILE_SUCCESSFULLY_CHANGED';
        openNotification = isProfileSuccessSubmitNotify;
      }
      return (
        <Snackbar
          onClose={handleClose}
          open={openNotification}
          autoHideDuration={4000}
          withLeftMargin
          AlertProps={{
            description: translate(message),
            status: 'success',
            variant: 'filled',
            onClose: handleClose,
          }}
        />
      );
    }
    return null;
  };

  const renderUserNotifications = () => {
    const value =
      profile.notifications?.reduce((result: string, item: NotificationTypes, i: number) => {
        const prefix = i && item ? ', ' : '';
        return `${result}${prefix}${item || ''}`;
      }, '') || '';
    let displayValue = translate('NOT_RECEIVING_NOTIFICATIONS');
    if (value.includes('sms')) {
      displayValue = translate('SMS_NOTIFICATION');
    }
    if (value.includes('email')) {
      displayValue = translate('EMAIL_NOTIFICATION');
    }
    if (value.includes('sms') && value.includes('email')) {
      displayValue = translate('ALL_NOTIFICATIONS');
    }
    return (
      <Flex direction={'column'}>
        <Typography type={'text4'} color={'tertiary600'}>
          {translate('MISSED_NOTIFICATIONS')}
        </Typography>
        <div className={classes.profileNotificationsBg}>
          <Typography type={'text3'}>{displayValue}</Typography>
        </div>
      </Flex>
    );
  };

  const renderFileUploader = () => (
    <input
      className={classes.editAvatarInput}
      type={'file'}
      accept={'image/*'}
      title={''}
      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        if (file) {
          const conversionResult: string | Error = await fileToBase64(file).catch((err) => err);
          if (conversionResult instanceof Error) {
            const message = translate(conversionResult.message);
            setError('avatarError', { message });
            return;
          }
          setIsAvatarRemove(false);
          setAvatarImage(conversionResult);
        }

        clearErrors('avatarError');
      }}
    />
  );

  const renderContent = () => (
    <div className={classes.content}>
      <FormProvider {...formMethods}>
        <form
          id={'profile-form'}
          action={''}
          onSubmit={handleSubmit(handleFormSubmit)}
          className={classes.form}
          onChange={handleFormChange}
        >
          <Flex className={classes.profileUserData} direction={'column'}>
            <Flex alignItems={'center'} className={classes.profileCardHeader}>
              <DocumentIcon className={classes.marginBottom025} />
              <Typography
                className={classes.marginLeft05}
                type={'text2'}
                color={'tertiary900'}
                bold
              >
                {translate('YOUR_DATA')}
              </Typography>
            </Flex>
            <Flex>
              <div className={classes.defaultElementWidthHalf}>
                <Flex alignItems={'center'}>
                  <Flex className={classes.avatarWrapper}>
                    <Avatar
                      url={getAvatarUrl(profile.avatar)}
                      className={profileAvatar ? classes.profileAvatar : classes.profileEmptyAvatar}
                      name={profile.name}
                      showInitials
                      size={'large'}
                      onClick={handleOpenEditAvatarDialog}
                    />
                    <Flex alignItems={'center'} className={classes.avatarCameraIconBg}>
                      <CameraIcon
                        className={classes.avatarCameraIcon}
                        onClick={handleOpenEditAvatarDialog}
                      />
                    </Flex>
                  </Flex>
                  <Flex direction={'column'}>
                    <Flex className={classes.profileNameRow} alignItems={'center'}>
                      {!isUserNameEdit ? (
                        <Typography className={classes.profileName} type={'text2'}>
                          {profile.name}
                        </Typography>
                      ) : (
                        <FormFieldRhfUncontrolled
                          className={classes.profileNameEditField}
                          name={'name'}
                          validate={(value: string) =>
                            !value ? (translate('EMPTY_FIELD_FULLNAME') as string) : true
                          }
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      )}
                      {!isUserNameEdit && (
                        <EditIcon
                          className={classes.profileEditIcon}
                          onClick={() => setIsUserNameEdit(true)}
                        />
                      )}
                    </Flex>
                    <div className={classes.profileInfoWrapper}>
                      <Typography className={classes.profileInfo}>{profile.email}</Typography>
                      <Typography className={classes.profileInfo}>{profile.position}</Typography>
                    </div>
                  </Flex>
                </Flex>
              </div>
              <Flex className={`${classes.defaultElementWidthHalf} ${classes.delimiter}`}>
                <div className={classes.profileCommonInfoLeft}>
                  <Flex className={classes.profileCommonInfo} direction={'column'}>
                    <Typography type={'text4'} color={'tertiary600'}>
                      {translate('ROLE')}
                    </Typography>
                    <Typography type={'text3'}>
                      {translate(profile.role || 'POSITION_NOT_DEFINED')}
                    </Typography>
                  </Flex>
                  <Flex className={classes.profileCommonInfo} direction={'column'}>
                    <Typography type={'text4'} color={'tertiary600'}>
                      {translate('EXTENSION_NUM')}
                    </Typography>
                    <Typography type={'text3'}>{profile.ext}</Typography>
                  </Flex>
                </div>
                <Flex className={classes.profileCommonInfo} direction={'column'}>
                  <Typography type={'text4'} color={'tertiary600'}>
                    {translate('DEPARTMENTS')}
                  </Typography>
                  {userDepartments?.length ? (
                    <div className={classes.profileUserDepartmentBg}>
                      <Flex direction={'column'}>
                        {userDepartments?.map((depName, i) => (
                          <Typography
                            key={`department-${i}`}
                            className={classes.profileUserDepartment}
                            type={'text3'}
                          >
                            {depName}
                          </Typography>
                        ))}
                      </Flex>
                    </div>
                  ) : (
                    <Typography type={'text3'} color={'tertiary900'}>
                      {translate('NOT_INCLUDED_IN_ANY_DEPARTMENT')}
                    </Typography>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent={'spaceBetween'}>
            <ProfileCard
              title={'FORWARDING'}
              icon={<ArrowCrossIcon />}
              content={
                <>
                  <div className={classes.profileCardPhone}>
                    <PhoneField
                      name={'phone'}
                      label={translate('MOBILE')}
                      required={callForwardingEnabled}
                    />
                  </div>
                  <CallForwardingFields
                    className={classes.defaultElementWidth17}
                    onChange={handleFormChange}
                  />
                </>
              }
            />
            <ProfileCard
              title={'NOTIFICATIONS'}
              icon={<EmailIcon />}
              content={<>{renderUserNotifications()}</>}
            />
            <ProfileCard
              title={'SECURITY'}
              icon={<LockIcon />}
              content={
                <Flex direction="column">
                  <Button
                    title={translate('CHANGE_PASSWORD')}
                    variant={'secondary'}
                    className={classes.button}
                    onClick={() => setOpenPassword(true)}
                  />
                  {user && (
                    <Button
                      title={translate('CHANGE_EMAIL')}
                      variant={'secondary'}
                      className={classes.button}
                      onClick={() => setIsChangeEmailModalOpen(true)}
                    />
                  )}
                </Flex>
              }
            />
          </Flex>
          <BottomButtons>
            <div className={classes.actions}>
              <ControlButtons
                confirmTitle={'SAVE_CHANGES'}
                cancelTitle={'CANCEL'}
                form={'profile-form'}
                onCancelClick={handleCancelClick}
                rootConfirmStyles={classes.confirmButton}
                loading={
                  loadingUpdate &&
                  !openPassword &&
                  !isChangeEmailModalOpen &&
                  !isAvatarDialogEditOpen
                }
                justifyContent={'start'}
                cancelUnderline
              />
            </div>
          </BottomButtons>
          <MessageDialog
            isOpen={isAvatarDialogEditOpen}
            title={translate('UPLOADING_PHOTO')}
            contentClass={classes.defaultElementWidth24}
            onCancel={handleClosePopupDialog}
            renderContent={
              <Flex direction={'column'} alignItems={'center'}>
                {avatarImage && !errors.avatarError ? (
                  <>
                    <AvatarEditor
                      image={avatarImage}
                      className={classes.avatarEditor}
                      color={[0, 0, 0, 0.5]}
                      scale={scaleValue}
                      borderRadius={150}
                      border={2}
                      ref={newAvatar}
                      crossOrigin={'anonymous'}
                      onLoadFailure={handleUploadAvatarError}
                    />
                    <RangePicker
                      className={classes.avatarScale}
                      min={1}
                      max={2}
                      step={0.1}
                      value={scaleValue}
                      onRangeChange={(value: number | number[]) =>
                        setScaleValue(
                          Array.isArray(value) && value.length > 0 ? value[0] : (value as number)
                        )
                      }
                    />
                  </>
                ) : (
                  <>
                    <Flex
                      className={classes.avatarControlsWrapper}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <div className={classes.editAvatarBg}>
                        {profileAvatar && !isAvatarRemove ? (
                          <>
                            <Button
                              className={classes.avatarControl}
                              variant={'secondary'}
                              disabled={!!errors?.avatarError}
                              onClick={() => {
                                setAvatarImage(getAvatarUrl(profile.avatar));
                              }}
                            >
                              <CropIcon />
                            </Button>
                            <Avatar
                              onClick={() => setIsAvatarDialogEditOpen(true)}
                              url={getAvatarUrl(profile.avatar)}
                              className={classes.editorAvatar}
                              size={'large'}
                            />
                            {renderFileUploader()}
                            <Button
                              className={classes.avatarControl}
                              variant={'secondary'}
                              color={'error'}
                              disabled={!!errors?.avatarError}
                              onClick={() => setIsAvatarRemove(true)}
                            >
                              <TrashIcon />
                            </Button>
                          </>
                        ) : (
                          <>
                            <CameraIcon className={classes.editAvatarCameraIcon} />
                            <Typography
                              color={'link600'}
                              type={'text3'}
                              underline
                              className={classes.editAvatarText}
                            >
                              {translate('UPLOAD_AVATAR')}
                            </Typography>
                            {renderFileUploader()}
                          </>
                        )}
                      </div>
                    </Flex>
                    <div className={classes.confirmPopupText}>
                      {errors.avatarError ? (
                        <Typography type={'text3'} color={'danger600'}>
                          {errors.avatarError.message}
                        </Typography>
                      ) : (
                        <Typography type={'text3'} color={'tertiary900'}>
                          {translate('MAX_FILE_SIZE')}
                        </Typography>
                      )}
                    </div>
                  </>
                )}
                <div className={classes.avatarEditButtons}>
                  <ControlButtons
                    confirmDisable={!isThereModificationsOnAvatar}
                    confirmTitle={'SAVE'}
                    cancelTitle={'CANCEL'}
                    cancelVariant="secondary"
                    onConfirmClick={handleUpdateAvatar}
                    onCancelClick={handleClosePopupDialog}
                    justifyContent={'start'}
                    flexDirection={'row-reverse'}
                    loading={loadingUpdate || avatarLoadingLocal}
                    small
                  />
                </div>
              </Flex>
            }
          />
        </form>
      </FormProvider>
      <ConfirmDialog
        isBlocked={isChanged.isBlocking}
        onSaveChanges={handleSubmit(handleFormSubmit)}
        onNavigationBlocked={getBlockedPath}
      />
      <FormErrorMessage errors={error?.graphQLErrors} />
    </div>
  );

  return (
    <BodyContainer>
      <Flex className={classes.root}>
        {renderContent()}
        {renderDialog()}
        {renderSuccessNotify()}
      </Flex>
    </BodyContainer>
  );
};

export default Profile;
