import { makeStyles } from '@material-ui/core/styles';

export const useIntegrationsStyles = makeStyles(
  ({ color: { tertiary, links, success, primary } }) => ({
    content: {
      width: '100%',
      minWidth: '1190px',
      height: '100%',
      padding: '0 2em 1em 2em',
      flexFlow: 'column',
    },
    cardsSection: {
      flexFlow: 'row wrap',
      paddingBottom: '1em',
    },
    cardsSectionCategory: {
      marginBottom: '1em',
    },
    cardsSectionTitleHigh: {
      marginBottom: '0.25em',
    },
    cardsSectionTitle: {
      marginBottom: '1em',
    },
    cardsSectionDescription: {
      marginBottom: '2em',
    },
    card: {
      width: '16.5em',
      height: '8.5em',
      cursor: 'pointer',
      position: 'relative',
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '0.75em',
      padding: '1em',
      marginRight: '1.5em',
      marginBottom: '1.5em',
      overflow: 'hidden',
      '&:last-child': {
        marginRight: 0,
      },
      '&:hover': {
        backgroundColor: tertiary[50],
      },
    },
    cardTitleBlock: {
      paddingBottom: '1em',
    },
    cardLogo: {
      width: '3.25em',
      height: '3.25em',
      marginRight: '1em',
    },
    cardPaidStatus: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '4.25em',
      height: '1.25em',
      borderRadius: '0 0 0 8px'
    },
    cardPaidStatusFree: {
      backgroundColor: success[50],
    },
    cardPaidStatusPaid: {
      backgroundColor: links[50],
    },
    cardTitle: {

    },
    cardDescription: {
      height: '2em',
    },
    cardButton: {
      height: '2em',
      width: '100%',
      marginTop: 'auto',
    },
    integrationCard: {
      width: '100%',
      height: '5.25em',
      boxSizing: 'border-box',
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '12px',
      padding: '1em',
      marginBottom: '1em',
      '&:hover': {
        backgroundColor: tertiary[50],
      },
    },
    integrationCardLogo: {
      height: '3.25em',
      width: '3.25em',
      whiteSpace: 'nowrap',
      color: links[600],
    },
    integrationCardTitle: {
      paddingLeft: '1em',
      wordBreak: 'break-word',
      overflow: 'hidden',
    },
    integrationCardName: {
      width: '33%',
    },
    integrationCardLogoSection: {
      flexFlow: 'row nowrap',
      width: '23%',
    },
    integrationActionsSection: {
      flexFlow: 'row nowrap',
      width: '43%',
    },
    integrationCardButton: {
      height: '2em',
      width: '9em',
      marginLeft: '1em',
      borderRadius: '6px',
    },
    integrationCardSideLogo: {
      width: '5em',
      opacity: '0.5',
    },
    integrationsListPreloader: {
      width: '100%',
      display: 'flex',
      paddingTop: '2em',
      marginBottom: '1em',
      justifyContent: 'center',
      alignItems: 'center',
    },
    integrationDialog: {
      width: '23em',
    },
    integrationTabs: {
      padding: '1em 0'
    },
    integrationTab: {
      fontSize: '0.875em',
      position: 'relative',
      padding: '0.5em 0 1em',
      textDecoration: 'none',
      color: tertiary[600],
      marginRight: '1em',
      '&::after': {
        borderRadius: '2px 2px 0px 0px',
        content: '" "',
        borderTop: '2px solid transparent',
        position: 'absolute',
        height: '0px',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'transparent',
      },
      '&:hover': {
        color: tertiary[900],
        '&:after': {
          borderTop: `2px solid ${tertiary[300]}`,
        },
      },
    },
    integrationTabActive: {
      color: tertiary[900],
      cursor: 'default',
      '&::after': {
        borderTop: `2px solid ${primary[700]}`,
      },
      '&:hover': {
        color: tertiary[900],
      },
    },
  }),
  {
    index: 1,
  }
);
