import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormErrors } from '@components/common/formErrors.hooks';
import { IIncomingNumberScenario } from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import Typography from '@shared/components/Typography';
import MessageFields from '@/components/MessageFields/MessageFields';
import { defaultMessages } from '../../IncomingNumber.constants';
import { useMessageScenarioStyles } from './MessageScenario.styles';
import { ScenariosTypes } from '../../IncomingNumber.interfaces';
import { IMessageSettingsFormData } from './MessageScenario.interfaces';

export interface IMessageScenarioProps {
  settings: IIncomingNumberScenario;
  onSave: (node: IIncomingNumberScenario) => void;
  onRemove: () => void;
  onCancel: () => void;
  onChanges: () => void;
  setIsSaveButtonLoading?: (value: boolean) => void;
}

export const MessageScenario = ({
  settings,
  onSave,
  onCancel,
  onChanges,
  setIsSaveButtonLoading,
}: IMessageScenarioProps) => {
  const classes = useMessageScenarioStyles();
  const [translate] = useTranslation();

  const formMethods = useForm<IMessageSettingsFormData>({
    defaultValues: {
      message: settings?.id
        ? settings?.message
        : translate(defaultMessages.get(ScenariosTypes.Message) || '', ''),
      messageType: settings?.messageType || 'TEXT',
      isMessageActive: true,
      fileName: settings?.fileName,
      fileUrl: settings?.fileUrl,
      fileHash: settings?.fileHash,
    },
  });

  const { handleSubmit } = formMethods;

  const handleFormSubmit = (data: IMessageSettingsFormData) => {
    onSave({ ...data, type: ScenariosTypes.Message, isMessageActive: true, fileUrl: undefined });
    onCancel();
  };

  const handleChanges = () => {
    if (typeof onChanges === 'function') {
      onChanges();
    }
  };

  useFormErrors(undefined, formMethods);

  return (
    <div className={classes.root}>
      <FormProvider {...formMethods}>
        <form id={'edit-text-or-audio'} action={''} onSubmit={handleSubmit(handleFormSubmit)}>
          <div>
            <div className={classes.caption}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('MESSAGE')}
              </Typography>
            </div>
            <MessageFields
              required
              onChanges={handleChanges}
              setIsSaveButtonLoading={setIsSaveButtonLoading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default MessageScenario;
