import {
  scenarioSettingsType,
  scenariosListType,
} from '@/features/Integrations/IntegrationForm/IntegrationForm.interfaces';
import KlienticsHelpImg from './images/klientics_help.png';

export const KlienticsScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'SCENARIO_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_01'],
    },
    {
      title: 'SCENARIO_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_02'],
    },
    {
      title: 'SCENARIO_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_03'],
    },
    {
      title: 'SCENARIO_TITLE_04',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_04'],
    },
  ],
];

export const KLIENTICS_SLIDER_IMAGES = [
  {
    title: 'KLIENTICS_WHERE_TO_FIND',
    img: KlienticsHelpImg,
  },
];
