/* eslint-disable no-param-reassign */
type MergeMultipleRefsFuncArgs<T extends HTMLElement> = (React.Ref<T> | null | undefined)[];
/**
 * Simple utility function that allows to assign
 * HTMLElement to multiple refs. Just pass return value of this
 * function to ref attribute of any React node.
 */
export function mergeMultipleRefs<T extends HTMLElement>(
  ...refs: MergeMultipleRefsFuncArgs<T>
): React.RefCallback<T> {
  return function (element) {
    refs.forEach((ref) => {
      if (!ref) return;
      if (ref instanceof Function) {
        ref(element);
      } else if (
        typeof ref === 'object' &&
        ref !== null &&
        Object.prototype.hasOwnProperty.call(ref, 'current')
      ) {
        // TODO create type and remove ts ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current = element;
      }
    });
  };
}
