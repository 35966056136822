import { makeStyles } from '@material-ui/core/styles';

export const useUploadFieldStyles = makeStyles(
  ({ color: { primary, tertiary } }) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    control: {
      display: 'flex',
      flexFlow: 'column',
      position: 'relative',
      width: '100%',
    },
    button: {
      height: '2.5em',
    },
    linearProgress: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.2,
      background: 'transparent',
      zIndex: 0,
    },
    label: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'transparent',
      zIndex: 1,
    },
    circularProgress: {
      marginRight: '0.5em',
      color: primary[600],
    },
    fileLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '0 0.5em',
    },
    functionalButton: {
      padding: 0,
      marginLeft: '0.5em',
      width: '3em',
      minWidth: '3em',
      height: '2.5em',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    smallSize: {
      width: '3.5em',
    },
    wideSize: {
      width: '7em',
    },
    textOutlined: {
      backgroundColor: tertiary['100'],
      borderRadius: '4px',
      width: '100%',
      height: '2.5em',
      paddingTop: '0.5em',
      alignItems: 'center',
      textAlign: 'center',
    },
  }),
  {
    index: 1,
  }
);

export const useHelperTextStyles = makeStyles(
  ({ color: { danger } }) => ({
    root: {
      marginLeft: 0,
      '&$error': {
        margin: 0,
        position: 'absolute',
        top: '100%',
        fontSize: '0.625em',
        color: danger[600],
      },
    },
    error: {},
  }),
  {
    index: 1,
  }
);
