import { TariffModel, TariffOptionModel } from '@/client/generated/graphql';
import { getAccountsPrices } from '@/utils/tariff/getAccountsPrices';
import { ResultOptionsType } from '@components/typings/interfaces';

export const getTariffEmployeePrices = (
  tariffDataInfo?: ResultOptionsType,
  currentTariff?: TariffModel | null,
  accountAmount?: number,
  accountsOption?: TariffOptionModel,
  additionalAccountsOption?: TariffOptionModel,
  isDiscountLoss?: boolean
) => {
  if (!tariffDataInfo) return { price: 0, discountPrice: 0 };
  const employeeAmount = accountAmount || 1;
  const { accountMax } = tariffDataInfo;

  const {
    isAccountDiscount,
    isAdditionalAccountDiscount,
    accountPrice,
    baseAccountPrice,
    additionalAccountPrice,
    baseAdditionalAccountPrice,
  } = getAccountsPrices(accountsOption, additionalAccountsOption, tariffDataInfo);

  const isDiscount = (isAccountDiscount || isAdditionalAccountDiscount) && !isDiscountLoss;

  if (employeeAmount <= accountMax) {
    return {
      price: baseAccountPrice * employeeAmount,
      discountPrice: isAccountDiscount && !isDiscountLoss ? accountPrice * employeeAmount : 0,
    };
  }
  return {
    price:
      baseAccountPrice * accountMax + (employeeAmount - accountMax) * baseAdditionalAccountPrice,
    discountPrice: isDiscount
      ? accountPrice * accountMax + (employeeAmount - accountMax) * additionalAccountPrice
      : 0,
  };
};
