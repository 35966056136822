import SidebarItem from '@/components/SidebarItem/SidebarItem';
import {
  administratorPrivateRoutesProps,
  operatorPrivateRoutesProps,
  ownerPrivateRoutesProps,
  systemPrivateRoutesProps,
  userPrivateRoutesProps,
} from '@/routes';
import { useQuery } from '@apollo/client';
import { IRoute } from '@components/Routes';
import Sidebar from '@components/Sidebar';
import { AUTHENTICATION_STATE_QUERY, WIDE_SIDEBAR_STATE } from '@components/client/queries';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
// prettier-ignore
import {
  isFeatureAvailable,
  isOptionAvailable,
  useDomainFeatures,
  useDomainOptions,
} from '@/common/hooks/domainFeatures.hooks';
import { LogoHorizontalPrimaryIcon, LogoPrimary } from '@shared/assets/images/icons/logos';
import { getRole } from '@/utils';
import { Role } from '@/client/generated/graphql';
import ErrorBoundaryCommon from '@shared/components/ErrorBoundaryCommon';
import DashboardHeader from './DashboardHeader';
import { useDashboardLayoutStyles } from './DashboardLayout.styles';

export const DashboardLayout = () => {
  const classes = useDashboardLayoutStyles();

  const { data: dataAuthStateQuery } = useQuery<{ isAuthenticated?: boolean; role?: string }>(
    AUTHENTICATION_STATE_QUERY
  );
  const role = getRole(dataAuthStateQuery?.role);

  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWideSidebar = sidebarState?.isWide;
  const { features } = useDomainFeatures();
  const { options: domainOptions } = useDomainOptions();

  const privateRoutes = useMemo(() => {
    switch (role) {
      case Role.Manager:
      case Role.Supporter:
      case Role.Sysadmin: {
        return systemPrivateRoutesProps;
      }
      case Role.Owner: {
        return ownerPrivateRoutesProps;
      }
      case Role.Admin: {
        return administratorPrivateRoutesProps;
      }
      case Role.User: {
        return userPrivateRoutesProps;
      }
      case Role.Operator: {
        return operatorPrivateRoutesProps;
      }
      default:
        return [];
    }
  }, [role]);

  const renderMenuItemsReducer = (result: IRoute[], item: IRoute) => {
    const hiddenOnProduction =
      (item.feature && !isFeatureAvailable(item.feature, features)) ||
      (item.option && !isOptionAvailable(item.option, domainOptions));

    if (!hiddenOnProduction) {
      if (!item?.excluded) {
        result.push(item);
      }
    }

    return result;
  };

  const computedWrapperClasses = clsx(classes.wrapper, {
    [classes.wrapperLeftShort]: !isWideSidebar,
    [classes.wrapperLeftLong]: isWideSidebar,
  });

  return (
    <>
      <Sidebar
        LogoVertical={<LogoPrimary />}
        LogoHorizontal={<LogoHorizontalPrimaryIcon />}
        items={privateRoutes.reduce(renderMenuItemsReducer, [])}
        ItemRender={SidebarItem}
        expandable
      />
      <main className={computedWrapperClasses}>
        <div className={classes.layoutRoot}>
          <DashboardHeader />
          <ErrorBoundaryCommon pageErrorBodyClassName={classes.pageErrorBody}>
            <Outlet />
          </ErrorBoundaryCommon>
        </div>
      </main>
    </>
  );
};

export default DashboardLayout;
