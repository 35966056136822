import { makeStyles } from '@material-ui/core/styles';

export const useSelectionAreaStyles = makeStyles(
  ({ color: { secondary, links, danger, tertiary, primary, success, warning } }) => ({
    areaPrimary: {
      background: primary[200],
      border: `0.0625em solid ${primary[500]}`,
    },
    areaTertiary: {
      background: tertiary[50],
      border: `0.0625em solid ${danger[500]}`,
    },
    areaSuccess: {
      background: success[200],
      border: `0.0625em solid ${success[500]}`,
    },
    areaSecondary: {
      background: secondary[200],
      border: `0.0625em solid ${secondary[500]}`,
    },
    areaLinks: {
      background: links[200],
      border: `0.0625em solid ${links[500]}`,
    },
    areaWarning: {
      background: warning[200],
      border: `0.0625em solid ${warning[500]}`,
    },
  })
);
