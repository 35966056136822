import React, { FunctionComponent } from 'react';
import Flex from '@shared/components/Flex';
import { ICellProps } from '@components/LazyTable';
import { useSearchParams } from 'react-router-dom';
import HighlightedText from '@components/HighlightedText';
import { useApologySMSStyles } from '../SettingsApologySMS.styles';
import { IApologySMS } from '../SettingsApologySMS.interface';

export const ApologySMSMessage: FunctionComponent<ICellProps<IApologySMS>> = ({ item }) => {
  const { messageTemplate = '', active } = item || {};
  const classes = useApologySMSStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('search') || null;

  return (
    <Flex direction={'column'} className={classes.defaultElementWidthFull}>
      <HighlightedText
        type={'text'}
        text={messageTemplate}
        query={searchText}
        typographyColor={active ? 'tertiary900' : 'tertiary300'}
        whiteSpaceProp={'break-spaces'}
      />
    </Flex>
  );
};
