import React, { FunctionComponent, useState } from 'react';
import {
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  PhoneMissedIcon,
} from '@shared/assets/images/icons';

import clsx from 'clsx';
import Typography from '@shared/components/Typography';
import { Button } from '@shared/components/Button/Button';
import { FilterButtonIconTypes, IButtonFilterProps } from './ButtonFilter.interfaces';
import { ButtonFilterStyles } from './ButtonFilter.styles';

export const ButtonFilter: FunctionComponent<IButtonFilterProps> = (props) => {
  const { icon, title, count, id, onClick, selected, disabled, border } = props;
  const classes = ButtonFilterStyles();

  const iconList = {
    [FilterButtonIconTypes.All]: <PhoneIcon className={classes.black} />,
    [FilterButtonIconTypes.Incoming]: <PhoneIncomingIcon className={classes.links} />,
    [FilterButtonIconTypes.IncomingMissed]: <PhoneIncomingIcon className={classes.danger} />,
    [FilterButtonIconTypes.Outgoing]: <PhoneOutgoingIcon className={classes.success} />,
    [FilterButtonIconTypes.OutgoingMissed]: <PhoneMissedIcon className={classes.warning} />,
  };

  const [hover, setHover] = useState(false);

  const buttonClasses = clsx(classes.button, {
    [classes.disabled]: disabled,
    [classes.hover]: hover,
    [classes.selected]: selected,
    [classes.noIcon]: icon === undefined,
  });

  const borderClasses = clsx({
    [classes.border]: border,
  });

  function handleMouseEnter() {
    if (!disabled && !selected) {
      setHover(true);
    }
  }

  function handleMouseLeave() {
    if (!disabled && !selected) {
      setHover(false);
    }
  }

  function handleClick() {
    if (!disabled && !selected) {
      setHover(false);
      if (onClick) {
        onClick(String(id));
      }
    }
  }

  return (
    <div className={classes.wrap}>
      <Button
        clear
        onClick={handleClick}
        disableRipple
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={buttonClasses}>
          <div className={classes.block}>
            <div className={classes.text}>
              <Typography className={classes.title} type={'text4'} color={'tertiary900'}>
                {title}
              </Typography>
            </div>
            <div className={classes.textLine}>
              {!!icon && <div className={classes.icon}>{iconList[icon]}</div>}
              <Typography type={'text4'} medium color={'tertiary900'}>
                {count}
              </Typography>
            </div>
          </div>
        </div>
      </Button>
      <div className={borderClasses} />
    </div>
  );
};
