import { useTariffFormStyle } from '@components/TariffForm/TariffForm.styles';
import Typography from '@shared/components/Typography';
import React from 'react';

type Props = {
  discountData:
    | {
        monthlyDiscount?: number | null;
        isMonthlyDiscountAbsolute?: boolean | null;
      }
    | undefined;
};

export const TariffDiscount = ({ discountData }: Props) => {
  const classes = useTariffFormStyle();

  if (!discountData) return null;

  const { monthlyDiscount: discount, isMonthlyDiscountAbsolute: isAbsolute } = discountData;
  if (!discount) return null;

  const discountAmount = `(-${discount}${isAbsolute ? '₽' : '%'})`;
  return (
    <Typography className={classes.tariffDiscountTextPadding} type={'default'} color={'primary600'}>
      {discountAmount}
    </Typography>
  );
};
