export enum NotificationTypes {
  Email = 'email',
  SMS = 'sms',
}

export enum NotificationEntity {
  Number = 'domainNumber',
  Department = 'department',
  Employee = 'employee',
}
