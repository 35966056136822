export enum SchedulePeriodsTypes {
  ATC = 'atc',
  NonWorkingHours = 'nonWorkingHours',
  WorkingHours = 'workingHours',
  Custom1 = 'custom1',
  Custom2 = 'custom2',
  Custom3 = 'custom3',
}

export enum SchedulingMode {
  ATC = 'atc',
  Scheduled = 'scheduled',
}

export enum ScenariosTypes {
  Period = 'period',
  Message = 'message',
  Voicemail = 'voicemail',
  VoiceMenu = 'voiceMenu',
  Fax = 'fax',
  ClientChoice = 'clientChoice',
  RedirectToNumber = 'redirectToNumber',
  RedirectToEmployee = 'redirectToEmployee',
  RedirectToDepartment = 'redirectToDepartment',
  SmartGreeting = 'smartGreeting',
}

export enum ScenarioActionTypes {
  Repeat = 'repeat',
  End = 'end',
  Redirect = 'redirect',
}

export interface IIncomingNumberScenarioConfig {
  nodeId?: string;
  parentId?: string;
  periodKey?: SchedulePeriodsTypes;
  settingType: ScenariosTypes;
}

export type ServerSchedulePeriod = {
  start: number;
  end: number;
  days: number[];
};

export type ServerSchedule = {
  [key in SchedulePeriodsTypes]?: ServerSchedulePeriod[];
};
