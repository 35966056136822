import { makeStyles } from '@material-ui/core';

const px = 1;
const py = 0.5;

export const useButtonStyle = makeStyles(({ color: { base, primary, tertiary, danger } }) => ({
  root: {
    padding: `${py}em ${px}em`,
    fontSize: '1em',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  borderRadius6: {
    borderRadius: '6px',
  },
  borderRadius20: {
    borderRadius: '20px',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  primary: {
    color: base,
    backgroundColor: primary[700],
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: primary[600],
      color: base,
      boxShadow: 'none',
    },
    '&$disableElevation': {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  primaryDisabled: {
    backgroundColor: tertiary[100],
    color: tertiary[300],
  },
  secondary: {
    backgroundColor: base,
    borderColor: tertiary[300],
    'MuiTouchRipple-root': {
      backgroundColor: primary[50],
    },
    '&:hover': {
      backgroundColor: primary[100],
      borderColor: primary[600],
      color: tertiary[900],
    },
  },
  secondaryDisabled: {
    opacity: 0.5,
    color: tertiary[300],
  },
  secondaryFilled: {
    backgroundColor: tertiary[100],
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: tertiary[200],
    },
  },
  secondaryFilledDisabled: {
    opacity: 0.8,
    color: tertiary[300],
  },
  disableElevation: {},
  primaryError: {
    color: base,
    backgroundColor: danger[600],
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: danger[500],
    },
  },
  primaryErrorLoading: {
    color: base,
    backgroundColor: tertiary[100],
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: tertiary[100],
    },
  },
  primaryLoading: {
    color: base,
    backgroundColor: tertiary[100],
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: tertiary[100],
    },
  },
  secondaryError: {
    backgroundColor: base,
    borderColor: tertiary[300],
    '&:hover': {
      backgroundColor: danger[200],
      borderColor: danger[600],
      color: tertiary[900],
    },
  },
  tertiary: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    transition: '0.3s ease',
    textDecoration: 'underline',
    '&:hover': {
      borderColor: tertiary[200],
      backgroundColor: 'transparent',
    },
    '&:hover $typography': {
      color: primary[700],
      textDecoration: 'none',
    },
  },
  typography: {},
  text: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clear: {
    padding: '0 !important',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: 'inherit',
    textTransform: 'initial',
    lineHeight: 'initial',
  },
  labelLoading: {
    '& > :not($loaderWrapper)': {
      opacity: 0,
    },
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '1.25em',
  },
  icon: {
    border: 0,
    padding: 0,
  },
  smallHeight: {
    height: '2em',
  },
}));
