import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import {
  SmsIcon,
  EmailIcon,
  UsersIcon,
  UserIcon,
  AlertTriangleIcon,
} from '@shared/assets/images/icons';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import HighlightedText from '@components/HighlightedText';
import { IMissedCalls } from '../SettingsNotifications.interface';
import { useNotificationsStyles } from '../SettingsNotifications.styles';

export const NotificationsRecipientCell: FunctionComponent<ICellProps<IMissedCalls>> = ({
  item,
}) => {
  const { receivers } = item || {};
  const [translate] = useTranslation();
  const classes = useNotificationsStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  const renderRecipientContent = () => {
    if (receivers) {
      if (receivers.length === 0) {
        return (
          <div className={classes.receiverContainer}>
            <AlertTriangleIcon className={classes.danger} />
            <Typography className={classes.textCell} type={'text3'} color={'tertiary900'}>
              {translate('NO_RECIPIENT')}
            </Typography>
          </div>
        );
      }
      return receivers?.map((rec) => {
        const renderContent = () => {
          if (rec.department) {
            return (
              <div key={rec.id} className={classes.receiverContainer}>
                <UsersIcon className={clsx(classes.iconMargin, classes.secondary)} />
                <HighlightedText type={'text'} text={rec.department?.name} query={searchText} />
              </div>
            );
          }
          if (rec.employee) {
            return (
              <div key={rec.id} className={classes.receiverContainer}>
                <UserIcon className={clsx(classes.iconMargin, classes.primary)} />
                <HighlightedText type={'text'} text={rec.employee?.name} query={searchText} />
              </div>
            );
          }
          if (rec.notificationTypes) {
            return rec.notificationTypes.map((type) => {
              if (type === 'sms') {
                return (
                  <div
                    key={`phone-${rec.id}`}
                    className={clsx(classes.receiverContainer, classes.sortingTypeSms)}
                  >
                    <SmsIcon className={classes.iconMargin} />
                    <HighlightedText type={'phone'} text={rec?.external} query={searchText} />
                  </div>
                );
              }
              if (type === 'email') {
                return (
                  <div
                    key={`email-${rec.id}`}
                    className={clsx(classes.receiverContainer, classes.sortingTypeEmail)}
                  >
                    <EmailIcon className={classes.iconMargin} />
                    <HighlightedText type={'text'} text={rec.external} query={searchText} />
                  </div>
                );
              }
              return null;
            });
          }
          return null;
        };
        return <Fragment key={rec.id}>{renderContent()}</Fragment>;
      });
    }
    return null;
  };

  return <Flex direction={'column'}>{renderRecipientContent()}</Flex>;
};
