import { ApolloError, useLazyQuery } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';

export const getNetworkError = (error: unknown) => {
  if (!(error instanceof ApolloError) || error.networkError === null) {
    return null;
  }
  return error.networkError;
};

export const getGraphqlErrors = (error: unknown) => {
  if (!(error instanceof ApolloError) || error.graphQLErrors.length <= 0) {
    return null;
  }
  return error.graphQLErrors;
};

export const getErrorMessageFromGraphqlError = (error: unknown) => {
  const graphqlErrors = getGraphqlErrors(error);
  if (!graphqlErrors) {
    return null;
  }

  const exception = graphqlErrors?.[0]?.extensions?.exception as { message?: string };

  return exception?.message || null;
};

function isMessageObject(object: unknown): object is { message: string } {
  return (
    typeof object === 'object' &&
    object !== null &&
    typeof (object as { message: string }).message === 'string'
  );
}

function isExceptionErrors(object: unknown): object is { errors: unknown[] } {
  return (
    typeof object === 'object' &&
    object !== null &&
    Array.isArray((object as { errors: unknown[] }).errors)
  );
}

const getAllErrorMessagesFromGraphqlErrors = (graphQLErrors: GraphQLErrors) => {
  const result: string[] = [];

  for (let i = 0; i < graphQLErrors.length; i += 1) {
    const graphqlError = graphQLErrors[i];
    const exception = graphqlError?.extensions?.exception;
    if (!isMessageObject(exception)) {
      continue;
    }
    result.push(exception.message);
  }

  return result;
};

const getAllErrorMessagesFromGraphqlErrorsInnerErrors = (graphQLErrors: GraphQLErrors) => {
  const result: string[] = [];

  for (let i = 0; i < graphQLErrors.length; i += 1) {
    const graphqlError = graphQLErrors[i];
    const exception = graphqlError?.extensions?.exception;
    if (!isExceptionErrors(exception)) {
      continue;
    }
    for (let j = 0; j < exception.errors.length; j += 1) {
      const innerError = exception.errors[j];
      if (!isMessageObject(innerError)) {
        continue;
      }
      result.push(innerError.message);
    }
  }

  return result;
};

export const isCertainGraphqlErrorCodeInApolloError = (error: unknown, code: string) => {
  const graphqlErrors = getGraphqlErrors(error);
  if (!graphqlErrors) {
    return false;
  }

  return getAllErrorMessagesFromGraphqlErrors(graphqlErrors).includes(code);
};

export const isCertainErrorMessageInApolloErrorErrors = (error: unknown, message: string) => {
  const graphqlErrors = getGraphqlErrors(error);
  if (!graphqlErrors) {
    return false;
  }

  return getAllErrorMessagesFromGraphqlErrorsInnerErrors(graphqlErrors).includes(message);
};

export const isInvalidPasswordError = (error: unknown) => {
  return isCertainGraphqlErrorCodeInApolloError(error, 'INVALID_PASSWORD');
};

export const areAllAndNewPasswordEqualError = (error: unknown) => {
  return isCertainGraphqlErrorCodeInApolloError(error, 'NEW_AND_OLD_PASSWORDS_EQUALS');
};

export const isEmailAlreadyRegisteredError = (error: unknown) => {
  return isCertainGraphqlErrorCodeInApolloError(error, 'EMAIL_ALREADY_REGISTERED');
};

export const isUnauthenticatedError = (error: unknown) => {
  const graphQLErrors = getGraphqlErrors(error);
  if (!graphQLErrors) {
    return false;
  }

  return graphQLErrors.some(
    ({ extensions: { code = '' } = { code: undefined } }: GraphQLError) =>
      code === 'UNAUTHENTICATED'
  );
};

/**
 * This custom hook is similar to useLazyQuery, except when error occurs
 * it will be thrown and needs to be caught with `.catch`. This hook
 * aims to resolve API inconsistency with `useMutation` marked in the following issue -
 * https://github.com/apollographql/apollo-client/pull/9684. If you need to use some fields
 * from successful promise even when error occurred, use regular `useLazyQuery` then.
 */
export const useCatchAbleLazyQuery: typeof useLazyQuery = (queryDocument, options) => {
  const [query, queryResult] = useLazyQuery(queryDocument, options);

  const modifiedQuery: typeof query = useCallback(
    (...args) => {
      return query(...args).then((data) => {
        if (data.error) {
          throw data.error;
        }
        return data;
      });
    },
    [query]
  );

  return [modifiedQuery, queryResult];
};
