import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { INCOMING_NUMBERS_QUERY } from '@/client/queries';
import {
  Integration,
  IScenarioSettingProps,
  selectedNumbersType,
} from '../IntegrationForm.interfaces';

export function useIntegrationNumbers(
  scenarioNumbers: string,
  integration: Integration,
  integrationSettings: IScenarioSettingProps[]
) {
  const [selectedNumbers, setSelectedNumbers] = useState<selectedNumbersType>({});
  const { data: incomingNumbersData } = useQuery(INCOMING_NUMBERS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const boughtDomainNumbers = useMemo(
    () => incomingNumbersData?.boughtDomainNumbers || [],
    [incomingNumbersData]
  );

  const selectedNumbersKeys = useMemo(() => Object.keys(selectedNumbers), [selectedNumbers]);

  const responsibleNumbersList = useMemo(
    () => (scenarioNumbers === 'allNumbers' ? boughtDomainNumbers : Object.values(selectedNumbers)),
    [scenarioNumbers, boughtDomainNumbers, selectedNumbers]
  );

  const isResponsibleNumbersAvailable = useMemo(
    () =>
      responsibleNumbersList.filter(
        (number) => number.responsible === null || number.responsible?.name === integration?.name
      ).length !== 0,
    [responsibleNumbersList, integration]
  );

  const formatDomainNumbers = (a1: string[], a2: string[] | null) =>
    a1.map((n) => ({
      domainNumberId: parseInt(n, 10),
      responsible: a2 ? a2.includes(n) : false,
    }));

  const availableNumbersList = useMemo(() => {
    const allSelectedNumbers = integrationSettings.reduce(
      (result: selectedNumbersType, sScenarioItem) => ({
        ...result,
        ...sScenarioItem.selectedNumbers,
      }),
      {}
    );

    const allSelectedNumbersKeys = Object.keys(allSelectedNumbers);

    return boughtDomainNumbers.filter(
      (number) => !allSelectedNumbersKeys.includes(String(number.id))
    );
  }, [integrationSettings, boughtDomainNumbers]);

  return {
    boughtDomainNumbers,
    selectedNumbersKeys,
    responsibleNumbersList,
    availableNumbersList,
    isResponsibleNumbersAvailable,

    selectedNumbers,
    setSelectedNumbers,

    formatDomainNumbers,
  };
}
