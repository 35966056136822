export interface ITelephonyDaysResult {
  todayAmount: number;
  minAmount: number;
  maxAmount: number;
  minDayName: string;
  maxDayName: string;
  todayDayName: string;
}

export interface IDesktopWidgetProps {
  pageSize?: string;
}

export enum callStatisticsPeriodTypes {
  Today = 'Today',
  Week = 'ThisWeek',
  Month = 'ThisMonth',
}

export enum callHourlyStatisticsTypes {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export type callStatisticsDataType = {
  inNotSuccess: number;
  inSuccess: number;
  outNotSuccess: number;
  outSuccess: number;
};

export type callHourlyStatisticsResultType = {
  firstColumn: Array<number>;
  secondColumn: Array<number>;
};

export enum desktopPageSize {
  Min = 'min',
  Normal = 'normal',
  Wide = 'wide',
}
