export const getEmployeeDepartmentList = (
  employees: any,
  departments: any,
  employeeType: string,
  departmentType: string
) => {
  const employeeList = employees.map(
    ({ id, user: { name = '' } }: { id: number; user: { name: string } }) => ({
      value: `${id}_${employeeType}`,
      title: name,
      type: employeeType,
    })
  );
  const departmentsList = departments.map(({ id, name = '' }: { id: number; name: string }) => ({
    value: `${id}_${departmentType}`,
    title: name,
    type: departmentType,
  }));

  return employeeList.concat(departmentsList) as { value: string; title: string; type: string }[];
};
