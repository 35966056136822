import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { IPayment } from '../Payments.interfaces';

export const PaymentNameCell: FunctionComponent<ICellProps<IPayment>> = ({ item }) => {
  const [translate] = useTranslation();
  const { id } = item || {};
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  if (!item) {
    return <div />;
  }

  return (
    <>
      <Typography type={'text3'} color={'tertiary900'}>
        {id ? `${translate('INVOICE_NUMBER')} № ` : '-'}
      </Typography>
      <HighlightedText type={'text'} text={String(id)} query={searchText} />
    </>
  );
};
