import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { toPrecision } from '@components/utils';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useTariffFormStyle } from '@components/TariffForm/TariffForm.styles';

export const TariffPriceDiscount: FunctionComponent<{
  price: number;
  discountPrice: number;
}> = ({ price, discountPrice }) => {
  const [translate] = useTranslation();
  const classes = useTariffFormStyle();
  return (
    <Flex direction={'column'} alignItems={'flexEnd'}>
      {discountPrice !== 0 ? (
        <Typography className={classes.amountTextStyle} type={'text3'} color={'tertiary900'} bold>
          {`${translate('RUB_PER_MONTH', {
            amount: toPrecision(discountPrice, false),
          })}`}
        </Typography>
      ) : null}
      <Typography
        className={discountPrice ? classes.oldPriceTextStyle : classes.amountTextStyle}
        type={discountPrice ? 'text4' : 'text3'}
        color={discountPrice ? 'tertiary600' : 'tertiary900'}
        bold
      >
        {`${translate('RUB_PER_MONTH', {
          amount: toPrecision(price, false),
        })}`}
      </Typography>
    </Flex>
  );
};
