import clsx from 'clsx';
import React from 'react';
import { TextColors, TextSize } from './Typography.constants';
import { TextProps } from './Typography.interfaces';
import { TypographyStyle } from './Typography.styles';

export const Typography = ({
  type = TextSize.TEXT3,
  color = TextColors.COLOR_TERTIARY900,
  children,
  underline,
  className,
  bold,
  medium,
  pxToEmSize,
  ...rest
}: TextProps) => {
  const classes = TypographyStyle();

  const typeClass = classes[type];
  const colorClass = classes[color];

  const computedClasses = clsx(
    classes.root,
    {
      [classes.underline]: underline,
      [classes.bold]: bold,
      [classes.medium]: medium,
    },
    typeClass,
    colorClass,
    className
  );

  const getFontSize = () => {
    if (typeof pxToEmSize !== 'number') {
      return undefined;
    }
    return `${pxToEmSize / 16}em`;
  };

  const getInlineStyles = () => {
    return { fontSize: getFontSize() };
  };

  return (
    <span className={computedClasses} style={getInlineStyles()} {...rest}>
      {children}
    </span>
  );
};

export default Typography;
