import { useState } from 'react';

export function useStateWithMemo<T>() {
  const [stateValues, setStateValues] = useState<T | null>(null);
  const [prevStateValues, setPrevState] = useState<T | null>(null);

  function savePrevState() {
    setPrevState(stateValues);
  }

  function restoreState() {
    setStateValues(prevStateValues);
  }

  return { stateValues, setStateValues, savePrevState, restoreState };
}
