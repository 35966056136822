import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { FormFieldRhfUncontrolledProps } from '../FormFieldRhfUncontrolled/FormFieldRhfUncontrolled.interfaces';
import FormFieldRhfUncontrolled from '../FormFieldRhfUncontrolled';

export const EmailField = ({
  name = 'email',
  required,
  inputProps = {},
  label,
  placeholder,
  validate,
  rules,
  InputProps,
  ...restTextFieldProps
}: FormFieldRhfUncontrolledProps) => {
  const [translate] = useTranslation();

  const validateEmail = (value: string) => {
    if (!value && !required) {
      return true;
    }
    if (value === '' || value === null) {
      return translate('EMAIL_REQUIRED');
    }
    if (value && !validator.isEmail(value, { allow_utf8_local_part: false })) {
      return translate('INVALID_EMAIL');
    }
    if (typeof validate === 'function') {
      return validate(value);
    }
    if (typeof rules?.validate === 'function') {
      return rules.validate(value);
    }

    return true;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value = undefined } = {} } = event || {};
    if (value) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = value.trim();
    }
    if (typeof restTextFieldProps?.onChange === 'function') {
      restTextFieldProps?.onChange(event);
    }
  };

  return (
    <FormFieldRhfUncontrolled
      label={label}
      placeholder={placeholder ?? 'user@domain.ru'}
      name={name || 'email'}
      validate={validateEmail}
      rules={rules}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
        },
      }}
      {...restTextFieldProps}
      onChange={handleChange}
    />
  );
};

export default EmailField;
