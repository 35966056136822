import { PaymentServiceModelWithId } from '@/utils/paymentServiceModel';
import { ICellProps } from '@components/LazyTable';
import { toPrecision } from '@components/utils/toPrecision';
import Typography from '@shared/components/Typography';
import React from 'react';
import { OperationType } from '../Balance.interfaces';

export const BalanceAmountCell = ({ item }: ICellProps<PaymentServiceModelWithId>) => {
  if (item) {
    let cellValue;

    switch (item.type) {
      case OperationType.Payment:
      case OperationType.Credit:
      case OperationType.FreezedPayment:
        if (item.amount < 0) {
          cellValue = `- ${toPrecision(item.amount).replace('-', '')} ₽`;
        } else {
          cellValue = `+ ${toPrecision(item.amount)} ₽`;
        }
        break;
      case OperationType.OneTime:
      case OperationType.Periodic:
      case OperationType.Telephony:
        cellValue = `- ${toPrecision(item.amount)} ₽`;
        break;
      default:
        break;
    }

    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {cellValue}
      </Typography>
    );
  }

  return <div />;
};
