import React, { FunctionComponent, memo, useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Drawer from '@components/Drawer';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import SelectField from '@shared/components/SelectField';
import Flex from '@shared/components/Flex';
import SwitchField from '@shared/components/SwitchField';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { getSettingsObjectKeys } from '../IntegrationForm.constants';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';
import {
  bitrixSettingLeadType,
  bitrixSettingTaskType,
  IScenarioSettingsType,
  responsibleObjectKey,
  scenarioDirectionEnum,
} from '../IntegrationForm.interfaces';
import {
  bitrixLeadResponsibleObjectKey,
  bitrixTaskResponsibleObjectKey,
} from './BitrixCRM.constants';
import { useBitrixScenarioFormPropsHooks } from './hooks/useBitrixScenarioFormProps.hooks';
import { IntegrationContext } from '@/features/Integrations/IntegrationForm/IntegrationForm';

export const BitrixCRMSettingsForm: FunctionComponent<IScenarioSettingsType> = ({
  isOpen,
  onClose,
  onComplete,
}) => {
  const { scenarioType, scenarioData, comparedEmployee, rawEmployee } = useContext(IntegrationContext);
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { setValue, watch, handleSubmit } = formMethods;
  const isLeadActive = watch('isLeadActive');
  const isTaskActive = watch('isTaskActive');
  const { title: settingTitle, edit } = scenarioType || {};
  const leadResponsibleKey = bitrixLeadResponsibleObjectKey(edit);
  const taskResponsibleKey = bitrixTaskResponsibleObjectKey(edit);
  const { direction, status, type } = getSettingsObjectKeys(edit);

  const {
    leadTitle,
    leadTitleDescription,
    isCommonSettings,
    comparedEmployeeList,
    rawEmployeeList,
    responsibleDefaultList,
  } = useBitrixScenarioFormPropsHooks(direction, edit, comparedEmployee, rawEmployee);

  useEffect(() => {
    if (isOpen && scenarioData && Object.keys(scenarioData).length) {
      if (direction === scenarioDirectionEnum.Common) {
        if (scenarioData[direction]?.defaultEmployee) {
          setValue('isLeadActive', true);
          setValue('manager', scenarioData[direction]?.defaultEmployee);
        } else {
          setValue('isLeadActive', false);
          setValue('manager', '');
        }
      } else {
        const isCurrentSettingFilled = scenarioData[direction]?.[type]?.[status];
        if (isCurrentSettingFilled?.lead) {
          const leadSetting = isCurrentSettingFilled?.lead as bitrixSettingLeadType;
          setValue('isLeadActive', true);
          const isLeadResponsibleFilled =
            leadSetting?.responsible || leadSetting?.defaultResponsible;
          if (isLeadResponsibleFilled) {
            setValue('leadResponsible', isLeadResponsibleFilled);
          }
        } else {
          setValue('isLeadActive', false);
        }
        if (isCurrentSettingFilled?.task) {
          const taskSetting = isCurrentSettingFilled?.task as bitrixSettingTaskType;
          setValue('isTaskActive', true);
          const isTaskResponsibleFilled =
            taskSetting?.responsible || taskSetting?.defaultResponsible;
          setValue('taskTitle', taskSetting?.title);
          setValue('taskDescription', taskSetting?.text);
          setValue('taskAuditor', taskSetting?.auditor);
          if (isTaskResponsibleFilled) {
            setValue('taskResponsible', isTaskResponsibleFilled);
          }
        } else {
          setValue('isTaskActive', false);
          setValue('taskTitle', '');
          setValue('taskDescription', '');
          setValue('taskAuditor', '');
          setValue('taskResponsible', '');
        }
      }
    } else {
      setValue('isLeadActive', false);
      setValue('isTaskActive', false);
    }
  }, [scenarioData, isOpen, direction, type, status, setValue]);

  const handleSettingsSubmit = useCallback(
    (formData) => {
      const { leadResponsible, taskResponsible, taskTitle, taskDescription, taskAuditor, manager } =
        formData || {};

      const leadResponsibleObject = leadResponsibleKey
        ? { [leadResponsibleKey]: leadResponsible }
        : undefined;

      const taskResponsibleObject = taskResponsibleKey
        ? { [taskResponsibleKey]: taskResponsible }
        : undefined;

      const lead =
        isLeadActive && !isCommonSettings
          ? { lead: { create: true, ...leadResponsibleObject } }
          : undefined;

      const task =
        isTaskActive && !isCommonSettings
          ? {
              task: {
                title: taskTitle,
                text: taskDescription,
                auditor: taskAuditor,
                ...taskResponsibleObject,
              },
            }
          : undefined;

      const defaultEmployee =
        isLeadActive && isCommonSettings ? { defaultEmployee: manager } : undefined;

      onComplete({
        direction,
        type,
        status,
        data: {
          ...lead,
          ...task,
          ...defaultEmployee,
        },
      });
    },
    [
      isLeadActive,
      isTaskActive,
      leadResponsibleKey,
      taskResponsibleKey,
      isCommonSettings,
      onComplete,
      direction,
      status,
      type,
    ]
  );

  return (
    <Drawer
      title={settingTitle || ''}
      variant={'persistent'}
      open={isOpen}
      classes={{ paper: classes.defaultElementWidth33 }}
      size={'lg'}
      primaryButton={
        !isLeadActive && !isTaskActive
          ? {
              title: 'APPLY',
              onClick: handleSettingsSubmit,
            }
          : {
              title: 'APPLY',
              props: {
                type: 'submit',
                form: 'scenarios-settings-form',
              },
            }
      }
      secondaryButton={{
        title: 'CANCEL',
        onClick: onClose,
      }}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSettingsSubmit)} id={'scenarios-settings-form'}>
          <div className={classes.scenarioSettingMenuContent}>
            <Flex className={classes.integrationOnOffSwitch}>
              <SwitchField
                name={'isLeadActive'}
                customLabel={
                  <Typography type={'text2'} color={'tertiary900'}>
                    {translate(leadTitle)}
                  </Typography>
                }
              />
            </Flex>
            <Flex
              className={!isLeadActive ? classes.responsibleWrapper : undefined}
              direction={'column'}
            >
              <div className={classes.scenarioSettingMenuTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {leadTitleDescription}
                </Typography>
              </div>
              {leadResponsibleKey && (
                <div className={classes.scenarioSettingMenuItem}>
                  <SelectField
                    data={
                      leadResponsibleKey === responsibleObjectKey.Responsible
                        ? responsibleDefaultList
                        : comparedEmployeeList
                    }
                    label={translate('CHOOSE_RESPONSIBLE_MANAGER')}
                    name={'leadResponsible'}
                    valueKey={'key'}
                    titleKey={'value'}
                    className={classes.defaultElementWidth21}
                    defaultValue={
                      taskResponsibleKey === responsibleObjectKey.Responsible ? 'responsible' : ''
                    }
                    translating={leadResponsibleKey === responsibleObjectKey.Responsible}
                    validate={(leadResponsible: string) => {
                      if (!isLeadActive) {
                        return true;
                      }
                      if (!leadResponsible || leadResponsible === '') {
                        return translate('RESPONSIBLE_EMPTY_TEXT') as string;
                      }
                      return true;
                    }}
                  />
                </div>
              )}
              {isCommonSettings && (
                <div className={classes.scenarioSettingMenuItem}>
                  <SelectField
                    data={rawEmployeeList}
                    name={'manager'}
                    valueKey={'key'}
                    titleKey={'value'}
                    defaultValue={'responsible'}
                    className={classes.defaultElementWidth21}
                    validate={(manager: string) => {
                      if (!isLeadActive) {
                        return true;
                      }
                      if (!manager || manager === '') {
                        return translate('CHOOSE_RESPONSIBLE_MANAGER') as string;
                      }
                      return true;
                    }}
                  />
                </div>
              )}
            </Flex>
            {!isCommonSettings && (
              <>
                <Flex className={classes.integrationOnOffSwitch}>
                  <SwitchField
                    name={'isTaskActive'}
                    customLabel={
                      <Typography type={'text2'} color={'tertiary900'}>
                        {translate('CREATE_TASK')}
                      </Typography>
                    }
                  />
                </Flex>
                <Flex
                  className={!isTaskActive ? classes.responsibleWrapper : undefined}
                  direction={'column'}
                >
                  <div className={classes.integrationResponsibleMessage}>
                    <div className={classes.integrationScenarioNumbersList}>
                      {taskResponsibleKey && (
                        <div className={classes.scenarioSettingMenuItemSmall}>
                          <SelectField
                            data={
                              taskResponsibleKey === responsibleObjectKey.Responsible
                                ? responsibleDefaultList
                                : comparedEmployeeList
                            }
                            label={translate('RESPONSIBLE')}
                            name={'taskResponsible'}
                            valueKey={'key'}
                            titleKey={'value'}
                            defaultValue={
                              taskResponsibleKey === responsibleObjectKey.Responsible
                                ? 'responsible'
                                : ''
                            }
                            translating={leadResponsibleKey === responsibleObjectKey.Responsible}
                            validate={(taskResponsible: string) => {
                              if (!isTaskActive) {
                                return true;
                              }
                              if (!taskResponsible || taskResponsible === '') {
                                return translate('RESPONSIBLE_EMPTY_TEXT') as string;
                              }
                              return true;
                            }}
                          />
                        </div>
                      )}
                      <div className={classes.scenarioSettingMenuItemSmall}>
                        <FormFieldRhfUncontrolled
                          name={'taskTitle'}
                          className={classes.integrationBottomField}
                          label={translate('TASK_TITLE')}
                          validate={(taskValue: string) => {
                            if (!isTaskActive) {
                              return true;
                            }
                            if (!taskValue) {
                              return translate('TASK_TITLE_EMPTY_TEXT') as string;
                            }
                            return true;
                          }}
                        />
                      </div>
                      <div className={classes.scenarioSettingMenuItemSmall}>
                        <FormFieldRhfUncontrolled
                          multiline
                          name={'taskDescription'}
                          rows={2}
                          className={classes.integrationBottomField}
                          label={translate('TASK_DESCRIPTION')}
                          validate={(taskDescription: string) => {
                            if (!isTaskActive) {
                              return true;
                            }
                            if (!taskDescription) {
                              return translate('TASK_DESCRIPTION_EMPTY_TEXT') as string;
                            }
                            return true;
                          }}
                        />
                      </div>
                      <div className={classes.scenarioSettingMenuItemSmall}>
                        <SelectField
                          data={comparedEmployeeList}
                          label={translate('OBSERVER')}
                          name={'taskAuditor'}
                          valueKey={'key'}
                          titleKey={'value'}
                          validate={(taskAuditor: string) => {
                            if (!isTaskActive) {
                              return true;
                            }
                            if (!taskAuditor || taskAuditor === '') {
                              return translate('AUDITOR_EMPTY_TEXT') as string;
                            }
                            return true;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Flex>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default memo(BitrixCRMSettingsForm);
