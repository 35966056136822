import { FunctionComponent, SVGProps } from 'react';

export enum IntegrationList {
  API = 'api',
  AmoCrm = 'amocrm',
  Bitrix24 = 'bitrix24',
  Megaplan = 'megaplan',
  Gravitel = 'gravitel',
  YClients = 'yclients',
  Omnidesk = 'omnidesk',
  Planfix = 'planfix',
  Roistat = 'roistat',
  RetailCRM = 'retailcrm',
  OdinS = '1c',
  ProstieZvonki = 'prostiezvonki',
  Elma365 = 'elma365',
  Albato = 'albato',
  Klientiks = 'klientiks',
}

export enum IntegrationCategory {
  CRM = 'CRM',
  CALLTRACKING = 'CALLTRACKING',
  OTHER = 'OTHER',
}

export interface IIntegrationTypes {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
}
