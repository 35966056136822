import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { IHistoryList } from '../HistoryPage.interfaces';

export const TimeCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  if (item) {
    const { time } = item;
    return (
      <Typography type={'text3'} color={'tertiary900'}>
        {time}
      </Typography>
    );
  }

  return <div />;
};
