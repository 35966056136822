import React, { FunctionComponent } from 'react';
import { SmsIcon, EmailIcon, AlertTriangleIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { IMissedCalls } from '../SettingsNotifications.interface';
import { useNotificationsStyles } from '../SettingsNotifications.styles';

export const NotificationsMethodCell: FunctionComponent<ICellProps<IMissedCalls>> = ({ item }) => {
  const { receivers } = item || {};
  const classes = useNotificationsStyles();
  const [translate] = useTranslation();

  const renderRecipientContent = () => {
    if (receivers) {
      return receivers?.map((rec, i) => {
        const renderIcon = () => {
          if (rec.notificationTypes) {
            return rec.notificationTypes.map((type, index) => {
              if (type === 'sms' && !rec.external) return <SmsIcon key={`icon_${index}`} />;
              if (type === 'email' && !rec.external) return <EmailIcon key={`icon_${index}`} />;
              return null;
            });
          }
          return null;
        };
        if (rec.notificationTypes && rec.notificationTypes.length > 0) {
          return (
            <Flex
              className={classes.iconsContainer}
              justifyContent={'spaceBetween'}
              alignItems={'center'}
              key={i}
            >
              {renderIcon()}
            </Flex>
          );
        }
        return (
          <div key={i} className={classes.alertContainer}>
            <AlertTriangleIcon className={clsx(classes.iconMargin, classes.danger)} />
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('NO_NOTIFICATIONS')}
            </Typography>
          </div>
        );
      });
    }
    return null;
  };

  return <Flex direction={'column'}>{renderRecipientContent()}</Flex>;
};
