import React, { FunctionComponent, memo, useContext, useState } from 'react';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import { IntegrationContext } from '@/features/Integrations/IntegrationForm/IntegrationForm';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import { AmoCRMAuthorization } from '../AmoCRM/AmoCRMAuthorization';
import { BitrixCRMAuthorization } from '../Bitrix24/BitrixCRMAuthorization';
import { YClientsCRMAuthorization } from '../YClients/YClientsCRMAuthorization';
import { RetailCRMAuthorization } from '../RetailCRM/RetailCRMAuthorization';
import { useNavigate } from 'react-router-dom';

export const InstallModule: FunctionComponent = () => {
  const {APIName} = useContext(IntegrationContext);
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [isModalShow, setModalShow] = useState(false);

  function handleClose() {
    setModalShow(false);
  }

  function handleClickInstall() {
    if (
      APIName === IntegrationList.AmoCrm ||
      APIName === IntegrationList.Bitrix24 ||
      APIName === IntegrationList.YClients ||
      APIName === IntegrationList.RetailCRM
    ) {
      setModalShow(true);
    } else {
      navigate(`/integrations/add-integration/${APIName}`);
    }
  }

  const renderSettingsForm = () => {
    if (APIName === IntegrationList.AmoCrm) {
      return <AmoCRMAuthorization isOpen={isModalShow} onClose={handleClose} />;
    }
    if (APIName === IntegrationList.Bitrix24) {
      return <BitrixCRMAuthorization isOpen={isModalShow} onClose={handleClose} />
    }
    if (APIName === IntegrationList.YClients) {
      return <YClientsCRMAuthorization isOpen={isModalShow} onClose={handleClose} />
    }
    if (APIName === IntegrationList.RetailCRM) {
      return <RetailCRMAuthorization isOpen={isModalShow} onClose={handleClose} />
    }
    return null;
  };

  return (
    <div>
      <Button title={translate('INSTALL')} onClick={handleClickInstall} />
      {renderSettingsForm()}
    </div>
  );
};

export default memo(InstallModule);
