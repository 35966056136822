import React, { FunctionComponent } from 'react';
import { UsersIcon, UserIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useOutgoingCallsStyles } from '@/features/OutgoingCalls/OutgoingCalls.styles';
import { ICellProps } from '@components/LazyTable';
import { OutgoingCallData } from '../OutgoingCalls';

export const OutgoingCallTypeCell: FunctionComponent<ICellProps<OutgoingCallData>> = ({ item }) => {
  const { department, employee } = item || {};
  const classes = useOutgoingCallsStyles();

  const computedClasses = clsx(classes.iconCell, {
    [classes.primary]: employee,
    [classes.secondary]: department,
  });

  const renderIcon = () => {
    if (employee) {
      return <UserIcon width={16} height={16} />;
    }
    if (department) {
      return <UsersIcon width={16} height={16} />;
    }
    return null;
  };
  return <div className={computedClasses}>{renderIcon()}</div>;
};
