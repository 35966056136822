import React, { FunctionComponent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { Button } from '@shared/components/Button/Button';
import clsx from 'clsx';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
  XIcon,
} from '@shared/assets/images/icons';
import { UseSortFilterStyles } from './SortFilter.styles';
import { sortDirection } from '../typings/interfaces';

export const SortFilter: FunctionComponent<{
  filterName?: string;
  title: string;
  group?: string[];
  defaultValue?: string;
  isShowRemoveBtn?: boolean;
}> = ({ filterName = 'filterName', title = '', group, defaultValue = '', isShowRemoveBtn }) => {
  const classes = UseSortFilterStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValue = searchParams.get(filterName);
  const activeChevron = { [classes.activeChevron]: !!filterValue };
  const xIconClass = clsx(classes.removeIcon, {
    [classes.filtered]: !!filterValue?.length,
  });

  useEffect(() => {
    if (defaultValue && !filterValue) {
      if (group) {
        const isGroupValueSet = group.reduce((res: boolean, groupFilterName: string) => {
          const groupFilterValue = searchParams.get(groupFilterName);
          if (groupFilterValue) return true;
          return res;
        }, false);
        if (!isGroupValueSet) {
          searchParams.set(filterName, defaultValue);
          setSearchParams(searchParams);
        }
      } else {
        searchParams.set(filterName, defaultValue);
        setSearchParams(searchParams);
      }
    }
  }, [defaultValue, filterName, filterValue, group, searchParams, setSearchParams]);

  function handleFilterChange() {
    if (group) {
      group.forEach((groupFilterName) => {
        if (groupFilterName !== filterName) {
          searchParams.delete(groupFilterName);
        }
      });
    }
    if (filterValue === sortDirection.Asc) {
      searchParams.set(filterName, sortDirection.Desc);
    }
    if (filterValue === sortDirection.Desc) {
      searchParams.set(filterName, sortDirection.Asc);
    }
    if (!filterValue) {
      searchParams.set(filterName, sortDirection.Asc);
    }
    setSearchParams(searchParams);
  }

  function handleRemoveSortFilter() {
    searchParams.delete(filterName);
    setSearchParams(searchParams);
  }

  const renderFilterIcon = () => {
    if (filterValue === sortDirection.Desc) {
      return <ChevronDownIcon className={clsx(classes.chevronIcon, activeChevron)} />;
    }
    if (filterValue === sortDirection.Asc) {
      return <ChevronUpIcon className={clsx(classes.chevronIcon, activeChevron)} />;
    }
    return <ChevronUpDownIcon className={classes.chevronIcon} />;
  };

  return (
    <div className={classes.filteredIconsContainer}>
      {isShowRemoveBtn && filterValue && (
        <XIcon
          className={xIconClass}
          width={16}
          height={16}
          onClick={() => handleRemoveSortFilter()}
        />
      )}
      <Button clear onClick={handleFilterChange}>
        <Flex alignItems={'center'}>
          <Typography type={'text4'} color={filterValue ? 'primary700' : 'tertiary500'}>
            {title}
          </Typography>
          {renderFilterIcon()}
        </Flex>
      </Button>
    </div>
  );
};

export default SortFilter;
