import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import QuickSearch from '@components/QuickSearch';
import { Button } from '@shared/components/Button/Button';
import { ExcelExportIcon } from '@shared/assets/images/icons';
import { getStorageItem } from '@components/storage/storage';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CALL_DETAILS_QUERY, USER_QUERY } from '@/client/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumbs from '@/components/Breadcrumbs';
import PeriodSelect from '@/components/PeriodSelect/PeriodSelect';
import { PeriodValues, useDateOptions } from '@/utils/useDateOptions';
import { startOfDay } from 'date-fns';
import Tag from '@components/Tag/Tag';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import LazyTable, { IColumn } from '@components/LazyTable';
import {
  CallDetailsClientCell,
  CallDetailsCostCell,
  CallDetailsDateCell,
  CallDetailsDirectionIconCell,
  CallDetailsDirectionNameCell,
  CallDetailsDurationCell,
  CallDetailsPBSCell,
} from '@/features/FinancialCabinet/CallDetails/modules';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import Typography from '@shared/components/Typography';
import { findWeekDay, formatDate } from '@components/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { ICallDetailsList, ICallDetailsState } from './CallDetails.interfaces';
import { useCallDetailsStyle } from './CallDetails.styles';
import { CALL_DETAILS_DEFAULT_ROW_HEIGHT, CALL_DETAILS_ROW_HEIGHT } from './CallDetails.constants';
import { goToDocumentsIfCurrentDomainStatusIsDemo } from '../utils/goToDocumentsIfCurrentDomainStatusIsDemo';

const columns: IColumn<ICallDetailsList>[] = [
  {
    id: 'callDirection',
    width: '1em',
    minWidth: '1em',
    label: '',
    Renderer: CallDetailsDirectionIconCell,
  },
  { id: 'client', label: 'CLIENT_NUMBER', Renderer: CallDetailsClientCell },
  { id: 'direction', label: 'DIRECTION', Renderer: CallDetailsDirectionNameCell },
  { id: 'local', label: 'PBX_NUMBER', Renderer: CallDetailsPBSCell },
  { id: 'date', label: 'DATE', Renderer: CallDetailsDateCell },
  { id: 'duration', label: 'DURATION', Renderer: CallDetailsDurationCell },
  { id: 'cost', label: 'COST', Renderer: CallDetailsCostCell },
];

export const CallDetails: FunctionComponent = () => {
  const classes = useCallDetailsStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  useEffect(() => {
    goToDocumentsIfCurrentDomainStatusIsDemo(navigate, userData);
  }, [navigate, userData]);

  const [searchParams, setSearchParams] = useSearchParams();
  const sSearch = searchParams.get('search');
  const sFrom = searchParams.get('from');
  const sTo = searchParams.get('to');
  const sPeriod = searchParams.get('period');
  const sGroup = searchParams.get('group');
  const dateLine = `${sFrom}${sTo}${sSearch}${sPeriod}${sGroup}`;

  const formMethods = useForm({
    defaultValues: {
      search: sSearch,
    },
  });

  const periodData = useDateOptions(null);
  const currentDay = periodData[PeriodValues.Today].to; // new Date();
  const startDay = periodData[PeriodValues.Today].from; // startOfDay(currentDay)

  const periodRangeDates = {
    from: sFrom ? new Date(sFrom) : startDay,
    to: sTo ? new Date(sTo) : currentDay,
  };

  const [state, setState] = useState<ICallDetailsState>({
    queryData: {
      from: sFrom || startDay.toISOString(),
      to: sTo || currentDay.toISOString(),
      search: '',
      offset: null,
      limit: 20,
    },
    tableRows: [],
    dateLine,
  });

  const {
    data,
    refetch,
    loading: refetchLoading,
  } = useQuery(CALL_DETAILS_QUERY, {
    variables: { ...state.queryData },
    fetchPolicy: 'cache-first',
  });

  const callDetails = data?.getReportTelephony;

  // TODO handle lazy query error
  const [
    getCallDetailsRows,
    { data: { getReportTelephony: { rows = null } = {} } = {}, loading: loadMoreRowsLoading },
  ] = useLazyQuery(CALL_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (state.dateLine !== dateLine) {
      const debounceData = setTimeout(() => {
        const endCurrentDay = new Date();
        const startCurrentDay = startOfDay(endCurrentDay);
        setState((prevState) => ({
          ...prevState,
          dateLine,
          queryData: {
            ...prevState.queryData,
            search: sSearch || '',
            from: sFrom || startCurrentDay.toISOString(),
            to: sTo || endCurrentDay.toISOString(),
          },
        }));
      }, 500);

      return () => {
        clearTimeout(debounceData);
      };
    }
    return undefined;
  }, [sSearch, sFrom, sTo, state.dateLine, dateLine]);

  useEffect(() => {
    refetch(state.queryData).then((response) => {
      setState((prevState) => ({
        ...prevState,
        tableRows: response.data?.getReportTelephony.rows || [],
      }));
    });
  }, [state.queryData, refetch]);

  useEffect(() => {
    if (callDetails) {
      setState((prevState) => ({
        ...prevState,
        tableRows: callDetails.rows,
      }));
    }
  }, [callDetails]);

  useEffect(() => {
    if (rows) {
      setState((prevState) => ({
        ...prevState,
        tableRows: [...prevState.tableRows, ...rows],
      }));
    }
  }, [rows]);

  function hasNextPage() {
    if (callDetails && callDetails.total > 0) {
      return state.tableRows.length < callDetails.total;
    }
    return false;
  }

  const getMoreCallDetailsItems = useCallback(() => {
    getCallDetailsRows({
      variables: {
        ...state.queryData,
        offset: state.tableRows.length,
      },
    });
    return null;
  }, [getCallDetailsRows, state.queryData, state.tableRows]);

  const isDayChanged = (index: number) => {
    const { tableRows } = state;
    if (tableRows[index] && tableRows[index - 1]) {
      const cDay = new Date(tableRows[index].date).getDay();
      const pDay = new Date(tableRows[index - 1].date).getDay();
      if (cDay !== pDay) {
        return CALL_DETAILS_ROW_HEIGHT;
      }
      return CALL_DETAILS_DEFAULT_ROW_HEIGHT;
    }
    return CALL_DETAILS_ROW_HEIGHT;
  };

  function handlePeriodChange(date: { from: Date; to: Date; period: string }) {
    searchParams.set('from', date.from.toISOString());
    searchParams.set('to', date.to.toISOString());
    searchParams.set('period', date.period);
    setSearchParams(searchParams);
  }

  function handleCloseTag() {
    searchParams.delete('search');
    searchParams.delete('group');
    setSearchParams(searchParams);
  }

  function handleReportClick() {
    const token = getStorageItem('token');
    const { from, to, search } = state.queryData;
    const url = process.env.API_URL;
    if (token && url) {
      window.open(
        `${url}/billing/telephonyReport?&from=${from}&to=${to}&search=${search}&token=${token}&limit=100000`
      );
    }
  }

  const renderSearchLine = () => {
    if (sGroup && sSearch) {
      return (
        <Tag
          text={sGroup === 'phone' ? formatPhone(sSearch) : sSearch}
          onClose={() => handleCloseTag()}
        />
      );
    }
    return (
      <FormProvider {...formMethods}>
        <QuickSearch parameterName={'search'} />
      </FormProvider>
    );
  };

  const renderBeforeRowContent = (itemIndex: number) => {
    const { tableRows } = state;
    const tableRow = tableRows[itemIndex];
    const cDay = new Date(tableRow.date).getDay();
    const week = findWeekDay(cDay);
    const { day, month } = formatDate(new Date(tableRow.date));
    const dateStr = tableRow ? `${day} ${translate(month)}` : '';
    if (isDayChanged(itemIndex) === CALL_DETAILS_ROW_HEIGHT) {
      return (
        <Flex alignItems={'center'} className={classes.dateLine}>
          <div className={classes.cellDirection}>
            <Typography color={'tertiary400'} type={'text4'}>
              {translate(week)}
            </Typography>
          </div>
          <div className={classes.cellNumber}>
            <Typography color={'tertiary400'} type={'text4'}>
              {dateStr}
            </Typography>
          </div>
        </Flex>
      );
    }
    return null;
  };

  return (
    <BodyContainer disableOverflow>
      <Flex direction={'column'} className={classes.root}>
        <Breadcrumbs>
          <div className={classes.lineItem}>{renderSearchLine()}</div>
          <div className={classes.lineItem}>
            <PeriodSelect<PeriodValues>
              periodName={sPeriod || 'today'}
              periodList={periodData}
              datePeriod={periodRangeDates}
              onPeriodChange={handlePeriodChange}
            />
          </div>
          <div className={classes.lineItem}>
            <Button onClick={handleReportClick} clear rounded>
              <ExcelExportIcon className={classes.xls} />
            </Button>
          </div>
        </Breadcrumbs>
        <LazyTable<ICallDetailsList>
          columns={columns}
          loadMoreRows={getMoreCallDetailsItems}
          rowLoading={loadMoreRowsLoading}
          tableLoading={refetchLoading}
          data={state.tableRows}
          hasNextPage={hasNextPage}
          rowHeight={CALL_DETAILS_DEFAULT_ROW_HEIGHT}
          emptyDataMessage={translate('NORESULT')}
          beforeRowRenderer={renderBeforeRowContent}
          customRowHeight={isDayChanged}
        />
      </Flex>
    </BodyContainer>
  );
};
