import { useNotificationsStyles } from '@/features/Settings/SettingsNotifications/SettingsNotifications.styles';
import Breadcrumb from '@components/Breadcrumb';
import QuickSearch from '@components/QuickSearch';
import Button from '@shared/components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NotificationsBreadcrumbs = () => {
  const classes = useNotificationsStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const handleClickAddButton = () => {
    navigate('/settings/notifications/add');
  };

  return (
    <div className={classes.actions}>
      <div className={classes.breadcrumbs}>
        <Breadcrumb path={'/settings/notifications'} titleCode={'MISSED_CALLS'} />
      </div>
      <div className={classes.actionButtons}>
        <QuickSearch parameterName={'query'} />
        <Button
          title={translate('ADD_NOTIFICATION')}
          className={classes.addButton}
          onClick={() => handleClickAddButton()}
        />
      </div>
    </div>
  );
};
