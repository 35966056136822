import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UPDATE_DOMAIN_MUTATION, UPLOAD_FILE_MUTATION } from '@/client/mutations';
import Typography from '@shared/components/Typography';
import { DOWNLOAD_URL_QUERY, USER_QUERY, GET_SYSTEM_HOLD_QUERY } from '@/client/queries';
import { useNavigate } from 'react-router-dom';
import Preloader from '@shared/components/Preloader';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { setUploadFieldProgress } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import UploadField, { uploadFieldOnChangeData } from '@components/UploadField';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { TuneType } from '../SettingsPage.interfaces';
import { useSettingsPagesStyles } from '../SettingsPage.styles';
import { SettingsPageTemplate } from '../SettingsPageTemplate';

export const SettingsTune = () => {
  const [translate] = useTranslation();
  const formMethods = useForm();
  const classes = useSettingsPagesStyles();
  const navigate = useNavigate();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const [updateDomain, { loading: isUpdateDomainMutationLoading }] = useMutation(
    UPDATE_DOMAIN_MUTATION,
    {
      refetchQueries: [{ query: USER_QUERY }],
    }
  );
  const { data } = useQuery(GET_SYSTEM_HOLD_QUERY);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const domain = userData && getCurrentDomain(userData.user);
  const { handleSubmit, watch, reset, setValue, setError } = formMethods;
  const userHash = domain?.settings?.holdMusic?.hash;
  const urlPath = data?.getSystemHoldURL;
  // TODO handle lazy query error
  const [getFileUrl, { data: { downloadUrl = undefined } = {}, loading: loadingAudioUrl }] =
    useLazyQuery(DOWNLOAD_URL_QUERY);
  const [uploadFile, { loading: uploadingFile }] = useMutation(UPLOAD_FILE_MUTATION);
  const tuneType = watch('tuneType');
  const fileUrl = watch('fileUrl');
  const fileHash = watch('fileHash');
  const fileName = watch('fileName');
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [state, setState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  const loading = isUpdateDomainMutationLoading || isAudioLoading;

  useEffect(() => {
    if (domain) {
      reset({
        tuneType: domain?.settings?.holdMusic?.type,
        fileUrl: downloadUrl || '',
        fileHash: domain?.settings?.holdMusic?.hash,
        fileName: domain?.settings?.holdMusic?.name,
      });
    }
  }, [domain, reset, downloadUrl, urlPath]);

  useEffect(() => {
    const storeType = domain?.settings?.holdMusic?.type;
    const storeHash = domain?.settings?.holdMusic?.hash;
    setState({
      isBlocking: tuneType !== storeType ? true : fileHash !== storeHash,
      action: ConfirmAction.Edit,
    });
  }, [tuneType, fileHash, domain?.settings?.holdMusic?.type, domain?.settings?.holdMusic?.hash]);

  useEffect(() => {
    if (userHash) {
      getFileUrl({ variables: { fileHash: userHash } });
    }
  }, [getFileUrl, userHash]);

  useEffect(() => {
    if (state.action === ConfirmAction.Cancel && !state.isBlocking) {
      navigate('/settings');
    }
  }, [state, navigate]);

  function handleTuneFormSubmit() {
    if (loading || uploadingFile) return;
    if (tuneType === TuneType.Custom && (!fileUrl || !fileUrl.length)) {
      setError('fileHash', {
        message: translate('AUDIO_FILE_IS_NOT_CHOSEN'),
        type: 'error',
      });
    } else {
      setState({
        isBlocking: false,
        action: ConfirmAction.Finish,
      });
      let submitData;
      if (tuneType === TuneType.Default) {
        submitData = {
          holdMusic: {
            type: tuneType,
          },
        };
      } else {
        submitData = {
          holdMusic: {
            type: tuneType,
            hash: fileHash,
            name: fileName,
          },
        };
      }

      updateDomain({
        variables: {
          data: {
            settings: { ...submitData },
          },
        },
      }).then(() => {
        navigate(blockedPath || '/settings');
      });
    }
  }

  function handleAudioUpload({ urlName, hashName, progressName, file }: uploadFieldOnChangeData) {
    if (!file) {
      return;
    }

    setIsAudioLoading(true);
    uploadFile({
      variables: { file },
      context: {
        fetchOptions: {
          onProgress: ({ loaded, total }: ProgressEvent) =>
            setUploadFieldProgress(loaded, total, setValue, progressName),
        },
      },
    })
      .then((res) => {
        setValue(urlName, res?.data?.uploadFile?.url);
        setValue(hashName, res?.data?.uploadFile?.filename);
      })
      .catch((error) => {
        reset({
          tuneType,
          fileUrl: '',
          fileHash: '',
          fileName: '',
        });
        let message = translate('UPLOAD_ERROR');
        const messageFromError = getErrorMessageFromGraphqlError(error);
        if (messageFromError === 'content type error') {
          message = translate('UPLOAD_AUDIO_ERROR');
        }
        setError(hashName, {
          type: 'error',
          message,
        });
      })
      .finally(() => {
        setIsAudioLoading(false);
      });
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleDeleteFile() {
    setValue('fileUrl', '');
  }

  function handleCancelChanges() {
    setState({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  }

  const renderAudioPlayer = () => {
    const audioUrl = tuneType === TuneType.Custom ? fileUrl : urlPath;
    if (loadingAudioUrl) {
      return (
        <div className={classes.preloader}>
          <Preloader />
        </div>
      );
    }
    if (tuneType === TuneType.Custom && (fileUrl === '' || fileUrl === undefined)) {
      return null;
    }
    return (
      <div className={classes.player}>
        <AudioPlayer side={'left'} collapseOnEnd={false} isExpanded source={audioUrl} />
      </div>
    );
  };

  if (!domain) {
    return null;
  }

  return (
    <SettingsPageTemplate
      form={'settings-tune'}
      title={'MUSIC_ON_HOLD'}
      onCancel={handleCancelChanges}
      isLoading={loading}
    >
      <div className={classes.description}>
        <Typography color={'tertiary900'} type={'text3'}>
          {translate('SETTING_TUNE_DESCRIPTION')}
        </Typography>
      </div>
      <FormProvider {...formMethods}>
        <form
          id={'settings-tune'}
          className={classes.form}
          onSubmit={handleSubmit(handleTuneFormSubmit)}
        >
          <RadioGroup defaultValue={TuneType.Default} name={'tuneType'}>
            <div className={classes.radioGroup}>
              <FormControlLabel
                value={TuneType.Default}
                control={<Radio classes={{ root: classes.radio }} color={'primary'} />}
                label={translate('STANDARD_MUSIC')}
              />
              <FormControlLabel
                value={TuneType.Custom}
                control={<Radio classes={{ root: classes.radio }} color={'primary'} />}
                label={translate('COMPUTER_FILE')}
              />
            </div>
            <div className={classes.playerContainer}>
              {renderAudioPlayer()}
              {tuneType === TuneType.Custom && (
                <UploadField
                  accept={'audio/*'}
                  onChange={handleAudioUpload}
                  onCancel={handleDeleteFile}
                  label={translate('CHOOSE_AUDIO_FILE')}
                  requiredText={translate('AUDIO_FILE_IS_NOT_CHOSEN')}
                />
              )}
            </div>
          </RadioGroup>
        </form>
        <ConfirmDialog
          isBlocked={state.isBlocking}
          onNavigationBlocked={getBlockedPath}
          onSaveChanges={handleSubmit(handleTuneFormSubmit)}
        />
      </FormProvider>
    </SettingsPageTemplate>
  );
};
