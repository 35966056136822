import React, { FunctionComponent, memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import { usePasswordFieldStyles } from '../PasswordField.styles';

export const PasswordGenerateControls: FunctionComponent<{
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}> = ({ onClick, disabled }) => {
  const [translate] = useTranslation();
  const classes = usePasswordFieldStyles();

  return (
    <Button disabled={disabled} clear onClick={onClick}>
      <Typography
        color={disabled ? 'tertiary300' : 'link500'}
        type={'text4'}
        className={classes.generationSpan}
      >
        {translate('GENERATE_PASSWORD')}
      </Typography>
    </Button>
  );
};

export default memo(PasswordGenerateControls);
