import { useLazyQuery } from '@apollo/client';
import { GET_AMO_PIPELINES_QUERY } from '@/client/queries';
import { useEffect, useMemo } from 'react';
import { ISettingInstalledProps } from '@/features/Integrations/IntegrationForm/IntegrationForm.interfaces';
import { IntegrationList } from '@components/typings/interfaces/integrations';

export function useScenarioSettingsLineProps(
  APIName: IntegrationList,
  isScenarioSettingInstalled: ISettingInstalledProps | null,
  optionIndex: number,
  integrationId?: number
) {
  // TODO handle lazy query error
  const [getAmoPipelines, { data: amoPipelines }] = useLazyQuery(GET_AMO_PIPELINES_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      id: integrationId,
    },
  });
  const pipelineList = amoPipelines?.getAmoPipelines || [];

  useEffect(() => {
    if (!amoPipelines && APIName === IntegrationList.AmoCrm && integrationId) {
      getAmoPipelines();
    }
  }, [APIName, amoPipelines, getAmoPipelines, integrationId]);

  const selectedSettingType = useMemo(() => {
    if (isScenarioSettingInstalled && isScenarioSettingInstalled[optionIndex]) {
      return isScenarioSettingInstalled[optionIndex].type;
    }
    return null;
  }, [isScenarioSettingInstalled, optionIndex]);

  return {
    pipelineList,
    selectedSettingType,
  };
}
