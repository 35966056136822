import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import SelectField from '@shared/components/SelectField';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  CLIENT_QUERY,
  GET_SUGGEST_BANK_QUERY,
  GET_SUGGEST_COMPANY_QUERY,
  GET_SUGGEST_FMS_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { UPDATE_CLIENT_PASSPORT_MUTATION, UPLOAD_DOCUMENT_MUTATION } from '@/client/mutations';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import CheckboxField from '@shared/components/CheckboxField';
import ComboBoxField, { ComboBoxFieldReasonType } from '@shared/components/ComboBoxField';
import NotificationMessage from '@components/NotificationMessage';
import { debounce, FormControlLabel } from '@material-ui/core';
import MaskedField from '@shared/components/MaskedField';
import EmailField from '@shared/components/EmailField';
import PhoneField from '@shared/components/PhoneField';
import DateField from '@shared/components/DateField';
import Button from '@shared/components/Button/Button';
import UploadField, { uploadFieldOnChangeData } from '@components/UploadField';
import MessageDialog from '@shared/components/MessageDialog';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import Radio from '@shared/components/Radio';
import RadioGroup from '@shared/components/RadioGroup';
import {
  ImagesMIMEType,
  personalDataFieldList,
  setSuggestBankList,
  setSuggestCompanyList,
  setSuggestFMSList,
  setUploadFieldProgress,
  uploadFieldsList,
} from '@components/utils';
import {
  formDataArray,
  IUpdateClientPassport,
  suggestBankItemType,
  suggestCompanyItemType,
  suggestFMSItemType,
  genderOptions,
  payerTypeOptionsList,
  DomainStatus,
} from '@components/typings/interfaces';
import clsx from 'clsx';
import HighlightedText from '@components/HighlightedText';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { ContractLayout } from '@/features/Documents';
import { isValid, isDate, addDays, startOfDay } from 'date-fns';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { ClientType, UpdateClientPassport } from '@/client/generated/graphql';
import { formType } from '../Contract.constants';
import { useContractStyle } from '../Contract.styles';

export const ContractPersonalData = () => {
  const classes = useContractStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [showErrorDialog, setShowErrorDialog] = useState({
    showDialog: false,
    title: '',
    message: '',
  });

  const rootRef = useRef<HTMLDivElement | null>(null);

  const formMethods = useForm();
  const { watch, setValue, setError, handleSubmit, clearErrors, unregister } = formMethods;
  const legalType = watch('type');
  const IsPostalAddressDuplicate = watch('IsPostalAddressDuplicate');
  const legalAddress = watch('legalAddress');
  const personalDataAgreement = watch('personalDataAgreement');

  const {
    data: {
      getClient: {
        individualPassport = null,
        legalPassport = null,
        type = null,
        params = null,
        domains = null,
      } = {},
    } = {},
    loading: loadingClientData,
  } = useQuery(CLIENT_QUERY);

  const {
    data: { suggestCompany = [] } = {},
    refetch: fetchSuggestCompany,
    loading: loadingSuggestCompany,
  } = useQuery(GET_SUGGEST_COMPANY_QUERY, {
    variables: { searchText: '', type: legalType === ClientType.Ul ? 'LEGAL' : 'INDIVIDUAL' },
    fetchPolicy: 'cache-first',
  });

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const clientData = userData?.user;

  const {
    data: { suggestBank = [] } = {},
    refetch: fetchSuggestBank,
    loading: loadingSuggestBank,
  } = useQuery(GET_SUGGEST_BANK_QUERY, {
    variables: { searchText: '' },
    fetchPolicy: 'cache-first',
  });

  const {
    data: { suggestFMS = [] } = {},
    refetch: fetchSuggestFMS,
    loading: loadingSuggestFMS,
  } = useQuery(GET_SUGGEST_FMS_QUERY, {
    variables: { searchText: '' },
    fetchPolicy: 'cache-first',
  });

  const [uploadFile] = useMutation(UPLOAD_DOCUMENT_MUTATION);
  const [updateClientPassport, { loading }] = useMutation(UPDATE_CLIENT_PASSPORT_MUTATION);

  const legalTypeDefaultValue =
    individualPassport === null && legalPassport === null ? undefined : type;
  const companyList = setSuggestCompanyList(suggestCompany);
  const bankList = setSuggestBankList(suggestBank);
  const fmsList = setSuggestFMSList(suggestFMS);

  const isSiblingDomainCommercial =
    !!domains &&
    domains.length > 1 &&
    domains.some(({ status }) => status === DomainStatus.Commercial);

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Finish)
      navigate(blockedPath || '/register/select-tariff');
  }, [isChanged, navigate, blockedPath]);

  useEffect(() => {
    if (individualPassport || legalPassport) {
      setValue('type', type);
      if (type === ClientType.Fl && individualPassport) {
        setTimeout(() => {
          if (individualPassport.postalAddress !== individualPassport.legalAddress)
            setValue('IsPostalAddressDuplicate', false);
          setValue('personName', individualPassport.name);
          setValue('gender', individualPassport.gender);
          setValue('inn', individualPassport.inn);
          setValue('series', individualPassport.series);
          setValue('number', individualPassport.number);
          setValue('departmentCode', individualPassport.departmentCode);
          setValue('placeOfIssue', individualPassport.placeOfIssue);
          setValue('dateOfIssue', new Date(individualPassport.dateOfIssue));
          setValue('legalAddress', individualPassport.legalAddress);
          setValue('postalAddress', individualPassport.postalAddress);
          setValue('phone', params?.contacts?.phones?.[0]);
          setValue('email', params?.contacts?.emails?.[0]);
          setValue('passportFrontFileHash', individualPassport?.scans?.[0] || '');
          setValue('passportFrontFileName', translate('DOCUMENT_LOADED'));
          setValue('passportAddressFileHash', individualPassport?.scans?.[1] || '');
          setValue('passportAddressFileName', translate('DOCUMENT_LOADED'));
          setValue('personalDataAgreement', true);
        });
      }
      if ((type === ClientType.Ul || type === ClientType.Ip) && legalPassport) {
        setTimeout(() => {
          if (type === ClientType.Ul) {
            setValue('position', legalPassport.position);
            setValue('genitiveCasePosition', legalPassport.genitiveCasePosition);
            setValue('actBasis', legalPassport.actBasis);
          }
          if (legalPassport.postalAddress !== legalPassport.legalAddress)
            setValue('IsPostalAddressDuplicate', false);
          setValue('companyName', legalPassport.name);
          setValue('inn', legalPassport.inn);
          setValue('kpp', legalPassport.kpp);
          setValue('ogrn', legalPassport.ogrn);
          setValue('legalAddress', legalPassport.legalAddress);
          setValue('postalAddress', legalPassport.postalAddress);
          setValue('bankName', legalPassport.bankName);
          setValue('bik', legalPassport.bik);
          setValue('correspondentAccount', legalPassport.correspondentAccount);
          setValue('account', legalPassport.account);
          setValue('bankAddress', legalPassport.bankAddress);
          setValue('personName', legalPassport.person);
          setValue('genitiveCasePerson', legalPassport.genitiveCasePerson);
          setValue('phone', params?.contacts?.phones?.[0] || '');
          setValue('email', params?.contacts?.emails?.[0] || '');
          setValue('personalDataAgreement', true);
        });
      }
    }
  }, [legalTypeDefaultValue, individualPassport, legalPassport, type, params, setValue, translate]);

  useEffect(() => {
    // legalType необходим в зависимостях, чтобы проставлялись контактные данные //
    setTimeout(() => {
      if (!individualPassport && !legalPassport) {
        setValue('phone', params?.contacts?.phones?.[0] || '');
        setValue('email', params?.contacts?.emails?.[0] || '');
      }
    });
  }, [clientData, individualPassport, setValue, legalType, legalPassport, params]);

  useEffect(() => {
    if (IsPostalAddressDuplicate) {
      setValue('postalAddress', legalAddress);
      clearErrors('postalAddress');
    }
  }, [IsPostalAddressDuplicate, legalAddress, setValue, clearErrors]);

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  const handleTypeChange = useCallback(() => {
    clearErrors();
    if (legalType !== ClientType.Fl) {
      unregister('passportFrontFileHash');
      unregister('passportAddressFileHash');
    }
    personalDataFieldList.forEach((item) => {
      const itemValue = () => {
        if (item === 'personalDataAgreement' || item === 'IsPostalAddressDuplicate') return true;
        if (item === 'gender') return 'male';
        return '';
      };
      setValue(item, itemValue());
    });
  }, [legalType, setValue, clearErrors, unregister]);

  useEffect(() => {
    handleTypeChange();
  }, [legalType, setValue, clearErrors, unregister, handleTypeChange]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggestCompany = useCallback(
    debounce((value: string, legalTypeStr: string) => {
      fetchSuggestCompany({
        searchText: value,
        type: legalTypeStr === ClientType.Ul ? 'LEGAL' : 'INDIVIDUAL',
      });
    }, 500),
    [fetchSuggestCompany]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggestBank = useCallback(
    debounce((value: string) => {
      fetchSuggestBank({ searchText: value });
    }, 500),
    [fetchSuggestBank]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSuggestFms = useCallback(
    debounce((value: string) => {
      fetchSuggestFMS({ searchText: value });
    }, 500),
    [fetchSuggestFMS]
  );

  function handleSuggestCompanyChange(event: ChangeEvent<unknown>, value: string) {
    if (value === '') {
      setValue('inn', '');
      setValue('ogrn', '');
      setValue('legalAddress', '');
      setValue('personName', '');
      setValue('postalAddress', '');
      if (legalType === ClientType.Ul) {
        setValue('kpp', '');
        setValue('position', '');
        setValue('genitiveCasePosition', '');
      }
    }
    if (event) {
      debounceSuggestCompany(value, legalType);
    }
  }

  function handleSuggestBankChange(event: ChangeEvent<unknown>, value: string) {
    if (value === '') {
      setValue('bik', '');
      setValue('correspondentAccount', '');
      setValue('bankAddress', '');
    }
    if (event) {
      debounceSuggestBank(value);
    }
  }

  function handleSuggestFMSChange(event: ChangeEvent<unknown>, value: string) {
    if (value === '') {
      setValue('placeOfIssue', '');
    }
    if (event) {
      debounceSuggestFms(value);
    }
    handleFormChange();
  }

  function handleSuggestCompanySelect(
    event: ChangeEvent<unknown>,
    item: suggestCompanyItemType,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option' && item) {
      setValue('inn', item.data.inn);
      setValue('ogrn', item.data.ogrn);
      setValue('legalAddress', item.data.address);
      setValue('personName', item.data.person);
      if (legalType === ClientType.Ul) {
        setValue('position', item.data?.position?.toLowerCase() || '');
        setValue('kpp', item.data.kpp);
        clearErrors('kpp');
      }
      if (IsPostalAddressDuplicate) {
        setValue('postalAddress', item.data.address);
        clearErrors('postalAddress');
      }
      clearErrors(['inn', 'ogrn', 'legalAddress', 'personName', 'position']);
    }
    handleFormChange();
  }

  function handleSuggestBankSelect(
    event: ChangeEvent<unknown>,
    item: suggestBankItemType,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option' && item) {
      setValue('bik', item.data.bik);
      setValue('correspondentAccount', item.data.correspondentAccount);
      setValue('bankAddress', item.data.address);
      clearErrors(['bik', 'correspondentAccount', 'bankAddress']);
    }
    handleFormChange();
  }

  function handleSuggestFMSSelect(
    event: ChangeEvent<unknown>,
    item: suggestFMSItemType,
    reason: ComboBoxFieldReasonType
  ) {
    if (reason === 'select-option' && item) {
      setValue('placeOfIssue', item.data.name);
      clearErrors('placeOfIssue');
    }
    handleFormChange();
  }

  function handlePersonalDataSubmit(formData: Partial<IUpdateClientPassport>) {
    const scans = [formData.passportFrontFileHash || '', formData.passportAddressFileHash || ''];
    const formObject = formData as unknown as formDataArray;
    uploadFieldsList.forEach((name: string) => {
      delete formObject[name];
    });
    if (legalType === ClientType.Fl) {
      updateClientPassport({
        variables: {
          data: {
            ...(formObject as UpdateClientPassport),
            scans,
          },
        },
      })
        .then(() => {
          setIsChanged({
            isBlocking: false,
            action: ConfirmAction.Finish,
          });
        })
        .catch((error) => {
          let titleText = 'SOMETHING_WENT_WRONG';
          let messageText = 'SOMETHING_WRONG_MESSAGE';
          const messageFromError = getErrorMessageFromGraphqlError(error);
          if (
            messageFromError === 'SERIES_NUMBER_IS_NOT_UNIQUE' ||
            messageFromError === 'INN_IS_NOT_UNIQUE'
          ) {
            titleText = 'NEW_ATS';
            messageText = 'NEW_ATS_MESSAGE';
          }
          setShowErrorDialog({
            title: titleText,
            message: messageText,
            showDialog: true,
          });
        });
    }
    if (legalType === ClientType.Ul || legalType === ClientType.Ip) {
      updateClientPassport({
        variables: {
          data: formObject as UpdateClientPassport,
        },
      })
        .then(() => {
          setIsChanged({
            isBlocking: false,
            action: ConfirmAction.Finish,
          });
        })
        .catch((error) => {
          let titleText = 'SOMETHING_WENT_WRONG';
          let messageText = 'SOMETHING_WRONG_MESSAGE';
          const messageFromError = getErrorMessageFromGraphqlError(error);
          if (messageFromError === 'INN_IS_NOT_UNIQUE') {
            titleText = 'NEW_ATS';
            messageText = 'NEW_ATS_MESSAGE';
          }
          setShowErrorDialog({
            title: titleText,
            message: messageText,
            showDialog: true,
          });
        });
    }
  }

  function handleErrorDialogClose() {
    setShowErrorDialog({
      title: '',
      message: '',
      showDialog: false,
    });
  }

  function handleDocumentUpload({ hashName, progressName, file }: uploadFieldOnChangeData) {
    if (file) {
      const comment = translate(
        progressName === 'passportDataPage' ? 'PASSPORT_DATA_PAGE' : 'PASSPORT_ADDRESS_PAGE'
      );
      uploadFile({
        variables: {
          data: {
            type: 'PASSPORT',
            comment,
          },
          file,
        },
        context: {
          fetchOptions: {
            onProgress: ({ loaded, total }: ProgressEvent) =>
              setUploadFieldProgress(loaded, total, setValue, progressName),
          },
        },
      })
        .then((res) => {
          setValue(hashName, res?.data?.uploadDocument?.filename);
        })
        .catch(() => {
          setError(hashName, {
            type: 'error',
            message: translate('UPLOAD_ERROR'),
          });
        });
      handleFormChange();
    }
  }

  const renderCompanyOptions = (
    companyOption: suggestCompanyItemType,
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <div className={classes.customOptionRender}>
      <HighlightedText type={'text'} text={companyOption.data.name} query={inputValue} />
      <div className={clsx(classes.defaultElementWidth19, classes.textOverflow)}>
        <HighlightedText
          type={'text'}
          text={companyOption.data.inn}
          query={inputValue}
          typographyType={'text4'}
          typographyColor={'tertiary600'}
        />
        {companyOption.data.shortAddress && (
          <Typography type={'text4'} color={'tertiary600'}>
            {`, ${companyOption.data.shortAddress}`}
          </Typography>
        )}
      </div>
    </div>
  );

  const renderBankOptions = (
    bankOption: suggestBankItemType,
    { inputValue }: AutocompleteRenderOptionState
  ) => (
    <div className={classes.customOptionRender}>
      <HighlightedText type={'text'} text={bankOption.data.name} query={inputValue} />
      <div className={clsx(classes.defaultElementWidth19, classes.textOverflow)}>
        <HighlightedText
          type={'text'}
          text={bankOption.data.bik}
          query={inputValue}
          typographyType={'text4'}
          typographyColor={'tertiary600'}
        />
        <Typography type={'text4'} color={'tertiary600'}>
          {`, ${bankOption.data.address}`}
        </Typography>
      </div>
    </div>
  );

  const renderLegalType = () => {
    // Поля для Физического Лица
    if (legalType === ClientType.Fl) {
      return (
        <>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'personName'}
              label={translate('FULL_NAME')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_FULLNAME') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <RadioGroup name={'gender'}>
              <div className={classes.personalDataRadioContainer}>
                <div className={classes.personalDataGenderContainer}>
                  <Typography type={'text4'} color={'tertiary700'}>
                    {translate('GENDER')}
                  </Typography>
                  <div className={classes.personalDataGenderControls}>
                    {genderOptions.map(({ titleCode, value }) => (
                      <FormControlLabel
                        key={`gender-${titleCode}`}
                        value={value}
                        control={<Radio color={'secondary'} />}
                        label={translate(titleCode)}
                        onChange={handleFormChange}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </RadioGroup>
          </div>
          <div className={classes.personalDataFormItem}>
            <MaskedField
              mask={'999999999999'}
              name={'inn'}
              label={translate('INN')}
              validate={(value: string) => {
                if (value.length !== 12) {
                  return translate('PERSONAL_ERROR_MESSAGE', {
                    amount: 12,
                    type: translate('INN'),
                  }) as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItems}>
            <MaskedField
              mask={'9999'}
              name={'series'}
              label={translate('SERIES')}
              className={classes.defaultElementWidth6}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_SERIES') as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
            <MaskedField
              mask={'999999'}
              name={'number'}
              label={translate('PAS_NUMBER')}
              className={classes.passportNumber}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_NUMBER') as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <ComboBoxField
              name={'departmentCode'}
              loading={loadingSuggestFMS}
              freeSolo
              valueKey={'value'}
              titleKey={'departmentCode'}
              onChange={handleSuggestFMSSelect}
              onInputChange={(event, value, reason) => {
                if (reason === 'reset') return;
                handleSuggestFMSChange(event, value);
              }}
              data={fmsList}
              placeholder={translate('CHOOSE')}
              label={translate('CODE')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_CODE') as string;
                }
                return true;
              }}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'placeOfIssue'}
              multiline
              rows={3}
              label={translate('WHO_GIVEN')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_WHOGIVEN') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <DateField
              name={'dateOfIssue'}
              label={translate('GIVEN_DATE')}
              disableFuture
              onChange={handleFormChange}
              validate={(selectedDate) => {
                if (selectedDate === null) {
                  return translate('EMPTY_FIELD_DATE');
                }
                if (!isDate(selectedDate) || !isValid(selectedDate)) {
                  return translate('INVALID_DATE');
                }

                const tomorrowMidnight = startOfDay(addDays(new Date(), 1));
                if (selectedDate >= tomorrowMidnight) {
                  return translate('INVALID_DATE_OF_ISSUE');
                }
                return true;
              }}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'legalAddress'}
              multiline
              rows={3}
              label={translate('REGISTRATION_ADDRESS')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_ADDRESS') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'postalAddress'}
              multiline
              rows={3}
              InputProps={{ readOnly: IsPostalAddressDuplicate }}
              label={translate('LIVING_ADDRESS')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_ADDRESS') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormCheckbox}>
            <CheckboxField
              name={'IsPostalAddressDuplicate'}
              label={translate('POSTAL_ADDRESS_MATCH')}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('MENU_CONTACTS')}
            </Typography>
          </div>
          <div className={classes.personalDataFormItem}>
            <PhoneField
              name={'phone'}
              label={translate('PHONE')}
              required
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <EmailField
              name={'email'}
              label={translate('EMAIL')}
              required
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DOCUMENTS')}
            </Typography>
          </div>
          <div className={classes.personalDataFileItem}>
            <div className={classes.personalDataFormItem}>
              <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
                {`${translate('PASSPORT')}. ${translate('PASSPORT_DATA_PAGE')}`}
              </Typography>
              <UploadField
                className={classes.uploadButton}
                label={translate('CHOOSE_FILE')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'passportFrontFileHash'}
                fileNameFieldName={'passportFrontFileName'}
                fileUrlFieldName={'passportFrontFileUrl'}
                fileProgressName={'passportDataPage'}
                onChange={handleDocumentUpload}
                accept={ImagesMIMEType}
                showViewButton
                required
              />
            </div>
          </div>
          <div className={classes.personalDataFileItem}>
            <div className={classes.personalDataFormItem}>
              <Typography type={'text4'} color={'tertiary600'} className={classes.fileUploadLabel}>
                {`${translate('PASSPORT')}. ${translate('PASSPORT_ADDRESS_PAGE')}`}
              </Typography>
              <UploadField
                className={classes.uploadButton}
                label={translate('CHOOSE_FILE')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'passportAddressFileHash'}
                fileNameFieldName={'passportAddressFileName'}
                fileUrlFieldName={'passportAddressFileUrl'}
                fileProgressName={'passportAddressPage'}
                onChange={handleDocumentUpload}
                accept={ImagesMIMEType}
                showViewButton
                required
              />
            </div>
          </div>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DATA_PROCESSING')}
            </Typography>
          </div>
          <div className={classes.personalDataFormCheckbox}>
            <CheckboxField
              name={'personalDataAgreement'}
              label={translate('PERSONAL_DATA_AGREEMENT')}
              required
              onChange={handleFormChange}
            />
            <NotificationMessage
              show={!personalDataAgreement}
              message={translate('PERSONAL_DATA_AGREEMENT_MESSAGE')}
            />
          </div>
        </>
      );
    }
    // Поля для Юридического Лица и ИП
    if (legalType === ClientType.Ul || legalType === ClientType.Ip) {
      return (
        <>
          <div className={classes.personalDataFormItem}>
            <ComboBoxField
              name={'companyName'}
              loading={loadingSuggestCompany}
              freeSolo
              valueKey={'value'}
              titleKey={'companyName'}
              onChange={handleSuggestCompanySelect}
              onInputChange={handleSuggestCompanyChange}
              data={companyList}
              placeholder={translate('EMPTY_COMPANY_OR_INN')}
              label={translate('COMPANY_NAME')}
              renderOption={renderCompanyOptions}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_COMPANY') as string;
                }
                return true;
              }}
              filterOptions={(opts, state_) => {
                if (!state_.inputValue) return opts;
                const inputValueLowerCased = state_.inputValue.toLowerCase();
                return opts.filter(
                  (opt) =>
                    opt.data.name.toLowerCase().includes(inputValueLowerCased) ||
                    opt.data.inn.toLowerCase().includes(inputValueLowerCased)
                );
              }}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <MaskedField
              mask={legalType === ClientType.Ul ? '9999999999' : '999999999999'}
              name={'inn'}
              label={translate('INN')}
              validate={(value: string) => {
                if (value.length !== (legalType === ClientType.Ul ? 10 : 12)) {
                  return translate('PERSONAL_ERROR_MESSAGE', {
                    amount: legalType === ClientType.Ul ? 10 : 12,
                    type: translate('INN'),
                  }) as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          {legalType === ClientType.Ul && (
            <div className={classes.personalDataFormItem}>
              <MaskedField
                mask={'999999999'}
                name={'kpp'}
                label={translate('KPP')}
                validate={(value: string) => {
                  if (value.length !== 9) {
                    return translate('PERSONAL_ERROR_MESSAGE', {
                      amount: 9,
                      type: translate('KPP'),
                    }) as string;
                  }
                  return true;
                }}
                onChanges={handleFormChange}
              />
            </div>
          )}
          <div className={classes.personalDataFormItem}>
            <MaskedField
              mask={legalType === ClientType.Ul ? '9999999999999' : '999999999999999'}
              name={'ogrn'}
              label={translate(legalType === ClientType.Ul ? 'OGRN' : 'OGRNIP')}
              validate={(value: string) => {
                const isLegalUL = legalType === ClientType.Ul;
                if (value.length !== (isLegalUL ? 13 : 15)) {
                  return translate('PERSONAL_ERROR_MESSAGE', {
                    amount: isLegalUL ? 13 : 15,
                    type: translate(isLegalUL ? 'OGRN' : 'OGRNIP'),
                  }) as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'legalAddress'}
              multiline
              rows={3}
              label={translate('LEGAL_ADDRESS')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_ADDRESS') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'postalAddress'}
              multiline
              rows={3}
              InputProps={{ readOnly: IsPostalAddressDuplicate }}
              label={translate('POSTAL_ADDRESS')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_ADDRESS') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormCheckbox}>
            <CheckboxField
              name={'IsPostalAddressDuplicate'}
              label={translate('LEGAL_ADDRESS_MATCH')}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('BANK_DETAILS')}
            </Typography>
          </div>
          <div className={classes.personalDataFormItem}>
            <ComboBoxField
              name={'bankName'}
              loading={loadingSuggestBank}
              freeSolo
              valueKey={'value'}
              titleKey={'bankName'}
              onInputChange={handleSuggestBankChange}
              onChange={handleSuggestBankSelect}
              data={bankList}
              renderOption={renderBankOptions}
              placeholder={translate('CHOOSE_BANK')}
              label={translate('BANK_NAME')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_BANK') as string;
                }
                return true;
              }}
              filterOptions={(opts, state_) => {
                if (!state_.inputValue) return opts;
                const inputValueLowerCased = state_.inputValue.toLowerCase();
                return opts.filter(
                  (opt) =>
                    opt.data.name.toLowerCase().includes(inputValueLowerCased) ||
                    opt.data.shortName.toLowerCase().includes(inputValueLowerCased) ||
                    opt.data.bik.toLowerCase().includes(inputValueLowerCased)
                );
              }}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <MaskedField
              mask={'999999999'}
              name={'bik'}
              label={translate('BIK')}
              validate={(value: string) => {
                if (value.length !== 9) {
                  return translate('PERSONAL_ERROR_MESSAGE', {
                    amount: 9,
                    type: translate('BIK'),
                  }) as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <MaskedField
              mask={'99999999999999999999'}
              name={'correspondentAccount'}
              label={translate('CORRESPONDENT_ACCOUNT')}
              validate={(value: string) => {
                if (value.length !== 20) {
                  return translate('PERSONAL_ERROR_MESSAGE', {
                    amount: 20,
                    type: translate('CORRESPONDENT_ACCOUNT_SHORT'),
                  }) as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <MaskedField
              mask={'99999999999999999999'}
              name={'account'}
              label={translate('CHECKING_ACCOUNT')}
              validate={(value: string) => {
                if (value.length !== 20) {
                  return translate('PERSONAL_ERROR_MESSAGE', {
                    amount: 20,
                    type: translate('CHECKING_ACCOUNT'),
                  }) as string;
                }
                return true;
              }}
              onChanges={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'bankAddress'}
              multiline
              rows={3}
              label={translate('BANK_ADDRESS')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_ADDRESS') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('SIGNATORY')}
            </Typography>
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'personName'}
              label={translate('FULL_NAME')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_FIELD_FULLNAME') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <FormFieldRhfUncontrolled
              name={'genitiveCasePerson'}
              label={translate('GENITIVE_NAME')}
              validate={(value: string) => {
                if (!value) {
                  return translate('EMPTY_GENITIVE_NAME') as string;
                }
                return true;
              }}
              onChange={handleFormChange}
            />
          </div>
          {legalType === ClientType.Ul && (
            <div className={classes.personalDataFormItem}>
              <FormFieldRhfUncontrolled
                name={'position'}
                label={translate('POSITION')}
                validate={(value: string) => {
                  if (!value) {
                    return translate('EMPTY_FIELD_POSITION') as string;
                  }
                  return true;
                }}
                onChange={handleFormChange}
              />
            </div>
          )}
          {legalType === ClientType.Ul && (
            <div className={classes.personalDataFormItem}>
              <FormFieldRhfUncontrolled
                name={'genitiveCasePosition'}
                label={translate('POSITION_GENITIVE')}
                validate={(value: string) => {
                  if (!value) {
                    return translate('EMPTY_FIELD_POSITION') as string;
                  }
                  return true;
                }}
                onChange={handleFormChange}
              />
            </div>
          )}
          {legalType === ClientType.Ul && (
            <div className={classes.personalDataFormItem}>
              <FormFieldRhfUncontrolled
                name={'actBasis'}
                label={translate('BASIC_CONTRACT_GENITIVE')}
                validate={(value: string) => {
                  if (!value) {
                    return translate('EMPTY_FIELD_ACTBASIS') as string;
                  }
                  return true;
                }}
                onChange={handleFormChange}
              />
            </div>
          )}
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('MENU_CONTACTS')}
            </Typography>
          </div>
          <div className={classes.personalDataFormItem}>
            <PhoneField
              name={'phone'}
              label={translate('PHONE')}
              required
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <EmailField
              name={'email'}
              label={translate('EMAIL')}
              required
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.personalDataFormItem}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DATA_PROCESSING')}
            </Typography>
          </div>
          <div className={classes.personalDataFormCheckbox}>
            <CheckboxField
              name={'personalDataAgreement'}
              label={translate('PERSONAL_DATA_AGREEMENT')}
              onChange={handleFormChange}
              required
            />
            <NotificationMessage
              show={!personalDataAgreement}
              message={translate('PERSONAL_DATA_AGREEMENT_MESSAGE')}
            />
          </div>
        </>
      );
    }
    return <div />;
  };

  const renderModalContentErrors = () => {
    const { showDialog, message } = showErrorDialog;
    if (showDialog) {
      return (
        <>
          <div className={classes.personalDataErrorCenterBlock}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate(message)}
            </Typography>
          </div>
          <div className={classes.controlls}>
            <Button title={translate('CLOSE')} onClick={handleErrorDialogClose} smallHeight />
          </div>
        </>
      );
    }
    return <div />;
  };

  const disabledProps = isSiblingDomainCommercial
    ? {
        optionsClassName: classes.selectDisabled,
        className: classes.selectDisabledWrap,
        iconClassName: classes.selectChevron,
      }
    : {};

  return (
    <ContractLayout
      isLoading={loading || loadingClientData}
      childrenHeight={rootRef?.current?.clientHeight}
      formId={formType.PersonalData}
    >
      <div ref={rootRef} className={classes.personalDataRoot}>
        <FormProvider {...formMethods}>
          <form id={formType.PersonalData} onSubmit={handleSubmit(handlePersonalDataSubmit)}>
            <fieldset disabled={Boolean(isSiblingDomainCommercial)} className={classes.fieldset}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('ENTER_PAYER_DATA')}
              </Typography>
              <div className={classes.personalDataContent}>
                <div className={classes.personalDataFormItem}>
                  <SelectField
                    name={'type'}
                    label={translate('LEGAL_STATUS')}
                    translating
                    valueKey={'value'}
                    titleKey={'titleCode'}
                    placeholder={'CHOOSE_STATUS'}
                    defaultValue={legalTypeDefaultValue}
                    data={payerTypeOptionsList}
                    onChange={handleTypeChange}
                    disabled={isSiblingDomainCommercial}
                    {...disabledProps}
                  />
                </div>
                {renderLegalType()}
              </div>
            </fieldset>
          </form>
        </FormProvider>
        <ConfirmDialog
          isBlocked={isChanged.isBlocking}
          onNavigationBlocked={getBlockedPath}
          onSaveChanges={handleSubmit(handlePersonalDataSubmit)}
        />
        <MessageDialog
          isOpen={showErrorDialog.showDialog}
          contentClass={classes.personalDataErrorDialog}
          title={translate(showErrorDialog.title)}
          onCancel={handleErrorDialogClose}
          renderContent={renderModalContentErrors()}
        />
      </div>
    </ContractLayout>
  );
};
