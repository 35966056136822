import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const AuthFormStyle = makeStyles(
  () => ({
    root: {
      width: '18.5em',
      height: 'auto',
      minHeight: '17.5em',
    },
    header: {
      paddingBottom: '1.5em',
      textAlign: 'center',
    },
    headerShort: {
      paddingBottom: '0.25em',
    },
    formContent: {
      marginBottom: '1.5em',
      '&:first-child': {
        marginTop: '1em',
      },
    },
    formFields: {
      '&:first-child': {
        marginTop: '1em',
      },
    },
    formField: {
      marginBottom: '1em',
      width: '100%',
      '&:last-child': {
        marginTop: '0',
        marginBottom: '1.5em',
      },
    },
    domainsFormContent: {
      height: 'auto',
      maxHeight: '50vh',
      marginTop: '1em',
      whiteSpace: 'nowrap',
      display: 'flex',
      flexFlow: 'column',
      overflowY: 'auto',
      marginBottom: '1.5em',
    },
    domainsFormRadioGroup: {
      padding: 0,
      fontSize: '1.125em',
    },
    hidden: {
      display: 'none',
    },
    text: {
      paddingBottom: '1em',
      textAlign: 'center',
    },
    content: {},
    sendAgain: {
      textAlign: 'right',
      paddingTop: '2em',
    },
    codeMargin: {
      marginRight: '0.75em',
    },
    leftSide: {
      width: '50%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    rightSide: {
      width: '50%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    confirmCodeSection: {
      display: 'flex',
      flexFlow: 'row nowrap',
      marginBottom: '1.5em',
    },
    itemWrapper: {
      marginBottom: '1.75em',
      height: '4.5em',
    },
    resetPasswordWrapper: {
      marginBottom: '2em',
      height: '4.5em',
    },
    formActions: {
      textAlign: 'center',
    },
    oneLineActions: {
      display: 'flex',
    },
    confirmButton: {
      height: 56,
      marginRight: '2.75em',
    },
    link: {
      textAlign: 'center',
      paddingTop: '1em',
      marginRight: '0',
      '& a': {
        textDecoration: 'none',
      },
    },
    helpLink: {
      textDecoration: 'none',
      margin: '0 auto',
    },
    linkReg: {
      textAlign: 'center',
      marginRight: '0',
      '& a': {
        textDecoration: 'none',
      },
    },
    controlLabel: {
      margin: 0,
    },
    dialog: {
      padding: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogPaper: {
      maxWidth: '21em',
      width: '21em',
    },
    errorDialogDescription: {
      display: 'flex',
      flexFlow: 'column',
      padding: '0 2em 2em',
      textAlign: 'center',
      '& > span': {
        marginTop: '1em',
      },
    },
    errorDialogActions: {
      textAlign: 'center',
    },
    errorDialogConfirmButton: {
      height: '2.5em',
      width: '12em',
      marginTop: '2em',
    },
    dialogContent: {
      padding: '0 2em 2em',
      textAlign: 'center',
    },
    termsDialogPaper: {
      maxWidth: '35em',
      width: '35em',
    },
    dialogActions: {
      padding: '2em',
      justifyContent: 'center',
    },
    submitButton: {
      height: '3.625em',
    },
    submitBtnSmallScreen: {
      height: '3em',
      marginTop: '5em',
    },
    confirmSignupButton: {
      width: '50%',
    },
    tooltipContent: {
      width: '15em',
      lineHeight: '1em',
    },
    tooltipLink: {
      padding: 0,
      margin: '0.25em',
      cursor: 'pointer',
    },
    signInDialogTitle: {
      padding: '2em 2em 1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    createPBXDialogTitle: {
      padding: '0 2em 1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogHeader: {
      display: 'flex',
      width: '100%',
      flexFlow: 'row-reverse',
    },
    closeButton: {
      width: '2em',
      height: '2em',
      padding: 'initial',
      border: 0,
    },
    signInForm: {
      marginTop: '1em',
    },
    textSpan: {
      textAlign: 'center',
      marginTop: '1.5em',
    },
    checkbox: {
      margin: '0',
    },
    signInFormError: {
      padding: 0,
      margin: 0,
      height: '1.25em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    updatedRow: {
      display: 'flex',
      alignItems: 'center',
      width: '12em',
      margin: '0 auto 2em',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
