import { Avatar as MUIAvatar } from '@material-ui/core';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { AvatarProps } from './Avatar.interfaces';
import { useAvatarStyles } from './Avatar.styles';

export const Avatar: FunctionComponent<AvatarProps> = ({
  children,
  className,
  url,
  name,
  showInitials,
  size = 'large',
  ...props
}) => {
  const [urlError, setUrlError] = useState(false);

  useEffect(() => {
    setUrlError(false);
  }, [url]);

  const classes = useAvatarStyles();
  const getUserNameInitials = () =>
    name
      ?.replace(/-/g, ' ')
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 3)
      .toUpperCase();

  const avatarInitialsClassNames = clsx(classes.avatarInitials, {
    [classes.avatarInitialsMedium]: size === 'medium',
    [classes.avatarInitialsSmall]: size === 'small',
  });

  const renderUserAvatar = () => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      className={clsx(classes.avatarImageWrapper, className)}
      src={url || undefined}
      alt={getUserNameInitials()}
      onClick={props.onClick}
      onError={() => {
        setUrlError(true);
      }}
      crossOrigin={'anonymous'}
    />
  );

  if (url && !urlError) {
    return renderUserAvatar();
  }

  if (!showInitials) {
    return <div className={className} />;
  }

  return (
    <MUIAvatar className={clsx(classes.root, className)} {...props}>
      <Typography className={avatarInitialsClassNames}>{getUserNameInitials()}</Typography>
    </MUIAvatar>
  );
};

export default Avatar;
