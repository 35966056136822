import React, { FunctionComponent } from 'react';
import { GraphQLError } from 'graphql';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@shared/components/Typography';
import { useFormErrorStyles } from './FormErrorMessage.styles';

interface IFormSimpleError {
  message: string;
}

type IFormError = GraphQLError & IFormSimpleError;

export const FormErrorMessage: FunctionComponent<{
  className?: string;
  errors: readonly IFormError[] | undefined;
}> = ({ className, errors = [] }) => {
  const classes = useFormErrorStyles();
  const [translate] = useTranslation();

  const filteredErrors = errors.filter((error) => !!error.message);

  return (
    <div className={clsx(classes.root, className)}>
      {filteredErrors.map((error, index: number) => {
        const field = (error.extensions?.exception as Record<string, string | undefined>)?.field;
        if (field) {
          return null;
        }

        const message = (error.extensions?.exception as Record<string, string | undefined>)
          ?.message;

        return (
          <Typography type={'text4'} color={'danger600'} key={index}>
            {translate(message || '', '') || translate('SOMETHING_WENT_WRONG')}
          </Typography>
        );
      })}
    </div>
  );
};

export default FormErrorMessage;
