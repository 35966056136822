const paddingStyleConstants = {
  paddingLeft05: {
    paddingLeft: '0.5em',
  },
  paddingLeft1: {
    paddingLeft: '1em',
  },
  paddingLeft2: {
    paddingLeft: '2em',
  },
  paddingLeft3: {
    paddingLeft: '3em',
  },
  paddingUpDown025: {
    padding: '0.25em 0',
  },
  paddingBottom1: {
    paddingBottom: '1em',
  },
  paddingBottom15: {
    paddingBottom: '1.5em',
  },
  paddingBottom2: {
    paddingBottom: '2em',
  },
  marginLeft05: {
    marginLeft: '0.5em',
  },
  marginLeft075: {
    marginLeft: '0.75em',
  },
  marginLeft1: {
    marginLeft: '1em',
  },
  marginLeft2: {
    marginLeft: '2em',
  },
  marginLeft3: {
    marginLeft: '3em',
  },
  marginTop025: {
    marginTop: '0.25em',
  },
  marginTop05: {
    marginTop: '0.5em',
  },
  marginTop1: {
    marginTop: '1em',
  },
  marginTop15: {
    marginTop: '1.5em',
  },
  marginTop2: {
    marginTop: '2em',
  },
  marginTop3: {
    marginTop: '3em',
  },
  marginTop5: {
    marginTop: '5em',
  },
  marginBottom025: {
    marginBottom: '0.25em',
  },
  marginBottom05: {
    marginBottom: '0.5em',
  },
  marginBottom15: {
    marginBottom: '1.5em',
  },
  marginBottom1: {
    marginBottom: '1em',
  },
  marginBottom2: {
    marginBottom: '2em',
  },
  marginBottom3: {
    marginBottom: '3em',
  },
  marginBottom5: {
    marginBottom: '5em',
  },
  marginRight05: {
    marginRight: '0.5em',
  },
  marginRight1: {
    marginRight: '1em',
  },
};

export default paddingStyleConstants;
