import {
  keepOnlyLeadingMinus,
  keepOnlyNCharactersAfterDot,
  removeAllDotsExceptFirst,
} from '@shared/utils';

const regAllNonIntegers = /\D/g;
const regAllNonSumNumberSymbols = /[^\d.,]/g;
const regAllNonArbitraryNumberSymbols = /[^-\d.]/g;
const regAllComas = /,/g;

export const limitNumber = 9_999_999_999;
export const limitFloatingNumber = 9_999_999_999.99;

function validateNumber(str: string): string {
  const n = Number(str);

  if (Number.isNaN(n)) {
    return '0';
  }

  return String(n);
}

/**
 * Функция проводит валидацию строки для представления числа в виде `dddd`, где `d` - число.
 * Результирующая строка представляет целое число - как положительное, так и отрицательное.
 *
 * `formatSumNumberString('2441')` => `'2441'`
 *
 * `formatSumNumberString('3887.5463')` => `'38875463'`
 *
 * `formatSumNumberString('I have 8 apples.')` => `'8'`
 *
 * `formatSumNumberString('It is -5 degrees outside.')` => `'-5'`
 */
export function formatIntegerNumberString(str: string) {
  if (str === '') {
    return '';
  }

  let strCleared = str.replace(regAllNonIntegers, '');
  const hasLeadingMinus = str.startsWith('-');
  const hasOnlyMinus = hasLeadingMinus && str.length === 1;
  const hasOnlyZeroAndMinus = str[0] === '0' && str[1] === '-';

  if (hasOnlyMinus || hasOnlyZeroAndMinus) {
    return '-';
  }

  if (hasLeadingMinus) {
    strCleared = `-${strCleared}`;
  }

  const allowedDigitsCount = hasLeadingMinus ? 11 : 10;

  if (strCleared.length === allowedDigitsCount + 1) {
    strCleared = strCleared.slice(0, -1);
  } else if (strCleared.length > allowedDigitsCount) {
    strCleared = hasLeadingMinus ? `-${limitNumber}` : `${limitNumber}`;
  }

  return validateNumber(strCleared);
}

export function formatIntegerPositiveNumberString(str: string) {
  if (str === '') {
    return '';
  }

  let strCleared = str.replace(regAllNonIntegers, '');

  const allowedDigitsCount = 10;

  if (strCleared.length === allowedDigitsCount + 1) {
    strCleared = strCleared.slice(0, -1);
  } else if (strCleared.length > allowedDigitsCount) {
    strCleared = `${limitNumber}`;
  }

  return validateNumber(strCleared);
}

/**
 * Функция проводит валидацию строки для представления числа в виде `dd.dd`, где `d` - число.
 * Часть после `.` опциональна.
 * Результирующая строка представляется исключительно положительным числом.
 *
 * `formatSumNumberString('0.04378')` => `'0.04'`
 *
 * `formatSumNumberString('3887.')` => `'3887.'`
 *
 * `formatSumNumberString('342')` => `'342'`
 */
export function formatSumNumberString(str: string) {
  if (str === '') {
    return '';
  }

  let strFormatted = str.replace(regAllNonSumNumberSymbols, '');
  strFormatted = strFormatted.replace(regAllComas, '.');
  strFormatted = removeAllDotsExceptFirst(strFormatted);
  strFormatted = keepOnlyNCharactersAfterDot(strFormatted, 2);

  const startsWithDot = strFormatted.startsWith('.');

  if (startsWithDot) {
    strFormatted = strFormatted.slice(1);
  }

  const indexOfDot = strFormatted.indexOf('.');
  const isThereDot = indexOfDot !== -1;
  const allowedSymbolsCountWithDot = 13;
  const allowedSymbolsCountWithoutDot = 10;

  if (indexOfDot > allowedSymbolsCountWithoutDot) {
    strFormatted = strFormatted.slice(0, allowedSymbolsCountWithoutDot);
  }

  if (isThereDot) {
    if (strFormatted.length === allowedSymbolsCountWithDot + 1) {
      strFormatted = strFormatted.slice(0, allowedSymbolsCountWithDot);
    } else if (strFormatted.length > allowedSymbolsCountWithDot) {
      strFormatted = `${limitFloatingNumber}`;
    }
  } else if (strFormatted.length === allowedSymbolsCountWithoutDot + 1) {
    strFormatted = strFormatted.slice(0, allowedSymbolsCountWithoutDot);
  } else if (strFormatted.length > allowedSymbolsCountWithoutDot) {
    strFormatted = `${limitNumber}`;
  }

  const endsWithDot = strFormatted.endsWith('.');
  const endsWithDotZero = strFormatted.endsWith('.0');

  if (endsWithDot || endsWithDotZero) {
    return strFormatted;
  }

  return validateNumber(strFormatted);
}

/**
 * Функция проводит валидацию строки для представления числа в виде `ddddd.dddddd`, где `d` - число.
 * Часть после `.` опциональна, количество символов ограничивается.
 * Число может быть как положительным, так и отрицательным.
 *
 * `formatSumNumberString('0.04378333')` => `'0.04378333'`
 *
 * `formatSumNumberString('3887.')` => `'3887.'`
 *
 * `formatSumNumberString('3877,22214124')` => `'3877.22214124'`
 *
 * `formatSumNumberString('-1398.428621908')` => `'-1398.428621908'`
 *
 * `formatSumNumberString('1000000000000000000000')` => `'999999999999999'`
 */
export function formatArbitraryNumberString(str: string) {
  if (str === '') {
    return '';
  }

  let strFormatted = str.replace(regAllNonArbitraryNumberSymbols, '');
  strFormatted = strFormatted.replace(regAllComas, '.');
  strFormatted = removeAllDotsExceptFirst(strFormatted);
  strFormatted = keepOnlyNCharactersAfterDot(strFormatted, 12);
  strFormatted = keepOnlyLeadingMinus(strFormatted);

  const hasLeadingMinus = str.startsWith('-');
  const hasOnlyMinus = hasLeadingMinus && str.length === 1;
  const hasOnlyZeroAndMinus = str[0] === '0' && str[1] === '-';

  if (hasOnlyMinus || hasOnlyZeroAndMinus) {
    return '-';
  }

  const startsWithDot = strFormatted.startsWith('.');
  if (startsWithDot) {
    strFormatted = strFormatted.slice(1);
  }

  const endsWithMinus = strFormatted.endsWith('-');

  if (endsWithMinus) {
    strFormatted = strFormatted.slice(0, -1);
  }

  const allowedSymbolsCountWithDot = hasLeadingMinus ? 14 : 13;
  const allowedSymbolsCountWithoutDot = hasLeadingMinus ? 11 : 10;

  const indexOfDot = strFormatted.indexOf('.');
  const isThereADot = indexOfDot !== -1;

  if (indexOfDot > allowedSymbolsCountWithoutDot) {
    strFormatted = strFormatted.slice(0, allowedSymbolsCountWithoutDot);
  }

  if (isThereADot) {
    if (strFormatted.length === allowedSymbolsCountWithDot + 1) {
      strFormatted = strFormatted.slice(0, allowedSymbolsCountWithDot);
    } else if (strFormatted.length > allowedSymbolsCountWithDot) {
      strFormatted = `${limitFloatingNumber}`;
    }
  } else if (strFormatted.length === allowedSymbolsCountWithoutDot + 1) {
    strFormatted = strFormatted.slice(0, allowedSymbolsCountWithoutDot);
  } else if (strFormatted.length > allowedSymbolsCountWithoutDot) {
    strFormatted = `${limitNumber}`;
  }

  const endsWithDot = strFormatted.endsWith('.');
  const endsWithDotAndZeroes = strFormatted.match(/\.0+$/);

  if (endsWithDot || endsWithDotAndZeroes) {
    return strFormatted;
  }

  return validateNumber(strFormatted);
}
