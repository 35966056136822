import React, { useRef } from 'react';
import clsx from 'clsx';
import { usePageBodyStyles } from './BodyContainer.styles';
import { IPageBodyProps } from './BodyContainer.interfaces';

export const BodyContainer = ({
  disableOverflow,
  children,
  customRootClass,
  customOutletClass,
  breadCrumbs,
}: IPageBodyProps) => {
  const classes = usePageBodyStyles();
  const breadCrumbsContainer = useRef<HTMLDivElement | null>(null);
  const breadCrumbsHeight =
    breadCrumbsContainer && breadCrumbsContainer.current
      ? breadCrumbsContainer.current?.clientHeight
      : 0;

  const computedContentClasses = clsx(classes.content, customRootClass);

  const computedOutletClasses = clsx(classes.outlet, customOutletClass, {
    [classes.outletFullHeight]: disableOverflow,
  });

  const renderBreadCrumbs = () => {
    if (!breadCrumbs) return null;

    return <div ref={breadCrumbsContainer}>{breadCrumbs}</div>;
  };

  return (
    <div className={classes.bodyRoot}>
      {renderBreadCrumbs()}
      <div
        className={computedContentClasses}
        style={{
          overflow: disableOverflow ? 'hidden' : 'auto',
          top: `${breadCrumbsHeight}px`,
        }}
      >
        <div className={computedOutletClasses}>{children}</div>
      </div>
    </div>
  );
};

export default BodyContainer;
