import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { AssistantStatus, IAssistant } from '@components/typings/interfaces';
import { ICellProps } from '@components/LazyTable';

export const ScenarioStatusCell: FunctionComponent<ICellProps<IAssistant>> = ({ item }) => {
  const [translate] = useTranslation();

  if (!item) {
    return <div />;
  }

  const { status, statusDate } = item;

  const renderColor = () => {
    if (status) {
      if (
        status === AssistantStatus.PreDeletedFrozen ||
        status === AssistantStatus.PreDeletedBought
      ) {
        return 'danger600';
      }
    }
    return 'tertiary900';
  };

  const renderValue = () => {
    if (status === AssistantStatus.Bought) {
      return `${translate('BOUGHT')}`;
    }
    if (status === AssistantStatus.Reserved) {
      return `${translate('RESERVED')}`;
    }
    const date = statusDate ? format(new Date(statusDate), ' dd.MM.yyyy') : '';
    if (status === AssistantStatus.Developing) {
      return `${translate('DEVELOPING')}\xa0${date}`;
    }
    if (status === AssistantStatus.Frozen) {
      return `${translate('FROZEN')}\xa0${date}`;
    }
    if (status === AssistantStatus.PreFrozen) {
      return `${translate('PRE_FROZEN')}\xa0${date}`;
    }
    if (status === AssistantStatus.PreDeletedBought) {
      return `${translate('PRE_DELETED_BOUGHT')}\xa0${date}`;
    }
    if (status === AssistantStatus.PreDeletedFrozen) {
      return `${translate('PRE_DELETED_FROZEN')}\xa0${date}`;
    }
    return null;
  };

  return (
    <Typography type={'text3'} color={renderColor()}>
      {renderValue()}
    </Typography>
  );
};
