import React, { ReactNode } from 'react';
import { CommercialDomainStatuses, DomainStatus } from '@components/typings/interfaces';
import {
  WalletIcon,
  BriefCaseIcon,
  NumbersIcon,
  ScenariosIcon,
  DocumentIcon,
  FolderIcon,
  LogOutIcon,
  DownloadIcon,
} from '@shared/assets/images/icons';

import { getGraviphoneDownloadLink } from '@/utils/getGraviphoneDownloadLink';

type NavItem = {
  icon: ReactNode;
  title: string;
  path: string;
  external?: boolean;
  logOut?: boolean;
};

const arrayWithOnlyDemoDomainStatus = [DomainStatus.Demo];

export const menuItems = [
  {
    icon: <WalletIcon />,
    title: 'FINANCE',
    path: '/cabinet/balance',
    feature: 'balance',
    notAvailableIn: arrayWithOnlyDemoDomainStatus,
  },
  {
    icon: <BriefCaseIcon />,
    title: 'MENU_PRICING',
    path: '/tariff-list/tariff',
    feature: 'tariff',
    availableIn: CommercialDomainStatuses,
  },
  {
    icon: <NumbersIcon />,
    title: 'NUMBERS',
    path: '/numbers/numbers',
    feature: 'numbers',
    availableIn: CommercialDomainStatuses,
  },
  {
    icon: <ScenariosIcon />,
    title: 'SCENARIOS',
    path: '/scenarios-list',
    feature: 'assistants',
    availableIn: CommercialDomainStatuses,
  },
  {
    icon: <DocumentIcon />,
    title: 'DOCUMENTS',
    path: '/documents/documents-list',
    feature: 'documents',
  },
];

export const userMenuNavItems: NavItem[] = [
  {
    icon: <FolderIcon />,
    title: 'PROFILE_DATA',
    path: '/profile',
  },
  {
    icon: <LogOutIcon />,
    title: 'LOGOUT',
    path: '/signout',
    logOut: true,
  },
];

const graviphoneDownloadLink = getGraviphoneDownloadLink();

if (graviphoneDownloadLink !== null) {
  userMenuNavItems.splice(1, 0, {
    icon: <DownloadIcon />,
    title: 'DOWNLOAD_GRAVIPHONE',
    path: graviphoneDownloadLink,
    external: true,
  });
}

export const sysUserMenuNavItems: NavItem[] = [
  {
    icon: <LogOutIcon />,
    title: 'LOGOUT',
    path: '/signout',
    logOut: true,
  },
];
