import { useRef, useCallback, useEffect } from 'react';

type AnyFunction = (...args: any[]) => any;

const useThrottledCallback: <Cb extends AnyFunction>(
  callback: Cb,
  throttleDelayMs: number
) => (...args: Parameters<Cb>) => void = (callback, throttleDelayMs) => {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const cleanup = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };

    return cleanup;
  }, []);

  const resultCallback = useCallback(
    (...args: Parameters<typeof callback>) => {
      if (timeout.current) {
        return;
      }

      callback(...args);
      timeout.current = setTimeout(() => {
        timeout.current = null;
      }, throttleDelayMs);
    },
    [callback, throttleDelayMs]
  );

  return resultCallback;
};

export default useThrottledCallback;
