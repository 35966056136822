import { makeStyles } from '@material-ui/core';

export const useControlButtonsStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    confirmButton: {
      width: '8em',
    },
    rootJustifyCenter: {
      justifyContent: 'center',
    },
    rootJustifyEvenly: {
      justifyContent: 'space-evenly',
    },
    rootJustifyBetween: {
      justifyContent: 'space-between',
    },
    rootJustifyStart: {
      justifyContent: 'flex-start',
    },
    rootJustifyEnd: {
      justifyContent: 'flex-end',
    },
    cancelButton: {
      height: '100%',
      maxHeight: '2.5em',
    },
    cancelButtonMarginLeft: {
      marginLeft: '1em',
    },
    cancelButtonMarginRight: {
      marginRight: '1em',
    },
    rootRowReverse: {
      flexDirection: 'row-reverse',
    },
    underline: {
      textDecoration: 'underline',
    },
    smallButtonHeight: {
      height: '2em',
    },
    cancelButtonSmallMarginRight: {
      marginRight: '.5em',
    },
    cancelButtonSmallMarginLeft: {
      marginRight: '.5em',
    },
  }),
  {
    index: 3,
  }
);
