import { makeStyles } from '@material-ui/core/styles';

export const useProfileChangeEmailStyles = makeStyles(({ color: { success } }) => ({
  itemWrapper: {
    marginBottom: '1.5em',
    '&:last-child': {
      marginBottom: '1em',
    },
  },
  content: {
    width: '20em',
  },
  buttonSave: {
    marginLeft: '.5em',
    height: '2em',
  },
  buttonCancel: {
    height: '2em',
  },
  buttonClose: {
    height: '2em',
  },
  controlButtonsWrapper: {
    marginTop: '2em',

    display: 'flex',
    justifyContent: 'end',
  },
  messageSuccess: {
    margin: '1.5em 0',
    fontSize: '0.875em',
  },
  iconSuccessContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconSuccess: {
    fontSize: '1em',
    width: '2em',
    height: '2em',
    fill: success[600],
  },
  hidden: {
    opacity: 0,
    display: 'none',
    height: 0,
  },
}));
