import { Snackbar as MUISnackbar, SnackbarCloseReason } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import { SnackbarProps } from './Snackbar.interfaces';
import Alert from '../Alert';
import { useSnackbarStyles } from './Snackbar.styles';

export default function Snackbar({
  AlertProps,
  open,
  autoHideDuration = 4000,
  vertical = 'bottom',
  horizontal = 'left',
  withLeftMargin = false,
  onClose,
  ...props
}: SnackbarProps) {
  const classes = useSnackbarStyles();

  function handleClose(event: SyntheticEvent<any, Event>, reason: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }

    if (onClose) {
      onClose(event, reason);
    }
  }

  return (
    <MUISnackbar
      TransitionComponent={Slide}
      open={open}
      resumeHideDuration={1000}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleClose}
      {...props}
    >
      <div>
        <Alert
          {...AlertProps}
          classes={{ ...AlertProps.classes, root: clsx(classes.alert, AlertProps.classes?.root) }}
        />
      </div>
    </MUISnackbar>
  );
}
