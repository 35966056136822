import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useBalanceStyle = makeStyles(
  ({ color: { base, tertiary }, spacing, breakpoints: { down } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    balance: {
      height: '5em',
      position: 'fixed',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `solid 1px ${tertiary['100']}`,
      display: 'flex',
      padding: '0 2em',
      bottom: 0,
      right: 0,
      left: spacing(10),
      backgroundColor: base,
      boxShadow: '0px -5px 10px 6px rgba(238, 238, 238, 0.6)',
      [down('md')]: {
        right: '1em',
        bottom: '1.1em',
      },
    },
    contentBottomSpace: {
      bottom: '5em',
    },
    balanceActions: {
      display: 'flex',
      alignItems: 'center',
    },
    currentBalanceMargin: {
      marginLeft: '9em',
    },
    balanceNumber: {
      minWidth: '11em',
      paddingLeft: '2em',
      textAlign: 'right',
    },
    balanceControls: {
      paddingLeft: '2em',
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    rowWrapper: {
      paddingTop: '1em',
      paddingBottom: '1em',
      borderBottom: `1px solid ${tertiary[100]}`,
    },
    operationCell: {
      whiteSpace: 'pre-wrap',
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
