import { scenarioTypes } from '@components/typings/interfaces';

export const scenarioList = [
  {
    title: 'SCENARIOS_BASE_TITLE',
    description: 'SCENARIO_BASE_DESCRIPTION',
    monthPrice: 5000,
    installationPrice: 5000,
    type: scenarioTypes.Base,
  },
  {
    title: 'SCENARIO_INDIVIDUAL_TITLE',
    description: 'SCENARIO_INDIVIDUAL_DESCRIPTION',
    monthPrice: 10000,
    type: scenarioTypes.Individual,
  },
];
