import { NotificationTypes } from '@components/typings/interfaces/notifications';
import { IEmployeeRaw } from './employee';
import { IDomain } from './domain';

export interface IUser {
  id?: number;
  name: string;
  email: string;
  phone?: string;
  createdAt?: Date;
  domains?: IDomain[];
  isActive: boolean;
  notifications?: NotificationTypes[];
  avatar?: string;
}

export interface IUserRaw {
  id: number;
  name: string;
  email: string;
  phone?: string;
  language?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  lastLoggedAt?: Date;
  isActive?: boolean;
  employees?: IEmployeeRaw[];
  missedCallsNotifications?: NotificationTypes[];
  avatar?: string;
}

export const deserializeUser = (data: Partial<IUserRaw> | undefined): IUser => {
  if (!data) {
    return {
      name: '',
      email: '',
      isActive: false,
      notifications: [],
      avatar: '',
    };
  }
  const { name, email, isActive, missedCallsNotifications, avatar } = data;
  return {
    name: name ?? '',
    email: email ?? '',
    isActive: isActive ?? false,
    notifications: missedCallsNotifications,
    avatar,
  };
};
