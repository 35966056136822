import React from 'react';
import { LogoBlackIcon } from '@shared/assets/images/icons/logos';
import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberTypeCell = () => {
  const classes = useIncomingNumbersStyles();
  return (
    <div className={classes.numberTypeBody}>
      <LogoBlackIcon className={classes.icon} width={16} height={16} />
    </div>
  );
};
