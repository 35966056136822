import { makeStyles } from '@material-ui/core/styles';

export const TranscriptionStyles = makeStyles(
  ({ color: { base, tertiary, secondary } }) => ({
    wrap: {
      position: 'fixed',
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0, 0.6)',
      zIndex: 1500,
      padding: '4.5em 0',
    },
    inputAdornedEnd: {
      padding: '0.58em 7.5em 0.58em 1em',
    },
    show: {
      display: 'flex',
    },
    window: {
      width: '33em',
      borderRadius: '5px',
      backgroundColor: base,
    },
    close: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    header: {
      padding: '0 2em 1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingLeft: '1em',
    },
    download: {
      marginLeft: '0.5em',
    },
    info: {
      backgroundColor: tertiary['50'],
      padding: '0.5em 2em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    cell: {
      display: 'flex',
      width: '10em',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    employeeInfo: {
      alignItems: 'flex-start',
      whiteSpace: 'nowrap',
      '& *': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    clientInfo: {
      alignItems: 'flex-end',
    },
    chat: {
      padding: '1em 1em 1em 2em',
      overflow: 'auto',
      height: '24em',
      position: 'relative',
    },
    itemLeft: {
      display: 'flex',
      paddingRight: '3em',
      justifyContent: 'flex-start',
    },
    itemRight: {
      display: 'flex',
      paddingLeft: '3em',
      justifyContent: 'flex-end',
    },
    message: {
      padding: '0.5em 1em',
      border: 'solid 1px',
      borderRadius: '10px',
      position: 'relative',
      display: 'inline-block',
      marginBottom: '0.5em',
      '&:after': {
        position: 'absolute',
        content: '""',
      },
    },
    to: {
      borderColor: tertiary['100'],
      '&:after': {
        top: '100%',
        left: '10px',
        borderTop: `6px solid${tertiary['100']}`,
        borderRight: '8px solid transparent',
      },
    },
    from: {
      borderColor: secondary['200'],
      backgroundColor: secondary['100'],
      '&:after': {
        top: '100%',
        right: '10px',
        borderTop: `6px solid${secondary['200']}`,
        borderLeft: '8px solid transparent',
      },
    },
    time: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    timeFrom: {
      right: '100%',
      marginRight: '1em',
      textAlign: 'right',
    },
    timeTo: {
      left: '100%',
      marginLeft: '1em',
      textAlign: 'left',
    },
    closeIcon: {
      cursor: 'pointer',
    },
    disabledIcon: {
      color: tertiary['100'],
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
    },
    switch: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.5em',
    },
    buttonClose: {
      width: '2em',
      height: '2em',
    },
  }),
  {
    index: 1,
  }
);
