import React, { FunctionComponent, useEffect, useState } from 'react';
import Typography from '@shared/components/Typography/Typography';
import { XIcon } from '@shared/assets/images/icons';
import { useNotificationMessageStyle } from './NotificationMessage.styles';
import { NotificationMessageProps } from './NotificationMessage.interfaces';

export const NotificationMessage: FunctionComponent<NotificationMessageProps> = ({
  show,
  message,
}) => {
  const classes = useNotificationMessageStyle();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(false);
  }, [show]);

  function handleCloseClick() {
    setIsHidden(true);
  }

  const renderMessage = () => {
    if (isHidden) {
      return <div />;
    }
    if (show) {
      return (
        <div className={classes.root}>
          <div className={classes.message}>
            <Typography color={'tertiary900'} type={'text4'}>
              {message}
            </Typography>
          </div>
          <XIcon onClick={handleCloseClick} className={classes.close} />
        </div>
      );
    }
    return <div />;
  };

  return <>{renderMessage()}</>;
};

export default NotificationMessage;
