import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import { CheckReadonlyIcon } from '@shared/assets/images/icons';
import Checkbox from '@shared/components/Checkbox';
import { ControllerRenderPropsCustom } from '@shared/types/forms';
import React, { FunctionComponent, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useCheckboxFieldStyles } from './CheckboxField.styles';

export const CheckboxField: FunctionComponent<
  Omit<CheckboxProps, 'defaultValue' | 'onChange'> & {
    label?: string;
    defaultValue?: boolean;
    disabled?: boolean;
    onChange?: (isChecked: boolean) => void;
    readOnly?: boolean;
  }
> = ({
  name = '',
  label = '',
  defaultValue = false,
  disabled = false,
  onChange,
  readOnly,
  ...props
}) => {
  const classes = useCheckboxFieldStyles();
  const { control } = useFormContext();

  const renderControl = (controlProps: ControllerRenderPropsCustom) => {
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      controlProps.field.onChange(checked);
      if (onChange) {
        onChange(checked);
      }
    };
    const checkedReadonlyIcon = readOnly
      ? { checkedIcon: <CheckReadonlyIcon className={classes.readOnly} /> }
      : null;

    const labelControl = (
      <Checkbox
        disabled={disabled}
        color={'primary'}
        onChange={!readOnly ? handleCheckboxChange : undefined}
        checked={controlProps.field.value}
        readOnly={readOnly}
        {...checkedReadonlyIcon}
        {...props}
      />
    );

    return (
      <FormControlLabel
        className={classes.root}
        control={labelControl}
        label={label}
        disabled={disabled}
        classes={{ label: classes.label, disabled: classes.disabled }}
        defaultChecked={defaultValue}
      />
    );
  };

  return (
    <Controller name={name} control={control} defaultValue={defaultValue} render={renderControl} />
  );
};

export default memo(CheckboxField);
