import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { Department } from '../Departments';

export const DepartmentExtCell = ({ item }: ICellProps<Department>) => {
  const ext = item.ext || '';
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  return <HighlightedText type={'text'} text={ext} query={searchText} />;
};
