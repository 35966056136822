import { intervalToDuration } from 'date-fns';

export function isEmailValid(value: string) {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(value).toLowerCase())
    ? true
    : "Invalid email address. Valid e-mail can contain only latin letters, numbers, '@' and '.";
}

export function secondsToDuration(seconds = 0): Duration {
  return intervalToDuration({ start: 0, end: seconds * 1000 });
}

export function haveOnlyAscii(text: string): boolean {
  const ascii =
    '\n !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~';
  const arrayOfString = text.split('');
  return arrayOfString.every((el) => ascii.indexOf(el) !== -1);
}

export function getSegments(text: string, additionalNumber: number): number {
  let sms1Length;
  let sms2Length;
  if (haveOnlyAscii(text) && !additionalNumber) {
    sms1Length = 160;
    sms2Length = 153;
  } else {
    sms1Length = 70;
    sms2Length = 67;
  }
  const len = text.length + (additionalNumber || 0);
  if (len > sms1Length) {
    return Math.ceil(len / sms2Length);
  }
  return 1;
}
