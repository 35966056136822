import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhone } from '@components/utils';
import { ICellProps } from '@components/LazyTable';
import { useSearchParams } from 'react-router-dom';
import HighlightedText from '@components/HighlightedText';
import { useApologySMSStyles } from '../SettingsApologySMS.styles';
import { IApologySMS, IApologySMSNumber } from '../SettingsApologySMS.interface';

export const ApologySMSNumber: FunctionComponent<ICellProps<IApologySMS>> = ({ item }) => {
  const { domainNumbers, allNumbers } = item || {};
  const [translate] = useTranslation();
  const classes = useApologySMSStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('search') || null;

  const renderContentContainer = () => {
    if (allNumbers) {
      return <HighlightedText type={'text'} text={translate('ALL_NUMBERS')} query={searchText} />;
    }
    if (domainNumbers && domainNumbers.length) {
      return domainNumbers.map((el: IApologySMSNumber, index: number) => {
        const phoneNumber = formatPhone(String(el.phone));
        return (
          <div key={`container_${index}`} className={classes.numberContainer}>
            <HighlightedText type={'phone'} text={formatPhone(phoneNumber)} query={searchText} />
          </div>
        );
      });
    }
    return null;
  };

  return <div>{renderContentContainer()}</div>;
};
