import { makeStyles } from '@material-ui/core';

export const useAvatarStyles = makeStyles(({ color: { base } }) => ({
  root: {
    backgroundColor: base,
  },
  avatarImageWrapper: {
    width: 'inherit',
    borderRadius: '50%',
  },
  avatarInitials: {
    fontSize: '38px',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  },
  avatarInitialsMedium: {
    fontSize: '24px',
  },
  avatarInitialsSmall: {
    fontSize: '16px',
  },
}));
