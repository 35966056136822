import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  comparedEmployeeListState,
  IResponsibleSettingState,
  IScenarioSettingProps,
} from '../IntegrationForm.interfaces';

export function useIntegrationSettings() {
  const [translate] = useTranslation();

  const [integrationSettings, setIntegrationSettings] = useState<IScenarioSettingProps[]>([
    { currentIndex: 0, name: `${translate('SCENARIO')} 1` },
  ]);
  const [responsibleSetting, setResponsibleSetting] = useState<IResponsibleSettingState>({
    isOpen: false,
    data: null,
  });
  const [comparedEmployee, setComparedEmployee] = useState<comparedEmployeeListState>({
    compared: null,
    raw: null,
  });
  const [scenarioIndex, setScenarioIndex] = useState(0);
  const [isScenarioNameEdit, setScenarioNameEdit] = useState(false);
  const [whereToFindShow, setWhereToFindShow] = useState(false);
  const [isConfigurationLoaded, setConfigurationLoaded] = useState(false);

  return {
    integrationSettings,
    setIntegrationSettings,

    responsibleSetting,
    setResponsibleSetting,

    comparedEmployee,
    setComparedEmployee,

    scenarioIndex,
    setScenarioIndex,

    isScenarioNameEdit,
    setScenarioNameEdit,

    whereToFindShow,
    setWhereToFindShow,

    isConfigurationLoaded,
    setConfigurationLoaded,
  };
}
