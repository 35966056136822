import { FormControlLabel, FormControlLabelProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useFormControlLabelForRadioStyles } from './FormControlLabelForRadio.styles';

type FormControlLabelForRadioProps = FormControlLabelProps & {
  isActive?: boolean;
};

export default function FormControlLabelForRadio({
  className,
  classes,
  isActive,
  ...props
}: FormControlLabelForRadioProps) {
  const classesStyles = useFormControlLabelForRadioStyles();

  return (
    <FormControlLabel
      className={clsx(classesStyles.label, isActive && classesStyles.active, className)}
      classes={{
        ...classes,
        label: clsx(classesStyles.labelText, classes?.label),
      }}
      {...props}
    />
  );
}
