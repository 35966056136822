import { makeStyles, Theme } from '@material-ui/core';

export const useRadioStyles = makeStyles(({ color: { base, tertiary, primary } }: Theme) => ({
  root: {
    fontSize: '0.875em',
    '& ~ *': {
      fontSize: '0.875em',
    },
  },
  icon: {
    borderRadius: '50%',
    width: '1em',
    height: '1em',
    border: `2px solid ${tertiary[400]}`,
    backgroundColor: base,
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: tertiary[100],
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    width: '1em',
    height: '1em',
    backgroundColor: primary[700],
    '&::before': {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    border: `2px solid ${primary[700]}`,
    'input:hover ~ &': {
      backgroundColor: primary[600],
      borderColor: primary[600],
    },
    'input:disabled ~ &': {
      border: `2px solid ${tertiary[300]}`,
      backgroundColor: tertiary[300],
    },
  },
}));
